.node-type-lab {
  .cb-lab-location {
    background-color: #fff;
    font-family: $font--text--alt;
    text-align: center;
    .pane-node-field-lab-location {
      font-size: 0.9em;
    }
    p {
      font-size: 1rem;
      font-family: $font--text--alt;
    }
    a:not(.cb-button) {
      text-decoration: none;
      font-family: $font--text--alt;
      color: color(action, m);
      &:hover {
        text-decoration: underline;
      }
    }
    .cb-phone,
    .cb-email {
      // remove phone icon underline on hover
      a:hover {
        &::before {
          display: inline-block;
          // to make 'no underline' work in IE11,
          // this needs to be declared first
          // followed by text-decoration: none
          // in separate declaration below
          text-decoration: underline;
        }
        &::before {
          text-decoration: none;
        }
      }
    }
    .organisation-block {
      font-size: 1em;
    }
    .cw-with-map {
      .content {
        display: flex;
        flex-direction: column;
        line-height: 1.5;
      }
    }
    .cb-contact-information__map {
      max-width: 200px;
      order: 9999;
      flex: 0 0 auto;
      margin-top: 0.75em;
      position: relative;
      @include icon($icon: 'external-link', $color: #fff, $size: 0.75em);
      &::before {
        position: absolute;
        top: 5px;
        left: 5px;
        display: inline-block;
        padding: 5px 5px 7px 7px;
        background: rgba(color(primary, lo), 0.5);
      }
      &:hover,
      &:focus,
      &:active {
        &::before {
          color: color(primary, lo);
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .pane-node-field-lab-members {
    margin-top: 3em;
    width: 100%;
  }
  .pane-node-field-lab-publications {
    margin-top: 3em;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li, p {
      font-size: 1rem;
      line-height: 1.7;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
    a,
    p a {
      font-family: $font--text--alt;
      text-decoration: none;
    }
  }
  // [DP-826] this uses to many selectors and needs to be revised.
  /* stylelint-disable declaration-no-important, selector-max-compound-selectors */
  // negation needed because of overrides to featured layout styles in
  // 01-atoms > 01-layout > _02-featured-layout.scss
  &.cb-flavor:not(.cb-list-news) .cb-layout--no-aside,
  .pane-page-content {
    .cb-page-content {
      .cb-content-area__main {
        // Switch to flexbox in order to have location and principal investigator
        // side by side and stretch to same height.
        @include media(bp(s)) {
          display: flex;
          flex-wrap: wrap;
          // The tweener browsers have (ie10, ie11) issues with flexwrap
          // These styles should fix.
          > * {
            flex: 0 1 auto;
            max-width: 100%;
            &:not(.pane-node-field-lab-location):not(.cb-list-profiles--lab-heads) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  /* stylelint-enable */
  .cb-content-area__main {
    .cb-lab-location {
      padding: 1.5em;
      text-align: left;
      margin-bottom: 2rem;
      box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.2);
      @include media(bp(s)) {
        padding: 1.6em 2em 2em 2em;
        width: 50%;
        float: left;
        border-top: 1px solid tint(color(neutral-brand, xhi), 70%);
        border-left: 1px solid tint(color(neutral-brand, xhi), 70%);
        box-shadow: 1.5px 1px 1px 0 rgba(0, 0, 0, 0.1);
      }
      h2.pane-title {
        font-size: 1.125rem;
        margin-bottom: 0.75em;
        font-weight: 600;
      }
      a {
        font-family: $font--text--alt;
        text-decoration: none;
        color: color(action, m);
      }
      .field:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
    .pane-node-body {
      + .pane-node-field-lab-members {
        margin-top: 0;
      }
    }
    // fix spacing between lab main content section and feature rows
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

.panels-ipe.node-type-lab {
  .pane-node-field-image-featured {
    margin-bottom: 2em;
  }
  .pane-node-body {
    clear: both;
  }
  .pane-node-field-lab-location {
    text-align: left;
  }
}
