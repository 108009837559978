// Base featured content styles

.panels-ipe-bundle-featured-content,
.pane-bundle-featured-content.cb-style {
  position: relative;
  // IE11 will add extra space to nested flex box items.
  // Setting overflow to hidden fixes this behavior.
  overflow: hidden;
  .fieldable-panels-pane {
    overflow: hidden;
  }
  .panelizer-view-mode {
    position: relative;
    display: flex;
    flex-direction: column;
    @include media(bp(s)) {
      display: block;
    }
  }
  .cb-layout__component-dynamic {
    overflow: visible;
  }
  .field-name-title-field {
    h3 {
      // Padding for focus outlines.
      padding: 1px;
      font-weight: 600;
      a {
        font-weight: 600;
      }
    }
  }
  .pane-node-field-subtitle {
    h4 {
      font-weight: 600;
    }
  }
  .field-name-field-summary {
    padding-bottom: 1rem;
    font-family: $font--text--alt;
  }
  .pane-node-field-summary:last-child {
    .field-name-field-summary {
      padding-bottom: 0;
    }
  }
  .pane-node-links {
    line-height: 1.2;
    @include media(bp(s)) {
      padding-top: 0;
      padding-left: 0;
      text-align: left;
      border: none;
      position: static;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      line-height: 1.2;
    }
  }
  // overlay link
  // stylelint-disable-next-line selector-class-pattern
  .cw-featured_content__overlay-link {
    z-index: z(content);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-indent: -999em;
    // Adding a bg color makes it work in IE9/10
    background-color: rgba(255, 255, 255, 0);
  }
}

.paragraphs-item-featured-content {
  &:not(.cw-with-image) {
    width: 100%;
    margin: 0 auto;
    @include media(bp(l)) {
      max-width: 47.5rem;
    }
    .field-name-field-summary {
      padding-bottom: $spacing--s;
    }
  }
  &.cw-with-image {
    .cb-layout__component-dynamic {
      display: flex;
      flex-direction: column;
    }
    .column-first {
      width: 100%;
      float: none;
      order: 2;
      > *:not(.pane-node-links) {
        text-align: left;
        padding-left: 0;
      }
    }
    .column-second {
      width: 100%;
      order: 1;
      padding-bottom: $spacing--m;
      text-align: center;
      // Fix ie10 and ie11 height stretch issue
      flex: 0 0 auto;
      display: block;
      img {
        width: 100%;
      }
    }
  }
}
