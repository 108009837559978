// Component right-aligned divider

@mixin component-divider($right-alignment: -1rem) {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    border-right: $border--s;
    top: 0;
    right: $right-alignment;
  }
}
