// Using id and important to override inline styles and ember overrides.
/* stylelint-disable selector-max-specificity, selector-max-id, declaration-no-important, selector-id-pattern */
#modalBackdrop {
  background: #111 !important;
}

#modalContent {
  div.ctools-modal-content {
    &.video-modal,
    &.cola-pages-node-modal {
      background: transparent;
      padding-bottom: 1em;
      border: none;
      .modal-header {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
        overflow: hidden;
        .close {
          height: 25px;
          width: 25px;
          font-size: 18px;
          line-height: 20px;
          background: none;
          text-indent: 0;
          margin-top: 0;
          margin-bottom: 5px;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
          text-decoration: none;
          text-shadow: 2px 2px rgba(0, 0 , 0, 0.5);
          @include icon("close");
          &:hover, &:focus {
            color: rgba(255, 255, 255, 1);
            text-shadow: 0 8px 8px 0 #111;
          }
          &:focus {
            outline: 1px dotted color(neutral-brand, m);
          }
          img {
            display: block;
            @include visually-hidden();
          }
        }
      }
      .modal-title {
        color: #fff;
        margin: 0;
        font-family: $font--display;
        font-size: 1.5em;
        display: none;
      }
      .cb-embed--ratio {
        // Overriding inline styles set at the backend.
        padding: 0 !important;
        height: auto;
        iframe {
          position: static;
        }
      }
      .modal-content,
      iframe {
        overflow: hidden;
        padding: 0;
        margin: 0 auto;
        display: block;
      }
      .ctools-modal-loading {
        min-height: 32px;
        min-width: 32px;
        // Adding height here seems to cause the modal to be off center
        // vertically until the window is resized.
        // height: 33vh;
        background: rgba(255, 255, 255, 0.5) url("#{$image-path}/ajax-loader-transparent.gif") no-repeat center;
        background-size: 32px;
        overflow: initial;
        &::before {
          content: "Loading";
          @include visually-hidden();
        }
        .modal-throbber-wrapper {
          display: none;
        }
      }
    }
    &.cola-pages-node-modal {
      max-height: 90vh;
      max-width: 960px;
      .modal-header,
      .modal-content {
        // Using important to override js inline styles.
        width: 100% !important;
        max-width: 960px;
      }
      .modal-content {
        h1 {
          color: color(primary, xlo);
        }
        p {
          flex-shrink: 1;
        }
      }
    }
  }
}
/* stylelint-enable */
