.cb-list-resources__search {
  .views-exposed-form {
    padding: 0;
    box-shadow: none;
  }
  .row-1, .row-2 {
    background-color: #fff;
    border: 1px solid color(neutral-brand, xxhi);
    padding: 0 1em;
    @include media(bp(s)) {
      padding: 0 1.5em;
    }
  }
  .row-1 {
    padding-top: 1em;
    border-radius: 2px 2px 0 0;
    border-bottom-width: 0;
    @include media(bp(s)) {
      padding-top: 1.5em;
    }
  }
  .row-2 {
    padding-bottom: 1em;
    border-radius: 0 0 2px 2px;
    border-top-width: 0;
    @include clearfix();
    @include media(bp(s)) {
      padding-bottom: 1.5em;
    }
  }
  .views-exposed-widgets {
    display: flex;
    align-items: flex-end;
    &.clearfix {
      &::after {
        display: none;
      }
    }
  }
  .views-exposed-widget {
    // stylelint-disable selector-class-pattern
    &.views-widget-filter-search_api_views_fulltext {
      width: auto;
      flex: 1 0 auto;
    }
    // stylelint-enable selector-class-pattern
    &.views-submit-button {
      order: 2;
      margin-top: 0;
      input {
        margin: 0;
      }
      &:nth-child(2):last-child {
        input {
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
  // All the filters are in this row.
  .row-2 {
    .panel-pane {
      margin-top: 6px;
      @include media(bp(s)) {
        margin-top: 10px;
        @include span-columns(6);
        @include quantities-all(2) {
          @include span-columns(6);
        }
        @include quantities-all(3) {
          @include span-columns(4);
        }
        &:last-child {
          @include omega();
        }
      }
    }
    // No ja fallback
    .pane-title {
      font-size: 1rem;
    }
  }
  .cb-facets__filter-button {
    @include icon($icon: sliders, $pseudo: after) {
      margin-left: 6px;
    }
  }
  .cb-facet__toggle-button {
    position: relative;
    .caret {
      position: absolute;
      right: 10px;
      top: calc(50% - 7px);
      line-height: 0;
      transition: transform 0.3s ease;
      @include icon($icon: 'caret-down', $size: 14px);
    }
    &[aria-expanded="true"] {
      .caret {
        transform: rotate(0.5turn);
      }
    }
  }
  .pane-current-search-cola-services-resources-search {
    .pane-title {
      margin: 16px 0 0 0;
      font-size: 1.25rem;
    }
    .current-search-item-active-items {
      ul {
        padding: 0;
        margin: 0;
        display: flex;
      }
      li {
        display: block;
        margin: 8px 6px 0 0;
        a {
          padding: 5px 6px 0 6px;
          border: 1px solid color(neutral-brand, m);
          border-radius: 2px;
          display: block;
          font-size: 0.9rem;
          line-height: 1.8;
          min-height: $touch-size-min;
          color: color(neutral-brand, xxlo);
          @include icon($icon: 'close', $pseudo: after, $size: 10px) {
            margin-left: 6px;
            vertical-align: baseline;
          }
          &:hover {
            background: $background-solid-light;
          }
        }
      }
    }
  }
}
