// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing {
  .pane {
    &.panels-ipe-bundle-featured-content {
      // stylelint-disable-next-line selector-class-pattern
      .cw-featured_content__overlay-link {
        display: none;
      }
      .panels-ipe-dragbar {
        border: 1px solid #CCC;
      }
    }
  }
  .region {
    .pane {
      &.panels-ipe-bundle-featured-content {
        padding: 0;
        border: 1px solid #CCC;
      }
    }
  }
  .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-bundle-featured-content {
        padding: 0;
        .panels-ipe-portlet-content {
          @include component-padding();
        }
      }
    }
  }
}

.region.cb-max-columns--3,
.region.cb-max-columns--4 {
  .pane {
    &.panels-ipe-bundle-featured-content {
      .panels-ipe-portlet-content {
        .pane-bundle-featured-content {
          padding: 0;
        }
      }
    }
  }
  .panels-ipe-bundle-featured-content {
    // Display image and text side by side
    @include media(bp(bs)) {
      @include quantities-all(odd) {
        &:only-child {
          .paragraphs-item-featured-content {
            &.cw-with-image {
              .cb-layout__component-dynamic {
                flex-direction: row;
              }
              .column-first {
                order: 1;
                @include span-columns(8);
              }
              .column-second {
                order: 2;
                margin-left: $spacing--m;
                @include span-columns(4);
              }
            }
          }
        }
      }
    }
    // set component flex grow to be consistent
    // with logged-out display
    &.panels-ipe-portlet-wrapper {
      flex-grow: 0;
    }
  }
}
