// CTA With No Image Base Styles

.region {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta {
    .paragraphs-item-cta {
      &:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
        .content {
          max-width: 100%;
        }
        .field-name-field-cta-link {
          @include media(bp(l)) {
            padding-bottom: 0;
          }
          a {
            margin-bottom: 0;
          }
        }
        // CTA with link only
        &:not(.cw-with-title):not(.cw-with-description) {
          height: 100%;
          .field-name-field-cta-link {
            max-width: 100%;
            width: 100%;
            display: flex;
            a {
              @include component-heading(xs);
              width: 100%;
              max-width: 100%;
              text-align: left;
              margin: 0;
              padding-right: 14%;
              &::after {
                justify-self: center;
                content: "";
                position: absolute;
                right: 0;
                top: calc(50% - 0.5em);
                line-height: 1;
              }
              @include icon($icon: "caret-right", $pseudo: "after", $size: 1em, $color: #fff);
            }
            a[data-extlink] {
              // hide arrow icon since ext links already has an ext link icon
              &::after {
                display: none;
              }
            }
          }
          &.cb-position-h--left,
          &.cb-position-h--center {
            .field-name-field-cta-link {
              width: 100%;
            }
            .content {
              max-width: 100%;
              @include media(bp(l)) {
                max-width: $max-width;
              }
            }
          }
          &:hover {
            .field-name-field-cta-link a {
              @media (hover: hover) {
                &::after {
                  @include animated($duration: 1s, $name: wobble);
                }
              }
            }
          }
        }
      }
    }
    // always center align cta link if component has no title/description
    .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
      &.cb-position-h--left,
      &.cb-position-h--center {
        &:not(.cw-with-title):not(.cw-with-description) {
          align-items: center;
          .field-name-field-cta-link {
            float: none;
            text-align: center;
          }
          .content {
            text-align: center;
          }
        }
      }
    }
  }
}

// Not Only Child
.region {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta {
    &:not(:only-child) {
      .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
        .content {
          @include component-padding();
        }
        // CTA with link only
        &:not(.cw-with-title):not(.cw-with-description) {
          .field-name-field-cta-link {
            a {
              // top align link arrow icon to balance
              // varies side-by-side CTA title lengths
              &::after {
                top: 0.1em;
              }
            }
          }
        }
      }
    }
    @include media($bp-only-s) {
      @include quantities-all(odd) {
        &:not(:only-child) {
          .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
            .content {
              max-width: 85%;
              margin: 0 auto;
            }
          }
        }
      }
    }
    @include media(bp(l)) {
      .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include quantities-all(2) {
        .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
          .content {
            padding: $spacing--xl;
          }
        }
      }
    }
  }
}

.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      @include media(bp(l)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
              .content {
                padding: $spacing--xl;
              }
            }
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta {
        @include media(bp(l)) {
          @include quantities-all(odd) {
            &:not(:only-child) {
              .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
                .content {
                  padding: $spacing--l;
                }
              }
            }
          }
          @include quantities-all(even) {
            .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
              .content {
                padding: $spacing--xl;
              }
            }
          }
        }
      }
    }
  }
}

// Only Child Styles
.region {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta {
    &:only-child {
      .paragraphs-item-cta {
        &:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
          display: block;
          padding-top: 0;
          padding-bottom: 0;
          .content {
            padding: $spacing--l $spacing--l 1.7rem $spacing--l;
            @include media(bp(s)) {
              padding: $spacing--xl;
              max-width: $max-width;
            }
          }
          &.cb-position-h--center {
            .content {
              @include media(bp(s)) {
                max-width: 85%;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
  // Region Specific Styles
  &.cb-padding--none {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      &:only-child {
        .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
          padding: 0;
          @include media(bp(s)) {
            padding: 1em $spacing--l 1.5em $spacing--l;
          }
          @include media(bp(l)) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-padding {
        .pane-bundle-cta.cb-style,
        .panels-ipe-bundle-cta {
          &:only-child {
            .paragraphs-item-cta:not(.cw-with-image):not(.cw-with-icon):not(.cw-cta-phone) {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
