// Base to page link styles

.node-type-trial,
.cb-feature-area .cb-container__inner,
.node-type-news .cb-feature-tertiary,
.cb-list-news--standard.cb-list-news--topic .cb-content-area__main >,
.cb-list-news--standard.cb-list-news--topic .cb-content-area__main .panels-ipe-portlet-content > {
  .pane-link-to-list {
    padding: $spacing--s $component-spacing 0 $component-spacing;
    margin: 1em auto 0 auto;
    @include media(bp(m)) {
      padding-left: $page-margins;
      padding-right: $page-margins;
      margin-top: 2em;
    }
    @include media(bp(l)) {
      max-width: $max-width;
      padding-top: 0;
    }
    a {
      color: color(action, m);
      text-shadow: none;
      background: transparent;
      text-decoration: none;
      display: inline-block;
      text-align: left;
      @include icon($icon: "arrow-left", $pseudo: "before", $size: 0.9em) {
        margin-right: 0.5em;
        padding-bottom: 0.1em;
      }
      @media (hover: hover) {
        &:hover::before {
          @include animated($duration: 1s, $name: wobble);
        }
      }
      &:hover,
      &:active {
        @include media(bp(l)) {
          background: transparent;
          color: color(action, m);
        }
      }
    }
  }
}

// clinical trial detail page only
.node-type-trial {
  .pane-link-to-list {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    @include media(bp(l)) {
      max-width: 100%;
    }
  }
}
