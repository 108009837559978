// Radio buttons and checkboxes
.cb-payment-button,
.search-form,
.webform,
.views-exposed-form,
.paragraphs-item-signup-form {
  .form-radios,
  .form-checkboxes {
    margin-bottom: 1em;
  }
  .form-required {
    color: color(alert-fail);
  }
  .form-type-radio {
    input[type="radio"] {
      @include visually-hidden();
      &:checked + label::before {
        content: "\2022";
        color: color(neutral-brand, m);
        font-size: 40px;
        text-align: center;
        line-height: 13px;
        font-weight: 600;
      }
      &:focus + label::before {
        outline: 1px dotted color(neutral-brand, m);
      }
      &:hover + label::before {
        @include component-bg-hover-styles(#fff, $change: 'darken');
      }
    }
    label {
      &::before {
        border-radius: 20px;
      }
    }
  }
  .form-type-checkbox {
    input[type="checkbox"] {
      @include visually-hidden();
      &:checked + label::before {
        content: "\2713";
        content: "\00D7" / "";
        color: color(neutral-brand, m);
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        line-height: 20px;
      }
      &:focus + label::before {
        outline: 1px dotted color(neutral-brand, m);
      }
      &:hover + label::before {
        @include component-bg-hover-styles(#fff, $change: 'darken');
      }
    }
  }
  .form-type-radio,
  .form-type-checkbox {
    label {
      display: inline-block;
      cursor: pointer;
      position: relative;
      padding-left: 28px;
      margin-right: 15px;
      font-size: 1em;
      font-weight: 400;
      font-family: $font--text--alt;
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 0.5em;
        position: absolute;
        left: 0;
        top: 1px;
        border: solid 1px color(neutral-brand, m);
        @include media(bp(m)) {
          top: 3px;
        }
      }
    }
  }
}

.cb-payment-button,
.search-form,
.webform,
.views-exposed-form {
  fieldset {
    margin: 2em 0 0 0;
    padding: 0;
    border: none;
    > .fieldset-wrapper > .form-item:first-child {
      margin-top: 0;
    }
    legend {
      font-size: 1.125em;
      font-family: $font--display;
      font-weight: 600;
      margin-bottom: 0.5em;
      @include media(bp(m)) {
        font-size: 1.25em;
      }
    }
  }
  .form-wrapper {
    > legend {
      font-family: $font--text;
      font-weight: 400;
      font-size: 1rem;
    }
  }
  .form-actions {
    margin-top: 2.5em;
  }
}

// Select boxes.
.cb-payment-button,
.search-form,
.webform,
.views-exposed-form,
.paragraphs-item-signup-form,
.cb-epic-scheduling__content form {
  select {
    appearance: none;
    min-height: $touch-size-min;
    padding: 4px 34px 4px 14px;
    border: solid 1px color(neutral-brand, m);
    border-radius: 2px;
    font-family: $font--text--alt;
    font-size: 1em;
    font-weight: 600;
    color: color(neutral-brand, xlo);
    // Margin top 1px to visually align with input boxes (inner shadow vs outer).
    margin-top: 1px;
    &:not([multiple]) {
      background: $dropdown-select-bg-color url($dropdown-select-icon-img-file) no-repeat 97% center;
      background-size: 20px 9px;
    }
    // Currently there is no inline disable for sass-lint.
    // see https://github.com/sasstools/sass-lint/pull/402
    // and https://github.com/sasstools/sass-lint/issues/70
    // The syntax below is from scss-lint and non-functional.
    //
    // scss-lint:disable VendorPrefix
    // Hide IE dropdown arrow.
    &::-ms-expand {
      display: none;
    }
    // scss-lint:enable VendorPrefix
  }
  .webform,
  .views-exposed-form-container-inline {
    .form-type-select {
      display: inline-block;
      margin-top: 0;
      margin-right: 1em;
      @include media(bp(m)) {
        display: inline;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &.hour {
        margin-right: 0.25em;
      }
      + .form-type-select {
        &.minute {
          margin-left: 0.25em;
          + .form-radios {
            display: block;
            margin-left: 0;
            @include media(bp(s)) {
              display: inline;
              margin-left: 1em;
            }
          }
        }
      }
    }
  }
}

// Set base label styles.
.cb-payment-button,
.search-form,
.webform,
.views-exposed-form,
.paragraphs-item-signup-form,
.cb-epic-scheduling__content form {
  label {
    font-size: 0.9em;
    font-weight: 400;
    font-family: $font--text--alt;
    display: block;
    margin-bottom: 0.25em;
    &.element-invisible {
      width: 1px;
      display: inline;
    }
  }
}

// Textareas and text inputs
.cb-payment-button,
.search-form,
.webform,
.views-exposed-form,
.paragraphs-item-signup-form,
.cb-mychart {
  textarea,
  input[type="text"],
  input[type="search"],
  input[type="email"],
  input[type="url"],
  input[type="password"] {
    min-height: $touch-size-min;
    padding: 4px 12px 5px 12px;
    background-color: #fff;
    border: solid 3px darken(color(neutral-brand, hi), 1%);
    font-family: $font--text--alt;
    &:hover {
      @include component-bg-hover-styles(#fff, $change: 'darken');
    }
    &::placeholder {
      color: color(neutral-brand, m);
      opacity: 1;
    }
    + * {
      margin-top: 0.5em;
    }
  }
  input[type="text"],
  input[type="search"],
  input[type="email"],
  input[type="url"],
  input[type="password"] {
    line-height: 26px;
  }
  textarea {
    min-height: 150px;
  }
}

// Spacing for text prefixes and suffixes.
.cb-payment-button,
.search-form,
.webform,
.views-exposed-form {
  .field-suffix {
    margin-left: 10px;
  }
  .field-prefix {
    margin-right: 10px;
  }
}

// Make IE10 et al. wrap legends.
legend {
  max-width: 100%;
}

// Add space between groups of input elements.
.cb-payment-button,
.search-form,
.webform:not(body),
.views-exposed-form-component {
  margin-top: 2em;
}

// Fix responsive vertical spacing for inline form items.
.cb-payment-button,
.search-form,
.webform,
.views-exposed-form-container-inline {
  div.form-type-radio,
  div.form-type-checkbox {
    display: inline-block;
    @include media(bp(m)) {
      display: inline;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}

// Ensure that web forms use generic fonts.
textarea,
input[type="text"],
input[type="search"],
input[type="email"],
input[type="url"],
input[type="password"] {
  // stylelint-disable declaration-no-important
  font-family: Helvetica, Arial, sans-serif !important;
  // stylelint-enable declaration-no-important
}
