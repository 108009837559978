// Main Newsroom classy panels:
// Promoted topics uses default component prominence auto 'width' styles

.cb-list-news--main {
  .region.cb-padding {
    .pane {
      margin-top: 2em;
      @include media(bp(m)) {
        margin-top: 2.5em;
      }
      @include media(bp(l)) {
        margin-top: 0;
      }
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(n+4) {
        @include media(bp(l)) {
          margin-top: $component-vertical-spacing;
        }
      }
    }
  }
  .region.cb-max-columns--3 & {
    .pane {
      // Fix ie9 horizontal alignment issue when children have different heights
      &:nth-child(3n+4) {
        @include media(bp(l)) {
          .lt-ie10 & {
            .cb-page-content:not(.cb-page-content--feature-rows) & {
              clear: both;
            }
          }
        }
      }
    }
  }
}

// Default component horizontal spacing.
.cb-list-news--main {
  .cb-content-area__feature-rows {
    .pane {
      &:not(:only-child) {
        @include media(bp(l)) {
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
          float: left;
        }
      }
      // Two components in this container.
      &:first-child:nth-last-child(2),
      &:nth-child(2):last-child {
        @include media(bp(l)) {
          width: width-margins(50%);
          float: left;
        }
      }
    }
  }
}

.cb-list-news--main {
  .region.cb-max-columns--3 {
    .pane {
      &:first-child:nth-last-child(n+3),
      &:nth-child(2):not(:last-child),
      &:nth-child(n+3) {
        @include media(bp(l)) {
          width: width-margins(33.3333%);
          float: left;
        }
      }
    }
  }
}
