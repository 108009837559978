// CTA Full Width Background Styles (Only child in a region)

@include component-region-bg-styles(auto) {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    .paragraphs-item-cta {
      &.cw-with-icon {
        .field-name-field-cta-description a {
          color: #fff;
        }
      }
    }
  }
}
