// stylelint-disable scss/dollar-variable-pattern
$spacing--xs: 0.3rem;
$spacing--s: 0.5rem;
$spacing--m: 1rem;
$spacing--l: 1.5rem;
$spacing--xl: 2rem;
$spacing--xxl: 3rem;


// Applied spacing.
$swim-lanes--s: 2.5rem;
$swim-lanes--l: 3.75rem;
$swim-lanes--xl: 4rem;
$component-v: 2.5rem;
$spacing--structured-content: 2rem;

@mixin region-padding() {
  padding-top: $swim-lanes--s;
  padding-bottom: $swim-lanes--s;
  @include media(bp(l)) {
    padding-top: $swim-lanes--l;
    padding-bottom: $swim-lanes--l;
  }
  @include media(bp(xl)) {
    padding-top: $swim-lanes--xl;
    padding-bottom: $swim-lanes--xl;
  }
}

// stylelint-enable scss/dollar-variable-pattern
