// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing:not(.cb-list-news) {
  .region {
    .pane {
      &.cb-list-news--promoted,
      &.cb-list-events--promoted,
      &.cb-list-news--promoted.cb-list-news--announcements {
        .panels-ipe-portlet-content {
          margin: 0;
        }
        .panels-ipe-handlebar-wrapper {
          border: 1px solid #CCC;
          .panels-ipe-linkbar {
            /* stylelint-disable selector-max-compound-selectors */
            > li {
              width: auto;
              display: block;
              margin: auto;
              padding: 0;
              clear: none;
              border: none;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
  .cb-page-content {
    .cb-content-area__aside-last {
      .pane {
        &.cb-list-news--promoted,
        &.cb-list-events--promoted {
          padding: 0;
          .panels-ipe-portlet-content {
            @include component-padding();
          }
        }
      }
    }
  }
  .cb-layout {
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .cb-content-area__aside-last {
        .pane {
          &.cb-list-news--promoted,
          &.cb-list-events--promoted {
            /* stylelint-disable selector-max-compound-selectors */
            .panels-ipe-handlebar-wrapper {
              border: 1px solid #CCC;
            }
            .panels-ipe-portlet-content {
              padding: 0;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
