// Labs and Unit by Type List Component Typography Styles

// Component specific typography styles
.cb-list-units--component,
.cb-list-labs--component {
  h2.pane-title {
    text-align: center;
    padding: 0 0 0.5rem 0;
  }
  .more-link {
    margin-top: $spacing--m;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    a {
      text-decoration: none;
    }
    &:last-child {
      margin-bottom: $spacing--m;
    }
  }
  @include media(bp(bs)) {
    .column-first {
      .cb-list-units,
      .cb-list-labs {
        &__title {
          h3 {
            padding: 0;
          }
        }
      }
    }
  }
  @include media(bp(m)) {
    .more-link {
      a {
        font-size: 1.1rem;
      }
    }
  }
  @include media(bp(l)) {
    .more-link {
      a {
        font-size: 1rem;
      }
    }
  }
}

.region,
.cb-page-content .cb-content-area__main {
  .cb-list-units--component,
  .cb-list-labs--component {
    .more-link a {
      @include btn($style: solid, $size: s);
      &:hover {
        border-color: transparent;
      }
    }
  }
}

// Standardized component default font sizes
.region,
.cb-page-content .cb-content-area__main {
  .cb-list-units--component,
  .cb-list-labs--component {
    h2.pane-title {
      @include component-heading(m);
      @include media(bp(xs)) {
        @include component-heading(l);
      }
      @include media(bp(m)) {
        @include component-heading(xl);
      }
      @include media(bp(l)) {
        @include component-heading(xxl);
      }
    }
    .cb-list-units,
    .cb-list-labs {
      &__title {
        h3 {
          @include component-link-title(s);
          a {
            font-weight: 600;
          }
        }
      }
      &__summary {
        p {
          @include component-paragraph(xs);
        }
      }
    }
    .view-content {
      li {
        @include media(bp(s)) {
          @include quantities-all(1,3) {
            // with image
            .cb-list-units,
            .cb-list-labs {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(s);
                }
              }
            }
          }
          @include quantities-all(1) {
            // no image
            .column-first:only-child {
              .cb-list-units,
              .cb-list-labs {
                &__title {
                  /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                  h3 {
                    @include component-link-title(l);
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
          @include quantities-all(2,4) {
            .cb-list-units,
            .cb-list-labs {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
            }
          }
        }
        @include media(bp(m)) {
          @include quantities-all(2,3,4) {
            .cb-list-units,
            .cb-list-labs {
              &__summary {
                p {
                  @include component-paragraph(s);
                }
              }
            }
          }
          @include quantities-all(3) {
            .cb-list-units,
            .cb-list-labs {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
            }
          }
          @include quantities-all(1) {
            .cb-list-units,
            .cb-list-labs {
              &__title {
                h3 {
                  @include component-link-title(l);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(m);
                }
              }
            }
          }
        }
        @include media(bp(xl)) {
          @include quantities-all(2) {
            .cb-list-units,
            .cb-list-labs {
              &__title {
                h3 {
                  @include component-link-title(xl);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(m);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-units--component,
    .cb-list-labs--component {
      @include media(bp(l)) {
        .view-content {
          li {
            @include quantities-all(4) {
              .cb-list-units,
              .cb-list-labs {
                &__title {
                  /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                  h3 {
                    @include component-link-title(s);
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
// Decrease font-size
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .cb-list-units--component,
      .cb-list-labs--component {
        @include media(bp(l)) {
          .view-content {
            li {
              @include quantities-all(2) {
                .cb-list-units,
                .cb-list-labs {
                  /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                  &__title {
                    h3 {
                      @include component-link-title(m);
                    }
                  }
                  &__summary {
                    p {
                      @include component-paragraph(s);
                    }
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
        }
      }
    }
  }
}
