.cb-yext-results {
  position: relative;
  min-height: 32px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -16px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 5px solid #f2f2f3;
    border-top-color: #0077c8;
    animation: yextloading 2s linear infinite;
  }
  &::after {
    content: "Loading physicians ...";
    font-family: $font--text--alt;
    font-size: 0.85rem;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0.75em auto;
    top: 30px;
    text-align: center;
  }
  &--loaded::before,
  &--loaded::after {
    display: none;
  }
  &:last-child {
    // Adjust for padding and margins in yext frame.
    margin-bottom: -1.5rem;
  }
  &__iframe {
    // Add 2rem to account for yext padding.
    width: calc(100% + 2rem);
    // Reposition to re-center larger iframe.
    margin-left: -1rem;
    // Adjust for top padding in yext iframe.
    margin-top: -1rem;
    height: 30rem;
  }
}

@keyframes yextloading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
