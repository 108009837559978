.pane.cb-list-trials {
  &.cb-list-trials--promoted,
  &.cb-list-trials--filtered {
    h2 {
      &.pane-title,
      &.field-name-field-title {
        padding-bottom: 0.5em;
        margin-bottom: 0;
        border-bottom: $border--m;
        border-color: $heading-border-color;
      }
    }
  }
  &.cb-list-trials--promoted {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    ul:not(.panels-ipe-linkbar) {
      li {
        line-height: 1.25;
        width: 100%;
        padding: 2em 0;
        margin: 0;
        border-bottom: $border--xs;
        a {
          font-size: 1.25em;
          font-weight: 600;
          @include media(bp(m)) {
            font-size: 1.375em;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    // [DP-826] this uses to many selectors and needs to be revised.
    /* stylelint-disable selector-max-compound-selectors */
    .view-content {
      margin-top: 2em;
    }
    .more-link {
      text-align: left;
      a {
        font-size: 1.125em;
        font-weight: 600;
        @include icon($icon: "caret-right", $pseudo: "after", $size: 0.8em) {
          margin-left: 0.2em;
        }
        &:hover::after {
          @include animated($duration: 1s, $name: wobble);
        }
        .cb-content-area__main &,
        .lt-ie10 .cb-content-area__main & {
          text-decoration: none;
        }
      }
    }
    /* stylelint-enable */
    &:not(.cb-list-trials--component) {
      overflow: visible;
      @include clearfix();
      ul:not(.pager):not(.panels-ipe-linkbar) {
        li {
          &:first-child {
            padding-top: 0;
            border-top: none;
          }
        }
      }
    }
    // override default teaser list styles
    &:nth-child(n+3):not(.panels-ipe-portlet-wrapper) {
      .view-content {
        ul:not(.pager):not(.panels-ipe-linkbar) {
          li {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  &.cb-list-trials--filtered {
    .view-content {
      ul:not(.pager):not(.panels-ipe-linkbar) {
        li {
          &:first-child {
            border-top: none;
          }
        }
      }
    }
    .panels-ipe-portlet-content {
      .cb-list-trials__body {
        margin-bottom: 0;
      }
    }
  }
}

.pane.cb-list-trials {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    &:not(.pager):not(.panels-ipe-linkbar) {
      li {
        width: 100%;
        padding: 1.9em 0 2em 0;
        margin: 0;
        &:first-child {
          border-top: $border--s;
        }
        &.views-row-last {
          border-bottom: none;
        }
      }
    }
  }
  h3 {
    margin-bottom: 0.25em;
    a {
      .cb-content-area__main & {
        font-weight: 600;
        font-size: 1.375rem;
      }
    }
  }
  .pane-node-field-trial-investigator {
    color: color(neutral-brand, m);
    margin-bottom: 0.5em;
    h3,
    .field-name-field-trial-investigator {
      font-weight: 600;
      font-size: 1.125rem;
      display: inline;
    }
  }
  .pane-node-body,
  .pane-node-body p {
    line-height: 1.7;
    font-size: 1rem;
  }
  &:not(:first-child) {
    h2 {
      &.pane-title,
      &.field-name-field-title {
        padding-bottom: 0.5em;
        margin-bottom: 0;
        border-bottom: $border--m;
        border-color: $heading-border-color;
      }
    }
  }
  ul:not(.pager):not(.panels-ipe-linkbar) {
    li:first-child {
      padding-top: 1.5em;
    }
  }
}

// Basic page main content
.cb-content-area__main {
  .pane.cb-list-trials {
    &:not(:first-child) {
      margin-top: 3em;
    }
  }
}
