.node-type-course {
  .cb-content-area__main {
    .pane-node-body {
      margin-bottom: 2em;
      ~ .pane-entity-field,
      ~ .pane-cola-courses-credits {
        margin-bottom: 0.25rem;
        overflow: hidden;
      }
    }
    .pane-title,
    .pane-title + div,
    .pane-title + ul {
      font-size: 1em;
      @include media(bp(m)) {
        font-size: 1.125rem;
      }
    }
    .pane-title + div,
    .pane-title + ul {
      background: $background-solid-xlight;
      color: a11yrize(color(neutral-brand, xlo), $background-solid-xlight);
    }
    .pane-title {
      width: 50%;
      max-width: 10em;
      background: $background-solid-light;
      color: a11yrize(color(neutral-brand), $background-solid-light);
      float: left;
      padding: 0.5rem;
      margin: 0;
      + div {
        padding: 0.5rem;
        float: left;
        width: calc(100% - 10em);
        line-height: 1.25;
        font-family: $font--text--alt;
      }
      + ul {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        clear: both;
        a {
          color: a11yrize(color(primary), $background-solid-xlight);
        }
      }
    }
    .field-name-field-courses-status {
      &::after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background: color(alert-pass, hi);
        vertical-align: baseline;
        margin-left: 0.5em;
      }
    }
    .pane-node-field-courses-status {
      border-top: 10px solid color(secondary, brand);
      &.cb-course__status--inactive {
        border-top: 10px solid color(neutral-brand, m);
      }
    }
    .cb-course__status--inactive {
      .field-name-field-courses-status::after {
        background: color(neutral-brand, hi);
      }
    }
    .pane-entity-field:not(.pane-node-field-courses-prerequisites):not(.pane-node-field-courses-corequisites),
    .pane-cola-courses-credits
     {
      display: flex;
    }
  }
}
