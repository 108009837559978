.node-type-treatment {
  .pane-node-field-treatment-when-performed,
  .pane-node-field-treatment-how-performed,
  .pane-node-field-treatment-preparation,
  .pane-node-field-treatment-outcome {
    margin-bottom: $spacing--xl;
    @include media(bp(m)) {
      margin-bottom: $spacing--xxl;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
