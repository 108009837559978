// CTA Square Img Backgrounds (style 1)
@mixin cta-bg-colors($bg-color, $color) {
  background-color: if($bg-color == none, transparent, $bg-color);
  @if $bg-color == none {
    border: $component-border-style;
    @include media(bp(l)) {
      border: none;
    }
  }
  .paragraphs-item-cta:not(.cw-cta-phone) {
    background-color: if($bg-color == none, transparent, $bg-color);
    .field-name-field-title h2,
    .field-name-field-cta-description p {
      color: $color;
    }
  }
  .paragraphs-item-cta:not(.cw-cta-phone) {
    &.cw-with-image:not(.cw-with-icon) {
      &::before {
        background: if($bg-color == none, transparent, linear-gradient(transparent, $bg-color));
        z-index: z(contentBottom);
      }
      &::after {
        background: if($bg-color == none, transparent, linear-gradient(transparent, $bg-color));
        z-index: z(contentBottom);
      }
      .field-name-field-cta-link a:focus {
        $test: if($bg-color == none, #fff, $bg-color);
        outline-color: if(color-ratio(#fff, $test) > color-ratio(#000, $test), #fff, #000);
      }
    }
    &:not(.cw-with-image):not(.cw-with-icon) {
      &::before {
        background: if($bg-color == none, transparent, rgba(darken($bg-color, 20%), 0.15));
      }
      &::after {
        background: if($bg-color == none, transparent, linear-gradient(transparent, darken($bg-color, 20%)));
      }
      .field-name-field-cta-link a:focus {
        $test: if($bg-color == none, #fff, darken($bg-color, 20%));
        outline-color: if(color-ratio(#fff, $test) > color-ratio(#000, $test), #fff, #000);
      }
    }
  }
}

@include component-region-bg-styles(auto,1) {
  .pane-bundle-cta.pane:not(:only-child),
  .panels-ipe-bundle-cta.pane:not(:only-child) {
    // dark blue
    @include cta-bg-colors($component-bg-2-color, $component-bg-3-font-color);
  }
}

@include component-region-bg-styles(2,3) {
  .pane-bundle-cta.pane:not(:only-child),
  .panels-ipe-bundle-cta.pane:not(:only-child) {
    // dark grey
    @include cta-bg-colors(color(neutral-brand,xlo), $component-bg-3-font-color);
    .paragraphs-item-cta.cw-with-icon:not(.cw-cta-phone) {
      .field-name-field-cta-description p {
        color: $c-text;
      }
    }
    .paragraphs-item-cta:not(.cw-cta-phone) {
      &.cw-with-image {
        .field-name-field-cta-link {
          a:focus {
            background: color(neutral-brand,xlo);
            border-color: transparent;
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(auto,1,2,3) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--1.pane:not(:only-child) &,
    &.cb-style--1.pane:not(:only-child) {
      &:not(:only-child) {
        .paragraphs-item-cta:not(.cw-cta-phone) {
          &.cw-with-title {
            &:hover {
              .field-name-field-cta-link a {
                background: transparent;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
