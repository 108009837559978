// Shared styles within region with classy panel styles
// i.e. homepage, featured layout feature rows

.region,
.cb-page-content .cb-content-area__main {
  .pane {
    &.panels-ipe-bundle-numbers,
    &.pane-bundle-numbers {
      max-width: 100%;
      .cb-numbers {
        &__numbers {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          @include media(bp(m)) {
            margin: 0 -1.7%;
          }
          .cb-number {
            display: flex;
            width: 100%;
            > .content {
              display: flex;
              flex-wrap: wrap;
              border: 1px solid $background-solid-light;
              width: 100%;
            }
            &__description {
              background-color: $background-solid-xlight;
              flex: 1 1 auto;
              padding: 0.5rem;
            }
            &__number {
              display: flex;
              padding: 0.5rem;
            }
          }
          li {
            width: 100%;
            margin: 0.5em 0;
            display: flex;
            @include media(bp(m)) {
              // display 2 columns when no sidebars or single sidebar
              width: 46.6%;
              margin: $spacing--s 1.7%;
              float: left;
            }
            .cb-layout--aside-first-last & {
              @include media(bp(xl)) {
                width: 100%;
                float: none;
              }
            }
          }
          dl, dt, dd {
            margin: 0;
          }
        }
        // Multiple in numbers lists
        + .cb-numbers {
          margin-top: $spacing--xl;
          border-top: 1px solid #f2f2f3;
          padding-top: $spacing--l;
        }
      }
    }
  }
}

.cb-page-content--feature-rows .region,
.cb-page-content--feature-rows .cb-content-area__main {
  .pane {
    &.panels-ipe-bundle-numbers,
    &.pane-bundle-numbers {
      .cb-numbers__numbers {
        li {
          .cb-layout--aside-first-last & {
            @include media(bp(xl)) {
              width: 46.6%;
              float: left;
            }
          }
        }
      }
      .cb-numbers.cw-with-icon {
        .cb-numbers__numbers {
          li {
            .cb-layout--aside-first-last & {
              @include media(bp(xl)) {
                width: 100%;
                margin: $spacing--s 0;
              }
            }
          }
        }
      }
    }
  }
}

// With icon
.region,
.cb-page-content .cb-content-area__main,
.cb-page-content .cb-content-area__aside-last {
  .pane {
    &.panels-ipe-bundle-numbers,
    &.pane-bundle-numbers {
      .cb-numbers {
        &.cw-with-icon {
          > .content {
            position: relative;
            &::before {
              content: '';
              padding-bottom: 15%;
              width: 15%;
              float: left;
              margin-right: $spacing--m;
            }
          }
          .paragraphs-items-field-numbers-paragraphs {
            clear: both;
            @include media(bp(m)) {
              padding-left: calc(15% + 1rem);
              clear: none;
            }
          }
          .cb-numbers {
            &__numbers {
              @include media(bp(m)) {
                margin: 0;
              }
            }
            &__icon {
              position: absolute;
              left: 0;
              top: 0;
              color: $component-icon-color;
              width: 15%;
              svg {
                fill: currentColor;
              }
            }
            &__title {
              text-align: left;
              padding-left: calc(15% + 1rem);
            }
          }
          li {
            @include media(bp(m)) {
              width: 100%;
              margin: $spacing--s 0;
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .pane {
      &.panels-ipe-bundle-numbers,
      &.pane-bundle-numbers {
        .cb-numbers {
          &__numbers {
            @include media(bp(l)) {
              margin: 0 -1.7%;
            }
            li {
              @include media(bp(l)) {
                // display 2 columns
                width: 46.6%;
                margin: $spacing--s 1.7%;
              }
            }
          }
          &.cw-with-icon {
            .cb-numbers__numbers {
              margin: 0;
              @include media(bp(l)) {
                .cb-number {
                  margin: 0;
                  // stylelint-disable selector-max-compound-selectors
                  &__number {
                    align-self: center;
                  }
                  > .content {
                    flex-wrap: nowrap;
                  }
                  // stylelint-enable selector-max-compound-selectors
                }
                li {
                  // Full width when icon present.
                  width: 100%;
                  margin-left: 0;
                  margin-right: 0;
                  @include quantities-all(2) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
