////
/// @group fonts
////

// stylelint-disable scss/dollar-variable-pattern
// Some of the font families have to be renamed to use the proper one once we have the web font provider setup.

///
$serif: "itc-giovanni", "ITC Giovanni Std", "Georgia", "Times New Roman", "Times", serif !default;

///
$sans: "Montserrat", "Helvetica", "Arial", sans-serif !default;

///
$font--family--regular: $serif;

///
$font--family--alternate: $sans;

///
$font--display: $sans;

///
$font--display--alt: $serif;

///
$font--text: $serif;

///
$font--text--alt: $sans;

// Font Sizes
///
$base-font-size--text: 1.125rem;

///
$base-font-size--text--alt: 1rem;

// stylelint-enable scss/dollar-variable-pattern
