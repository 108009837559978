// Testimonials list page
.cb-list-specialty--patient-stories,
.cb-list-testimonials--standard {
  .pane.cb-list-testimonials {
    .col {
      // Template overrides.
      float: none;
      margin: 0;
      width: auto;
    }
    .view-content {
      > ul {
        margin-top: 0;
        > li {
          position: relative;
        }
      }
      li:hover {
        .cb-list-testimonials__title {
          /* stylelint-disable selector-max-compound-selectors */
          a {
            text-decoration: underline;
          }
          /* stylelint-enable */
        }
      }
    }
    .cb-list-testimonials {
      &__title {
        margin-bottom: 0.5em;
        // Padding for focus outlines.
        padding: 1px;
        h3 {
          padding: 1px;
        }
      }
    }
    // base styles override fix:
    // when body field is displayed instead of summary
    .pane-node-body:not(:last-child) {
      margin-bottom: 0;
      float: none;
    }
    // with image
    .cb-layout__component-dynamic:not(.column-second-empty) {
      display: flex;
      flex-direction: column;
      .column-second {
        order: 1;
        margin-bottom: 1em;
        @include media(bp(bs)) {
          margin-bottom: 0;
        }
      }
      .column-first {
        order: 2;
      }
      .cb-overlay-link {
        // Necessary for list item overlay link
        // to not be beneath image
        z-index: z(content);
      }
      .cb-list-testimonials {
        &__image-teaser {
          @include media(bp(bs)) {
            overflow: hidden;
            position: absolute;
            top: 2rem;
            right: 0;
            // Padding for focus outlines.
            padding: 1px;
            // Necessary for list item overlay link
            // to not be beneath image
            z-index: z(contentBottom);
          }
          @include media(bp(m)) {
            top: 1.8rem;
          }
          img {
            @include media(bp(bs)) {
              width: 100%;
            }
          }
        }
        &__title {
          // fix hidden title link hover styles
          z-index: z(content);
          &::before {
            @include media(bp(bs)) {
              content: "";
              float: right;
              margin-left: 1em;
              margin-bottom: 1.25em;
            }
          }
        }
        // Setting pseudo and image element to be same size to wrap text.
        // Pseudo wraps text, image is placed over it using absolute positioning.
        // This also restricts the image content area to a 16:9 ratio.
        &__image-teaser,
        &__title::before {
          @include media(bp(bs)) {
            width: calc((100vw - 2 * #{$page-margins}) * 0.357);
            height: calc((100vw - 2 * #{$page-margins}) * 0.2);
          }
          @include media(bp(m)) {
            width: calc((100vw - 2 * #{$page-margins}) * 0.3434);
            height: calc((100vw - 2 * #{$page-margins}) * 0.1931);
          }
          @include media(bp(l)) {
            width: 210px;
            height: 118.125px;
          }
          @include media(bp(xl)) {
            width: 260px;
            height: 143px;
          }
        }
      }
    }
    // no image
    .cb-layout__component-dynamic.column-second-empty {
      .cb-list-testimonials {
        &__title {
          width: 100%;
        }
      }
    }
  }
  // base styles overrides
  .pane-node-body {
    + .pane.cb-list-testimonials {
      .view-content {
        ul,
        li:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
