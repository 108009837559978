// Featured content main content only

.cb-content-area__main {
  .panels-ipe-bundle-featured-content,
  .pane-bundle-featured-content.cb-style {
    .column-first {
      @include component-padding();
    }
    // Fix default main content link override
    a {
      background: transparent;
    }
    // Display image and text side by side
    @include media(bp(bs)) {
      .paragraphs-item-featured-content {
        &.cw-with-image {
          .cb-layout__component-dynamic {
            flex-direction: row;
          }
          .column-first {
            order: 1;
            padding-right: 0;
            @include span-columns(8);
          }
          .column-second {
            order: 2;
            margin-left: $spacing--m;
            padding: $spacing--m;
            @include span-columns(4);
          }
        }
      }
    }
    @include media(bp(s)) {
      .paragraphs-item-featured-content {
        &.cw-with-image {
          .column-second {
            padding: $spacing--l;
          }
        }
      }
    }
  }
}
