// Badges Background Styles

@include component-region-bg-styles(2,3) {
  .cb-badges {
    h2.cb-badges__title,
    .cb-badges__description p {
      color: #fff;
    }
  }
}
