// Promoted and Related Testimonials Typography Styles

// Base styles across all pages
.cb-list-testimonials--related-testimonials.cb-list-testimonials--compact,
.cb-list-testimonials--related-testimonials.cups-profile-section,
.node-type-service .cb-list-testimonials--related-testimonials,
.cb-page-content .cb-content-area__main .cb-list-testimonials--promoted,
.region .cb-list-testimonials--promoted {
  h2.pane-title {
    text-align: center;
    padding: 0 0 $spacing--s 0;
    @include component-heading(m);
    @include media(bp(xs)) {
      @include component-heading(l);
    }
    @include media(bp(m)) {
      @include component-heading(xl);
    }
    @include media(bp(l)) {
      @include component-heading(xxl);
    }
  }
  .more-link {
    width: 100%;
    margin-top: $spacing--m;
    text-align: center;
    a {
      // remove icon default left spacing because testimonials
      // more-links have a space at the end in the html markup
      @include more-link($margin-left: 0);
      @include media(bp(s)) {
        @include component-link-title(m);
      }
    }
  }
  .cb-list-testimonials {
    &__title {
      h3 {
        @include component-link-title(s);
        @include media(bp(bs)) {
          @include component-link-title(m);
        }
        @include media(bp(s)) {
          @include component-link-title(l);
        }
      }
    }
    &__summary {
      p {
        @include component-paragraph(xs);
        @include media(bp(s)) {
          @include component-paragraph(s);
        }
      }
    }
  }
}

// Homepage and Featured Layout
.region {
  .cb-list-testimonials--promoted {
    .view-content {
      li {
        @include media(bp(m)) {
          @include quantities-all(2,4) {
            .cb-list-testimonials {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(xs);
                }
              }
            }
          }
          @include quantities-all(1,3) {
            .cb-list-testimonials {
              &__title {
                h3 {
                  @include component-link-title(xl);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(m);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .cb-list-testimonials--promoted {
    .view-content {
      li {
        @include media(bp(xl)) {
          @include quantities-all(3) {
            .cb-list-testimonials {
              &__title {
                h3 {
                  @include component-link-title(s);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(xs);
                }
              }
            }
          }
          @include quantities-all(2,4) {
            .cb-list-testimonials {
              &__title {
                h3 {
                  @include component-link-title(l);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(s);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Main content only
.cb-layout--aside-first {
  .cb-content-area__main {
    .cb-list-testimonials--promoted {
      @include media(bp(l)) {
        .cb-list-testimonials {
          &__title {
            h3 {
              @include component-link-title(xl);
            }
          }
          &__summary {
            p {
              @include component-paragraph(m);
            }
          }
        }
      }
    }
  }
}

.cb-layout--aside-first-last {
  .cb-content-area__main {
    .cb-list-testimonials--promoted {
      @include media(bp(l)) {
        h2.pane-title {
          @include component-heading(xl);
        }
        .cb-list-testimonials {
          &__title {
            h3 {
              @include component-link-title(l);
            }
          }
          &__summary {
            p {
              @include component-paragraph(s);
            }
          }
        }
      }
    }
  }
}
