// Site header Layout
.scroll-lock {
  .cb-site-header {
    // neccessary to fix hidden mobile nav links
    overflow: visible;
  }
}

.cb-site-header {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  .cb-nameplate {
    .cb-utility-menu,
    .cb-primary-menu,
    &__compact {
      .cb-search {
        width: 22px;
        display: inline-block;
        line-height: 1.5;
        @include icon($icon: "search", $text-replace: true, $size: 1.9vw);
        @include media(bp(xl)) {
          &::before {
            font-size: 1.275rem;
          }
        }
        &:focus {
          outline: none;
          &::after {
            width: 24px;
            height: 100%;
            content: '';
            border: 1px dotted #000;
            display: inline-block;
            right: -10000px;
            top: -1px;
            position: absolute;
            // to make search focus outline color work with different
            // site headers such as the blue CUIMC header
            border-color: inherit;
          }
        }
        &:hover {
          text-decoration: none;
          &::before {
            font-size: 2.1vw;
            @include media(bp(xl)) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
  .cb-utility-menu {
    @include media(bp(xl)) {
      font-size: 1.3vw;
      line-height: 1.75;
      a.active-trail {
        border-bottom: 2px solid tint(color(neutral-brand, xhi), 25%);
        padding-bottom: 0.175em;
      }
      // Outlined btn style option for utility links
      .cb-menu-item--featured > a,
      .cb-menu-item--featured > a.active-trail {
        @include btn($style: outline, $size: s);
        @include override-nested() {
          border-color: $nav-secondary-link-color;
          color: $nav-secondary-link-color;
          background: transparent;
          padding: 0.3em 0.7em;
          font-size: 1.3vw;
        }
        @include media(bp(xl)) {
          font-size: 1rem;
        }
        &:hover {
          background-color: $nav-secondary-btn-hover-bg-color;
          color: $nav-secondary-btn-hover-font-color;
        }
        .cb-flavor--nameplate-primary & {
          color: #fff;
          border-color: #fff;
        }
      }
    }
    @include media(bp(xl)) {
      font-size: 1rem;
    }
    // No javascript fallback for mobile.
    .no-js & {
      @include media(max-width (bp(xl) - 0.06em)) {
        ul {
          margin: 0;
          margin-top: 0.5rem;
          padding: 0;
          list-style: none;
          font-size: 0.9em;
          @include clearfix();
        }
        li {
          padding: 0 $page-margins;
          padding-bottom: 0.25em;
          &:last-child {
            padding-bottom: 0;
          }
          a {
            color: $nav-primary-link-color;
            &.active-trail {
              &::before {
                content: '';
                border-left: 0.5em solid $nav-primary-hover-border-color;
                margin-right: 0.25em;
                display: inline-block;
                vertical-align: text-bottom;
                height: 1em;
              }
            }
          }
        }
      }
    }
  }
  .cb-global-menu {
    // mobile
    @include media(max-width (bp(xl) - 0.001em)) {
      padding: 1em 1.4rem;
    }
    &__links {
      li {
        padding: 0;
        a:not(.active-trail) {
          background: $background-solid-light;
        }
        &:first-child {
          a {
            border-radius: 4px 4px 0 0;
            border-top-width: 1px;
          }
        }
        &:last-child {
          a {
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }
    &__link {
      padding: 0.5em 0.65em;
      margin: 0;
      display: block;
      border: 1px solid color(primary, brand);
      color: color(neutral, xlo);
      border-width: 0 1px 1px 1px;
      font-size: 1rem;
      line-height: 1.3;
      @include media(bp(xl)) {
        padding: 0.5em 0.75em;
        border: 1px solid color(neutral-brand);
      }
      &--active {
        border-color: color(primary, lo);
        color: color(primary, lo);
        background: lighten(color(secondary, xhi), 17%);
        &::before {
          content: "\25CF \00a0";
        }
      }
    }
    @include media(bp(xl)) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      max-width: $page-container-max-width;
      margin: 0 auto;
      nav {
        float: right;
      }
      &__links {
        margin: 0;
        @include clearfix();
        li {
          display: block;
          float: left;
          &:first-child {
            a {
              border-radius: 0 0 0 4px;
              border-left-width: 1px;
              border-top-width: 0;
            }
          }
          &:last-child {
            a {
              border-radius: 0 0 4px 0;
            }
          }
        }
      }
      &__link {
        border-width: 0 1px 1px 0;
        font-weight: 400;
        font-size: 0.9rem;
        background: $background-solid-light;
        box-shadow: $box-shadow;
        &:hover {
          text-decoration: none;
          &:not(.active-trail) {
            background: #fff;
            color: color(neutral, xlo);
          }
        }
        &--active {
          border-color: color(primary, lo);
          color: color(primary, lo);
          background: lighten(color(secondary, xhi), 17%);
          &::before {
            display: none;
          }
        }
      }
      // Adjust spacing between global and site menus.
      + .pane-cola-panels-local-header {
        .cb-utility-menu {
          margin-top: 1.5em;
        }
        .cb-primary-menu {
          margin-top: 1.25em;
        }
      }
      // Adjust spacing for minimized primary menu on subsites.
      + .cb-nameplate__compact {
        padding-top: 2.5rem;
      }
    }
  }
}

.cb-header-logo {
  // default logo styles
  &__site-name {
    @include media(bp(s)) {
      font-size: 1.4rem;
    }
    @include media(bp(m)) {
      margin: 0;
      padding: 0;
    }
    @include media(min-width bp(m) max-width (bp(xl) - 0.06em)) {
      max-width: 100%;
      width: auto;
    }
    @include media(bp(xl)) {
      max-width: 100%;
    }
  }
  &__site-link {
    &:focus {
      outline-color: best-contrast($header-nameplate-background-color, color(primary, brand), #fff);
      .cb-flavor--nameplate-primary & {
        outline-color: #fff;
      }
    }
  }
  &--mobile-desktop {
    .cb-header-logo__site-name {
      max-width: 100%;
    }
  }
}

// Featured link styles for utility nav in mobile menu.
.cb-mobile-menu {
  .cb-secondary-links {
    > .cb-menu-item--featured > a {
      font-size: 1.125rem;
      font-style: italic;
      font-weight: 700;
    }
  }
}
