// CTA list styles typography ("not:only child" in a region)

.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--1.pane:not(:only-child) &,
    &.cb-style--1.pane:not(:only-child) {
      .paragraphs-item-cta:not(.cw-cta-phone) {
        &:not(.cw-with-title) {
          .field-name-field-cta-link a {
            @include component-link-title(m);
            line-height: 1.3;
            color: #fff;
          }
        }
        // btn styles
        &.cw-with-title {
          /* stylelint-disable selector-max-compound-selectors */
          .field-name-field-cta-link {
            a {
              line-height: 1.3;
              margin-bottom: 0;
              @include btn($style: outline, $size: s, $dark: true);
            }
          }
          &:not(.cw-with-description) {
            .field-name-field-cta-link a {
              margin-top: $spacing--xs;
            }
          }
          /* stylelint-enable */
        }
        .field-name-field-icon {
          // Ensure the icon is not cropped on smaller screens.
          min-height: calc(50px + 1rem);
        }
      }
      @include media(bp(xs)) {
        .paragraphs-item-cta:not(.cw-cta-phone) {
          .field-name-field-cta-link {
            padding-bottom: $spacing--xl;
          }
          &:not(.cw-with-title) {
            .field-name-field-cta-link a {
              @include component-link-title(m);
            }
          }
        }
      }
      @include media(bp(bs)) {
        @include quantities-all(even) {
          .paragraphs-item-cta:not(.cw-cta-phone) {
            .field-name-field-cta-link {
              padding-bottom: $spacing--m;
            }
            &:not(.cw-with-title) {
              .field-name-field-cta-link a {
                @include component-link-title(s);
              }
            }
          }
        }
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-cta-phone) {
              &:not(.cw-with-title) {
                .field-name-field-cta-link a {
                  @include component-link-title(m);
                }
              }
            }
          }
        }
      }
      @include media(bp(s)) {
        @include quantities-all(even) {
          .paragraphs-item-cta:not(.cw-cta-phone) {
            .field-name-field-cta-link {
              padding-bottom: $spacing--l;
            }
            &:not(.cw-with-title) {
              .field-name-field-cta-link a {
                @include component-link-title(m);
              }
            }
          }
        }
      }
      @include media(bp(m)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-cta-phone) {
              .field-name-field-cta-link {
                padding-bottom: $spacing--m;
              }
              &:not(.cw-with-title) {
                .field-name-field-cta-link a {
                  @include component-link-title(xs);
                }
              }
            }
          }
        }
        @include quantities-all(even) {
          .paragraphs-item-cta:not(.cw-cta-phone) {
            &:not(.cw-with-title) {
              .field-name-field-cta-link a {
                @include component-link-title(m);
              }
            }
          }
        }
      }
      @include media(bp(l)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-cta-phone) {
              &:not(.cw-with-title) {
                .field-name-field-cta-link a {
                  @include component-link-title(m);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    &.cb-max-columns--4 {
      .pane-bundle-cta,
      .panels-ipe-bundle-cta {
        .cb-style--1.pane:not(:only-child) &,
        &.cb-style--1.pane:not(:only-child) {
          @include media(bp(l)) {
            // 4+ items: fixed 1/4 page width for each
            .paragraphs-item-cta:not(.cw-cta-phone) {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &:not(.cw-with-title) {
                .field-name-field-cta-link a {
                  @include component-link-title(m);
                }
              }
              &.cw-with-title {
                .field-name-field-cta-link {
                  a {
                    @include component-link-title(xxs);
                    line-height: 1.3;
                  }
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
    &.cb-max-columns--3,
    &.cb-max-columns--4 {
      .pane-bundle-cta,
      .panels-ipe-bundle-cta {
        .cb-style--1.pane:not(:only-child) &,
        &.cb-style--1.pane:not(:only-child) {
          @include media(bp(m)) {
            @include quantities-all(2) {
              .paragraphs-item-cta:not(.cw-cta-phone) {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                &:not(.cw-with-title) {
                  .field-name-field-cta-link a {
                    @include component-link-title(l);
                  }
                }
                /* stylelint-enable */
              }
            }
          }
          @include media(bp(l)) {
            @include quantities-all(2) {
              .paragraphs-item-cta:not(.cw-cta-phone) {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                &:not(.cw-with-title) {
                  .field-name-field-cta-link a {
                    @include component-link-title(xl);
                  }
                }
                /* stylelint-enable */
              }
            }
            @include quantities-all(3) {
              .paragraphs-item-cta:not(.cw-cta-phone) {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                &:not(.cw-with-title) {
                  .field-name-field-cta-link a {
                    @include component-link-title(m);
                  }
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .pane-bundle-cta,
        .panels-ipe-bundle-cta {
          .cb-style--1.pane:not(:only-child) &,
          &.cb-style--1.pane:not(:only-child) {
            @include media(bp(l)) {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              .paragraphs-item-cta:not(.cw-cta-phone) {
                .field-name-field-cta-link {
                  padding-bottom: $spacing--s;
                }
              }
              @include quantities-all(2,4) {
                .paragraphs-item-cta:not(.cw-cta-phone) {
                  // CTA with link only
                  &:not(.cw-with-title) {
                    .field-name-field-cta-link a {
                      @include component-link-title(l);
                    }
                  }
                }
              }
              // Six or more components in a region:
              // always "1/3" component width
              &:first-child:nth-last-child(n+6),
              &:nth-child(2):nth-last-child(n+5),
              &:nth-child(3):nth-last-child(n+4),
              &:nth-child(4):nth-last-child(n+3),
              &:nth-child(5):nth-last-child(n+2),
              &:nth-child(n+6) {
                .paragraphs-item-cta:not(.cw-cta-phone) {
                  // CTA with link only
                  &:not(.cw-with-title) {
                    .field-name-field-cta-link a {
                      @include component-link-title(m);
                    }
                  }
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}
