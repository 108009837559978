// Labs and Unit by Type list component
// Styles depending on number of list items

.region,
.cb-page-content .cb-content-area__main {
  .cb-list-units--component,
  .cb-list-labs--component {
    .view-content {
      li {
        &:not(:last-child) {
          margin-bottom: $spacing--s;
        }
        &:only-child {
          margin: 0 auto;
        }
      }
    }
    @include media(bp(bs)) {
      .view-content {
        > ul {
          display: flex;
          flex-wrap: wrap;
        }
        li {
          @include quantities-all(2,4) {
            // display 2 side-by-side per row
            width: 49.5%;
          }
          @include quantity-positions(2,1) {
            margin-bottom: 0;
          }
          @include quantity-positions(2,1) {
            margin-right: 0.5%;
          }
          @include quantity-positions(2,2) {
            margin-left: 0.5%;
          }
          @include quantity-positions(4,1,3) {
            margin-right: 0.5%;
          }
          @include quantity-positions(4,2) {
            clear: both;
          }
          @include quantity-positions(4,3,4) {
            margin-top: $spacing--s;
          }
          @include quantity-positions(4,3) {
            margin-bottom: 0;
          }
          // display image and text side by side
          @include quantities-all(1,3) {
            /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
            .cb-layout__component-dynamic {
              position: relative;
            }
            .column-first {
              width: 100%;
              margin-right: 0;
              // with image
              &:not(:only-child) {
                .cb-list-units,
                .cb-list-labs {
                  &__title {
                    .field-name-title-field {
                      padding-right: 170px;
                      display: flex;
                      align-items: center;
                      height: 90px;
                    }
                  }
                }
              }
            }
            // adjust image ratio to fit within container
            .column-second {
              height: 90px;
              border-top: none;
              right: 0;
              position: absolute;
              top: 0;
              width: 160px;
              float: left;
              display: block;
              overflow: hidden;
              img {
                position: absolute;
                top: 0;
                right: 0;
                margin: auto;
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
    @include media(bp(m)) {
      .view-content {
        li {
          @include quantities-all(1) {
            /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
            .column-second {
              height: 140px;
              width: 248.89px;
            }
            // with image
            .column-first:not(:only-child) {
              .cb-list-labs,
              .cb-list-units {
                &__title {
                  .field-name-title-field {
                    height: 140px;
                    padding-right: 260px;
                  }
                }
              }
            }
            /* stylelint-enable */
          }
          @include quantities-all(3) {
            // 3 columns side-by-side per row
            width: 32.6%;
            float: left;
            // display image and text stacked
            .column-second {
              position: relative;
              width: 100%;
              height: auto;
              /* stylelint-disable selector-max-compound-selectors */
              img {
                max-width: 100%;
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                right: auto;
                width: 100%;
                height: auto;
              }
              /* stylelint-enable */
            }
            .cb-list-units,
            .cb-list-labs {
              /* stylelint-disable selector-max-compound-selectors */
              &__title {
                .field-name-title-field {
                  padding: 0.5rem 1rem;
                  min-height: auto;
                  height: auto;
                }
              }
              &__summary {
                padding: 0.4rem 1rem 0.8rem 1rem;
              }
              /* stylelint-enable */
            }
            .column-first {
              &:only-child,
              &:not(:only-child) {
                .cb-list-units,
                .cb-list-labs {
                  /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                  &__title {
                    .field-name-title-field {
                      padding-right: $spacing--m;
                      min-height: auto;
                      height: auto;
                    }
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
          @include quantity-positions(3,1,2) {
            margin-bottom: 0;
            margin-right: 0.5%;
          }
          @include quantity-positions(3,2,3) {
            margin-left: 0.5%;
          }
        }
      }
    }
    @include media(bp(l)) {
      @include quantities-all(1) {
        .view-content {
          li {
            max-width: $component-content-max-width;
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-units--component,
    .cb-list-labs--component {
      @include media(bp(l)) {
        .view-content {
          li {
            @include quantities-all(4) {
              // 4 columns side-by-side
              width: 24.2%;
              float: left;
            }
            @include quantity-positions(4,1,2,3) {
              margin-bottom: 0;
              margin-right: 0.5%;
            }
            @include quantity-positions(4,2,3,4) {
              margin-left: 0.5%;
            }
            @include quantity-positions(4,3,4) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
