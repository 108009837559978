.cb-primary-navigation,
.cb-subsection-navigation {
  @include media(bp(xl)) {
    .cb-primary-links > li {
      > a {
        font-weight: 600;
        border-bottom: 6px solid transparent;
        &:focus,
        &.active-trail {
          text-decoration: none;
          border-bottom: 6px solid $nav-primary-hover-border-color;
          .cb-flavor--nav-inverted & {
            border-bottom-color: #fff;
          }
        }
        &:hover::after {
          transform: scale(1,1);
        }
        &::after {
          content: '';
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 6px;
          background-color: $nav-primary-hover-border-color;
          transform: scale(0,1);
          transition: transform 0.35s ease;
          .cb-flavor--nav-inverted & {
            background-color: #fff;
          }
        }
      }
      .ajax-progress {
        @include visually-hidden();
      }
    }
  }
  // Mobile menu is hidden on initial page load
  // because js loads afterwards along with the non-critical css
  &.cb-container {
    .js & {
      display: block;
    }
  }
  // No javascript fallback for mobile.
  .no-js & {
    @include media(max-width (bp(xl) - 0.06em)) {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        @include clearfix();
      }
      li {
        padding: 0 $page-margins;
        padding-bottom: 0.5em;
        a {
          color: $nav-primary-link-color;
          font-weight: 700;
          &.active-trail {
            &::before {
              content: '';
              border-left: 0.5em solid $nav-primary-hover-border-color;
              margin-right: 0.25em;
              border-right: none;
              display: inline-block;
              vertical-align: text-bottom;
              height: 1em;
            }
          }
        }
      }
    }
  }
}
