// CTA with icon Full Width Typography Styles (Only Child in a Region)
.region {
  &.cb-max-columns {
    .pane-bundle-cta.cb-style:only-child,
    .panels-ipe-bundle-cta:only-child {
      .paragraphs-item-cta.cw-with-icon {
        padding-left: 0;
        padding-right: 0;
        .field-name-field-icon {
          order: -1;
          width: 6.25em;
          height: 6.25em;
          position: relative;
          background: transparentize($background-solid-light, 0.8);
          margin-bottom: 0;
          margin-left: auto;
          margin-right: auto;
          .cb-icon {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 50%;
            transform: translate(0, -50%);
            width: 100px;
            height: 100px;
          }
        }
        .field-name-field-title {
          margin-top: 0;
        }
        .field-name-field-cta-description {
          a {
            margin-bottom: 0;
          }
        }
        &.cb-position-h--left {
          .content {
            @include media(bp(l)) {
              justify-content: unset;
              min-height: 110px;
            }
          }
          .field-name-field-title,
          .field-name-field-cta-description,
          .field-name-field-cta-link {
            @include media(bp(l)) {
              padding-left: 10vw;
              flex: none;
            }
          }
          .field-name-field-title,
          .field-name-field-cta-description {
            @include media(bp(l)) {
              width: 70%;
              padding-left: 7em;
            }
            @include media(bp(xl)) {
              padding-left: 8em;
            }
          }
          .field-name-field-icon {
            @include media(bp(l)) {
              width: 100px;
              top: 0.8em;
              left: 1.2vw;
              height: auto;
              margin-bottom: 0;
              margin-left: 0;
            }
            @include media(bp(xl)) {
              top: 0.9em;
              width: 7.5em;
            }
            @include media(bp(xxl)) {
              right: #{$page-container-max-width * 0.017};
            }
          }
          // CTA with title and/or description
          &.cw-with-title.cw-with-description,
          &.cw-with-title:not(.cw-with-description) {
            .field-name-field-cta-link {
              @include media(bp(l)) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 1.5rem;
                width: 25.6%;
                margin: 0;
                text-align: right;
              }
              a {
                @include media(bp(l)) {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }
          // CTA with no description
          &.cw-with-title:not(.cw-with-description) {
            .content {
              @include media(bp(l)) {
                display: flex;
                align-items: center;
                height: 100%;
                // IE11 fix: make flex vertical center alignment work when a min-height is set
                &::after {
                  content: "";
                  min-height: inherit;
                  font-size: 0;
                }
              }
            }
            .field-name-field-icon {
              @include media(bp(l)) {
                top: 1.7em;
                width: 100px;
              }
              @include media(bp(xl)) {
                width: 120px;
                top: 1.2em;
              }
            }
            .field-name-field-title {
              @include media(bp(l)) {
                width: 100%;
                padding-left: 7em;
                padding-right: 30%;
              }
              @include media(bp(xl)) {
                padding-left: 8em;
              }
            }
          }
        }
      }
    }
  }
}

// CTA with link only
.region {
  &.cb-max-columns {
    .pane-bundle-cta.cb-style:only-child,
    .panels-ipe-bundle-cta:only-child {
      .paragraphs-item-cta.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
        display: block;
        .content {
          display: flex;
          align-items: center;
          @include media(bp(s)) {
            padding: $spacing--xl;
            max-width: $max-width;
          }
        }
        .field-name-field-cta-link {
          a {
            @include component-heading(xs);
            margin-bottom: 0;
          }
        }
        &.cb-position-h--center {
          .content {
            @include media(bp(s)) {
              max-width: 85%;
              margin: 0 auto;
            }
          }
          .field-name-field-cta-link {
            margin-bottom: 1em;
            a {
              line-height: 1.4;
              &::after {
                top: -0.1em;
                position: relative;
              }
            }
          }
        }
        &.cb-position-h--left {
          .content {
            @include media(bp(l)) {
              min-height: 100px;
            }
          }
          .field-name-field-icon {
            top: 1.1em;
            @include media(bp(bs)) {
              top: 0.8em;
            }
            @include media(bp(s)) {
              top: 1.2em;
            }
            @include media(bp(m)) {
              width: 90px;
              top: 1em;
              left: 1.2rem;
            }
            @include media(bp(l)) {
              top: 2em;
              width: 100px;
            }
            @include media(bp(xl)) {
              width: 120px;
              top: 1.3em;
            }
          }
          .field-name-field-cta-link {
            @include media(bp(m)) {
              padding-left: 6em;
            }
            @include media(bp(l)) {
              padding-left: 6.5em;
            }
            @include media(bp(xl)) {
              padding-left: 8em;
            }
            a {
              margin-bottom: 0;
              @include media(bp(l)) {
                padding-right: 2.5rem;
              }
              &::after {
                top: 0.1em;
              }
            }
          }
        }
      }
    }
  }
}
