// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing {
  .region {
    .pane {
      &.panels-ipe-video {
        /* stylelint-disable selector-max-compound-selectors */
        .panels-ipe-portlet-content {
          margin: 0;
        }
        /* stylelint-enable */
      }
    }
  }
}
