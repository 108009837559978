// Related Information Section base styles
// Section can include related services, doctors, conditions, treatments

// News Article and Testimonial Page
.pane-cola-news-related-info,
.pane-cola-testimonials-related-info {
  h2 {
    margin-bottom: 0;
    margin-top: 1.4em;
  }
  > .cb-panel-container {
    > div {
      margin-top: $spacing--xl;
    }
  }
  .cb-list-conditions--related-conditions,
  .cb-list-treatments--related-treatments,
  .cb-list-services--related-services,
  .cb-list-profiles--related-profiles.cb-list-profiles--full {
    .pane-title {
      margin-bottom: $spacing--m;
      @include related-component-heading($size: m);
    }
  }
}

// News Article Only
.pane-cola-news-related-info {
  max-width: $component-content-max-width;
  margin: 0 auto;
}
