// Badges Typography Styles

// Badges with description only
.region {
  .cb-badges.cw-with-description {
    h2 {
      @include component-heading(m);
    }
    .cb-badges__description p {
      @include component-paragraph(s);
    }
    &:only-child {
      &:not(.cw-with-title) {
        .cb-badges__description p {
          font-weight: 600;
        }
      }
    }
    @include media(bp(xs)) {
      &:nth-child(odd):only-child {
        h2 {
          @include component-heading(l);
        }
      }
    }
    @include media(bp(bs)) {
      @include quantities-all(odd) {
        h2 {
          @include component-heading(m);
        }
      }
      &:only-child {
        &:not(.cw-with-title) {
          .cb-badges__description p {
            @include component-paragraph(m);
          }
        }
      }
    }
    @include media(bp(m)) {
      &:nth-child(odd):only-child {
        h2 {
          @include component-heading(xl);
        }
        .cb-badges__description p {
          @include component-paragraph(l);
        }
        &:not(.cw-with-title) {
          .cb-badges__description p {
            @include component-paragraph(xl);
          }
        }
      }
      @include quantities-all(even) {
        h2 {
          @include component-heading(l);
        }
        .cb-badges__description p {
          @include component-paragraph(s);
        }
      }
      @include quantities-all(odd) {
        &:not(:only-child) {
          h2 {
            @include component-heading(s);
          }
          .cb-badges__description p {
            @include component-paragraph(xs);
          }
        }
      }
    }
    @include media(bp(l)) {
      &:nth-child(odd):only-child {
        h2 {
          @include component-heading(xxl);
        }
      }
    }
  }
  &.cb-max-columns--3 {
    .cb-badges.cw-with-description {
      @include media(bp(l)) {
        // 3 or more badges: fixed "1/3 page width" for each
        &:not(:only-child) {
          h2 {
            @include component-heading(m);
          }
        }
        @include quantities-all(2) {
          h2 {
            @include component-heading(l);
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    &.cb-max-columns--4 {
      .cb-badges.cw-with-description {
        // 4 or more badges
        @include media(bp(l)) {
          // 4 or more badges: fixed "1/4 page width" for each
          &:not(:only-child) {
            h2 {
              @include component-heading(s);
            }
            .cb-badges__description p {
              @include component-paragraph(xs);
            }
          }
          @include quantities-all(2) {
            h2 {
              @include component-heading(l);
            }
            .cb-badges__description p {
              @include component-paragraph(s);
            }
          }
        }
      }
    }
    &.cb-max-columns--3,
    &.cb-max-columns--4 {
      .cb-badges.cw-with-description {
        @include media(bp(l)) {
          @include quantities-all(3) {
            h2 {
              @include component-heading(l);
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .cb-badges.cw-with-description {
          @include media(bp(l)) {
            // Six or more components in a region:
            // always "1/3" component width
            &:first-child:nth-last-child(n+6),
            &:nth-child(2):nth-last-child(n+5),
            &:nth-child(3):nth-last-child(n+4),
            &:nth-child(4):nth-last-child(n+3),
            &:nth-child(5):nth-last-child(n+2),
            &:nth-child(n+6) {
              h2 {
                @include component-heading(s);
              }
            }
          }
        }
      }
    }
  }
}

// Badges with no description (component is allways full width)
.region {
  .cb-badges:not(.cw-with-description) {
    h2 {
      @include component-heading(s);
      margin-bottom: $spacing--m;
      @include media(bp(bs)) {
        @include component-heading(m);
        margin-bottom: $spacing--l;
      }
      @include media(bp(m)) {
        @include component-heading(xl);
        margin-bottom: $spacing--l;
      }
    }
  }
}
