// Badges Base Styles

.cb-badges {
  &__content {
    max-width: 47.5em;
    margin: 0 auto;
    @include media(bp(l)) {
      display: flex;
      justify-content: center;
      max-width: 100%;
    }
  }
  &__title,
  &__description {
    padding-left: $spacing--m;
    padding-right: $spacing--m;
  }
  &__title {
    text-align: center;
  }
  &__badges {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__badge {
    a {
      text-decoration: none;
      // image zoom on hover if badge is a link
      img {
        transition: all 0.2s ease-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .field-name-field-image-image {
      // needed for img zoom hover effect
      overflow: visible;
      // IE11 fix: to prevent image from stretching
      width: 100%;
      text-align: center;
    }
    img {
      max-height: 100px;
      // IE fix: to prevent image from stretching
      width: auto;
    }
  }
}
