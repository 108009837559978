.paragraphs-item-timeline {
  // Timeline title styles
  > .content {
    > h3,
    > .field-name-field-body {
      display: block;
      margin: 0 auto;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border: 3px solid color(neutral-brand, xxhi);
      position: relative;
      @include media(bp(m)) {
        width: calc(100% - 4rem);
      }
    }
    > h3 {
      background-color: color(neutral-brand, xxhi);
      text-align: center;
      @include media(bp(m)) {
        padding: 1rem;
      }
    }
    > .field-name-field-body {
      &, p {
        font-family: $font--text--alt;
        font-size: 0.9rem;
        color: color(neutral-brand, m);
        @include media(bp(m)) {
          font-size: 1rem;
        }
      }
    }
  }
  // stylelint-disable selector-class-pattern
  .view-id-cola_timelines {
    padding: 0 0  0.75rem 0;
    margin-top: 1.5em;
    background: $background-solid-xlight;
    @include media(bp(m)) {
      margin-top: 2.5em;
      padding: 0 1em;
      background: $background-solid-xlight;
    }
  }
  // stylelint-enable selector-class-pattern
  .view-content {
    > ul {
      padding: 0 0 1rem 0;
      margin: 0;
      list-style: none;
      position: relative;
      @include media(bp(m)) {
        padding: 2rem 0 1.5rem 12rem;
      }
      // Timeline line.
      &::before {
        top: 0;
        bottom: 1.5rem;
        right: 2.5rem;
        position: absolute;
        content: "";
        display: block;
        margin: 0 auto;
        width: 0;
        border-right: 3px solid color(neutral-brand, xxhi);
        @include media(bp(m)) {
          left: 10rem;
          right: auto;
          bottom: 2rem;
        }
      }
      // Line end slug.
      &::after {
        content: "";
        position: absolute;
        bottom: 1.5rem;
        right: 1rem;
        width: calc(3rem + 3px);
        border-bottom: 3px solid color(neutral-brand, xxhi);
        @include media(bp(m)) {
          bottom: 2rem;
          left: calc(8.5rem + 1px);
          right: auto;
          width: 3rem;
        }
      }
      > li {
        // Individual timeline entry
        margin-bottom: 1em;
        @include media(bp(m)) {
          margin-bottom: 2em;
        }
        &:last-child {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
  // Connect timeline to heading.
  // stylelint-disable selector-class-pattern
  .field-name-field-title + .view-id-cola_timelines,
  .field-name-field-body + .view-id-cola_timelines {
    .view-content {
      > ul {
        &::before {
          top: -1.5rem;
          @include media(bp(m)) {
            top: -2.5rem;
          }
        }
      }
    }
  }
  // stylelint-enable selector-class-pattern
  // Timeline date
  .column-first {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
    border-radius: 2px;
    color: color(primary, xlo);
    position: relative;
    margin-bottom: 0.3em;
    margin-right: 0;
    @include media(bp(m)) {
      text-align: right;
      position: absolute;
      left: 0;
      padding: 1.4em 2.25rem 1em 0;
      width: calc(10rem + 11px);
    }
    // Timeline dot indicator.
    &::before {
      position: absolute;
      content: "";
      background-color: color(secondary, hi);
      height: 20px;
      width: 20px;
      top: calc(1.75rem - 10px);
      right: calc(2.5rem - 9px);
      border-radius: 100px;
      border: 3px solid #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      z-index: 2;
      @include media(bp(m)) {
        top: 1.4em;
        right: 0;
        margin-top: 0;
      }
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 400;
      margin-top: 0;
      @include media(bp(m)) {
        font-size: 1.1rem;
        font-weight: 400;
        span {
          &:nth-last-child(2) {
            &:first-child {
              display: block;
            }
          }
          &:nth-child(3) {
            &:last-child {
              display: block;
            }
          }
        }
      }
      span {
        color: color(primary, xlo);
        &:last-child {
          margin-right: 0.5em;
          @include media(bp(m)) {
            margin-right: 0;
          }
        }
      }
    }
    // Comma added for full date display (month day, year)
    .field-name-field-timeline-entry-day {
      white-space: nowrap;
      &::after {
        content: "\002c";
        display: inline-block;
        position: relative;
      }
    }
  }
  // Timeline text and media content
  .column-second {
    border-radius: 2px;
    border: 1px solid tint(color(neutral-brand, xxhi), 30%);
    background-color: #fff;
    padding: 1.4em 1.5em 2em 1.5em;
    width: calc(100% - 2rem);
    margin: 0 0 0 1em;
    position: relative;
    @include media(bp(m)) {
      width: 100%;
      margin: 0 auto;
    }
    > * {
      max-width: 400px;
      margin: 0 auto 1em auto;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .pane-node-title-field {
    h4 {
      text-align: center;
      font-size: 1.2em;
      font-weight: 600;
      @include media(bp(m)) {
        font-size: 1.1em;
      }
    }
  }
  .pane-node-field-timeline-entry-media {
    text-align: center;
    figcaption {
      margin: 0 auto;
      p {
        font-weight: 400;
        font-size: 0.8em;
        line-height: 1.4;
        @include media(bp(m)) {
          font-size: 0.75em;
        }
      }
    }
    .paragraphs-item-image {
      h3 {
        font-size: 0.9em;
      }
      figure {
        &:not(:last-child) {
          margin-bottom: $spacing--s;
        }
      }
    }
    .paragraphs-item-video {
      position: relative;
      a {
        // play button
        @include icon($icon: "play-button", $size: 1.2em, $color: $video-home-play-btn-icon-color);
        &::before {
          z-index: z(contentTop);
          line-height: 2;
          width: 3em;
          height: $touch-size-min;
        }
        &:hover,
        &:focus {
          &::before {
            color: #fff;
          }
        }
        &::after {
          // Dark video overlay
          z-index: z(content);
          content: "";
          background-color: transparentize(shade(color(primary, xlo), 80%), 0.6);
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
        img {
          // Needed for lazyload auto size calculation.
          width: 100%;
        }
      }
    }
  }
  .pane-node-body {
    margin-top: 0.5em;
    .field-item {
      > * {
        margin-bottom: 1em;
      }
      ol, ul, dl {
        font-size: 0.9rem;
        line-height: 1.6;
        font-weight: 400;
        padding-left: 1.8em;
        @include media(bp(m)) {
          font-size: 0.8rem;
        }
      }
    }
    p {
      font-family: $font--text--alt;
      font-size: 0.9em;
      line-height: 1.6;
      font-weight: 400;
      @include media(bp(m)) {
        font-size: 0.8em;
      }
      strong {
        font-weight: 600;
      }
      a {
        font-family: $font--text--alt;
      }
    }
    blockquote {
      font-size: 1rem;
      margin: 2rem 0;
      + .cb-attribution {
        margin-top: -1rem;
      }
    }
  }
}

// Fix base style overrides
.cb-structured-content {
  .paragraphs-item-timeline {
    .pane-node-title-field {
      h4:first-child {
        margin-top: 0;
      }
    }
  }
}
