.cb-list-specialty--conditions-treatments,
.cb-list-specialty--conditions,
.cb-list-specialty--treatments {
  .cb-list-treatments--related-treatments,
  .cb-list-conditions--related-conditions {
    .show-more {
      margin: $spacing--s 0 0 0;
      width: 100%;
    }
  }
}
