// Base Gateway Typography Styles

// Standardizes component default font sizes
.region {
  .cb-gateway {
    h2.cb-gateway__title {
      @include component-heading(s);
    }
    .field-name-field-gateway-references a {
      @include component-link-title(xs);
    }
    @include media(bp(bs)) {
      @include quantities-all(odd) {
        h2.cb-gateway__title {
          @include component-heading(m);
        }
      }
    }
    @include media(bp(m)) {
      &:nth-child(odd):only-child {
        h2.cb-gateway__title {
          @include component-heading(xl);
        }
        .field-name-field-gateway-references a {
          @include component-link-title(s);
        }
      }
      @include quantities-all(even) {
        h2.cb-gateway__title {
          @include component-heading(l);
        }
        .field-name-field-gateway-references a {
          @include component-link-title(s);
        }
      }
      @include quantities-all(odd) {
        &:not(:only-child) {
          h2.cb-gateway__title {
            @include component-heading(s);
          }
        }
      }
    }
  }
  &.cb-max-columns--3 {
    .cb-gateway {
      @include media(bp(l)) {
        // 3+ items: fixed 1/3 page width for each
        &:not(:only-child) {
          h2.cb-gateway__title {
            @include component-heading(m);
          }
        }
        @include quantities-all(2) {
          h2.cb-gateway__title {
            @include component-heading(l);
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    &.cb-max-columns--4 {
      .cb-gateway {
        @include media(bp(l)) {
          // 4+ items: fixed 1/4 page width for each
          &:not(:only-child) {
            h2.cb-gateway__title {
              @include component-heading(s);
            }
            .field-name-field-gateway-references a {
              @include component-link-title(xs);
            }
          }
          @include quantities-all(2) {
            h2.cb-gateway__title {
              @include component-heading(l);
            }
            .field-name-field-gateway-references a {
              @include component-link-title(s);
            }
          }
        }
      }
    }
    &.cb-max-columns--3,
    &.cb-max-columns--4 {
      .cb-gateway {
        @include media(bp(l)) {
          @include quantities-all(3) {
            h2.cb-gateway__title {
              @include component-heading(m);
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .cb-gateway {
          @include media(bp(l)) {
            // Six or more components in a region:
            // always "1/3" component width
            &:first-child:nth-last-child(n+6),
            &:nth-child(2):nth-last-child(n+5),
            &:nth-child(3):nth-last-child(n+4),
            &:nth-child(4):nth-last-child(n+3),
            &:nth-child(5):nth-last-child(n+2),
            &:nth-child(n+6) {
              h2.cb-gateway__title {
                @include component-heading(s);
              }
            }
          }
        }
      }
    }
  }
}

// Component specific typography Styles
.cb-gateway {
  .cb-gateway {
    &__content {
      .field-name-field-gateway-references {
        padding-top: 1.5em;
        padding-left: 0;
        margin: 0.8rem 0 0 0;
        list-style: none;
        @include media(bp(l)) {
          padding-top: 1em;
        }
      }
    }
    &__title {
      margin-bottom: 0.8em;
      font-family: $font--text--alt;
      padding-bottom: 0;
      font-weight: 600;
      position: relative;
      &::after {
        content: "";
        font-size: 1rem;
        width: 4em;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: -1rem;
      }
    }
  }
}
