// Base CTA with icon styles

.region {
  &.cb-max-columns {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      .field-name-field-icon {
        .cb-icon {
          width: 50px;
          height: 50px;
          transition: all 0.2s ease-out;
          display: inline-block;
        }
        svg {
          width: 100%;
          height: auto;
          fill: currentColor;
        }
      }
    }
  }
}

// CTA with link only
.region {
  &.cb-max-columns {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      .paragraphs-item-cta.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
        display: block;
        padding-top: 0;
        padding-bottom: 0;
        .content {
          display: flex;
          align-items: center;
          padding: $spacing--l $spacing--l 1.7rem $spacing--l;
          @include media(bp(s)) {
            padding: $spacing--xl;
            max-width: $max-width;
          }
        }
        .field-name-field-cta-link {
          a {
            @include component-heading(xs);
            @include icon($icon: "caret-right", $pseudo: "after", $size: 1em, $color: #fff);
          }
          // hide arrow icon for links with ext link icon
          a[data-extlink] {
            &::after {
              display: none;
            }
          }
        }
        &:hover {
          .field-name-field-cta-link a {
            @media (hover: hover) {
              &::after {
                @include animated($duration: 1s, $name: wobble);
              }
            }
          }
        }
        &.cb-position-h--left {
          @include media(bp(m)) {
            padding: 0;
          }
          .content {
            @include media(bp(m)) {
              min-height: 60px;
            }
            // IE11 fix: make flex vertical center alignment work when a min-height is set
            &::after {
              content: "";
              min-height: inherit;
              font-size: 0;
            }
          }
          .field-name-field-icon {
            width: 2em;
            left: 1.3rem;
            top: 1.2em;
            @include media(bp(bs)) {
              width: 3em;
              top: 1em;
            }
            @include media(bp(s)) {
              top: 1.2em;
              width: 3.5em;
            }
            @include media(bp(m)) {
              left: 3.6vw;
              top: 1.5em;
            }
          }
          .field-name-field-cta-link {
            width: 100%;
            padding-left: 2.5em;
            @include media(bp(bs)) {
              padding-left: 3.5em;
            }
            @include media(bp(s)) {
              padding-left: 4em;
            }
            @include media(bp(m)) {
              padding-left: 10vw;
            }
            a {
              margin-top: 0;
              padding-right: 1.3em;
              text-align: left;
              &::after {
                position: absolute;
                right: 0;
                top: 0.45em;
              }
              @include media(bp(bs)) {
                padding-right: 1.4em;
              }
              @include media(bp(s)) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// left positioned CTA
.region {
  &.cb-max-columns {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      .paragraphs-item-cta.cw-with-icon.cb-position-h--left {
        padding-top: 0;
        padding-bottom: 0;
        .content {
          display: block;
          position: relative;
          padding: $spacing--m;
          @include media(bp(s)) {
            padding: $spacing--l;
          }
        }
      }
      &:only-child,
      &:not(:only-child) {
        .paragraphs-item-cta.cw-with-icon.cb-position-h--left {
          .field-name-field-cta-link {
            padding-bottom: 0;
            a {
              width: 100%;
              text-align: center;
              @include media(bp(m)) {
                width: auto;
              }
            }
          }
          .field-name-field-title {
            padding-top: 0;
          }
          .field-name-field-icon {
            position: absolute;
            width: 9vw;
            height: auto;
            top: 0.9em;
            padding: 0;
            @include media(bp(bs)) {
              width: 10vw;
              top: 0.7em;
            }
            @include media(bp(s)) {
              top: 1em;
            }
            .cb-icon {
              width: 100%;
              transform: none;
            }
          }
          .field-name-field-title,
          .field-name-field-cta-description,
          .field-name-field-cta-link {
            padding-left: 11vw;
            padding-right: 0;
            margin: 0;
            width: 100%;
            @include media(bp(bs)) {
              padding-left: 12.5vw;
            }
          }
        }
      }
    }
  }
}

// Feature rows icon backgrounds.
// stylelint-disable selector-max-compound-selectors
.front,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows .cb-content-area__feature-rows,
.cb-layout--aside-first.cb-layout--dynamic-features-asides-feature-rows  .cb-content-area__feature-rows,
.cb-layout--aside-first-last.cb-layout--dynamic-features-asides-feature-rows  .cb-content-area__feature-rows,
.cb-content-area__feature-rows {
  .region {
    &.cb-background {
      .pane-bundle-cta.cb-style:only-child,
      .panels-ipe-bundle-cta:only-child {
        .paragraphs-item-cta.cw-with-icon {
          .field-name-field-icon {
            background: transparent;
          }
        }
      }
    }
  }
}
// stylelint-enable selector-max-compound-selectors
