.pane-paragraph-panes.pane-image:not(:last-child) {
  .cb-content-area__main & {
    margin-bottom: 2.1875em;
    @include media(bp(l)) {
      margin-bottom: 2.8125em;
    }
  }
}

.panels-ipe:not(.panels-ipe-editing) {
  .cb-content-area__main {
    .pane-paragraph-panes.pane-image {
      margin-bottom: 2.1875em;
      @include media(bp(l)) {
        margin-bottom: 2.8125em;
      }
    }
  }
}

// Structured content image type
.paragraphs-item-image {
  img {
    // Needed for lazyload auto size calculation.
    width: 100%;
  }
  .field-name-field-image-caption {
    p {
      font-size: 0.9em;
      font-family: $font--text--alt;
      color: color(neutral-brand, m);
      padding: 0.5em 0;
      text-align: left;
      display: block;
      margin: 0 auto;
      margin-bottom: -0.5rem;
    }
  }
}
