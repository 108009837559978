// rss feed
.cb-feed {
  font-size: 0.9em;
  padding: 0.5em;
  background: $background-solid-light;
  @include media(bp(s)) {
    padding: 0;
    background: transparent;
  }
  @include media(bp(m)) {
    margin-top: 0;
  }
  &:not(:last-child) {
    .cb-content-area__main & {
      margin-bottom: $spacing--s;
    }
    .cb-list-news--blog & {
      margin-bottom: 1em;
      text-align: right;
    }
  }
  &__content {
    @include media(bp(s)) {
      line-height: 1;
    }
  }
  &__link {
    .cb-content-area__main & {
      color: a11yrize($text-link-color, $background-solid-light);
      text-decoration: none;
      font-weight: 600;
      @include media(bp(s)) {
        color: $text-link-color;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &--rss {
      @include icon($icon: rss, $color: color(primary, lo)) {
        margin-right: 0.25em;
      }
      &:hover::before {
        text-decoration: none;
      }
    }
  }
  .cb-list-news__menu + & {
    @include media(bp(m)) {
      margin-top: 1em;
      margin-bottom: 0;
    }
  }
}

.cb-list-news--with-topics:not(.cb-list-news--with-search) {
  .cb-news-browse-topics--compact + .cb-feed {
    margin-top: 1em;
  }
}

.cb-list-news--with-search:not(.cb-list-news--with-topics) {
  .cb-news-simple-search + .cb-feed {
    margin-top: 1em;
  }
}
