// Structured content contact information component only

.cb-structured-content {
  .paragraphs-items {
    .cb-contact-information {
      &.cw-with-map {
        > .content {
          @include media($bp-only-m) {
            min-height: calc(36vw + 1px);
            padding-left: calc(45vw + 1.25em);
          }
          @include media($bp-only-l) {
            min-height: calc(25.5vw + 1px);
            padding-left: calc(31vw + 1.25em);
          }
        }
      }
      &__map {
        @include media($bp-only-m) {
          left: 1em;
          top: 1em;
          width: 43vw;
        }
        @include media($bp-only-l) {
          left: 1em;
          top: 1em;
          width: 30vw;
        }
      }
      &:not(.cw-with-map) {
        // fix hidden focus outlines
        overflow: inherit;
      }
    }
  }
  &:not(:first-child) {
    .cb-content-area__main & {
      .paragraphs-item-contact-information {
        margin-top: 2em;
      }
    }
  }
}
