.cb-news-browse-topics--full {
  > div {
    margin-top: 2em;
    &:first-child {
      @include media(bp(m)) {
        margin-top: 0;
      }
    }
    h2 {
      margin-bottom: 1em;
    }
    > ul.menu {
      @include media(bp(s)) {
        column-count: 2;
      }
      // Main topic
      > li {
        padding: 0;
        line-height: 1.8;
        @include media(bp(s)) {
          break-inside: avoid;
          page-break-inside: avoid;
        }
        &:not(:last-child) {
          @include media(bp(s)) {
            border-bottom: none;
          }
        }
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  // Sup topics
  ul ul {
    margin-top: 0.35rem;
    padding-left: 1.2em;
    list-style-type: disc;
    width: 100%;
    li {
      line-height: 2.1;
      font-size: 1rem;
      margin-bottom: 0;
      // Fix Safari top padding issue
      page-break-inside: avoid;
    }
    ul {
      margin-top: 0.25rem;
      list-style-type: circle;
      &:last-child {
        margin-bottom: $spacing--xs;
      }
      ul {
        list-style-type: square;
      }
    }
  }
}

// Button can be inline with title.
.cb-list-news--topics.cb-list-news--title-buttons {
  &.cb-list-news--with-search:not(.cb-list-news--with-topics) {
    &:not(.panels-ipe-editing) {
      .cb-news-simple-search {
        .cb-panel-container {
          @include media(bp(m)) {
            // The arrow indicator height minus the page title margin.
            margin-top: calc(15px - 1.8em);
            margin-bottom: 1.5rem;
          }
        }
      }
      .aside-first-empty.aside-last-empty,
      .aside-last-empty {
        .cb-news-simple-search {
          button {
            @include media(bp(m)) {
              right: 0;
            }
          }
        }
      }
    }
  }
  .cb-content-area__main {
    position: relative;
  }
}

// Button cannot be inline with title
.cb-list-news--topics:not(.cb-list-news--title-buttons) {
  .cb-list-news__menu {
    padding-top: 0;
  }
  .cb-news-simple-search {
    @include media(bp(m)) {
      margin-bottom: 1.5rem;
    }
    .cb-panel-container {
      @include media(bp(m)) {
        margin-top: 0;
        border-radius: 0 0 4px 4px;
      }
      .cb-indicator {
        display: none;
      }
    }
    button {
      @include media(bp(m)) {
        width: 100%;
        position: relative;
        &.show-hide-controller--open {
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }
}

// Fix ipe issues
.cb-list-news--topics {
  &.cb-list-news--with-search:not(.cb-list-news--with-topics) {
    &:not(.panels-ipe-editing) {
      .panels-ipe-portlet-content {
        .pane-page-title {
          margin-bottom: 1.8em;
        }
        .cb-news-simple-search {
          .cb-panel-container {
            @include media(bp(xl)) {
              margin-top: -0.5em;
            }
          }
        }
      }
      .aside-first-empty.aside-last-empty {
        .panels-ipe-portlet-content {
          .cb-news-simple-search {
            button {
              @include media(bp(xl)) {
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}
