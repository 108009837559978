// Classy panels component styles
// Prominence styles if multiple components exist in a region,
// only applicable to "non-list" components: Badges, CTA, Gateway, Featured Content

.cb-badges,
.cb-gateway,
.pane-bundle-featured-content,
.panels-ipe-bundle-featured-content,
.pane-bundle-cta,
.panels-ipe-bundle-cta {
  &.pane {
    .region.cb-padding & {
      // All components stacked
      &:not(:first-child) {
        margin-top: $component-vertical-spacing;
      }
      @include media(bp(bs)) {
        // 2 components per row for even quantities
        @include quantity-positions(even,2) {
          margin-top: 0;
        }
      }
      @include media(bp(m)) {
        // 3 components per row for odd quantities
        @include quantity-positions(odd,2,3) {
          margin-top: 0;
        }
      }
    }
    .region.cb-max-columns--3 & {
      @include media(bp(l)) {
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
    .region.cb-max-columns--4 & {
      @include media(bp(l)) {
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 0;
        }
      }
    }
  }
}

// "Landing page" (cola_base_dynamic_features_asides_feature_rows) adjustments
// Not applicable to News list pages
.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region.cb-max-columns--3,
      .region.cb-max-columns--4 {
        .cb-badges,
        .cb-gateway,
        .pane-bundle-featured-content,
        .panels-ipe-bundle-featured-content,
        .pane-bundle-cta,
        .panels-ipe-bundle-cta {
          &.pane {
            /* stylelint-disable selector-max-compound-selectors */
            &:not(:only-child) {
              // Max 3 components is displayed per row
              @include media(bp(l)) {
                margin-top: $component-vertical-spacing;
              }
              &:first-child,
              &:nth-child(2),
              &:nth-child(3) {
                @include media(bp(l)) {
                  margin-top: 0;
                }
              }
              // Total 4 components: max 2 is displayed per row
              @include quantity-positions(4,1,2) {
                @include media(bp(l)) {
                  margin-top: 0;
                }
              }
              @include quantity-positions(4,3) {
                @include media(bp(l)) {
                  margin-top: $component-vertical-spacing;
                }
              }
            }
            /* stylelint-enable selector-max-compound-selectors */
          }
        }
      }
    }
  }
}

// Default component horizontal spacing.
.front,
.cb-section-front,
.cb-content-area__feature-rows {
  .cb-badges,
  .cb-gateway,
  .pane-bundle-featured-content,
  .panels-ipe-bundle-featured-content,
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    &.pane {
      &:not(:only-child) {
        @include media(bp(l)) {
          display: flex;
          flex-direction: column;
          float: left;
        }
        @include media(bp(bs)) {
          @include quantities-all(even) {
            width: width-margins(50%);
            float: left;
          }
        }
        @include media(bp(m)) {
          @include quantities-all(odd) {
            width: width-margins(33.3333%);
            float: left;
          }
        }
      }
    }
  }
  .region.cb-max-columns--3 {
    .cb-badges,
    .cb-gateway,
    .pane-bundle-featured-content,
    .panels-ipe-bundle-featured-content,
    .pane-bundle-cta,
    .panels-ipe-bundle-cta {
      &.pane {
        @include media(bp(l)) {
          // 3+ items: fixed 1/3 page width for each
          &:not(:only-child) {
            width: width-margins(33.3333%);
            float: left;
          }
          @include quantities-all(2) {
            width: width-margins(50%);
            float: left;
          }
        }
      }
    }
  }
  .region.cb-max-columns--4 {
    .cb-badges,
    .cb-gateway,
    .pane-bundle-featured-content,
    .panels-ipe-bundle-featured-content,
    .pane-bundle-cta,
    .panels-ipe-bundle-cta {
      &.pane {
        @include media(bp(m)) {
          &:not(:only-child) {
            display: flex;
            flex-direction: column;
            float: left;
          }
        }
      }
    }
  }
}

.front,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows {
  .region.cb-max-columns--4 {
    .cb-badges,
    .cb-gateway,
    .pane-bundle-featured-content,
    .panels-ipe-bundle-featured-content,
    .pane-bundle-cta,
    .panels-ipe-bundle-cta {
      &.pane {
        @include media(bp(l)) {
          // 4+ items: fixed 1/4 page width for each
          &:not(:only-child) {
            width: width-margins(25%);
          }
          @include quantities-all(2) {
            width: width-margins(50%);
            float: left;
          }
          @include quantities-all(3) {
            width: width-margins(33.3333%);
            float: left;
          }
        }
      }
    }
  }
}

.front,
.cb-section-front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--dynamic-features-asides-feature-rows.cb-layout--no-aside {
  .region.cb-padding {
    .cb-badges,
    .cb-gateway,
    .pane-bundle-featured-content,
    .panels-ipe-bundle-featured-content,
    .pane-bundle-cta,
    .panels-ipe-bundle-cta {
      &.pane {
        &:only-child {
          width: 100%;
        }
      }
    }
    .panels-ipe-bundle-featured-content,
    .panels-ipe-bundle-cta {
      flex: 0 1 auto;
    }
  }
  // No left and right margins within regions with no side paddings
  .region.cb-padding--none {
    .cb-badges,
    .cb-gateway,
    .pane-bundle-featured-content,
    .panels-ipe-bundle-featured-content,
    .pane-bundle-cta,
    .panels-ipe-bundle-cta {
      &.pane {
        &:only-child {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .cb-badges,
    .cb-gateway,
    .pane-bundle-featured-content,
    .panels-ipe-bundle-featured-content {
      &.pane {
        &:only-child {
          padding-left: $component-spacing;
          padding-right: $component-spacing;
        }
      }
    }
    .pane-bundle-cta,
    .panels-ipe-bundle-cta,
    .pane-video,
    .panels-ipe-video {
      &.pane {
        &:only-child {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

// Add only-child component top and bottom margins on screens larger than 1680px
// in region with no padding. Temporary solution to balance layout/region
// backgrounds until full-width 'edge-to-edge' image styles are implemented
.front .cb-feature-row:not(.cb-feature-primary),
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--dynamic-features-asides-feature-rows.cb-layout--no-aside {
  .region.cb-padding--none {
    .cps-region-inner {
      .pane-bundle-cta.pane:only-child .paragraphs-item-cta.cw-with-image,
      .panels-ipe-bundle-cta.pane:only-child .paragraphs-item-cta.cw-with-image,
      .pane-video.pane:only-child,
      .panels-ipe-video.pane:only-child,
      .cb-curator.pane:only-child,
      .cb-airtable.pane:only-child {
        @include media(bp(xxl)) {
          margin-top: $swim-lanes--xl;
          margin-bottom: $swim-lanes--xl;
        }
      }
    }
  }
}

// "Landing page" (cola_base_dynamic_features_asides_feature_rows) adjustments.
// Not applicable to News list pages
.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    // Featured layout width sidebars
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region.cb-max-columns--3,
      .region.cb-max-columns--4 {
        .cb-badges,
        .cb-gateway,
        .pane-bundle-featured-content,
        .panels-ipe-bundle-featured-content,
        .pane-bundle-cta,
        .panels-ipe-bundle-cta {
          &.pane {
            @include media(bp(l)) {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &:not(:only-child) {
                width: width-margins(33.3333%);
                // Always use 2-column layout when there are 2 or 4 in same region:
                // not using "quantities-all" mixin here because it causes global component overrides
                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(4),
                &:nth-child(2):last-child,
                &:nth-child(2):nth-last-child(3),
                &:nth-child(3):nth-last-child(2),
                &:last-child:nth-child(4) {
                  width: width-margins(50%);
                }
              }
              /* stylelint-enable selector-max-compound-selectors */
            }
          }
        }
      }
    }
  }
  .cb-content-area__aside-last & {
    .cb-page-content--feature-rows & {
      .cb-badges,
      .cb-gateway,
      .pane-bundle-featured-content,
      .panels-ipe-bundle-featured-content,
      .pane-bundle-cta,
      .panels-ipe-bundle-cta {
        &:nth-last-child(n) {
          float: none;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

// Add vertical spacing between full width components
// in the same region

.cb-list-news--promoted:not(.cb-list-news--topic),
.cb-list-events--promoted,
.pane-bundle-numbers,
.panels-ipe-bundle-numbers,
.cb-list-units--component,
.cb-list-labs--component,
.pane-bundle-place,
.panels-ipe-bundle-place,
.pane-cola-search-faculty-box-simple,
.pane-cola-search-providers-box-simple,
.panels-ipe-cola-search-faculty-box-simple,
.panels-ipe-cola-search-providers-box-simple,
.cb-list-testimonials--promoted,
.pane-video,
.panels-ipe-video {
  &:not(:only-child) {
    margin-bottom: $component-vertical-spacing;
  }
}

// MIXED COMPONENTS FALLBACK SOLUTION
// Add component "width: 100%" style override if editor adds
// different components in same region (only cta, featured content,
// gateway, and badges can be displayed side-by-side)

.cb-flavor:not(.cb-list-news) {
  &.front,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--no-aside,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-last,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last {
    .region.cb-max-columns--3,
    .region.cb-max-columns--4 {
      .cb-appointments,
      .pane-cola-search-faculty-box-simple,
      .pane-cola-search-providers-box-simple,
      .cb-curator,
      .cb-airtable,
      .cb-list-units--component,
      .cb-list-labs--component,
      .cb-mychart,
      .cb-list-news--promoted:not(.cb-list-news--topic),
      .cb-list-events--promoted,
      .pane-bundle-place,
      .panels-ipe-bundle-place,
      .cb-list-testimonials--promoted,
      .pane-video,
      .panels-ipe-video {
        @include media(bp(l)) {
          &:not(:only-child) {
            width: 100%;
          }
        }
      }
    }
  }
}

// "In Numbers" component exception: Facilities site "Covid-19" page displays
// "In Numbers" side-by-side "CTA" on featured layout aside-first.
// Thus, we can't add "width: 100%" style override for this component
//  within "layout--aside-first" until we get content approval.
.cb-flavor:not(.cb-list-news) {
  &.front,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--no-aside,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-last,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last {
    .region.cb-max-columns--3,
    .region.cb-max-columns--4 {
      .pane-bundle-numbers,
      .panels-ipe-bundle-numbers {
        @include media(bp(l)) {
          &:not(:only-child) {
            width: 100%;
          }
        }
      }
    }
  }
}
