// Promoted News, Blog, Announcements Typography Styles

.cb-list-news--related-news,
.cb-flavor:not(.cb-list-news) .region .cb-list-news--promoted,
.cb-flavor:not(.cb-list-news) .cb-content-area__main .cb-list-news--promoted {
  .cb-list-news {
    &__title {
      .field-name-title-field {
        // Without this IE10 will cut off text as the display width is
        // somehow calculated as the entire component width.
        width: 100%;
      }
      a {
        color: $news-promoted-title-link-color;
      }
    }
    &__best-category {
      display: block;
      font-size: 0.75em;
      text-transform: uppercase;
      color: $news-promoted-category-color;
      padding-right: 0.75em;
      .pane-title {
        @include visually-hidden();
      }
      a {
        font-weight: 400;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__byline {
      font-size: 0.8rem;
      margin-bottom: 0;
    }
    &__date {
      font-size: 0.8rem;
      color: $news-promoted-date-color;
      font-weight: 400;
    }
    &__summary {
      @include visually-hidden();
      font-family: $font--text--alt;
    }
    &__external-source {
      color: $news-promoted-category-color;
      font-weight: 400;
      margin-top: 0.2em;
      .pane-title,
      .field-name-field-news-external-source {
        font-size: 0.875rem;
      }
    }
    &__title,
    &__best-category,
    &__external-source,
    &__summary {
      @include word-wrap($hyphens: false);
    }
  }
}
