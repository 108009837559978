.node-type-resource {
  .cb-content-area__main {
    .pane-page-title + .pane-custom p {
      font-size: 1rem;
      font-family: $font--text--alt;
    }
    .cb-resource {
      &__attendance-modes,
      &__locations,
      &__categories {
        font-size: 1rem;
        font-family: $font--text--alt;
        .pane-title {
          margin-bottom: 0;
          margin-top: 0.75rem;
        }
      }
    }
  }
}

.cb-list-resources__header {
  margin-top: 1rem;
  margin-bottom: 0;
}
