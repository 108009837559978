.paragraphs-item-persons {
  width: 100%;
  + .paragraphs-item-persons {
    margin-top: 3em;
    @include media(bp(s)) {
      margin-top: 4em;
    }
  }
  h3 {
    margin-bottom: 1em;
  }
  ul.field-name-field-persons-paragraphs {
    li:not(:first-child) {
      .paragraphs-item-person {
        padding-top: 1.75em;
        margin-top: 2em;
        border-top: solid 1px $border-light;
        + .paragraphs-item-person {
          margin-top: 3em;
        }
      }
    }
  }
}

.paragraphs-item-persons {
  ul.field{
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.paragraphs-item-person {
  .cw-with-images & {
    > .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 80px;
      position: relative;
      min-height: 55px;
      @include media(bp(s)) {
        padding-left: 110px;
        min-height: 90px;
      }
    }
  }
  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    @include media(bp(s)) {
      font-size: 1.2rem;
    }
  }
  .field,
  .entity {
    // fix IE11 text wrap
    width: 100%;
    p {
      font-family: $font--text--alt;
      font-size: 1rem;
      line-height: 1.7;
      a {
        font-family: $font--text--alt;
        &:not(.cb-button) {
          text-decoration: none;
        }
      }
    }
  }
  .cb-person__phone,
  .cb-person__email,
  .field-name-field-person-link {
    li {
      margin-bottom: 0.15em;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .field-name-field-person-link {
    margin-top: 0.5em;
  }
  .cb-person__phone + .cb-person__email {
    margin-top: 0;
  }
  .field-name-field-person-image {
    order: 1;
    margin-right: 1.2em;
    width: 60px;
    height: 60px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    @include media(bp(s)) {
      width: 90px;
      height: 90px;
    }
    img {
      // Needed for lazyload auto size calculation.
      width: 100%;
    }
  }
  .cb-person__name,
  .cb-person__positions {
    p, li {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .item-list:not(:last-child) .cb-person__positions,
  .cb-person__positions:not(:last-child) {
    // Negative margin to reduce space between full name and position.
    margin-top: -0.5em;
    margin-bottom: 0.75em;
  }
  .cb-person__positions {
    list-style: none;
    li {
      color: color(neutral-brand, m);
      line-height: 1.2;
      margin-left: 0;
      &:not(:only-child) {
        margin-top: 0.25em;
      }
    }
  }
  .field-name-field-person-bio {
    margin-top: 1em;
  }
}

// Fix base style overrides
.cb-structured-content {
  .paragraphs-item-persons {
    h4:first-child {
      margin-top: 0;
    }
  }
}
