// Promoted News, Blog, Announcements Sidebar Styles

.cb-content-area__aside-last {
  .cb-list-news--promoted {
    .cb-list-news {
      &__title {
        order: 2;
      }
      &__byline {
        order: 4;
      }
      &__best-category {
        order: 1;
        background-color: $sidebar-component-bg-color;
        color: $sidebar-component-tags-color;
        display: block;
        line-height: 1em;
        font-size: 0.75em;
        text-transform: uppercase;
        padding-right: 0.75em;
        padding-bottom: $spacing--xs;
        font-weight: 600;
        .pane-title {
          @include visually-hidden();
        }
        a {
          font-weight: 400;
        }
      }
      &__date {
        order: 5;
        color: $sidebar-component-date-color;
        font-size: 0.8rem;
        font-weight: 400;
      }
      &__summary {
        @include visually-hidden();
        font-family: $font--text--alt;
      }
      &__external-source {
        order: 3;
        font-weight: 400;
        margin-top: 0.2em;
        .pane-title,
        .field-name-field-news-external-source {
          font-size: 0.875rem;
        }
      }
    }
    .view-content {
      > ul {
        .cb-layout__component-dynamic {
          overflow: hidden;
        }
      }
    }
  }
  // Announcements only
  .cb-list-news--promoted.cb-list-news--announcements {
    .view-content {
      background: transparent;
      > ul {
        .columns-2.column--first {
          display: block;
        }
        > li {
          padding-top: $spacing--l;
          padding-bottom: $spacing--l;
          &:not(:first-child) {
            border-top: $border--s;
            border-color: $sidebar-component-border-color;
          }
        }
      }
    }
    .cb-layout__component-dynamic {
      @include media(bp(bs)) {
        flex-direction: row;
        .column-first.column--first {
          order: 1;
        }
        .column-second.column--last {
          order: 2;
          margin-left: $spacing--m;
        }
      }
    }
    .cb-list-news {
      &__byline,
      &__date {
        display: none;
      }
      &__summary {
        font-size: 0.8em;
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.cb-list-news--promoted {
          .cb-layout__component-dynamic {
            display: flex;
            flex-direction: column;
          }
          .columns-2 {
            &.column-first {
              margin-right: 0;
              width: auto;
              order: 2;
              margin-top: 0.5rem;
            }
            // image
            &.column-second {
              order: 1;
              width: 100%;
            }
          }
          .cb-list-news {
            &__best-category {
              font-weight: 600;
              font-size: 0.7em;
            }
            &__byline {
              font-size: 0.8em;
            }
          }
          .view-content {
            /* stylelint-disable selector-max-compound-selectors */
            li {
              padding-top: 1em;
              margin-bottom: $spacing--s;
              &:last-child {
                margin-bottom: 0;
              }
            }
            ul {
              .columns-2.column--first {
                display: flex;
              }
            }
            /* stylelint-enable */
          }
        }
        // Announcement only
        &.cb-list-news--promoted.cb-list-news--announcements {
          .view-content {
            /* stylelint-disable selector-max-compound-selectors */
            li {
              margin-bottom: 0;
              padding-bottom: $spacing--s;
            }
            .columns-2.column-second {
              margin-top: 0;
              margin-left: 0;
              margin-bottom: $spacing--s;
              order: 1;
            }
            .columns-2.column--first {
              order: 2;
              margin: 0;
              // fix IE10/11 text overflow
              display: block;
              max-width: 100%;
              height: 100%;
            }
            /* stylelint-enable */
          }
          .cb-list-news {
            &__summary {
              font-size: 0.65rem;
            }
            &__image-teaser {
              width: auto;
              height: 100%;
            }
            &__title {
              /* stylelint-disable selector-max-compound-selectors */
              h3 a {
                overflow: auto;
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}
