// Base Styles shared between all news top stories component
.cb-list-news--top-stories {
  .cb-list-news {
    &__best-category {
      text-transform: uppercase;
      margin-bottom: 0.2em;
      position: static;
      background-color: transparent;
    }
    &__best-category {
      color: color(neutral-brand, m);
      a {
        color: color(action, m);
        font-weight: 400;
      }
    }
    &__title {
      h3 {
        @include media(bp(m)) {
          line-height: 1.1;
        }
        a {
          line-height: 1.4;
          font-weight: 600;
          // Padding for focus outlines.
          padding: 1px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__summary {
      p {
        line-height: 1.6;
        font-family: $font--text--alt;
        @include media(bp(m)) {
          line-height: 1.5;
        }
      }
    }
    &__title,
    &__best-category,
    &__external-source,
    &__summary {
      @include word-wrap($hyphens: false);
    }
  }
  .view-content {
    border-bottom: $border--s;
  }
  ul:not(.pager):not(.panels-ipe-linkbar) {
    li {
      .cb-layout__component-dynamic {
        // fix hidden focus outlines
        overflow: inherit;
      }
    }
  }
  .cb-content-area__main & {
    h3 {
      a {
        font-weight: 600;
      }
    }
  }
}

// Main top story
/* stylelint-disable selector-max-compound-selectors */
// disabling max selectors since we have to exclude ipe
.cb-list-news--top-stories {
  ul:not(.panels-ipe-linkbar) {
    li {
      &:first-child {
        &:only-child,
        &:nth-last-child(4),
        &:nth-last-child(3) {
          h3 {
            a {
              font-size: 1.45rem;
              @include media(bp(bs)) {
                font-size: 1.6rem;
              }
              @include media(bp(m)) {
                font-size: 1.35rem;
                line-height: 1.3;
              }
              @include media(bp(xl)) {
                font-size: 1.5rem;
              }
            }
          }
          .cb-list-news {
            &__title {
              &::before {
                display: none;
              }
            }
            &__summary {
              @include media(bp(m)) {
                margin-top: 1.25em;
              }
            }
            &__image-teaser {
              @include media(bp(m)) {
                margin: 0.9em 0;
              }
              img {
                max-height: 56.25vw;
                @include media(bp(m)) {
                  max-width: 630px;
                  width: 100%;
                  margin: 0 auto;
                }
              }
            }
          }
          // Top story without image
          /* stylelint-disable max-nesting-depth */
          // disabling max nesting depth since we have to exclude ipe.
          // this should probably be moved outside the li bracket instead.
          .cb-layout__component-dynamic.column-second-empty {
            .cb-list-news {
              &__title {
                margin-bottom: 0;
              }
              &__summary {
                margin-top: 1em;
                p {
                  @include media(bp(m)) {
                    font-size: 1.25rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
          /* stylelint-enable max-nesting-depth */
        }
      }
    }
  }
}
/* stylelint-enable selector-max-compound-selectors */
// Top story two - four (benath main top story)
.cb-list-news--top-stories {
  ul:not(.panels-ipe-linkbar) {
    li {
      &:first-child:nth-last-child(2),
      &:nth-child(n+2) {
        @include media(bp(m)) {
          display: flex;
          margin-bottom: 0;
        }
        &:not(:last-child) {
          margin-bottom: 2em;
          border-bottom: $border--s;
          padding-bottom: 2.5em;
        }
        .lt-ie10 & {
          padding-top: 0;
        }
        .cb-list-news {
          &__best-category {
            .lt-ie10 & {
              @include media(bp(m)) {
                top: 0;
                left: 0;
                margin-top: 0.8rem;
              }
            }
          }
          &__image-teaser {
            @include media(bp(m)) {
              position: static;
              width: auto;
              height: auto;
            }
          }
          &__title {
            &::before {
              @include media(bp(m)) {
                display: none;
              }
            }
          }
          &__summary {
            p {
              @include media($bp-only-s) {
                font-size: 1rem;
              }
            }
          }
        }
        &:not(:first-child) {
          &::before {
            @include media(bp(m)) {
              content: "";
              display: block;
              border-left: $border--s;
              height: 100%;
              position: absolute;
              top: 0;
            }
          }
        }
        &:not(:last-child) {
          @include media(bp(m)) {
            padding-bottom: 0;
            border-bottom: none;
          }
        }
        &:last-child {
          @include media(bp(m)) {
            padding-bottom: 0;
            margin-bottom: 2em;
          }
        }
        .cb-layout__component-dynamic {
          @include media(bp(m)) {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
          }
        }
        // Top story with image
        .cb-layout__component-dynamic:not(.column-second-empty) {
          .column-first {
            @include media(bp(m)) {
              flex: 1 0 auto;
              order: 2;
              &:not(:only-child) {
                margin-top: 1.1em;
                /* stylelint-disable selector-max-compound-selectors */
                .lt-ie10 & {
                  margin-top: 0;
                }
                /* stylelint-enable */
              }
            }
          }
          .column-second {
            @include media(bp(m)) {
              flex: 0 0 auto;
              order: 1;
              /* stylelint-disable selector-max-compound-selectors */
              .lt-ie10 & {
                margin-top: 0.5em;
              }
              /* stylelint-enable */
            }
          }
        }
        // Top story without image
        .cb-layout__component-dynamic.column-second-empty {
          @include media(bp(m)) {
            // fix hidden content in IE11
            display: block;
          }
          .column-first {
            @include media(bp(m)) {
              margin-bottom: 0;
              display: flex;
              flex: 1;
              flex-direction: column;
            }
          }
          .cb-list-news {
            &__summary {
              @include media(bp(m)) {
                flex: 1 0 auto;
                margin-top: 0.6em;
                display: flex;
                flex-wrap: wrap;
              }
              .field-name-field-summary {
                @include media(bp(m)) {
                  // Fix ie10 text overflow issue
                  max-width: 100%;
                }
              }
            }
            &__title {
              .field-name-title-field {
                @include media(bp(m)) {
                  flex: 1 0 auto;
                }
              }
              .field-name-field-news-external-url {
                @include media(bp(m)) {
                  flex: 0 0 auto;
                }
              }
            }
            &__best-category {
              .lt-ie10 & {
                @include media(bp(m)) {
                  top: -2.5em;
                }
              }
            }
          }
        }
        // Hide summary for news item with teaser image
        .cb-layout__component-dynamic:not(.column-second-empty) {
          .cb-list-news {
            &__summary {
              display: none;
            }
          }
        }
      }
    }
  }
}

// Component width depending on total number of top stories
.cb-list-news--top-stories {
  ul:not(.panels-ipe-linkbar) {
    li {
      @include media(bp(m)) {
        // Two top stories only
        &:first-child:nth-last-child(2),
        &:nth-child(2):last-child {
          h3 {
            a {
              @include media(bp(m)) {
                font-size: 1.5rem;
                line-height: 1.4;
              }
            }
          }
          .cb-list-news__image-teaser {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
}

// More link
.cb-list-news--top-stories {
  .more-link {
    text-align: center;
    clear: both;
    width: 100%;
    margin: 1em auto 3em auto;
    &:last-child {
      margin-bottom: 3em;
      margin-top: 0;
      padding-top: $spacing--m;
    }
    a {
      @include more-link($margin-left: 0);
      @include media(bp(s)) {
        @include component-link-title(m);
      }
    }
  }
}

// Override default classy panel styles
.region.cb-max-columns--3 {
  .pane:nth-child(2):not(:last-child) {
    &.cb-list-news {
      &--top-stories {
        width: 100%;
      }
    }
  }
  .pane:nth-child(n+3) {
    &.cb-list-news {
      &--regular-stories {
        width: 100%;
      }
    }
  }
}

// Fix top Stories border alignments when left sidebar exists
.aside-last-empty:not(.aside-first-empty) {
  .cb-list-news--top-stories {
    ul:not(.panels-ipe-linkbar) {
      li {
        &:first-child:nth-last-child(2),
        &:nth-child(n+2) {
          &:not(:last-child) {
            @include media(bp(m)) {
              padding-bottom: 0;
              border-bottom: none;
            }
          }
          &:not(:first-child) {
            &::before {
              @include media(bp(m)) {
                left: -6%;
              }
            }
          }
        }
        // Two top stories only
        &:nth-child(2):last-child {
          &::before {
            @include media(bp(m)) {
              left: -4.5%;
            }
          }
        }
        // Three top stories only
        &:nth-child(3):last-child {
          &::before {
            @include media(bp(m)) {
              left: -3.6%;
            }
          }
        }
      }
    }
  }
}
