// CTA Full Width Featured Layout Overrides (Only Child in a Region)

.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      &:only-child {
        .cw-with-image {
          @include media(bp(m)) {
            height: 26em;
            align-items: center;
            justify-content: center;
            &.cb-position-h--left {
              align-items: flex-end;
              justify-content: flex-end;
            }
          }
        }
        .content {
          @include media(bp(m)) {
            justify-content: center;
          }
        }
      }
      .field-name-field-cta-link a,
      .field-name-field-cta-description,
      .field-name-field-title {
        max-width: 100%;
      }
    }
  }
}

// Featured layout with no sidebars
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--dynamic-features-asides-feature-rows.cb-layout--no-aside {
  .region {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      &:only-child {
        .paragraphs-item-cta.cw-with-image {
          @include media(bp(l)) {
            height: 28em;
          }
          @include media(bp(xl)) {
            height: 32em;
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta {
        &:only-child {
          .paragraphs-item-cta.cw-with-image {
            @include media(bp(l)) {
              height: 23em;
              .content {
                padding: 0;
              }
              /* stylelint-disable selector-max-compound-selectors */
              &.cb-position-h--left {
                .field-name-field-cta-link a {
                  padding: 0.6em 0.7em;
                }
              }
              &.cb-position-h--center {
                .content {
                  max-width: 85%;
                }
              }
              /* stylelint-enable */
            }
            @include media(bp(xl)) {
              height: 26em;
            }
          }
        }
      }
    }
  }
}
