// Clinical Trials Search List Page

.cb-list-trials {
  &__header {
    margin-bottom: 0;
  }
  &__search-message {
    padding: 0;
    p {
      font-weight: 600;
    }
  }
  &__results {
    &.pane.cb-list-trials {
      &:not(:last-child) {
        margin-bottom: 0;
      }
      .cb-content-area__main & {
        margin-top: 2em;
      }
      ul:not(.pager):not(.panels-ipe-linkbar) {
        li {
          &:first-child {
            border-top: none;
            padding-top: 0;
          }
        }
      }
    }
  }
  &__pager {
    .item-list {
      ul {
        margin: 0 auto;
      }
    }
  }
}

// IPE
.panels-ipe-portlet-wrapper.pane.cb-list-trials {
  &.cb-list-trials__results {
    .cb-list-trials__body {
      margin-bottom: 0;
    }
  }
}
