// Base expand/collapse styles

// Show/hide
.paragraphs-item-person {
  .show-hide-controller {
    margin-top: 0.6em;
    display: inline-block;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    font-size: 1rem;
    color: color(action, m);
    text-align: left;
    font-weight: 700;
    &:focus,
    &:active {
      box-shadow: none;
      outline: 1px dotted color(neutral-brand, m);
    }
    &:hover {
      .show-hide__action {
        text-decoration: underline;
      }
    }
    .show-hide {
      &__description {
        @include visually-hidden();
      }
    }
    &::after {
      margin-left: 0.25em;
    }
    &[aria-expanded='true'] {
      &::after {
        content: '\2212';
      }
    }
    &[aria-expanded='false'] {
      &::after {
        content: '\002B';
      }
    }
  }
}

// Show more/less
.cb-list-treatments--related-treatments,
.cb-list-conditions--related-conditions,
.node-type-treatment .pane-entity-field,
.node-type-condition .pane-entity-field,
.node-type-lab .pane-node-field-lab-publications,
.node-type-cups-profile .pane-node-field-cups-research-grants {
  .show-more {
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1em auto 0 auto;
    padding: 0.5em 0;
    text-align: center;
    width: 60%;
    position: relative;
    display: block;
    &--more,
    &--less {
      border-top: $border--s;
      span {
        padding-right: 0.25em;
      }
    }
    &--more {
      &::after {
        content: '\002B';
      }
    }
    &--less {
      &::after {
        content: '\2212';
      }
    }
    &--hidden {
      position: relative;
      &::after {
        content: '';
        display: block;
        height: 1.5em;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), #fff);
        position: absolute;
        bottom: 0;
      }
    }
  }
}
