// Gateway sidebar component typography and paddings

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.cb-gateway {
        @include component-sidebar-padding();
        .cb-gateway__title {
          color: $sidebar-component-title-color;
          text-align: center;
          @include component-heading(s);
          @include media(bp(bs)) {
            @include component-heading(m);
          }
          @include media(bp(m)) {
            @include component-heading(l);
          }
        }
        .field-name-field-gateway-references {
          a {
            @include component-link-title(s);
            color: $sidebar-component-link-color;
            font-weight: 600;
            @include media(bp(s)) {
              @include component-link-title(m);
            }
            &::after {
              color: $sidebar-component-link-color;
            }
          }
        }
      }
    }
  }
}

// Sidebar specific styles for gateway
.cb-content-area__aside-last {
  .cb-gateway {
    .cb-gateway {
      &__title {
        &::after {
          background: $sidebar-component-border-color;
          left: 0;
          right: 0;
          margin: 0 auto;
          height: 5px;
        }
      }
      &__content {
        padding: 0;
        max-width: $sidebar-component-content-max-width;
        margin: 0 auto;
        a {
          &:hover {
            text-decoration: underline;
          }
          &::before {
            text-decoration: none;
          }
        }
      }
    }
    @include media(bp(bs)) {
      .field-name-field-gateway-references {
        .field-item {
          // display gateway links in 2 columns
          float: left;
          @include span-columns(6);
          &:nth-child(2n+1) {
            clear: both;
          }
          &:nth-child(2n+2) {
            @include omega();
          }
        }
      }
    }
  }
  .aside-first-empty & {
    .cb-gateway {
      .cb-gateway__title {
        @include media(bp(m)) {
          &::after {
            font-size: 2em;
          }
        }
        @include media(bp(l)) {
          text-align: center;
          &::after {
            font-size: 1.3rem;
          }
        }
        &::after {
          font-size: 1.625em;
        }
      }
    }
  }
  .cb-gateway {
    &:only-child {
      @include media(bp(l)) {
        max-width: 100%;
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.cb-gateway {
          padding: $sidebar-component-right-rail-padding;
          .cb-gateway {
            &__title {
              @include component-heading-right-rail();
              text-align: center;
              &::after {
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: -1rem;
                font-size: 1.3rem;
              }
            }
          }
          .field-name-field-gateway-references {
            a {
              @include component-link-title-right-rail();
            }
            .field-item {
              // display gateway links in 1 column
              width: 100%;
              margin-bottom: 0.5em;
              // stylelint-disable selector-max-compound-selectors
              &:last-child {
                margin-bottom: 0;
              }
              // stylelint-enable
            }
          }
        }
      }
    }
  }
}
