// Announcements Promoted Featured Layout:

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-news--promoted.cb-list-news--announcements,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
      @include media(bp(l)) {
        li {
          @include quantity-positions(5,1) {
            // no image
            .column-first.column--first:only-child {
              margin: 0 auto;
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              > * {
                width: 100%;
                max-width: $component-content-max-width;
                margin-left: auto;
                margin-right: auto;
              }
              /* stylelint-enable */
            }
          }
        }
      }
      @include media(bp(xl)) {
        li {
          @include quantity-positions(4,1,3) {
            @include component-divider($right-alignment: -1.3rem);
          }
          @include quantity-positions(4,3) {
            &::after {
              top: -0.1rem;
            }
          }
          @include quantity-positions(5,2,4) {
            @include component-divider($right-alignment: -1.3rem);
          }
          @include quantity-positions(5,3,5) {
            &::after {
              top: -0.1rem;
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebar
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .cb-list-news--promoted.cb-list-news--announcements,
      .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
        @include media(bp(l)) {
          li {
            @include quantities-all(3) {
              width: 100%;
              float: none;
              margin-right: 0;
              &::after {
                border: none;
              }
              /* stylelint-disable selector-max-compound-selectors */
              .cb-layout__component-dynamic {
                display: flex;
                flex-direction: row;
              }
              .columns-2.column--first {
                &:not(:only-child) {
                  width: 78%;
                  justify-content: center;
                  display: flex;
                }
              }
              .column--first:only-child {
                width: 100%;
              }
              .columns-2.column--last {
                width: 22%;
              }
              /* stylelint-enable */
            }
            @include quantity-positions(3,2,3) {
              padding-top: 1.25em;
            }
            @include quantity-positions(3,1,2) {
              border-bottom: $border--xs;
              padding-bottom: 1.25em;
            }
          }
        }
      }
    }
  }
}
