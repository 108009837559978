// File view
.page-file {
  .pane-file-display {
    margin-bottom: 2rem;
    + div + div {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: $border--xs;
    }
  }
  .pane-media-embed-data {
    margin-top: $spacing--xl;
    dt {
      font-weight: 700;
    }
    dd {
      margin: 0;
    }
    dd + dt {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: $border--xs;
    }
  }
  .field-name-field-file-image-alt-text,
  .field-name-field-cola-media-caption {
    &, p {
      font-family: $font--text--alt;
      font-size: 1rem;
    }
  }
}
