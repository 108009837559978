.cb-list-news--main {
  .region.cb-padding {
    .cb-list-news--promoted.cb-list-news--topic {
      border: $component-border-style;
      .view {
        padding: 0;
      }
      h2.pane-title {
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        padding: 0.6em;
        background: #fff;
        border-bottom: none;
        font-size: 1.2em;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        flex-flow: column wrap;
        @include media(bp(m)) {
          min-height: 5rem;
        }
        a {
          text-decoration: none;
          color: color(action, m);
          font-weight: 700;
          font-size: 1rem;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .cb-layout__component-dynamic {
        padding: 1em;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
      }
      .column-first {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        order: 2;
      }
      .column-second {
        order: 1;
        margin-bottom: 1em;
      }
      .cb-list-news {
        &__best-category {
          display: none;
        }
        &__title {
          a {
            color: a11yrize(color(action, m), $component-bg-light-1-color);
            font-weight: 600;
          }
        }
        &__date {
          font-size: 0.7rem;
          color: color(neutral-brand, xxlo);
          font-weight: 400;
        }
        &__summary {
          display: none;
        }
        &__image-teaser {
          position: static;
          .lt-ie10 & {
            margin-top: 1em;
          }
        }
        &__byline {
          display: none;
        }
      }
      li {
        &:not(:last-child) {
          border-bottom: solid 2px #fff;
        }
        &:not(:first-child) {
          .column-second {
            display: none;
          }
        }
      }
      // View All Link
      + .pane-link-to-list {
        margin: 2em auto 0 auto;
        @include media(bp(m)) {
          margin-top: 0;
        }
        @include media(bp(l)) {
          padding-top: 2em;
        }
      }
      + .panels-ipe-portlet-wrapper {
        .pane-link-to-list {
          @include media(bp(l)) {
            padding-top: 2em;
          }
        }
      }
      .view-content {
        margin: 0;
      }
    }
  }
}

// Only child per row styles
.cb-list-news--main {
  .region.cb-padding {
    .pane {
      &.cb-list-news--promoted.cb-list-news--topic {
        @mixin topic-full-width() {
          .cb-list-news {
            &__content {
              display: flex;
              > .view-content {
                height: 100%;
              }
            }
          }
          .cb-layout__component-dynamic.feature-empty.column-third-empty {
            flex-direction: row;
            /* stylelint-disable selector-max-compound-selectors */
            .columns-2.column--first {
              float: left;
              order: 1;
            }
            .columns-2.column--last {
              float: right;
              width: 20%;
              order: 2;
            }
            /* stylelint-enable */
          }
          h3 {
            font-size: 1.3rem;
          }
          /* stylelint-disable selector-max-compound-selectors */
          .more-link {
            margin-bottom: 2em;
            a {
              font-size: 0.8rem;
            }
          }
          h2.pane-title {
            a {
              font-size: 1.5rem;
            }
          }
          /* stylelint-enable */
        }
        // Only child topic.
        // First child is Section heading, last child is view all topics link.
        &:nth-child(2):nth-last-of-type(2) {
          @include media(bp(m)) {
            @include topic-full-width();
          }
        }
        // Third chil dthat is also a last child
        // (we have two up on tablets)
        &:nth-child(3n+4):nth-last-of-type(2) {
          @include media($bp-only-m) {
            @include topic-full-width();
          }
        }
        // Every fourth child that is also a last child
        &:nth-child(3n+5):nth-last-of-type(2) {
          @include media(bp(l)) {
            @include topic-full-width();
          }
        }
      }
    }
  }
}

// Override default classy panel styles
.cb-list-news--main {
  .cb-content-area__feature-rows {
    .cb-list-news--promoted.cb-list-news--topic {
      &.pane {
        height: 100%;
        // Display two column layout on tablet
        @include media($bp-only-m) {
          width: width-margins(50%);
          float: left;
          margin-top: 0;
          margin-bottom: 2.5em;
          display: flex;
          flex-direction: column;
          height: auto;
        }
        @include media(bp(m)) {
          margin-left: 1.7%;
          margin-right: 1.7%;
          height: auto;
        }
        // Only child styles
        // nth-child(2) because title and link appear before and after
        &:nth-child(2):nth-last-of-type(2),
        &:nth-child(3n+4):nth-last-of-type(2) {
          @include media($bp-only-m) {
            width: 100%;
          }
        }
        // Fix ie9 horizontal alignment issue when children have different heights
        &:nth-child(3n+4) {
          @include media(bp(l)) {
            .lt-ie10 & {
              clear: none;
            }
          }
        }
        &:nth-child(3n+5) {
          @include media(bp(l)) {
            .lt-ie10 & {
              clear: both;
            }
          }
        }
      }
    }
  }
}

// Override default news base styles
.cb-list-news--main {
  .region {
    .pane.cb-list-news--promoted.cb-list-news--topic {
      &:nth-child(4) {
        @include media(bp(l)) {
          margin-top: 0;
        }
      }
      h2.pane-title {
        // hide news icon
        &::before {
          display: none;
        }
      }
      .view {
        box-shadow: none;
        background-color: $component-bg-1-color;
        // Safari fix: to prevent container height stretch
        height: auto;
      }
      .view-content {
        // ie10/ie11 fix: to prevent container height stretch
        height: 100%;
        flex-shrink: 1;
        > ul {
          /* stylelint-disable selector-max-compound-selectors */
          .columns-2.column--first {
            display: flex;
          }
          .columns-2.column--first,
          .columns-2.column--last {
            width: 100%;
          }
          /* stylelint-enable */
          > li {
            margin: 0;
            border-top: none;
            padding: 0.5em;
            @include media(bp(m)) {
              padding: 0;
            }
          }
        }
      }
      a {
        padding-right: 0;
        &::before,
        &::after {
          display: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .cb-list-news {
        &__image-teaser {
          max-height: 100%;
        }
        &__title {
          margin: 0;
          h3 {
            font-size: 1rem;
            line-height: 1.3;
            &::before {
              display: none;
            }
          }
          a {
            font-weight: 600;
            max-height: 100%;
            display: inline;
          }
        }
      }
      .more-link {
        text-align: center;
        line-height: 1.4;
        width: 100%;
        margin: 0.5em auto 1.4em auto;
        border-top: none;
        padding-top: 0;
        a {
          @include btn($style: outline, $size: s);
          @include override-nested() {
            text-decoration: none;
            font-weight: 600;
            max-width: 80%;
            background: transparent;
            border-color: a11yrize(color(action, m), $component-bg-light-1-color);
            color: a11yrize(color(action, m), $component-bg-light-1-color);
          }
          @include media(bp(l)) {
            font-size: 0.8rem;
          }
          &:hover {
            color: #fff;
            border-color: $btn-style-outline-color;
          }
          /* stylelint-disable selector-max-compound-selectors */
          .lt-ie10 & {
            margin-bottom: 2em;
          }
          /* stylelint-enable */
        }
      }
    }
  }
  .cb-page-content--feature-rows {
    .region.cb-max-columns.cb-padding {
      .pane.cb-list-news--promoted.cb-list-news--topic {
        h2.pane-title {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Fix ie issues
.cb-list-news--main {
  .cb-content-area__feature-rows {
    .cb-list-news--promoted.cb-list-news--topic {
      &.pane {
        .lt-ie10 & {
          &:first-child:nth-last-child(2) {
            width: 100%;
          }
        }
      }
    }
    .pane-custom ~ .cb-list-news--promoted.cb-list-news--topic {
      &.pane {
        &:nth-child(2):nth-last-child(2) {
          @include media(bp(l)) {
            width: 96.6%;
          }
        }
        &:nth-child(2):nth-last-child(3),
        &:nth-child(3):nth-last-child(2) {
          @include media(bp(l)) {
            width: 46.6%;
          }
        }
      }
    }
  }
}

// Fix IPE issues
.cb-list-news--main {
  .region.cb-padding {
    .panels-ipe-portlet-wrapper {
      width: 100%;
    }
    /* stylelint-disable selector-max-compound-selectors */
    .pane {
      &.panels-ipe-portlet-wrapper {
        .cb-list-news--promoted.cb-list-news--topic {
          margin-top: 0;
          .cb-list-news {
            &__image-teaser {
              max-height: 100%;
            }
            &__content {
              max-width: 100%;
              height: 100%;
            }
          }
          .view-content {
            > ul {
              max-width: 100%;
            }
            li {
              margin: 0;
            }
          }
        }
      }
    }
    /* stylelint-enable */
  }
}

// Override default styles for empty items
.cb-list-news--main {
  .region.cb-padding {
    .pane {
      &.cb-list-news--promoted.cb-list-news--topic,
      &.panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--topic,
      &.panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--topic {
        .view-content {
          // This section exceeds max specificity rules and needs to be refactored [DP-826]
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          > ul {
            > li {
              @include media(bp(l)) {
                &:first-child:last-child,
                &:nth-child(2):last-child {
                  &::before, &::after {
                    display: none;
                  }
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
