// Promoted Events Typography Styles

// Default across all pages
.region,
.cb-page-content .cb-content-area__main {
  /* stylelint-disable selector-max-compound-selectors */
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    .cb-list-events {
      &__title {
        margin-bottom: 0;
        // Padding for focus outlines.
        padding: 1px;
        a {
          color: $events-promoted-title-link-color;
          max-height: 100%;
          &::before,
          &::after {
            display: none;
          }
        }
      }
      &__date,
      &__time {
        color: $events-promoted-date-color;
        line-height: 1.4;
      }
      &__time {
        font-size: 0.8rem;
        color: color(neutral, m);
        > * {
          display: inline;
        }
      }
      &__date {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: $spacing--s;
        ~ .cb-list-events__time {
          font-weight: 400;
          white-space: nowrap;
        }
      }
      &__location {
        font-size: 0.8rem;
        color: $events-promoted-venue-font-color;
        line-height: 1.25;
        margin-bottom: $spacing--m;
        a {
          font-weight: 400;
          @include icon($icon: "map-marker", $pseudo: before);
          &::before {
            font-size: 0.9em;
            margin: -0.1rem 0.3rem 0 0.06rem;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    li {
      .cb-list-events {
        &__title {
          h3 {
            @include component-link-title(s);
          }
        }
        &__date {
          font-size: 1rem;
        }
        &__time {
          @include component-paragraph(xs);
          font-weight: 400;
        }
        &__location {
          line-height: 1.25;
          font-weight: 400;
        }
      }
    }
  }
  /* stylelint-enable */
}

.region {
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    li {
      /* stylelint-disable selector-max-compound-selectors */
      @include media(bp(bs)) {
        @include quantities-all(2,4) {
          .cb-list-events {
            &__date {
              font-size: 0.9rem;
            }
          }
        }
      }
      @include media(bp(m)) {
        @include quantities-all(1) {
          .cb-list-events {
            &__date {
              font-size: 1.4rem;
            }
            &__title {
              h3 {
                @include component-link-title(l);
              }
            }
          }
        }
        @include quantities-all(2,4) {
          .cb-list-events {
            &__date {
              font-size: 1.3rem;
            }
            &__title {
              h3 {
                @include component-link-title(m);
              }
            }
          }
        }
        @include quantities-all(3) {
          .cb-list-events {
            &__date {
              font-size: 0.9rem;
            }
          }
        }
      }
      @include media(bp(l)) {
        @include quantities-all(3) {
          .cb-list-events {
            &__date {
              font-size: 1.1rem;
            }
          }
        }
        @include quantities-all(4) {
          .cb-list-events {
            &__date {
              font-size: 0.9rem;
            }
            &__time {
              font-size: 0.75rem;
            }
            &__title {
              h3 {
                @include component-link-title(s);
              }
            }
          }
        }
      }
      /* stylelint-enable */
      @include media(bp(xl)) {
        @include quantities-all(2) {
          .cb-list-events {
            &__title {
              h3 {
                @include component-link-title(l);
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and Featured layout with no sidebars
.front.cb-flavor:not(.cb-list-news),
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows .cb-flavor:not(.cb-list-news) {
  .region {
    /* stylelint-disable selector-max-compound-selectors */
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      @include media(bp(l)) {
        li {
          @include quantities-all(4) {
            .cb-list-events {
              &__location {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
      @include media(bp(xl)) {
        li {
          @include quantities-all(3) {
            .cb-list-events {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
            }
          }
        }
      }
    }
    /* stylelint-enable */
  }
}

// Featured Layout with sidebars

.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region {
        .cb-list-events--promoted,
        .panels-ipe-portlet-wrapper .cb-list-events--promoted {
          @include media(bp(l)) {
            li {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              .cb-list-events {
                &__title {
                  h3 {
                    @include component-link-title(l);
                  }
                }
              }
              @include quantities-all(2,4) {
                .cb-list-events {
                  &__title {
                    h3 {
                      @include component-link-title(m);
                    }
                  }
                }
              }
              @include quantities-all(3) {
                .cb-list-events {
                  &__title {
                    h3 {
                      @include component-link-title(s);
                    }
                  }
                  &__location {
                    font-size: 0.8rem;
                  }
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}

// Main content only
.cb-flavor:not(.cb-list-news) {
  .cb-page-content .cb-content-area__main {
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      /* stylelint-disable selector-max-compound-selectors */
      @include media(bp(m)) {
        li {
          .cb-list-events {
            &__date {
              font-size: 1.2rem;
            }
            &__title {
              h3 {
                @include component-link-title(l);
              }
            }
          }
        }
      }
      /* stylelint-enable */
    }
  }
}

.cb-layout--aside-first-last.cb-flavor:not(.cb-list-news) {
  .cb-page-content .cb-content-area__main {
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      @include media(bp(l)) {
        h2.pane-title {
          @include component-heading(xl);
        }
        /* stylelint-disable selector-max-compound-selectors */
        li {
          .cb-list-events {
            &__date {
              font-size: 1.1rem;
            }
            &__title {
              h3 {
                @include component-link-title(m);
              }
            }
          }
        }
        /* stylelint-enable */
      }
    }
  }
}

.cb-layout--aside-first.cb-flavor:not(.cb-list-news),
.cb-layout--aside-last.cb-flavor:not(.cb-list-news) {
  .cb-page-content .cb-content-area__main {
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      /* stylelint-disable selector-max-compound-selectors */
      @include media(bp(l)) {
        li {
          .cb-list-events {
            &__date {
              font-size: 1.2rem;
            }
            &__title {
              h3 {
                @include component-link-title(l);
              }
            }
          }
        }
      }
      /* stylelint-enable */
    }
  }
}
