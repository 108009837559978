// Promoted News, Blog, Announcements, Events Sidebar Styles

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.cb-list-news--promoted,
      &.cb-list-events--promoted {
        background: $sidebar-component-bg-color;
        padding: 0;
        .view-content {
          padding: 0 $spacing--m 0 $spacing--m;
          @include media(bp(bs)) {
            padding: 0 $spacing--l 0 $spacing--l;
          }
          @include media(bp(m)) {
            padding: 0 $spacing--xl 0 $spacing--xl;
          }
          &:last-child {
            padding-bottom: 1.7rem;
            @include media(bp(m)) {
              padding-bottom: 2rem;
            }
            @include media(bp(l)) {
              padding-bottom: 2.3rem;
            }
          }
        }
        h2.pane-title {
          padding: $spacing--m $spacing--m 0 $spacing--m;
          color: $sidebar-component-title-color;
          @include component-heading(m);
          // icon
          &::before {
            color: a11yrize(color(action, m), $region-bg-1-color);
          }
          @include media(bp(xs)) {
            @include component-heading(l);
          }
          @include media(bp(bs)) {
            padding: $spacing--l $spacing--l 0 $spacing--l;
          }
          @include media(bp(m)) {
            padding: $spacing--xl $spacing--xl 0 $spacing--xl;
          }
          @include media(bp(l)) {
            @include component-heading(xl);
          }
        }
        /* stylelint-disable selector-max-compound-selectors */
        .more-link a,
        li h3 a {
          @include component-link-title(s);
          color: $sidebar-component-link-color;
          font-weight: 600;
          @include media(bp(s)) {
            @include component-link-title(m);
          }
        }
        /* stylelint-enable */
        .cb-list-news,
        .cb-list-events {
          &__content {
            margin-top: 1rem;
          }
          &__title {
            &:not(:only-child) {
              margin-bottom: 0.2rem;
              @include media(bp(m)) {
                margin-bottom: 0.3rem;
              }
            }
            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
          &__best-category {
            a {
              font-weight: 400;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .more-link {
          margin-top: 1em;
          padding-bottom: 0.7em;
          @include media(bp(m)) {
            margin-top: 2em;
            padding-bottom: 1em;
          }
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

// Sidebar specific styles shared between all promoted news, announcements, events, and deadlines
.cb-content-area__aside-last {
  .cb-list-news--promoted,
  .cb-list-events--promoted {
    h2.pane-title {
      text-align: center;
    }
    .view {
      padding: 0;
    }
    .view-content {
      width: 100%;
      margin: 0 auto 1rem auto;
      &:last-child {
        margin-bottom: 0;
      }
      ul {
        max-width: $sidebar-component-content-max-width;
        margin: 0 auto;
      }
      li {
        border-top: $border--s;
        border-color: $sidebar-component-border-color;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          padding-top: $spacing--l;
          border-top: $border--m;
          border-color: $sidebar-component-border-color;
        }
      }
    }
    .more-link {
      text-align: center;
      background: $background-solid-light;
      padding-top: 0.8em;
      a {
        background: transparent;
        color: a11yrize(color(action, m), $background-solid-light);
        @include media(bp(m)) {
          font-size: 1.1rem;
        }
        @include media(bp(l)) {
          font-size: 1rem;
        }
      }
    }
    .cb-list-news,
    .cb-list-events {
      &__title {
        h3 {
          font-size: 1.125rem;
          @include media(bp(m)) {
            font-size: 1.3rem;
          }
        }
        a {
          color: $sidebar-component-link-color;
          max-height: auto;
          &::before,
          &::after {
            display: none;
          }
        }
        span.ext {
          display: inline;
        }
      }
      &__summary {
        color: $sidebar-component-font-color;
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    .pane {
      &.cb-list-news--promoted,
      &.cb-list-events--promoted {
        @include media(bp(xl)) {
          padding: 0;
          h2.pane-title {
            @include component-heading-right-rail();
            margin-bottom: 1.2rem;
            padding: 2rem 2rem 0 2rem;
          }
          .view-content {
            padding: 0 2rem 0 2rem;
            &:last-child {
              padding-bottom: 2rem;
            }
            /* stylelint-disable selector-max-compound-selectors */
            ul {
              padding-bottom: 0;
            }
            /* stylelint-enable */
          }
          .cb-layout__component-dynamic {
            display: flex;
            flex-direction: column;
          }
          li {
            margin-bottom: 1.6em;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .columns-2 {
            float: none;
            margin: 0;
            width: auto;
          }
          .more-link {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            margin-top: 1.6em;
            /* stylelint-disable selector-max-compound-selectors */
            a {
              color: $sidebar-component-link-color;
              @include component-link-title-right-rail();
              &:hover {
                color: $sidebar-component-link-color;
              }
            }
            /* stylelint-enable */
          }
          .cb-list-news,
          .cb-list-events {
            &__title {
              &:not(:only-child) {
                margin-bottom: 0.2rem;
              }
              h3 {
                font-size: 1rem;
                /* stylelint-disable selector-max-compound-selectors */
                &::before {
                  display: none;
                }
                a {
                  @include component-link-title-right-rail();
                  overflow: visible;
                  display: inline;
                  padding-right: 0;
                }
                /* stylelint-enable */
              }
            }
            &__image-teaser {
              max-width: 100%;
              position: static;
            }
          }
        }
      }
      /* stylelint-disable selector-max-compound-selectors */
      .cb-list-events--promoted.cb-list-events--deadline,
      .panels-ipe-portlet-wrapper .cb-list-events--promoted.cb-list-events--deadline {
        h2.pane-title {
          &::before {
            @include media(bp(l)) {
              max-width: 22px;
              max-height: 22px;
            }
          }
        }
      }
      /* stylelint-enable */
    }
  }
}
