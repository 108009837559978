// Fixed positioning for elements.
// The element--sicky and has-sticky-element class is currently applied
// through jquery on the CUPS jump menu.
.element--sticky {
  position: fixed;
  top: 0;
  z-index: z(nav);
}

// Add some min-height to the container of the sticky element
// to preserve floats and widths if container is otherwise empty.
.has-sticky-element {
  min-height: 10px;
}

// ==================== Fix for element-invisible of system.base.css ====================
// The lack of width in .element-invisible of the base styles cause the layout to break in
// mobile. This will be fixed in d8: https://www.drupal.org/node/1973456
// additionally there appears to be a screenreader issue if width: 1px is added,
// but this can be solved by applying word-wrap: normal
// also fixed in d8: https://www.drupal.org/node/2045151
//
// should be fixed through a drupal patch instead
.element-invisible {
  width: 1px;
  word-wrap: normal;
  padding: 0;
  margin: 0;
}

.pane-node-field-image-featured,
.pane-taxonomy-term-field-image-featured {
  &:not(:last-child) {
    margin-bottom: $spacing--xl;
  }
  .panels-ipe-portlet-wrapper:not(:last-child) & {
    &:last-child {
      margin-bottom: $spacing--xl;
    }
  }
  img {
    // Needed for lazyload auto size calculation.
    width: 100%;
  }
}

// Add some spacing for basic page paragraphs content items.
.cb-structured-content {
  &:not(:first-child) {
    margin-top: $spacing--structured-content;
  }
  .panels-ipe-portlet-wrapper:not(:first-child) & {
    margin-top: $spacing--structured-content;
  }
  .field-name-field-page-paragraphs,
  .field-name-field-generic-paragraphs {
    > * + * {
      margin: $spacing--structured-content 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Clears floats, can be applied through wysiwyg
.cb-clear-both,
.ck-clear-both {
  clear: both;
  &--compressed {
    display: block;
    width: 100%;
    height: 0;
    margin: 0;
    overflow: hidden;
  }
}

// Highlight unpublished content for editors.
.node-unpublished.logged-in .cb-content-area__main,
.logged-in .node-unpublished.node-teaser,
.node-unpublished.logged-in.node-type-news .cb-page-content::after {
  background: url('#{$image-path}/ui-assets/red-diagonal-dash.png') repeat;
}

.node-unpublished.logged-in .cb-content-area__main,
.logged-in .node-unpublished.node-teaser,
.node-unpublished.logged-in.node-type-news .cb-page-content {
  &::before {
    content: '\26A0 unpublished';
    text-transform: uppercase;
    font-family: $sans;
    color: color(alert-fail);
    background: #fff;
    padding: 0.5em;
  }
}

.node-unpublished.logged-in.node-type-news .cb-page-content {
  position: relative;
  &::before {
    display: block;
    max-width: 75em;
    margin: 0 auto;
    background-color: rgba(255,255,255, 0.8);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    max-width: 75em;
    margin: 0 auto;

  }
}

// Remove focus outlines when focus is set to the main content wrapper.
.cb-content-area__main:focus {
  outline: none;
}

// Floats
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

// Spacing
.margin-bottom--l {
  margin-bottom: $spacing--xl;
}

.margin-bottom--s {
  margin-bottom: $spacing--s;
}

.margin-top-l {
  margin-top: $spacing--xl;
}

.margin-top--s {
  margin-top: $spacing--s;
}

// Hide elements visually only.
// See utilities.
.visually-hidden {
  @include visually-hidden();
}

// IPE Global Overrides
div.panels-ipe-handlebar-wrapper {
  // Fix hidden toolbar css btn text
  li.css {
    a span {
      text-indent: 0;
    }
  }
}
