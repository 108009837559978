// Clinical trials (promoted and filtered list) sidebar component typography and paddings

// Standardized sidebar component default styles
.cb-content-area__aside-last {
  .pane.cb-list-trials {
    &.cb-list-trials--promoted h2,
    &.cb-list-trials--filtered h2 {
      &.pane-title,
      &.field-name-field-title {
        padding: $spacing--m $spacing--m 0 $spacing--m;
        color: $sidebar-component-title-color;
        border: none;
        text-align: center;
        @include component-heading(m);
        @include media(bp(bs)) {
          padding: $spacing--l $spacing--l 0 $spacing--l;
        }
        @include media(bp(m)) {
          padding: $spacing--xl $spacing--xl 0 $spacing--xl;
        }
      }
    }
    &.cb-list-trials--promoted {
      .view-content {
        padding: 0 $spacing--m 0 $spacing--m;
        @include media(bp(bs)) {
          padding: 0 $spacing--l 0 $spacing--l;
        }
        @include media(bp(m)) {
          padding: 0 $spacing--xl 0 $spacing--xl;
        }
      }
      .more-link {
        margin-top: 1em;
        padding: 0.7em 0;
        text-align: center;
        width: 100%;
        background: $background-solid-light;
        @include media(bp(m)) {
          margin-top: 2em;
          padding: 1em 0;
        }
        a {
          font-size: 1rem;
          color: a11yrize(color(action, m), $background-solid-light);
          margin-top: 1em;
          padding-bottom: 0.7em;
          padding-top: 0.8em;
          @include media(bp(m)) {
            font-size: 1.1rem;
          }
          @include media(bp(l)) {
            font-size: 1rem;
          }
          &::after {
            display: none;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &.cb-list-trials--filtered {
      .view-content {
        padding: 0 $spacing--m $spacing--m $spacing--m;
        overflow: auto;
        @include media(bp(bs)) {
          padding: 0 $spacing--l $spacing--l $spacing--l;
        }
        @include media(bp(m)) {
          padding: 0 $spacing--xl $spacing--xl $spacing--xl;
        }
      }
      li {
        h2 {
          line-height: 1.25;
        }
      }
    }
  }
}

// Sidebar specific styles
.cb-content-area__aside-last {
  .pane.cb-list-trials {
    &.cb-list-trials--promoted,
    &.cb-list-trials--filtered {
      .view-content {
        margin-top: 0;
        ul:not(.pager):not(.panels-ipe-linkbar) {
          float: none;
          li {
            padding-top: 1.2em;
            padding-bottom: 1.3em;
            margin-right: 0;
            border-bottom: none;
            border-top: $border--s;
            border-color: $sidebar-component-border-color;
            &:first-child {
              padding-top: $spacing--l;
              border-top: $border--m;
              border-color: $sidebar-component-border-color;
            }
            &:last-child {
              padding-bottom: 0;
              float: none;
              clear: both;
            }
          }
        }
      }
    }
    &.cb-list-trials--promoted h2,
    &.cb-list-trials--filtered h2 {
      &.pane-title,
      &.field-name-field-title {
        padding-bottom: 0.7em;
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    .pane.cb-list-trials {
      @include media(bp(xl)) {
        &.cb-list-trials--promoted,
        &.cb-list-trials--filtered {
          /* stylelint-disable selector-max-compound-selectors */
          ul:not(.pager) {
            li {
              line-height: 1.3;
              a {
                @include component-link-title-right-rail();
              }
            }
          }
          /* stylelint-enable */
        }
        &.cb-list-trials--promoted h2,
        &.cb-list-trials--filtered h2 {
          &.pane-title,
          &.field-name-field-title {
            @include component-heading-right-rail();
            margin-bottom: 1.2rem;
            padding: 2rem 2rem 0 2rem;
          }
        }
        &.cb-list-trials--promoted {
          .view-content {
            &:last-child {
              padding-bottom: 2rem;
            }
            /* stylelint-disable selector-max-compound-selectors */
            ul:not(.pager) {
              padding-bottom: 0;
            }
            /* stylelint-enable */
          }
          .more-link {
            padding: 0.5em 0;
            /* stylelint-disable selector-max-compound-selectors */
            a {
              font-weight: 600;
            }
            /* stylelint-enable */
          }
        }
        &.cb-list-trials--filtered {
          .view-content {
            padding: $sidebar-component-right-rail-padding;
            padding-top: 0;
          }
          ul:not(.pager) {
            li {
              /* stylelint-disable selector-max-compound-selectors */
              h2 {
                text-align: left;
                line-height: 0.9;
              }
              a {
                line-height: 1;
              }
              .cb-list-trials__body {
                @include component-paragraph-right-rail();
              }
              /* stylelint-enable */
            }
          }
        }
        .cb-list-trials__trial-investigator {
          /* stylelint-disable selector-max-compound-selectors */
          .pane-title,
          .field-name-field-trial-investigator {
            font-size: 0.9rem;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
