// Events Promoted Featured Layout

.cb-flavor:not(.cb-list-news) {
  .cb-layout--dynamic-features-asides-feature-rows {
    .region {
      .cb-list-events--promoted,
      .panels-ipe-portlet-wrapper .cb-list-events--promoted {
        @include media(bp(l)) {
          li {
            &:only-child {
              /* stylelint-disable selector-max-compound-selectors */
              .cb-list-events {
                &__date {
                  padding-left: $spacing--xl;
                  padding-right: $spacing--xl;
                }
                &__time,
                &__title,
                &__location {
                  margin-left: $spacing--xl;
                  margin-right: $spacing--xl;
                }
              }
              .column-first {
                text-align: center;
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region {
        .cb-list-events--promoted,
        .panels-ipe-portlet-wrapper .cb-list-events--promoted {
          @include media(bp(l)) {
            /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
            ul {
              flex-wrap: wrap;
            }
            li {
              @include quantities-all(3) {
                .cb-list-events {
                  &__date {
                    padding-left: $spacing--m;
                    padding-right: $spacing--m;
                  }
                  &__time,
                  &__title,
                  &__location {
                    margin-left: $spacing--m;
                    margin-right: $spacing--m;
                  }
                }
              }
              // Display max 2 events per row for lists with 4 events
              @include quantities-all(4) {
                margin: 0 1.7% 3.4% 1.7%;
                width: 46.6%;
              }
              @include quantity-positions(4,3,4) {
                margin-bottom: 0;
              }
              @include quantity-positions(4,1,3) {
                margin-left: 0;
              }
              @include quantity-positions(4,2,4) {
                margin-right: 0;
              }
            }
            .more-link {
              right: 0;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
