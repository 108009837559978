.cb-list-specialty--locations .pane-cola-clinical-cups-locations-panel-pane-by-specialties,
.page-our-locations .pane-cola-cups-locations-panel-pane-full-list,
.cb-list-cups--locations .pane-cola-cups-locations-panel-pane-full-list {
  &:not(:first-child) {
    margin-top: 1em;
  }
  > .view {
    .view-content {
      @include clearfix();
      @include media(bp(m)) {
        width: 60%;
        float: left;
      }
      a {
        text-decoration: none;
        color: a11yrize($text-link-color, $cups-our-locations-bg-color);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .views-row {
      padding: 30px 20px 30px 60px;
      background-color: $cups-our-locations-bg-color;
      position: relative;
      font-family: $font--text--alt;
      @include media(bp(m)) {
        padding: 40px 100px;
      }
      &:not(:last-child) {
        border-bottom: $border--s;
      }
      &.active {
        color: $cups-our-locations-active-font-color;
        @include media(bp(m)) {
          background-color: $cups-our-locations-active-bg-color;
          .result-number {
            color: $cups-our-ocations-active-result-number-color;
          }
        }
        a {
          color: a11yrize($text-link-color, $cups-our-locations-active-bg-color);
        }
      }
    }
    .result-number {
      width: 40px;
      font-size: 1.5em;
      left: 20px;
      color: $cups-our-locations-result-number-color;
      display: inline-block;
      line-height: 1em;
      position: absolute;
      font-weight: 700;
      @include media(bp(m)) {
        color: a11yrize($cups-our-locations-inactive-result-number-color, $cups-our-locations-bg-color, 3.5);
        width: 60px;
        font-size: 2.25em;
        left: 30px;
      }
    }
    .views-field-title,
    .field-name-field-cups-location-address {
      margin-bottom: 0.75em;
      color: $cups-our-locations-address-color;
    }
    .views-field-title {
      line-height: 1.4;
      @include media(bp(m)) {
        padding-right: 1em;
      }
      a {
        font-weight: 700;
      }
    }
    .cups-location {
      &__is-primary {
        text-align: center;
        background-color: $cups-locations-primary-label-bg-color;
        color: $cups-locations-primary-label-font-color;
        display: block;
        padding: 0.25em 0.5em;
        margin-top: 0.75em;
        min-width: 100px;
        width: 30%;
        @include media(bp(m)) {
          margin-top: 0;
          position: absolute;
          right: 20px;
          top: 40px;
          min-width: 0;
          width: auto;
        }
      }
      &__has-transit,
      &__has-disabled {
        display: block;
        margin-top: 0.5em;
        font-family: $font--text--alt;
        font-weight: 400;
        font-size: 1rem;
        &::before {
          font-family: "#{$icon-font-family}";
          margin-right: 0.25em;
          font-weight: normal;
        }
      }
    }
    .attachment {
      display: none;
      @include media(bp(m)) {
        display: block;
        width: 40%;
        float: left;
        padding: 20px;
        background-color: $cups-our-locations-map-bg-color;
      }
    }
    .item-list {
      display: inline-block;
      width: 100%;
    }
  }
}
