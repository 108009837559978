// Shared styles between all news, announcements, and events.
.cb-list-news--standard .cb-list-news,
.cb-list-events--standard .cb-list-events {
  .view-content,
  ul:not(.pager) {
    font-size: 1.125rem;
    line-height: 1.4;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .col {
    // Template overrides.
    float: none;
    margin: 0;
    width: auto;
  }
  .panel-separator {
    clear: both;
  }
  &__image-teaser {
    overflow: hidden;
    position: absolute;
    top: 1rem;
    right: 0;
    // Padding for focus outlines.
    padding: 1px;
    @include media(bp(m)) {
      top: 1.8rem;
    }
    img {
      max-height: 300px;
    }
  }
  &__title {
    h3 {
      font-size: 1.2rem;
      margin: 0;
      line-height: 1.3;
      @include media(bp(m)) {
        font-size: 1.5rem;
      }
    }
    a {
      margin: 1px;
    }
    &::before {
      content: "";
      float: right;
      margin-left: 1em;
      margin-bottom: 1.25em;
    }
  }
  &__summary {
    margin-top: 0.5em;
    @include media(bp(m)) {
      margin-top: 0.33em;
    }
  }
  &__date {
    font-size: 0.875rem;
    color: color(neutral-brand, m);
    display: inline-block;
    margin-top: 0.125em;
  }
  .cb-content-area__main {
    h3 {
      a {
        font-weight: 600;
      }
    }
  }
}
