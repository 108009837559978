// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing {
  .region,
  .cb-layout--aside-last .cb-content-area__aside-last,
  .cb-layout--aside-first-last .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-bundle-numbers {
        padding: 0;
        .panels-ipe-dragbar {
          border: 1px solid #CCC;
        }
      }
    }
  }
  .region {
    .pane {
      &.panels-ipe-bundle-numbers {
        border: 1px solid #CCC;
        .panels-ipe-portlet-content {
          padding: $spacing--xl;
        }
      }
    }
  }
  .cb-layout--aside-last,
  .cb-layout--aside-first-last {
    .cb-content-area__aside-last {
      .pane {
        &.panels-ipe-bundle-numbers {
          /* stylelint-disable selector-max-compound-selectors */
          .panels-ipe-portlet-content {
            @include component-padding();
          }
          .panels-ipe-handlebar-wrapper li {
            margin: 0;
            float: left;
            width: auto;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
