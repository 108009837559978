// Promoted Events: within main Newsroom page

.cb-list-news--main {
  .region.cb-padding {
    .cb-list-news--regular-stories:first-child ~ .cb-list-events--promoted {
      &::before {
        display: none;
        @include media(bp(m)) {
          display: block;
        }
      }
    }
    &.cb-max-columns--3,
    &.cb-max-columns--4 {
      .cb-list-events--promoted {
        &:not(:only-child),
        &:first-child:nth-last-child(n+3),
        &:nth-child(2):not(:last-child),
        &:nth-child(n+3) {
          width: 96.6%;
          @include media(bp(m)) {
            width: 46.6%;
            .lt-ie10 & {
              float: left;
              width: 46.6%;
            }
          }
        }
      }
    }
    .cb-list-events--promoted {
      // Default overrides
      position: static;
      &:not(:only-child) {
        @include media(bp(m)) {
          margin-top: 2em;
        }
        &:first-child {
          margin-top: 0;
          @include media(bp(m)) {
            margin-top: 2em;
          }
        }
      }
      .view-content {
        margin-top: 0;
      }
      h2.pane-title {
        padding: 0 0 $spacing--s 0;
        border-bottom: $border--m;
        border-color: $heading-border-color;
      }
      &:only-child {
        margin-bottom: $spacing--m;
      }
      &:not(:only-child) {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        @include media(bp(m)) {
          &:nth-child(2n+1):not(.panels-ipe-portlet-wrapper) {
            margin-right: 0;
            padding-right: 3%;
          }
        }
        &:nth-child(2n+2):not(.panels-ipe-portlet-wrapper) {
          border-top: 3px solid color(primary, lo);
          padding-top: 2em;
          @include media(bp(m)) {
            margin-left: 0;
            padding-left: 3%;
            padding-top: 0;
            border-top: 0;
          }
        }
        .view-cola-events > .view-content {
          flex: 1 0 auto;
        }
      }
      .cb-list-events__content {
        background: transparent;
        border-bottom: $border--s;
      }
      .pane-title {
        text-align: left;
        &::before {
          display: none;
        }
      }
      .view {
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;
      }
      .cb-list-events__title {
        order: 3;
        font-size: 1rem;
        h3 {
          font-size: 1rem;
          line-height: 1.25;
        }
        a {
          overflow: visible;
          padding: 0;
          margin-right: 0;
          max-height: 100%;
          &::before, &::after {
            display: none;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .cb-list-events__date {
        font-size: 0.9rem;
        font-weight: 700;
        display: block;
        color: color(neutral-brand, lo);
        order: 1;
        margin-bottom: 0.25rem;
        + .cb-list-events__time{
          margin-bottom: 0.25rem;
          margin-top: -0.25rem;
        }
        .date-display-end {
          display: inline-block;
          margin-left: 0;
        }
        &::before,
        .field-name-field-event-date {
          float: left;
        }
        .field-name-field-event-date {
          @include icon($icon: "calendar", $color: #{color(neutral-brand, lo)}, $size: 1rem) {
            margin-right: 0.4rem;
            margin-top: -0.25rem;
          }
        }
      }
      .cb-list-events__time {
        order: 2;
        display: block;
        margin-left: 0;
      }
      .cb-list-events__location {
        order: 4;
        margin-top: $spacing--s;
        margin-bottom: 0;
        a {
          font-size: 0.8rem;
        }
      }
      /* stylelint-disable selector-max-compound-selectors */
      .view-content > ul {
        > li {
          border: none;
          margin: 0;
          padding-top: 0.8rem;
          margin-bottom: 0.85rem;
        }
        .cb-layout__component-dynamic {
          // fix hidden focus outlines
          overflow: inherit;
        }
      }
      /* stylelint-enable selector-max-compound-selectors */
      .column-first {
        display: flex;
        flex-direction: column;
      }
      .more-link {
        border: none;
        text-align: left;
        margin-top: $spacing--m;
        margin-bottom: $spacing--l;
        a {
          font-size: 1.1rem;
          border: none;
          padding: 0;
          color: color(action, m);
          @include icon($icon: "caret-right", $pseudo: "after", $size: 0.8em);
          @media (hover: hover) {
            &:hover::after {
              @include animated($duration: 1s, $name: wobble);
            }
          }
          &:hover,
          &:focus,
          &:active {
            @include media(bp(m)) {
              background: transparent;
              color: color(action, m);
            }
          }
        }
      }
    }
  }
}
