// Individual news topic page
.cb-list-news--standard.cb-list-news--topic {
  .cb-content-area__main {
    display: flex;
    flex-direction: column;
    @include media(bp(m)) {
      margin-top: 0;
      padding-top: 3em;
    }
    // Position relative throws off firefox padding calculations.
    // We there fore only set it when js is not enabled to align the
    // back to news link.
    .no-js & {
      position: relative;
    }
    > .pane-page-title {
      order: 1;
      @include media(bp(m)) {
        order: 2;
      }
    }
    > .cb-news-description {
      order: 2;
      @include media(bp(m)) {
        order: 3;
      }
    }
    > .pane-menu-cola-news-categories {
      order: 3;
      @include media(bp(m)) {
        order: 4;
      }
    }
    > .cb-list-news__menu {
      order: 4;
      margin-bottom: 0.5rem;
      @include media(bp(m)) {
        order: 1;
        margin-bottom: 0;
      }
    }
    > .pane-page-title,
    .panels-ipe-portlet-content > .pane-page-title {
      max-width: 100%;
      margin: 0;
      @include media(bp(m)) {
        margin-top: 1em;
      }
    }
    // Back to Newsroom link
    > .pane-link-to-list,
    .panels-ipe-portlet-content > .pane-link-to-list {
      position: absolute;
      top: -2em;
      left: 0;
      text-align: left;
      width: auto;
      margin: 0;
      @include media(bp(m)) {
        top: 0;
      }
      @include media(bp(l)) {
        top: 0.5em;
      }
    }
    + .cb-content-area__feature-rows {
      margin-top: 0;
      // override default featured layout styles
      .region .cps-region-inner {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  // Fix ipe issues
  &.panels-ipe-editing {
    .cb-content-area__main {
      .panels-ipe-portlet-content > .pane-page-title {
        margin-top: 0;
      }
      .panels-ipe-portlet-content > .pane-link-to-list {
        position: static;
      }
    }
  }
  // Subtopics links
  .pane-menu-cola-news-categories {
    background: lighten(color(neutral-brand, xxhi), 14%);
    margin-bottom: 2em;
    padding: 0;
    width: 100%;
    @include media(bp(m)) {
      display: flex;
    }
    @include media(bp(xl)) {
      margin-top: 0;
    }
    h2 {
      padding: 0.5em 1.1em;
      background: color(neutral-brand, xxlo);
      color: #fff;
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 1.2;
      margin: 0;
      overflow-wrap: normal;
      word-wrap: normal;
      word-break: normal;
      @include media(bp(m)) {
        border: none;
        padding: 0.9em 1em 1em 1em;
      }
    }
    nav {
      padding: 0.35em;
      width: 100%;
      @include media(bp(m)) {
        padding: 0.5em;
      }
    }
    // Subtopics nav
    ul.menu {
      padding: 0.5em 0 0.1em 0;
      @include media(bp(m)) {
        padding: 0;
      }
      .lt-ie10 & {
        min-height: 60px;
      }
      @include media(bp(bs)) {
        column-count: 2;
      }
      @include media(bp(m)) {
        column-count: 1;
      }
      li {
        margin-bottom: 0.5em;
        display: block;
        line-height: 1.4;
        @include media(bp(m)) {
          float: left;
          margin-bottom: 0;
          line-height: 1.8;
          display: inline;
          &:first-child {
            padding-left: 0;
          }
        }
        @include media(bp(m)) {
          &:not(:last-child) {
            a {
              border-right: 1px solid color(neutral-brand, hi);
            }
          }
        }
        a {
          font-size: 0.9rem;
          color: a11yrize(color(action, m), lighten(color(neutral-brand, xxhi), 14%));
          font-weight: 600;
          padding: 0 0.7em;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  // News listing
  .cb-list-news--regular-stories {
    h2 {
      &.pane-title {
        @include visually-hidden();
      }
    }
    ul {
      &.pager {
        margin-top: $spacing--xxl;
      }
      &:not(.pager) {
        li {
          border-width: 2px;
        }
      }
    }
    .cb-list-news {
      &__title {
        a {
          font-weight: 600;
        }
      }
    }
    .lt-ie10 & {
      clear: both;
      display: block;
    }
    .view-content {
      border-bottom: $border--m;
    }
  }
  // Override default classy panel styles
  .cb-feature-row-primary,
  .cb-feature-row-secondary {
    .region.cb-padding {
      padding-top: 0;
    }
  }
  .cb-feature-row-secondary {
    .region.cb-padding {
      @include media(bp(m)) {
        margin-top: -2em;
      }
    }
  }
  .cb-content-area__main {
    .cps-region-inner {
      position: relative;
    }
  }
  .cb-feature-row-primary {
    &:only-child {
      ul {
        border-bottom: $border--m;
        padding-bottom: $spacing--m;
      }
    }
  }
  // Override top stories styles
  // Fix double margin issue
  .cb-list-news--top-stories {
    .view-content {
      border-bottom: none;
    }
    .cb-layout__component-dynamic:not(.column-second-empty) {
      .column-second {
        margin-top: 0;
      }
    }
    // Top story two - four (benath main top story)
    li {
      &:first-child:nth-last-child(2),
      &:nth-child(n+2) {
        .cb-list-news__best-category {
          .lt-ie10 & {
            margin-top: -1rem;
          }
        }
      }
    }
  }
}

// Default overrides if only one button is displayed.
.cb-list-news--standard.cb-list-news--topic {
  &.cb-list-news--with-topics:not(.cb-list-news--with-search),
  &.cb-list-news--with-search:not(.cb-list-news--with-topics) {
    .cb-list-news__menu {
      .show-hide-controller {
        @include media(bp(m)) {
          right: $page-margins;
        }
        @include media(bp(l)) {
          top: 0.5rem;
        }
      }
    }
    .cb-content-area__main {
      @include media(bp(l)) {
        padding-top: 4em;
      }
    }
  }
}
