// [DP-826] this uses to many selectors and needs to be revised.
/* stylelint-disable selector-max-compound-selectors */
.node-type-news {
  .cb-feature-primary {
    .pane-node-field-image-hero {
      img {
        width: 100%;
      }
    }
    .pane-node-field-news-video {
      @include media(bp(l)) {
        margin-bottom: 0;
        top: 0;
      }
      .field-name-field-video-image {
        position: relative;
      }
      .file-video-oembed {
        .content {
          position: relative;
          // .5625 = 16:9 default ratio.
          padding-bottom: 56.25%;
          height: 0;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      }
    }
  }
  .cb-feature-secondary,
  .cb-feature-tertiary,
  .cb-feature-quaternary,
  .cb-feature-quinary {
    padding: 0 $component-spacing;
    @include media(bp(m)) {
      padding: 0 $page-margins;
    }
  }
  .cb-feature-quinary {
    // spacing between body field
    // and related information section
    &:nth-child(4) {
      margin-top: 3em;
    }
  }
  .cb-feature-secondary {
    @include media(bp(m)) {
      padding-top: 3rem;
    }
    .cb-container__inner {
      max-width: $max-width;
    }
    .panel-separator {
      display: none;
    }
    .pane-page-title {
      padding: 0;
      h1 {
        margin-top: 1.75rem;
        text-align: center;
        margin-bottom: 0.55em;
        line-height: 1.3;
        @include media(bp(m)) {
          margin-top: 0;
        }
      }
      &:only-child {
        padding-bottom: 0.5em;
      }
      + .pane-node-field-subtitle {
        &::before {
          content: '';
          display: block;
          margin: 0 auto;
          width: 21%;
          border-top: 3px solid tint(color(neutral-brand, m), 25%);
        }
        h2 {
          margin-top: 0.9em;
          line-height: 1.3;
        }
      }
    }
    .pane-node-field-subtitle {
      h2 {
        font-weight: 600;
        margin-bottom: 1.2em;
        @include media(bp(m)) {
          font-size: 2em;
          text-align: center;
        }
      }
    }
    .pane-page-title,
    .pane-node-field-subtitle {
      text-align: center;
      max-width: 47.5rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .cb-feature-tertiary {
    .cb-container__inner {
      max-width: $max-width;
      margin-bottom: 1.5em;
      margin-top: -1em;
      position: relative;
      padding: 0.6 0;
      text-align: right;
      display: flex;
      flex-direction: column;
      @include media(bp(l)) {
        margin-bottom: 3em;
        height: 5em;
        text-align: center;
        display: block;
      }
    }
    .pane-node-field-news-byline,
    .pane-node-field-news-date {
      flex-direction: column;
      font-family: $font--text--alt;
      text-align: center;
      color: color(neutral-brand, m);
      font-size: 1.1em;
      @include media(bp(l)) {
        font-size: 1.2em;
        display: inline;
        * {
          display: inline;
        }
      }
    }
    .pane-node-field-news-byline {
      order: 1;
      + .pane-node-field-news-date {
        @include media(bp(l)) {
          &::before {
            content: "/";
          }
        }
      }
    }
    .pane-node-field-news-date {
      order: 2;
    }
    .pane-link-to-list {
      text-align: left;
      width: 100%;
      border-top: $border--s;
      border-bottom: $border--s;
      order: 4;
      padding: 0.5em 0 0.2em 0;
      @include media(bp(l)) {
        height: 2.8em;
        position: absolute;
        margin-top: 0;
        top: calc(100% - 1.4em);
      }
    }
    .pane-share-widgets {
      margin-top: 0.5em;
      order: 3;
      @include media(bp(l)) {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: 0;
      }
      .item-list {
        ul {
          display: flex;
          justify-content: center;
          @include media(bp(l)) {
            width: auto;
            display: block;
          }
          li {
            font-size: 1em;
          }
        }
      }
      .cb-share-widgets {
        @include media(bp(l)) {
          display: block;
          position: absolute;
          top: 82%;
          white-space: nowrap;
          right: 0;
        }
        a {
          position: relative;
          width: 44px;
          height: 44px;
          color: $btn-bg-color-interior-pg-component;
          @include media(bp(l)) {
            width: 28px;
            height: 28px;
          }
          &:hover {
            color: $btn-hover-bg-color-interior-pg-component;
          }
          &::before {
            font-size: 44px;
            @include media(bp(l)) {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
  .cb-feature-quaternary {
    .cb-container__inner {
      @include media(bp(xl)) {
        position: relative;
      }
      .pane-node-field-news-references,
      .pane-node-field-news-media-contact,
      .pane-node-field-news-categories,
      .pane-node-field-news-tags {
        margin: 3em auto;
        clear: both;
        max-width: 47.5rem;
      }
      .pane-node-field-news-references,
      .pane-node-field-news-categories,
      .pane-node-field-news-tags {
        border-top: $border--s;
        padding-top: 2em;
      }
      .pane-node-field-news-categories {
        @include media(bp(xl)) {
          margin: 0;
          border-top: 0;
          padding-top: 1em;
        }
      }
    }
  }
  .pane-node-body {
    overflow: hidden;
    &:last-child {
      margin-bottom: $spacing--xl;
      @include media(bp(m)) {
        margin-bottom: $spacing--xxl;
      }
    }
    ~ *,
    .field-name-body > *:not(.file-cola-wysiwyg-teaser):not(.file-cola-wysiwyg-full):not(blockquote):not(.ck-pullquote):not(.ck-callout--right):not(.ck-callout--left) {
      max-width: 47.5rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  blockquote {
    max-width: 35rem;
    margin: 0 auto 1.9rem auto;
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
  .file-cola-wysiwyg-teaser,
  .file-cola-wysiwyg-full {
    img {
      width: 100%;
      max-height: 100%;
    }
  }
  .file-cola-wysiwyg-teaser {
    @include media(bp(m)) {
      max-width: 35rem;
      width: 50%;
    }
    // Force align right to avoid overlapping with topics.
    &:first-child {
      &.align-left {
        @include media(bp(m)) {
          margin: 0 0 1.25em 1.5em;
          float: right;
          clear: none;
          clear: right;
        }
      }
    }
    &:not(:first-child) {
      margin-top: 2.1em;
      @include media(bp(m)) {
        margin-top: 0.75em;
      }
    }
    &.cb-image-portrait {
      @include media(bp(m)) {
        width: auto;
      }
      img {
        width: auto;
        max-height: 400px;
      }
    }
    &.cb-image-squarish {
      @include media(bp(m)) {
        width: auto;
      }
      img {
        width: auto;
        max-height: 300px;
      }
    }
  }
  .file-cola-wysiwyg-full {
    width: 100%;
    max-width: 100%;
    // Force align-center if img is first child to avoid overlap with topics.
    &.align-center,
    &:first-child {
      max-width: 47.5rem;
    }
  }
  .pane-node-field-news-references,
  .pane-node-field-news-media-contact {
    font-family: $font--text--alt;
    font-size: 1.125rem;
    p {
      font-size: 1.125rem;
    }
  }
  .pane-node-field-news-references p a {
    font-family: $font--text--alt;
    font-size: 1.125rem;
  }
  .pane-node-field-news-tags,
  .pane-node-field-news-categories {
    .pane-title {
      font-size: 1.8em;
    }
  }
  .pane-node-field-news-tags {
    .field-name-field-news-tags {
      font-size: 1.125rem;
      font-family: $font--text--alt;
    }
  }
  .pane-node-field-news-categories {
    font-family: $font--text--alt;
    font-size: 1rem;
    @include media(bp(s)) {
      font-size: 1.125rem;
    }
    @include media(bp(xl)) {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 11.5em;
      font-size: 1rem;
      background-color: $background-solid-xlight;
      padding: 1em;
      + .panel-separator {
        display: none;
      }
    }
    .pane-title {
      @include media(bp(xl)) {
        font-size: 1.1rem;
        padding-bottom: 0.5em;
        font-weight: 600;
        border-bottom: 1px solid color(neutral-brand, xxhi);
      }
    }
    .pane-title, div {
      @include media(bp(xl)) {
        display: block;
      }
    }
    .field-name-field-news-categories {
      font-size: 1rem;
      @include media(bp(s)) {
        font-size: 1.125rem;
      }
      @include media(bp(xl)) {
        font-size: 0.9em;
      }
      a {
        color: color(action, m);
        @include media(bp(xl)) {
          color: a11yrize(color(action, m), $background-solid-xlight);
          font-weight: 600;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .pane-node-field-news-media-contact {
    padding: 1em;
    background-color: $background-solid-xlight;
    @include media(bp(m)) {
      padding: 1.1em 1.5em 1.5em 1.5em;
    }
    h2 {
      @include media(bp(s)) {
        font-size: 1.8em;
      }
    }
    h3 {
      font-size: 1.2em;
    }
    a {
      color: a11yrize(color(action, m), $background-solid-xlight);
      &:hover {
        text-decoration: underline;
        &::before {
          display: inline-block;
          // to make 'no underline' work in IE11,
          // this needs to be declared first
          // followed by text-decoration: none
          // in separate declaration below
          text-decoration: underline;
        }
        &::before {
          text-decoration: none;
        }
      }
    }
    .field-name-field-text-text {
      p,
      li {
        font-size: 0.8em;
      }
      a {
        text-decoration: none;
        font-family: $font--display;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .field-name-field-news-media-contact {
      > *:not(:first-child) {
        margin-top: 0.75em;
      }
    }
    .cb-contact-information {
      font-size: 1rem;
      line-height: 1.5;
      &:not(:last-child) {
        border-bottom: 2px solid #fff;
        padding-bottom: 2em;
        margin-bottom: 1em;
      }
      &:last-child:not(:first-child) {
        border-top: 2px solid #fff;
        padding-top: 1em;
        margin-top: 2em;
      }
      .field-type-geofield {
        margin-top: 0.5em;
      }
      .field-type-phone {
        p {
          color: color(neutral-brand, xxlo);
        }
      }
      &.cw-with-map {
        .cb-contact-information__map {
          padding: 0;
          @include media(bp(s)) {
            margin-top: 0;
            padding: 0 1em 1em 0;
          }
          @include media(bp(m)) {
            width: 40vw;
          }
          @include media(bp(l)) {
            width: 45%;
          }
        }
        > .content {
          max-width: 100%;
          background: transparent;
          padding-right: 0;
          padding-top: 0;
          color: color(neutral-brand, xxlo);
          @include media(bp(s)) {
            padding-top: 1em;
          }
          @include media(bp(m)) {
            min-height: 30vw;
            padding-left: calc(40vw + 0.5em);
          }
          @include media(bp(l)) {
            min-height: 227px;
            padding-left: calc(45% + 0.5em);
          }
          > *:not(.field-type-geofield) {
            padding: 0;
          }
        }
      }
    }
  }
}
/* stylelint-enable */
