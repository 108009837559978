/// animated
///
/// Adds base styles for animated text.
/// @param {string} $duration [1s]- duration of animation in seconds
/// @param {string} $fill-mode [both] - determines if animation styles remain applied after animation is over
/// @param {mixed} $repeat [1] - how often is the animation repeated (number or "infinite")
/// @param {string} $name [1] - name of the animation keyframes
/// @param {string} $direction [1] - which direction should the keyframes be played in
/// @group mixins
/// @link https://github.com/daneden/animate.css
@mixin animated($duration: 1s, $fill-mode: both, $repeat: 1, $name: slideInDown, $direction: normal) {
  animation-duration: $duration;
  animation-fill-mode: $fill-mode;
  animation-iteration-count: $repeat;
  animation-name: $name;
  animation-direction: $direction;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}
