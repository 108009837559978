// Promoted News, Blogs, Announcements Background Styles

// Default across all pages
.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      .view-content {
        border-color: var(--site-accent);
      }
      li {
        &:only-child {
          padding: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

.cb-flavor:not(.cb-list-news) {
  .region {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      li {
        @include quantities-all(1) {
          /* stylelint-disable selector-max-compound-selectors */
          // no image: add bg to balance layout
          .column-first.column--first:only-child {
            background: $component-bg-1-color;
            padding: $spacing--s;
            @include media(bp(bs)) {
              padding: $spacing--m;
            }
            @include media(bp(l)) {
              padding: $spacing--xl;
            }
            a {
              color: a11yrize(color(action, m), $component-bg-1-color);
            }
            .cb-list-news__date {
              color: color(neutral-brand, lo);
            }
          }
          /* stylelint-enable */
        }
        @include quantity-positions(5,1) {
          padding-bottom: $spacing--xl;
          /* stylelint-disable selector-max-compound-selectors */
          // no image: add bg to balance layout
          .column-first.column--first:only-child {
            background: $component-bg-1-color;
            padding: $spacing--s;
            @include media(bp(bs)) {
              padding: $spacing--m;
            }
            @include media(bp(l)) {
              padding: $spacing--xl;
            }
            a {
              color: a11yrize(color(action, m), $component-bg-1-color);
            }
            .cb-list-news__date {
              color: color(neutral-brand, lo);
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

@include component-region-bg-styles(1) {
  .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
  .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
    .cb-list-news {
      &__title,
      &__best-category {
        a {
          color: a11yrize(color(action, m), $region-bg-1-color);
        }
      }
      &__date,
      &__best-category {
        color: a11yrize($news-promoted-date-color, $region-bg-1-color);
      }
    }
    .view-content {
      ul:not(.panels-ipe-linkbar) {
        li {
          &:not(:only-child):not(:last-child),
          &:not(:only-child):not(:last-child)::after {
            border-color: #fff;
          }
          @include quantity-positions(5,2,3,4) {
            border-color: #fff;
            &::after {
              border-color: #fff;
            }
          }
          @include quantities-all(1) {
            /* stylelint-disable selector-max-compound-selectors */
            // no image: add bg to balance layout
            .column-first.column--first:only-child {
              background: $component-bg-auto-color;
              a {
                color: color(action, m);
              }
            }
            /* stylelint-enable */
          }
          @include quantity-positions(5,1) {
            /* stylelint-disable selector-max-compound-selectors */
            // no image: add bg to balance layout
            .column-first.column--first:only-child {
              background: $component-bg-auto-color;
              a {
                color: color(action, m);
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
    &.cb-list-news--topic {
      h2 {
        a {
          color: a11yrize(color(action, m), $region-bg-1-color);
        }
      }
    }
  }
}

@include component-region-bg-styles(2) {
  .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
  .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
    .view-content {
      border-color: $component-bg-2-border-color;
    }
  }
}

@include component-region-bg-styles(3) {
  .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
  .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
    .view-content {
      border-color: $component-bg-3-border-color;
    }
  }
}

// Region bg 2 and 3:
// add content padding since list items
// need white background in blue colored regions
@include component-region-bg-styles(2,3) {
  .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
  .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
    ul:not(.panels-ipe-linkbar) {
      margin: $spacing--m 0;
      background: $component-bg-auto-color;
      padding: $spacing--m;
      li {
        &:not(:only-child) {
          @include media(bp(l)) {
            padding: 0;
          }
        }
        @include quantities-all(1) {
          padding: 0;
          .cb-list-news__image-teaser {
            margin-top: 0;
          }
        }
        @include quantity-positions(5,1) {
          padding: 0;
          .cb-list-news__image-teaser {
            margin-top: 0;
          }
          @include media(bp(l)) {
            padding-bottom: $spacing--m;
          }
          .column-first.column--first {
            padding-bottom: $spacing--m;
            // news item with text only
            &:only-child {
              margin-bottom: $spacing--l;
              @include media(bp(l)) {
                padding-bottom: $spacing--l;
                margin-bottom: $spacing--s;
              }
            }
          }
        }
        @include quantity-positions(5,2,3,4,5) {
          @include media(bp(bs)) {
            margin-top: $spacing--xl;
            padding-top: 0;
          }
          @include media(bp(l)) {
            @include span-columns(3) {
              margin-top: 0;
              margin-bottom: $spacing--m;
              @include omega();
            }
          }
          .cb-list-news__image-teaser {
            @include media(bp(bs)) {
              margin-top: 0;
            }
          }
        }
        @include quantity-positions(5,1,2,3,4) {
          @include media($bp-only-m) {
            margin-bottom: 0;
          }
          @include media(bp(l)) {
            padding-right: 0;
            border-right: none;
          }
        }
        @include quantity-positions(5,2,3,4) {
          @include media(bp(l)) {
            margin-right: 1.6%;
          }
        }
      }
    }
    &.cb-list-news--topic {
      h2 {
        a {
          color: #fff;
        }
      }
    }
  }
}
