@font-face {
  $compile-id: $font-compile-id;
  font-family: "#{$icon-font-family}";
  src: url("#{$icon-font-path}#{$icon-font-base-name}.eot?v=#{$compile-id}");
  src: /* stylelint-disable-line declaration-colon-space-after */
    url("#{$icon-font-path}#{$icon-font-base-name}.eot?v=#{$compile-id}") format("eot"),
    url("#{$icon-font-path}#{$icon-font-base-name}.woff?v=#{$compile-id}") format("woff"),
    url("#{$icon-font-path}#{$icon-font-base-name}.ttf?v=#{$compile-id}") format("truetype"),
    url("#{$icon-font-path}#{$icon-font-base-name}.svg?v=#{$compile-id}#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

/**
 * Font application to generic DOM
 */

// @font-face {
//   font-family: $icon-font-base-name;
//   src: $icon-font-source-1;
//   src: $icon-font-source-2;
//   font-weight: normal;
//   font-style: normal;
// }

// Everything with .icon--something has a base set of styles in order to view
[class*="#{$icon-font-class-prefix}--"] {
  @include font-icon-base();
}

// Print .icon--thingy classes using default ::before for easy elements
@each $icon-name, $icon-symbol in $font-icons {
  .#{$icon-font-class-prefix}--#{$icon-name}::before {
    content: map-get($font-icons, $icon-name);
  }
}
