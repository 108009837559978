// Grid Listing:
// Services, Academic Programs, Specialties

.cb-list-specialties--grid,
.cb-list-programs--grid,
.cb-list-services--grid {
  .cb-layout__component-dynamic {
    overflow: visible;
    height: 100%;
  }
  .cb-list-specialties,
  .cb-list-programs,
  .cb-list-services {
    .view-content {
      > ul {
        > li {
          width: 100%;
          position: relative;
          &:not(:first-child) {
            margin-top: 1.5em;
            @include media(bp(s)) {
              margin-top: 0;
            }
          }
          // stylelint-disable selector-max-compound-selectors
          @media (hover: hover) {
            &:hover {
              background: $background-solid-xlight;
            }
          }
          // stylelint-enable
        }
      }
    }
    &__content {
      .cb-overlay-link {
        z-index: 2;
      }
      img {
        width: 100%;
      }
    }
  }
  .cb-list-specialties,
  .cb-list-programs,
  .cb-list-services {
    .view-content {
      > ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        > li {
          border: $component-border-style;
          margin-bottom: 0;
        }
      }
    }
    .column-third-empty:not(.column-second-empty) {
      position: relative;
      padding-top: 56.25%;
      .column-first,
      .column-second {
        width: 100%;
        float: none;
      }
      .column-second {
        position: absolute;
        top: 0;
        left: 0;
        float: none;
      }
    }
    .column-first {
      @include component-padding();
    }
    &__title {
      h3 {
        font-size: 1.25rem;
        margin-bottom: 0.5em;
      }
    }
    li {
      .cb-list-specialties,
      .cb-list-services,
      .cb-list-programs {
        &__title a {
          color: color(action, m);
        }
      }
      &:hover .cb-list-specialties,
      &:hover .cb-list-services,
      &:hover .cb-list-programs {
        &__title a {
          color: a11yrize(color(action, m), $background-solid-xlight);
        }
      }
    }
    &__summary {
      p {
        font-size: 0.9rem;
        line-height: 1.6;
      }
    }
  }
}

.cb-list-specialties--grid,
.cb-list-programs--grid,
.cb-list-services--grid {
  .cb-content > .pane-page-content {
    // Being overly specifica here because we don't want any list styles to
    // apply to potential lists inside the listing.
    /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
    .cb-layout--dynamic-features-asides {
      // Default: display max three per row
      &.cb-layout--aside-first,
      &.cb-layout--aside-last,
      &.cb-layout--no-aside {
        .cb-list-specialties,
        .cb-list-programs,
        .cb-list-services {
          .view-content {
            > ul {
              > li {
                &:not(:only-child) {
                  @include media($bp-only-s) {
                    @include span-columns(6);
                    &:nth-child(2n+2) {
                      @include omega();
                    }
                    &:nth-child(2n+3) {
                      clear: both;
                    }
                    &:nth-child(n+3) {
                      margin-top: 3.3%;
                    }
                  }
                  @include media(bp(m)) {
                    @include span-columns(4);
                    &:nth-child(3n+3) {
                      @include omega();
                    }
                    &:nth-child(3n+4) {
                      clear: both;
                    }
                    &:nth-child(n+4) {
                      margin-top: 3.3%;
                    }
                  }
                  @include media(bp(l)) {
                    .column-first {
                      padding: $spacing--m;
                    }
                  }
                  @include quantities-all(2,4) {
                    @include media(bp(s)) {
                      @include span-columns(6);
                      &:nth-child(2n+2) {
                        @include omega();
                      }
                      &:nth-child(2n+3) {
                        clear: both;
                      }
                      &:nth-child(n+3) {
                        margin-top: 3.3%;
                      }
                    }
                    @include media(bp(l)) {
                      .column-first {
                        padding: $spacing--l;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // Page with both sidebars: display max two per row
      &.cb-layout--aside-first-last {
        .cb-list-specialties,
        .cb-list-programs,
        .cb-list-services {
          .view-content {
            > ul {
              > li {
                &:not(:only-child) {
                  @include media(bp(s)) {
                    @include span-columns(6);
                    &:nth-child(2n+2) {
                      @include omega();
                    }
                    &:nth-child(2n+3) {
                      clear: both;
                    }
                    &:nth-child(n+3) {
                      margin-top: 3.3%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    /* stylelint-enable */
  }
}
