// Events listing styles only
.cb-list-events--standard {
  .cb-list-events {
    .view-content {
      > ul {
        > li {
          padding-top: $spacing--m;
          margin-bottom: 1.9375rem;
          &:first-child {
            border-top: $border--l;
          }
          &:not(:first-child) {
            border-top: $border--s;
          }
          &:last-child {
            padding-bottom: 1.9375rem;
            border-bottom: $border--m;
          }
        }
      }
      .cb-layout__component-dynamic {
        // fix focus outlines
        overflow: visible;
      }
      .column-first {
        display: flex;
        flex-direction: column;
        .cb-list-events {
          &__date {
            order: 1;
          }
          &__time {
            order: 2;
          }
          &__title {
            order: 3;
          }
          &__location {
            order: 4;
          }
          &__categories {
            order: 5;
          }
          &__summary {
            order: 6;
          }
        }
        > .panel-pane {
          &:not(.cb-list-events__categories):not(.cb-list-events__summary) {
            margin: 0.33em 0;
            &.cb-list-events {
              &__time {
                margin-top: 0;
              }
              &__date {
                margin-top: 0;
                margin-bottom: 0;
              }
              &__title {
                margin-top: $spacing--m;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    // note: summary markup varies for bedework events
    &__summary,
    &__summary p {
      font-size: 1rem;
      line-height: 1.5;
      @include media(bp(m)) {
        font-size: 1.1rem;
        line-height: 1.6;
      }
    }
    &__summary {
      margin-top: 0.5em;
      @include media(bp(m)) {
        margin-top: 0.33em;
      }
    }
    &__date,
    &__time {
      color: color(neutral-brand, lo);
    }
    &__date {
      font-size: 1.1rem;
      font-weight: 700;
      margin-bottom: 0;
      + .cb-list-events__time {
        font-weight: 400;
      }
    }
    &__time {
      font-size: 0.875rem;
    }
    &__location {
      font-size: 0.75rem;
      color: color(neutral-brand, m);
      @include media(bp(m)) {
        font-size: 0.875rem;
      }
      a {
        font-weight: 400;
        @include icon($icon: "map-marker", $pseudo: before);
        &::before {
          font-size: 0.9em;
          margin: -0.1rem 0.3rem 0 0;
        }
        &:hover {
          text-decoration: underline;
          &::before {
            text-decoration: none;
          }
        }
      }
    }
    &__categories {
      margin-top: 0.66em;
      margin-bottom: -0.6875rem;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      ul:not(.pager) {
        &.show-more-applied {
          display: inline;
        }
        li {
          &:not(.cb-category--deadline) {
            display: none;
          }
          &.cb-category--deadline {
            display: inline-block;
            background-color: $background-solid-light;
            font-size: 0.875rem;
            font-weight: 600;
            padding: 0.2em 0.7em;
            margin: 0 0.7143em 0.6429em 0;
            border-radius: 2px;
            overflow: hidden;
            &:last-child {
              padding-bottom: 0.2em;
            }
          }
        }
      }
      .show-more {
        display: inline-block;
        font-size: 0.875rem;
        font-weight: 600;
        padding: 0.2em 0.7em;
        margin: 0 0.7143em 0.6429em 0;
        border-radius: 2px;
        // stylelint-disable declaration-no-important
        // Overriding inline style added by jQuery.
        overflow: hidden !important;
        // stylelint-enable declaration-no-important
        background-color: $background-solid-light;
        color: a11yrize(color(action), $background-solid-light);
        span {
          @include visually-hidden();
        }
        &--more {
          &::before {
            content: '+ ';
          }
        }
        &--less {
          &::before {
            content: '- ';
          }
        }
      }
      .cb-category--deadline {
        color: color(alert-fail, lo);
        @include icon($icon: 'warning', $size: 0.8em) {
          margin-right: 0.35em;
          margin-top: -0.18em;
        }
      }
      + .cb-list-events__summary {
        margin-top: 0.9rem;
      }
    }
    .view-cola-events-search {
      .pager {
        margin-top: 3.75rem;
      }
    }
    .excess {
      display: inline;
    }
    .show-hide-controller {
      margin-top: 1em;
      margin-left: 1px;
      display: block;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      border-radius: 0;
      font-size: 1rem;
      color: color(action, m);
      text-align: left;
      &:focus {
        outline: 1px dotted color(neutral-brand, m);
      }
      .show-hide {
        &__description {
          @include visually-hidden();
        }
      }
      &::after {
        margin-left: 0.25em;
      }
      &[aria-expanded='true'] {
        &::after {
          content: '\2212';
        }
      }
      &[aria-expanded='false'] {
        &::after {
          content: '\002B';
        }
      }
    }
  }
}
