.node-type-service {
  .pane-node-field-core-facilities-categories {
    margin-top: 1em;
    font-family: $font--text--alt;
    @include h5();
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      display: inline-block;
      background-color: $background-solid-xlight;
      font-size: 0.875rem;
      font-weight: 600;
      padding: 0.2em 0.7em;
      margin: 0 0.7143em 0.6429em 0;
      border-radius: 2px;
    }
  }
  .pane-cola-services-infobox {
    padding: 2em 2.25em;
    margin: 2em 0 1.25em 0;
    border-radius: 2px;
    box-shadow: $box-shadow;
    + .cb-list-news--related-news {
      margin-top: 3.5em;
    }
    @include media(max-width (bp(bs))) {
      // display director image and text fields stacked
      .pane-title,
      .cb-persons > .content > .field-name-field-title {
        text-align: center;
      }
      .content {
        padding-left: 0;
        .field-name-field-person-image {
          position: relative;
          order: 1;
          margin-bottom: 0.5rem;
        }
        .field-name-field-person-image,
        .cb-person__name,
        .cb-person__positions,
        .cb-person__phone,
        .cb-person__email,
        .field-name-field-person-link,
        .show-hide-controller {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
        .cb-person__name,
        .cb-person__name + .item-list,
        .cb-person__positions,
        .cb-person__phone,
        .cb-person__email,
        .field-name-field-person-link,
        .show-hide-controller,
        .field-name-field-person-bio {
          order: 2;
        }
      }
    }
  }
  .pane-node-field-services-affiliation,
  .pane-node-field-services-directors,
  .pane-node-field-services-contact {
    .pane-title {
      @include h3();
    }
    &:not(:first-child) {
      border-top: $border--s;
      padding-top: 2em;
      margin: 1.5em 0 0 0;
    }
  }
  .pane-node-field-services-affiliation {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      margin : 0;
    }
    .field-name-field-services-affiliation {
      font-family: $font--text--alt;
    }
  }
  .paragraphs-items {
    .cb-contact-information {
      &.cw-with-map {
        > .content {
          @include media(bp(s)) {
            min-height: calc(28.5vw + 2em + 1px);
            padding-left: calc(38vw + 2em);
          }
          @include media(bp(l)) {
            font-size: 0.9em;
            min-height: calc(19.5vw + 2em + 1px);
            padding-left: calc(26vw + 2em);
          }
        }
      }
      &__map {
        @include media(bp(s)) {
          width: 38vw;
          top: 1em;
          left: 1em;
          background-color: transparent;
        }
        @include media(bp(l)) {
          width: 26vw;
        }
      }
    }
    &:not(.paragraphs-items-field-lab-location) {
      .cb-contact-information {
        &.cw-with-map {
          > .content {
            @include media(bp(xl)) {
              min-height: calc(255px + 2rem);
              padding-left: calc(50% + 2rem);
            }
          }
        }
        &__map {
          @include media(bp(xl)) {
            width: 50%;
            background-color: transparent;
            padding: 0;
          }
        }
      }
    }
  }
  .cb-layout--aside-first-last {
    .paragraphs-items {
      .cb-contact-information {
        &.cw-with-map {
          > .content {
            @include media(bp(xl)) {
              min-height: calc(182px + 2rem);
            }
          }
        }
      }
    }
  }
  .cb-layout--aside-last {
    .paragraphs-items {
      .cb-contact-information {
        &.cw-with-map {
          > .content {
            @include media(bp(xl)) {
              min-height: calc(257px + 2rem);
            }
          }
        }
      }
    }
  }
  .cb-layout--aside-first,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last {
    .paragraphs-items {
      .cb-contact-information {
        &.cw-with-map {
          > .content {
            @include media(bp(xl)) {
              min-height: calc(294px + 2rem);
            }
          }
        }
      }
    }
  }
  .show-hide-controller:focus {
    outline-color: color(primary);
  }
  // Fix spacing for structured content text component
  .cb-content-area__main {
    .pane-node-field-generic-paragraphs {
      &:not(:first-child) {
        margin-top: 2em;
      }
      .field-name-field-generic-paragraphs {
        /* stylelint-disable selector-max-compound-selectors */
        > * {
          margin-top: 2em;
        }
        > h3.field-name-field-title {
          + .field-name-field-text-text {
            margin-top: 1em;
          }
        }
        /* stylelint-enable */
      }
    }
  }
}
