// Structured content Text component

.cb-content-area__main {
  .cb-structured-content {
    .field-name-field-page-paragraphs,
    .field-name-field-generic-paragraphs {
      > h3.field-name-field-title {
        margin-bottom: 0;
        + .field-name-field-text-text {
          margin-top: 1em;
        }
      }
      > .field-name-field-link-link a {
        font-family: $font--family--regular;
      }
    }
  }
}
