// Component Headings Mixins

@mixin component-heading($size: m) {
  font-family: $font--display;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: $spacing--s;
  @if $size == xs {
    font-size: 1.2rem;
  }
  @else if $size == s {
    font-size: 1.35rem;
  }
  @else if $size == m {
    font-size: 1.6rem;
  }
  @else if $size == l {
    font-size: 1.8rem;
  }
  @else if $size == xl {
    font-size: 2rem;
    line-height: 1.2;
  }
  @else if $size == xxl {
    font-size: 2.2rem;
    line-height: 1.2;
  }
  @else {
    @warn 'Invalid size argument: #{$size}';
  }
}

@mixin related-component-heading($size: l) {
  font-family: $font--display;
  font-weight: 600;
  line-height: 1.15;
  @if $size == l {
    font-size: 1.6rem;
    @include media(bp(xs)) {
      font-size: 1.8rem;
    }
    @include media(bp(m)) {
      font-size: 2rem;
      line-height: 1.2;
    }
  }
  @else if $size == m {
    font-size: 1.35rem;
    @include media(bp(s)) {
      font-size: 1.6rem;
    }
    @include media(bp(m)) {
      font-size: 1.8rem;
    }
    @include media(bp(l)) {
      font-size: 2rem;
      line-height: 1.2;
    }
  }
  @else {
    @warn 'Invalid size argument: #{$size}';
  }
}

@mixin component-subtitle($size: m) {
  @if $size == xs {
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0 0 0.5rem 0;
  }
  @else if $size == s {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4;
    margin: 0 0 0.5rem 0;
    @include media(bp(m)) {
      font-size: 1.15rem;
    }
    @include media(bp(l)) {
      font-size: 1rem;
    }
  }
  @else if $size == m {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4;
    margin: 0 0 0.5rem 0;
    @include media(bp(m)) {
      font-size: 1.15rem;
    }
  }
  @else if $size == l {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4;
    margin: 0 0 0.5rem 0;
    @include media(bp(m)) {
      font-size: 1.15rem;
    }
    @include media(bp(l)) {
      font-size: 1.3rem;
    }
  }
  @else {
    @warn 'Invalid size argument: #{$size}';
  }
}

@mixin component-heading-right-rail() {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.25;
  text-align: center;
}

@mixin component-sub-heading-right-rail() {
  font-size: 1rem;
  line-height: 1.25;
}
