// Layout
.front,
.cb-section-front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--dynamic-features-asides-feature-rows.cb-layout--no-aside {
  .region.cb-padding--none {
    .cb-curator,
    .cb-airtable {
      &.pane {
        &:only-child {
          // stylelint-disable declaration-block-no-redundant-longhand-properties
          padding-top: $swim-lanes--s;
          padding-bottom: $swim-lanes--s;
          padding-left: $component-spacing;
          padding-right: $component-spacing;
          // stylelint-enable declaration-block-no-redundant-longhand-properties
          @include media(bp(l)) {
            padding-top: $swim-lanes--l;
            padding-bottom: $swim-lanes--l;
          }
          @include media(bp(xxl)) {
            padding-top: 0;
            padding-bottom: 0;
          }
          // Only remove the sixde padding if we have enough excess side space.
          // 2em (~32px) one either side.
          @include media((bp(xxl) + 3em)) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

// Typography
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region.cb-padding {
    &.cb-background--2,
    &.cb-background--3 {
      .cb-curator,
      .cb-airtable {
        &__title,
        &__description,
        &__description p {
          color: #fff;
        }
        &__description a {
          text-decoration: underline;
          color: #fff;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.cb-curator,
.cb-airtable {
  &__title {
    text-align: center;
    @include component-heading(m);
    @include media(bp(xs)) {
      @include component-heading(l);
    }
    @include media(bp(m)) {
      @include component-heading(xl);
    }
    @include media(bp(l)) {
      @include component-heading(xxl);
    }
  }
  // Constraining the description width for a more comfortable measure.
  &__description {
    max-width: $max-width;
    margin: 0 auto;
  }
  &__description,
  &__description p {
    font-family: $font--text--alt;
    text-align: center;
  }
  &__description a {
    text-decoration: underline;
    font-family: $font--text--alt;
    &:hover {
      text-decoration: none;
    }
  }
}

// Sidebar styles
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    .cb-curator__title {
      @include media(bp(xl)) {
        @include component-heading-right-rail();
      }
    }
    .cb-curator__feed {
      border-top: $border--m;
    }
    .crt-load-more {
      border: none;
      padding-top: 0;
    }
  }
}

.cb-content-area__aside-last {
  .cb-curator,
  .cb-airtable {
    padding: $sidebar-component-right-rail-padding;
    &__title {
      color: $sidebar-component-title-color;
      text-align: center;
      @include component-heading(s);
      @include component-heading-right-rail();
      @include media(bp(bs)) {
        @include component-heading(m);
      }
      @include media(bp(s)) {
        @include component-heading(l);
      }
      @include media(bp(m)) {
        @include component-heading(xl);
      }
    }
    &__title,
    &__description,
    &__description p,
    &__description a {
      color: $sidebar-component-title-color;
    }
    &__description a {
      text-decoration: underline;
      font-family: $font--text--alt;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// ipe styles
.panels-ipe-editing {
  .cb-curator__feed,
  .cb-airtable__embed {
    &::before {
      content: "Placeholder for embedded feed.";
      display: block;
      text-align: center;
    }
  }
  .cb-curator__feed-container,
  .cb-curator__more-button,
  .cb-airtable__iframe {
    display: none;
  }
  .crt-widget-waterfall {
    min-height: 0;
    padding-bottom: 0;
  }
}
