// Base styles for
// Related Profiles
// (Conditions & Treatments, CUPS location, News article, Testimonial, Unit)
// and Related Services
// (Conditions & Treatments, CUPS Location & Profile, News Article, Testimonial)
// Related Specialties (CUPS Location)

.cb-list-profiles--related-profiles,
.cb-list-profiles--by-unit,
.node-type-cups-location .pane-cola-search-location-providers,
.cb-list-services--related-services,
.node-type-cups-location .pane-node-field-services,
.node-type-cups-location .cb-list-specialties--related-specialties {
  ul:not(.pager) {
    margin: 0;
    list-style-type: none;
    @include media(bp(m)) {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      margin-bottom: $spacing--m;
      @include media(bp(m)) {
        // display 3 columns
        @include span-columns(4);
        &:only-child {
          margin-bottom: 0;
          @include span-columns(12);
        }
        &:nth-child(3n) {
          @include omega();
        }
        // 2 items: display 2 columns
        &:first-child:nth-last-child(2),
        &:last-child:nth-child(2) {
          margin-bottom: 0;
          @include span-columns(6);
        }
        &:last-child:nth-child(2) {
          margin-right: 0;
        }
        // adjust last row list items bottom margins
        &:first-child:nth-last-child(3),
        &:nth-child(2):nth-last-child(2),
        &:last-child,
        &:nth-child(3n+4):nth-last-child(3),
        &:nth-child(3n+4):nth-last-child(2),
        &:nth-child(3n+5):nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }
    h3,
    a {
      @include component-link-title(s);
    }
  }
  // pagination
  .item-list:not(:only-child) {
    margin: 0;
    ul.pager {
      padding-bottom: $spacing--l;
      margin: 0;
      @include media(bp(l)) {
        padding-bottom: $spacing--l;
      }
      a {
        outline-color: $text-link-color;
      }
    }
  }
}
