// Labs and Unit by Type List Component Base Styles

.cb-list-units--component,
.cb-list-labs--component {
  .view-content {
    padding: $spacing--m $spacing--s $spacing--m $spacing--s;
    > ul {
      list-style: none;
      padding: 0;
    }
    li {
      border-top: $border--m;
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
    }
    // pagination
    +.item-list {
      width: 100%;
      .pager {
        li {
          &.pager-current {
            @include media(bp(m)) {
              width: auto;
            }
          }
        }
      }
    }
    +.more-link {
      margin-top: $spacing--l;
      @include media(bp(m)) {
        margin-top: 1.8rem;
      }
    }
  }
  .cb-list-units__content,
  .cb-list-labs__content,
  .view-content {
    @include media(bp(l)) {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      width: 100%;
      // IE needs this or it will throw a hissy fit.
      overflow: hidden;
    }
  }
  ul.pager {
    margin: 0 auto 0.5em auto;
    padding-bottom: 1em;
  }
  // Display image and text stacked
  .cb-layout__component-dynamic {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  // text
  .column-first {
    order: 2;
    width: 100%;
  }
  // image
  .column-second {
    width: 100%;
    order: 1;
    border-top: 1px solid #fff;
    img {
      width: 100%;
    }
  }
  .cb-list-units,
  .cb-list-labs {
    &__title {
      .field-name-title-field {
        padding: 0.8rem $spacing--m 0.8rem $spacing--m;
      }
    }
    &__summary {
      padding: 0.8rem $spacing--m 1.2rem $spacing--m;
    }
  }
}
