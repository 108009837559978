@mixin max-page-width($margins: true, $flex: false) {
  margin: 0 auto;
  max-width: $max-width;
  display: block;
  @if $flex {
    display: flex;
    flex-wrap: wrap;
  }
  @if $margins {
    padding: 0 $page-margins;
  }
}
