.pane-share-widgets {
  .pane-title {
    @include visually-hidden();
  }
  .item-list {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      li {
        display: inline-block;
        font-size: 1.7em;
        margin: 0;
      }
    }
  }
  .cb-share-widget {
    a {
      width: 28px;
      height: 28px;
      text-decoration: none;
      text-indent: 29px;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      line-height: 1;
      &::before {
        text-indent: -29px;
        vertical-align: initial;
      }
    }
    &__facebook-share {
      a {
        @include icon("facebook-square");
      }
    }
    &__twitter-tweet {
      a {
        @include icon("twitter-square");
      }
    }
    &__email-share {
      a {
        @include icon("email-square");
      }
    }
    &__linkedin-share {
      a {
        @include icon("linkedin-square");
      }
    }
  }
}
