.pane-gcse-searchbox {
  background: #fff;
  width: 100%;
  max-width: $page-container-max-width;
  @include component-padding();
  .pane-title {
    text-align: center;
    @include component-heading();
  }
  .cb-gcse-container {
    max-width: $component-content-max-width;
    width: 100%;
    margin: 0 auto;
    // Overspecified to override gcse base styles.
    form table.gsc-search-box {
      background: none;
      box-shadow: none;
      padding: $spacing--m;
      height: auto;
    }
  }
}

.cb-gcse-container {
  form table.gsc-search-box .gsc-input-box {
    &:hover {
      input,
      .gsib_b { // stylelint-disable-line selector-class-pattern
        // Override gcse base styles.
        // stylelint-disable declaration-no-important
        background: darken(#fff, 3%) !important;
        // stylelint-enable declaration-no-important
      }
      input::placeholder {
        color: darken(color(neutral-brand, m), 1%);
      }
    }
    input::placeholder {
      font-size: 1em;
      color: color(neutral-brand, m);
    }
  }
}
