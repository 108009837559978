// Help message styles
// currently applied to: empty search results, revision page

// Base Styles
.page-node-revisions .cb-help,
.view-cola-programs-search .view-empty,
.cb-gcse-container .gs-no-results-result,
.cb-list-cups__empty-search,
.cb-list-news__empty-search,
.pane-cola-core-facilities-panel-pane-full-search .view-empty,
.pane-cola-events-search-panel-pane-events-upcoming-main .view-empty,
.view-cola-courses-search .view-empty,
.cb-list-services__empty-search,
.cb-site-search__empty-search,
.cb-list-conditions-treatments__empty-search,
.cb-list-trials__empty-search,
.cb-list-trials__search-message,
.cb-list-resources__empty-search {
  margin-top: 1.5em;
  padding: 1em 1em 1em 3.5em;
  border-radius: 2px;
  position: relative;
  p {
    font-family: $font--text--alt;
    font-size: 1rem;
    line-height: 1.4;
    @include media(bp(m)) {
      font-size: 1.125rem;
    }
  }
}

// Revision page message only
.page-node-revisions .cb-help {
  margin-left: 1.7%;
  margin-right: 1.7%;
  background-color: lighten(color(secondary, xhi), 20%);
  border: $border--s;
  border-color: color(secondary, xhi);
  @include media(bp(xl)) {
    margin-left: 0;
    margin-right: 0;
  }
  @include icon($icon: "info-solid", $size: 1.5rem, $color: color(secondary, lo)) {
    position: absolute;
    left: 0.75em;
    top: 1rem;
  }
  span {
    font-weight: 700;
  }
}

// Empty search results message only
.view-cola-programs-search .view-empty,
.cb-gcse-container .gs-no-results-result,
.cb-list-cups__empty-search,
.cb-list-news__empty-search,
.pane-cola-core-facilities-panel-pane-full-search .view-empty,
.pane-cola-events-search-panel-pane-events-upcoming-main .view-empty,
.view-cola-courses-search .view-empty,
.cb-list-services__empty-search,
.cb-site-search__empty-search,
.cb-list-conditions-treatments__empty-search,
.cb-list-trials__empty-search,
.cb-list-resources__empty-search {
  background-color: color(neutral-brand, xxhi);
  @include icon($icon: "warning", $size: 1.5rem) {
    position: absolute;
    left: 0.75em;
    top: 1rem;
  }
  p {
    font-weight: 600;
  }
}
