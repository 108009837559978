// Custom Capte accreditation html logo embed
.cb-badges {
  &.cw-with-badge-capte {
    .cb-badges {
      &__badges {
        justify-content: center;
      }
      &__badge {
        border: none;
        width: auto;
        &:not(:only-child) {
          padding: 1em;
          border: none;
          width: auto;
        }
        &:not(.cb-badges__badge--capte) {
          img {
            max-height: 78px;
          }
        }
      }
    }
    &.cw-with-description {
      // Badges and description are stacked
      .cb-badges {
        &__content {
          @include media(bp(l)) {
            display: block;
          }
        }
        &__description,
        &__badges {
          @include media(bp(l)) {
            max-width: 100%;
            float: none;
          }
        }
        &__badges {
          justify-content: center;
          display: flex;
        }
        &__description {
          display: block;
          clear: both;
          max-width: 840px;
          margin: 1em auto 0 auto;
        }
      }
    }
  }
}
