// Based search box styles shared between events and core facilities
.cb-list-trials__search,
.cb-cups-by-specialties,
.cb-list-conditions-treatments__search,
.cb-cups-full-search,
.cb-news-full-search,
.view-cola-programs-search,
.view-cola-events-search,
.view-cola-core-facilities,
.view-cola-courses-search,
.cb-list-services__search,
.cb-site-search__search,
.cb-list-resources__search {
  .views-exposed-form {
    background-color: #fff;
    font-family: $font--display;
    box-shadow: $box-shadow;
    padding: 1em;
    border-radius: 2px;
    @include media(bp(s)) {
      padding: 1.5em;
    }
    label {
      color: color(neutral-brand, m);
    }
  }
  .views-exposed-widgets {
    > * {
      margin: 0.6em 0;
    }
  }
  .views-exposed-widget {
    padding: 0;
    // stylelint-disable selector-class-pattern
    &.views-widget-filter-search_api_views_fulltext {
      margin-top: 0;
      @include span-columns(12);
      .form-item {
        position: relative;
        input {
          min-height: $touch-size-large;
          background: #fff;
          font-size: 1em;
          padding-left: 0.7em;
          padding-right: 2.5em;
          text-overflow: ellipsis;
          border: solid 3px darken(color(neutral-brand, hi), 1%);
          @include span-columns(12);
          @include media(bp(s)) {
            padding: 6px 12px;
            line-height: 21px;
          }
          @include media(bp(m)) {
            font-size: 1.2em;
          }
          &::placeholder {
            color: color(neutral-brand, m);
          }
          &:hover {
            @include component-bg-hover-styles(#fff, $change: 'darken');
          }
        }
      }
    }
    // stylelint-enable selector-class-pattern
  }
  .view-filters {
    margin-bottom: 3em;
    margin-top: 2.5em;
  }
  .views-reset-button,
  .views-submit-button {
    input[type="submit"] {
      @include btn($size: xs);
    }
  }
  .views-reset-button {
    input[type="submit"] {
      &:hover {
        background: #fff;
        color: $text-link-color;
      }
    }
  }
  // if there's only a search field with right-aligned submit btn
  .views-submit-button:nth-child(2):last-child {
    input[type="submit"] {
      @include btn($size: s);
      @include override-nested() {
        padding-bottom: 0.65em;
        border-bottom: none;
        min-height: $touch-size-large;
      }
    }
  }
  .view-content {
    ul {
      list-style: none;
    }
  }
}
