// This section exceeds max specificity rules:
// 'item-list' class wrapper is needed due to Drupal core theme overrides
/* stylelint-disable selector-max-compound-selectors */
.item-list {
  .pager {
    margin: 3em auto 1em auto;
    padding: 0;
    list-style: none;
    text-align: center;
    clear: both;
    font-family: $font--display;
    display: flex;
    justify-content: center;
    li {
      padding: 0;
      // Hide all but the current, and controls.
      display: none;
      &.pager-current,
      &.pager-item,
      &.pager-first,
      &.pager-previous,
      &.pager-next,
      &.pager-last {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
      }
      &.pager-item,
      &.pager-current {
        margin: 0 0.2em;
        @include media(bp(m)) {
          margin: 0 0.22em;
        }
        a {
          padding: 0.35em;
        }
      }
      &.pager-item a,
      &.pager-current {
        padding: 0.35em;
        @include media(bp(m)) {
          justify-content: center;
          width: $touch-size-large;
        }
        @include media(bp(l)) {
          width: $touch-size-min;
        }
      }
      &.pager-item {
        display: none;
        @include media(bp(m)) {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
        }
        &:hover {
          a {
            color: color(neutral-brand, xxlo);
          }
        }
      }
      &.pager-first,
      &.pager-previous,
      &.pager-next,
      &.pager-last {
        margin: 0 0.2em;
        @include media(bp(m)) {
          margin: 0 0.5em;
        }
        a {
          border: 1px solid;
          border-radius: 4px;
          position: relative;
        }
      }
      &.pager-first,
      &.pager-last {
        a {
          overflow: hidden;
          display: inline-block;
          width: $touch-size-large;
          height: $touch-size-large;
          white-space: nowrap;
          text-transform: capitalize;
          text-indent: 110%;
          border-color: $btn-style-outline-color;
          color: $btn-style-outline-font-color;
          @include media(bp(l)) {
            width: 72px;
            height: $touch-size-min;
            text-indent: 0;
            padding: 0.25em 0.1em 0.2em 0.1em;
          }
          &:hover {
            background-color: $btn-bg-color-interior-pg-component;
            color: #fff;
            &::after {
              color: #fff;
            }
          }
        }
      }
      &.pager-first {
        a {
          @include icon($icon: "caret-left-double") {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            text-align: center;
            text-indent: 0;
            line-height: 42px;
            @include media(bp(l)) {
              left: 0;
              right: auto;
              top: -0.1em;
              bottom: auto;
              margin-right: 0.3em;
              position: relative;
              line-height: inherit;
            }
          }
        }
      }
      &.pager-last {
        a {
          @include icon($icon: "caret-right-double", $pseudo: "after") {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            text-align: center;
            text-indent: 0;
            line-height: 42px;
            @include media(bp(l)) {
              right: 0;
              left: 0;
              top: -0.1em;
              bottom: auto;
              margin-left: 0.3em;
              position: relative;
              line-height: inherit;
            }
          }
        }
      }
      &.pager-previous {
        a {
          @include icon($icon: "caret-left");
        }
      }
      &.pager-next {
        a {
          @include icon($icon: "caret-right");
        }
      }
      &.pager-previous,
      &.pager-next {
        a {
          overflow: hidden;
          display: inline-block;
          width: $touch-size-large;
          height: $touch-size-large;
          white-space: nowrap;
          text-indent: 110%;
          background-color: $btn-bg-color-interior-pg-component;
          color: #fff;
          position: relative;
          @include media(bp(l)) {
            width: $touch-size-min;
            height: $touch-size-min;
          }
          &::before {
            line-height: 42px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            text-indent: 0;
            text-align: center;
            @include media(bp(l)) {
              top: 4px;
              line-height: 28px;
            }
          }
          &:hover {
            background-color: #fff;
            border-color: $btn-bg-color-interior-pg-component;
            &::before {
              color: $btn-bg-color-interior-pg-component;
            }
          }
        }
      }
      &.pager-ellipsis {
        margin: 0;
        @include media(bp(m)) {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
        }
      }
    }
    a {
      font-weight: 400;
    }
  }
}
/* stylelint-enable */
