// CUMC Global Footer
//
// The global footer styles make the following assumptions:
// * a base font size of at least 16px
// * "Gotham SSm A" or "Gotham SSm B" is loaded (see https://www.typography.com/fonts/gotham/webfonts/)
//
// markup:
// <div class="cb-cumc-global-footer">
//   <nav class="cb-cumc-global-footer__wrapper" aria-label="CUIMC Global Footer">
//     <div class="item-list">
//       <ul>
//         <li class="cb-cumc-global-footer__item first">
//           &copy; <span id="cb-cumc-global-footer__copyright-year">2022</span>
//           <a href="https://www.columbia.edu" target="_blank" class="cb-cumc-global-footer__link">Columbia University</a>
//         </li>
//         <li class="cb-cumc-global-footer__item">
//           <a href="https://www.cuimc.columbia.edu/privacy-policy" target="_blank" class="cb-cumc-global-footer__link">Privacy Policy</a>
//         </li>
//         <li class="cb-cumc-global-footer__item">
//           <a href="https://www.cuimc.columbia.edu/terms-and-conditions-use" target="_blank" class="cb-cumc-global-footer__link">Terms and Conditions</a>
//         </li>
//         <li class="cb-cumc-global-footer__item">
//           <a href="https://www.hipaa.cumc.columbia.edu" target="_blank" class="cb-cumc-global-footer__link">HIPAA</a>
//         </li>
//         <li class="cb-cumc-global-footer__item last">
//           <span class="phone-label">General Information: </span>
//           <a href="tel:2123052862" target="_blank" class="cb-cumc-global-footer__link cb-cumc-global-footer__phone" aria-label="phone 2 1 2. 3 0 5. 2 8 6 2."><span class="phone-link">212-305-2862</span></a>
//         </li>
//       </ul>
//     </div>
//   </nav>
// </div>
//
// Styleguide: global.footer

.cb-cumc-global-footer {
  background-color: color(neutral-brand, brand);
  display: block;
  &__wrapper {
    background-color: color(neutral-brand, brand);
    // side padding should be consistent with page margins
    padding: 1.6875em 1.25em 1.8125em 1.25em;
    text-align: center;
    font-family: $font--text--alt;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    box-shadow: inset 0 14px 14px -14px black;
    a {
      font-weight: 700;
      color: #fff;
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  &__item {
    @include media(bp(l)) {
      display: inline;
      font-size: 0.96em;
      &:not(:last-child) {
        margin-right: 2.75em;
      }
      &:not(:first-child) {
        &:last-child {
          display: block;
        }
      }
    }
    @include media(bp(xl)) {
      &:not(:first-child) {
        &:last-child {
          display: inline;
          margin-top: 1em;
        }
      }
    }
    &:not(:first-child) {
      margin-top: 1em;
    }
    &:last-child {
      font-weight: 300;
    }
    &:first-child {
      span {
        font-weight: 300;
      }
    }
  }
  &__phone {
    .phone-label {
      font-weight: 400;
    }
    .phone-link {
      white-space: nowrap;
    }
  }
}