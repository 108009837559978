// Component Padding Mixin

@mixin component-padding() {
  padding: $spacing--m;
  @include media(bp(s)) {
    padding: $spacing--l;
  }
}

@mixin component-sidebar-padding() {
  padding: $spacing--m;
  @include media(bp(bs)) {
    padding: $spacing--l;
  }
  @include media(bp(m)) {
    padding: $spacing--xl;
  }
}
