@use "sass:math";

////
/// @group mixins
////

/// visually-hidden()
///
/// Make an element visually hidden, but accessible to screen readers, etc.
/// @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
/// @group mixins

@mixin visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal;
  @if support-legacy-browser(ie, "7") {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax.
  }
  clip: rect(1px, 1px, 1px, 1px);
}

/// visually-hidden--off()
///
/// Turns off the visually-hidden effect.
/// @group mixins
@mixin visually-hidden--off { // stylelint-disable-line scss/at-mixin-pattern
  position: static;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/// visually-hidden--focusable()
///
/// Makes an element visually hidden by default, but visible when receiving
/// focus.
/// @group mixins
@mixin visually-hidden--focusable { // stylelint-disable-line scss/at-mixin-pattern
  @include visually-hidden();
  &:active,
  &:focus {
    @include visually-hidden--off();
  }
}

/// image replacement
///
/// Hides text and inserts a background image.
/// Width and height should be the maximum display width, and should reflect
/// the true image proportions as they are used to calculate the image ratio.
/// @param {string} $img-path [''] - relative path to the image
/// @param {int} $width [0] - full width of the image without unit
/// @param {int} $height [0] - full height of the image without unit
/// @param {float} $display-width [0] - scales the image and its container based on a percentage value given; if set to 0 image and container are scaled using the width / height values.
/// @param {mixed} $margins [0] - uses calc() to subtract the given margin from the image size; takes em, rem, % , or vw as units.
/// @param {string} $display ['block'] - ('block', 'inline-block') what should the element display property be?
/// @param {string} $screen-size ['all'] - ('all', 'mobile', 'desktop') which screen size should this apply to?
/// @group mixins
/// @author Chris Henkel
@mixin image-replace($img-path: "", $width: 0, $height: 0, $display-width: 0, $margins: 0, $display: "block", $screen-size: 'all') {
  $width: math.div($width, ($width * 0 + 1));
  $height: math.div($height, ($height * 0 + 1));
  $ratio: math.div($height, $width);

  @if $display == "inline" {
    $display: "inline-block";
    @warn "Image replace display parameter has to be block or inline-block. Assumed inline is meant to be inline-block.";
  }
  display: #{$display};
  white-space: nowrap;
  overflow: hidden;
  background-image: url( $img-path );
  background-repeat: no-repeat;

  @if $display-width != 0 {
    $display-width: math.div($display-width, ($display-width * 0 + 1));
    $vw-width: #{($display-width * 1vw)};
    $vw-height: calc(#{($display-width * $ratio * 1vw)} + 1px);
    @if $margins != 0 {
      $vw-width: calc(#{($display-width * 1vw)} - #{(2 * $margins)});
      $vw-height: calc(#{($display-width * $ratio * 1vw)} - #{(2 * $margins * $ratio)} + 1px);
    }
    @if $screen-size == 'mobile' {
      width: $vw-width;
      height: $vw-height;
    }
    @else {
      background-size: #{$display-width * 1%} auto;
      background-position: top left;
      max-width: $width * 1px;
      max-height: ceil($width * $ratio * 1px);
      // IE doesn't play nice with max-width and positive text indents;
      text-indent: -99999px;
      @if $margins != 0 and $screen-size != 'desktop' {
        $stripped: math.div($margins, ($margins * 0 + 1));
        $break: 0;

        // Needed otherwise the image height is too tall.
        line-height: 0.5;
        width: auto;
        height: auto;
        @if unit($margins) == 'em' or unit($margins) == 'rem' {
          $break: $width + ($stripped * 16px);
        }
        @if unit($margins) == '%' or unit($margins) == 'vw' {
          $pct: math.div($stripped, 100);
          $break: ($width * $stripped) + $width * 1px;
        }
        @media screen and (max-width: $break) {
          width: $vw-width;
          height: $vw-height;
        }
      }
      &::before {
        content: '';
        display: inline-block;
        width: $display-width * 1%;
        // + 1px to avoid cropping due to pixel rounding errors.
        padding-bottom: calc(#{($display-width * $ratio * 1%)} + 1px);
      }
    }
  }
  @else {
    width: $width * 1px;
    height: $height * 1px;
    background-size: 100% auto;
    background-position: top center;
    text-indent: 200%;
  }
}

/// gradient
///
/// Adds a css gradient with fallbacks.
/// @param {color} $firstcolor - first color
/// @param {color} $secondcolor - second color
/// @param {int} $stop-first [0%] - first color end point
/// @param {int} $stop-second [100%] - second color end point
/// @param {string} $bg [$firstcolor] - background color fallback
/// @param {string} $direction - direction of gradient
/// @group mixins
@mixin gradient($direction, $firstcolor, $secondcolor, $stop-first: 0%, $stop-second: 100%, $bg: $firstcolor) {
  background: $bg;
  // stylelint-disable-next-line declaration-block-no-shorthand-property-overrides
  background: linear-gradient($direction, $firstcolor $stop-first, $secondcolor $stop-second);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str($firstcolor)}', endColorstr='#{ie-hex-str($secondcolor)}',GradientType=0 );
}

@mixin bg($topcolor, $bottomcolor, $stop-first: 0%, $stop-second: 100%, $ie-fallback: true) {
  @if $topcolor == $bottomcolor {
    background-color: $topcolor;
  }
  @else {
    background-color: transparent;
    // stylelint-disable-next-line declaration-block-no-shorthand-property-overrides
    background: linear-gradient(to bottom, $topcolor $stop-first, $bottomcolor $stop-second);
    @if $ie-fallback {
      .lt-ie10 & {
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str($topcolor)}', endColorstr='#{ie-hex-str($bottomcolor)}', GradientType=0);
      }
    }
  }
}

@mixin clearfix() {
  *zoom: 1;
  #{if(&, "&", "*")}::before,
  #{if(&, "&", "*")}::after {
    content: " ";
    display: table;
  }
  #{if(&, "&", "*")}::after {
    clear: both;
  }
}

/// Mixin - Fluid Type
///
/// PRECISE CONTROL OVER RESPONSIVE TYPOGRAPHY FOR SASS
/// Indrek Paas @indrekpaas
/// Inspired by Mike Riethmuller's Precise control over responsive typography
/// @link http://madebymike.com.au/writing/precise-control-responsive-typography/
/// @param {string} $properties
/// @param {string} $min-vw
/// @param {string} $max-vw
/// @param {string} $min-value
/// @param {string} $max-value
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }
  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }
  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

/// Strip unit
/// Removes any unit from a given value and returns a number.
/// @param {mixed} $value - The value to be stripped of its unit e.g. 1.5em
/// @return {number} - The number without a unit e.g. 1.5
@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

/// Validate number
/// Tests if a supplied value is a number.
/// @param {mixed} $value - the value to test
/// @return {bool} - true or false
@function is-number($value) {
  @return type-of($value) == 'number';
}

/// Validate Percentage
/// Tests if a supplied value is a percentage;
/// @param {mixed} $value - the value to test
/// @return {bool} - true or false
@function is-percentage($value) {
  @return is-number(strip-unit($value)) and unit($value) == '%';
}

/// Percentage to fraction
/// Converts a percentage to a decimal fraction.
/// @param {mixed} $value - the value to convert
/// @return {float} - the decimalized value
@function decimalize($value) {
  @return math.div(strip-unit($value), 100);
}

/// Mixin - Center.
///
/// Center an element vertically or horizontally in its container,
/// and optionally horizontally. Option to undo.
/// @param {bool} $horizontally - center horizontally
/// @param {bool} $vertically - center vertically
/// @param {bool} $absolute - position absolutely, rather than relatively
/// @param {bool} $undo - undo vertical centering on an element it was previously applied to
@mixin center($horizontally: true, $vertically: true, $absolute: false, $undo: false) {
  display: inline-block;
  @if $absolute {
    position: absolute;
  } @else {
    position: relative;
  }
  @if $horizontally == true and $vertically == true {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @if $vertically == true and $horizontally == false {
    top: 50%;
    transform: translateY(-50%);
  }

  @if $horizontally == true and $vertically == false {
    left: 50%;
    transform: translateX(-50%);
  }
  @if $undo {
    position: static;
    left: auto;
    transform: none;
  }
}

/// Mixin - percentage of parent
/// Calculates the equivalent of a parent container's percentage in a child.
/// e.g. parent width is 400px, 20% would be 80px. child width is 40% (160px).
/// If we use 20% on the child we get 32px not 80px. But with this function we do
/// get the 80px.
@function percentage-of-parent($percentage, $child-percentage) {
  @if is-percentage($percentage) and is-percentage(($child-percentage)) {
    $decimal: decimalize($percentage);
    $decimal-child: decimalize($child-percentage);
    $decimal-equiv: math.div($decimal, $decimal-child);
    @return $decimal-equiv * 100%;
  }
  @else {
    @warn "To get parent percentage equivalent parameters have to be percentages";
  }
}

/// Mixin - Local Base
/// Set HTML font sizing base for global/local sizing (rem/em).
/// @link https://css-tricks.com/rem-global-em-local/
@mixin local-base() {
  font-size: 1rem; // 16px (default)
}

/// Word Wrap fix for long spaceless strings
/// @link https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin word-wrap($hyphens: true) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  @if $hyphens {
    hyphens: auto;
  }
  @else {
    hyphens: none;
  }
}

/// Ellipsis fix for long spaceless strings
/// @link https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/// Section navigation styles - sets the color for each section menu
@mixin section-menu-style($section-style-number) {
  background-color: lighten(map-get(map-get($section-styles, $section-style-number), section-style-menu-title-bg-color), 5%);
  .cb-section-menu {
    h2 {
      color: map-get(map-get($section-styles, $section-style-number), section-style-menu-title-color);
      background-color: map-get(map-get($section-styles, $section-style-number), section-style-menu-title-bg-color);
      &::after {
        border-left-color: map-get(map-get($section-styles, $section-style-number), section-style-menu-title-bg-color);
      }
    }
    li {
      a {
        color: map-get(map-get($section-styles, $section-style-number), section-style-menu-links-color);
        @include media(bp(xl)) {
          &::after {
            background-color: map-get(map-get($section-styles, $section-style-number), section-style-menu-links-hover-border-color);
          }
          &:hover,
          &:focus,
          &.active-trail {
            border-bottom-color: map-get(map-get($section-styles, $section-style-number), section-style-menu-links-hover-border-color);
          }
        }
      }
    }
    .menu-block-wrapper {
      background-color: map-get(map-get($section-styles, $section-style-number), section-style-menu-links-bg-color);
    }
    .pane-title {
      a {
        color: map-get(map-get($section-styles, $section-style-number), section-style-menu-links-color);
      }
    }
  }
}

// stylelint-disable value-keyword-case
$section-styles: (
  /// Orange
  section-style-1: (
    section-style-menu-title-color: $section-style-1-menu-title-color,
    section-style-menu-title-bg-color: $section-style-1-menu-title-bg-color,
    section-style-menu-links-color: $section-style-1-menu-links-color,
    section-style-menu-links-hover-border-color: $section-style-1-menu-links-hover-border-color,
    section-style-menu-links-bg-color: $section-style-1-menu-links-bg-color
  ),
  /// Green
  section-style-2: (
    section-style-menu-title-color: $section-style-2-menu-title-color,
    section-style-menu-title-bg-color: $section-style-2-menu-title-bg-color,
    section-style-menu-links-color: $section-style-2-menu-links-color,
    section-style-menu-links-hover-border-color: $section-style-2-menu-links-hover-border-color,
    section-style-menu-links-bg-color: $section-style-2-menu-links-bg-color
  ),
  /// Blue
  section-style-3: (
    section-style-menu-title-color: $section-style-3-menu-title-color,
    section-style-menu-title-bg-color: $section-style-3-menu-title-bg-color,
    section-style-menu-links-color: $section-style-3-menu-links-color,
    section-style-menu-links-hover-border-color: $section-style-3-menu-links-hover-border-color,
    section-style-menu-links-bg-color: $section-style-3-menu-links-bg-color
  ),
  /// Pink
  section-style-4: (
    section-style-menu-title-color: $section-style-4-menu-title-color,
    section-style-menu-title-bg-color: $section-style-4-menu-title-bg-color,
    section-style-menu-links-color: $section-style-4-menu-links-color,
    section-style-menu-links-hover-border-color: $section-style-4-menu-links-hover-border-color,
    section-style-menu-links-bg-color: $section-style-4-menu-links-bg-color
  ),
  /// Indigo
  section-style-5: (
    section-style-menu-title-color: $section-style-5-menu-title-color,
    section-style-menu-title-bg-color: $section-style-5-menu-title-bg-color,
    section-style-menu-links-color: $section-style-5-menu-links-color,
    section-style-menu-links-hover-border-color: $section-style-5-menu-links-hover-border-color,
    section-style-menu-links-bg-color: $section-style-5-menu-links-bg-color
  )
);

// stylelint-enable value-keyword-case
