// Promoted Blog Background Styles

// Default
.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted.cb-list-news--blog,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
      li {
        background: $component-bg-1-color;
        .column-first {
          /* stylelint-disable selector-max-compound-selectors */
          .cb-list-news {
            &__best-category,
            &__title {
              a {
                color: a11yrize(color(action, m), $component-bg-1-color);
              }
            }
            &__best-category {
              background: transparent;
            }
            &__best-category,
            &__date {
              color: a11yrize($news-promoted-date-color, $component-bg-1-color);
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

@include component-region-bg-styles(1,2,3) {
  .cb-list-news--promoted.cb-list-news--blog,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
    li {
      background: $component-bg-auto-color;
      .column-first {
        .cb-list-news {
          /* stylelint-disable selector-max-compound-selectors */
          &__best-category,
          &__title {
            a {
              color: color(action, m);
            }
          }
          &__date {
            color: $news-promoted-date-color;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
