.accordion,
.ui-accordion {
  font-family: $font--display;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 0 0 2px 2px;
  box-shadow: $box-shadow;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    border: 1px solid tint(color(neutral-brand, xhi), 50%);
    border-width: 1px 0 0 1px;
  }
  .lt-ie10 & {
    border: 1px solid tint(color(neutral-brand, xhi), 50%);
    border-width: 1px 0 0 1px;
  }
  > li {
    margin: 0 0 1.875em 0;
  }
}

.ui-accordion {
  .ui-state-default {
    background: $accordion-bg-color;
  }
  .ui-accordion {
    &-header,
    &-content {
      border: none;
      border-radius: 0;
      .views-label {
        font-weight: 700;
      }
      .field-content {
        font-weight: 400;
      }
    }
    &-header {
      font-size: 1rem;
      position: relative;
      line-height: 1.4;
      font-weight: 700;
      padding: 1.5rem 4.5rem 1.5rem 2rem;
      border-top: $border--s;
      margin-top: 0;
      @include icon("caret-down", "after", $color: $accordion-open-close-icon-color) {
        margin: auto;
        position: absolute;
        font-size: 1.1875rem;
        right: 2rem;
        top: 0;
        bottom: 0;
        height: 1em;
        width: 1em;
        line-height: 100%;
        transition: all 0.3s ease;
      }
      @include media(bp(l)) {
        font-size: 1.125rem;
      }
      &:first-child {
        border-top: none;
        margin-top: 0;
      }
      &.ui-state-active {
        &::after {
          transform: rotate(0.5turn);
        }
      }
      &.ui-state-hover {
        background: $background-solid-xlight;
        &::after,
        a {
          color: $text-link-color-hover;
        }
      }
      &-icon {
        display: none;
      }
      a {
        color: $accordion-title-color;
        &:hover {
          color: $text-link-color-hover;
        }
      }
    }
    &-content {
      padding: 0.75rem 2rem 2rem 2rem;
      background: $accordion-content-bg-color;
      p,
      li {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.7;
        color: $accordion-content-font-color;
        @include media(bp(l)) {
          font-size: 1.125rem;
        }
        a {
          font-size: 1em;
          font-family: $font--display;
        }
      }
      a {
        color: $accordion-content-link-color;
      }
      .cb-button {
        @include btn();
      }
    }
  }
}

// Styles for striped background colors.
.cb-faq--striped {
  .ui-state-default,
  .ui-state-active {
    background: $accordion-bg-color--striped-1;
    &:nth-child(4n+3) {
      background: $accordion-bg-color--striped-2;
    }
    &.ui-state-hover {
      background: darken($accordion-bg-color--striped-1, 5%);
      &::after,
      a {
        color: #fff;
      }
      &:nth-child(4n+3) {
        background: darken($accordion-bg-color--striped-2, 5%);
      }
    }
  }
  .ui-accordion-header {
    color: $accordion-header-color--striped;
    border-top: none;
    margin-bottom: 0;
    a {
      color: $accordion-header-color--striped;
      text-decoration: none;
      &:focus {
        outline-color: #fff;
      }
    }
    &::after,
    &.ui-state-active::after {
      color: $accordion-open-close-icon-color--striped;
    }
  }
}


.ck-accordion  {
  // wysiwyg accordion page spacing
  // identical to classy-panels spacing -> atoms/classy-panels/classy_panels.scss
  // Main content components
  .cb-content-area__main & {
    clear: both;
    &:not(:last-child) {
      margin-bottom: $spacing--xl;
      @include media(bp(m)) {
        margin-bottom: $spacing--xxl;
      }
    }
  }
  // Resets for image captions
  .ck-accordion__content {
    @include clearfix();
    .file-cola-wysiwyg-teaser {
      &.align-left,
      &.align-right {
        margin-top: 0.35em;
      }
    }
    figcaption p {
      font-size: 0.9rem;
      color: color(neutral-brand, m);
    }
  }
}

// ckeditor accordion editing styles
// stylelint-disable selector-class-pattern
.cke_editable {
  .ck-accordion {
    border: 1px solid tint(color(neutral-brand, xhi), 50%);
    border-width: 1px 0 0 1px;
    border-radius: 0 0 2px 2px;
    box-shadow: $box-shadow;
  }
  .ck-accordion__title {
    font-size: 1.125rem;
    font-weight: 700;
    padding: 0.75rem;
    background: $background-solid-light;
    &:not(:first-child) {
      border-top: $border--s;
    }
  }
  .cb-faq--striped {
    .ck-accordion__title {
      color: #fff;
      background: $accordion-bg-color--striped-1;
      &:nth-child(4n+3) {
        background: $accordion-bg-color--striped-2;
      }
    }
  }
  .ck-accordion__content {
    padding: 0 0.75rem;
    drupal-entity figure img {
      width: 100%;
    }
  }
  .ck-accordion__content,
  .ck-accordion__content p {
    font-family: $font--text--alt;
    font-size: 1.125rem;
  }
  .ck-accordion__content p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
// stylelint-enable selector-class-pattern
