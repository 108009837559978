// Promoted Announcements Background Styles

@include component-region-bg-styles(auto) {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    .view-content {
      border: $component-border-style;
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-news--promoted.cb-list-news--announcements,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
      li {
        @include quantity-positions(5,1) {
          // no image: add bg to balance layout
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          .column-second-empty {
            @include media(bp(l)) {
              padding: $spacing--xl;
              background: $component-bg-1-color;
              a {
                color: a11yrize(color(action, m), $component-bg-1-color);
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

// Main Content
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    .view-content {
      border: $component-border-style;
    }
  }
}
