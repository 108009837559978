.cb-epic-scheduling,
.pane-bundle-epic-scheduling {
  background: lighten(color(secondary, xhi), 16%);
  @include component-padding();
}

// The cb-epic-scheduling class is not added when javascript is disabled and the
// user is logged in. Using pane bundle class as fallback.
.no-js .pane-bundle-epic-scheduling {
  noscript {
    font-family: $font--text--alt;
    font-size: 1rem;
    a {
      font-weight: 600;
      color: a11yrize(color(primary, brand), lighten(color(secondary, xhi), 16%));
      white-space: nowrap;
    }
  }
}

.cb-epic-scheduling {
  &__title {
    @include component-heading(s);
    margin-bottom: 0.5em;
    @include icon('deadline') {
      vertical-align: baseline;
    }
  }
  &__content {
    .form-type-select {
      margin-top: 0;
    }
    form select:not([multiple]) {
      margin-top: 0;
      background-color: #fff;
      max-width: 100%;
    }
    .form-submit {
      @include btn($style: solid, $size: s, $dark: true);
      @include override-nested() {
        background: color(primary, brand);
        color: #fff;
        border-bottom-color: color(primary, xlo);
      }
      &:hover,
      &:focus,
      &:active {
        background: color(primary, brand);
        color: #fff;
      }
    }
  }
  &__ajax-wrapper {
    .no-js & {
      display: none;
    }
    form {
      background: rgba(255, 255, 255, 0.4);
      padding: 0.75rem;
      border-radius: 4px;
      margin: $spacing--m auto;
      &:only-child {
        margin-bottom: 0;
        @include media(bp(xl)) {
          @include clearfix();
          > div > p {
            width: 50%;
            float: left;
            margin-right: 1.5rem;
            margin-bottom: 0;
            .cb-aside-last & {
              width: 100%;
              float: none;
              margin-right: 0;
              margin-bottom: 1em;
            }
          }
          .cb-epic-scheduling__type-selection {
            max-width: calc(50% - 1.5rem);
            float: left;
            select {
              width: 100%;
              max-width: 100%;
            }
            .cb-aside-last & {
              max-width: 100%;
              float: none;
            }
          }
        }
      }
      label {
        font-weight: 600;
      }
      > div {
        > h3 {
          @include component-heading(xs);
          font-size: 1rem;
        }
        > p {
          @include component-paragraph(xs);
          font-family: $font--text--alt;
          .cb-aside-last & {
            @include component-paragraph-right-rail();
          }
        }
      }
    }
  }
  &__iframe-wrapper {
    margin-top: 1rem;
    /* stylelint-disable-next-line selector-class-pattern */
    .MyChartIframe {
      background: #fff;
      padding: 1rem;
    }
  }
  &__reset {
    display: inline-block;
    color: color(primary, xlo);
    @include icon('arrow-left') {
      margin-right: 0.25em;
      font-size: 0.9rem;
    }
    &:hover::before {
      @include animated($duration: 1s, $name: wobble);
    }
    .form-submit {
      margin: 0;
      padding: 0;
      color: color(primary, xlo);
      background: none;
      border: none;
      box-shadow: none;
      font-size: 0.9rem;
      &:hover,
      &:focus,
      &:active {
        background: none;
        box-shadow: none;
        color: #fff;
        color: color(primary, xlo);
        text-decoration: underline;
      }
    }
    .cb-aside-last & {
      @include media(bp(xl)) {
        &::before {
          font-size: 0.8rem;
        }
        .form-submit {
          font-size: 0.8rem;
        }
      }
    }
  }
}
