.node-type-cups-location {
  // "Back Link" styles
  .cb-content-area__main {
    .pane-link-to-list {
      margin-bottom: 1em;
      a {
        text-decoration: none;
        @include icon($icon: "arrow-left", $pseudo: "before", $size: 0.8em);
        &::before {
          margin-right: 0.5em;
        }
        &:hover::before {
          @include animated($duration: 1s, $name: wobble);
        }
        &:hover,
        &:active {
          @include media(bp(l)) {
            background: transparent;
            color: color(action, m);
          }
        }
      }
    }
  }
  // Display fields and location map side by side on desktop
  .pane-node-field-cups-location-coords {
    @include media(bp(m)) {
      margin-bottom: 4em;
      @include span-columns(5 of 8);
    }
    ~ .pane-node-field-cups-location-address,
    ~ .pane-node-field-cups-location-appt-phone,
    ~ .pane-node-field-cups-location-bill-phone,
    ~ .pane-node-field-cups-location-disability,
    ~ .pane-node-field-cups-location-transit,
    ~ .pane-node-field-cups-location-is-primary,
    ~ .pane-node-field-cups-location-info {
      @include media(bp(m)) {
        @include span-columns(3 of 8) {
          float: right;
          clear: right;
          margin-top: 0;
          @include omega();
        }
      }
    }
    ~ .pane-node-field-cups-location-address,
    ~ .pane-node-field-cups-location-appt-phone,
    ~ .pane-node-field-cups-location-bill-phone,
    ~ .pane-node-field-cups-location-is-primary,
    ~ .pane-node-field-cups-location-info {
      @include media(bp(m)) {
        margin-bottom: $spacing--m;
      }
    }
    ~ .pane-node-field-cups-location-disability,
    ~ .pane-node-field-cups-location-transit {
      display: inline-block;
      float: left;
      width: 2em;
      height: 2em;
      margin-bottom: $spacing--m;
    }
    ~ .pane-node-field-cups-location-appt-phone,
    ~ .pane-node-field-cups-location-bill-phone {
      clear: both;
      @include media(bp(m)) {
        clear: none;
      }
    }
  }
  // Display address fields beneath map in IPE
  .panels-ipe-portlet-content {
    .pane-node-field-cups-location-address {
      clear: both;
    }
    .pane-node-body {
      margin-top: 2rem;
    }
  }
  .pane-node-field-cups-location-address,
  .pane-node-field-cups-location-appt-phone,
  .pane-node-field-cups-location-bill-phone,
  .pane-node-field-cups-location-disability,
  .pane-node-field-cups-location-transit,
  .pane-node-field-cups-location-is-primary,
  .pane-node-field-cups-location-info {
    margin-top: 1.6em;
    font-family: $font--text--alt;
    .pane-title {
      font-size: 1.125em;
    }
    p {
      font-size: 1rem;
    }
  }
  .pane-node-body {
    margin-top: $spacing--m;
    clear: left;
    @include media(bp(m)) {
      margin-top: 0;
    }
  }
  .pane-node-field-cups-location-appt-phone,
  .pane-node-field-cups-location-bill-phone {
    a {
      text-decoration: none;
    }
  }
  .pane-node-field-cups-location-is-primary {
    .cups-location__is-primary {
      text-align: center;
      background-color: color(neutral-brand, m);
      color: #fff;
      display: block;
      padding: 0.25em 0.5em;
      margin-top: 0.75em;
      min-width: 100px;
      width: 30%;
    }
  }
}

// shared location icon styles
.node-type-cups-location,
.node-type-cups-profile {
  .icon--wheelchair,
  .icon--train {
    position: relative;
    left: -9999px;
    &::before {
      position: absolute;
      left: 9999px;
    }
  }
  .icon--train {
    @include icon($icon: "train", $size: 1.5em, $color: color(primary, brand));
  }
  .icon--wheelchair {
    @include icon($icon: "wheelchair", $size: 1.5em, $color: color(primary, brand));
  }
}
