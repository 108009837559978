// Formatting for external labs.
.pane.cb-list-labs:not(.cb-list-labs--component) {
  li .cb-panel-container > h2 {
    font-size: 1.5em;
    margin-bottom: 1rem;
    @include span-columns(12);
    @include media(bp(bs)) {
      @include span-columns(9);
    }
  }
}
