// CTA with icon and link only (no title or description)

.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style:not(:only-child) &,
    &.pane.cb-style:not(:only-child) {
      .paragraphs-item-cta.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
        .field-name-field-cta-link {
          padding-top: 0;
          padding-bottom: 0;
          a {
            margin-bottom: 0;
            line-height: 1.3;
            /* stylelint-disable selector-max-compound-selectors */
            &::after {
              margin-top: -0.1em;
              font-size: 0.7em;
              margin-left: 0.3em;
            }
            /* stylelint-enable */
          }
        }
        .field-name-field-icon {
          padding-top: 0;
        }
        &.cb-position-h--center {
          .field-name-field-cta-link,
          .field-name-field-icon {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

// CTA style auto only
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--auto.pane:not(:only-child) &,
    &.cb-style--auto.pane:not(:only-child) {
      .paragraphs-item-cta.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
        .content {
          @include media(bp(m)) {
            min-height: auto;
          }
        }
      }
      @include quantities-all(odd) {
        .paragraphs-item-cta.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
          &.cb-position-h--left {
            .field-name-field-icon {
              @include media(bp(m)) {
                left: 1.1em;
                top: 0.7em;
                width: 2em;
              }
              @include media(bp(l)) {
                top: 1.4em;
              }
            }
            .field-name-field-cta-link {
              @include media(bp(m)) {
                padding-left: 2.8em;
              }
              @include media(bp(l)) {
                padding-left: 2.5em;
              }
            }
          }
        }
      }
    }
    .cb-style--auto.pane &,
    &.cb-style--auto.pane {
      @include quantities-all(even) {
        .paragraphs-item-cta.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
          &.cb-position-h--left {
            .field-name-field-icon {
              @include media(bp(bs)) {
                width: 2em;
                top: 1.4em;
              }
              @include media(bp(s)) {
                width: 2.5em;
                top: 1.7em;
              }
              @include media(bp(m)) {
                width: 3em;
                top: 1.5em;
                left: 1.5em;
              }
              @include media(bp(l)) {
                top: 1.5em;
              }
              @include media(bp(l)) {
                top: 1.4em;
              }
            }
            .field-name-field-cta-link {
              @include media(bp(bs)) {
                padding-left: 2.5em;
              }
              @include media(bp(s)) {
                padding-left: 2.5em;
              }
              @include media(bp(m)) {
                padding-left: 3.5em;
              }
              @include media(bp(l)) {
                padding-left: 3.7em;
              }
              @include media(bp(xl)) {
                padding-left: 3.6em;
              }
              a {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                &::after {
                  @include media(bp(m)) {
                    top: 0.6em;
                  }
                  @include media(bp(l)) {
                    top: 0.4em;
                  }
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
  }
}
