// CTA Main Content Styles

.cb-content-area__main {
  .paragraphs-item-cta {
    &:not(:first-child) {
      margin-top: 3em;
    }
    + .paragraphs-item-cta {
      padding-top: 2em;
      border-top: solid 2px $border-light;
      clear: both;
    }
    .content {
      text-align: center;
      padding: 0 2rem;
      display: block;
      @include media(bp(s)) {
        text-align: left;
        padding: 0 2px;
      }
    }
    .field-name-field-title {
      margin-bottom: 0;
      @include media(bp(l)) {
        align-items: left;
      }
      h2 {
        @include h3();
        @include override-nested() {
          font-weight: 600;
          margin-bottom: 0.5em;
          color: $cta-title-color;
          // Fix base title styles overrides
          margin-top: 0;
        }
      }
    }
    .field-name-field-cta-image {
      margin-bottom: 1em;
    }
    .field-name-field-icon {
      .cb-icon {
        width: 50px;
        height: 50px;
        transition: all 0.2s ease-out;
        display: inline-block;
      }
      svg {
        width: 100%;
        height: auto;
        fill: currentColor;
      }
    }
    .field-name-field-cta-description {
      width: auto;
      margin-bottom: 1em;
    }
    &:not(.cw-cta-phone) {
      .cb-structured-content:first-child & {
        &:first-child {
          margin-top: 0;
        }
      }
      .field-name-field-cta-link {
        width: auto;
        align-items: flex-start;
        margin-top: 1em;
        margin-bottom: 1em;
        display: block;
        a {
          @include btn($size: s);
        }
      }
    }
    &.cw-cta-phone {
      .content {
        text-align: center;
        position: relative;
        @include media(bp(m)) {
          text-align: left;
        }
        > * {
          display: inline;
        }
        > a {
          text-decoration: none;
        }
        .field-name-field-title,
        .field-name-field-cta-description {
          @include media(bp(s)) {
            text-align: left;
          }
        }
        .field-name-field-cta-link-prefix,
        .field-name-field-cta-link-suffix,
        .field-name-field-cta-link a {
          font-family: $font--text--alt;
        }
        .field-name-field-cta-link-prefix,
        .field-name-field-cta-link-suffix {
          font-weight: 600;
        }
        .field-name-field-cta-link-prefix,
        .field-name-field-cta-link {
          // phone icon styles
          &::before {
            @include media(bp(m)) {
              font-size: 1.6rem;
              margin-right: 0.5rem;
            }
          }
        }
      }
      .field-name-field-cta-description {
        p {
          margin-bottom: 0.8em;
        }
      }
      .cw-cta__overlay-link {
        display: none;
      }
    }
    &:not(.cw-with-description) {
      .field-name-field-cta-link {
        clear: both;
      }
      &.cw-with-image {
        .field-name-field-cta-image {
          @include media(bp(s)) {
            width: 100%;
          }
          img {
            float: left;
            margin-bottom: 1em;
            // Needed for lazyload auto calculation.
            width: 100%;
          }
        }
        .content {
          max-width: 560px;
          margin: 0 auto;
          text-align: center;
        }
        .field-name-field-cta-link {
          a {
            margin: 0 auto;
          }
        }
      }
    }
    &.cw-with-description {
      .field-name-field-icon,
      .field-name-field-cta-image {
        margin-bottom: 1em;
        @include media(bp(m)) {
          margin-bottom: 2em;
        }
        @include media(bp(s)) {
          float: right;
          margin-left: 2em;
          width: 33.333%;
        }
      }
      .field-name-field-icon {
        // create 16:9 ratio.
        // 56.25% is 16:9 for 100% width.
        padding-bottom: 56.25%;
        background: $background-blue-solid-light;
        position: relative;
        @include media(bp(s)) {
          // 18.75% is 16:9 for 33.333% width.
          padding-bottom: 18.75%;
        }
        .cb-icon {
          position: absolute;
          width: 50%;
          height: auto;
          margin: 0 auto;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .field-name-field-cta-image {
        img {
          // Needed for lazyload auto size calculation.
          width: 100%;
        }
      }
      &.cw-with-image,
      &.cw-width-icon {
        // add content min-height when cta image is
        // absolute positioned next to description
        .content {
          @include media(bp(s)) {
            min-height: calc(24vw);
          }
          @include media(bp(m)) {
            min-height: 11.5rem;
          }
          @include media(bp(l)) {
            min-height: calc(16.5vw);
          }
          @include media(bp(xl)) {
            min-height: 13rem;
          }
        }
      }
    }
    a.cw-cta__overlay-link {
      display: none;
    }
    // CTA with link only
    &:not(.cw-cta-phone):not(.cw-with-title):not(.cw-with-description):not(.cw-with-image):not(.cw-with-icon) {
      &.cb-position-h--center {
        .content {
          text-align: center;
        }
      }
    }
  }
}

// Default CTA component only
.cb-content-area__main {
  .pane-bundle-cta.cb-style.pane {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    .cw-with-image {
      padding-left: 0;
    }
    .field-name-field-cta-image {
      position: static;
      height: auto;
    }
    .lt-ie10 & {
      a {
        text-decoration: none;
      }
    }
    .paragraphs-item-cta {
      &:not(.cw-with-description) {
        .field-name-field-cta-image {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Icon and link only in the main content.
.cb-content-area__main {
  .paragraphs-item-cta {
    &.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
      &.cb-position-h--left {
        text-align: left;
      }
      &.cb-position-h--center {
        text-align: center;
      }
      .content {
        display: inline-flex;
        flex-direction: row;
        width: auto;
        position: relative;
        padding: 0;
        &:hover {
          .cw-cta__overlay-link {
            box-shadow: 0 6px 9px $btn-hover-box-shadow-color;
          }
        }
      }
      .cw-cta__overlay-link {
        display: block;
        z-index: 20;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        text-indent: -999em;
        background-color: rgba(255,255,255,0);
        box-shadow: $box-shadow;
        border-radius: 4px;
      }
      .field-name-field-icon,
      .field-name-field-cta-link {
        margin: 0;
        // IE fix for flex width.
        min-width: 1px;
      }
      .field-name-field-icon {
        position: relative;
        min-width: 50px;
        min-height: 50px;
        background: color(primary, brand);
        border-radius: 4px 0 0 4px;
        .cb-icon {
          width: 40px;
          height: 40px;
          display: block;
          position: relative;
          top: 50%;
          left: 0;
          right: 0;
          margin: 0 auto;
          transform: translateY(-50%);
          color: #fff;
        }
      }
      .field-name-field-cta-link {
        display: flex;
        border-radius: 0 4px 4px 0;
        flex: 0 1 auto;
        align-items: stretch;
        a {
          border-radius: 0 4px 4px 0;
          margin: 0;
          box-shadow: none;
          border: none;
          flex: 1 0 auto;
          background: color(secondary, m);
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
}
