// Base CTA Typography Styles

// Base link styles
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .paragraphs-item-cta {
      &:not(.cw-cta-phone) {
        &.cw-with-title,
        &.cw-with-description {
          .field-name-field-cta-link {
            // CTA button
            a {
              margin-bottom: $spacing--s;
              @include btn($style: solid, $size: s, $dark: true);
            }
          }
          .field-name-field-cta-description a {
            font-weight: 600;
          }
        }
        &:not(.cw-with-description) {
          .field-name-field-cta-link a {
            margin-top: $spacing--xs;
          }
        }
        &.cb-position-h--left {
          .content {
            text-align: left;
          }
        }
      }
    }
  }
}
