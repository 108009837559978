// Sidebar and Related Testimonials (service detail page and cups profile) Base Styles

.cb-content-area__aside-last .cb-list-testimonials--promoted,
.cb-list-testimonials--related-testimonials.cb-list-testimonials--compact,
.cb-list-testimonials--related-testimonials.cups-profile-section,
.node-type-service .cb-list-testimonials--related-testimonials {
  &.pane {
    background: $sidebar-component-bg-color;
    h2.pane-title {
      padding: $spacing--l $spacing--l 0 $spacing--l;
      color: $sidebar-component-title-color;
      text-align: center;
      margin-bottom: $spacing--s;
      @include related-component-heading($size: l);
      @include icon($icon: "comment", $pseudo: "before", $size: 0.9em) {
        margin-top: -0.13em;
        margin-right: 0;
        color: a11yrize(color(primary), $background-solid-xlight);
      }
      @include media(bp(l)) {
        padding: $spacing--xl $spacing--xl 0 $spacing--xl;
        margin-bottom: 0;
      }
    }
  }
}

// Sidebar component styles only
.cb-content-area__aside-last .cb-list-testimonials--promoted {
  &.pane {
    padding: 0;
    .view-content {
      padding: 0 $spacing--l 0 $spacing--l;
      @include media(bp(l)) {
        padding: 0 $spacing--xl 0 $spacing--xl;
      }
      &:last-child {
        padding-bottom: 1.7rem;
        @include media(bp(m)) {
          padding-bottom: 2rem;
        }
        @include media(bp(l)) {
          padding-bottom: 2.3rem;
        }
      }
      > ul:not(.pager) {
        list-style: none;
        padding-left: 0;
        > li {
          position: relative;
        }
      }
      li {
        max-width: $sidebar-component-content-max-width;
        border-bottom: $border--s;
        border-color: $sidebar-component-border-color;
        margin: 0 auto;
        padding: 1.25em 0 1.56em 0;
        &:first-child {
          border-top: $border--m;
          border-color: $sidebar-component-border-color;
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        &:hover {
          /* stylelint-disable selector-max-compound-selectors */
          .cb-list-testimonials__title a {
            text-decoration: underline;
          }
          /* stylelint-enable */
        }
      }
    }
    .col {
      // Template overrides.
      float: none;
      margin: 0;
      width: auto;
    }
    .cb-list-testimonials {
      &__title {
        margin-bottom: 0.5em;
        h3 {
          @include component-link-title(s);
          @include media(bp(s)) {
            @include component-link-title(m);
          }
          @include media(bp(m)) {
            @include component-link-title(l);
          }
          a {
            color: $sidebar-component-link-color;
          }
        }
      }
      &__summary {
        p {
          font-family: $font--text--alt;
          @include component-paragraph(xs);
          @include media(bp(m)) {
            @include component-paragraph(s);
          }
        }
        // base styles override: when body field is displayed instead of summary
        &.pane-node-body:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
    .item-list {
      &:last-child {
        padding-bottom: 0.5rem;
      }
      ul.pager {
        margin: 2em auto;
      }
    }
    .more-link {
      background: $background-solid-light;
      display: block;
      width: auto;
      margin-top: 1em;
      text-align: center;
      padding-bottom: 0.7em;
      padding-top: 0.8em;
      @include media(bp(m)) {
        margin-top: 2em;
        padding-bottom: 1em;
      }
      a {
        color: a11yrize(color(action, m), $background-solid-light);
        background: transparent;
        @include component-link-title(s);
        &:hover {
          text-decoration: underline;
        }
        @include media(bp(s)) {
          @include component-link-title(m);
        }
      }
    }
    .cb-layout__component-dynamic {
      position: relative;
      display: flex;
      flex-direction: column;
      // with image
      &:not(.column-second-empty) {
        .column-second {
          order: 1;
          margin-bottom: 1em;
          @include media(bp(bs)) {
            margin-bottom: 0;
          }
        }
        .column-first {
          order: 2;
        }
        // Display image and title side by side
        .cb-list-testimonials {
          &__image-teaser {
            @include media(bp(bs)) {
              overflow: hidden;
              position: absolute;
              top: 0;
              right: 0;
              // Padding for focus outlines.
              padding: 1px;
            }
            img {
              // Needed for lazyload auto size calculation.
              width: 100%;
            }
          }
          &__title {
            &::before {
              @include media(bp(bs)) {
                content: "";
                float: right;
                margin-left: 1em;
                margin-bottom: 1.25em;
              }
            }
          }
          // Setting pseudo and image element to be same size to wrap text.
          // Pseudo wraps text, image is placed over it using absolute positioning.
          // This also restricts the image content area to a 16:9 ratio.
          &__image-teaser,
          &__title::before {
            @include media(bp(bs)) {
              width: calc((100vw - 2 * #{$page-margins}) * 0.196);
              height: calc((100vw - 2 * #{$page-margins}) * 0.11);
            }
            @include media(bp(l)) {
              width: 150px;
              height: 83.38px;
            }
            @include media(bp(xl)) {
              width: 160px;
              height: 90px;
            }
          }
        }
      }
    }
  }
}
