// "Landing page" (cola_base_dynamic_features_asides_feature_rows) adjustments.

.cb-page-content--feature-rows {
  .cb-content-area {
    @include media(bp(xl)) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media(bp(l)) {
      &__main,
      &__feature-rows .region .cps-region-inner {
        // This approximates the page margins by subtracting the proportionally
        // adjusted component spacing.
        padding-left: calc(#{$page-margins} - (#{$featured-aside-last-right-margin} * 0.98));
        padding-right: calc(#{$page-margins} - (#{$featured-aside-last-right-margin} * 0.98));
      }
    }
    &__main {
      .pane-page-title {
        .cb-flavor--subsection-front & {
          @include visually-hidden();
        }
      }
    }
    &__main,
    &__aside-last {
      padding-left: $component-spacing;
      padding-right: $component-spacing;
      @include media(bp(m)) {
        padding-left: $page-margins;
        padding-right: $page-margins;
      }
      @include media(bp(l)) {
        padding-left: $component-spacing;
        padding-right: $component-spacing;
      }
    }
    &__aside-first {
      width: 100%;
      .cb-layout--aside-first &,
      .cb-layout--aside-first-last & {
        padding-left: $component-spacing;
        padding-right: $component-spacing;
        @include media(bp(m)) {
          padding-left: $page-margins;
          padding-right: $page-margins;
        }
        @include media(bp(xl)) {
          padding-right: 0;
          padding-left: 1.26072%;
          margin-right: 2.92206%;
          width: 22.91828%;
          .cb-flavor--subsection-front & {
            display: none;
          }
        }
      }
    }
    &__wrapper {
      .cb-flavor--subsection-front & {
        @include media(bp(xl)) {
          @include span-columns(12);
        }
      }
    }
  }
}

.cb-content-area__feature-rows {
  clear: both;
}

.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first,
  .cb-layout--aside-first-last,
  .cb-layout--aside-last {
    .cb-page-content--feature-rows {
      .cb-content-area {
        @include media(bp(l)) {
          // Featured layout with sidebars
          // remove default side padding since region backgrounds are disabled
          /* stylelint-disable selector-max-compound-selectors */
          &__feature-rows .region .cps-region-inner {
            padding-left: 0;
            padding-right: 0;
          }
          /* stylelint-enable selector-max-compound-selectors */
        }
        // override cola_base floats to enable vertical margin
        // between body content and feature rows
        &__aside-first,
        &__main {
          @include media($bp-only-l) {
            float: none;
          }
        }
        &__main {
          @include media(bp(xl)) {
            float: none;
          }
          // center align page title on mobile if main content
          // has no body text or structured content
          > .pane-page-title:only-child {
            h1 {
              text-align: center;
              @include media(bp(xl)) {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  &.cb-flavor--sections {
    .cb-layout--aside-last {
      .cb-page-content--feature-rows {
        .cb-content-area__main {
          // fix missing spacing between page menu and page title
          @include media($bp-only-l) {
            float: left;
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-list-news) .cb-layout--no-aside,
.cb-flavor--subsection-front:not(.cb-list-news) .cb-layout--aside-first,
.cb-flavor--subsection-front:not(.cb-list-news) .cb-layout--aside-first-last {
  .cb-page-content--feature-rows {
    .cb-content-area__feature-rows {
      // remove default main.cb-content
      // page bottom spacing (_01-layout.scss) after last feature row
      // (in IPE spacing still appears since empty rows are displayed in the markup)
      > div:last-child {
        margin-bottom: -3.125em;
        @include media(bp(l)) {
          margin-bottom: -6.25em;
        }
      }
    }
    .cb-content-area__main {
      // center align page title if main content
      // has no body text or structured content
      /* stylelint-disable selector-max-compound-selectors */
      > .pane-page-title:only-child {
        h1 {
          text-align: center;
          margin-bottom: 0;
        }
      }
      /* stylelint-enable selector-max-compound-selectors */
      // decrease spacing between page title and feature rows
      + .cb-content-area__feature-rows {
        margin-top: 0;
      }
      .pane-node-body,
      .cb-structured-content,
      // "related components" such as related profiles, news, etc. can appear after body field
      .pane.cb-style {
        &:last-child {
          margin-bottom: $swim-lanes--l;
          @include media(bp(m)) {
            margin-bottom: $swim-lanes--xl;
          }
        }
      }
    }
    // Enable full-width edge-to-edge region background styles
    // Make main content area width consistent with basic pages
    &.cb-page-content {
      .region.cb-background {
        &.cb-padding {
          width: 100%;
          max-width: 100%;
          &--auto {
            .cps-region-inner {
              margin-left: auto;
              margin-right: auto;
              max-width: 75em;
            }
          }
          &--none {
            .cps-region-inner {
              @include media(bp(l)) {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
      .cb-content-area {
        .cb-container__inner {
          max-width: 100%;
        }
        &__feature-rows {
          width: 100%;
          margin-left: 0;
        }
        &__main {
          @include media(bp(l)) {
            @include span-columns(8, $shift: 2) {
              padding-left: calc((65.54622% - ((100% - 2.5em) * 0.6554622)) / 2);
              padding-right: calc((65.54622% - ((100% - 2.5em) * 0.6554622)) / 2);
            }
          }
          @include media(bp(xl)) {
            max-width: 47.5em;
            float: none;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

// IPE fix for empty sidebars not appearing when editing
.cb-flavor.panels-ipe-editing:not(.cb-list-news) {
  .cb-layout--no-aside {
    .cb-page-content--feature-rows.cb-page-content {
      .cb-content-area {
        > .cb-container__inner {
          @include media(bp(xxl)) {
            width: calc(100% - 400px);
            max-width: $page-container-max-width;
          }
        }
        &__feature-rows {
          width: calc(100% + 400px);
          max-width: unset;
          margin-left: -200px;
        }
      }
    }
  }
}
