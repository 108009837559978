// CUPS simple search base styles

.pane-cola-search-faculty-box-simple,
.pane-cola-search-providers-box-simple {
  box-shadow: none;
  .views-exposed-widget {
    float: none;
    padding: 0;
    .form-submit {
      width: 50%;
      margin-top: 0.66em;
    }
  }
  .cb-panel-container {
    max-width: $component-content-max-width;
    width: 100%;
    margin: 0 auto;
    padding-top: $spacing--s;
    label {
      @include visually-hidden();
    }
  }
  // Display search btn next to search field
  // stylelint-disable selector-class-pattern
  .views-widget-filter-search_api_views_fulltext {
    @include media(bp(s)) {
      float: left;
      display: inline-block;
      width: 77%;
      margin-right: 0;
    }
    input {
      width: 100%;
      padding: 12px;
      @include media(bp(s)) {
        height: 44px;
        line-height: 20px;
      }
      &.form-text::placeholder {
        text-align: left;
      }
    }
  }
  // stylelint-enable selector-class-pattern
  .views-exposed-widget.views-submit-button {
    @include media(bp(s)) {
      margin: 0;
      @include span-columns(3, inline-block);
    }
    .form-submit {
      width: 100%;
      margin-top: 0.5em;
      @include media(bp(s)) {
        border-radius: 0 4px 4px 0;
        height: 44px;
        margin-top: 0;
      }
    }
  }
}

.region,
.cb-page-content .cb-content-area__main {
  .pane-cola-search-faculty-box-simple,
  .pane-cola-search-providers-box-simple,
  .panels-ipe-cola-search-faculty-box-simple,
  .panels-ipe-cola-search-providers-box-simple {
    padding: $spacing--l $spacing--l $spacing--xl $spacing--l;
    @include media(bp(l)) {
      padding: $spacing--xl $spacing--xl $spacing--xxl $spacing--xl;
    }
  }
}

// Fix ipe issues
.region,
.cb-page-content .cb-content-area__main {
  .panels-ipe-cola-search-faculty-box-simple,
  .panels-ipe-cola-search-providers-box-simple {
    .pane-cola-search-faculty-box-simple {
      padding: 0;
    }
  }
}

.cb-flavor.panels-ipe-editing:not(.cb-list-news) {
  .pane {
    &.panels-ipe-cola-search-faculty-box-simple,
    &.panels-ipe-cola-search-providers-box-simple {
      padding: 0;
      border: 1px solid #CCC;
      .panels-ipe-portlet-content {
        padding: $spacing--m;
      }
    }
  }
}
