// CUPS simple search sidebar styles

.cb-content-area__aside-last {
  .pane-cola-search-faculty-box-simple,
  .pane-cola-search-providers-box-simple,
  .panels-ipe-cola-search-faculty-box-simple,
  .panels-ipe-cola-search-providers-box-simple {
    font-family: $font--display;
    border: $component-border-style;
    text-align: center;
    padding: 1.5em;
    padding-bottom: 2em;
    max-width: 100%;
    margin: 0 auto 2em;
    &.pane {
      background: #fff;
    }
    h2.pane-title {
      @include component-heading(s);
      @include media(bp(bs)) {
        @include component-heading(m);
      }
      @include media(bp(m)) {
        @include component-heading(l);
      }
    }
    label {
      font-size: 1rem;
    }
    .form-submit {
      font-weight: 600;
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane-cola-search-faculty-box-simple,
      .pane-cola-search-providers-box-simple,
      .panels-ipe-cola-search-faculty-box-simple,
      .panels-ipe-cola-search-providers-box-simple {
        padding: $sidebar-component-right-rail-padding;
        h2.pane-title {
          @include component-heading-right-rail();
        }
        // full width search box and btn
        // stylelint-disable-next-line selector-class-pattern
        .views-widget-filter-search_api_views_fulltext {
          width: 100%;
        }
        .views-exposed-widget {
          float: none;
          padding: 0;
          /* stylelint-disable selector-max-compound-selectors */
          .form-submit,
          .cb-button--submit {
            margin-top: 0.75rem;
          }
          &.views-submit-button {
            width: 100%;
            .form-submit {
              border-radius: 4px;
              font-size: 1rem;
            }
          }
          /* stylelint-enable */
        }
        input {
          width: 100%;
          text-align: center;
          @include component-paragraph-right-rail();
        }
        /* stylelint-disable selector-max-compound-selectors */
        .more-link a {
          font-size: 1rem;
          &:hover {
            text-decoration: underline;
          }
        }
        /* stylelint-enable */
      }
    }
  }
}

// Panels ipe editing display fixes
.cb-flavor.panels-ipe-editing {
  // right rail
  .cb-layout--aside-last,
  .cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
    .cb-content-area__aside-last {
      @include media(bp(xl)) {
        .panels-ipe-cola-search-faculty-box-simple,
        .panels-ipe-cola-search-providers-box-simple {
          padding: 0;
          /* stylelint-disable selector-max-compound-selectors */
          .panels-ipe-portlet-content {
            padding: $sidebar-component-right-rail-padding;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
