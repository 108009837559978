// Styles based on number of appointment links
.cb-appointments:not(.cw-with-doctors-link) {
  .cb-appointments {
    &__link {
      &:only-child {
        a {
          @include component-link-title(s);
          align-items: center;
          padding: 0 0 0 1.6em;
          height: $touch-size-min;
          background: transparent;
          color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
          text-decoration: underline;
          &:hover {
            background: transparent;
            text-decoration: none;
          }
          &::before {
            @include component-link-title(s);
            margin-left: -1.6em;
            height: 1.4em;
          }
          @include media(bp(bs)) {
            @include component-link-title(m);
            padding: 0 0 0 1.3em;
            &::before {
              @include component-link-title(m);
              margin-top: 0.35em;
            }
          }
          @include media(bp(m)) {
            @include component-link-title(l);
            &::before {
              @include component-link-title(l);
            }
          }
        }
      }
      @include quantities-all(2,4) {
        @include media(bp(s)) {
          @include span-columns(6) {
            width: 49.441%;
          }
          &:nth-child(even) {
            margin-right: 0;
          }
          &:nth-child(odd) {
            margin-right: 0.4rem;
          }
          a {
            font-size: 0.85em;
          }
        }
        @include media(bp(m)) {
          a {
            font-size: 1em;
          }
        }
        @include media(bp(l)) {
          a {
            font-size: 1.1em;
          }
        }
      }
      &--book-online {
        &:only-child {
          a {
            font-size: 0.87em;
            @media screen and (min-width: 25em) {
              font-size: 1em;
            }
            @include media(bp(bs)) {
              font-size: 1.25em;
            }
            &::before {
              margin-left: -1.4em;
              @media screen and (min-width: 25em) {
                margin-left: -1.6em;
              }
            }
          }
        }
      }
    }
  }
  // 3 appointments links
  &:not(.cw-with-book-online-link) {
    &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
      .cb-appointments {
        &__links {
          @include media(bp(m)) {
            display: flex;
          }
        }
        &__link {
          @include media(bp(m)) {
            flex-grow: 1;
          }
          &:not(:last-child) {
            @include media(bp(m)) {
              margin-right: $spacing--xs;
            }
          }
        }
      }
    }
  }
  &.cw-with-book-online-link {
    &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
    &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
    &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
      .cb-appointments {
        &__links {
          @include media(bp(m)) {
            display: flex;
          }
        }
        &__link {
          @include quantities-all(3) {
            @include media(bp(m)) {
              flex-grow: 1;
            }
            &:not(:last-child) {
              @include media(bp(m)) {
                margin-right: $spacing--xs;
              }
            }
          }
        }
      }
    }
    &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
    &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link) {
      .cb-appointments {
        &__link {
          a {
            @include media(bp(m)) {
              font-size: 0.9em;
            }
            @include media(bp(l)) {
              font-size: 1em;
            }
          }
        }
      }
    }
    &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
      .cb-appointments {
        &__link {
          a {
            @include media(bp(m)) {
              font-size: 0.8em;
            }
            @include media(bp(l)) {
              font-size: 0.9em;
            }
          }
        }
      }
    }
    // 4 appointments links
    &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
      @include media(bp(s)) {
        display: flex;
        flex-wrap: wrap;
      }
      &.panels-ipe-portlet-wrapper {
        display: block;
      }
      .panels-ipe-portlet-content {
        margin: 0 auto;
      }
    }
  }
}

.cb-appointments.cw-with-description:not(.cw-with-doctors-link) {
  .cb-appointments {
    &__title {
      margin-bottom: $spacing--s;
    }
    &__link {
      &:only-child {
        a {
          @include media(bp(bs)) {
            padding-left: 1.6em;
          }
          &::before {
            top: 0.6rem;
            @include media(bp(bs)) {
              top: 0.4rem;
              margin-top: 0;
            }
            @include media(bp(m)) {
              top: 0.3rem;
              margin-left: -1.4em;
            }
          }
        }
      }
      &--phone {
        &:only-child {
          a {
            // fix phone link to not wrap in Safari
            display: block;
            &::before {
              top: 0;
            }
          }
        }
      }
    }
  }
}

.cb-appointments:not(.cw-with-description):not(.cw-with-doctors-link) {
  text-align: center;
  .panels-ipe-dragbar {
    text-align: left;
  }
  .cb-appointments {
    &__links {
      @include media(bp(bs)) {
        margin-top: $spacing--m;
      }
    }
    &__link {
      &:only-child {
        width: 100%;
        a {
          display: block;
          &::before {
            margin-right: 0.5em;
            margin-top: 0;
          }
        }
      }
      &--phone {
        &:only-child {
          a {
            &::before {
              vertical-align: top;
              @include media(bp(l)) {
                font-size: 2rem;
                margin-top: -0.1em;
              }
            }
          }
        }
      }
      // fix ie11 icon alignment
      &--phone,
      &--telehealth,
      &--book-online,
      &--info {
        &:only-child {
          a {
            &::before {
              position: relative;
            }
          }
        }
      }
    }
  }
}
