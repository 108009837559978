// News Promoted Featured Layout:
// adjust styles to more narrow content container width

.cb-flavor:not(.cb-list-news) {
  .cb-layout--dynamic-features-asides-feature-rows {
    .region {
      .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
      .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
        @include media(bp(l)) {
          ul:not(.panels-ipe-linkbar) {
            li {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &:not(:only-child) {
                &:not(:last-child) {
                  @include component-divider($right-alignment: -0.9rem);
                }
              }
              @include quantities-all(1) {
                padding: 0;
                margin-bottom: 0;
              }
              @include quantity-positions(5,1) {
                &::after {
                  display: none;
                }
              }
              @include quantity-positions(5,2,3,4) {
                @include component-divider($right-alignment: -0.9rem);
              }
              /* stylelint-enable */
            }
          }
        }
        @include media(bp(xl)) {
          ul:not(.panels-ipe-linkbar) {
            li {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              @include quantity-positions(2,1) {
                @include component-divider($right-alignment: -1rem);
              }
              @include quantity-positions(3,1,2) {
                @include component-divider($right-alignment: -1rem);
              }
              @include quantity-positions(4,1,2,3) {
                @include component-divider($right-alignment: -1rem);
              }
              @include quantity-positions(5,2,3,4) {
                @include component-divider($right-alignment: -1rem);
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}

// No sidebars
.cb-flavor:not(.cb-list-news) {
  &.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--no-aside {
    .region {
      .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
      .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
        @include media(bp(l)) {
          ul:not(.panels-ipe-linkbar) {
            li {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              @include quantity-positions(1,2) {
                @include component-divider($right-alignment: -0.6rem);
              }
              @include quantity-positions(3) {
                @include component-divider($right-alignment: -0.9rem);
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}

// Featured layouts with sidebars
.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-first-last {
      .region {
        .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
        .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
          @include media($bp-only-l) {
            ul:not(.panels-ipe-linkbar) {
              li {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                &:not(:only-child) {
                  &:not(:last-child) {
                    @include component-divider($right-alignment: -0.8rem);
                  }
                }
                /* stylelint-enable */
              }
            }
          }
          @include media(bp(l)) {
            ul:not(.panels-ipe-linkbar) {
              li {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                @include quantities-all(4) {
                  .columns-2.column--first {
                    margin-right: 0;
                  }
                }
                @include quantity-positions(5,2,3,4,5) {
                  .columns-2.column--first {
                    margin-right: 0;
                  }
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
    &.cb-layout--aside-last {
      .region {
        .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
        .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
          ul:not(.panels-ipe-linkbar) {
            li {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &:not(:only-child) {
                &:not(:last-child) {
                  @include media(bp(l)) {
                    @include component-divider($right-alignment: -0.7rem);
                  }
                  @include media(bp(xl)) {
                    @include component-divider($right-alignment: -0.85rem);
                  }
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
    &.cb-layout--aside-first-last,
    &.cb-layout--aside-first,
    &.cb-layout--aside-last {
      .region {
        .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
        .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
          @include media(bp(l)) {
            ul:not(.panels-ipe-linkbar) {
              li {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                @include quantities-all(1) {
                  .cb-layout__component-dynamic {
                    align-items: unset;
                  }
                }
                @include quantity-positions(1,2) {
                  @include component-divider($right-alignment: -0.5rem);
                }
                @include quantity-positions(3) {
                  @include component-divider($right-alignment: -0.9rem);
                }
                // 4 news: display max 2 per row
                @include quantity-positions(4,1,3) {
                  margin-left: 0;
                  margin-right: 1.6%;
                }
                @include quantity-positions(4,2,4) {
                  margin-right: 0;
                  margin-left: 1.6%;
                }
                @include quantity-positions(4,1,2) {
                  padding-bottom: 3%;
                  margin-bottom: 3%;
                  border-bottom: $border--s;
                }
                @include quantities-all(4) {
                  width: 48.31933%;
                  &::after {
                    display: none;
                  }
                  .cb-layout__component-dynamic {
                    display: flex;
                    flex-direction: row;
                  }
                  .column-first:not(:only-child) {
                    width: 65%;
                    order: 1;
                    padding-right: $spacing--s;
                  }
                  .column-second.column--last {
                    width: 35%;
                    order: 2;
                  }
                  .cb-list-news {
                    &__summary {
                      @include visually-hidden();
                    }
                  }
                }
                // 5 news: display max 2 per row except for top
                // full width featured item
                @include quantity-positions(5,1) {
                  &::after {
                    display: none;
                  }
                  .cb-layout__component-dynamic {
                    align-items: unset;
                  }
                }
                @include quantity-positions(5,2,3,4,5) {
                  width: 48.31933%;
                  &:nth-child(2),
                  &:nth-child(4) {
                    margin-left: 0;
                    margin-right: 1.6%;
                  }
                  &:nth-child(3),
                  &:nth-child(5) {
                    margin-right: 0;
                    margin-left: 1.6%;
                  }
                  &:nth-child(2),
                  &:nth-child(3) {
                    padding-bottom: 2.5%;
                    margin-bottom: 2.5%;
                    border-bottom: $border--s;
                  }
                  &::after {
                    display: none;
                  }
                  .cb-layout__component-dynamic {
                    display: flex;
                    flex-direction: row;
                  }
                  .column-first:not(:only-child) {
                    width: 65%;
                    order: 1;
                    padding-right: $spacing--s;
                  }
                  .column-second.column--last {
                    width: 35%;
                    order: 2;
                  }
                  .cb-list-news {
                    &__summary {
                      @include visually-hidden();
                    }
                  }
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
  }
}
