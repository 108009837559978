// Promoted Events Background Styles

// Default
.region,
.cb-page-content .cb-content-area__main {
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    .view-empty,
    li {
      border: $component-border-style;
      background: $component-bg-auto-color;
    }
  }
}

@include component-region-bg-styles(1) {
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    .view-empty,
    li {
      border: none;
    }
  }
}
