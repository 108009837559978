// CTA Full Width Background Styles (Only child in a region)

@mixin cta-full-width-bg-colors($bg-color, $font-color, $link-color, $font-color-img-overlay) {
  background: $bg-color;
  .field-name-field-title h2,
  .field-name-field-cta-description p,
  .field-name-field-cta-description a,
  .field-name-field-cta-link-prefix,
  .field-name-field-cta-link-suffix {
    color: $font-color;
  }
  @include media(bp(m)) {
    background: a11yrize(color(primary, m), $font-color-img-overlay);
    .field-name-field-title h2,
    .field-name-field-cta-description p,
    .field-name-field-cta-description a {
      color: $font-color-img-overlay;
    }
  }
}

.region.cb-background {
  // Add subtle bottom border to fix bg color overlap between
  // full width CTA mobile text box and other regions backgrounds
  &.cb-padding--none {
    .pane-bundle-cta.cb-style:only-child,
    .panels-ipe-bundle-cta:only-child {
      .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone) {
        border-bottom: $border--xs;
        @include media(bp(m)) {
          border-bottom: none;
        }
      }
    }
  }
}

@include component-region-bg-styles(auto) {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    .paragraphs-item-cta {
      &.cw-with-image:not(.cw-cta-phone) {
        @include cta-full-width-bg-colors(#fff, $component-bg-auto-font-color, color(action, m), $component-bg-3-font-color);
        // img gradient overlay
        .field-name-field-cta-image {
          &::before {
            @include bg(rgba(color(neutral-warm,lo), 0.8), rgba(color(neutral-warm,xlo), 0.8));
          }
        }
        .field-name-field-cta-link a {
          @include btn($style: solid, $size: s, $dark: false);
          @include media(bp(m)) {
            @include btn($style: outline, $size: s, $dark: false);
            @include override-nested() {
              background: transparent;
              border-color: #fff;
              color: #fff;
            }
            &:hover {
              background: #fff;
              color: color(neutral-brand, xxlo);
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(3) {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone) {
      @include cta-full-width-bg-colors(#fff, $component-bg-auto-font-color, color(action, m), $component-bg-3-font-color);
      // img gradient overlay
      .field-name-field-cta-image {
        &::before {
          @include bg(rgba(color(neutral-brand,xxlo), 0.8), rgba(color(secondary,lo), 0.85));
        }
      }
      .field-name-field-cta-link a {
        @include btn($style: solid, $size: s, $dark: false);
        @include media(bp(m)) {
          @include btn($style: outline, $size: s, $dark: false);
          @include override-nested() {
            background: transparent;
            border-color: #fff;
            color: #fff;
          }
          &:hover {
            background: #fff;
            color: color(neutral-brand, xxlo);
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(2) {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone) {
      @include cta-full-width-bg-colors(#fff, $component-bg-auto-font-color, color(action, m), $component-bg-3-font-color);
      // img gradient overlay
      .field-name-field-cta-image {
        &::before {
          @include bg(rgba(color(neutral-brand,xxlo), 0.8), rgba(color(primary,lo), 0.85));
        }
      }
      .field-name-field-cta-link a {
        @include btn($style: solid, $size: s, $dark: false);
        @include media(bp(m)) {
          @include btn($style: outline, $size: s, $dark: false);
          @include override-nested() {
            background: transparent;
            border-color: #fff;
            color: #fff;
          }
          &:hover {
            background: #fff;
            color: color(neutral-brand, xxlo);
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(1) {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone) {
      @include cta-full-width-bg-colors($component-bg-auto-color, $component-bg-auto-font-color, color(action, m), $component-bg-auto-font-color);
      .field-name-field-title h2,
      .field-name-field-cta-description p,
      .field-name-field-cta-description a {
        @include media(bp(m)) {
          text-shadow: 0 3px 3px rgba(255, 255, 255, 0.18), 0 0 3px rgba(255, 255, 255, 0.08);
        }
      }
      // img gradient overlay
      .field-name-field-cta-image {
        background: #fff;
        &::before {
          @include bg(rgba(lighten(color(neutral-warm,m),40%), 0.7), rgba(lighten(color(neutral-warm,hi),20%), 0.8));
        }
      }
      .field-name-field-cta-link a {
        @include btn($style: solid, $size: s, $dark: false);
        @include media(bp(m)) {
          @include btn($style: outline, $size: s, $dark: false);
          @include override-nested() {
            border-color: color(neutral-brand, xxlo);
            color: color(neutral-brand, xxlo);
            background: transparent;
          }
          &:hover {
            background: color(neutral-brand, xxlo);
            color: #fff;
          }
        }
      }
    }
  }
  // Add subtle bottom border to fix overlap between
  // full width CTA with white text box and regions with white backgrounds
  &.cb-padding--none {
    .pane-bundle-cta.cb-style:only-child,
    .panels-ipe-bundle-cta:only-child {
      .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone) {
        border-bottom: $border--xs;
        @include media(bp(m)) {
          border-bottom: none;
        }
      }
    }
  }
}

// Featured layout with sidebars
// Default blue cta gradient
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region.cb-background {
      .pane-bundle-cta.cb-style:only-child,
      .panels-ipe-bundle-cta:only-child {
        .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone) {
          @include cta-full-width-bg-colors(#fff, $component-bg-auto-font-color, color(action, m), $component-bg-3-font-color);
          // img gradient overlay
          .field-name-field-cta-image {
            &::before {
              @include bg(rgba(color(neutral-brand,xxlo), 0.8), rgba(color(primary,lo), 0.85));
            }
          }
          .field-name-field-cta-link a {
            @include btn($style: solid, $size: s, $dark: false);
            @include media(bp(m)) {
              @include btn($style: outline, $size: s, $dark: false);
              @include override-nested() {
                background: transparent;
                border-color: #fff;
                color: #fff;
              }
              /* stylelint-disable selector-max-compound-selectors */
              &:hover {
                background: #fff;
                color: color(neutral-brand, xxlo);
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}
