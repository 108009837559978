// Opening Hours Typography Styles

// Standardized component default font sizes
.region,
.cb-page-content .cb-content-area__main {
  .panels-ipe-bundle-place,
  .pane-bundle-place {
    h3 {
      @include component-heading(s);
      @include media(bp(bs)) {
        @include component-heading(m);
      }
      @include media(bp(m)) {
        @include component-heading(xl);
      }
    }
    .cw-opening-hours {
      &__day,
      &__time {
        @include component-paragraph(s);
        @include media(bp(m)) {
          @include component-paragraph(m);
        }
      }
      &__day {
        @media screen and (max-width: bp(bs)) {
          font-weight: 600;
        }
      }
    }
  }
}

// Featured Layout with sidebars:
// Decrease font size due to smaller page content width
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .panels-ipe-bundle-place,
      .pane-bundle-place {
        h3 {
          @include media(bp(l)) {
            @include component-heading(l);
          }
        }
      }
    }
  }
}

// Main Content Only
.cb-layout--aside-first-last {
  .cb-content-area__main {
    .panels-ipe-bundle-place,
    .pane-bundle-place {
      @include media(bp(l)) {
        h3 {
          @include component-heading(l);
        }
        .cw-opening-hours {
          &__day,
          &__time {
            @include component-paragraph(s);
          }
        }
      }
    }
  }
}

// Component Specific Typography Styles
.cw-opening-hours {
  h3 {
    text-align: center;
    margin-bottom: 0;
    .cw-opening-hours__status {
      font-style: italic;
    }
  }
  .field-name-field-summary {
    p,
    a {
      font-family: $font--text--alt;
    }
    p {
      font-size: 0.9em;
      color: color(neutral-brand, m);
    }
  }
}

ul.cw-opening-hours__hours {
  li {
    margin: 0.5em 0;
    line-height: 1;
    span {
      display: inline-block;
      vertical-align: top;
    }
  }
  .cw-opening-hours {
    &__day,
    &__time {
      text-align: center;
      font-family: $font--text--alt;
    }
    &__day {
      font-weight: 600;
      @include media(bp(bs)) {
        text-align: left;
      }
    }
    &__time {
      white-space: nowrap;
      @include media(bp(bs)) {
        text-align: right;
      }
    }
  }
}
