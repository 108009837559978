// Header alert
.cb-alert {
  @mixin alert-icon($icon) {
    .cb-alert__wrapper {
      position: relative;
      @include icon($icon, $size: 1.33em) {
        font-size: 1.25rem;
        margin-right: 0.25em;
        float: left;
        @include media(bp(l)) {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 1.4rem;
        }
      }
    }
  }
  .cb-alert__wrapper {
    max-width: 100%;
    display: inline-block;
    text-align: left;
    @include media(bp(l)) {
      padding-left: 2rem;
      max-width: $max-width;
    }
  }
  p, a {
    font-family: $font--text--alt;
    font-weight: 400;
    line-height: 1.5;
  }
  ul, ol, dl {
    line-height: 1.5;
    margin: 0.25em 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul, ol{
    padding-left: 1.5em;
  }
  p {
    margin-bottom: 0.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: 1em;
    font-weight: 600;
    display: inline-block;
    vertical-align: text-bottom;
    line-height: 1.5;
    margin-bottom: 0;
    @include media(bp(l)) {
      font-size: 1.125em;
      display: inline-block;
      vertical-align: baseline;
    }
    &:first-child {
      margin-top: -0.25rem;
    }
    + .cb-alert__message {
      margin-top: 0.25em;
    }
  }
  .cb-alert__message, p, ul, ol, dl {
    font-size: 0.9em;
    @include media(bp(l)) {
      font-size: 1em;
    }
  }
  &--emergency {
    @include alert-icon("warning");
  }
  &--advisory {
    @include alert-icon("advisory");
  }
  &--notice,
  &--notice-warm,
  &--notice-cool {
    @include alert-icon("info");
  }
  .js & {
    display: none;
    &.cb-alert--visible {
      display: block;
      .cb-alert__wrapper {
        padding-right: 37px;
        @include media(bp(l)) {
          padding-right: 50px;
        }
      }
      .cb-alert__dismiss {
        border-radius: 0;
        box-shadow: none;
        position: absolute;
        // Padding from alert critical styles.
        top: -0.8rem;
        right: 0;
        padding: 0;
        width: $touch-size-min;
        height: $touch-size-min;
        font-size: 1rem;
        @include icon("close");
        @include media(bp(l)) {
          // Padding from alert critical styles.
          top: -0.9rem;
          width: $touch-size-large;
          height: $touch-size-large;
        }
      }
    }
  }
}
