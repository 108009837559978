// Promoted Testimonials styles depending on list number

.region {
  .cb-list-testimonials--promoted {
    @include media(bp(m)) {
      .cb-list-testimonials {
        &__content {
          li,
          .cb-layout__component-dynamic,
          .col {
            display: flex;
            flex-direction: column;
          }
          li {
            width: 100%;
          }
          .cb-layout__component-dynamic {
            flex: 1 0 auto;
          }
        }
      }
      .view-content {
        @include clearfix();
        > ul {
          display: flex;
          flex-wrap: wrap;
        }
        li {
          // 2 or 4 testimonials: display max 2 per row
          @include quantities-all(2,4) {
            margin: 0 1.7% 3.4% 1.7%;
            width: 44.6%;
            flex: 1 0 auto;
            // decrease img teaser size
            .cb-layout__component-dynamic {
              // fix inconsistent flex width for varying title lengths
              margin: 0;
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              .column-second {
                .cb-list-testimonials {
                  &__image-teaser {
                    width: 100px;
                    img {
                      width: 100px;
                    }
                  }
                }
              }
              .column-first:not(:only-child) {
                .cb-list-testimonials {
                  &__title {
                    min-height: 72px;
                    padding-right: 140px;
                  }
                }
              }
              /* stylelint-enable */
            }
          }
          @include quantities-all(2) {
            margin-bottom: 0;
          }
          @include quantity-positions(2,1) {
            margin-left: 0;
          }
          @include quantity-positions(2,2) {
            margin-right: 0;
          }
          @include quantity-positions(4,1,3) {
            margin-left: 0;
          }
          @include quantity-positions(4,2,4) {
            margin-right: 0;
          }
          @include quantity-positions(4,3,4) {
            margin-bottom: 0;
          }
          // ie10 and ie11 needs this to center align content
          // since "margin: 0 auto" conflicts with flexbox
          @include quantities-all(1) {
            align-items: center;
          }
        }
      }
    }
    .view-content {
      ul {
        width: 100%;
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-testimonials--promoted {
      @include media(bp(xl)) {
        .view-content {
          li {
            // 3 Testimonials: display 3 side by side
            @include quantities-all(3) {
              flex: 1 0 auto;
              margin: 0 1.7%;
              float: left;
              width: 21.6%;
              // decrease img teaser size
              .cb-layout__component-dynamic {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                .column-second {
                  .cb-list-testimonials {
                    &__image-teaser {
                      width: 100px;
                      img {
                        width: 100px;
                      }
                    }
                  }
                }
                .column-first:not(:only-child) {
                  .cb-list-testimonials {
                    &__title {
                      min-height: 72px;
                      padding-right: 140px;
                    }
                  }
                }
                /* stylelint-enable */
              }
            }
            @include quantity-positions(3,1) {
              margin-left: 0;
            }
            @include quantity-positions(3,3) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .cb-list-testimonials--promoted {
        @include media(bp(l)) {
          .view-content {
            li {
              // ie10 and ie11 needs this to center align content
              // since "margin: 0 auto" conflicts with flexbox
              @include quantities-all(3) {
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
