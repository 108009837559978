// CTA with link only and default 16:9 image (style auto) or no img
.region.cb-max-columns {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    &.cb-style--auto {
      &:not(:only-child) {
        .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
          .field-name-field-cta-link {
            padding-bottom: $spacing--m;
            a {
              @include component-heading(xs);
            }
          }
          &:not(.cw-with-image):not(.cw-with-icon) {
            .field-name-field-cta-link {
              padding-bottom: 0;
            }
          }
        }
      }
      @include media(bp(bs)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
              .field-name-field-cta-link a {
                @include component-heading(m);
                margin-bottom: 0;
              }
            }
          }
        }
      }
      @include media(bp(s)) {
        @include quantities-all(even) {
          .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link a {
              @include component-heading(s);
              margin-bottom: 0;
            }
          }
        }
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
              .field-name-field-cta-link a {
                @include component-heading(l);
                margin-bottom: 0;
              }
            }
          }
        }
      }
      @include media(bp(m)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
              .field-name-field-cta-link a {
                @include component-heading(xs);
                margin-bottom: 0;
              }
            }
          }
        }
        @include quantities-all(even) {
          .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link a {
              @include component-heading(m);
              margin-bottom: 0;
            }
          }
        }
      }
      @include media(bp(l)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
              .field-name-field-cta-link a {
                @include component-heading(s);
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    &.cb-max-columns--4 {
      .pane-bundle-cta:not(:only-child),
      .panels-ipe-bundle-cta:not(:only-child) {
        &.cb-style--auto {
          @include media(bp(l)) {
            // 4+ items: fixed 1/4 page width for each
            .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
              .field-name-field-cta-link a {
                @include component-heading(xs);
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    &.cb-max-columns--3,
    &.cb-max-columns--4 {
      .pane-bundle-cta:not(:only-child),
      .panels-ipe-bundle-cta:not(:only-child) {
        &.cb-style--auto {
          @include media(bp(l)) {
            @include quantities-all(2) {
              .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
                .field-name-field-cta-link a {
                  @include component-heading(l);
                  margin-bottom: 0;
                }
              }
            }
            @include quantities-all(3) {
              .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description) {
                .field-name-field-cta-link a {
                  @include component-heading(m);
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}


// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .pane-bundle-cta:not(:only-child),
        .panels-ipe-bundle-cta:not(:only-child) {
          &.cb-style--auto {
            @include media(bp(l)) {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              .paragraphs-item-cta:not(.cw-cta-phone) {
                &:not(.cw-with-title):not(.cw-with-description) {
                  .field-name-field-cta-link a {
                    @include component-heading(xs);
                    margin-bottom: 0;
                  }
                }
              }
              @include quantities-all(2,4) {
                .paragraphs-item-cta:not(.cw-cta-phone) {
                  &:not(.cw-with-title):not(.cw-with-description) {
                    .field-name-field-cta-link a {
                      @include component-heading(s);
                      margin-bottom: 0;
                    }
                  }
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}
