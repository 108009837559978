.cb-layout--aside-last .cb-content-area__aside-last,
.cb-layout--aside-first-last .cb-content-area__aside-last {
  .pane {
    &.panels-ipe-cola-search-faculty-box-simple,
    &.panels-ipe-cola-search-providers-box-simple {
      border: none;
      padding: 0;
    }
  }
}

// Style fixes within Drupal panels ipe editing display
.cb-flavor.panels-ipe-editing {
  .cb-layout--aside-last .cb-content-area__aside-last,
  .cb-layout--aside-first-last .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-cola-search-faculty-box-simple,
      &.panels-ipe-cola-search-providers-box-simple {
        .panels-ipe-portlet-content {
          padding: 0;
        }
      }
    }
  }
}
