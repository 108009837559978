// Clinic Location component is displayed in the sidebar by default
// default address with map styles will apply if component is in the main content

.node-type-clinic {
  .cb-content-area__aside-last {
    .pane-node-field-clinic-location {
      background: $sidebar-component-bg-color;
      text-align: center;
      margin: 0 auto;
      font-family: $font--text--alt;
      max-width: 100%;
      @include component-padding();
      h2.pane-title {
        @include component-heading(m);
        max-width: 100%;
      }
      a {
        color: $sidebar-component-link-color;
        // Preserve focus outlines for link with image.
        display: inline-block;
        margin: 1px;
        &:hover {
          text-decoration: underline;
        }
      }
      .cb-phone,
      .cb-email {
        // remove phone icon underline on hover
        a:hover {
          &::before {
            display: inline-block;
            // to make 'no underline' work in IE11,
            // this needs to be declared first
            // followed by text-decoration: none
            // in separate declaration below
            text-decoration: underline;
          }
          &::before {
            text-decoration: none;
          }
        }
      }
      img {
        max-width: 400px;
      }
      .pane-title {
        margin-bottom: 1rem;
      }
      .cb-contact-information.cw-with-map,
      .cb-contact-information.cw-with-map .content {
        // fix map hidden focus outlines
        overflow: inherit;
      }
    }
  }
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last {
    .cb-content-area__aside-last {
      .pane-node-field-clinic-location {
        @include media(bp(s)) {
          text-align: left;
          img {
            width: 100%;
          }
        }
        h2.pane-title {
          @include media(bp(s)) {
            margin-left: 1rem;
            margin-bottom: 1rem;
          }
          @include media(bp(m)) {
            margin-left: 0;
          }
        }
        .paragraphs-items:not(.paragraphs-items-field-lab-location) {
          .cb-contact-information {
            > .content {
              @include media(bp(m)) {
                padding-top: 0;
              }
              @include media(bp(xl)) {
                min-height: 325px;
              }
            }
          }
        }
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane-node-field-clinic-location {
        margin-bottom: $spacing--xl;
        h2.pane-title {
          @include component-heading-right-rail();
        }
        .paragraphs-item-postal-address > *,
        .paragraphs-item-postal-address p,
        .paragraphs-item-postal-address a,
        .field-name-field-postal-address-phone,
        .field-name-field-postal-address-email {
          @include component-paragraph-right-rail();
          text-align: center;
        }
        /* stylelint-disable selector-max-compound-selectors */
        .paragraphs-item-postal-address a {
          font-weight: 600;
          color: $sidebar-component-link-color;
        }
        /* stylelint-enable */
      }
    }
  }
}
