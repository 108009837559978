// In Numbers sidebar component styles

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-bundle-numbers,
      &.pane-bundle-numbers {
        background: $sidebar-component-bg-color;
        @include component-sidebar-padding();
        .cb-numbers {
          &__title {
            @include component-heading(s);
            color: $sidebar-component-title-color;
            text-align: center;
            @include media(bp(bs)) {
              @include component-heading(m);
            }
            @include media(bp(s)) {
              @include component-heading(l);
            }
            @include media(bp(m)) {
              @include component-heading(xl);
            }
          }
          &__numbers {
            dd, dt {
              @include component-paragraph(s);
            }
          }
        }
      }
    }
  }
}

// Sidebar specific styles for in numbers list
.cb-content-area__aside-last {
  .pane {
    &.panels-ipe-bundle-numbers,
    &.pane-bundle-numbers {
      overflow: hidden;
      max-width: 100%;
      .cb-numbers {
        &__title {
          text-align: center;
        }
        &__numbers {
          max-width: $sidebar-component-content-max-width;
          margin: $spacing--m auto 0 auto;
          li {
            padding-bottom: $spacing--m;
            width: 100%;
            margin: 0.5em 0;
            float: left;
            border-bottom: $border--s;
            border-color: $sidebar-component-border-color;
            @include media(bp(m)) {
              width: 48.3%;
              &.odd {
                margin-right: 1.7%;
              }
              &.even {
                margin-left: 1.7%;
              }
            }
          }
        }
        + .cb-numbers {
          margin-top: $spacing--xl;
        }
        &:first-child {
          .cb-numbers__numbers {
            margin-top: 0;
          }
        }
        .cb-number {
          > .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          &__number {
            display: flex;
            order: 2;
            max-width: 100%;
          }
          &__description {
            max-width: 90%;
          }
          dl, dt, dd {
            margin: 0;
          }
        }
      }
    }
  }
  .cb-numbers__numbers {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      display: block;
      div {
        display: block;
      }
    }
    .cb-number {
      &__number {
        font-weight: 700;
      }
      &__description {
        b {
          font-weight: 600;
          font-style: italic;
        }
      }
      .content {
        display: block;
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.panels-ipe-bundle-numbers,
        &.pane-bundle-numbers {
          padding: $sidebar-component-right-rail-padding;
          .cb-numbers {
            &__title {
              @include component-heading-right-rail();
            }
            &.cw-with-icon {
              .cb-numbers__title {
                text-align: left;
              }
            }
            &__numbers {
              dd, dt {
                @include component-paragraph-right-rail();
              }
              li {
                width: 100%;
                /* stylelint-disable selector-max-compound-selectors */
                &.odd {
                  margin-right: 0;
                }
                &.even {
                  margin-left: 0;
                }
                &:last-child {
                  border-bottom: none;
                  padding-bottom: 0;
                  margin-bottom: 0;
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
  }
}

.cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-bundle-numbers,
      &.pane-bundle-numbers {
        .cb-numbers {
          &.cw-with-icon {
            > .content {
              &::before {
                @include media(bp(xl)) {
                  margin-right: 0.5rem;
                }
              }
            }
            .cb-numbers {
              &__icon {
                color: color(neutral-brand, xxlo);
              }
              &__title {
                @include media(bp(xl)) {
                  padding-left: calc(15% + 0.5rem);
                }
              }
            }
            .paragraphs-items-field-numbers-paragraphs {
              width: 100%;
              @include media(bp(xl)) {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

.cb-page-content--feature-rows {
  .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-bundle-numbers,
      &.pane-bundle-numbers {
        .cb-numbers {
          &.cw-with-icon {
            .cb-numbers {
              &__numbers {
                max-width: 100%;
              }
              &__icon {
                color: color(neutral-brand, xxlo);
              }
            }
          }
        }
      }
    }
  }
}
