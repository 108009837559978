// Promoted Blog Only Base Styles

.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted.cb-list-news--blog,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
      ul:not(.panels-ipe-linkbar) {
        li {
          padding: 0;
          /* stylelint-disable selector-max-compound-selectors */
          .cb-layout__component-dynamic {
            display: flex;
            flex-direction: column;
          }
          .column-first.column--first,
          .column-second.column--last {
            width: 100%;
          }
          .column-first.column--first {
            order: 2;
            @include component-padding();
          }
          .column-second.column--last {
            order: 1;
          }
          .cb-list-news {
            &__image-teaser {
              margin-top: 0;
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
