.cb-proofpoint {
  form {
    padding: 1em;
    margin-top: 0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: $box-shadow;
    position: relative;
    @include media(bp(s)) {
      padding: 2em;
    }
  }
  .form-required {
    color: color(alert-fail);
  }
  label,
  p,
  .cb-proofpoint__result {
    font-family: $font--text--alt;
    font-weight: 600;
    font-size: 1rem;
  }
  .cb-proofpoint__description p {
    margin-top: -0.5em;
    color: color(neutral-brand, m);
  }
  &__text-input {
    @include media(bp(s)) {
      width: 75%;
      margin-bottom: 0;
    }
    input {
      width: 100%;
      float: left;
      display: block;
      height: 44px;
      background: #fff;
      font-family: $font--text--alt;
      font-size: 1em;
      padding-left: 0.7em;
      padding-right: 2.5em;
      text-overflow: ellipsis;
      border: solid 3px darken(color(neutral-brand, hi), 1%);
      @include media(bp(s)) {
        height: 2.6em;
        padding: 12px;
        line-height: 1;
        font-size: 1.2em;
        border-right: none;
        // iOS adds border radii to the form fields.
        // let's make sure the radii are disabled so the button butts up.
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &::placeholder {
        color: color(neutral-brand, m);
      }
    }
    + .cb-proofpoint__submit {
      box-shadow: none;
      margin-top: 1em;
      width: 100%;
      @include media(bp(s)) {
        // Set the button height to the same height as the input field.
        height: 3.12em;
        width: 25%;
        border-radius: 0 4px 4px 0;
        margin-top: 0;
      }
      &:focus,
      &:hover,
      &:active {
        box-shadow: none;
      }
    }
  }
  &__result {
    margin-top: 1.25em;
    min-height: calc(1.7em + 26px);
    position: relative;
    padding: 12px;
    transition: all 0.2s;
    &--default {
      border: 1px dotted darken(color(neutral-brand, hi), 1%);
    }
    &--error {
      border: 1px solid color(alert-fail);
      .messages {
        @include icon('fail', $color: color(alert-fail));
      }
    }
    &--success {
      border: 1px solid color(alert-pass);
      p {
        @include icon('check', $color: color(alert-pass));
      }
    }
    &::after {
      content: 'Result';
      text-transform: uppercase;
      position: absolute;
      top: -0.35em;
      left: 6px;
      font-size: 0.7em;
      line-height: 1;
      background: #fff;
      color: color(neutral-brand, m);
      padding: 0 6px;
    }
    .messages,
    p {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: none;
      min-width: 0;
      max-width: 100%;
      display: inline-block;
    }
  }
  .ajax-progress-throbber {
    position: absolute;
    left: calc(1em + 13px);
    // Calc form padding + half result height - half throbber height.
    bottom: calc(1.85em + 5px);
    line-height: 1;
    @include media(bp(s)) {
      // Calc form padding + half result height - half throbber height.
      bottom: calc(2.85em + 5px);
      // Left calc form padding + result padding.
      left: calc(2em + 13px);
    }
    .throbber {
      margin: 0;
    }
    + .cb-proofpoint__result {
      border: 1px dotted darken(color(neutral-brand, hi), 1%);
      > * {
        // stylelint-disable declaration-no-important
        // Need important to override inline javascript styles.
        display: none !important;
        // stylelint-enable declaration-no-important
      }
    }
  }
}
