// CTA Phone Typography Styles

// Standardized component default font sizes
.region {
  &.cb-max-columns {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta {
      .paragraphs-item-cta.cw-cta-phone {
        .content {
          line-height: 1.35;
          .field-name-field-cta-link-prefix,
          .field-name-field-cta-link,
          .field-name-field-cta-link-suffix {
            margin-bottom: 0;
            @include component-link-title(s);
            @include media(bp(bs)) {
              @include component-link-title(m);
            }
            @include media(bp(s)) {
              @include component-link-title(l);
            }
            @include media(bp(m)) {
              @include component-link-title(xl);
            }
          }
          .field-name-field-cta-link-prefix,
          .field-name-field-cta-link-suffix {
            font-weight: 600;
          }
        }
      }
    }
  }
}

// Component Specific Typography Styles
.paragraphs-item-cta.cw-cta-phone {
  .content {
    text-align: center;
    .field-name-field-cta-description {
      margin-bottom: 1em;
    }
    .field-name-field-cta-link-prefix,
    .field-name-field-cta-link,
    .field-name-field-cta-link-suffix {
      font-family: $font--text--alt;
    }
    a {
      font-weight: 700;
    }
  }
}
