.node-type-trial {
  .cb-clinical-trial {
    &__conditions,
    &__specialties,
    &__investigator {
      &:not(:last-child) {
        margin-bottom: $spacing--xl;
        @include media(bp(m)) {
          margin-bottom: $spacing--xxl;
        }
      }
    }
    &__contact {
      padding: $spacing--m;
      background: lighten(color(secondary, xhi), 16%);
      @include media(bp(bs)) {
        padding-left: $spacing--l;
        padding-right: $spacing--l;
      }
      @include media(bp(m)) {
        padding-top: $spacing--m;
        padding-bottom: $spacing--m;
      }
      h2,
      .field-name-field-phone {
        @include component-heading(xs);
        display: inline;
        @include media(bp(bs)) {
          @include component-heading(s);
        }
      }
      a {
        @include component-heading(xs);
        text-decoration: underline;
        color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
        @include media(bp(bs)) {
          @include component-heading(s);
        }
        &:hover {
          text-decoration: none;
          color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
        }
        &::before {
          vertical-align: middle;
          font-size: 0.95em;
          color: color(neutral-brand, xxlo);
        }
      }
    }
    &__ids,
    &__status,
    &__population,
    &__phase {
      padding: $spacing--s $spacing--m;
      @include media(bp(bs)) {
        padding-left: $spacing--l;
        padding-right: $spacing--l;
      }
      @include media(bp(m)) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-top: $spacing--m;
        padding-bottom: $spacing--m;
      }
      @include media(bp(xl)) {
        display: block;
      }
      &:not(:last-child) {
        border-bottom: $border--xs;
        @include media(bp(m)) {
          border-right: $border--xs;
          border-bottom: none;
        }
      }
      h2 {
        margin: 0;
      }
      a {
        color: a11yrize($text-link-color, $background-solid-xlight);
      }
    }
    &__ids,
    &__id,
    &__status,
    &__population,
    &__phase {
      > h2,
      > .field {
        font-size: 0.95em;
        font-family: $font--text--alt;
        @include media(bp(bs)) {
          display: inline-block;
          font-size: 0.89em;
        }
        @include media(bp(m)) {
          display: block;
        }
        @include media(bp(xl)) {
          display: inline-block;
        }
      }
    }
    &__id--nct {
      &:not(:only-child) {
        margin-bottom: $spacing--s;
      }
    }
    &__infobox {
      background: $background-solid-xlight;
      margin-bottom: $spacing--xl;
      line-height: 1.4;
      > .cb-panel-container {
        @include media(bp(m)) {
          display: flex;
        }
        > .panel-pane {
          @include quantity-positions(3,1) {
            @include media(bp(m)) {
              padding-right: $spacing--s;
            }
          }
          @include quantity-positions(3,2) {
            @include media(bp(m)) {
              padding-left: $spacing--s;
              padding-right: $spacing--s;
            }
          }
          @include quantity-positions(3,3) {
            @include media(bp(m)) {
              padding-left: $spacing--s;
            }
          }
          @include quantity-positions(4,1) {
            @include media(bp(m)) {
              padding-right: $spacing--s;
              // to display "NCT no." ext link icon inline
              min-width: 154px;
            }
            @include media(bp(xl)) {
              min-width: auto;
            }
          }
          @include quantity-positions(4,2,3) {
            @include media(bp(m)) {
              padding-left: $spacing--s;
              padding-right: $spacing--s;
            }
          }
          @include quantity-positions(4,4) {
            @include media(bp(m)) {
              padding-left: $spacing--s;
            }
          }
        }
      }
    }
    &__conditions,
    &__specialties {
      font-family: $font--text--alt;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// Decrease page title size due to clinical
// trial names being extremely long in general
.node-type-trial {
  .pane-page-title h1 {
    font-size: 1.8em;
    @include media(bp(m)) {
      font-size: 2.2em;
    }
    @include media(bp(l)) {
      font-size: 2.35em;
    }
    @include media(bp(xl)) {
      font-size: 2.5em;
    }
  }
}
