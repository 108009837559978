@mixin more-link($margin-left: 0.5rem, $icon-size: 0.75rem) {
  text-decoration: none;
  @include icon($icon: 'caret-right', $pseudo: 'after', $size: $icon-size) {
    vertical-align: baseline;
    margin-left: $margin-left;
  }
  &:hover {
    &::after {
      @include animated($duration: 1s, $name: wobble);
    }
  }
}
