// Shared styles for search results header
.cb-list-trials__header,
.cb-list-conditions-treatments__header,
.cb-list-programs__header,
.cb-list-news__header,
.cb-list-cups__header,
.cb-cups-full-search .view-header,
.cb-news-full-search .view-header,
.view-cola-programs-search .view-header,
.view-cola-events-search .view-header,
.cb-list-services__header,
.cb-site-search__header,
.cb-list-resources__header {
  @include span-columns(12) {
    font-size: 1em;
    margin: 3em 0;
    float: none;
    font-family: $font--display;
    margin-bottom: 3em;
    font-weight: 700;
    padding-bottom: 0.5em;
    border-bottom: $border--l;
  }
}

.cb-list-news__header,
.cb-list-cups__header {
  // fix ipe issue
  .panels-ipe-portlet-content & {
    &:last-child {
      margin-bottom: 3em;
    }
  }
}

.cb-list-trials__header,
.cb-list-conditions-treatments__header,
.cb-site-search__header,
.cb-list-cups__header,
.cb-cups-full-search .view-header,
.cb-news-full-search .view-header,
.view-cola-programs-search .view-header,
.view-cola-events-search .view-header,
.cb-list-services__header,
.cb-list-resources__header {
  font-weight: 400;
  font-size: 1em;
  @include media(bp(s)) {
    font-size: 1.1em;
  }
}

.cb-list-news {
  &__header {
    p {
      font-weight: 400;
      font-size: 1em;
      @include media(bp(s)) {
        font-size: 1.25em;
      }
    }
  }
}

// With Alphabetical Filter
.cb-list-conditions-treatments--search .cb-list-conditions-treatments,
.cb-list-services--standard .cb-list-services {
  &__header {
    margin-bottom: 0;
  }
  &__alphabet-filter {
    + .cb-list-conditions-treatments__header,
    + .cb-list-services__header {
      border: none;
    }
  }
}
