.cb-content-area__main {
  .cb-appointments {
    display: block;
    height: 100%;
    clear: both;
    text-align: left;
    @include component-padding();
    .panels-ipe-editing & {
      padding: 0;
    }
    &:not(:last-child) {
      margin-bottom: $spacing--xl;
    }
    &__title {
      text-align: left;
    }
    &__content {
      @include media(bp(s)) {
        display: flex;
        flex-direction: column;
      }
    }
    &__link {
      &:only-child {
        &:not(.cb-appointments__link--doctors) {
          text-align: left;
        }
      }
    }
    &:not(.cw-with-description) {
      .cb-appointments {
        &__links {
          @include media(bp(s)) {
            margin-top: 0;
          }
        }
        &__link {
          &:only-child {
            &:not(.cb-appointments__link--doctors) {
              a {
                padding-left: 1.8em;
              }
            }
          }
        }
      }
    }
  }
}

// appointments without find a doc link

.cb-layout {
  .cb-content-area__main {
    .cb-appointments:not(.cw-with-doctors-link) {
      @include media($bp-only-m) {
        // 3 appointment links
        &.cw-with-book-online-link.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
        &.cw-with-book-online-link.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
        &.cw-with-book-online-link.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link),
        &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link:not(.cw-with-book-online-link) {
          /* stylelint-disable selector-max-compound-selectors */
          .cb-appointments__links {
            a {
              font-size: 0.81rem;
            }
          }
          /* stylelint-enable */
        }
      }
      @include media($bp-only-l) {
        .cb-appointments {
          &__link {
            @include quantities-all(2,4) {
              width: 49.4%;
            }
          }
        }
        // 3 appointment links
        &.cw-with-book-online-link.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
        &.cw-with-book-online-link.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
        &.cw-with-book-online-link.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link),
        &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link:not(.cw-with-book-online-link) {
          /* stylelint-disable selector-max-compound-selectors */
          .cb-appointments__links {
            display: block;
            .cb-appointments__link {
              margin-right: 0;
            }
            a {
              font-size: 1rem;
            }
          }
          /* stylelint-enable */
        }
      }
      @include media(bp(l)) {
        .cb-appointments {
          &__link {
            @include quantities-all(2,4) {
              a {
                font-size: 0.84em;
              }
            }
            &:only-child {
              a,
              a::before {
                font-size: 1.1rem;
              }
            }
            &--phone {
              &:only-child {
                a::before {
                  font-size: 1.2rem;
                  padding-top: 0.1em;
                  padding-left: 0.1em;
                }
              }
            }
          }
        }
        // fix ie11 content overflow
        // 4 appointments links
        &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
          .cb-appointments__content {
            width: 100%;
          }
          &.panels-ipe-portlet-wrapper {
            display: flex;
          }
        }
      }
    }
  }
}

// styles depending on sidebars display
.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first {
    .cb-content-area__main {
      .cb-appointments:not(.cw-with-doctors-link) {
        @include media(bp(xl)) {
          .cb-appointments {
            &__link {
              @include quantities-all(2,4) {
                a:not(.cw-appointments__overlay-link) {
                  font-size: 1.1em;
                }
              }
            }
          }
          // default overrides: 3 appointments links
          /* stylelint-disable selector-max-compound-selectors */
          &:not(.cw-with-book-online-link) {
            &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
              .cb-appointments__links {
                a {
                  font-size: 1em;
                }
              }
            }
          }
          &.cw-with-book-online-link {
            &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
            &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link) {
              .cb-appointments__links {
                a {
                  font-size: 1em;
                }
              }
            }
            &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
              .cb-appointments__links {
                a {
                  font-size: 0.9em;
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-last {
    .cb-content-area__main {
      .cb-appointments:not(.cw-with-doctors-link) {
        @include media(bp(xl)) {
          .cb-appointments {
            &__link {
              @include quantities-all(2,4) {
                a {
                  font-size: 1em;
                }
              }
            }
            // default overrides: 3 appointments links
            /* stylelint-disable selector-max-compound-selectors */
            &:not(.cw-with-book-online-link) {
              &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
                .cb-appointments__links {
                  a {
                    font-size: 1em;
                  }
                }
              }
            }
            &.cw-with-book-online-link {
              &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
              &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
              &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
                .cb-appointments__links {
                  a {
                    font-size: 0.9em;
                  }
                }
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first-last {
    .cb-content-area__main {
      .cb-appointments:not(.cw-with-doctors-link) {
        @include media(bp(xl)) {
          .cb-appointments {
            /* stylelint-disable selector-max-compound-selectors */
            &__link {
              @include quantities-all(2,4) {
                width: 49.378%;
                a {
                  font-size: 0.77em;
                }
              }
              @include quantities-all(3) {
                width: 100%;
              }
            }
          }
          &:not(.cw-with-book-online-link) {
            .cb-appointments__link {
              @include quantities-all(2) {
                a {
                  font-size: 0.9em;
                }
              }
            }
          }
          // default overrides: 3 appointments links
          &:not(.cw-with-book-online-link) {
            &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
              .cb-appointments__links {
                display: block;
                a {
                  font-size: 0.9em;
                }
              }
            }
          }
          &.cw-with-book-online-link {
            &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
            &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
            &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
              .cb-appointments__links {
                display: block;
                a {
                  font-size: 0.9em;
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside {
  .cb-content-area__main {
    .cb-appointments:not(.cw-with-doctors-link) {
      .cb-appointments {
        &__link {
          @include quantities-all(2,4) {
            @include media(bp(l)) {
              width: 49.42%;
              a {
                font-size: 0.83em;
              }
            }
            @include media(bp(xl)) {
              a {
                font-size: 1em;
              }
            }
          }
        }
      }
      // default overrides: 3 appointments links
      &:not(.cw-with-book-online-link) {
        &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
          .cb-appointments__link {
            a {
              @include media(bp(l)) {
                font-size: 1em;
              }
            }
          }
        }
      }
      &.cw-with-book-online-link {
        &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
        &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
        &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
          .cb-appointments__link {
            /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
            a {
              @include media(bp(l)) {
                font-size: 1em;
                &::before {
                  margin-left: -1.6em;
                }
              }
              @include media(bp(xl)) {
                font-size: 0.84em;
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
