.cb-overlay-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}
