// Apply Now CTA.
// Similar styles as wysiwyg widgets.
.node-type-program {
  .cb-programs-apply {
    margin-bottom: 1rem;
    @include media(bp(m)) {
      margin-top: 0.5rem;
      margin-right: 1.5rem;
      width: 45%;
      max-width: 28.75rem;
      float: left;
    }
  }
  .field-name-field-program-apply-intro {
    p {
      font-family: $font--text--alt;
      font-size: 0.9rem;
      background: $background-solid-xlight;
      padding: 1.8rem;
      @include media(bp(m)) {
        padding: $spacing--l;
      }
    }
  }
  .pane-node-field-program-apply-link {
    a {
      width: 100%;
      text-align: center;
    }
    &:nth-child(2) a {
      border-radius: 0;
      font-size: 1rem;
    }
  }
}

// Program Director.
.node-type-program {
  .cb-programs-director {
    position: relative;
    overflow: hidden;
    margin-bottom: $spacing--xl;
    @include media(bp(m)) {
      border-bottom: $text-horizontal-rule-border;
      padding-bottom: $spacing--xl;
    }
    .pane-title {
      font-size: 1.125em;
      @include media(bp(m)) {
        font-size: 1.25em;
      }
    }
  }
}

// Course Listing with total credits.
.node-type-program {
  .pane-cola-programs-courses {
    .pane-node-field-text-tiny h2 {
      margin-bottom: 0.5em;
    }
  }
  .pane-node-field-generic-paragraphs {
    // add structured content appears before
    + .pane-cola-programs-courses {
      margin-top: $spacing--xl;
      @include media(bp(m)) {
        margin-top: $spacing--xxl;
      }
    }
  }
  .pane-cola-courses-courses-in-program {
    .view-footer {
      margin-top: $spacing--m;
      @include media(bp(m)) {
        margin-top: $spacing--xl;
      }
      h2 {
        font-size: 1rem;
        display: inline-block;
        margin-right: 1rem;
        @include media(bp(m)) {
          font-size: 1.25rem;
        }
      }
      span {
        font-family: $font--text--alt;
        font-size: 0.9rem;
        @include media(bp(m)) {
          font-size: 1rem;
        }
      }
    }
    // No active status means we have to adjust the widths here.
    .cb-course-listing__cell--name {
      max-width: calc(100% - 14em);
      width: calc(154% - 14em);
      min-width: 54%;
      @include media(bp(m)) {
        max-width: calc(100% - 15em);
        width: calc(150% - 15em);
        min-width: 50%;
      }
    }
  }
}
