.region {
  .cb-epic-scheduling {
    padding: $spacing--l;
    @include media(bp(l)) {
      padding: $spacing--xl $spacing--xl $spacing--xxl $spacing--xl;
    }
    &__title {
      @include media(bp(bs)) {
        @include component-heading(m);
      }
      @include media(bp(m)) {
        @include component-heading(xl);
      }
    }
  }
  .panels-ipe-editing & {
    .cb-epic-scheduling {
      padding: 0;
    }
  }
}
