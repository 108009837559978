// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing {
  .region,
  .cb-layout.cb-layout--aside-last .cb-content-area__aside-last,
  .cb-layout.cb-layout--aside-first-last .cb-content-area__aside-last {
    .pane {
      &.cb-badges {
        /* stylelint-disable selector-max-compound-selectors */
        .panels-ipe-dragbar {
          border: 1px solid #CCC;
        }
        /* stylelint-enable */
      }
    }
  }
}
