// Compact Lists base styles
.cb-list-specialty--patient-stories .cb-list-testimonials,
.cb-list-programs--compact .cb-list-programs,
.cb-list-labs--compact .cb-list-labs,
.cb-list-services--compact .cb-list-services {
  &__content {
    ul:not(.pager),
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 1.9em 0 2em 0;
        border-bottom: $border--s;
        margin-bottom: 0;
        &:first-child {
          border-top: $border--l;
        }
        &:last-child {
          border-bottom: $border--m;
        }
      }
    }
    ul:not(.pager) {
      .cb-layout__component-dynamic {
        // fix hidden focus outlines
        padding: 2px;
      }
    }
  }
  .column-first:not(.column--last) {
    @include media(bp(s)) {
      @include span-columns(8);
    }
  }
  .column-second {
    margin-top: 1em;
    @include media(bp(s)) {
      margin-top: 0;
      @include span-columns(4);
    }
  }
  &__image-teaser {
    img {
      width: 100%;
    }
  }
  &__title {
    h2, h3 {
      @include media(bp(m)) {
        font-size: 1.25em;
      }
      @include media(bp(l)) {
        font-size: 1.5em;
      }
    }
  }
  &__summary {
    margin-top: 1em;
  }
}

// Standardized typography across all "compact list" pages
.cb-list-programs--compact .cb-list-programs,
.cb-list-labs--compact .cb-list-labs,
.cb-list-services--compact .cb-list-services,
.cb-list-news--standard .cb-list-news,
.cb-list-events--standard .cb-list-events,
.cb-list-clinics--standard .cb-list-clinics,
.cb-list-testimonials--standard .cb-list-testimonials,
.cb-list-specialty--patient-stories .cb-list-testimonials {
  &__summary {
    p {
      font-size: 1rem;
      line-height: 1.5;
      @include media(bp(m)) {
        font-size: 1.1rem;
        line-height: 1.6;
      }
    }
  }
  &__title {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.cb-list-trials--standard .cb-list-trials {
  &__body {
    .field-type-text-with-summary {
      font-size: 1rem;
      line-height: 1.5;
      @include media(bp(m)) {
        font-size: 1.1rem;
        line-height: 1.6;
      }
    }
  }
}
