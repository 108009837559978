.cb-news-full-search {
  margin-top: 2em;
  @include media(bp(m)) {
    margin-top: 0;
  }
  .views-exposed-form {
    border-radius: 2px 2px 0 0;
  }
  // Text input field
  // stylelint-disable-next-line selector-class-pattern
  .views-widget-filter-search_api_views_fulltext {
    margin-bottom: 0;
    input {
      width: 100%;
      padding: 12px;
      line-height: 20px;
    }
    label {
      @include visually-hidden();
    }
  }
  // Filter dropdown
  .views-widget-filter-filter {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    select {
      width: 100%;
    }
  }
  .views-exposed-widget {
    &.views-widget-sort-by {
      max-width: 100%;
      @include span-columns(12);
      @include media(bp(s)) {
        @include span-columns(6);
      }
      select {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
      }
    }
    &.views-submit-button {
      width: 100%;
      margin-bottom: 0;
      margin-right: 1em;
      @include media(bp(s)) {
        display: inline-block;
        width: auto;
        margin: 0.5em 0 0 0;
        float: left;
      }
      .form-submit {
        width: 100%;
        margin-top: 0;
        background: $btn-bg-color-interior-pg-component;
        color: $btn-font-color-interior-pg-component;
        &:hover,
        &:focus {
          background: $btn-hover-bg-color-interior-pg-component;
          outline-color: $btn-hover-outline-color-interior-pg-component;
          color: $btn-hover-font-color-interior-pg-component;
        }
      }
    }
  }
}

// Styles for news search with no filter
// Input button is displayed next to search box
.cb-news-full-search {
  // Text input field
  // stylelint-disable selector-class-pattern
  .views-widget-filter-search_api_views_fulltext {
    &:first-child:nth-last-child(2) {
      width: 100%;
      font-size: 1.2em;
      @include media(bp(s)) {
        margin: 0;
        float: left;
        display: inline-block;
        width: 77%;
        input {
          border-right: none;
        }
      }
      .form-item {
        &::after {
          display: none;
        }
      }
    }
  }
  // stylelint-enable selector-class-pattern
  .views-submit-button {
    &:last-child:nth-child(2) {
      width: 100%;
      @include media(bp(s)) {
        margin: 0;
        float: left;
        display: inline-block;
        @include span-columns(3);
        input {
          border-radius: 0 4px 4px 0;
        }
      }
      &:hover {
        box-shadow: none;
      }
    }
  }
}
