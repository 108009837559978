// Newsroom search page
.cb-list-news--search {
  .cb-list-news {
    &__empty-search {
      margin: 2em 0;
    }
  }
  // News listing
  .cb-list-news--regular-stories {
    ul:not(.pager) {
      border-bottom: $border--m;
      .cb-layout__component-dynamic {
        // fix hidden focus outlines
        overflow: inherit;
      }
      li {
        border-width: 2px;
      }
    }
  }
  .cb-content-area__main {
    // Necessary since Browse By Topics is positioned absolute
    position: relative;
  }
  // Button can be inline with title.
  &.cb-list-news--title-buttons.cb-list-news--with-topics {
    .cb-news-browse-topics--compact {
      .cb-panel-container {
        @include media(bp(m)) {
          margin-top: calc(15px - 1.8em);
          margin-bottom: 1.5rem;
        }
      }
      button {
        right: 0;
      }
    }
  }
  // Button cannot be inline with title.
  &.cb-list-news--with-topics:not(.cb-list-news--title-buttons) {
    .cb-list-news__menu {
      padding-top: 0;
    }
    .cb-news-browse-topics--compact {
      @include media(bp(m)) {
        margin-bottom: 1.5rem;
      }
      .cb-panel-container {
        @include media(bp(m)) {
          margin-top: 0;
          border-radius: 0 0 4px 4px;
        }
        .cb-indicator {
          display: none;
        }
      }
      button {
        @include media(bp(m)) {
          width: 100%;
          position: relative;
          &.show-hide-controller--open {
            border-radius: 4px 4px 0 0;
          }
        }
      }
    }
  }
  // fix long page title overlap with topics button on mobile
  &.cb-list-news--with-topics {
    .cb-news-full-search {
      @include media(bp(m)) {
        margin-top: 2em;
      }
      @include media(bp(xl)) {
        margin-top: 0;
      }
    }
  }
}
