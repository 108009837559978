.cb-list-cups__profiles {
  font-family: $font--display;
  .views-row-odd,
  .views-row-even {
    padding: 5% 5% 3% 5%;
    background: $background-solid-xlight;
    margin: 1em auto;
    position: relative;
    width: 100%;
    @include clearfix();
    @include media(bp(s)) {
      background: none;
      padding: 4em 0 3.5em 0;
      border-bottom: $border--s;
      margin: 0;
    }
    &.views-row-first {
      @include media(bp(s)) {
        padding-top: 0;
      }
    }
  }
  h3 {
    font-size: 1.1em;
  }
  .feature {
    margin-bottom: 1em;
    float: left;
    display: block;
    margin-right: 0;
    width: 100%;
    h2 {
      text-align: center;
      @include media(bp(s)) {
        text-align: left;
      }
      a {
        color: a11yrize(color(action, m), $background-solid-xlight);
        text-decoration: none;
        @include media(bp(s)) {
          color: color(action, m);
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .field-name-field-cups-new-patients,
  .field-name-field-cups-treats-children {
    .icon {
      &--not-accept {
        @include icon('fail');
      }
      &--check,
      &--not-accept {
        font-family: $font--display;
        &::before {
          font-family: "#{$icon-font-family}";
          margin-right: 0.5em;
          display: inline-block;
          vertical-align: bottom;
        }
      }
    }
  }
  .field-name-field-cups-virtual-urgent-care,
  .field-name-field-cups-virtual-visits {
    .icon--video {
      font-family: $font--display;
      &::before {
        font-family: "#{$icon-font-family}";
        margin-right: 0.5em;
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }
  .field-name-field-cups-new-patients,
  .field-name-field-cups-virtual-visits:nth-child(2),
  .field-name-field-cups-treats-children:nth-child(2),
  .field-name-field-cups-virtual-urgent-care:nth-child(2) {
    margin-top: 1.2em;
  }
  .field-name-field-cups-new-patients,
  .field-name-field-cups-virtual-visits,
  .field-name-field-cups-treats-children,
  .field-name-field-cups-virtual-urgent-care {
    &:first-child {
      margin-top: 0;
    }
    + .field-name-field-cups-new-patients,
    + .field-name-field-cups-virtual-visits,
    + .field-name-field-cups-treats-children,
    + .field-name-field-cups-virtual-urgent-care {
      margin-top: 0.25em;
    }
  }
  .col {
    margin-bottom: 0.5em;
    padding: 0.5em 0;
  }
  .col-first {
    text-align: center;
    @include media(bp(s)) {
      @include span-columns(5) {
        text-align: left;
      }
    }
    @include media(bp(l)) {
      @include span-columns(3);
    }
    img {
      width: 150px;
      height: auto;
      max-width: 100%;
      @include media(bp(s)) {
        width: 90%;
        max-height: 100%;
      }
    }
  }
  .col-second {
    @include media(bp(s)) {
      @include span-columns(7) {
        @include omega();
      }
    }
    @include media(bp(l)) {
      @include span-columns(5);
    }
    .pane-entity-field {
      &:not(:first-child) {
        margin-top: 1.5em;
      }
    }
  }
  .col-third {
    @include media(bp(s)) {
      @include span-columns(7) {
        float: right;
        @include omega();
      }
    }
    @include media(bp(l)) {
      @include span-columns(4) {
        display: flex;
        flex-direction: column;
        padding: 0;
      }
    }
    > .panel-pane {
      @include media(bp(l)) {
        padding-left: 1.2em;
        &:first-child {
          padding-top: 0.5em;
        }
        &:last-child {
          padding-bottom: 0.5em;
        }
        &:first-child:not(.pane-custom) {
          flex-grow: 0;
        }
        + .panel-pane {
          flex-grow: 1;
        }
      }
      &:not(:first-child):not(.pane-custom) {
        padding-top: 1.5em;
      }
    }
    .pane-custom {
      margin-top: 1em;
      @include media(bp(s)) {
        margin: 0;
      }
    }
    a {
      &:not(.phone-link) {
        @include btn($size: s);
        @include override-nested() {
          width: 100%;
          text-align: center;
        }
        @include media(bp(s)) {
          width: auto;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        @include media(bp(l)) {
          @include span-columns(4);
        }
      }
      &.phone-link {
        color: a11yrize(color(action, m), $background-solid-xlight);
        text-decoration: none;
        @include media(bp(s)) {
          color: color(action, m);
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .field-name-field-cups-departments-divisions,
  .field-name-field-cups-specializes-in,
  .field-name-field-cups-board,
  .field-name-field-cups-research-interests,
  .field-name-field-cups-academic-titles .cups-profile-list li {
    line-height: 1.4;
  }
  .node-cups-profile {
    @include media(bp(l)) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @include clearfix();
    }
    .col-third {
      > .panel-pane {
        @include media(bp(l)) {
          border-left: $border--xs;
        }
      }
      .pane-custom {
        @include media(bp(l)) {
          &:only-child {
            border: none;
          }
        }
      }
    }
  }
  .pane-node-field-cups-appt-phone,
  .pane-node-field-cups-appt-phone-new,
  .pane-node-field-cups-appt-phone-exs {
    &:not(:last-child) {
      margin-bottom: $spacing--s;
    }
  }
}

// No search results text field
.cb-list-cups__empty-search {
  margin-top: 3em;
}
