// Page breadcrumb

.pane-page-breadcrumb {
  .breadcrumb {
    height: $touch-size-min;
    padding-top: 0.3rem;
    background: $background-solid-xlight;
    font-size: 1em;
    font-family: $font--text--alt;
    color: color(neutral-brand, m);
    @include media(bp(xl)) {
      height: auto;
      background: transparent;
    }
    &__separator {
      display: none;
    }
    ol, ul {
      list-style-type: none;
      margin: 0;
    }
    ul {
      padding: 0;
    }
    ol {
      display: flex;
      padding: 0 $component-spacing;
      @include media(bp(m)) {
        padding-left: $page-margins;
        padding-right: $page-margins;
      }
      @include media(bp(xl)) {
        display: block;
        padding: 0;
      }
    }
    a {
      color: a11yrize(color(action, m), $background-solid-xlight);
      font-weight: 400;
      font-size: 0.85em;
      margin-right: 1px;
      @include media(bp(xl)) {
        padding-right: $spacing--s;
        color: color(action, m);
        font-size: 0.9em;
        margin-left: 1px;
      }
    }
    // add standardized arrow icon as breadcrumb separator
    // display main section breadcrumb link only on mobile (i.e. the breadcrumb after home)
    li {
      margin-bottom: 0;
      // fix focus outlines
      padding: 1px;
      @include visually-hidden();
      @include media(bp(xl)) {
        @include visually-hidden--off();
        @include ellipsis();
      }
      &:not(:last-child) {
        @include media(bp(xl)) {
          margin-right: $spacing--xs;
          padding-right: 11px;
          position: relative;
          @include icon($icon: "caret-right", $pseudo: "after", $size: 0.6em) {
            position: absolute;
            right: 0;
            line-height: 1.5;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &:nth-child(2) {
          display: inline-block;
          @include visually-hidden--off();
          @include icon($icon: "caret-left", $pseudo: "before", $size: 0.6em) {
            padding-right: $spacing--xs;
            @include media(bp(xl)) {
              display: none;
            }
          }
          @include media(bp(xl)) {
            position: relative;
            overflow: hidden;
          }
          @include media(max-width (bp(xl) - 0.001em)) {
            @include ellipsis();
          }
        }
      }
      &:last-child:not(:only-child) {
        font-size: 0.85em;
        @include media(bp(xl)) {
          font-size: 0.9em;
          line-height: 2;
        }
      }
    }
  }
}
