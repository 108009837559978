// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing:not(.cb-list-news) {
  .cb-container:not(.cb-feature-primary) {
    .cb-content-area__feature-rows {
      .region.cb-overlap {
        &--top,
        &--top-and-bottom {
          .panels-ipe-portlet-static {
            margin-top: 1em;
            margin-bottom: 0;
          }
          .cps-region-inner {
            margin-top: 0;
          }
        }
      }
    }
  }
}
