// Base alphabetical listing styles:
// News Topics List, Services Search List

.cb-news-browse-topics--full,
.cb-list-conditions-treatments--standard .pane-views-row.classy-panel-styles,
.cb-list-conditions-treatments--standard .panels-ipe-views-row .pane-views-row,
.cb-list-services--standard .pane-views-row.classy-panel-styles,
.cb-list-services--standard .panels-ipe-views-row .pane-views-row {
  > * {
    padding: 0;
    list-style: none;
    margin: $spacing--m auto $spacing--xxl auto;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: $spacing--xxl;
      border-bottom: $border--m;
    }
  }
}

// A-Z letter headings
.cb-news-browse-topics--full > div,
.cb-list-conditions-treatments--standard .pane-views-row.classy-panel-styles,
.cb-list-conditions-treatments--standard .panels-ipe-views-row .pane-views-row,
.cb-list-services--standard .pane-views-row.classy-panel-styles,
.cb-list-services--standard .panels-ipe-views-row .pane-views-row {
  > h2 {
    background: $background-solid-light;
    padding-left: 0.25em;
    margin: 0;
    color: color(neutral-brand, lo);
    border: 4px solid color(neutral-brand, xxhi);
    border-width: 4px 0 0 0;
  }
}

// Alphabetical lists with links only
.cb-list-conditions-treatments__results,
.cb-news-browse-topics--full > div {
  > ul {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    > li {
      line-height: 1.3;
      margin-bottom: 1.2em;
      a {
        font-weight: 700;
      }
    }
    ul {
      a {
        font-weight: 400;
      }
    }
    > * {
      font-size: 1.1rem;
    }
  }
}
