// Styles for the latest news mixed listing and the In-the-media only listing.
.cb-list-news--main {
  .cb-feature-row-secondary {
    .cb-list-news--regular-stories {
      h2 {
        @include visually-hidden();
      }
      ul:not(.panels-ipe-linkbar) {
        li {
          &:only-child {
            width: 100%;
            // stylelint-disable selector-max-compound-selectors
            h3 {
              font-size: 1.3rem;
            }
            // stylelint-enable selector-max-compound-selectors
          }
        }
      }
      .more-link {
        margin-bottom: 1.4em;
        @include media(bp(s)) {
          margin-bottom: 0;
        }
      }
    }
  }
  .cb-list-news--regular-stories,
  .cb-list-news--in-the-media {
    clear: both;
    margin-top: 3em;
    padding-top: 1.7em;
    position: relative;
    @include media(bp(m)) {
      padding-top: 2em
    }
    .column-first {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }
    .columns-2 {
      width: 100%;
    }
    ul:not(.panels-ipe-linkbar) {
      list-style-type: none;
      padding: 0;
      margin: 0;
      @include media(bp(m)) {
        margin-top: -0.5em;
      }
      li {
        padding-top: 0.8rem;
        margin-bottom: 0.85rem;
        border-top: solid 2px;
        border-color: $border-xlight;
        @include media(bp(m)) {
          @include span-columns(6);
          &.views-row-first {
            padding-top: 0;
            + .views-row-even {
              padding-top: 0;
            }
          }
          &.views-row-even {
            margin-right: 0;
          }
        }
        &.views-row-first {
          border-top: none;
          padding-top: 0;
          + .views-row-even {
            @include media(bp(m)) {
              border-top: none;
            }
          }
        }
        &:nth-child(3):last-child {
          @include media(bp(m)) {
            clear: left;
          }
        }
      }
      .cb-layout__component-dynamic {
        // fix hidden focus outlines
        overflow: inherit;
      }
    }
    .cb-list-news {
      &__best-category {
        background-color: transparent;
        font-size: 0.75rem;
        text-transform: uppercase;
        margin-bottom: 0.2em;
        font-weight: 400;
        color: color(neutral-brand, m);
        order: 1;
        position: static;
        a {
          font-weight: 400;
          color: $text-link-color;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &__title {
        order: 2;
        h3 {
          font-size: 1rem;
          line-height: 1.4;
          @include media(bp(m)) {
            font-size: 1rem;
          }
          &::before {
            display: none;
          }
          a {
            font-weight: 600;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &__date {
        order: 4;
        font-size: 0.7rem;
        &::before {
          display: none;
        }
      }
      &__external-source {
        order: 3;
      }
      &__summary {
        display: none;
      }
      &__byline {
        display: none;
      }
      &__content {
        border-bottom: $border--s;
        > .view-content {
          @include clearfix();
        }
      }
    }
  }
  .cb-content-area__feature-rows {
    .cb-list-news--regular-stories {
      &:only-child,
      &.panels-ipe-portlet-wrapper:first-child:nth-last-child(2),
      &.panels-ipe-portlet-wrapper:nth-child(2):last-child {
        width: 96.6%;
        .more-link {
          border-top: $border--s;
          padding-top: 1em;
          text-align: center;
          margin-top: 1em;
          a {
            @include more-link($margin-left: 0);
            @include media(bp(s)) {
              @include component-link-title(m);
            }
          }
        }
        .cb-list-news__content {
          border-bottom: none;
        }
      }
    }
    .cb-list-news--in-the-media {
      h2.pane-title {
        padding: 0 0 $spacing--s 0;
        border-bottom: $border--m;
        border-color: $heading-border-color;
      }
      &:only-child,
      &.panels-ipe-portlet-wrapper:first-child:nth-last-child(2),
      &.panels-ipe-portlet-wrapper:nth-child(2):last-child {
        border: none;
        ul:not(.panels-ipe-linkbar) {
          margin-top: 0;
        }
        .more-link {
          text-align: center;
          clear: both;
          width: 100%;
          margin: 1em auto;
          a {
            text-decoration: none;
            @include media(bp(s)) {
              font-size: 1.25rem;
              line-height: 1.25;
              font-weight: 600;
            }
          }
        }
        .cb-list-news__content {
          border-bottom: none;
          .view-content {
            border-bottom: $border--s;
          }
        }
      }
      &.panels-ipe-portlet-wrapper:nth-child(2):last-child {
        @include media(bp(l)) {
          border-top: none;
        }
      }
    }
    .cb-list-news--in-the-media,
    .cb-list-events {
      &:not(:only-child) {
        .more-link {
          margin: 0;
        }
      }
    }
  }
}

// Styles when another component (e.g. events) is dropped into the same region.
.cb-list-news--main {
  .cb-feature-row-secondary {
    .cb-list-news--regular-stories {
      &:not(:only-child):not(:first-child):not(.panels-ipe-portlet-wrapper),
      &.panels-ipe-portlet-wrapper:nth-child(2):not(:last-child),
      &.panels-ipe-portlet-wrapper:nth-child(n+3) {
        h2 {
          @include visually-hidden--off();
        }
      }
      &:first-child:not(:only-child):not(.panels-ipe-portlet-wrapper),
      &.panels-ipe-portlet-wrapper:first-child:nth-last-child(n+3) {
        border-top: $border--s;
        padding-top: 0.85em;
        @include media(bp(m)) {
          border-top: none;
          padding-top: 0;
          h2 {
            @include visually-hidden--off();
          }
        }
      }
    }
  }
  .cb-list-news--in-the-media {
    &:not(:only-child):not(.panels-ipe-portlet-wrapper),
    &.panels-ipe-portlet-wrapper:first-child:nth-last-child(n+3),
    &.panels-ipe-portlet-wrapper:nth-child(2):not(:last-child),
    &.panels-ipe-portlet-wrapper:nth-child(n+3){
      ul:not(.panels-ipe-linkbar) {
        li {
          border: none;
        }
      }
      // Overriding default top margin.
      &:first-child {
        margin-top: 2em;
      }
    }
  }
  // Classy panels overrides.
  .region.cb-max-columns--3,
  .region.cb-max-columns--4 {
    .cb-list-news--regular-stories,
    .cb-list-news--in-the-media {
      &:not(:only-child):not(.panels-ipe-portlet-wrapper),
      &.panels-ipe-portlet-wrapper:first-child:nth-last-child(n+3),
      &.panels-ipe-portlet-wrapper:nth-child(2):not(:last-child),
      &.panels-ipe-portlet-wrapper:nth-child(n+3) {
        @include media(bp(m)) {
          width: 46.6%;
          .lt-ie10 & {
            width: 46.6%;
            float: left;
          }
        }
      }
    }
  }
  .cb-list-news--regular-stories,
  .cb-list-news--in-the-media {
    &:not(:only-child):not(.panels-ipe-portlet-wrapper),
    &.panels-ipe-portlet-wrapper:first-child:nth-last-child(n+3),
    &.panels-ipe-portlet-wrapper:nth-child(2):not(:last-child),
    &.panels-ipe-portlet-wrapper:nth-child(n+3) {
      border: none;
      padding-top: 0;
      clear: none;
      display: flex;
      flex-direction: column;
      @include media(bp(m)) {
        width: 46.6%;
        margin-top: 2em;
        .lt-ie-10 & {
          width: 46.6%;
          float: left;
        }
        .cb-list-news__content {
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
          > .view-content {
            flex: 1 0 auto;
          }
        }
      }
      ul:not(.panels-ipe-linkbar) {
        margin-top: 0;
        li {
          width: 100%;
          float: none;
          @include media(bp(m)) {
            border: none;
          }
          &.views-row-first,
          &.views-row-first + .views-row-even {
            padding-top: 0.8rem;
          }
        }
      }
    }
  }
  .cb-list-news--regular-stories {
    &:not(:only-child):not(.panels-ipe-portlet-wrapper),
    &.panels-ipe-portlet-wrapper:first-child:nth-last-child(n+3),
    &.panels-ipe-portlet-wrapper:nth-child(2):not(:last-child),
    &.panels-ipe-portlet-wrapper:nth-child(n+3) {
      .more-link {
        text-align: left;
        a {
          @include btn($style: solid, $size: s);
          @include override-nested() {
            font-size: 1.1rem;
          }
          @include media(bp(m)) {
            border: none;
            padding: 0;
            color: color(action, m);
            padding-top: 0.875em;
            font-weight: 700;
          }
          &:hover,
          &:focus,
          &:active {
            @include media(bp(m)) {
              background: transparent;
              color: color(action, m);
            }
          }
        }
      }
    }
  }
  .cb-list-news--in-the-media {
    .cb-list-news__title {
      h3 {
        line-height: 1.25;
      }
    }
    .more-link {
      border: none;
      a {
        border: none;
        padding: 0;
        color: color(action, m);
        @include icon($icon: "caret-right", $pseudo: "after", $size: 0.8em);
        @media (hover: hover) {
          &:hover::after {
            @include animated($duration: 1s, $name: wobble);
          }
        }
        &:hover,
        &:focus,
        &:active {
          @include media(bp(m)) {
            background: transparent;
            color: color(action, m);
          }
        }
      }
    }
    &:not(:only-child):not(.panels-ipe-portlet-wrapper),
    &.panels-ipe-portlet-wrapper:first-child:nth-last-child(n+3),
    &.panels-ipe-portlet-wrapper:nth-child(2):not(:last-child),
    &.panels-ipe-portlet-wrapper:nth-child(n+3) {
      @include media(bp(m)) {
        margin-bottom: 2.5em;
      }
      .more-link {
        text-align: left;
        margin-top: $spacing--m;
        margin-bottom: $spacing--l;
        a {
          font-size: 1.1rem;
        }
      }
    }
    &:not(:only-child):last-child {
      margin-bottom: 2.5em;;
    }
  }
  .cb-list-news--regular-stories,
  .cb-list-news--in-the-media {
    &:not(:only-child):not(.panels-ipe-portlet-wrapper) {
      &:nth-child(2n+2) {
        @include media(bp(m)) {
          margin-left: 0;
          padding-left: 3%;
          padding-top: 0;
          border: none;
        }
      }
      &:nth-child(2n+1) {
        @include media(bp(m)) {
          margin-right: 0;
          padding-right: 3%;
          border-right: $border--s;
        }
      }
    }
  }
}

// Fix IPE issues
.panels-ipe-editing {
  .region.cb-padding {
    .cb-list-news--regular-stories,
    .cb-list-news--in-the-media {
      + .cb-ipe-empty {
        width: 46.6%;
      }
    }
  }
}
