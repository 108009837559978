// Google custom search

// overrides
// stylelint-disable selector-class-pattern
.cb-gcse-container {
  .gsc-tabsArea {
    margin-top: $spacing--xxl;
  }
  .gsc-results {
    .gsc-webResult.gsc-result {
      &:first-child {
        border-top: none;
      }
    }
    // pagination styles
    .gsc-cursor-box {
      .gsc-cursor-page {
        &:hover {
          // Override gcse base styles.
          // stylelint-disable declaration-no-important
          color: color(neutral-brand, xxlo) !important;
          // stylelint-enable declaration-no-important
        }
      }
    }
  }
  table {
    box-shadow: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin: auto;
    tbody {
      &:first-child {
        td {
          border: none;
        }
      }
    }
    &.gsc-search-box {
      td {
        padding: 0;
      }
    }
  }
  td {
    border-right: none;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
  form {
    .gsc-input {
      // Clear button with "x" icon.
      a {
        margin: 0.4rem 0.5rem 0.5rem 0.5rem;
        padding: 0.15rem 0.4rem 0.2rem 0.4rem;
      }
    }
    table.gsc-search-box button {
      position: relative;
      right: 0;
      background: $btn-bg-color-interior-pg-component;
      @include icon($icon: "search", $color: #fff) {
        position: absolute;
        font-size: 1.4em;
        top: 0.5em;
        @include media(bp(s)) {
          left: 40%;
          top: 0.6em;
        }
      }
      svg {
        @include visually-hidden();
      }
      &:hover {
        background: $btn-hover-bg-color-interior-pg-component;
      }
    }
  }
}

// search result typography styles
.cb-gcse-container .gsc-control-cse {
  .gs-visibleUrl,
  .gsc-table-result,
  .gs-spelling {
    font-family: $font--text--alt;
  }
  .gs-spelling {
    padding: 0 0 0.4em 0;
    font-size: 1.15em;
    color: color(neutral-brand, xxlo);
    a {
      background: none;
      text-decoration: none;
      b {
        font-weight: 600;
      }
      i {
        font-style: normal;
      }
    }
    &.gs-spelling-original {
      font-size: 0.9em;
    }
  }
  .gsc-webResult.gsc-result {
    .gs-title {
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// stylelint-enable selector-class-pattern
