// Base Video Styles

// Remove default desktop side paddings for full width videos
.region.cb-padding--none {
  .panels-ipe-video.pane:only-child,
  .pane-video.pane:only-child,
  .cb-feature-primary .pane-node-field-news-video {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

// Featured layout and main content style fixes
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--aside-last .cb-content-area__feature-rows,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--aside-first .cb-content-area__feature-rows,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--aside-first-last .cb-content-area__feature-rows,
.cb-layout--aside-last .cb-content-area__main,
.cb-layout--aside-first .cb-content-area__main,
.cb-layout--aside-first-last .cb-content-area__main {
  .panels-ipe-video,
  .pane-video {
    @include media(bp(m)) {
      .field-name-field-title {
        margin: 0 auto;
        max-width: 30em;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
    @include media(bp(xl)) {
      .field-name-field-title {
        @include component-heading(m);
      }
      .field-name-field-video-image {
        a {
          &::before {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}
