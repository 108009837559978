// Appointments with find a doc link

.cb-appointments.cw-with-doctors-link {
  position: relative;
  .cw-appointments__overlay-link {
    z-index: z(content);
    text-indent: -999em;
    background: lighten(color(secondary, xhi), 16%);
    &:hover {
      background: lighten(color(secondary, xhi), 16%);
    }
  }
  .cw-appointments__overlay-link,
  .cb-appointments__link--doctors {
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top: auto;
  }
  .cb-appointments {
    &__title,
    &__content {
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
    &__title {
      margin-bottom: $spacing--s;
      text-align: left;
    }
    &__link {
      &--doctors {
        z-index: z(contentBottom);
        display: flex;
        justify-content: center;
        a:not(.cw-appointments__overlay-link) {
          @include btn($size: s);
          @include override-nested() {
            z-index: z(contentTop);
            position: relative;
            display: flex;
            width: 100%;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            padding: 0.5rem 0.8rem;
            background: $component-bg-2-color;
            color: #fff;
            font-size: 1.2rem;
            text-decoration: none;
          }
          @include icon($icon: 'doctors', $size: 2.2rem, $color: white) {
            margin-right: 0.4em;
          }
          &:hover {
            color: #fff;
            background: $component-bg-2-color;
          }
        }
      }
      &--telehealth,
      &--phone,
      &--info,
      &--book-online {
        width: 100%;
        a {
          font-size: 1rem;
          text-align: left;
        }
      }
      @include quantities-all(2) {
        &.cb-appointments__link--phone {
          // fix phone link to not wrap in Safari
          @include media( max-width (bp(s) - 0.06em) ) {
            a {
              display: block;
              &::before {
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}

// if find a doc link is not an only-child
.cb-appointments.cw-with-doctors-link {
  &.cw-with-title,
  &.cw-with-description,
  &.cw-with-phone-link,
  &.cw-with-book-online-link,
  &.cw-with-telehealth-link,
  &.cw-with-info-link {
    padding-bottom: 90px;
    @include media(bp(s)) {
      min-height: 150px;
      padding-bottom: $spacing--m;
    }
    .cw-appointments__overlay-link,
    .cb-appointments__link--doctors {
      @include media(bp(s)) {
        bottom: auto;
        left: auto;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
    .cw-appointments__overlay-link {
      @include media(bp(s)) {
        width: 100%;
        background: $component-bg-2-color;
        &:hover {
          background: $component-bg-2-color;
        }
      }
    }
    .cb-appointments {
      &__title,
      &__content {
        @include media(bp(s)) {
          width: 63%;
        }
      }
      &__links {
        @include media(bp(s)) {
          flex-direction: column;
        }
      }
      &__link {
        &--doctors {
          @include media(bp(s)) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35%;
            padding-top: 3em;
            a:not(.cw-appointments__overlay-link) {
              @include btn($style: outline, $size: s, $dark: true);
              @include override-nested() {
                display: flex;
                flex-direction: column;
                width: 150px;
                margin-left: 1.3rem;
                margin-right: 1.3rem;
                padding: 0 0.5em;
                font-size: 1rem;
                text-align: center;
              }
              &::before {
                margin: -1.1em 0 0.3em 0;
                font-size: 3.5rem;
              }
            }
          }
          @include media(bp(l)) {
            a:not(.cw-appointments__overlay-link) {
              height: 44px;
            }
          }
        }
        @include quantities-all(2) {
          &:first-child {
            margin-bottom: 0;
            a {
              align-items: center;
              padding: 0 0 0 1.6em;
              height: $touch-size-min;
              background: transparent;
              color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
              text-decoration: underline;
              @include component-link-title(s);
              &:hover {
                background: transparent;
                text-decoration: none;
              }
              &::before {
                @include component-link-title(s);
                margin-left: -1.5em;
                height: 1.4em;
                line-height: 1.4;
              }
              @include media(bp(bs)) {
                @include component-link-title(m);
                &::before {
                  @include component-link-title(m);
                  line-height: 1.4;
                }
              }
              @include media(bp(s)) {
                font-size: 1.2rem;
                margin-top: 0.9rem;
                margin-bottom: 1rem;
                &::before {
                  font-size: 1.2rem;
                  top: 0.3em;
                }
              }
              @include media(bp(m)) {
                @include component-link-title(l);
                &::before {
                  @include component-link-title(l);
                }
              }
            }
          }
        }
      }
    }
  }
}

// layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-last,
  .cb-layout--aside-first,
  .cb-layout--aside-first-last {
    .region,
    .cb-content-area__main {
      .cb-appointments.cw-with-doctors-link {
        // if find a doc link is not an only-child
        &.cw-with-title,
        &.cw-with-description,
        &.cw-with-phone-link,
        &.cw-with-book-online-link,
        &.cw-with-telehealth-link,
        &.cw-with-info-link {
          .cb-appointments {
            @include media(bp(xl)) {
              &__link--doctors {
                width: 22%;
              }
              &__title,
              &__content {
                width: 78%;
              }
            }
          }
        }
        .cb-appointments {
          &__link {
            @include quantities-all(3,5) {
              &:not(.cb-appointments__link--doctors) {
                @include media(bp(xl)) {
                  a {
                    font-size: 0.9rem;
                  }
                }
              }
            }
            @include quantities-all(4) {
              &:not(.cb-appointments__link--doctors) {
                @include media(bp(xl)) {
                  width: 100%;
                  float: none;
                  margin-right: 0;
                  a {
                    font-size: 1rem;
                  }
                }
              }
              &.cb-appointments__link--doctors {
                @include media(bp(xl)) {
                  a {
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// IPE
.panels-ipe-editing {
  .cb-appointments.cw-with-doctors-link {
    position: unset;
    margin-left: 0;
    margin-right: 0;
    .pane-bundle-appointments {
      position: relative;
    }
    .panels-ipe-handlebar-wrapper {
      width: 100%;
      li {
        a {
          padding: 4px 5px;
        }
      }
    }
  }
}
