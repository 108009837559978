@mixin btn($style: solid, $size: m, $dark: false) {
  display: inline-block;
  font-family: $font--text--alt;
  font-weight: 600;
  text-decoration: none;
  border-radius: 4px;
  @if $size == l {
    font-size: 1.125rem;
    padding: 0.9em 1.4em 0.956em 1.4em;
  } @else if $size == xs {
    font-size: 1rem;
    padding: 0.38em 1.2em 0.42em 1.2em;
  } @else if $size == s {
    font-size: 1rem;
    padding: 0.6em 1.2em 0.6625em 1.2em;
  } @else if $size == m {
    font-size: 1.125rem;
    padding: 0.65em 1.4em 0.706em 1.4em;
  }
  @if $style == solid {
    @if $dark {
      background: $btn-dark-bg-color;
      color: $btn-dark-font-color;
      box-shadow: 0 1px 2px $btn-dark-box-shadow-color;
      border-bottom: solid 1px $btn-dark-border-color;
    } @else {
      background: $btn-bg-color;
      color: $btn-font-color;
      border-bottom: solid 1px $btn-border-color;
      box-shadow: $box-shadow;
    }
    &:hover, &:focus {
      text-decoration: none;
      @if $dark {
        background: $btn-dark-hover-bg-color;
        color: $btn-dark-hover-font-color;
        box-shadow: 0 3px 8px $btn-dark-hover-box-shadow-color;
        outline-color: #fff;
      } @else {
        background: $btn-hover-bg-color;
        color: $btn-hover-font-color;
        outline-color: $btn-bg-color;
        box-shadow: 0 6px 9px $btn-hover-box-shadow-color;
      }
    }
  }
  @if $style == outline {
    transition-duration: 0.4s;
    @if $dark {
      background: transparent;
      color: $btn-style-outline-dark-color;
      box-shadow: none;
      border: 1px solid $btn-style-outline-dark-color;
    } @else {
      background: #fff;
      color: $btn-style-outline-font-color;
      border: 1px solid $btn-style-outline-color;
      box-shadow: none;
    }
    &:hover, &:focus {
      text-decoration: none;
      @if $dark {
        background: $btn-style-outline-dark-color;
        color: $btn-style-outline-dark-hover-font-color;
        box-shadow: none;
        outline-color: #fff;
      } @else {
        background: $btn-style-outline-color;
        color: $btn-style-outline-hover-font-color;
        outline-color: $btn-style-outline-color;
        box-shadow: none;
      }
    }
  }
}

// stylelint-disable selector-class-pattern
.btn,
.cb-button {
  @include btn();
  &--small {
    @include btn($size: s);
  }
  &--large {
    @include btn($size: l);
  }
  &--dark {
    @include btn($size: false, $dark: true);
  }
  &--alt {
    @include btn($size: false);
  }
  &--dark.cb-button--alt {
    @include btn($size: false, $dark: true);
  }
  &--style-outline {
    @include btn($style: outline);
  }
  &--small--style-outline {
    @include btn($style: outline, $size: s);
  }
  &--large--style-outline {
    @include btn($style: outline, $size: l);
  }
  &--dark--style-outline {
    @include btn($style: outline, $size: false, $dark: true);
  }
  &--alt--style-outline {
    @include btn($style: outline, $size: false);
  }
  &--dark--style-outline.cb-button--alt--style-outline {
    @include btn($style: outline, $size: false, $dark: true);
  }
  .cb-content-area__main .pane-node-body & {
    margin: 0.28em 0.5em 0.28em 0;
  }
}
// stylelint-enable selector-class-pattern

// Wysiwyg button alignment options.
.ck-cta {
  margin: $spacing--m 0;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &--float:not(.ck-cta--center) {
    max-width: 50%;
    &.ck-cta--left {
      float: left;
      margin-right: $spacing--m;
    }
    &.ck-cta--right {
      float: right;
      margin-left: $spacing--m;
    }
  }
  &:not(.ck-cta--float) {
    &.ck-cta--right {
      text-align: right;
    }
    &.ck-cta--center {
      text-align: center;
    }
  }
  &__link {
    .cb-content-area__main .pane-node-body & {
      margin: 0;
    }
  }
}

// stylelint-disable QualifyingElement
input[type="submit"],
.form-submit {
  @include btn($size: s);
  @include override-nested() {
    border: 0;
    line-height: 1.5;
  }
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background: color(neutral-brand, m);
    color: a11yrize(color(neutral-brand, m), color(neutral-brand, m));
    border-bottom-color: color(neutral-brand, xlo);
  }
}

.webform {
  input[type="submit"] {
    font-size: 1.0625em;
    + input[type="submit"] {
      margin-left: 1em;
      float: right;
      @include media(bp(m)) {
        float: none;
      }
    }
  }
}
// stylelint-enable QualifyingElement

button {
  @include btn();
  @include override-nested() {
    border: 0;
  }
}
