// Appointments with find a doc link Background Styles

.cb-appointments.cw-with-doctors-link {
  background: transparent;
  &.cw-with-title,
  &.cw-with-description,
  &.cw-with-phone-link,
  &.cw-with-book-online-link,
  &.cw-with-telehealth-link,
  &.cw-with-info-link {
    background: lighten(color(secondary, xhi), 16%);
  }
}

// Region padding auto backgrounds

@include component-region-bg-styles(2) {
  &.cb-padding--auto {
    .cb-appointments.cw-with-doctors-link {
      .cb-appointments__link--doctors {
        a {
          background: lighten(color(secondary, xhi), 16%);
          &:not(.cw-appointments__overlay-link) {
            color: color(neutral-brand, xxlo);
            @include media(bp(s)) {
              border-color: color(neutral-brand, xxlo);
            }
            &::before {
              color: color(neutral-brand, xxlo);
            }
            &:hover,
            &:focus {
              @include media(bp(s)) {
                background: #fff;
                color: color(neutral-brand, xxlo);
              }
            }
          }
          &.cw-appointments__overlay-link {
            background: transparent;
            @include media(bp(s)) {
              background: lighten(color(secondary, xhi), 16%);
            }
            &:hover {
              @include media(bp(s)) {
                background: lighten(color(secondary, xhi), 16%);
              }
            }
          }
        }
      }
      // if find a doc is the only link displayed
      &:not(.cw-with-book-online-link):not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-title):not(.cw-with-description) {
        .cw-appointments__overlay-link {
          background: lighten(color(secondary, xhi), 16%);
        }
        a:not(.cw-appointments__overlay-link) {
          border-color: color(neutral-brand, xxlo);
          color: color(neutral-brand, xxlo);
          &:hover {
            background: #fff;
          }
        }
      }
    }
  }
}
@include component-region-bg-styles(1,3) {
  &.cb-padding--auto {
    .cb-appointments.cw-with-doctors-link {
      .cb-appointments__link--doctors {
        a {
          color: #fff;
          background: color(primary, brand);
          &:not(.cw-appointments__overlay-link) {
            &:hover,
            &:focus {
              @include media(bp(s)) {
                background: #fff;
                color: color(neutral-brand, xxlo);
              }
            }
          }
          &.cw-appointments__overlay-link {
            background: transparent;
            @include media(bp(s)) {
              background: color(primary, brand);
            }
            &:hover {
              @include media(bp(s)) {
                background: color(primary, brand);
              }
            }
          }
        }
      }
    }
  }
}
@include component-region-bg-styles(2,3) {
  &.cb-padding--auto {
    .cb-appointments.cw-with-doctors-link {
      .cb-appointments__link {
        &:not(.cb-appointments__link--doctors) {
          a {
            background: $background-solid-light;
            &:hover {
              background: $background-solid-xlight;
            }
          }
        }
      }
    }
  }
}
@include component-region-bg-styles(auto,1,2,3) {
  &.cb-padding--auto {
    .cb-appointments.cw-with-doctors-link {
      // if find a doc is displayed with only one other appointment link
      &.cw-with-phone-link:not(.cw-with-book-online-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-book-online-link:not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-telehealth-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-info-link),
      &.cw-with-info-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link) {
        .cb-appointments__link:not(.cb-appointments__link--doctors) {
          a {
            background: transparent;
            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }
}

// Region padding none backgrounds

@include component-region-bg-styles(1) {
  &.cb-padding--none {
    .cb-appointments.cw-with-doctors-link {
      // if find a doc is the only link displayed
      &:not(.cw-with-book-online-link):not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-title):not(.cw-with-description) {
        .cb-appointments__link--doctors {
          a:not(.cw-appointments__overlay-link) {
            color: color(neutral-brand, xxlo);
            border-color: color(neutral-brand, xxlo);
            &::before {
              color: color(neutral-brand, xxlo);
            }
          }
        }
        .cw-appointments__overlay-link {
          background: transparent;
        }
      }
    }
  }
}
@include component-region-bg-styles(2,3) {
  &.cb-padding--none {
    .cb-appointments.cw-with-doctors-link {
      // if find a doc is the only link displayed
      &:not(.cw-with-book-online-link):not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-title):not(.cw-with-description) {
        .cw-appointments__overlay-link {
          background: transparent;
        }
      }
    }
  }
}
@include component-region-bg-styles(auto,1,2,3) {
  &.cb-padding--none {
    .cb-appointments.cw-with-doctors-link {
      &.cw-with-title,
      &.cw-with-description,
      &.cw-with-phone-link,
      &.cw-with-book-online-link,
      &.cw-with-telehealth-link,
      &.cw-with-info-link {
        background: lighten(color(secondary, xhi), 16%);
      }
      .cb-appointments {
        &__title,
        &__description p {
          color: color(neutral-brand, xxlo);
        }
        &__link {
          &:not(.cb-appointments__link--doctors) {
            a {
              background: #fff;
              color: $text-link-color;
              &:hover {
                background: $background-solid-xlight;
                color: a11yrize($text-link-color, $background-solid-xlight);
              }
            }
          }
          &--doctors {
            a {
              color: #fff;
              &:not(.cw-appointments__overlay-link) {
                &:hover,
                &:focus {
                  background: #fff;
                  color: color(neutral-brand, xxlo);
                }
              }
              &.cw-appointments__overlay-link {
                &:hover {
                  background: color(primary, brand);
                }
              }
            }
            &:not(:only-child) {
              a {
                &:not(.cw-appointments__overlay-link) {
                  &:hover {
                    background: color(primary, brand);
                    color: #fff;
                    /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                    @include media(bp(s)) {
                      background: #fff;
                      color: color(neutral-brand, xxlo);
                    }
                    /* stylelint-enable */
                  }
                }
                &.cw-appointments__overlay-link {
                  @include media(bp(s)) {
                    background: color(primary, brand);
                  }
                  &:hover {
                    background: lighten(color(secondary, xhi), 16%);
                    /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                    @include media(bp(s)) {
                      background: color(primary, brand);
                    }
                    /* stylelint-enable */
                  }
                }
              }
            }
          }
        }
      }
      // if find a doc is displayed with only one other appointment link
      &.cw-with-phone-link:not(.cw-with-book-online-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-book-online-link:not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-telehealth-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-info-link),
      &.cw-with-info-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link) {
        .cb-appointments__link:not(.cb-appointments__link--doctors) {
          a {
            color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
            background: transparent;
            &:hover {
              background: transparent;
            }
          }
        }
      }
      // if find a doc is the only link within appointments component
      .cb-appointments__link--doctors:only-child {
        a {
          &:hover {
            color: #fff;
            background: $component-bg-2-color;
            @include media(bp(s)) {
              color: color(neutral-brand, xxlo);
              background: #fff;
            }
          }
          &.cw-appointments__overlay-link {
            &:hover {
              background: transparent;
              @include media(bp(s)) {
                background: $component-bg-2-color;
              }
            }
          }
        }
      }
    }
  }
}
