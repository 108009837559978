.region {
  .cb-appointments {
    padding: $spacing--l;
    @include media(bp(l)) {
      padding: $spacing--xl $spacing--xl $spacing--xxl $spacing--xl;
    }
    &__title,
    &__content {
      width: 100%;
      max-width: $component-content-max-width;
      margin-left: auto;
      margin-right: auto;
    }
    &__title {
      @include media(bp(bs)) {
        @include component-heading(m);
      }
      @include media(bp(m)) {
        @include component-heading(xl);
      }
    }
    &__description {
      p,
      a {
        @include media(bp(m)) {
          @include component-paragraph(m);
        }
      }
    }
  }
  .panels-ipe-editing & {
    .cb-appointments {
      padding: 0;
    }
  }
}

// styles depending on sidebars display
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside {
  .region {
    .cb-appointments:not(.cw-with-doctors-link) {
      // default overrides: 3 appointments links
      &:not(.cw-with-book-online-link) {
        &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
          .cb-appointments__link {
            a {
              @include media(bp(m)) {
                font-size: 0.9em;
              }
              @include media(bp(l)) {
                font-size: 1em;
              }
            }
          }
        }
      }
      &.cw-with-book-online-link {
        &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link) {
          .cb-appointments__link {
            @include quantities-all(3) {
              a {
                @include media(bp(m)) {
                  text-align: left;
                  font-size: 0.85em;
                }
                @include media(bp(l)) {
                  font-size: 0.9em;
                }
                @include media(bp(l)) {
                  font-size: 0.95em;
                }
              }
            }
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first-last,
  .cb-layout--aside-last,
  .cb-layout--aside-first {
    .region {
      @include media(bp(m)) {
        .cb-appointments:not(.cw-with-doctors-link) {
          &.cw-with-description {
            .cb-appointments__link {
              &:only-child {
                a::before {
                  margin-left: -1.5em;
                }
              }
            }
          }
          &:not(.cw-with-description) {
            .cb-appointments__link {
              &:only-child {
                a {
                  padding-left: 2.1em;
                }
              }
            }
          }
        }
      }
      @include media(bp(l)) {
        .cb-appointments:not(.cw-with-doctors-link) {
          // 3 appointments links
          &.cw-with-book-online-link {
            &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
            &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link) {
              .cb-appointments__link {
                @include quantities-all(3) {
                  a {
                    text-align: left;
                  }
                }
              }
            }
            &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
              .cb-appointments__link {
                @include quantities-all(3) {
                  a {
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first {
    .region {
      .cb-appointments:not(.cw-with-doctors-link) {
        @include media(bp(xl)) {
          .cb-appointments {
            &__link {
              @include quantities-all(2,4) {
                a {
                  font-size: 1.1em;
                }
              }
            }
          }
          // 3 appointments links
          &:not(.cw-with-book-online-link) {
            &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
              .cb-appointments__link {
                a {
                  font-size: 1em;
                }
              }
            }
          }
          &.cw-with-book-online-link {
            &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
            &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link) {
              .cb-appointments__link {
                @include quantities-all(3) {
                  a {
                    font-size: 1em;
                  }
                }
              }
            }
            &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
              .cb-appointments__link {
                @include quantities-all(3) {
                  a {
                    font-size: 0.9em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-last {
    .region {
      .cb-appointments:not(.cw-with-doctors-link) {
        @include media(bp(xl)) {
          .cb-appointments {
            &__link {
              @include quantities-all(2,4) {
                a {
                  font-size: 1em;
                }
              }
            }
          }
          // 3 appointments links
          &:not(.cw-with-book-online-link) {
            &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link {
              .cb-appointments__link {
                a {
                  font-size: 1em;
                }
              }
            }
          }
          &.cw-with-book-online-link {
            &.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
            &.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
            &.cw-with-info-link.cw-with-telehealth-link:not(.cw-with-phone-link) {
              .cb-appointments__link {
                @include quantities-all(3) {
                  a {
                    font-size: 0.9em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
