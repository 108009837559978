// Labs and Unit by Type List Component Sidebar Typography

.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.cb-list-units--component,
      &.cb-list-labs--component {
        h2.pane-title {
          @include component-heading(m);
          color: $sidebar-component-title-color;
          margin-top: $spacing--xl;
          margin-bottom: 0;
          @include media(bp(xs)) {
            @include component-heading(l);
            margin-bottom: 0;
          }
          @include media(bp(l)) {
            @include component-heading(xl);
            margin-bottom: 0;
          }
        }
        .cb-list-labs,
        .cb-list-units {
          &__title {
            border-bottom: none;
            background: transparent;
            h3 {
              @include component-link-title(s);
              margin-bottom: 0;
              @include media(bp(s)) {
                @include component-link-title(m);
              }
              @include media(bp(m)) {
                @include component-link-title(l);
              }
            }
          }
          &__summary {
            border-top: $border--xs;
            border-color: $sidebar-component-border-color;
            p {
              @include component-paragraph(xs);
              @include media(bp(m)) {
                @include component-paragraph(s);
              }
            }
          }
        }
        .more-link {
          width: 100%;
          margin-bottom: 0;
          padding-bottom: $spacing--m;
          background: $background-solid-light;
          padding-top: 0.8em;
          a {
            @include component-link-title(s);
            color: a11yrize(color(action, m), $background-solid-light);
            @include media(bp(s)) {
              @include component-link-title(m);
            }
          }
        }
      }
    }
  }
}


// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.cb-list-units--component,
        &.cb-list-labs--component {
          h2.pane-title {
            @include component-heading-right-rail();
            margin-top: $spacing--m;
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .more-link a {
            @include component-link-title-right-rail();
          }
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          .cb-list-labs,
          .cb-list-units {
            &__title {
              h3 {
                font-size: 1rem;
              }
            }
          }
          .column-first {
            .cb-list-labs,
            .cb-list-units {
              &__summary {
                p {
                  @include component-paragraph-right-rail();
                }
              }
            }
            &:not(:only-child) {
              .cb-list-labs,
              .cb-list-units {
                &__title {
                  .field-name-title-field {
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
