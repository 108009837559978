// CTA list styles typography ("not:only child" in a region)

.region {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta {
    &:not(:only-child) {
      .field-name-field-title h2 {
        @include component-heading(xs);
      }
      .field-name-field-cta-description p,
      .field-name-field-cta-description a {
        @include component-paragraph(xs);
      }
      .paragraphs-item-cta {
        &.cw-with-title,
        &.cw-with-description {
          .field-name-field-cta-link a {
            @include component-link-title(xs);
            line-height: 1.3;
          }
        }
        &.cw-with-image,
        &.cw-with-icon {
          .field-name-field-title {
            padding-top: $spacing--m;
          }
          .field-name-field-title,
          .field-name-field-cta-description,
          .field-name-field-cta-link {
            padding-left: $spacing--l;
            padding-right: $spacing--l;
          }
          .field-name-field-cta-description {
            padding-bottom: $spacing--s;
          }
          .field-name-field-cta-link {
            padding-bottom: $spacing--l;
          }
          &.cw-with-description:not(.cw-with-title) {
            .field-name-field-cta-description {
              padding-top: $spacing--m;
            }
          }
          &:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link {
              padding-top: $spacing--m;
              a {
                border: none;
                padding: 0;
              }
            }
          }
        }
      }
    }
    @include media(bp(xs)) {
      &:not(:only-child) {
        .field-name-field-title h2 {
          @include component-heading(s);
        }
      }
    }
    @include media(bp(bs)) {
      @include quantities-all(even) {
        .field-name-field-title h2 {
          @include component-heading(xs);
        }
        .paragraphs-item-cta {
          &.cw-with-title,
          &.cw-with-description {
            .field-name-field-cta-link a {
              @include component-link-title(xxs);
            }
          }
        }
      }
      @include quantities-all(odd) {
        &:not(:only-child) {
          .field-name-field-title h2 {
            @include component-heading(m);
          }
        }
      }
    }
    @include media(bp(s)) {
      @include quantities-all(even) {
        .field-name-field-title h2 {
          @include component-heading(s);
        }
        .paragraphs-item-cta {
          &.cw-with-title,
          &.cw-with-description {
            .field-name-field-cta-link a {
              @include component-link-title(xs);
            }
          }
        }
      }
      @include quantities-all(odd) {
        &:not(:only-child) {
          .field-name-field-cta-description p {
            @include component-paragraph(s);
          }
        }
      }
    }
    @include media(bp(m)) {
      @include quantities-all(odd) {
        &:not(:only-child) {
          .field-name-field-title h2 {
            @include component-heading(xs);
          }
          .field-name-field-cta-description p,
          .field-name-field-cta-description a {
            @include component-paragraph(xs);
          }
          .paragraphs-item-cta {
            &.cw-with-title,
            &.cw-with-description {
              .field-name-field-cta-link a {
                @include component-link-title(xxs);
              }
            }
          }
        }
      }
      @include quantities-all(even) {
        .field-name-field-title h2 {
          @include component-heading(m);
        }
      }
    }
    @include media(bp(l)) {
      @include quantities-all(odd) {
        &:not(:only-child) {
          .field-name-field-title h2 {
            @include component-heading(s);
          }
          .paragraphs-item-cta {
            &.cw-with-title,
            &.cw-with-description {
              .field-name-field-cta-link a {
                @include component-link-title(xs);
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    &.cb-max-columns--4 {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta {
        @include media(bp(l)) {
          // 4+ items: fixed 1/4 page width for each
          &:not(:only-child) {
            .field-name-field-title h2 {
              @include component-heading(xs);
            }
            .field-name-field-cta-description p,
            .field-name-field-cta-description a {
              @include component-paragraph(xs);
            }
            .paragraphs-item-cta {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &.cw-with-title,
              &.cw-with-description {
                .field-name-field-cta-link a {
                  @include component-link-title(xs);
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
    &.cb-max-columns--3,
    &.cb-max-columns--4 {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta {
        @include media(bp(l)) {
          @include quantities-all(2) {
            .field-name-field-title h2 {
              @include component-heading(l);
            }
            .field-name-field-cta-description p,
            .field-name-field-cta-description a {
              @include component-paragraph(s);
            }
            .paragraphs-item-cta {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &.cw-with-title,
              &.cw-with-description {
                .field-name-field-cta-link a {
                  @include component-link-title(s);
                }
              }
              /* stylelint-enable */
            }
          }
          @include quantities-all(3) {
            .field-name-field-title h2 {
              @include component-heading(m);
            }
            .field-name-field-cta-description p,
            .field-name-field-cta-description a {
              @include component-paragraph(s);
            }
            .paragraphs-item-cta {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &.cw-with-title,
              &.cw-with-description {
                .field-name-field-cta-link a {
                  @include component-link-title(xs);
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta.cb-style {
        @include quantities-all(2,4) {
          .paragraphs-item-cta.cw-with-description:not(.cw-cta-phone) {
            @include media(bp(l)) {
              .field-name-field-cta-description p,
              .field-name-field-cta-description a {
                @include component-paragraph(s);
              }
            }
          }
        }
      }
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .pane-bundle-cta.cb-style,
        .panels-ipe-bundle-cta.cb-style {
          // Six or more components in a region:
          // always "1/3" component width
          &:first-child:nth-last-child(n+6),
          &:nth-child(2):nth-last-child(n+5),
          &:nth-child(3):nth-last-child(n+4),
          &:nth-child(4):nth-last-child(n+3),
          &:nth-child(5):nth-last-child(n+2),
          &:nth-child(n+6) {
            @include media(bp(l)) {
              .field-name-field-title h2 {
                @include component-heading(s);
              }
              .paragraphs-item-cta {
                &:not(.cw-with-title):not(.cw-with-description) {
                  /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                  .field-name-field-cta-link a {
                    @include component-link-title(l);
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
        }
      }
    }
  }
}
