// Hero Base Styles

.region.cb-padding--none {
  .pane.pane-hero,
  .pane.pane-hero:only-child {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.pane {
  &.pane-hero,
  &.panels-ipe-hero {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: color(primary, xlo);
    min-height: 25vw;
    @include media(bp(m)) {
      &.hero-anchor-processed {
        margin-bottom: 0;
      }
    }
    @include media(bp(l)) {
      min-height: 300px;
      &.hero-anchor-processed {
        margin-bottom: 0;
      }
    }
    img {
      width: 100%;
    }
    .pane-hero__jump-link {
      display: none;
      @include media(bp(m)) {
        display: block;
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: -25px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $hero-home-jump-link-bg-color;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 -2px 4px 0 rgba(0, 0, 0, 0.24);
        border-radius: 100px;
        text-align: center;
        line-height: 50px;
        font-size: 1.2em;
        z-index: z(contentTop);
        @include icon($icon: "caret-down", $pseudo: "after", $color: $hero-home-jump-link-icon-color);
        &:focus {
          outline-width: 2px;
        }
      }
      @include media(bp(l)) {
        font-size: 1.4em;
        line-height: 60px;
        width: 60px;
        height: 60px;
        bottom: -25px;
      }
      span {
        @include visually-hidden();
      }
    }
  }
  .paragraphs-item-hero {
    position: relative;
    overflow: hidden;
    .field-name-field-image-hero {
      position: relative;
      @include media(bp(m)) {
        min-height: 300px;
      }
      @include media(bp(l)) {
        min-height: 400px;
      }
    }
    .content {
      @include media(bp(m)) {
        overflow: hidden;
        position: relative;
      }
    }
    .group-titles {
      order: 2;
      background-color: $hero-mobile-tone-default-group-titles-bg-color;
      padding: 1em;
      font-size: 1.2em;
      font-weight: 700;
      font-family: $font--display;
      line-height: 1.66;
      text-align: center;
      z-index: 5;
      margin: 0 auto;
      max-width: 55rem;
      width: 100%;
      @include media(bp(m)) {
        background-color: transparent;
        font-size: 2em;
        line-height: 1.33;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12);
        padding: 1em 2em;
        .lt-ie10 & {
          max-width: 100%;
          padding: 2.5em 8% 1.5em 8%;
        }
      }
      @include media(bp(l)) {
        max-width: 66rem;
        font-size: 3em;
        padding: 1em;
        .lt-ie10 & {
          padding: 2.5em 10% 1.5em 10%;
        }
      }
      @include media(bp(xl)) {
        padding: 1em 0 1.75em 0;
        .lt-ie10 & {
          padding: 2.5em 17% 1.5em 17%;
        }
      }
      h2 {
        line-height: 1.33;
        font-size: 1.325rem;
        font-weight: 700;
        @include media(bp(m)) {
          font-size: 1.25rem;
          line-height: 1.25;
        }
        @include media(bp(l)) {
          font-size: 1.75rem;
          max-width: 66rem;
          margin-left: auto;
          margin-right: auto;
        }
        @include media(bp(xl)) {
          font-size: 2.25rem;
        }
      }
      .field-name-field-subtitle p {
        line-height: 1.33;
        font-size: 1rem;
        font-weight: 600;
        margin-top: 0;
        @include media(bp(m)) {
          font-size: 1rem;
          line-height: 1.125;
        }
        @include media(bp(l)) {
          font-size: 1.25rem;
        }
        @include media(bp(xl)) {
          font-size: 1.5rem;
        }
      }
      h2,
      .field-name-field-subtitle p {
        color: $hero-mobile-tone-default-title-font-color;
        @include media(bp(m)) {
          color: $hero-tone-default-title-font-color;
        }
      }
    }
  }
  // stylelint-disable selector-max-specificity, selector-max-id
  #jump-target {
    @include clearfix();
  }
  // stylelint-enable selector-max-specificity, selector-max-id
}

// Base hero title alignment styles
.pane {
  &.pane-hero,
  &.panels-ipe-hero {
    .paragraphs-item-hero {
      &.cb-position-h--right,
      &.cb-position-h--left {
        .group-titles {
          @include media(bp(m)) {
            text-align: left;
            max-width: 50%;
            padding-left: 1rem;
            padding-right: 1rem;
            .field-name-field-subtitle p {
              margin-top: 0.25em;
            }
          }
        }
      }
      &.cb-position-v--top {
        .group-titles {
          @include media(bp(m)) {
            top: 0;
            bottom: auto;
            padding-top: 1.5em;
          }
        }
      }
      &.cb-position-v--middle {
        .group-titles {
          @include media(bp(m)) {
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
            padding: 1rem;
          }
        }
      }
      &.cb-position-h--right {
        .group-titles {
          @include media(bp(m)) {
            left: auto;
            padding-right: 1em;
          }
        }
      }
      &.cb-position-h--left {
        .group-titles {
          @include media(bp(m)) {
            right: auto;
            padding-left: 1em;
          }
        }
      }
      &.cb-tone--light {
        .group-titles {
          background-color: $hero-mobile-tone-light-group-titles-bg-color;
          @include media(bp(m)) {
            background-color: transparent;
          }
          h2,
          .field-name-field-subtitle p {
            color: $hero-mobile-tone-light-title-font-color;
            @include media(bp(m)) {
              color: $hero-tone-light-title-font-color;
            }
          }
        }
      }
      &.cb-tone--dark {
        .group-titles {
          background-color: $hero-mobile-tone-dark-group-titles-bg-color;
          @include media(bp(m)) {
            background-color: transparent;
          }
          h2,
          .field-name-field-subtitle p {
            color: $hero-mobile-tone-dark-title-font-color;
            @include media(bp(m)) {
              color: $hero-tone-dark-title-font-color;
            }
          }
        }
      }
    }
  }
}

// Base hero title animation styles
.pane {
  &.pane-hero,
  &.panels-ipe-hero {
    .paragraphs-item-hero {
      // hero image zoom effect
      &.cb-fx--1 {
        @include media(bp(m)) {
          img {
            @include animated($duration: 1.65s, $name: scale);
          }
        }
      }
    }
  }
}

// Fix ipe
.front {
  .region.cb-padding {
    .pane {
      &.panels-ipe-hero,
      &.panels-ipe-hero:only-child {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
  }
}

// Style Auto
// Slimmer hero images
.pane {
  &.pane-hero.cb-style--auto,
  &.panels-ipe-hero.cb-style--auto {
    .paragraphs-item-hero {
      .field-name-field-image-hero {
        position: relative;
        @include media(bp(m)) {
          min-height: 256px;
        }
        @include media(bp(l)) {
          min-height: 320px;
        }
      }
      .group-titles {
        h2 {
          @include media(bp(m)) {
            font-size: 1.25rem;
          }
          @include media(bp(l)) {
            font-size: 1.5rem;
          }
          @include media(bp(xl)) {
            font-size: 1.75rem;
          }
        }
        .field-name-field-subtitle p {
          @include media(bp(m)) {
            font-size: 0.9rem;
          }
          @include media(bp(l)) {
            font-size: 1.125rem;
          }
          @include media(bp(xl)) {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}
