// Appointments Background Styles

@include component-region-bg-styles(1,2,3) {
  &.cb-padding--auto {
    .cb-appointments {
      background: #fff;
      &__link {
        &:only-child {
          a {
            color: $text-link-color;
          }
        }
        &:not(:only-child) {
          a {
            background: lighten(color(secondary, xhi), 16%);
            color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
            &:hover {
              background: lighten(color(secondary, xhi), 19%);
              color: a11yrize($text-link-color, lighten(color(secondary, xhi), 19%));
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(auto,1,2,3) {
  &.cb-padding--auto {
    .cb-appointments {
      &__link {
        &:not(:only-child) {
          a {
            &:focus {
              outline-color: color(neutral-brand, xxlo);
            }
          }
        }
      }
    }
  }
  &.cb-padding--none {
    .cb-appointments {
      background: transparent;
      &__link {
        &:not(:only-child) {
          a {
            background: lighten(color(secondary, xhi), 16%);
            color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(auto,2,3) {
  &.cb-padding--none {
    .cb-appointments {
      &__link {
        &:not(:only-child) {
          a {
            &:hover {
              background: lighten(color(secondary, xhi), 21%);
              color: a11yrize($text-link-color, lighten(color(secondary, xhi), 21%));
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(1,2,3) {
  &.cb-padding--none {
    .cb-appointments {
      &__link {
        &:not(:only-child) {
          a {
            &:hover {
              background: $background-solid-xlight;
              color: a11yrize($text-link-color, $background-solid-xlight);
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(2,3) {
  &.cb-padding--none {
    .cb-appointments {
      h2,
      p {
        color: #fff;
      }
      &__link {
        &:only-child {
          a::before {
            color: #fff;
          }
        }
        &:not(:only-child) {
          a {
            &:focus {
              outline-color: #fff;
              outline-width: 2px;
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(2) {
  &.cb-padding--none {
    .cb-appointments {
      &__link {
        &:only-child {
          a {
            color: a11yrize($text-link-color, $region-bg-2-color);
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(3) {
  &.cb-padding--none {
    .cb-appointments {
      &__link {
        &:only-child {
          a {
            color: a11yrize($text-link-color, $region-bg-3-color);
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(1) {
  &.cb-padding--none {
    .cb-appointments {
      &__link {
        &:not(:only-child) {
          a {
            &:hover {
              background: lighten(color(secondary, xhi), 19%);
              color: a11yrize($text-link-color, lighten(color(secondary, xhi), 19%));
            }
          }
        }
      }
    }
  }
}
