// Promoted Announcements Only Base Styles

.cb-list-news--promoted.cb-list-news--announcements {
  .cb-list-news {
    &__summary {
      margin-top: $spacing--s;
      font-size: 0.7rem;
      color: color(neutral-brand, m);
      line-height: 1.4;
    }
    &__byline {
      display: none;
    }
    &__image-teaser {
      margin-top: 0;
    }
    &__title {
      // Padding for focus outlines.
      padding: 1px;
      a {
        max-height: 100%;
        &::before,
        &::after {
          display: none;
        }
        @include media(bp(xs)) {
          overflow: visible;
          display: block;
        }
      }
    }
  }
  .view {
    @include media(bp(l)) {
      // fix IE11 container height stretch
      display: block;
    }
  }
  .view-content {
    background: #fff;
    .columns-2 {
      float: none;
      width: auto;
    }
    .column-second {
      width: 100%;
    }
    > ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      max-width: 100%;
      > li {
        position: relative;
        padding-bottom: $spacing--m;
        padding-top: $spacing--m;
        margin: 0;
        &:first-child {
          padding-top: 0;
          .cb-list-news__image-teaser {
            @include media(bp(xs)) {
              top: 0;
            }
          }
        }
        &:last-child {
          padding-bottom: 0;
        }
        &:not(:first-child) {
          border-top: $border--xs;
        }
      }
      .pane-node-body {
        display: none;
      }
    }
  }
  .cb-layout__component-dynamic {
    display: flex;
    align-items: center;
    flex-direction: column;
    .column--last {
      order: 1;
      margin-bottom: $spacing--s;
      @include media(bp(bs)) {
        margin-bottom: 0;
      }
    }
    .column--first {
      order: 2;
    }
  }
}

.region,
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--announcements {
    .view {
      margin: $spacing--m auto 0 auto;
    }
    .cb-list-news {
      &__title {
        a {
          max-height: 100%;
          margin-right: 0;
          // Fix Safari title alignment issue
          overflow: visible;
          display: block;s
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
    &.pane,
    .panels-ipe-portlet-content {
      .view-content {
        @include component-padding();
        ul {
          @include media(bp(l)) {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
