// CTA Full Width Base Styles (Only Child in a Region)

.region {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    overflow: hidden;
    * {
      align-items: center;
    }
    .content {
      padding-left: 1.7%;
      padding-right: 1.7%;
      max-width: 100%;
    }
    .cw-with-image {
      @include media($bp-only-m) {
        padding-left: 0;
        overflow: hidden;
      }
      .content {
        margin-top: 120px;
        @include media(bp(bs)) {
          margin-top: 13rem;
        }
        @include media(bp(s)) {
          margin-top: 15rem;
        }
        @include media(bp(m)) {
          margin-top: 0;
        }
      }
    }
    .field-name-field-cta-image {
      position: absolute;
      max-width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      height: 140px;
      width: 100vw;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      &::before {
        @include media(bp(m)) {
          opacity: 1;
          content: "";
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 3;
        }
      }
      @include media(bp(bs)) {
        height: 12rem;
      }
      @include media(bp(bs)) {
        height: 14rem;
      }
      @include media(bp(s)) {
        height: 16rem;
      }
      @include media($bp-only-m) {
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      @include media(bp(m)) {
        height: 100%;
      }
      @include media(bp(l)) {
        // CTA hover effect
        img {
          transition: all 0.2s ease-out;
        }
      }
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 100vw;
      z-index: 2;
    }
    .cb-content-area__main & {
      .field-name-field-cta-image {
        float: none;
        margin: 0;
        width: 100vw;
      }
    }
  }
}

// Standardized component default padding sizes
.region {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    .paragraphs-item-cta,
    .paragraphs-item-cta.cw-with-image,
    .paragraphs-item-cta.cw-with-icon {
      padding-top: $spacing--s;
      @include media(bp(m)) {
        padding: $spacing--l;
      }
      @include media(bp(l)) {
        padding: $spacing--xl;
      }
      .content {
        @include component-padding();
      }
    }
  }
}

// Component Specific Prominence Styles
.region {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta.cb-style {
    &:only-child {
      padding-left: 0;
      padding-right: 0;
      .paragraphs-item-cta:not(.cw-with-image) {
        padding-top: 1em;
        padding-bottom: 1.5em;
        @include media(bp(l)) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .cw-with-image {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include media(bp(m)) {
          min-height: 20em;
          height: 46vw;
          max-height: 560px;
          align-items: center;
          justify-content: center;
          &.cb-position-h--left {
            align-items: flex-end;
            justify-content: flex-end;
          }
        }
      }
      .content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        @include clearfix();
        @include media(bp(bs)) {
          flex: 0 0 auto;
          display: block;
        }
        @include media(bp(m)) {
          min-height: 0;
          max-width: 100%;
          padding: 2em 0 2em 0;
          display: flex;
          flex-flow: row wrap;
          align-items: flex-end;
        }
        @include media(bp(l)) {
          @include max-page-width($margins: false, $flex: false);
        }
      }
      .cb-position-h--center {
        .content {
          @include media(bp(m)) {
            flex-direction: column;
          }
        }
      }
      .cw-with-icon {
        .content {
          @include media(bp(bs)) {
            display: flex;
          }
        }
      }
    }
    img {
      @include media(bp(l)) {
        width: 100%;
        height: auto;
        max-height: initial;
      }
    }
    // base styles overrides
    .paragraphs-item-cta {
      &:not(.cw-with-image) {
        padding-top: 0;
        padding-bottom: 0;
        .field-name-field-cta-link {
          padding-bottom: 0.5em;
        }
        &:not(.cw-with-description):not(.cw-with-title) {
          .field-name-field-cta-link {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

// Region with no padding
.region.cb-padding--none {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta.cb-style {
    &:only-child {
      .content {
        @include media(bp(l)) {
          padding-left: 1.7%;
          padding-right: 1.7%;
        }
      }
      .paragraphs-item-cta:not(.cw-with-image) {
        padding: 0;
        @include media(bp(s)) {
          padding: 1em $spacing--l 1.5em $spacing--l;
        }
        @include media(bp(l)) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
