// Badges with Description
.cb-badges {
  &.cw-with-description {
    .cb-badges {
      &__content {
        width: 100%;
      }
      &__badges {
        justify-content: center;
        display: flex;
        &:not(:only-child) {
          @include media(bp(l)) {
            max-width: 40%;
            width: auto;
            float: left;
          }
        }
      }
      // Default styles: badges and description are side-by-side
      &__description {
        width: 100%;
        text-align: center;
        @include media(bp(l)) {
          max-width: 60%;
          width: auto;
          display: flex;
          align-items: center;
          padding: 0 0 1em 0;
          flex-wrap: wrap;
        }
        p {
          font-family: $font--text--alt;
          @include media(bp(l)) {
            // IE10 fix: to prevent text overflow
            max-width: 100%;
          }
        }
      }
      &__badge {
        float: left;
        display: block;
        padding: 1em;
        &:only-child {
          @include media(bp(l)) {
            float: left;
            display: block;
            width: 100%;
            padding: 0 1em;
          }
          img {
            @include media(bp(l)) {
              max-height: 110px;
            }
          }
        }
        &:not(:only-child) {
          img {
            @include media(bp(l)) {
              max-height: 130px;
            }
          }
        }
      }
    }
    &.cw-with-title {
      .cb-badges {
        &__badge {
          &:only-child {
            padding-top: 1em;
          }
        }
      }
    }
  }
}

.region {
  .cb-badges {
    &:only-child {
      .cb-badges__badges {
        &:not(:only-child) {
          @include media(bp(l)) {
            margin-right: 1em;
          }
        }
      }
    }
  }
}

// Featured Layout
.cb-content-area__feature-rows {
  .cb-badges {
    &.cw-with-description {
      .cb-badges {
        &__badge {
          img {
            max-height: 100px;
          }
        }
      }
    }
  }
}

// Component Prominences and Styles
.cb-badges.cw-with-description {
  // Badges and description are stacked
  &:not(:only-child) {
    .cb-badges {
      &__content {
        @include media(bp(l)) {
          display: block;
        }
      }
      &__description,
      &__badges {
        @include media(bp(l)) {
          max-width: 100%;
          float: none;
        }
      }
      &__badges {
        justify-content: center;
        display: flex;
        text-align: center;
        @include media(bp(l)) {
          margin-bottom: $spacing--s;
        }
      }
      &__description {
        display: block;
        clear: both;
        margin: 0 auto;
        width: 100%;
        p {
          max-width: 840px;
          margin: 0 auto;
        }
      }
    }
  }
}

// Prevent badge image grid overflow
// Featured Layouts
.feature-row-nonary-empty:not(.aside-first-empty):not(.aside-last-empty),
.feature-row-nonary-empty.aside-first-empty {
  .cb-page-content {
    .cb-content-area__feature-rows {
      .cb-badges.cw-with-description {
        .cb-badges__badge {
          /* stylelint-disable selector-max-compound-selectors */
          @include media(bp(l)) {
            img {
              max-width: 256px;
              width: auto;
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
