////
/// @group colors
////

// stylelint-disable scss/dollar-variable-pattern

/* ========== Basic CUIMC Colors ============= */
$cuimc_blue: #1d4f91; // Accessible with white. Not accessible with black.
$cuimc_blue_xhi: hsl(214, 67%, 77%); // #9dbfec
$cuimc_blue_hi: hsl(214, 67%, 63%); // #6198e0
$cuimc_blue_m: hsl(214, 67%, 50%); // #2a74d5
$cuimc_blue_lo: hsl(214, 67%, 38%); // #2058a2
$cuimc_blue_xlo: hsl(214, 67%, 26%); // #163c6f

$cuimc_secondary_blue: #0077C8; // Accessible with white. Not accessible with black.
$cuimc_secondary_blue_xhi: hsl(204, 100%, 71%); // #6bc4ff
$cuimc_secondary_blue_hi: hsl(204, 100%, 50%); // #0099ff
$cuimc_secondary_blue_m: hsl(204, 100%, 40%); // #007acc
$cuimc_secondary_blue_lo: hsl(204, 100%, 30%); // #005c99
$cuimc_secondary_blue_xlo: hsl(204, 100%, 20%); // #003d66

$cuimc_grey_brand_lo: #53565A; // Accessible with white. Not accessible with black. hsl(214, 4%, 34%)
$cuimc_grey_brand_m: #75787B; // Accessible with black. Not accessible with white. hsl(210, 3%, 47%)
$cuimc_grey_brand_hi: #D0D0CE; // Accessible with black. Not accessible with white. hsl(60, 2%, 81%)
$cuimc_grey_xxhi: hsl(214, 4%, 83%); // #d2d3d5;
$cuimc_grey_xhi: hsl(214, 4%, 74%); // #babcbf;
$cuimc_grey_hi: hsl(214, 4%, 59%); // #92969b;
$cuimc_grey_m: hsl(214, 4%, 46%); // #71757a;
$cuimc_grey_lo: hsl(214, 4%, 35%); // #56595d;
$cuimc_grey_xlo: hsl(214, 4%, 24%); // #3b3d40;
$cuimc_grey_xxlo: hsl(214, 4%, 17%); // #2a2b2d;

$cuimc_accent_warm_grey: #A59C94; // Accessible with black. Not accessible with white.
$cuimc_accent_warm_grey_xhi: hsl(27, 9%, 74%); // #50d380
$cuimc_accent_warm_grey_hi: hsl(27, 9%, 59%); // #2bab5a
$cuimc_accent_warm_grey_m: hsl(27, 9%, 46%); // #228747
$cuimc_accent_warm_grey_lo: hsl(27, 9%, 34%); // #196636
$cuimc_accent_warm_grey_xlo: hsl(27, 9%, 23%); // #114524

$cuimc_accent_green: #228848; // Accessible with black. Not accessible with white.
$cuimc_accent_green_xhi: hsl(142, 60%, 57%); // #50d380
$cuimc_accent_green_hi: hsl(142, 60%, 42%); // #2bab5a
$cuimc_accent_green_m: hsl(142, 60%, 33%); // #228747
$cuimc_accent_green_lo: hsl(142, 60%, 25%); // #196636
$cuimc_accent_green_xlo: hsl(142, 60%, 17%); // #114524

$cuimc_accent_lime_green: #76881D; // Accessible with black. Not accessible with white. hsl(70, 65%, 32%)
$cuimc_accent_lime_green_xhi: hsl(70, 65%, 48%); // #afca2b
$cuimc_accent_lime_green_hi: hsl(70, 65%, 38%); // #8ba022
$cuimc_accent_lime_green_m: hsl(70, 65%, 30%); // #6e7e1b
$cuimc_accent_lime_green_lo: hsl(70, 65%, 22%); // #505d14
$cuimc_accent_lime_green_xlo: hsl(70, 65%, 15%); // #373f0d

$cuimc_accent_pink: #AE2573; // Accessible with white. Not accessible with black. hsl(326, 65%, 41%)
$cuimc_accent_pink_xhi: hsl(326, 65%, 79%); // #eca7ce;
$cuimc_accent_pink_hi: hsl(326, 65%, 66%); // #e170b0
$cuimc_accent_pink_m: hsl(326, 65%, 51%); // #d3318d)
$cuimc_accent_pink_lo: hsl(326, 65%, 38%); // #a02269
$cuimc_accent_pink_xlo: hsl(326, 65%, 26%); // #6d1748

$cuimc_accent_warm_pink: #A60A3D; // Accessible with white. Not accessible with black. hsl(340, 89%, 35%)
$cuimc_accent_warm_pink_xhi: hsl(340, 89%, 81%); // #faa3c0
$cuimc_accent_warm_pink_hi: hsl(340, 89%, 67%); // #f66092
$cuimc_accent_warm_pink_m: hsl(340, 89%, 48%); // #e70d56
$cuimc_accent_warm_pink_lo: hsl(340, 89%, 36%); // #ae0a41
$cuimc_accent_warm_pink_xlo: hsl(340, 89%, 25%); // #78072d

$cuimc_accent_plum: #5D3754; // Accessible with white. Not accessible with black. hsl(314, 26%, 29%)
$cuimc_accent_plum_xhi: hsl(314, 26%, 77%); // #d4b5cc
$cuimc_accent_plum_hi: hsl(314, 26%, 63%); // #b988ae
$cuimc_accent_plum_m: hsl(314, 26%, 50%); // #a15e91
$cuimc_accent_plum_lo: hsl(314, 26%, 38%); // #7a486e
$cuimc_accent_plum_xlo: hsl(314, 26%, 25%); // #502f49

$cuimc_accent_dark_orange: #B94700; // Accessible with white. Not accessible with black. hsl(23, 100%, 36%)
$cuimc_accent_dark_orange_xhi: hsl(23, 100%, 72%); // #ffa770
$cuimc_accent_dark_orange_hi: hsl(23, 100%, 51%); // #ff6505
$cuimc_accent_dark_orange_m: hsl(23, 100%, 40%); // #cc4e00
$cuimc_accent_dark_orange_lo: hsl(23, 100%, 30%); // #993b00
$cuimc_accent_dark_orange_xlo: hsl(23, 100%, 20%); // #662700

$cuimc_accent_red: #D14124; // Accessible with white. Not accessible with black. hsl(10, 71%, 48%)
$cuimc_accent_red_xhi: hsl(10, 71%, 78%); // #efac9f
$cuimc_accent_red_hi: hsl(10, 71%, 64%); // #e47862
$cuimc_accent_red_m: hsl(10, 71%, 49%); // #d64224
$cuimc_accent_red_lo: hsl(10, 71%, 37%); // #a1321b
$cuimc_accent_red_xlo: hsl(10, 71%, 25%); // ##6d2212

$cuimc_accent_yellow: #FF9800; // Accessible with black. Not accessible with white. hsl(36, 100%, 50%)
$cuimc_accent_yellow_xhi: hsl(36, 100%, 59%); // #ffab2e
$cuimc_accent_yellow_hi: hsl(36, 100%, 43%); // #db8400
$cuimc_accent_yellow_m: hsl(36, 100%, 33%); // #a86500
$cuimc_accent_yellow_lo: hsl(36, 100%, 25%); // #804d00
$cuimc_accent_yellow_xlo: hsl(36, 100%, 17%); // #573400

/* ======== Replaced Accents ======= */
// The June 2019 brand update replaced the orange and yellow accent hues.
// While the yellow is nearly identical, the orange brand color was darkened.
// The previous orange is included for continuity.
// The accessible palette is nearly identical to the new orange, which is a
// darker tint than the previous brand color.
$cuimc_accent_orange: #FC4C02; // Accessible with black. Not accessible with white. hsl(18, 98%, 50%)
$cuimc_accent_orange_xhi: hsl(18, 98%, 75%); // #fea681
$cuimc_accent_orange_hi: hsl(18, 98%, 57%); // #fd6626
$cuimc_accent_orange_m: hsl(18, 98%, 42%); // #d44102
$cuimc_accent_orange_lo: hsl(18, 98%, 32%); // #a23202
$cuimc_accent_orange_xlo: hsl(18, 98%, 22%); // #6f2201

/* ======== Legacy Cardiology Red ======= */
// Cardiology red is a legacy color that is not used in the brand guide.
// This red is exclusively used as an accent color on the cardiology website.
// www.cardiology.org
$cardiology_red: hsl(352, 82%, 50%); // #e81733 Accessible with white and black.
$cardiology_red_xhi: hsl(352, 82%, 81%); // #f6a7b1
$cardiology_red_hi: hsl(352, 82%, 68%); // #f06a7c
$cardiology_red_m: hsl(352, 82%, 50%); // #e81733
$cardiology_red_lo: hsl(352, 82%, 38%); // #b01127
$cardiology_red_xlo: hsl(352, 82%, 26%); // #790c1a


/* ========== Alert Colors ========= */
// These are web only colors that are not pert of the brand guide.
$pass: hsl(124, 82%, 29%); // #0d8715
$pass_hi: hsl(124, 82%, 38%); // #11b01c
$pass_lo: hsl(124, 82%, 22%); // #0a6610

$fail: hsl(5, 91%, 47%); // #e51d0b
$fail_hi: hsl(5, 91%, 66%); // #f76759
$fail_lo: hsl(5, 91%, 36%); // #af1608

$warn: hsl(20, 86%, 43%); // #cc4e0f
$warn_hi: hsl(20, 86%, 57%); // #f07233
$warn_lo: hsl(20, 86%, 32%); // #983a0b

/* ========== Unused Brand Colors ========= */
// There are a number of accent colors specified in the brand guide that we
// don't use for the web. For the most part these colors are close to colors
// that can be found in the accessible palette of another brand color.
// For reference the unused colors can be found in the unused-colors.json file.

/* ========== SASS Map ========= */
/// A map of all colors used in the theme abstracted to
/// accent and neutral colors to facilitate easier color
/// changes should the need arise.
/// @group Colors
/// @type Map
/// @prop {Map} primary - Primary color tones
/// @prop {color} primary.xhi [hsl(214, 67%, 77%)]
/// @prop {color} primary.hi [hsl(214, 67%, 63%)]
/// @prop {color} primary.m  [hsl(214, 67%, 50%)]
/// @prop {color} primary.lo [hsl(214, 67%, 38%)]
/// @prop {color} primary.xlo [hsl(214, 67%, 26%)]
/// @prop {color} primary.brand [#1d4f91]
/// @prop {Map} secondary - Secondary color tones
/// @prop {color} secondary.xhi [hsl(204, 100%, 71%)]
/// @prop {color} secondary.hi [hsl(204, 100%, 50%)]
/// @prop {color} secondary.m  [hsl(204, 100%, 40%)]
/// @prop {color} secondary.lo [hsl(204, 100%, 30%)]
/// @prop {color} secondary.xlo [hsl(204, 100%, 20%)]
/// @prop {color} secondary.brand [#0077C8]
/// @prop {Map} accent-site - Site accent color
/// @prop {color} accent-site.xhi [hsl(204, 100%, 71%)]
/// @prop {color} accent-site.hi [hsl(204, 100%, 50%)]
/// @prop {color} accent-site.m  [hsl(204, 100%, 40%)]
/// @prop {color} accent-site.lo [hsl(204, 100%, 30%)]
/// @prop {color} accent-site.xlo [hsl(204, 100%, 20%)]
/// @prop {color} accent-site.brand [#0077C8]
/// @prop {Map} accent-green - Green accent color
/// @prop {color} accent-green.xhi [hsl(142, 60%, 57%)]
/// @prop {color} accent-green.hi [hsl(142, 60%, 42%)]
/// @prop {color} accent-green.m  [hsl(142, 60%, 33%)]
/// @prop {color} accent-green.lo [hsl(142, 60%, 25%)]
/// @prop {color} accent-green.xlo [hsl(142, 60%, 17%)]
/// @prop {color} accent-green.brand [#228848]
/// @prop {Map} accent-pink - Pink accent color
/// @prop {color} accent-pink.xhi [hsl(326, 65%, 79%)]
/// @prop {color} accent-pink.hi [hsl(326, 65%, 66%)]
/// @prop {color} accent-pink.m  [hsl(326, 65%, 51%)]
/// @prop {color} accent-pink.lo [hsl(326, 65%, 38%)]
/// @prop {color} accent-pink.xlo [hsl(326, 65%, 26%)]
/// @prop {color} accent-pink.brand [#AE2573]
/// @prop {Map} accent-orange - Orange accent color
/// @prop {color} accent-orange.xhi [hsl(18, 98%, 75%)]
/// @prop {color} accent-orange.hi [hsl(18, 98%, 57%)]
/// @prop {color} accent-orange.m  [hsl(18, 98%, 42%)]
/// @prop {color} accent-orange.lo [hsl(18, 98%, 32%)]
/// @prop {color} accent-orange.xlo [hsl(18, 98%, 22%)]
/// @prop {color} accent-orange.brand [#FC4C02]
/// @prop {Map} accent-yellow - Yellow accent color
/// @prop {color} accent-yellow.xhi [hsl(39, 100%, 53%)]
/// @prop {color} accent-yellow.hi [hsl(39, 100%, 41%)]
/// @prop {color} accent-yellow.m  [hsl(39, 100%, 32%)]
/// @prop {color} accent-yellow.lo [hsl(39, 100%, 24%)]
/// @prop {color} accent-yellow.xlo [hsl(39, 100%, 16%)]
/// @prop {color} accent-yellow.brand [#FFA300]
/// @prop {Map} neutral-brand - Neutral color tones
/// @prop {color} neutral-brand.xxhi [hsl(214, 4%, 83%)]
/// @prop {color} neutral-brand.xhi [hsl(214, 4%, 74%)]
/// @prop {color} neutral-brand.hi [hsl(214, 4%, 59%)]
/// @prop {color} neutral-brand.m  [hsl(214, 4%, 46%)]
/// @prop {color} neutral-brand.lo [hsl(214, 4%, 35%)]
/// @prop {color} neutral-brand.xlo [hsl(214, 4%, 24%)]
/// @prop {color} neutral-brand.xxlo [hsl(214, 4%, 17%)]
/// @prop {color} neutral-brand.brand [#53565A]
/// @prop {color} neutral-brand.accent [#75787B]
/// @prop {Map} action - Action color tones
/// @prop {color} action.xhi [hsl(204, 100%, 71%)]
/// @prop {color} action.hi [hsl(204, 100%, 50%)]
/// @prop {color} action.m  [hsl(204, 100%, 40%)]
/// @prop {color} action.lo [hsl(204, 100%, 30%)]
/// @prop {color} action.xlo [hsl(204, 100%, 20%)]
/// @prop {Map} alert-pass - Alert pass color tones
/// @prop {color} alert-pass.hi [hsl(124, 82%, 38%)]
/// @prop {color} alert-pass.m  [hsl(124, 82%, 29%)]
/// @prop {color} alert-pass.lo [hsl(124, 82%, 22%)]
/// @prop {Map} alert-warn - Alert warn color tones
/// @prop {color} alert-warn.hi [hsl(20, 86%, 57%)]
/// @prop {color} alert-warn.m  [hsl(20, 86%, 43%)]
/// @prop {color} alert-warn.lo [hsl(20, 86%, 32%)]
/// @prop {Map} alert-warn - Alert fail color tones
/// @prop {color} alert-warn.hi [hsl(5, 91%, 66%)]
/// @prop {color} alert-warn.m  [hsl(5, 91%, 47%)]
/// @prop {color} alert-warn.lo [hsl(5, 91%, 36%)]
$colors-default: (
  primary: (
    brand: $cuimc_blue,
    pantone: "Pantone 7686c",
    cmyk: "cmyk(100, 73, 0, 10)",
    xhi: $cuimc_blue_xhi,
    hi: $cuimc_blue_hi,
    m: $cuimc_blue_m,
    lo: $cuimc_blue_lo,
    xlo: $cuimc_blue_xlo
  ),
  secondary: (
    brand: $cuimc_secondary_blue,
    pantone: "Pantone 3005c",
    cmyk: "cmyk(100, 31, 0, 0)",
    xhi: $cuimc_secondary_blue_xhi,
    hi: $cuimc_secondary_blue_hi,
    m: $cuimc_secondary_blue_m,
    lo: $cuimc_secondary_blue_lo,
    xlo: $cuimc_secondary_blue_xlo
  ),
  accent-site: (
    brand: $cuimc_blue,
    pantone: "Pantone 7686c",
    cmyk: "cmyk(100, 73, 0, 10)",
    xhi: $cuimc_blue_xhi,
    hi: $cuimc_blue_hi,
    m: $cuimc_blue_m,
    lo: $cuimc_blue_lo,
    xlo: $cuimc_blue_xlo
  ),
  accent-green: (
    brand: $cuimc_accent_green,
    pantone: "Pantone 7731c",
    cmyk: "cmyk(79, 0, 89, 22)",
    xhi: $cuimc_accent_green_xhi,
    hi: $cuimc_accent_green_hi,
    m: $cuimc_accent_green_m,
    lo: $cuimc_accent_green_lo,
    xlo: $cuimc_accent_green_xlo
  ),
  accent-lime-green: (
    brand: $cuimc_accent_lime_green,
    pantone: "Pantone 7496c",
    cmyk: "cmyk(46, 6, 100, 42)",
    xhi: $cuimc_accent_lime_green_xhi,
    hi: $cuimc_accent_lime_green_hi,
    m: $cuimc_accent_lime_green_m,
    lo: $cuimc_accent_lime_green_lo,
    xlo: $cuimc_accent_lime_green_xlo
  ),
  accent-pink: (
    brand: $cuimc_accent_pink,
    pantone: "Pantone 675c",
    cmyk: "cmyk(18, 100, 0, 8)",
    xhi: $cuimc_accent_pink_xhi,
    hi: $cuimc_accent_pink_hi,
    m: $cuimc_accent_pink_m,
    lo: $cuimc_accent_pink_lo,
    xlo: $cuimc_accent_pink_xlo
  ),
  accent-warm-pink: (
    brand: $cuimc_accent_warm_pink,
    pantone: "Pantone 1945c",
    cmyk: "cmyk(0, 100, 48, 26)",
    xhi: $cuimc_accent_warm_pink_xhi,
    hi: $cuimc_accent_warm_pink_hi,
    m: $cuimc_accent_warm_pink_m,
    lo: $cuimc_accent_warm_pink_lo,
    xlo: $cuimc_accent_warm_pink_xlo
  ),
  accent-plum: (
    brand: $cuimc_accent_plum,
    pantone: "Pantone 7659c",
    cmyk: "cmyk(32, 75, 0, 64)",
    xhi: $cuimc_accent_plum_xhi,
    hi: $cuimc_accent_plum_hi,
    m: $cuimc_accent_plum_m,
    lo: $cuimc_accent_plum_lo,
    xlo: $cuimc_accent_plum_xlo
  ),
  accent-orange: (
    brand: $cuimc_accent_orange,
    pantone: "Pantone 1655c",
    cmyk: "cmyk(0, 73, 98, 0)",
    xhi: $cuimc_accent_orange_xhi,
    hi: $cuimc_accent_orange_hi,
    m: $cuimc_accent_orange_m,
    lo: $cuimc_accent_orange_lo,
    xlo: $cuimc_accent_orange_xlo
  ),
  accent-dark-orange: (
    brand: $cuimc_accent_dark_orange,
    pantone: "Pantone 1525c",
    cmyk: "cmyk(2, 77, 100, 9)",
    xhi: $cuimc_accent_dark_orange_xhi,
    hi: $cuimc_accent_dark_orange_hi,
    m: $cuimc_accent_dark_orange_m,
    lo: $cuimc_accent_dark_orange_lo,
    xlo: $cuimc_accent_dark_orange_xlo
  ),
  accent-red: (
    brand: $cuimc_accent_red,
    pantone: "Pantone 7597c",
    cmyk: "cmyk(0, 85, 95, 2)",
    xhi: $cuimc_accent_red_xhi,
    hi: $cuimc_accent_red_hi,
    m: $cuimc_accent_red_m,
    lo: $cuimc_accent_red_lo,
    xlo: $cuimc_accent_red_xlo
  ),
  accent-yellow: (
    brand: $cuimc_accent_yellow,
    pantone: "Pantone 137c",
    cmyk: "cmyk(0, 41, 100, 0)",
    xhi: $cuimc_accent_yellow_xhi,
    hi: $cuimc_accent_yellow_hi,
    m: $cuimc_accent_yellow_m,
    lo: $cuimc_accent_yellow_lo,
    xlo: $cuimc_accent_yellow_xlo
  ),
  accent-cardiology: (
    brand: $cardiology_red_lo,
    xhi: $cardiology_red_xhi,
    hi: $cardiology_red_hi,
    m: $cardiology_red_m,
    lo: $cardiology_red_lo,
    xlo: $cardiology_red_xlo
  ),
  accent-grey: (
    brand: $cuimc_grey_brand_m,
    pantone: "Cool Gray 9C",
    cmyk: "cmyk(30, 22, 17, 57)"
  ),
  neutral-brand: (
    brand: $cuimc_grey_brand_lo,
    accent: $cuimc_grey_brand_m,
    pantone: "Pantone Cool Gray 11c",
    cmyk: "cmyk(44, 34, 22, 77)",
    xxhi: $cuimc_grey_xxhi,
    xhi: $cuimc_grey_xhi,
    hi: $cuimc_grey_hi,
    m: $cuimc_grey_m,
    lo: $cuimc_grey_lo,
    xlo: $cuimc_grey_xlo,
    xxlo: $cuimc_grey_xxlo
  ),
  neutral-warm: (
    pantone: "Pantone Cool Gray 6c",
    brand: $cuimc_accent_warm_grey,
    xhi: $cuimc_accent_warm_grey_xhi,
    hi: $cuimc_accent_warm_grey_hi,
    m: $cuimc_accent_warm_grey_m,
    lo: $cuimc_accent_warm_grey_lo,
    xlo: $cuimc_accent_warm_grey_xlo,
  ),
  neutral: (
    xhi: hsl(0, 0%, 74%),
    hi: hsl(0, 0%, 59%),
    m: hsl(0, 0%, 46%),
    lo: hsl(0, 0%, 35%),
    xlo: hsl(0, 0%, 23%)
  ),
  action: (
    xhi: $cuimc_blue_xhi,
    hi: $cuimc_blue_hi,
    m: $cuimc_blue_m,
    lo: $cuimc_blue_lo,
    xlo: $cuimc_blue_xlo
  ),
  alert-pass: (
    hi: $pass_hi,
    m: $pass,
    lo: $pass_lo
  ),
  alert-fail: (
    hi: $fail_hi,
    m: $fail,
    lo: $fail_lo
  ),
  alert-warn: (
    hi: $warn_hi,
    m: $warn,
    lo: $warn_lo
  ),
);

// stylelint-enable scss/dollar-variable-pattern
