// Appointments component base styles

.cb-appointments {
  background: lighten(color(secondary, xhi), 16%);
  &__title,
  p,
  a {
    font-family: $font--text--alt;
  }
  a:not(.cw-appointments__overlay-link) {
    display: flex;
    align-items: center;
    padding: 0.5em 0.5em 0.5em 2em;
    height: $touch-size-large;
    width: 100%;
    background: #fff;
    font-size: 0.83em;
    text-decoration: none;
    line-height: 1.2;
    @media screen and (min-width: 25em) {
      @include component-link-title(s);
      padding: 0.5em 1em 0.5em 2.5em;
    }
    @include media(bp(l)) {
      height: $touch-size-min;
    }
    &:hover {
      background: $background-solid-xlight;
      color: a11yrize($text-link-color, $background-solid-xlight);
    }
  }
  &__title {
    @include component-heading(s);
    margin-bottom: 0.5em;
  }
  &__description {
    margin-bottom: $spacing--m;
    p,
    a:not(.cw-appointments__overlay-link) {
      @include component-paragraph(xs);
    }
    a:not(.cw-appointments__overlay-link) {
      display: inline;
      padding: 0;
      background: transparent;
      text-decoration: underline;
      color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
      &:hover {
        text-decoration: none;
        background: transparent;
        color: a11yrize($text-link-color, lighten(color(secondary, xhi), 16%));
      }
    }
    a[href^="mailto:"] {
      // fix mail icon to display inline with email address
      display: inline-block;
      width: auto;
      height: auto;
      min-height: auto;
    }
  }
  .cb-appointments {
    &__links {
      display: block;
    }
    &__link {
      width: 100%;
      @include media(bp(m)) {
        width: auto;
      }
      &:not(:last-child) {
        margin-bottom: $spacing--s;
      }
      span.ext::before {
        display: none;
      }
      &--phone {
        a {
          @include icon($icon: 'phone-outline', $size: 0.9rem);
        }
      }
      &--telehealth {
        a {
          @include icon($icon: 'video', $size: 0.9rem);
        }
      }
      &--book-online {
        a {
          @include icon($icon: 'calendar-check', $size: 0.9rem);
        }
      }
      &--info {
        a {
          @include icon($icon: 'info-outline', $size: 0.9rem);
        }
      }
      &--phone,
      &--telehealth,
      &--book-online,
      &--info {
        a {
          position: relative;
          &::before {
            position: absolute;
            margin-left: -1.5em;
            color: color(neutral-brand, xxlo);
            // to make 'no underline' work in IE11,
            // below needs to be declared first
            // followed by text-decoration: none
            // in separate declaration below
            text-decoration: underline;
            display: inline-block;
            @media screen and (min-width: 25em) {
              margin-left: -1.8em;
            }
          }
          &::before {
            text-decoration: none;
          }
        }
      }
    }
  }
}

// IPE
.panels-ipe-editing {
  .cb-appointments {
    .panels-ipe-handlebar-wrapper {
      li {
        a {
          height: auto;
          padding: 4px 5px;
          font-size: 0.8em;
        }
      }
    }
  }
  .cb-content-area__main {
    .panels-ipe-sort-container {
      .cb-appointments.panels-ipe-portlet-wrapper {
        &.cw-with-phone-link.cw-with-telehealth-link.cw-with-info-link:not(.cw-with-doctors-link) {
          display: block;
        }
      }
    }
  }
}
