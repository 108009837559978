// Promoted News, Blog, Announcements, Events Typography Styles

// Component specific typography styles
.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted,
    .cb-list-events--promoted {
      h2.pane-title {
        color: $news-events-promoted-title-color;
        padding: 0;
      }
      .more-link {
        width: 100%;
        margin-top: $spacing--m;
        a {
          // remove icon default left spacing because news/events
          // more-links have a space at the end in the html markup
          @include more-link($margin-left: 0);
          @include media(bp(s)) {
            @include component-link-title(m);
          }
        }
      }
      .cb-list-events,
      .cb-list-news {
        &__title {
          // Padding for focus outlines.
          padding: 1px;
          a {
            font-weight: 600;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .cb-list-news--promoted.cb-list-news--topic {
      .more-link a::after {
        margin-left: 0.25em;
      }
    }
  }
}

// Standardized default component font sizes
.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted,
    .cb-list-events--promoted {
      h2.pane-title {
        @include component-heading(m);
        @include media(bp(xs)) {
          @include component-heading(l);
        }
        @include media(bp(m)) {
          @include component-heading(xl);
        }
        @include media(bp(l)) {
          @include component-heading(xxl);
        }
        a {
          font-weight: 600;
        }
      }
      .more-link {
        text-align: center;
        padding: $spacing--s;
      }
    }
  }
  // Featured layout with sidebars
  &:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region {
        .cb-list-news--promoted,
        .cb-list-events--promoted {
          h2.pane-title {
            @include media(bp(l)) {
              @include component-heading(xl);
            }
          }
        }
      }
    }
  }
}
