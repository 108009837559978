// Promoted and Related Testimonials background styles

// Base colors across all pages
.cb-page-content .cb-content-area__main .cb-list-testimonials--promoted,
.cb-list-testimonials--related-testimonials.cb-list-testimonials--compact,
.cb-list-testimonials--related-testimonials.cups-profile-section,
.node-type-service .cb-list-testimonials--related-testimonials,
.region .cb-list-testimonials--promoted {
  .cb-layout__component-dynamic {
    background: #fff;
  }
  .cb-list-testimonials {
    &__content {
      /* stylelint-disable selector-max-compound-selectors */
      .view-content {
        li {
          border: $border--m;
          border-color: $component-bg-1-color;
          background: $component-bg-1-color;
        }
      }
      /* stylelint-enable */
    }
    &__summary {
      border-color: $border-xlight;
      &::before {
        border-top-color: $border-xlight;
      }
      &::after {
        border-top-color: #fff;
      }
    }
  }
}

// Promoted Testimonials Only
.cb-page-content .cb-content-area__main .cb-list-testimonials--promoted,
.region .cb-list-testimonials--promoted {
  .cb-list-testimonials {
    &__content {
      .view-content {
        /* stylelint-disable selector-max-compound-selectors */
        li:hover {
          border-color: $background-solid-light;
        }
        /* stylelint-enable */
      }
    }
  }
  li {
    &:hover {
      .cb-layout__component-dynamic {
        background: $background-solid-xlight;
      }
      h3 {
        /* stylelint-disable selector-max-compound-selectors */
        a {
          color: a11yrize(color(action, m), $background-solid-xlight);
        }
        /* stylelint-enable */
      }
      .cb-list-testimonials__summary {
        border-color: lighten(color(neutral-brand, xxhi), 10%);
        &::before {
          border-top-color: lighten(color(neutral-brand, xxhi), 10%);
        }
        &::after {
          border-top-color: $background-solid-xlight;
        }
      }
    }
    @include quantities-all(1,3) {
      &:hover {
        border-color: transparent;
        background: lighten(color(neutral-brand, xxhi), 10%);
      }
    }
  }
}

@include component-region-bg-styles(1) {
  .cb-list-testimonials--promoted {
    .more-link {
      a {
        color: a11yrize(color(action, m), $region-bg-1-color);
      }
    }
  }
}

@include component-region-bg-styles(2,3) {
  .cb-list-testimonials--promoted {
    h2.pane-title {
      color: #fff;
    }
    .more-link {
      a {
        color: #fff;
      }
    }
  }
}

@include component-region-bg-styles(1,2,3) {
  .cb-list-testimonials--promoted {
    .cb-list-testimonials {
      &__content {
        .view-content li {
          border: none;
        }
      }
    }
  }
}
