// With 1.77.17 combining mixed declarations was deprecated.
// To fix deprecation warnings, we need to opt in to the new behavior.
// @see https://sass-lang.com/documentation/breaking-changes/mixed-decls/
// The follwing mixins should be used to override nested selectors and to
// ensure that mixins with nested selectors are not combined.

@mixin override-nested() {
  /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
  & {
    @content;
  }
}

@mixin mixed-declarations() {
    /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
    & {
      @content;
    }
}
