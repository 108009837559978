.cb-course-listing,
.cb-course-listing p {
  font-family: $font--text--alt;
  font-size: 0.7rem;
  @include media(bp(bs)) {
    font-size: 0.9rem;
  }
  @include media(bp(m)) {
    font-size: 1rem;
  }
  @include media(bp(l)) {
    font-size: 0.9rem;
  }
  @include media(bp(xl)) {
    font-size: 1rem;
  }
}

.cb-course-listing {
  border-bottom: 5px solid color(secondary, brand);
  &__row {
    position: relative;
    overflow: hidden;
    border-bottom: $border--xs;
    padding-right: 58px;
    .no-js & {
      padding-right: 0;
    }
    &:last-child:not(.cb-course-listing__row--headers) {
      border-bottom: none;
    }
    &--headers {
      background: lighten(color(secondary, xhi), 20%);
      border-bottom: 5px solid color(secondary, brand);
      .cb-course-listing__cell {
        font-weight: 700;
        color: a11yrize(color(neutral-brand, xlo), lighten(color(secondary, xhi), 20%));
        &--description,
        &--status {
          @include visually-hidden();
        }
        &--name {
          max-width: calc(100% - 14em);
          width: calc(154% - 14em);
          min-width: 54%;
          @include media(bp(m)) {
            max-width: calc(100% - 15em);
            width: calc(150% - 15em);
            min-width: 50%;
          }
        }
      }
    }
    &:not(.cb-course-listing__row--headers) {
      .cb-course-listing__cell {
        @include word-wrap();
        &:not(.cb-course-listing__cell--description) {
          min-height: calc(42px + 1.4rem);
        }
      }
    }
    &--active {
      .cb-course-listing__cell--status div {
        background: color(alert-pass, hi);
      }
    }
    &--inactive {
      .cb-course-listing__cell--status div {
        background: color(neutral-brand, hi);
      }
    }
  }
  &__cell {
    float: left;
    padding: 1em 0.5em 1em 0.5em;
    &--id {
      max-width: 10em;
      width: 33%;
      padding-left: 1em;
    }
    &--status {
      padding-right: 0;
      div {
        position: relative;
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-top: 0.4em;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 200%;
      }
    }
    &--credits {
      max-width: 4em;
      width: 13%;
      @include media(bp(m)) {
        max-width: 5em;
        width: 17%;
      }
    }
    &--name {
      max-width: calc(100% - 14.5em - 12px);
      width: calc(154% - 14.5em - 12px);
      min-width: calc(54% - 0.5em - 12px);
      @include media(bp(m)) {
        max-width: calc(100% - 15.5em - 12px);
        width: calc(150% - 15.5em - 12px);
        min-width: calc(50% - 0.5em - 12px);
      }
      a {
        text-decoration: none;
      }
    }
    &--description {
      float: none;
      clear: both;
      padding: 0;
      width: 100%;
    }
  }
  &__description-wrapper {
    padding: 0.25em 1em 1em 1em;
    width: calc(100% + 58px);
    @include media(bp(s)) {
      width: 100%;
    }
    .no-js & {
      width: 100%;
    }
  }
  .show-hide-controller {
    position: absolute;
    top: 0.7rem;
    right: 8px;
    font-size: 15px;
    width: 42px;
    height: 42px;
    padding: 0 14px;
    background: lighten(color(neutral-brand, xxhi), 8%);
    color: a11yrize(color(neutral-brand, lo), lighten(color(neutral-brand, xxhi), 8%));
    &--closed {
      @include icon('caret-down');
    }
    &--open {
      @include icon('caret-up');
    }
    .show-hide__action,
    .show-hide__description {
      @include visually-hidden();
    }
  }
}

// Search form overrides.
.cb-list-courses {
  .form-item-status {
    @include media(bp(bs)) {
      // Providing more separation to accomodate the green dot/grey dor legend.
      display: inline-block;
      margin-right: 2.5em;
      &:first-child {
        // Adjust space optically to account for lack of dot and added dash.
        margin-right: 2.25em;
      }
      &:last-child {
        margin-right: 0;
      }
      label {
        margin-right: 0;
      }
    }
  }
  .cb-course-search-status__1,
  .cb-course-search-status__2 {
    + label span::after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: color(neutral-brand, hi);
      margin-left: 0.25em;
      margin-top: 0.4em;
      opacity: 0.7;
    }
  }
  .cb-course-search-status__1 {
    + label span::after {
      background: color(alert-pass, hi);
    }
  }
}
