// These styles currently only apply to news topic pages. However, the
// description field is available for any term page (e.g. services).
// News topics are the only term pages that are exposed at this time
//
// The equivalent generic classes for term pages are currently
// .page-taxonomy-term {
//   .pane-taxonomy-term-description-field {
//     // ...
//   }
// }

.cb-list-news--with-description {
  .cb-news-description,
  .cb-news-description p,
  .cb-news-description a {
    font-family: $font--text--alt;
    color: $headings-page-subtitle-color;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5;
    @include media(bp(l)) {
      font-size: 1.4rem;
      line-height: 1.4;
    }
    @include media(bp(l)) {
      font-size: 1.5rem;
      line-height: 1.3;
    }
  }
  .cb-news-description {
    margin-bottom: 1.8rem;
    &::before {
      content: '';
      display: block;
      width: 21%;
      border-top: 3px solid #96989b;
      margin: 0 auto 1.2em auto;
      @include media(bp(m)) {
        margin: 0.5em 0 1.5em 0;
      }
    }
  }
  .cb-news-description a {
    background: transparent;
    color: $text-link-color;
  }
}
