.node-type-cups-profile {
  .cb-content {
    padding-top: 0;
  }
  .cb-content-area {
    position: relative;
  }
  .cb-page-content {
    padding: 0;
    .cb-feature-area {
      &__feature-primary {
        background: color(secondary, brand);
        box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.24);
        color: #fff;
        font-family: $font--display;
        position: relative;
        .pane-cola-cups-profile-feature {
          @include media(bp(m)) {
            max-width: $max-width;
            margin: 0 auto;
            padding-left: $page-margins;
            padding-right: $page-margins;
          }
        }
      }
    }
  }
  .cb-content-area__main {
    margin-top: 0;
    ul, ol {
      font-family: $font--text;
      @include main-text-size();
    }
    ul {
      padding-left: 0;
      list-style-type: none;
      li {
        margin: 0;
        padding-left: 10px;
        &:not(:last-child) {
          margin: 0 0 0.75em 0;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: color(primary, xlo);
          vertical-align: middle;
          margin-right: 0.25em;
          margin-left: -10px;
          margin-bottom: 2px;
        }
      }
    }
    li {
      a {
        font-family: $font--text;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .pane-jump-menu {
    a {
      &.active {
        color: color(neutral-brand, m);
      }
    }
  }
  // stylelint-disable-next-line selector-class-pattern
  .element--sticky.element--sticky--bottom {
    position: absolute;
    bottom: 0;
    top: auto;
  }
  blockquote {
    border-left: 6px solid $cups-profile-blockquote-border-color;
    margin: 3rem 0;
    padding-left: 2.5rem;
    text-align: left;
  }
  .pane-cola-cups-profile-feature {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2em 0 0 0;
    @include clearfix();
    @include media(bp(m)) {
      flex-direction: row;
      padding: 0;
    }
    .col {
      text-align: center;
      @include media(bp(m)) {
        text-align: left;
      }
      &-first {
        order: 2;
        background-color: color(secondary, brand);
        color: #fff;
        padding: 1.25em 1.25em 1.5em 1.25em;
        border-bottom: $border--s;
        @include media(bp(m)) {
          @include span-columns(8) {
            padding: 3em 0 0 0;
            background-color: transparent;
            border: none;
            order: 1;
            display: flex;
            flex-direction: column;
          }
          > *:last-child:not(.cb-profile-feature__links) {
            margin-bottom: 3em;
          }
        }
        @include media(bp(l)) {
          @include span-columns(9) {
            display: flex;
            flex-direction: column;
          }
        }
        a {
          color: #fff;
        }
      }
      &-second {
        order: 1;
        @include media(bp(m)) {
          @include span-columns(4) {
            padding-right: 1.25em;
            text-align: right;
            order: 2;
            display: flex;
            flex-direction: column;
            @include omega();
          }
        }
        @include media(bp(l)) {
          @include span-columns(3) {
            display: flex;
          }
        }
        @include media(bp(xxl)) {
          padding-right: 0;
        }
      }
    }
    &.cb-profile-feature--hero {
      .col {
        &-first {
          @include media(bp(m)) {
            width: 45%;
            margin-right: 0;
          }
          @include media(bp(l)) {
            width: 55%;
            margin-right: 0;
          }
          @include media(bp(xl)) {
            width: 60%;
            margin-right: 0;
          }
        }
        &-second {
          @include media(bp(m)) {
            width: 55%;
            justify-content: flex-end;
          }
          @include media(bp(l)) {
            width: 45%;
          }
          @include media(bp(xl)) {
            width: 40%;
          }
        }
      }
    }
    .cb-profile-feature {
      &__specialties,
      &__academic-titles {
        @include main-text-size();
      }
      &__specialties-primary {
        .field {
          font-size: 1.125rem;
          font-weight: 600;
          @include media(bp(l)) {
            font-size: 1.5rem;
          }
          @include media(bp(m)) {
            font-size: 1.25rem;
          }
        }
        &:not(:last-child) .field {
          margin-bottom: -0.25rem;
        }
        &:nth-last-child(2) {
          margin-bottom: 1rem;
          + .cb-profile-feature__specialties {
            margin-top: -1rem;
          }
        }
        + .field:not(.cb-profile-feature__specialties) {
          margin-top: 1rem;
        }
      }
      &__phone,
      &__email {
        &:nth-last-child(2) {
          // Ensure minimum space between social media content.
          // Needed because social media content uses margin auto to self align.
          margin-bottom: 1.5em;
          + .cb-profile-feature__phone,
          + .cb-profile-feature__email {
            // Don't add space if the last item isn't social media.
            margin-top: -1em;
          }
        }
        a {
          &:hover {
            text-decoration: underline;
            &::before {
              text-decoration: none;
            }
          }
          &::before {
            display: inline-block;
          }
        }
      }
    }
    .field,
    .cb-profile-feature__phone,
    .cb-profile-feature__email {
      font-size: 1rem;
      margin: 0.25em 0;
      @include media(bp(l)) {
        font-size: 1.25rem;
      }
      @include media(bp(m)) {
        font-size: 1.125rem;
      }
    }
    .field {
      @include media(bp(l)) {
        &.field-name-field-links {
          font-size: 1rem;
        }
      }
      @include media(bp(m)) {
        &.field-name-field-links {
          font-size: 1rem;
        }
      }
      &.field-type-image {
        margin: 0;
      }
    }
    .cb-profile-feature__name {
      h1 {
        font-size: 1.5rem;
        color: $cups-profile-featured-area-title-phone-color;
        @include media(bp(m)) {
          color: $cups-profile-featured-area-title-color;
          font-size: 2rem;
        }
        @include media(bp(l)) {
          font-size: 2.5rem;
        }
        @include media(bp(xl)) {
          font-size: 3rem;
        }
      }
    }
    // Accepting new patients, Treats Children, Virtual Visits
    .field-type-list-boolean {
      &:nth-last-child(2) {
        // Ensure minimum space between social media content.
        // Needed because social media content uses margin auto to self align.
        margin-bottom: 1.5em;
        + .field-type-list-boolean {
          // Don't add space if the last item isn't social media.
          margin-top: -1em;
        }
      }
    }
    .cb-profile-feature__academic-titles {
      margin-bottom: 1rem;
      ul {
        list-style: none;
      }
      li {
        margin: 0;
        line-height: 1.2;
        + li {
          margin-top: 0.5em;
        }
      }
    }
    .cb-profile-feature__specialties {
      margin-bottom: 1rem;
      .field:not(:last-child) {
        margin-bottom: 0;
        @include media(bp(m)) {
          margin-bottom: -0.25rem;
        }
        + a {
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          text-decoration: underline;
          @include media(bp(m)) {
            color: #fff;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .pane-node-field-cups-specialties-list {
      .pane-title {
        font-weight: 700;
      }
    }
    .panel-pane.pane-node-field-cups-new-patients {
      + .pane-node-field-cups-treats-children {
        margin-top: 0.5em;
      }
    }
    .cups-profile {
      &__virtual-visits,
      &__virtual-urgent-care,
      &__new-patients,
      &__treats-children {
        font-family: $font--display;
      }
    }
    .icon {
      &--check,
      &--fail,
      &--video {
        &::before {
          font-family: "#{$icon-font-family}";
          margin-right: 0.5em;
          font-size: 1em;
          display: inline-block;
          vertical-align: bottom;
          @include media(bp(m)) {
            color: #fff;
          }
        }
      }
    }
    .cb-profile-feature__links {
      padding-top: 1em;
      margin-top: 1em;
      margin-bottom: -1.5em;
      background: color(primary, brand);
      position: relative;
      text-align: left;
      @include clearfix();
      &::before {
        content: '';
        position: absolute;
        background: color(primary, brand);
        display: block;
        top: 0;
        bottom: 0;
        width: 100vw;
        left: -1.25rem;
        z-index: 0;
      }
      @include media(bp(m)) {
        min-height: 100px;
      }
      * {
        position: relative;
        z-index: 1;
      }
      > h2 {
        font-size: 0.875em;
        font-weight: 400;
        color: #fff;
        margin-bottom: 1em;
        @include media(bp(m)) {
          font-size: 1em;
        }
      }
      .field-type-link-field {
        margin: 0;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0.875em;
        display: flex;
        flex-wrap: wrap;
        @include media(bp(m)) {
          font-size: 1em;
        }
      }
      li {
        width: 50%;
        padding: 0;
        margin: 0 0 1.25em 0;
        @include media(bp(l)) {
          width: 33.33333%
        }
      }
      @include media(bp(m)) {
        margin-top: auto;
        margin-bottom: 0;
        &::before {
          content: '';
          position: absolute;
          background: color(primary, brand);
          display: block;
          top: 0;
          bottom: 0;
          width: 100vw;
          left: -1.25rem;
          z-index: 0;
        }
      }
      @include media(bp(xl)) {
        &::before {
          width: calc(100vw - 1.875em);
          left: calc(((100vw - 1200px) / 2 + 1.25rem) * -1);
        }
      }
      // Breakpoint xxl (105 em) plus 2 * 1.875 page margins.
      @include media(min-width 108.75em) {
        &::before {
          width: calc((100vw - 1680px) / 2 + 1680px);
          // 280px = 1680px - 1200px
          // 1.25rem = left/right padding in the feature area
          left: calc(((100vw - 1680px) / 2 + 280px - 1.25rem) * -1);
        }
      }
    }
    .cb-profile-feature__links {
      .cb-links__link {
        color: #fff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          &::before {
            text-decoration: none;
          }
        }
        &--twitter {
          @include icon(twitter);
        }
        &--linkedin {
          @include icon(linkedin);
          &::before {
            vertical-align: text-bottom;
          }
        }
        &--google-scholar {
          @include icon(google-scholar);
        }
        &--pubmed {
          @include icon(pub-med);
        }
        &--website {
          @include icon(globe);
        }
        &--clinical {
          @include icon(id);
        }
        &::before {
          margin-right: 0.5em;
          font-size: 1.5em;
        }
      }
    }
    .cb-profile-feature__headshot {
      margin-bottom: 0.5em;
      @include media(bp(m)) {
        padding: 3em 0 3em 0;
        margin-bottom: 0;
      }
      .field-name-field-cups-headshot {
        overflow: visible;
      }
      img {
        width: 100%;
        max-width: 180px;
        display: inline-block;
        box-shadow: 8px 8px 0 0 $background-solid-xlight;
        @include media(bp(m)) {
          box-shadow: 16px 16px 0 0 $background-solid-xlight;
          max-width: 50vw;
        }
        @include media(bp(l)) {
          width: 100%;
          max-width: 50vw;
        }
        @include media(bp(xl)) {
          box-shadow: 20px 20px 0 0 $background-solid-xlight;
        }
      }
    }
    .cb-profile-feature__hero {
      background: linear-gradient(color(secondary, brand), color(primary, brand));
      @include media(bp(m)) {
        position: relative;
        background: none;
        flex: 1;
        display: flex;
        align-items: flex-end;
        height: 100%;
        min-height: 350px;
        max-height: 400px;
        div {
          width: 100%;
          display: flex;
          flex: 1 0 auto;
          height: 100%;
        }
      }
      @include media(bp(xl)) {
        min-height: 450px;
        max-height: 475px;
      }
      img {
        width: auto;
        height: 300px;
        @include media(bp(m)) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom;
          margin: 0 auto;
        }
      }
    }
  }
  .pane-page-breadcrumb {
    margin-left: auto;
    margin-right: auto;
    max-width: 72.5rem;
    padding: 0;
    .breadcrumb {
      margin-bottom: 0;
    }
  }
  .pane-jump-menu {
    margin-top: 4rem;
  }
  // Back link
  .pane-link-to-list {
    margin-bottom: 1em;
    @include media(bp(l)) {
      margin-bottom: -3rem;
      margin-top: 4rem;
    }
    a {
      text-decoration: none;
      @include icon($icon: "arrow-left", $pseudo: "before", $size: 0.8em);
      &::before {
        margin-right: 0.5em;
      }
      &:hover::before {
        @include animated($duration: 1s, $name: wobble);
      }
      &:hover,
      &:active {
        @include media(bp(l)) {
          background: transparent;
          color: color(action, m);
        }
      }
    }
  }
  /* stylelint-disable selector-max-compound-selectors */
  // @todo fix specificity to 3 max-compound selectors.
  // @see https://specificity.keegan.st/
  // @see http://stylelint.io/user-guide/rules/selector-max-specificity/
  .cb-content-area__main,
  .cb-content-area__main .panels-ipe-region > .panels-ipe-sort-container {
    padding-top: 0;
    > .panels-ipe-portlet-wrapper:not(:last-child) > .panels-ipe-portlet-content > .panel-pane {
      &:not(.panels-ipe-empty-pane):not(.pane-cola-news-panel-pane-related-news) {
        padding-bottom: 2.5em;
        border-bottom: $border--s;
        @include media(bp(l)) {
          padding-bottom: 4em;
        }
      }
    }
    > .panel-pane:not(.pane-cola-news-panel-pane-related-news),
    > .panels-ipe-portlet-wrapper > .panels-ipe-portlet-content > .panel-pane:not(.pane-cola-news-panel-pane-related-news) {
      overflow: hidden;
      clear: both;
      &:not(:last-child):not(.pane-link-to-list) {
        padding-bottom: 2.5em;
        border-bottom: $border--s;
        @include media(bp(l)) {
          padding-bottom: 4em;
        }
      }
      > .pane-title {
        padding-top: 2.5rem;
        text-align: center;
        margin-bottom: 1.25em;
        @include media(bp(l)) {
          padding-top: 4rem;
        }
      }
      > .cb-panel-container {
        > .panel-pane:not(:last-child) {
          margin-bottom: 1.75em;
        }
      }
      &.pane-cups-profile-appointments {
        > .cb-panel-container {
          > .panel-pane:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  /* stylelint-enable */
  .pane-cola-cups-profile-about {
    .pane-node-field-cups {
      &-expertise,
      &-academic-titles,
      &-admin-positions {
        .field-item,
        ul {
          font-family: $font--text;
          @include main-text-size();
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        li {
          margin: 0;
        }
      }
      &-expertise {
        ul {
          margin-top: 0.9em;
          @include media(bp(s)) {
            column-count: 2;
            column-gap: 2.5em;
          }
          @include media(bp(m)) {
            column-count: 3;
          }
        }
        li {
          line-height: 1.4;
          margin-bottom: 0.7em;
          // prevent an item from breaking over two columns
          display: inline-block;
          width: 100%;
        }
      }
    }
  }


  // Apointments section
  // Layout
  .pane-cups-profile-appointments {
    > .cb-panel-container {
      display: flex;
      flex-wrap: wrap;
      .pane-panels-mini {
        width: 100%;
        ~ .pane-panels-mini {
          margin-top: 2rem;
        }
        &:last-child {
          @include omega();
        }
      }
      .pane-cups-profile-appt-docasap {
        @include span-columns(12) {
          @include omega();
        }
        ~ .pane-panels-mini {
          &:last-child {
            width: 100%;
          }
        }
        ~ .pane-panels-mini:nth-child(2):nth-last-child(3),
        ~ .pane-panels-mini:nth-child(2):nth-last-child(3) ~ .pane-panels-mini {
          @include media(bp(l)) {
            @include span-columns(4) {
              display: flex;
            }
          }
        }
        ~ .pane-panels-mini:nth-child(2):nth-last-child(2),
        ~ .pane-panels-mini:nth-child(2):nth-last-child(2) ~ .pane-panels-mini {
          @include media(bp(s)) {
            @include span-columns(6) {
              display: flex;
            }
          }
        }
      }
      .pane-panels-mini {
        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(2) ~ .pane-panels-mini,
        &:first-child:nth-last-child(4) ~ .pane-panels-mini {
          @include media(min-width bp(s) max-width (bp(l) - 0.06em)) {
            @include span-columns(6) {
              display: flex;
            }
          }
        }
        &:first-child:nth-last-child(2) ~ .pane-panels-mini:last-child,
        &:first-child:nth-last-child(4) ~ .pane-panels-mini:nth-child(2) {
          @include media(min-width bp(s) max-width (bp(l) - 0.06em)) {
            margin-top: 0;
            @include omega();
          }
        }
      }
      .pane-panels-mini:not(.pane-cups-profile-appt-docasap):first-child:nth-last-child(3),
      .pane-panels-mini:not(.pane-cups-profile-appt-docasap):first-child:nth-last-child(3) ~ .pane-panels-mini {
        @include media(min-width bp(s) max-width (bp(l) - 0.06em)) {
          @include span-columns(6) {
            display: flex;
            margin-top: 2rem;
          }
        }
        @include media(bp(l)) {
          @include span-columns(4) {
            display: flex;
            margin-top: 0;
          }
        }
      }
      .pane-panels-mini:not(.pane-cups-profile-appt-docasap):first-child:nth-last-child(3) {
        @include media(min-width bp(s) max-width (bp(l) - 0.06em)) {
          width: 100%;
          margin-top: 0;
          @include omega();
        }
      }
      .pane-panels-mini:not(.pane-cups-profile-appt-docasap):first-child:nth-last-child(2),
      .pane-panels-mini:not(.pane-cups-profile-appt-docasap):first-child:nth-last-child(2) ~ .pane-panels-mini {
        @include media(bp(s)) {
          @include span-columns(6) {
            display: flex;
          }
        }
      }
      .pane-panels-mini:not(.pane-cups-profile-appt-docasap):first-child:nth-last-child(2) ~ .pane-panels-mini:last-child {
        @include media(bp(s)) {
          margin-top: 0;
        }
      }
    }
  }

  // Appointments Theme
  .pane-cups-profile-appointments {
    .pane-panels-mini {
      padding: 0 1rem 1rem 1rem;
      border: 1px solid $background-solid-light;
      display: flex;
      text-align: center;
      > .cb-panel-container::after {
        content: '';
        width: calc(100% + 2rem);
        height: 15px;
        background: $background-solid-light;
        margin: 1em -1rem -1rem -1rem;
        display: block;
      }
      > .cb-panel-container,
      > .cb-panel-container > .panel-pane {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        // IE 11 flex sizing fixes.
        min-width: 1px;
        min-height: 1px;
      }
      &.pane-cups-profile-appt-docasap > .cb-panel-container > .panel-pane {
        &.pane-docasap-link {
          flex: 0 1 auto;
          display: block;
          margin-bottom: 0;
          margin-top: 1rem;
          @include media(bp(l)) {
            display: none;
          }
        }
        &.pane-text:first-child {
          flex: 1 0 auto;
          display: block;
          margin-bottom: 0;
        }
        &.pane-docasap-inline {
          display: none;
          @include media(bp(l)) {
            display: block;
            margin: 1rem;
          }
        }
      }
      &.pane-cups-profile-appt-phone > .cb-panel-container > .panel-pane {
        display: block;
      }
      h3 {
        position: relative;
        margin: -8px auto 1rem auto;
        font-size: 1.33rem;
        width: 100%;
        flex: 0 1 auto;
        &::before {
          content: '';
          display: block;
          margin: 15px auto;
          margin-top: 0;
          width: 60px;
          height: 60px;
          border-radius: 60px;
          background: #fcfcfc;
          padding: 15px;
          border: 1px solid $background-solid-light;
        }
        &::after {
          content: '';
          width: calc(100% + 2rem);
          height: 43px;
          background: $background-solid-light;
          display: block;
          position: absolute;
          top: 8px;
          left: -1rem;
          right: -1rem;
          z-index: -1;
        }
      }
      p {
        font-size: 1.1rem;
        margin-top: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .pane-cups-profile-appt-docasap {
      h3::before {
        content: url("#{$image-path}/icons/src/pointer.svg");
        padding: 15px 14px 15px 17px;
      }
    }
    .pane-cups-profile-appt-virtual-visits,
    .pane-cups-profile-appt-virtual-urgent {
      h3::before {
        content: url("#{$image-path}/icons/src/video.svg");
      }
    }
    .pane-cups-profile-appt-phone {
      h3::before {
        content: url("#{$image-path}/icons/src/phone-alt.svg");
      }
    }
    .pane-cups-profile-appt-mychart {
      h3::before {
        content: url("#{$image-path}/icons/src/laptop-link.svg");
        padding: 12px;
      }
    }
    .pane-cups-profile-appt-docasap {
      @include media(bp(l)) {
        padding: 0;
        > .cb-panel-container::after {
          display: none;
        }
      }
      h3 + p {
        margin: 0 auto;
        max-width: 31rem;
        flex: 1 0 auto;
        // IE11 flex alignment fix.
        width: 100%;
      }
    }
    // CTA Buttons
    .pane-cups-profile-appt-virtual-visits a,
    .pane-cups-profile-appt-virtual-urgent a,
    .pane-cups-profile-appt-mychart a,
    .pane-cups-profile-appt-phone a,
    .pane-docasap-link a {
      @include btn($size: s);
      @include override-nested() {
        font-size: 0.9rem;
      }
    }
    .pane-cups-profile-appt-virtual-visits,
    .pane-cups-profile-appt-virtual-urgent,
    .pane-cups-profile-appt-mychart {
      h3 + div {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 28.125rem;
        // IE11 flex alignment fix.
        width: 100%;
        // stylelint-disable selector-max-compound-selectors
        p {
          &:first-child {
            flex: 1 0 auto;
          }
        }
        // stylelint-enable selector-max-compound-selectors
      }
    }
    .pane-cups-profile-appt-phone {
      > .cb-panel-container {
        > .pane-text:first-child {
          flex: 1 0 auto;
          margin-bottom: 0;
        }
        .panel-pane:not(:first-child) {
          font-size: 1.1rem;
          flex: 0 1 auto;
        }
      }
      .pane-node-field-cups-appt-phone,
      .pane-node-field-cups-appt-phone-exs,
      .pane-node-field-cups-appt-phone-new {
        margin: 0 auto;
        max-width: 28.125rem;
        &:not(:last-child) {
          margin-bottom: 0.75rem;
          a {
            @include btn($style: outline, $size: s, $dark: false);
            @include override-nested() {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
  .pane-cola-cups-profile-locations-list-pane {
    .views-row {
      + .views-row {
        margin-top: 1em;
      }
    }
    .views-field-title {
      a {
        text-decoration: none;
        font-weight: 700;
      }
    }
    .panel-col-first,
    .panel-col-last {
      .inside {
        margin: 0;
      }
    }
    .panel-col-first {
      width: 100%;
      margin-bottom: 2em;
      @include media(bp(m)) {
        @include span-columns(4) {
          float: right;
          @include omega();
        }
      }
    }
    .panel-col-last {
      width: 100%;
      @include media(bp(m)) {
        @include span-columns(8);
      }
    }
    img {
      display: none;
      width: 100%;
      height: auto;
      @include media(bp(m)) {
        display: inline-block;
      }
    }
    .field-name-field-cups-location-disability,
    .field-name-field-cups-location-transit,
    .field-name-field-cups-location-is-primary {
      margin-bottom: $spacing--m;
    }
    .views-field-field-cups-location-appt-phone,
    .views-field-field-cups-profile-location-pn,
    .views-field-field-cups-profile-location-fx {
      margin-bottom: $spacing--xs;
    }
    .field-name-field-cups-location-address,
    .views-field-field-cups-location-appt-phone,
    .views-label-field-cups-location-bill-phone,
    .views-field-field-cups-profile-location-pn,
    .views-field-field-cups-profile-location-fx {
      font-family: $font--text--alt;
    }
    .field-name-field-cups-location-address {
      margin-bottom: $spacing--s;
    }
    .views-label-field-cups-location-appt-phone,
    .views-label-field-cups-location-bill-phone,
    .views-label-field-cups-profile-location-pn,
    .views-label-field-cups-profile-location-fx {
      font-size: 0.9rem;
      font-weight: 600;
    }
    .views-field-field-cups-location-appt-phone,
    .field-name-field-cups-location-bill-phone,
    .views-field-field-cups-profile-location-pn,
    .views-field-field-cups-profile-location-fx {
      font-size: 0.9rem;
      a.phone-link {
        text-decoration: none;
      }
    }
    .views-field-field-cups-location-appt-phone,
    .field-name-field-cups-location-bill-phone,
    .views-field-field-cups-profile-location-pn,
    .views-field-field-cups-profile-location-fx,
    .field-name-field-cups-location-is-primary {
      display: block;
      clear: both;
    }
    .cups-location {
      &__is-primary {
        display: inline-block;
        font-family: $font--text--alt;
        padding: 0.25em 0.5em;
        background-color: $cups-locations-primary-label-bg-color;
        color: $cups-locations-primary-label-font-color;
        margin-top: 0.25em;
      }
    }
    .field-name-field-cups-location-disability,
    .field-name-field-cups-location-transit {
      display: inline-block;
      float: left;
      width: 2em;
      height: 1.6em;
    }
  }
  .cups-profile {
    &__insurance-list {
      @include media(bp(m)) {
        column-count: 2;
        column-gap: 2.5em;
      }
      @include media(bp(xl)) {
        column-count: 3;
      }
      .item-list {
        display: inline-block;
        width: 100%;
        margin-bottom: 2em;
      }
      h3 {
        font-size: 1.125rem;
        border-bottom: $border--s;
        padding: 0 0.75rem 0.25rem 0.75rem;
        margin: 0;
      }
      ul {
        border-bottom: $border--s;
        list-style-type: none;
        margin-bottom: 0;
        li {
          padding-left: 1.5rem;
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
      li {
        padding: 0.5rem 0.75rem;
        background-color: tint($background-solid-xlight, 15%);
        font-size: 1.0625rem;
        font-family: $font--text--alt;
        color: color(neutral-brand, xlo);
        &:not(:last-child) {
          border-bottom: $border--s;
          margin-bottom: 0;
        }
        &::before {
          margin-left: 0;
          margin-right: 0.5rem;
        }
      }
    }
    &__insurance-citation,
    &__billing-info,
    &__billing-info a {
      font-family: $font--text--alt;
    }
    &__insurance-citation {
      text-align: center;
      color: color(neutral-brand, lo);
    }
    &__billing-info {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: $spacing--xl;
      a.phone-link {
        text-decoration: none;
        display: inline-block;
        font-weight: 600;
        &::before {
          margin-right: 0.4em;
        }
      }
    }
  }
  .pane-cola-cups-profile-credentials {
    .pane-node-field-cups-america-top-doc,
    .pane-node-field-cups-ny-top-doc {
      width: 150px;
      display: inline-block;
      margin-right: 1.75em;
      margin-bottom: 1.75em;
      .field-name-field-cups-america-top-doc,
      .field-name-field-cups-ny-top-doc {
        height: 56px;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .field-name-field-cups-america-top-doc {
        background-image: url("#{$image-path}/logos/accolade-americas-top-doc.png");
      }
      .field-name-field-cups-ny-top-doc {
        background-image: url("#{$image-path}/logos/find-a-doctor-best-badges-new-york-mag.png");
      }
      .cups-profile__americas-top-doc,
      .cups-profile__ny-top-doc {
        @include visually-hidden();
      }
    }
  }
  // Ensure consistent font sizing when paragraph tags are missing.
  .pane-cola-cups-profile-about .pane-node-field-cups-bio,
  .pane-cola-cups-profile-research .pane-node-field-cups-research-overview {
    &, p {
      @include main-text-size();
    }
  }
  .pane-node-field-cups-publications {
    // display standardized list styles
    // (publications data from Phynd has inconsistent markup: ol, ul, p)
    ol {
      font-family: $font--text;
      @include main-text-size();
      li {
        // stylelint-disable declaration-no-important
        // Need important to override jQuery auto show more/less script inline styles.
        overflow: inherit !important;
        // stylelint-enable declaration-no-important
        &::before {
          display: none;
        }
      }
    }
    ul {
      li {
        > p {
          display: inline;
        }
      }
    }
  }
}

// Health insurance show/hide
.node-type-cups-profile {
  .cups-profile__insurance-list.columns-sorted {
    display: flex;
    .column-sort {
      width: 100%;
      display: none;
      &-1 {
        display: block;
      }
      @include media(bp(m)) {
        width: 50%;
        &-2 {
          display: block;
        }
      }
      @include media(bp(xl)) {
        width: 33.33333%;
        &-3 {
          display: block;
        }
      }
    }
    .item-list > h3,
    .show-hide-controller {
      padding: 0 1.75rem 0.25rem 0.25rem;
      font-size: 1rem;
      line-height: 1.25;
      min-height: $touch-size-min;
      border-bottom: $border--s;
      @include media(bp(l)) {
        min-height: calc(2.75rem + 2px);
      }
    }
    .item-list {
      position: relative;
      display: block;
      margin-bottom: 1.5rem;
      > h3 {
        display: flex;
        align-items: center;
        opacity: 0;
      }
    }
    .show-hide-controller {
      background: none;
      box-shadow: none;
      position: absolute;
      top: 0%;
      right: 0;
      width: 100%;
      color: $c-text;
      text-align: left;
      border-radius: 0;
      @include icon($icon:'caret-down', $size:0.75rem, $pseudo: after) {
        transition: transform 0.3s ease;
        position: absolute;
        right: 0.25rem;
        top: 50%;
        margin-top: -0.375rem;
      }
      &--open {
        &::after {
          transform: rotate(0.5turn);
        }
      }
      .show-hide__action {
        @include visually-hidden();
      }
    }
  }
}

// Related News
.node-type-cups-profile {
  .cb-content-area__main {
    .cb-list-news--related-news {
      &:not(:first-child) {
        margin-top: 2.5em;
        @include media(bp(l)) {
          margin-top: 4em;
        }
      }
      &:not(:last-child) {
        margin-bottom: 2.5em;
        @include media(bp(l)) {
          margin-bottom: 4em;
        }
      }
      + div {
        border-top: solid 2px #e7e8e9;
      }
      ul {
        font-family: $font--text--alt;
        font-size: 1em;
      }
      li {
        padding-left: 0;
        margin-left: 0;
        &::before {
          display: none;
        }
        &:not(:last-child) {
          margin-bottom: 2em;
        }
        a {
          font-family: $font--display;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
