// Shared styles between all teaser lists
// These styles are nearly identical to the service-list--compact styles.
// We want to create a generic base list from these styles.

// Need the extra .pane class because the cb class is also on the body.
.cb-list-specialty--patient-stories .pane.cb-list-testimonials--related-testimonials,
.cb-list-testimonials--standard .pane.cb-list-testimonials,
.pane.cb-list-trials:not(.cb-list-trials--component),
.pane.cb-list-clinics:not(.cb-list-clinics--component) {
  overflow: hidden;
  &:nth-child(n+3):not(.panels-ipe-portlet-wrapper) {
    ul:not(.pager) {
      li {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  ul:not(.pager):not(.panels-ipe-linkbar) {
    list-style: none;
    padding-left: 0;
    @include span-columns(12);
    li {
      padding: 1.9em 0 2em 0;
      border-bottom: $border--s;
      margin-bottom: 0;
      @include span-columns(12);
      &:first-child {
        border-top: $border--l;
      }
      &:last-child {
        border-bottom: $border--m;
        margin-bottom: 1.75em;
      }
    }
  }
  .pane-node-body {
    @include span-columns(12);
    p {
      font-family: $font--text--alt;
    }
  }
}

// BEM elements of the node lists.
// Add only styles starting with &__
.pane.cb-list-trials:not(.cb-list-trials--component) .cb-list-trials,
.pane.cb-list-clinics:not(.cb-list-clinics--component) .cb-list-clinics {
  &__title {
    margin-bottom: 1em;
    padding: 1px;
    @include span-columns(12);
    @include media(bp(bs)) {
      @include span-columns(9);
    }
    h2 {
      font-size: 1.5em;
      padding: 1px;
    }
  }
  &__image-teaser {
    margin-bottom: 0.5em;
    @include span-columns(12);
    @include media(bp(bs)) {
      @include span-columns(3) {
        float: right;
        @include omega();
      }
    }
    img {
      width: 100%;
    }
  }
  // Using generic classes here, otherwise we would have to use list classes.
  // e.g. .cb-list-trails__image-teaser + .cb-list-clinics__summary cannot occur.
  // At the moment .pane-node-body only occurs in clinical-trials, but this
  // redundancy makes more sense as the body could potentially be used for the
  // other panes as well.
  &__image-teaser + .pane-node-body,
  &__image-teaser + .pane-node-field-summary {
    @include span-columns(12);
    @include media(bp(bs)) {
      @include span-columns(9);
    }
  }
  &__summary {
    @include span-columns(12);
    p {
      font-family: $font--text--alt;
      line-height: 1.7;
    }
  }
}

// Clinics Specific
.pane.cb-list-clinics:not(.cb-list-clinics--component) .cb-list-clinics {
  // clinic with no image
  &__title {
    &:first-child:nth-last-child(2) {
      width: 100%;
    }
  }
}

// Clinical Trials Specific
.pane.cb-list-trials:not(.cb-list-trials--component) .cb-list-trials {
  &__title {
    width: 100%;
    float: none;
    margin-right: 0;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__contact,
  &__id-nct,
  &__population,
  &__trial-investigator,
  &__phase {
    color: color(neutral-brand, m);
    margin-bottom: 1em;
    @include media(bp(bs)) {
      margin-bottom: 0.5em;
    }
    .pane-title,
    .field {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1;
      display: block;
      @include media(bp(bs)) {
        display: inline;
      }
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__population {
    .field-name-field-trial-population {
      .field-items,
      .field-item {
        @include media(bp(bs)) {
          display: inline;
        }
      }
    }
  }
}
