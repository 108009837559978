// Mychart Background Styles:
// Homepage and Featured layouts with no sidebar

@include component-region-bg-styles(1,2,3) {
  .cb-mychart {
    &__info {
      border: none;
    }
  }
}

@include component-region-bg-styles(1) {
  .cb-mychart {
    &__actions {
      border: $border--s;
      border-top: none;
      border-color: #fff;
    }
  }
}

@include component-region-bg-styles(2) {
  .cb-mychart {
    &__title {
      border: $component-border-style;
      border-bottom: none;
      border-color: #fff;
    }
  }
}
