// Video Typography Styles

// Component specific typography styles
.pane-video,
.cb-feature-primary .pane-node-field-news-video {
  .field-name-field-video-image {
    a {
      // play button styles
      text-decoration: none;
      @include icon($icon: "play-button", $size: 1.6em, $color: $video-home-play-btn-icon-color) {
        line-height: 2;
        width: 3em;
        height: 2em;
        font-size: 1.6rem;
        @include media(bp(m)) {
          font-size: 1.5rem;
        }
        @include media(bp(l)) {
          font-size: 2.1rem;
        }
      }
      // video dark overlay
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
      }
    }
  }
}

.pane-video,
.cb-feature-row .pane-video,
.cb-feature-primary .pane-video,
.cb-feature-primary .pane-node-field-news-video {
  .field-name-field-title {
    @include component-heading(xs);
    text-align: center;
    width: 100%;
    padding: 1rem;
    margin-bottom: 0;
    @include media(bp(s)) {
      font-size: 1.3rem;
    }
    @include media(bp(m)) {
      font-size: 1.5rem;
    }
    @include media(bp(l)) {
      @include component-heading(l);
      margin-bottom: 0;
    }
    @include media(bp(xl)) {
      @include component-heading(xxl);
      margin-bottom: 0;
    }
  }
}

.cb-feature-row .pane-video,
.cb-feature-primary .pane-video,
.cb-feature-primary .pane-node-field-news-video {
  // video in hero area or feature row with no sidebars only
  .field-name-field-video-image {
    a {
      &::before {
        // Lower play button position
        @include media(bp(s)) {
          top: 2em;
        }
        @include media(bp(m)) {
          top: 2.5em;
        }
      }
      &:hover, &:focus {
        &::before {
          color: #fff;
        }
      }
    }
  }
}

// Interior and Featured Layout
.cb-page-content--feature-rows,
.cb-content-area__main {
  .pane-video {
    font-size: 0.8em;
  }
}
