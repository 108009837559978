%related-boxy-text-listing {
  overflow: hidden;
  clear: both;
  margin-top: 3em;
  @include media(bp(l)) {
    margin-top: 4em;
  }
  ul:not(.pager) {
    li {
      background: #fff;
      margin-left: 0;
      line-height: 1.2;
      border: $border--xs;
      &:last-child {
        margin-bottom: 0;
      }
      * {
        height: 100%;
      }
      a {
        padding: $spacing--m;
        display: block;
        width: 100%;
        &:hover {
          background: $background-solid-xlight;
          color: a11yrize(color(action, m), $background-solid-xlight);
        }
      }
      .cb-layout__component-dynamic {
        // fix hidden focus outlines
        overflow: inherit;
      }
    }
  }
}
