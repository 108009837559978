.cb-flavor--subsection {
  .cb-primary-navigation {
    @include media(bp(xl)) {
      background-color: darken(color(primary, lo), 6%);
      .cb-primary-links > li > a {
        color: color(primary, xhi);
        &.active-trail {
          border-bottom-color: color(primary, xhi);
        }
        &::after {
          background-color: #fff;
        }
      }
      .cb-menu-item--featured .cb-megamenu__link {
        color: #fff;
        background-color: color(secondary, lo);
      }
      &--active-drawer {
        &::after {
          background: color(primary, m);
        }
        + .cb-subsection-navigation {
          position: relative;
          z-index: -10;
        }
      }
    }
  }
  .cb-subsection-navigation {
    padding-top: $spacing--m;
    box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.24);
    &.cb-container {
      .js & {
        display: none;
        @include media(bp(xl)) {
          display: block;
        }
      }
    }
    .cb-primary-links {
      > li {
        > a {
          font-weight: 400;
        }
      }
    }
  }
}

.cb-subsection-menu {
  .cb-primary-links {
    @include media(bp(xl)) {
      padding: 0 1.875rem;
      > li {
        margin-right: 2.9%;
      }
    }
    @include media(#{(bp(xxl) + 1.875em)}) {
      padding: 0;
    }
  }
  > .pane-title {
    @include media(bp(xl)) {
      font-size: 1.5rem;
      padding-bottom: 0.75rem;
      margin-left: 1.875rem;
      margin-right: 1.875rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      border-bottom: 2px solid color(neutral-brand, xxhi);
      a {
        font-weight: 700;
        color: color(primary, brand);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @include media(#{(bp(xxl) + 1.875em)}) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.cb-megamenu__link {
  .cb-flavor--subsection .cb-subsection-menu .cb-megamenu:not(.cb-megamenu--l) & {
    @include media(bp(xl)) {
      font-size: 1.5vw;
    }
    @include media(93em) {
      font-size: 1.3vw;
    }
    @include media(bp(xxl)) {
      font-size: 1.3125em;
    }
  }
  .cb-flavor--subsection .cb-subsection-menu .cb-megamenu--l & {
    @include media(bp(xl)) {
      font-size: 1.35vw;
    }
    @include media(93em) {
      font-size: 1.3vw;
    }
    @include media(bp(xxl)) {
      font-size: 1.25em;
    }
  }
}

// Sticky Menu
.cb-subsection-navigation {
  > .cb-container__inner.element--sticky {
    position: static;
    @include media(bp(xl)) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: $background-solid-light;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
      max-width: 100%;
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 6px;
        background: transparent;
        bottom: 0;
        position: absolute;
        transition: all 300ms ease-in-out;
      }
      > .cb-subsection-menu {
        padding-top: $spacing--m;
        background-color: $background-solid-light;
        max-width: 105rem;
        margin: 0 auto;
      }
    }
  }
  &--active-drawer {
    > .cb-container__inner.element--sticky {
      @include media(bp(xl)) {
        &::after {
          width: 100%;
          background: color(primary, brand);
          z-index: 1;
        }
      }
    }
  }
}
