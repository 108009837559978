@mixin component-region-bg-styles($region-bgs...) {
  @each $bg in $region-bgs {
    @if $bg == auto {
      .region.cb-background--auto {
        @content;
      }
      // region-bg none and 4 do not have any background colors
      // thus same functionality as region-bg auto
      .region.cb-background--none {
        @content;
      }
      // region backgrounds disabled for featured layouts with sidebars
      .cb-layout--aside-first.cb-layout--dynamic-features-asides-feature-rows,
      .cb-layout--aside-last.cb-layout--dynamic-features-asides-feature-rows {
        .region.cb-background--1,
        .region.cb-background--2,
        .region.cb-background--3 {
          .cb-flavor:not(.cb-flavor--subsection-front) & {
            @content;
          }
        }
      }
    }
    // 'Non-white' Region Backgrounds only enabled on pages with no sidebars
    @if $bg == 1 {
      .front,
      .cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
      .cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
        .region.cb-background--1 {
          @content;
        }
      }
    }
    @if $bg == 2 {
      .front,
      .cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
      .cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
        .region.cb-background--2 {
          @content;
        }
      }
    }
    @if $bg == 3 {
      .front,
      .cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
      .cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
        .region.cb-background--3 {
          @content;
        }
      }
    }
  }
}

@mixin component-bg-hover-styles($bg, $change: 'darken') {
  @if $change == 'lighten' {
    background: lighten($bg, 3%);
  }
  @else {
    background: darken($bg, 3%);
  }
}
