.cb-back-to-top {
  position: fixed;
  right: calc(-4.5em - 4px);
  bottom: 3em;
  width: 4.5em;
  min-height: 30px;
  font-size: 0.8em;
  font-weight: 400;
  text-align: center;
  line-height: 1.2;
  margin: 0 auto;
  padding: 0.5em;
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.24), -0.5px 0 0.5px 1px rgba(0, 0, 0, 0.06);
  z-index: z(nav);
  transition: all ease 400ms;
  &:focus {
    background: color(action, lo);
    color: #fff;
    right: 0;
    .cb-back-to-top__text,
    .cb-back-to-top__icon::before {
      color: #fff;
    }
  }
  &:hover {
    background: $btn-bg-color-interior-pg-component;
    color: #fff;
    .cb-back-to-top__icon {
      &::before {
        color: #fff;
      }
    }
    .cb-back-to-top__text {
      color: #fff;
    }
  }
  // syllable and mainstay use their own classes and ids so we have to disable linting here.
  // stylelint-disable selector-max-id, selector-max-specificity, selector-class-pattern, selector-id-pattern
  &--visible {
    right: 0;
    // adjust position of syllable chatbot when back to top is visible.
    ~ #syllable-container {
      > iframe {
        bottom: 7em;
      }
    }
    ~ div ._admithub_embed-container {
      .activation-button {
        bottom: 7rem;
      }
      #admithub_message_iframe {
        bottom: 11.375rem;
      }
    }
  }
  // stylelint-enable selector-max-id, selector-max-specificity, selector-class-pattern, selector-id-pattern
  &__icon {
    @include icon('caret-up', $color: color(primary, brand));
    &:hover {
      color: #fff;
    }
  }
  &__text {
    color: color(primary, brand);
  }
}
