.cb-list-trials__search {
  // stylelint-disable-next-line selector-class-pattern
  .views-widget-filter-field_trial_nct_number_title {
    input {
      width: 100%;
      max-height: 44px;
      @include media(bp(s)) {
        max-height: 46px;
      }
    }
  }
  // stylelint-disable-next-line selector-class-pattern
  .views-widget-filter-search_api_views_fulltext {
    label {
      // hide keywords label
      @include visually-hidden();
    }
  }
}
