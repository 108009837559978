// Search box with two columns for filter widgets and a reset button.
.cb-list-trials__search,
.cb-cups-by-specialties,
.cb-list-conditions-treatments__search,
.view-cola-courses-search,
.cb-list-services__search {
  .views-exposed-widget {
    // Dropdown filters
    // stylelint-disable selector-class-pattern
    &:not(.views-submit-button):not(.views-reset-button):not(.views-widget-filter-search_api_views_fulltext) {
      @include span-columns(12) {
        float: none;
      }
      @include media(bp(s)) {
        @include span-columns(6);
      }
      select {
        width: 100%;
      }
      &:nth-child(2n+3),
      &:nth-last-child(3) {
        @include media(bp(s)) {
          margin-right: 0;
        }
      }
    }
    // stylelint-enable selector-class-pattern
    // Submit and clear all buttons
    &.views-submit-button,
    &.views-reset-button {
      width: 48.3%;
      clear: both;
      margin-top: 1em;
      @include media(bp(s)) {
        width: auto;
      }
      .form-submit {
        width: 100%;
        margin-top: 0;
        @include media(bp(s)) {
          width: auto;
        }
      }
    }
    &.views-reset-button {
      .form-submit {
        background: #fff;
        color: $text-link-color;
      }
    }
    &.views-submit-button + .views-reset-button,
    &.views-reset-button + .views-submit-button {
      clear: none;
      margin-left: 3%;
      @include media(bp(m)) {
        margin-left: 0.75em;
      }
    }
  }
}

// 100% width if only one dropdown is displayed
.cb-list-conditions-treatments__search,
.view-cola-courses-search,
.cb-list-services__search {
  .views-exposed-widget {
    // stylelint-disable selector-class-pattern
    &:not(.views-submit-button):not(.views-reset-button):not(.views-widget-filter-search_api_views_fulltext) {
      &:nth-child(2):nth-last-child(3) {
        @include media(bp(s)) {
          width: 100%;
        }
      }
    }
    // stylelint-enable selector-class-pattern
  }
}

.cb-cups-by-specialties {
  .views-exposed-widget {
    // stylelint-disable selector-class-pattern
    &:not(.views-submit-button):not(.views-reset-button):not(.views-widget-filter-search_api_views_fulltext) {
      &:first-child:nth-last-child(3) {
        @include media(bp(s)) {
          width: 100%;
        }
      }
    }
    // stylelint-enable selector-class-pattern
  }
}
