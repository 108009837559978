.cb-list-resources--standard {
  .pane-views-row.classy-panel-styles,
  .panels-ipe-views-row .pane-views-row {
    > ul {
      margin: 1.75rem auto 3rem auto;
      padding: 0;
      > li {
        display: block;
        &:not(:first-child) {
          margin-top: 1.5em;
        }
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 1.75rem;
          border-bottom: $border--m;
        }
        .cb-layout__component-dynamic {
          box-shadow: $box-shadow;
          border-radius: $border-radius--default;
        }
      }
    }
  }
}

.page-node-revisions-preview .cb-preview--teaser .node-teaser {
  .cb-layout__component-dynamic {
    box-shadow: $box-shadow;
    border-radius: $border-radius--default;
  }
}

.cb-list-resources--standard,
.page-node-revisions-preview .cb-preview--teaser {
  .cb-resource {
    &__header {
      margin-bottom: 0;
    }
    &__image-teaser {
      img {
        width: 100%;
      }
    }
    &__title {
      h2, h3 {
        font-size: 1.125em;
        @include media(bp(m)) {
          font-size: 1.25em;
          margin-top: -0.15625em;
        }
        @include media(bp(l)) {
          font-size: 1.5em;
          margin-top: -0.1875em;
        }
      }
    }
    &__subtitle {
      font-weight: 600;
      p {
        line-height: 1.5;
        margin: 0.25em 0;
      }
    }
    &__summary {
      p {
        line-height: 1.5;
      }
      .show-hide-controller {
        margin-left: 1px;
        display: block;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0;
        font-size: 1rem;
        color: color(action, m);
        text-align: left;
        &:focus {
          outline: 1px dotted color(neutral-brand, m);
        }
        .show-hide {
          &__description {
            @include visually-hidden();
          }
        }
        &::after {
          margin-left: 0.25em;
        }
        &[aria-expanded='true'] {
          &::after {
            content: '\2212';
          }
        }
        &[aria-expanded='false'] {
          &::after {
            content: '\002B';
          }
        }
      }
    }
    &__locations {
      .field-item:not(:first-child) {
        margin-top: 0.5em;
      }
    }
  }
}

.cb-list-resources--standard .cb-list-resources__results,
.page-node-revisions-preview .cb-preview--teaser .node-resource {
  $resource-card-padding: 1.25rem;
  .column-first {
    padding-top: $resource-card-padding;
    &:not(.column--last) {
      @include media(bp(s)) {
        @include span-columns(12) {
          @include omega();
        }
      }
    }
    > .panel-pane {
      padding: 0 $resource-card-padding;
    }
  }
  .column-second {
    order: 1;
    margin-top: 1em;
    @include media(bp(s)) {
      width: calc(31.09% - 0.66666rem);
      margin: 0;
      position: absolute;
      right: $resource-card-padding;
      top: $resource-card-padding;
    }
    img {
      height: 100px;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      display: block;
      @include media(bp(s)) {
        width: 100%;
        height: auto;
        margin : 0;
      }
    }
  }
  .column-third {
    padding: 1rem $resource-card-padding;
    margin-top: 1rem;
    background-color: $background-solid-xlight;
    float: left;
    width: 100%;
    line-height: 1.5;
    @include media(bp(s)) {
      width: 100%;
    }
    .pane-title {
      font-size: 1rem;
      margin-bottom: 0;
    }
    > .panel-pane {
      font-size: 0.9rem;
      &:not(:first-child) {
        margin-top: 0.75rem;
      }
      @include media(bp(bs)) {
        @include quantities-all(2) {
          @include span-columns(6);
        }
        @include quantities-all(3) {
          @include span-columns(4);
        }
        &:last-child {
          @include omega()
        }
        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }
  .cb-layout:not(.column-second-empty) {
    display: flex;
    flex-direction: column;
    position: relative;
    .column-third {
      order: 3;
    }
    .column-first {
      order: 2;
      @include media(bp(s)) {
        min-height: 17.49%;
        &::before {
          content: '';
          width: calc(31.09% - 0.66666rem);
          margin: 0 $resource-card-padding;
          float: right;
          padding-bottom: 17.49%;
        }
      }
    }
  }
  .pane-node-field-external-url {
    // We're not using the overlay link for these cards.
    display: none;
  }
}

.page-node-revisions-preview .cb-preview--teaser {
  > .pane-title {
    margin-top: 1em;
    @include h3();
  }
  &, p {
    font-family: $font--text--alt;
    font-size: 1em;
  }
}
