// Condition and Treatment Detail Pages Shared Base Styles

.node-type-condition,
.node-type-treatment {
  .pane-node-field-specialties,
  .pane-node-field-image-featured {
    margin-bottom: $spacing--xl;
    @include media(bp(m)) {
      margin-bottom: $spacing--xxl;
    }
  }
  .cb-content-area__main {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.node-type-condition .cb-list-treatments--related-treatments,
.node-type-treatment .cb-list-conditions--related-conditions {
  .show-more {
    width: auto;
  }
}
