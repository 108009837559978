// If CTA style auto is selected:
// CTA will display 16:9 image format (default)
// CTA will display icon instead of image if icon option is used.

.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--auto:not(:only-child) &,
    &.pane.cb-style--auto:not(:only-child) {
      .field-name-field-cta-image {
        position: relative;
        overflow: hidden;
        img {
          display: block;
          max-width: 100%;
          height: auto;
          z-index: z(contentBottom);
          position: relative;
          transition: all 0.2s ease-out;
        }
      }
      .cw-with-image {
        &::before {
          display: none;
        }
        // with img and link only
        &:not(.cw-with-description):not(.cw-with-title) {
          /* stylelint-disable selector-max-compound-selectors */
          .field-name-field-cta-link a {
            margin-top: 0;
          }
          /* stylelint-enable */
        }
      }
      // always bottom-align cta btn
      .fieldable-panels-pane,
      .paragraphs-items-field-cta-paragraphs,
      .field-name-field-cta-paragraphs,
      .paragraphs-item-cta,
      .content {
        height: 100%;
      }
      .paragraphs-item-cta {
        &.cw-with-description,
        &.cw-with-title {
          .field-name-field-cta-link {
            margin-top: auto;
          }
        }
      }
    }
  }
  // Fix ie11 container height stretch
  &.region.cb-max-columns--3,
  &.region.cb-max-columns--4 {
    .pane-bundle-cta,
    .panels-ipe-bundle-cta {
      .pane.cb-style--auto:not(:only-child) &,
      &.pane.cb-style--auto:not(:only-child) {
        display: block;
        flex: none;
        flex-direction: unset;
        .field-name-field-title,
        .field-name-field-cta-image,
        .field-name-field-image,
        .field-name-field-cta-link,
        .field-name-field-link,
        img {
          display: block;
          flex: none;
          flex-direction: unset;
        }
      }
    }
  }
}
