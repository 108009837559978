// Overrides for Main Content, Layouts with Sidebar, and sidebar.
.cb-page-content .cb-content-area__main,
.cb-layout--aside-last .cb-content-area__aside-last,
.cb-layout--aside-first-last .cb-content-area__aside-last,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-last,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last {
  @include media(bp(l)) {
    // Size adjustments due to minimum width in the iframe form.
    .cb-mychart {
      &__info {
        width: 45%;
      }
      &__actions {
        width: 55%;
      }
      &__description {
        p,
        ul,
        ol {
          @include component-paragraph(xs);
        }
      }
      &__links {
        a {
          font-size: 0.76rem;
        }
      }
    }
  }
}

// Main content with two sidebars.
.cb-layout--dynamic-features-asides.cb-layout--aside-first-last .cb-page-content .cb-content-area__main {
  @include media(bp(xl)) {
    .cb-mychart {
      &__content {
        flex-wrap: wrap;
      }
      &__info {
        width: 100%;
        border-right: $component-border-style;
        border-bottom: 0;
      }
      &__actions {
        width: 100%;
      }
    }
  }
}

// Overrides for Main Content and Layouts with Sidebar
.cb-page-content .cb-content-area__main,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-last,
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last {
  @include media(bp(xl)) {
    // Typography adjustments due to narrow content width.
    .cb-mychart {
      &__info {
        width: 50%;
      }
      &__actions {
        width: 50%;
      }
      &__description {
        p,
        ul,
        ol {
          @include component-paragraph(s);
        }
        ul,
        ol {
          line-height: 2;
        }
      }
      .item-list {
        li {
          margin-right: 0.75em;
        }
      }
      &__links {
        a {
          font-size: 0.76rem;
        }
      }
    }
  }
}

// Main Content Only Overrides
.cb-page-content .cb-content-area__main {
  @include media(bp(xl)) {
    .cb-mychart {
      &__description {
        p,
        ul,
        ol {
          @include component-paragraph(s);
        }
        ul,
        ol {
          line-height: 2;
        }
      }
      .item-list {
        li {
          margin-right: 0.75em;
        }
      }
    }
  }
  .cb-mychart {
    &__actions {
      a {
        text-decoration: none;
        color: a11yrize(color(action, m), $background-solid-light);
      }
    }
  }
  .cb-layout--no-aside & {
    @include media($bp-only-l) {
      .cb-mychart {
        &__content {
          display: block;
        }
        &__info,
        &__actions {
          width: 100%;
        }
        &__info {
          border-right: $component-border-style;
          border-bottom: 0;
        }
      }
    }
  }
}

// Right Rail Sidebar Styles
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .cb-mychart {
        &__content {
          flex-direction: column;
        }
        &__title {
          @include component-heading-right-rail();
        }
        &__info {
          width: 100%;
          border-right: $component-border-style;
          background: $sidebar-component-bg-color;
          order: 2;
        }
        &__actions {
          padding: 0;
          width: 100%;
          order: 1;
          border: $component-border-style;
          border-bottom: none;
        }
        &__description {
          color: a11yrize($c-text, $sidebar-component-bg-color);
          p,
          ul,
          ol {
            @include component-paragraph(xs);
          }
          ul,
          ol {
            line-height: 1.8;
          }
        }
        &__links {
          a {
            color: a11yrize(color(action, m), $sidebar-component-bg-color);
          }
        }
      }
      h2.cb-mychart__title {
        margin-bottom: 0;
      }
    }
  }
}
