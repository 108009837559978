// Promoted and Related Testimonials Base Styles

// Default all pages
.region .cb-list-testimonials--promoted,
.cb-page-content .cb-content-area__main .cb-list-testimonials--promoted,
.node-type-service .cb-list-testimonials--related-testimonials,
.cb-list-testimonials--related-testimonials.cb-list-testimonials--compact,
.cb-list-testimonials--related-testimonials.cups-profile-section {
  .column-first,
  .column-second {
    float: none;
    width: 100%;
  }
  .cb-overlay-link {
    z-index: z(contentTop);
  }
  .cb-layout__component-dynamic {
    max-width: $component-content-max-width;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    // with image
    &:not(.column-second-empty) {
      .column-second {
        order: 1;
        margin-top: 0;
      }
      .column-first {
        order: 2;
      }
    }
  }
  .cb-list-testimonials {
    &__image-teaser img {
      width: 100%;
    }
    &__title {
      padding: $spacing--l;
    }
    &__summary {
      padding: $spacing--s $spacing--l $spacing--l $spacing--l;
      position: relative;
      border-top: $border--s;
      // Arrow indicator
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
      }
      &::after {
        top: -2px;
        border-width: 13px;
        left: 27px;
      }
      &::before {
        top: -2px;
        border-width: 16px;
      }
      p {
        margin-top: $spacing--s;
      }
      // base styles override: when body field is displayed instead of summary
      &.pane-node-body:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
  .cb-list-testimonials__content,
  .view-content {
    @include media(bp(l)) {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      width: 100%;
      // IE needs this or it will throw a hissy fit.
      overflow: hidden;
    }
  }
  .view-content {
    > ul {
      /* stylelint-disable selector-max-compound-selectors */
      > li {
        position: relative;
      }
      /* stylelint-enable */
    }
    ul {
      margin: 0 auto;
    }
    ul, li {
      list-style: none;
      padding: 0;
    }
    li {
      margin: $spacing--s 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .view {
    // prevent more link button crop
    overflow: visible;
  }
  // Display image and title side by side
  @include media(bp(bs)) {
    .cb-layout__component-dynamic {
      .cb-list-testimonials {
        &__title {
          text-align: left;
          a {
            font-weight: 600;
          }
        }
      }
      // with image
      &:not(.column-second-empty) {
        /* stylelint-disable selector-max-compound-selectors */
        .column-first {
          margin-top: 0;
          // necessary to vertically center align title in ie
          display: flex;
          flex-direction: column;
        }
        .cb-list-testimonials {
          &__title {
            display: flex;
            align-items: center;
            padding: 0 140px $spacing--m $spacing--l;
            min-height: 72px;
            // fix text overflow issue in ie10
            * {
              display: block;
              flex-shrink: 1;
              max-width: 100%;
            }
          }
        }
        /* stylelint-enable */
      }
      .column-second {
        margin-bottom: $spacing--m;
        .cb-list-testimonials {
          &__image-teaser {
            margin: 0;
            overflow: hidden;
            position: absolute;
            top: $spacing--m;
            right: $spacing--l;
            width: 100px;
            /* stylelint-disable selector-max-compound-selectors */
            img {
              width: 100px;
              height: auto;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
  @include media(bp(s)) {
    .cb-layout__component-dynamic {
      .column-second {
        .cb-list-testimonials {
          &__image-teaser {
            width: 160px;
            /* stylelint-disable selector-max-compound-selectors */
            img {
              width: 160px;
            }
            /* stylelint-enable */
          }
        }
      }
      // with image
      &:not(.column-second-empty) {
        .cb-list-testimonials {
          /* stylelint-disable selector-max-compound-selectors */
          &__title {
            min-height: 106px;
            padding-right: 200px;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

// Basic Page Main Content only
.cb-page-content .cb-content-area__main .cb-list-testimonials--promoted {
  .view-content {
    ul {
      margin: 0;
    }
  }
}
