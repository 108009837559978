// Labs and Unit by Type List backgrounds

// Default
.cb-list-units--component,
.cb-list-labs--component {
  .view-content,
  .item-list {
    background: $component-bg-1-color;
  }
  .view-content {
    li {
      background: $component-bg-auto-color;
      border-color: var(--site-accent);
      &:hover {
        @include component-bg-hover-styles($component-bg-1-color, $change: 'darken');
        .cb-list-units,
        .cb-list-labs {
          &__title {
            border-color: #fff;
            a {
              color: $text-link-color-hover;
            }
          }
        }
      }
    }
  }
  .cb-list-units,
  .cb-list-labs {
    &__title {
      border-bottom: $border--xs;
      h3 a {
        color: $text-link-color;
        &:hover {
          color: $text-link-color-hover;
        }
      }
    }
    &__summary {
      p {
        color: color(neutral-brand, xxlo);
      }
    }
  }
}

@include component-region-bg-styles(1) {
  .cb-list-units--component,
  .cb-list-labs--component {
    .view-content,
    .item-list {
      background: $component-bg-auto-color;
    }
    .view-content {
      li {
        background: $component-bg-1-color;
        &:hover {
          @include component-bg-hover-styles($component-bg-1-color, $change: 'darken');
          .cb-list-units,
          .cb-list-labs {
            &__title {
              a {
                color: $text-link-color-hover;
              }
            }
          }
        }
      }
    }
    .cb-list-units,
    .cb-list-labs {
      &__title {
        border-bottom: $border--s;
        border-color: #fff;
        h3 a {
          color: a11yrize(color(action, m), $component-bg-1-color);
        }
      }
    }
  }
}

@include component-region-bg-styles(2,3) {
  .cb-list-units--component,
  .cb-list-labs--component {
    h2.pane-title {
      color: #fff;
    }
    .more-link {
      padding-bottom: $spacing--m;
      a {
        @include btn($style: solid, $size: s, $dark: true);
      }
    }
  }
}
