// CTA phone background styles

@mixin cta-phone-bg-colors($bg-color, $font-color, $link-color) {
  @if $bg-color != none and $bg-color != transparent {
    $font-color: a11yrize($font-color, $bg-color);
    $link-color: a11yrize($link-color, $bg-color);
  }
  background: $bg-color;
  color: $font-color;
  .field-name-field-cta-link-prefix,
  .field-name-field-cta-link-suffix {
    color: $font-color;
  }
  .field-name-field-cta-description a {
    color: $link-color;
  }
  .content {
    span.field-name-field-cta-link {
      a {
        text-decoration: underline;
        text-decoration-color: $link-color;
        &:hover {
          text-decoration: none;
        }
        &::before {
          text-decoration: none;
        }
      }
      a,
      a::before {
        color: a11yrize(color(action, m), $bg-color);
      }
    }
  }
}

@include component-region-bg-styles(auto) {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta  {
    .paragraphs-item-cta.cw-cta-phone {
      @include cta-phone-bg-colors(
        $component-bg-auto-color,
        $component-bg-auto-font-color,
        $component-bg-auto-link-color
      );
      .content {
        span.field-name-field-cta-link {
          a {
            text-decoration-color: color(action, m);
          }
          a,
          a::before {
            color: color(action, m);
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(1) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta  {
    .paragraphs-item-cta.cw-cta-phone {
      @include cta-phone-bg-colors(
        $region-bg-1-color,
        $component-bg-auto-font-color,
        $component-bg-auto-link-color
      );
    }
  }
}

@include component-region-bg-styles(2) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta  {
    .paragraphs-item-cta.cw-cta-phone {
      @include cta-phone-bg-colors(
        $region-bg-2-color,
        #fff,
        $component-bg-auto-link-color
      );
    }
  }
}

@include component-region-bg-styles(3) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta  {
    .paragraphs-item-cta.cw-cta-phone {
      @include cta-phone-bg-colors(
        $region-bg-3-color,
        #fff,
        $component-bg-auto-link-color
      );
    }
  }
}
