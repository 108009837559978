// Promoted News Only

.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      .view-content {
        border-top: $border--l;
        border-bottom: $border--m;
        padding: $spacing--s 0;
      }
      ul:not(.panels-ipe-linkbar) {
        margin: 0;
        display: block;
        li {
          width: 100%;
          /* stylelint-disable selector-max-compound-selectors */
          &:only-child,
          &:nth-last-child(5) {
            max-width: 100%;
            .column--first,
            .column--last {
              width: 100%;
            }
            .column--last {
              order: 1;
              margin-bottom: $spacing--m;
            }
          }
          &:not(:only-child) {
            &:not(:last-child) {
              border-bottom: $border--s;
              margin-bottom: 0;
            }
          }
          .cb-layout__component-dynamic {
            display: flex;
            flex-direction: column;
          }
          .column-first.column--first {
            order: 2;
          }
          .column-second.column--last {
            order: 1;
            margin-bottom: $spacing--s;
          }
          /* stylelint-enable */
        }
      }
    }
  }
  .region {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      ul:not(.panels-ipe-linkbar) {
        li {
          &:only-child,
          &:nth-last-child(5) {
            .cb-list-news {
              // stylelint-disable selector-max-compound-selectors
              &__summary {
                display: block;
                position: relative;
                height: auto;
                width: auto;
              }
              // stylelint-enable selector-max-compound-selectors
            }
          }
        }
      }
    }
  }
}
