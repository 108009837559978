.node-type-testimonial {
  .pane-node-field-summary {
    &:not(:last-child) {
      margin-bottom: $spacing--xl;
      @include media(bp(m)) {
        margin-bottom: $spacing--xxl;
      }
    }
  }
}
