// Hero with: text background scrim auto

.pane-hero,
.panels-ipe-hero {
  .paragraphs-item-hero {
    &.cb-scrim--auto {
      .group-titles {
        @include media(bp(m)) {
          padding: 0.5em;
          text-shadow: none;
        }
        .field-name-field-subtitle p {
          margin-top: 0.25em;
        }
      }
      // text color
      &.cb-tone--default {
        .group-titles {
          @include media(bp(m)) {
            background-color: $hero-tone-default-group-titles-bg-color;
          }
        }
      }
      &.cb-tone--light {
        .group-titles {
          @include media(bp(m)) {
            background-color: $hero-tone-light-group-titles-bg-color;
            h2,
            .field-name-field-subtitle p {
              color: $hero-tone-light-title-font-color;
            }
          }
        }
      }
      &.cb-tone--dark {
        .group-titles {
          @include media(bp(m)) {
            background-color: $hero-tone-dark-group-titles-bg-color;
            h2,
            .field-name-field-subtitle p {
              color: $hero-tone-dark-title-font-color;
            }
          }
        }
      }
      // text alignment
      &.cb-position-h--center {
        .group-titles {
          @include media(bp(m)) {
            max-width: 75%;
            h2,
            .field-name-field-subtitle p {
              max-width: 100%;
            }
            h2 {
              font-size: 1.25rem;
            }
            .field-name-field-subtitle p {
              font-size: 0.9rem;
            }
          }
          @include media(bp(l)) {
            h2 {
              font-size: 1.6rem;
            }
            .field-name-field-subtitle p {
              font-size: 1.125rem;
            }
          }
          @include media(bp(xl)) {
            h2 {
              font-size: 2rem;
            }
            .field-name-field-subtitle p {
              font-size: 1.25rem;
            }
          }
        }
      }
      &.cb-position-h--right,
      &.cb-position-h--left {
        .group-titles {
          @include media(bp(m)) {
            max-width: 55%;
            h2 {
              font-size: 1rem;
            }
            .field-name-field-subtitle p {
              font-size: 0.85rem;
            }
          }
          @include media(bp(l)) {
            max-width: 55%;
            h2 {
              font-size: 1.25rem;
            }
            .field-name-field-subtitle p {
              font-size: 0.9rem;
            }
          }
          @include media(bp(xl)) {
            h2 {
              font-size: 1.75rem;
            }
            .field-name-field-subtitle p {
              font-size: 1.125rem;
            }
          }
        }
      }
      &.cb-position-v--top {
        .group-titles {
          @include media(bp(m)) {
            margin-top: 1.5em;
            padding-top: 0.5em;
          }
        }
      }
      &.cb-position-v--bottom {
        .group-titles {
          @include media(bp(m)) {
            margin-bottom: 1.5em;
            .cb-overlapped--bottom & {
              margin-bottom: 2em;
            }
          }
        }
      }
    }
  }
}

// Slim heroes
.pane-hero.cb-style--auto,
.panels-ipe-hero.cb-style--auto {
  .paragraphs-item-hero {
    &.cb-scrim--auto {
      &.cb-position-h--center {
        .group-titles {
          @include media(bp(l)) {
            h2 {
              font-size: 1.25rem;
            }
            .field-name-field-subtitle p {
              font-size: 0.9rem;
            }
          }
          @include media(bp(xl)) {
            h2 {
              font-size: 1.75rem;
            }
            .field-name-field-subtitle p {
              font-size: 1.125rem;
            }
          }
        }
      }
      &.cb-position-h--right,
      &.cb-position-h--left {
        .group-titles {
          @include media(bp(xl)) {
            h2 {
              font-size: 1.6rem;
            }
            .field-name-field-subtitle p {
              font-size: 1.125rem;
            }
          }
        }
      }
      &.cb-position-v--top {
        .group-titles {
          @include media(bp(m)) {
            margin-top: 1em;
          }
        }
      }
      &.cb-position-v--bottom {
        .group-titles {
          @include media(bp(m)) {
            margin-bottom: 1em;
            .cb-overlapped--bottom & {
              margin-bottom: 2em;
            }
          }
        }
      }
    }
  }
}
