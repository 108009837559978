.cb-content {
  table {
    font-family: $font--text--alt;
    background-color: $background-solid-xlight;
    border-radius: 2px;
    border: none;
    width: 100%;
    // Standard bottom margin. Same as paragraph bottom padding
    // (1em of 1.25em font-size)
    margin-bottom: 1.25em;
    // IE9, 10 won't render box-shadows with border-collapse: collapse;
    border-collapse: separate;
    a {
      font-family: $font--text--alt;
      text-decoration: none;
      color: a11yrize(color(action, m), $background-solid-xlight);
      &:hover {
        text-decoration: underline;
      }
      // This will prevent long email addresses from breaking out of the
      // cell width. No hyphens, because they might be misinterpreted as
      // belonging to the email address.
      &[href^="mailto:"] {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: none;
        min-width: 0;
        max-width: 100%;
        display: inline-block;
      }
    }
    caption {
      font-weight: 700;
      font-size: 1.125em;
      padding: 1rem 0 0.875rem 0;
      text-align: left;
      border-bottom: $border--xs;
    }
    thead:first-child,
    tbody:first-child {
      th, td {
        border-top: $border--xs;
      }
    }
  }
  thead {
    th {
      border-bottom: 1px solid color(neutral-brand, xxhi);
    }
  }
  th {
    font-weight: 700;
    background-color: #fff;
    padding: 0.75rem 1.125rem 0.75rem 1.125rem;
  }
  td {
    padding: 0.75em 1.125em 0.75em 1.125em;
    border-right: $border--xs;
    border-bottom: $border--xs;
    &:last-child {
      border-right: none;
    }
  }
  td, th {
    vertical-align: top;
  }
  tbody {
    border-top: none;
    th {
      border-right: $border--xs;
      border-left: $border--xs;
      border-bottom: $border--xs;
    }
  }
  tfoot {
    border-top: 2px solid color(neutral-brand, xhi);
    text-align: left;
    th {
      padding: 0.8125rem 1.8125rem 1.0625rem 1.8125rem;
      border-top: 2px solid color(neutral-brand, xhi);
    }
  }
  thead,
  tfoot {
    th {
      border-right: $border--xs;
      &:first-child {
        border-left: $border--xs;
      }
    }
  }
}

.cb-content-area__main > .pane-node-body,
.cb-content-area__main .panels-ipe-portlet-content > .pane-node-body {
  table {
    p,
    li {
      font-size: 1rem;
      font-family: $font--text--alt;
    }
    ul,
    ol {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.table-wrapper {
  position: relative;
  // Standard bottom margin. Same as paragraph bottom padding
  // (1em of 1.25em font-size)
  margin-bottom: 1.25em;
  &.has-overflow {
    padding: 30px 10px 30px 0;
    &::before {
      content: '\279f';
      content: '\279f' / '';
      speak: none;
      position: absolute;
      display: inline-block;
      right: 25px;
      top: 0;
      font-size: 30px;
      line-height: 1;
      height: 30px;
      color: color(neutral);
    }
    &::after {
      content: '';
      width: 10px;
      top: 10px;
      bottom: 10px;
      right: 5px;
      position: absolute;
      background: url('#{$image-path}/ui-assets/zigzag.png');
      background-repeat: no-repeat repeat;
    }
    &.has-caption {
      padding: calc(1.69rem + 30px) 15px 30px 0;
      table {
        > caption {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          max-width: calc(100vw - 2.5rem);
          background: #fff;
          margin-bottom: 1em;
          border-bottom: 0;
          z-index: 10;
          + tbody {
            border-top: 1px solid lighten(color(neutral-brand, xxhi), 8%);
            display: block;
          }
        }
      }
    }
  }
  &.max-scroll {
    &::before {
      transform: rotate(180deg);
    }
    &::after {
      display: none;
    }
  }
}

.table-responsive {
  max-width: 100%;
  overflow: auto;
  // padding needed to accomodate table box-shadow
  padding: 0 1px 4px 1px;
  th, td {
    max-width: calc(75vw - 2.1875rem);
    min-width: calc(((100vw - 2.5rem) / 2) - 1px);
    @include media(bp(m)) {
      min-width: 0;
    }
    .lt-ie10 & {
      max-width: 200px;
      min-width: 200px;
      @include media(bp(m)) {
        max-width: auto;
        min-width: 0;
      }
    }
  }
}
