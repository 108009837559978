// Base styles for related conditions, treatments, services, providers,
// and specialties (on cups location pages)

.cb-list-conditions--related-conditions,
.cb-list-treatments--related-treatments,
.cb-list-profiles--related-profiles,
.cb-list-profiles--by-unit,
.cb-list-profiles--by-clinic,
.node-type-cups-location .pane-cola-search-location-providers,
.node-type-cups-location .pane-node-field-services,
.node-type-cups-location .cb-list-specialties--related-specialties,
.cb-list-services--related-services {
  border: $component-border-style;
  .pane-title {
    margin: $spacing--m;
    @include related-component-heading($size: l);
    @include media(bp(s)) {
      margin: $spacing--l $spacing--l $spacing--m $spacing--l;
    }
    @include media(bp(m)) {
      margin-bottom: $spacing--m;
    }
  }
  ul {
    &.pager {
      li {
        &.pager-current {
          width: auto;
        }
      }
    }
    &:not(.pager) {
      padding-top: $spacing--m;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.cb-list-conditions--related-conditions .view-content,
.cb-list-treatments--related-treatments .view-content,
.cb-list-profiles--related-profiles .view-content,
.cb-list-profiles--by-unit .view-content,
.cb-list-profiles--by-clinic .paragraphs-item-cola-cups-profiles,
.node-type-cups-location .pane-cola-search-location-providers .view-content,
.cb-list-services--related-services .view-content,
.node-type-cups-location .pane-node-field-services .item-list,
.node-type-cups-location .cb-list-specialties--related-specialties .view-content {
  margin: 0 $spacing--m $spacing--m $spacing--m;
  @include media(bp(s)) {
    margin: 0 $spacing--l $spacing--l $spacing--l;
  }
  > ul {
    border-top: $border--l;
  }
}

.cb-list-conditions--related-conditions .view-content,
.cb-list-treatments--related-treatments .view-content,
.cb-list-profiles--related-profiles .view-content,
.cb-list-profiles--by-unit .view-content,
.cb-list-profiles--by-clinic .paragraphs-item-cola-cups-profiles,
.node-type-cups-location .pane-cola-search-location-providers .view-content,
.node-type-cups-location .cb-list-specialties--related-specialties  .view-content,
.cb-list-services--related-services .view-content {
  &:not(:only-child) {
    @include media(bp(s)) {
      margin-bottom: $spacing--m;
    }
  }
}
