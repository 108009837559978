.cb-list-conditions-treatments__alphabet-filter,
.cb-list-services__alphabet-filter {
  padding: 1.125em 1em 0.6em 1em;
  border: 1px solid #eee;
  margin: 2em 0;
  position: relative;
  &:last-child {
    margin-bottom: 2em;
  }
  > .pane-title {
    font-size: 1rem;
    background: #fff;
    display: inline-block;
    padding: 0 0.25em;
    position: absolute;
    top: -0.5em;
    // Matches padding for search base.
    left: calc(1.5rem - 0.25em);
  }
  .item-list {
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include clearfix();
      li {
        margin: 0.15em 0.18em 0.15em 0.18em;
        float: left;
        font-weight: 600;
        font-size: 0.9em;
        border: 1px solid color(action, m);
        width: 2.5em;
        height: 2.5em;
        line-height: 2.33;
        text-align: center;
        border-radius: 2px;
        position: relative;
        a {
          color: $btn-outline-color-interior-pg-component;
          font-weight: 600;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transition: all 0.1s ease;
          &:hover,
          &:active,
          &:focus {
            // stylelint-disable selector-max-compound-selectors
            @media (hover: hover) and (pointer: fine) {
              font-size: 1.2em;
              white-space: nowrap;
              line-height: 2;
              box-shadow: 0 0 4px 2px rgba($btn-bg-color-interior-pg-component, 0.3);
              &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border: 1px solid $btn-outline-color-interior-pg-component;
              }
            }
            // stylelint-enable selector-max-compound-selectors
          }
          &:focus {
            outline: 2px dotted color(neutral-brand, lo);
          }
        }
        &.no-content {
          background: lighten(color(neutral-brand, xxhi), 10%);
          border-color: color(neutral-brand, xxhi);
          color: darken(color(neutral-brand, m), 4%);
        }
        &.active {
          color: a11yrize(color(action, m), lighten(color(secondary, xhi), 16%));
          background: lighten(color(secondary, xhi), 16%);
          border-color: a11yrize(color(action, m), lighten(color(secondary, xhi), 16%));
          a {
            color: a11yrize(color(action, m), lighten(color(secondary, xhi), 16%));
            overflow: hidden;
            &::before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              border: 2px solid transparent;
            }
          }
          // stylelint-disable selector-max-compound-selectors, max-nesting-depth
          &:first-child {
            a {
              &:hover,
              &:active,
              &:focus {
                @media (hover: hover) and (pointer: fine) {
                  box-shadow: none;
                  &::before {
                    border: 2px solid color(action, lo);
                  }
                }
              }
            }
          }
          &:not(:first-child) {
            a {
              &:hover,
              &:active,
              &:focus {
                @media (hover: hover) and (pointer: fine) {
                  box-shadow: 0 0 7px 2px rgba(color(neutral-brand, hi), 0.7);
                  &::before {
                    border: 2px solid color(action, lo);
                  }
                  &::after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    content: '';
                    border-left: 4px solid color(action, lo);
                    transform: skewX(-45deg) translateX(50%);
                  }
                }
              }
            }
          }
          // stylelint-enable selector-max-compound-selectors, max-nesting-depth
        }
      }
    }
  }
}
