// In Numbers Background Styles

// Default
.region,
.cb-page-content .cb-content-area__main {
  .panels-ipe-bundle-numbers,
  .pane-bundle-numbers {
    &.pane {
      padding: $spacing--m;
      border: $component-border-style;
      background: $component-bg-auto-color;
      @include media(bp(s)) {
        padding: $spacing--l;
      }
      @include media(bp(m)) {
        padding: $spacing--xl;
      }
      .cb-number {
        &__number,
        &__description {
          color: $component-bg-auto-font-color;
        }
      }
    }
  }
}

@include component-region-bg-styles(1,2,3) {
  .panels-ipe-bundle-numbers,
  .pane-bundle-numbers {
    &.pane {
      border: none;
    }
  }
}
