.pane-menu-menu-social-accounts {
  text-align: center;
  .pane-title {
    font-size: 1.3125rem;
    margin-bottom: 1em;
    color: #fff;
    .cb-local-footer & {
      color: #fff;
      margin-top: 0;
    }
  }
  a {
    position: relative;
    width: 33px;
    height: 33px;
    text-decoration: none;
    color: #fff;
    text-indent: 34px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    line-height: 1;
    span.ext {
      @include visually-hidden();
    }
    .cb-local-footer & {
      opacity: 0.75;
      &:hover, &:focus {
        opacity: 1;
      }
    }
    &.menu__link {
      &::before {
        text-indent: -34px;
        vertical-align: initial;
      }
      &--facebook {
        @include icon("facebook");
      }
      &--twitter {
        @include icon("twitter");
      }
      &--youtube {
        @include icon("youtube");
      }
      &--linkedin {
        @include icon("linkedin");
      }
      &--instagram {
        @include icon("instagram");
      }
    }
  }
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    width: 31px;
    height: 31px;
    font-size: 1.75em;
    display: inline-block;
    margin: 0 0.4em;
    @include media(bp(bs)) {
      margin: 0 0.5em;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
