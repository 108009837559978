@use "sass:list";

@mixin headings($heading-size: null) {
  $headings: h1, h2, h3, h4, h5, h6;
  font-family: $font--display;
  margin: 1.4em 0 0.5em 0;
  line-height: 1.25;
  font-weight: 600;
  @if $heading-size {
    @if not list.index($headings, $heading-size) {
      @error "Unknown heading size #{heading-size} passed to the headings mixin.";
    } @else if $heading-size == h1 {
      @include h1();
    } @else if $heading-size == h2 {
      @include h2();
    } @else if $heading-size == h3 {
      @include h3();
    } @else if $heading-size == h4 {
      @include h4();
    } @else if $heading-size == h5 {
      @include h5();
    } @else if $heading-size == h6 {
      @include h6();
    }
  }
  &:first-child {
    margin-top: 0;
  }
  &:first-of-type {
    .file-cola-wysiwyg-teaser + & {
      margin-top: 0;
    }
  }
  .cb-structured-content & {
    &:first-child {
      margin-top: 1.4em;
    }
  }
}

@mixin h1() {
  font-size: 2em;
  color: $headings-h1-color;
  @include media(bp(m)) {
    font-size: 2.5em;
  }
  @include media(bp(l)) {
    font-size: 2.5em;
  }
}

@mixin h2() {
  font-size: 1.625em;
  color: $headings-h2-color;
  @include media(bp(m)) {
    font-size: 2em;
  }
  @include media((bp(l))) {
    font-size: 2em;
  }
}

@mixin h3() {
  font-size: 1.375em;
  color: $headings-h3-color;
  @include media(bp(m)) {
    font-size: 1.5em;
  }
  @include media(bp(l)) {
    font-size: 1.5em;
  }
}

@mixin h4() {
  font-size: 1.125em;
  color: $headings-h4-color;
  @include media(bp(l)) {
    font-size: 1.25em;
  }
}

@mixin h5() {
  font-size: 1em;
  color: $headings-h5-color;
  @include media(bp(l)) {
    font-size: 1em;
  }
}

@mixin h6() {
  font-size: 0.8em;
  color: $headings-h6-color;
  @include media(bp(l)) {
    font-size: 0.8em;
  }
}

.pane-page-title {
  width: 100%;
  margin-bottom: 1.8em;
}

h1, .h1 {
  @include headings(h1);
  .pane-page-title & {
    width: 100%;
    margin-bottom: 1em;
    text-align: center;
    color: $headings-page-title-color;
    @include media(bp(m)) {
      margin-bottom: 0.4em;
      text-align: left;
    }
    .feature-primary-empty & {
      @include media(max-width (bp(m) - 0.06em)) {
        margin-top: 1rem;
      }
    }
  }
}

h2, .h2 {
  @include headings(h2);
  .pane-node-field-subtitle & {
    color: $headings-page-subtitle-color;
    margin-bottom: 1.4em;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3;
    text-align: center;
    @include media(bp(m)) {
      text-align: left;
    }
    .cb-content-area__main & {
      &::before {
        content: '';
        display: block;
        width: 21%;
        border-top: 3px solid #96989b;
        margin: 1em auto;
        @include media(bp(m)) {
          margin: 1.1em 0 1.25em 0;
        }
      }
    }
  }
}

h3, .h3 {
  @include headings(h3);
}

h4, .h4 {
  @include headings(h4);
}

h5, .h5 {
  @include headings(h5);
}

h6, .h6 {
  @include headings(h6);
}