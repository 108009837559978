// Color Palettes
@mixin create-tones($palette) {
  $tones: brand xhi hi m lo xlo;
  @each $tone in $tones {
    .cb-color-palette__tone--#{$tone} {
      background: #{color(#{$palette}, #{$tone})};
    }
  }
}

.cb-color-palette {
  padding: 0;
  margin: 0;
  list-style: none;
  clear: both;
  display: flex;
  @include clearfix();
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  &__tone {
    margin: 0;
    div {
      background: #fff;
    }
    &:only-child {
      font-size: 0.7rem;
      width: 100%;
      div {
        width: 33.3333%;
        padding-right: 0.5rem;
        @include media(bp(s)) {
          width: 25%;
        }
        @include media(bp(l)) {
          width: 33%;
          .aside-first-empty &,
          .aside-last-empty & {
            width: 20%;
          }
        }
        @include media(bp(xl)) {
          width: 25%;
          .aside-first-empty &,
          .aside-last-empty & {
            width: 20%;
          }
        }
      }
    }
    &:not(:only-child) {
      padding-top: 50px;
      float: left;
      font-size: 0.7em;
      display: flex;
      flex-direction: column;
      @include media(bp(xl)) {
        font-size: 0.8em;
      }
      div {
        padding-right: 0.5em;
        &:first-child {
          padding-top: 0.5em;
        }
      }
    }
    &:first-child:nth-last-child(5),
    &:first-child:nth-last-child(5) ~ .cb-color-palette__tone {
      width: 20%;
    }
    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ .cb-color-palette__tone {
      width: 25%;
    }
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ .cb-color-palette__tone {
      width: 33.3333%;
    }
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ .cb-color-palette__tone {
      width: 50%;
    }
  }
  &__tone-contrast {
    &-black,
    &-white {
      position: relative;
      white-space: nowrap;
      &::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 1px solid #000;
        background: #000;
        border-radius: 20px;
        margin-right: 0.5em;
      }
      &:hover,
      &:focus {
        .cb-color-palette__qualifier {
          left: calc(10px + 0.5em);
          top: 100%;
          clip: auto;
          height: auto;
          width: 130px;
          display: block;
          background: color(accent-yellow, xhi);
          z-index: z(contentTop);
          overflow: visible;
          max-width: 190%;
          &::before {
            content: '';
            border-bottom: 8px solid color(accent-yellow, xhi);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: -8px;
            left: 8px;
          }
        }
      }
    }
    &-white {
      &::before {
        background: #fff;
      }
    }
  }
  &__grade {
    &:not(:last-child) {
      cursor: help;
      border-bottom: 1px dotted $c-text;
    }
  }
  &__qualifier {
    background: transparent;
    padding: 0.25em;
    transition: background 0.5s;
    white-space: normal;
  }
  &--primary {
    @include create-tones(primary);
  }
  &--secondary {
    @include create-tones(secondary);
  }
  &--neutral-brand {
    @include create-tones(neutral-brand);
    .cb-color-palette__tone--xxhi {
      background: color(neutral-brand, xxhi);
    }
    .cb-color-palette__tone--xxlo {
      background: color(neutral-brand, xxlo);
    }
  }
  &--neutral-warm {
    @include create-tones(neutral-warm);
  }
  &--accent-green {
    @include create-tones(accent-green);
  }
  &--accent-lime-green {
    @include create-tones(accent-lime-green);
  }
  &--accent-pink {
    @include create-tones(accent-pink);
  }
  &--accent-warm-pink {
    @include create-tones(accent-warm-pink);
  }
  &--accent-plum {
    @include create-tones(accent-plum);
  }
  &--accent-dark-orange {
    @include create-tones(accent-dark-orange);
  }
  &--accent-red {
    @include create-tones(accent-red);
  }
  &--accent-yellow {
    @include create-tones(accent-yellow);
  }
  &--accent-gray {
    .cb-color-palette__tone--brand {
      background: $cuimc_grey_brand_m;
    }
  }
  &--accent-light-gray {
    .cb-color-palette__tone--brand {
      background: $cuimc_grey_brand_hi;
    }
  }
  &--accent-dark-green {
    .cb-color-palette__tone--brand {
      background: #00573F;
    }
  }
  &--accent-brown {
    .cb-color-palette__tone--brand {
      background: #6B3529;
    }
  }
  &--accent-taupe {
    .cb-color-palette__tone--brand {
      background: #7A6855;
    }
  }
  &--accent-aqua {
    .cb-color-palette__tone--brand {
      background: #007096;
    }
  }
  &--accent-light-aqua {
    .cb-color-palette__tone--brand {
      background: #71B2C9;
    }
  }
  &--accent-light-lime-green {
    .cb-color-palette__tone--brand {
      background: #B7BF10;
    }
  }
  &--accent-light-brown {
    .cb-color-palette__tone--brand {
      background: #936D73;
    }
  }
  &--tones-1 + .cb-color-palette--tones-5:not(:last-child) {
    margin-bottom: 4em;
  }
  &--tones-1 + .cb-color-palette--tones-1 {
    margin-top: 4em;
  }
  &--web {
    .cb-color-palette__tone {
      &:only-child {
        .cb-color-palette__tone {
          &-rgb,
          &-cmyk {
            display: none;
          }
          &-pantone {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.4;
            min-height: 2.8em;
            padding-bottom: 0.25em;
          }
        }
      }
      &:not(:only-child) {
        .cb-color-palette__tone {
          &-hex,
          &-rgb,
          &-cmyk {
            display: none;
          }
          &-hsl {
            padding-top: 0.5em;
            flex: 1 0 auto;
          }
        }
      }
    }
  }
  &--print {
    .cb-color-palette__tone {
      &:only-child {
        .cb-color-palette__tone {
          &-hex,
          &-hsl {
            display: none;
          }
          &-pantone {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.4;
            min-height: 2.8em;
            padding-bottom: 0.25em;
          }
        }
      }
      &:not(:only-child) {
        .cb-color-palette__tone {
          &-hex,
          &-hsl,
          &-cmyk {
            display: none;
          }
          &-rgb {
            padding-top: 0.5em;
            flex: 1 0 auto;
          }
        }
      }
    }
  }
}
