// Promoted Blog Typography

// Default across all pages
.region,
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--blog,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
    .columns-1 {
      .cb-list-news {
        &__summary {
          @include visually-hidden();
        }
        &__byline {
          font-size: 0.9rem;
        }
        &__title {
          /* stylelint-disable selector-max-compound-selectors */
          a {
            overflow: visible;
            margin-right: 0;
            padding: 0;
          }
          /* stylelint-enable */
        }
      }
    }
    ul:not(.panels-ipe-linkbar) {
      li {
        .cb-list-news {
          &__title {
            h3 {
              @include component-link-title(s);
            }
          }
        }
        .cb-list-news {
          &__summary {
            p {
              @include component-paragraph(xs);
            }
          }
        }
        @include quantities-all(1) {
          .column-first.column--first {
            /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
            // no image
            &:only-child {
              .cb-list-news__title h3,
              .cb-list-news__best-category,
              .cb-list-news__date,
              .cb-list-news__byline {
                text-align: center;
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}

.region {
  .cb-list-news--promoted.cb-list-news--blog,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
    ul:not(.panels-ipe-linkbar) {
      li {
        @include media(bp(bs)) {
          @include quantities-all(2,4) {
            .columns-1 {
              .cb-list-news {
                // Display summary when item has no image
                &__summary {
                  @include visually-hidden--off();
                  display: block;
                  margin-top: 0.5em;
                }
              }
            }
          }
        }
        @include media(bp(m)) {
          @include quantities-all(1) {
            .column-first.column--first {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &:not(:only-child) {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(m);
                    }
                  }
                }
              }
              // no image
              &:only-child {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(l);
                    }
                  }
                  &__summary {
                    p {
                      @include component-paragraph(s);
                    }
                  }
                }
              }
              /* stylelint-enable */
            }
          }
          @include quantities-all(2,4) {
            .cb-list-news {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
            }
          }
          @include quantities-all(3) {
            .columns-1 {
              .cb-list-news {
                // Display summary when item has no image
                &__summary {
                  @include visually-hidden--off();
                  display: block;
                  margin-top: 0.5em;
                }
              }
            }
          }
        }
        @include media(bp(xl)) {
          @include quantities-all(1) {
            .column-first.column--first {
              // with image
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              &:not(:only-child) {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(l);
                    }
                  }
                  &__summary {
                    p {
                      @include component-paragraph(s);
                    }
                  }
                }
              }
              /* stylelint-enable */
            }
          }
          @include quantities-all(2) {
            .cb-list-news {
              &__title {
                h3 {
                  @include component-link-title(l);
                }
              }
              &__summary {
                p {
                  @include component-paragraph(s);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and Featured layout with no sidebars
.front.cb-flavor:not(.cb-list-news),
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-flavor:not(.cb-list-news) .cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-news--promoted.cb-list-news--blog,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
      ul:not(.panels-ipe-linkbar) {
        li {
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          @include media(bp(l)) {
            @include quantities-all(4) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(s);
                  }
                }
              }
            }
          }
          @include media(bp(xl)) {
            @include quantities-all(3) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(m);
                  }
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

// Featured Layout with sidebars:
.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region {
        .cb-list-news--promoted.cb-list-news--blog,
        .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
          @include media(bp(xl)) {
            @include quantities-all(1) {
              .column-first.column--first {
                // with image
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                &:not(:only-child) {
                  .cb-list-news {
                    &__summary {
                      p {
                        @include component-paragraph(xs);
                      }
                    }
                  }
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
  }
}

// Main content only
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--blog,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
    @include media(bp(m)) {
      .column-first.column--first {
        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        .cb-list-news {
          &__title {
            h3 {
              @include component-link-title(m);
            }
          }
        }
        /* stylelint-enable */
      }
    }
    .more-link a {
      text-decoration: none;
    }
  }
}

.cb-layout--aside-first-last {
  .cb-content-area__main {
    .cb-list-news--promoted.cb-list-news--blog,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
      @include media(bp(l)) {
        h2.pane-title {
          @include component-heading(xl);
        }
        .cb-list-news {
          &__title {
            h3 {
              @include component-link-title(l);
            }
          }
        }
      }
    }
  }
}
