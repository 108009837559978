ol, ul, dl {
  font-family: $font--text--alt;
  line-height: 1.8;
  main & {
    line-height: 1.8;
  }
}

// Main content, news article, condition/treatment detail page
.cb-content-area__main > .pane-node-body,
.cb-content-area__main .panels-ipe-portlet-content > .pane-node-body,
.cb-content-area__main .field-name-field-page-paragraphs > .field-name-field-text-text,
.cb-content-area__main .field-name-field-generic-paragraphs > .field-name-field-text-text,
.node-type-news .cb-feature-quaternary .pane-node-body,
.node-type-condition .pane-node-field-diagnosis-overview,
.node-type-condition .pane-cola-clinical-condition-symptoms,
.node-type-condition .pane-cola-clinical-risk-factors,
.node-type-condition .pane-node-field-prevention-overview,
.node-type-condition .pane-node-field-treatments-overview,
.node-type-treatment .pane-node-field-treatment-when-performed,
.node-type-treatment .pane-node-field-treatment-how-performed,
.node-type-treatment .pane-node-field-treatment-preparation,
.node-type-treatment .pane-node-field-treatment-outcome,
.cke_editable { // stylelint-disable-line selector-class-pattern
  // use default body font styles for lists
  ol, ul, dl, li, li a {
    font-family: $font--family--regular;
  }
  ol, ul, dl {
    font-size: 1.125rem;
    @include media(bp(l)) {
      font-size: 1.25rem;
    }
  }
  // needed since list item can exist with or without p tag
  li,
  dl p {
    font-size: 1.125rem;
    @include media(bp(s)) {
      font-size: 1.3rem;
    }
    @include media(bp(m)) {
      font-size: 1.4rem;
    }
    @include media(bp(l)) {
      font-size: 1.25rem;
    }
  }
}

.cb-content-area__main > .pane-node-body,
.cb-content-area__main .panels-ipe-portlet-content > .pane-node-body,
.cb-content-area__main .field-name-field-page-paragraphs > .field-name-field-text-text,
.cb-content-area__main .field-name-field-generic-paragraphs > .field-name-field-text-text,
.node-type-news .cb-feature-quaternary .pane-node-body,
.cke_editable { // stylelint-disable-line selector-class-pattern
  // Use sans serif in wysiwyg accordions.
  // And keep the font sizes consistent.
  .ck-accordion {
    /* stylelint-disable selector-max-compound-selectors */
    ol, ul, dl, li, li a {
      font-family: $font--family--alternate;
    }
    /* stylelint-enable */
    ol, ul, dl {
      font-size: 1rem;
      @include media(bp(l)) {
        font-size: 1.125rem;
      }
    }
    li,
    dl p {
      font-size: 1rem;
      @include media(bp(s)) {
        font-size: 1rem;
      }
      @include media(bp(m)) {
        font-size: 1rem;
      }
      @include media(bp(l)) {
        font-size: 1.125rem;
      }
    }
  }
}

li {
  main & {
    margin-bottom: 0.5em;
    > ul,
    > ol {
      margin-top: 0.5em;
    }
    p {
      a {
        font-family: $font--text--alt;
      }
    }
  }
}

ol {
  ol {
    list-style-type: lower-latin;
    ol {
      list-style-type: lower-roman;
      ol {
        list-style-type: decimal-leading-zero;
      }
    }
  }
}

ul {
  ul {
    list-style-type: circle;
    ul {
      list-style-type: square;
    }
  }
}

.cb-content-area__main {
  .pane-node-body {
    // Fix margin overlap between lists and floated images
    ol, ul, dl {
      overflow: hidden;
    }
    // Styles for the ckeditor list wrap class.
    .cb-list-wrap {
      @include word-wrap($hyphens: true);
      overflow: visible;
      list-style-position: inside;
    }
    dl.cb-list-wrap {
      dt {
        font-weight: 700;
        &:not(:first-child) {
          margin-top: 1em;
        }
      }
      dd {
        margin-left: 0;
      }
    }
  }
}
