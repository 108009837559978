// Video Background Styles
// Available on homepage, featured layout regions, main feature region on all interior pages

// Default across all pages
.panels-ipe-video,
.pane-video {
  // No image fallback
  background: color(neutral-brand, xlo);
  h2.field-name-field-title {
    color: #fff;
    @include media(bp(s)) {
      text-shadow: 0 4px 4px color(neutral-brand, lo);
    }
  }
  a {
    // play btn
    &::before {
      box-shadow: 0 2px 2px 0 color(neutral-brand, lo), 0 0 2px 0 color(neutral-brand, lo);
    }
    // img gradient overlay
    &::after {
      background-color: transparentize(shade(color(primary, xlo), 80%), 0.8);
      @include media(bp(s)) {
        background-color: transparentize(shade(color(primary, xlo), 80%), 0.65);
      }
    }
  }
}

// Layout with sidebar
.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first,
  .cb-layout--aside-last,
  .cb-layout--aside-first-last {
    // video that's not in the hero area
    .cb-content-area__feature-rows {
      .panels-ipe-video.pane,
      .pane-video.pane {
        h2.field-name-field-title {
          @include media(bp(s)) {
            text-shadow: none;
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(2,3) {
  .panels-ipe-video,
  .pane-video {
    background: $component-bg-1-color;
    border-bottom: $border--xs;
    @include media(bp(s)) {
      border-bottom: none;
    }
    h2.field-name-field-title {
      color: color(neutral-brand, xxlo);
      @include media(bp(s)) {
        color: #fff;
      }
    }
  }
}


// News article hero video (markup differs slightly)
.node-type-news {
  .cb-feature-primary {
    .pane-node-field-news-video {
      background: color(neutral-brand, xlo);
      h2.field-name-field-title {
        color: #fff;
      }
      .field-name-field-video-image {
        a {
          // play btn
          &::before {
            box-shadow: 0 2px 2px 0 color(neutral-brand, lo), 0 0 2px 0 color(neutral-brand, lo);
          }
          // img gradient overlay
          &::after {
            background-color: transparentize(shade(color(primary, xlo), 80%), 0.75);
            @include media(bp(s)) {
              background-color: transparentize(shade(color(primary, xlo), 80%), 0.75);
            }
          }
        }
      }
    }
  }
}
