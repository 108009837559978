// Gateway Background Styles

// default styles across all pages
.cb-gateway {
  background: $component-bg-1-color;
  .cb-gateway {
    &__content {
      a {
        &:hover {
          text-decoration: underline;
        }
        &::before {
          text-decoration: none;
        }
      }
      a,
      a::before {
        color: a11yrize(color(action, m), $component-bg-1-color);
      }
    }
    &__title {
      // gateway title border underline
      &::after {
        background: var(--site-accent);
      }
    }
  }
}

@include component-region-bg-styles(2) {
  .cb-gateway {
    background: $component-bg-3-color;
    h2.cb-gateway__title {
      color: #fff;
      &::after {
        background: $component-bg-3-border-color;
      }
    }
    .cb-gateway {
      &__content {
        a,
        a::before {
          color: #fff;          }
        a {
          // display vague underline to make it more clear
          // that the list of white text are links
          text-decoration: underline;
          text-decoration-color: $component-bg-3-link-underline-color;
          &:hover {
            text-decoration-color: #fff;
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(3) {
  .cb-gateway {
    background: $component-bg-auto-color;
    .cb-gateway {
      &__content {
        a,
        a::before {
          color: color(action, m);
        }
      }
    }
  }
}

@include component-region-bg-styles(1) {
  .cb-gateway {
    background: $component-bg-2-color;
    h2.cb-gateway__title {
      color: #fff;
      &::after {
        background: $component-bg-2-border-color;
      }
    }
    .cb-gateway {
      &__content {
        a,
        a::before {
          color: a11yrize(color(action, m), $component-bg-2-color);
        }
        a {
          // display vague underline to make it more clear that the
          // the list of "white text" are links
          text-decoration: underline;
          text-decoration-color: $component-bg-2-link-underline-color;
          &:hover {
            text-decoration-color: a11yrize(color(action, m), $component-bg-2-color);
          }
        }
      }
    }
  }
}
