/// Mixin - Body Copy
@mixin body-copy() {
  font-family: $font--family--regular;
  font-weight: 400;
  color: $c-text;
}

@mixin main-text-size() {
  font-size: 1.125rem;
  @include media(bp(l)) {
    font-size: 1.25rem;
  }
}

body {
  @include body-copy();
  background-color: $text-body-bg;
  line-height: 1.7;
  // Fix Drupal WYSIWYG body input fields' background to be white
  // and not overriden by default body background styles.
  &.cke_editable { // stylelint-disable-line selector-class-pattern
    background: #fff;
  }
}

p {
  font-size: 1.125em;
  margin: 0 0 1em 0;
  @include media(bp(l)) {
    font-size: 1.25em;
  }
  a {
    font-family: $font--family--regular;
  }
  &.cb-attribution {
    font-size: 1.25em;
    text-align: left;
    font-weight: 700;
    color: $text-blockquote-color;
    font-style: italic;
    margin-bottom: 2rem;
    &::before {
      content: "\2014";
    }
  }
}

// main content
.cb-content-area__main {
  li, dl {
    p {
      font-size: 1em;
    }
  }
}

// Fix text size on courses descriptions when not wrapped in p tag.
.node-type-course .cb-content-area__main .field-name-body,
.node-type-course .cb-content-area__main .field-name-body p {
  font-size: 1.125rem;
}

// Main content, news article, structured content text and cta components
.cb-content-area__main .paragraphs-item-cta .field-name-field-cta-description p,
.cb-content-area__main .paragraphs-item-cta.cw-cta-phone .field-name-field-cta-link-prefix,
.cb-content-area__main .paragraphs-item-cta.cw-cta-phone .field-name-field-cta-link-suffix,
.cb-content-area__main .paragraphs-item-cta.cw-cta-phone .field-name-field-cta-link a,
.cb-content-area__main > .pane-node-body p,
.cb-content-area__main > .pane-bundle-text p,
.node-type-news .cb-feature-quaternary .cb-container__inner > .pane-node-body p,
.node-type-course .cb-content-area__main .field-name-body,
.node-type-course .cb-content-area__main .field-name-body p,
.cb-structured-content .field-name-field-page-paragraphs > .field-name-field-link-prefix,
.cb-structured-content .field-name-field-page-paragraphs > .field-name-field-link-suffix,
.cb-structured-content .field-name-field-page-paragraphs > .field-name-field-link-link,
.cb-structured-content .field-name-field-generic-paragraphs > .field-name-field-link-prefix,
.cb-structured-content .field-name-field-generic-paragraphs > .field-name-field-link-suffix,
.cb-structured-content .field-name-field-generic-paragraphs > .field-name-field-link-link {
  // increase body font size to ensure max characters
  // per line follow ux guidelines (76 char with no spaces)
  @include media(bp(s)) {
    font-size: 1.3rem;
  }
  @include media(bp(m)) {
    font-size: 1.4rem;
  }
  @include media(bp(l)) {
    font-size: 1.25rem;
  }
}

p + p {
  margin-top: -0.25em;
}

blockquote {
  font-style: italic;
  font-weight: 400;
  color: $text-blockquote-color;
  text-align: center;
  margin: 2rem 10%;
  p {
    &::before {
      content: "\201c";
    }
    &::after {
      content: "\201d";
    }
  }
  + .cb-attribution {
    margin-top: -1.5rem;
    text-align: center;
  }
}

.cb-content-area__main .pane-node-body,
.node-type-cups-profile {
  // shared blockquote styles between main content
  // and CUPS profile page (quote can exist with or without p tags)
  blockquote,
  blockquote p {
    font-size: 1.3125rem;
    @include media(bp(m)) {
      font-size: 1.4375rem;
    }
  }
}

.cb-content-area__main {
  .pane-node-body {
    .field-name-body {
      > blockquote {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

a {
  font-family: $font--text--alt;
  font-weight: 400;
  text-decoration: none;
  color: $text-link-color;
  // Normalize focus outlines for links.
  &:focus {
    outline: 1px dotted;
    outline-color: inherit;
    .cb-content-area__main & {
      outline-color: color(neutral-brand, m);
    }
  }
  .node-type-news .cb-feature-quaternary .pane-node-body li &,
  .cb-content-area__main > .pane-node-body li &,
  .cb-content-area__main .panels-ipe-portlet-content > .pane-node-body li &,
  .cb-content-area__main &,
  .cb-structured-content .field-name-field-page-paragraphs > .field-name-field-text-text &,
  .cb-structured-content .field-name-field-generic-paragraphs > .field-name-field-text-text &,
  .cb-structured-content .ui-accordion-content &,
  p & {
    color: $text-link-color-main-content-and-p;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $text-link-color-hover;
    }
    &.cb-button,
    &.btn {
      text-decoration: none;
      color: $btn-font-color;
    }
    &.cb-skip-link {
      color: color(action, lo);
      text-decoration: none;
      font-family: $font--text--alt;
    }
  }
  .cb-content-area__main ul & {
    text-decoration: none;
  }
  h1 &, h2 &, h3 &, h4 &, h5 &, h6 & {
    .cb-content-area__main & {
      text-decoration: none;
      font-weight: 600;
    }
  }
  .pager & {
    .cb-content-area__main & {
      text-decoration: none;
    }
  }
}

hr {
  border-top: $text-horizontal-rule-border;
  // Override border styles from system.theme.css
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  height: 0;
  clear: both;
  margin: 2.5em 0;
  .cke_editable & { // stylelint-disable-line selector-class-pattern
    border-color: color(neutral-brand, xxhi);
  }
}

// inline code wrappers, Markdown style
code {
  &::before {
    content: '`';
  }
  &::after {
    content: '`';
  }
  // don't show it when in a block of code
  pre & {
    &::after,
    &::before {
      content: '';
    }
  }
}
