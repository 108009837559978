// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing {
  .pane {
    &.cb-list-news--regular-stories {
      padding-top: 0;
      ul:not(.panels-ipe-linkbar) {
        margin-top: 0;
      }
    }
  }
}
