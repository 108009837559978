// Opening Hours Base Styles

.cw-opening-hours {
  display: block;
  margin: 0 auto;
  .field-name-field-summary {
    margin: $spacing--s auto 0 auto;
    padding: 0.9em 0 0 0;
    border-top: 2px solid;
    @include media(bp(bs)) {
      max-width: 22rem;
    }
  }
}

ul.cw-opening-hours__hours {
  display: block;
  margin: 0 auto;
  list-style: none;
  border-top: 2px solid;
  padding: $spacing--s 0;
  @include media(bp(bs)) {
    max-width: 22rem;
  }
  .cw-opening-hours {
    &__day {
      width: 100%;
      @include media(bp(bs)) {
        width: 40%;
      }
    }
    &__time {
      width: 100%;
      @include media(bp(bs)) {
        width: 60%;
      }
    }
  }
}

.region,
.cb-page-content .cb-content-area__main {
  .panels-ipe-bundle-place,
  .pane-bundle-place {
    .cw-opening-hours {
      li {
        padding-left: $spacing--s;
        padding-right: $spacing--s;
        &:nth-child(even) {
          padding-top: 0.25em;
          padding-bottom: 0.25em;
        }
      }
      .field-name-field-summary {
        margin-top: 0;
      }
    }
    ul.cw-opening-hours__hours {
      padding: 0;
      margin-top: $spacing--m;
      li {
        margin: 0.25em 0;
      }
    }
    ul.cw-opening-hours__hours,
    .cw-opening-hours .field-name-field-summary {
      @include media(bp(m)) {
        max-width: 28rem;
      }
    }
    .fieldable-panels-pane {
      @include component-padding();
    }
  }
}
