// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing {
  .region,
  .cb-content-area__aside-last {
    .pane {
      &.cb-gateway {
        padding: 0;
      }
    }
  }
  .region {
    .pane {
      &.cb-gateway {
        .panels-ipe-portlet-content {
          padding: $spacing--l;
        }
        .panels-ipe-dragbar {
          border: 1px solid #CCC;
        }
      }
    }
  }
  .cb-content-area__aside-last {
    .pane {
      &.cb-gateway {
        .panels-ipe-portlet-content {
          @include component-padding();
        }
      }
    }
  }
}
