// Layout overrides.

// This magic number is the propotionate percentage of the 1.7%
// component spacing, adjusted for the sidebar width.
// Needed to have equal page margins on featured layouts,
// since the component space dictates the left.
// i.e.: the main content is 65.54% of the full page width (from cola base)
// therefore the component spacing is 1.7% of those 65% since the components
// are children of the main content wrapper (or grandchildren of
// the full width wrapper).
// The sidebar percentages are based on 100% width since the sidebar is
// is a direct child of the full width wrapper.
// In order to get the same width as 1.7% of 65% we have to solve an equation
// 65.54622 * 0.017 = 100 * x
// (65.54622 * 0.017) / 100 = x = 0.0111428574 =~ 0.0111429 = 1.11429%
// Or simpler 65.54622 * 0.017 = 1.11428574 =~ 1.11429%
$featured-aside-last-right-margin: 65.54622% * decimalize($component-spacing); // ~1.11429%

.cb-page-content {
  padding: 0;
}

.cb-content-area {
  margin-top: 0;
  @include media(bp(xl)) {
    margin-top: 2em;
  }
  &__main,
  &__aside-last {
    margin-top: $spacing--xl;
    @include media(bp(xl)) {
      // This is to keep content level with the secondary navigation,
      // which has a top padding to extend the vertical rule.
      padding-top: 0.75em;
      margin-top: 0;
      .panels-ipe-editing & {
        padding-top: 0;
      }
    }
  }
  &__main {
    .pane-node-body {
      @include clearfix();
      &:not(:last-child) {
        margin-bottom: $spacing--xl;
        @include media(bp(m)) {
          margin-bottom: $spacing--xxl;
        }
      }
    }
    .panels-ipe-sort-container {
      .panels-ipe-portlet-wrapper:not(:last-child) {
        .pane-node-body {
          margin-bottom: $spacing--xxl;
        }
      }
    }
    // Add bottom spacing if structured content is not the last child
    > .cb-structured-content:not(:last-child),
    .panels-ipe-portlet-wrapper:not(:last-child) .cb-structured-content {
      margin-bottom: $spacing--xxl;
    }
    // This is needed to prevent bottom area of structured content
    // styles from being "cut off"
    + .cb-content-area__feature-rows {
      margin-top: $spacing--xl;
    }
  }
}

// Sidebar in the right rail only
// Default and featured layout 2-columns layout with no left nav
.cb-layout--aside-last {
  .cb-page-content {
    .cb-content-area__aside-last {
      // Add extra margin between main content and sidebar.
      // This essentially breaks the grid.
      // Should probably be moved to cola_base, but need to evaluate
      // how columbiadoctors might be affected.
      @include media(bp(xl)) {
        // Extra sidebar margin on large screens.
        padding-left: calc(2.5em - #{$featured-aside-last-right-margin});
        padding-right: $featured-aside-last-right-margin;
        margin-top: 0;
      }
    }
  }
}

// Sidebar at the bottom of page
// Default and featured layout with 3-columns
.cb-layout--aside-first-last {
  .cb-content-area__aside-last {
    @include media(bp(l)) {
      margin-top: 3.125em;
    }
  }
  .cb-page-content.cb-page-content--feature-rows {
    .cb-content-area__aside-last {
      @include media(bp(xl)) {
        padding-left: $component-spacing;
        padding-right: $component-spacing;
      }
    }
  }
  // basic page: right rail is displayed on large desktop
  .cb-page-content:not(.cb-page-content--feature-rows) {
    .cb-content-area__aside-last {
      @include media(bp(xl)) {
        margin-top: 0;
      }
    }
  }
}

main.cb-content {
  // IE flexbox fix.
  min-height: 1px;
  padding-bottom: 3.125em;
  @include media(bp(l)) {
    padding-top: 0;
    padding-bottom: 6.25em;
  }
}

.cb-page-wrapper {
  width: 100%;
  background-color: #fff;
  z-index: 1;
}

.cb-nameplate .cb-container__inner,
.cb-flavor--default .cb-primary-navigation .cb-container__inner,
.cb-flavor--mega-menu .cb-primary-navigation .cb-container__inner,
.cb-flavor--sections .cb-primary-navigation .cb-section-menu,
.cb-flavor--subsection .cb-subsection-navigation .cb-container__inner,
.cb-page-content .cb-feature-area__feature-primary .pane:only-child {
  @include media(bp(xl)) {
    max-width: $page-container-max-width;
    margin: 0 auto;
  }
}

.cb-nameplate {
  .cb-container__inner,
  .cb-global-menu {
    @include media(bp(l)) {
      padding: 0 $page-margins;
    }
    @include media(1250px) {
      padding: 0 1.875em;
    }
    @include media(#{(bp(xxl) + 1.875em)}) {
      padding: 0;
    }
  }
}

.cb-content-area__main {
  .cb-structured-content{
    clear: both;
  }
  .cb-flavor:not(.cb-list-news) & {
    // add default content max width to ensure
    // max characters per line follow ux guidelines
    @include media($bp-only-m) {
      max-width: $component-content-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

body .cb-site-footer {
  .cb-global-footer {
    .cb-container__inner {
      max-width: 100%;
      padding: 0;
    }
  }
}

.max-page-width {
  @include max-page-width();
}
