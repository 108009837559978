.cb-list-news--related-news {
  background-color: $background-solid-xlight;
  display: block;
  @include component-padding();
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  .view-content {
    margin-bottom: 0;
    > ul {
      max-width: 100%;
      li {
        padding-top: $spacing--l;
        margin-bottom: $spacing--m;
        border-top: $border--s;
        border-color: $sidebar-component-border-color;
        &:first-child {
          border-top: $border--l;
          border-color: $sidebar-component-border-color;
        }
        &:last-child {
          border-bottom: $border--m;
          border-color: $sidebar-component-border-color;
          padding-bottom: $spacing--m;
        }
      }
      .cb-layout__component-dynamic {
        overflow: hidden;
        // fix hidden focus outlines
        padding: 2px;
      }
    }
    li {
      .cb-list-news {
        /* stylelint-disable selector-max-compound-selectors */
        &__best-category {
          order: 3;
        }
        &__title {
          order: 4;
        }
        &__external-source {
          order: 5;
        }
        &__byline {
          order: 6;
        }
        &__date {
          order: 7;
        }
        &__summary {
          order: 8;
        }
        /* stylelint-enable */
      }
    }
  }
  .pager {
    margin: $spacing--m 0 0 0;
    li.pager-current {
      width: auto;
    }
  }
}

// Typography
.cb-list-news--related-news {
  h2.pane-title {
    text-align: center;
    margin-bottom: $spacing--s;
    @include related-component-heading($size: l);
    @include icon($icon: "news", $pseudo: "before", $size: 1em) {
      margin-top: -0.2em;
      color: a11yrize(color(primary), $background-solid-xlight);
    }
  }
  .cb-list-news {
    &__content {
      max-width: $sidebar-component-content-max-width;
      margin: 1.25rem auto 0 auto;
      // Prevent excess space in tweener IEs.
      display: block;
    }
    &__title {
      h3 {
        @include component-link-title(s);
        @include media(bp(s)) {
          @include component-link-title(m);
        }
        a {
          color: a11yrize(color(primary), $background-solid-xlight);
          font-family: $font--display;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__best-category {
      background: $background-solid-xlight;
      color: a11yrize($news-promoted-category-color, $background-solid-xlight);
      a {
        color: a11yrize(color(primary), $background-solid-xlight);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__summary {
      p {
        @include component-paragraph(s);
        color: a11yrize(color(neutral-brand, lo), $background-solid-xlight);
      }
    }
    &__date {
      color: a11yrize(color(neutral-brand), $background-solid-xlight);
    }
  }
  .more-link {
    text-align: center;
    a {
      @include btn($size: s);
      @include icon($icon: "arrow-right", $pseudo: after) {
        margin-left: 0.125em;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        font-size: 0.8em;
        padding-bottom: 0.1em;
      }
      @include media(bp(m)) {
        font-size: 1.1rem;
      }
      @include media(bp(l)) {
        font-size: 1rem;
      }
      @media (hover: hover) {
        &:hover::after {
          @include animated($duration: 1s, $name: wobble);
        }
      }
    }
  }
}
