// CTA Base List Styles ("not:only-child" in a region)

.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane:not(:only-child) &,
    &.pane:not(:only-child) {
      background: tint($background-solid-xlight, 70%);
      position: relative;
      max-width: 100%;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
      margin-left: 1.7%;
      margin-right: 1.7%;
      .cw-cta__overlay-link {
        // necessary to appear above before and after pseudo element (bg gradients)
        z-index: #{z(content) + 5};
      }
      .field-name-field-cta-image {
        box-shadow: none;
        max-width: 100%;
        margin: 0;
      }
      .panels-ipe-handlebar-wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: z(tooltip);
        .panels-ipe-dragbar {
          background: rgba(255, 255, 255, 0.9);
        }
      }
      .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone),
      .paragraphs-item-cta.cw-with-icon:not(.cw-cta-phone) {
        overflow: visible;
        .content {
          /* stylelint-disable selector-max-compound-selectors */
          > * {
            overflow: visible;
          }
          .field-name-field-cta-image,
          .field-name-field-icon {
            order: 1;
            overflow: hidden;
          }
          .field-name-field-title {
            order: 2;
          }
          .field-name-field-cta-description {
            order: 3;
          }
          .field-name-field-cta-link {
            order: 4;
          }
          /* stylelint-enable */
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          z-index: z(content);
        }
        &::before {
          background: rgba(45, 46, 49, 0.15);
          height: 100%;
          width: 100%;
          transition: background-color 0.5s ease;
          bottom: 0;
        }
        &::after {
          background: linear-gradient(rgba(0,0,0,0),rgba(45,46,49,1));
          width: 100%;
          bottom: 0;
        }
        &.cw-with-image,
        &.cw-with-icon {
          background: transparent;
        }
      }
      // fix ipe alignment issue
      .panels-ipe-portlet-content {
        .paragraphs-item-cta:not(.cw-cta-phone) {
          /* stylelint-disable selector-max-compound-selectors */
          .field-name-field-title {
            bottom: 5em;
          }
          /* stylelint-enable */
        }
        .pane-bundle-cta {
          background: transparent;
        }
      }
    }
  }
}
