// Related Profiles "full styles"
// (image and name is displayed with position)
// Pages: Clinic, Treatments & Conditions, News Article

.cb-list-profiles--related-profiles.cb-list-profiles--full,
.cb-list-profiles--by-clinic {
  ul:not(.pager) {
    margin: 0;
    list-style: none;
    li {
      width: 100%;
      margin-right: 0;
      &:last-child {
        &:nth-child(2):last-child {
          width: 100%;
        }
      }
      &:first-child {
        &:nth-last-child(2) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .cb-panel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 72px;
    position: relative;
    min-height: 90px;
  }
  .cb-list-profiles {
    &__title {
      margin-bottom: 0.2em;
      width: 100%;
      h3 {
        a {
          @include media(bp(s)) {
            @include component-link-title(m);
          }
        }
      }
    }
    &__headshot {
      .field-name-field-cups-headshot {
        order: 1;
        margin-right: 1.2rem;
        width: 60px;
        height: 90px;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        img {
          min-height: 90px;
        }
      }
    }
    &__positions {
      order: 3;
      width: 100%;
      .item-list {
        ul {
          padding: 0;
          /* stylelint-disable selector-max-compound-selectors */
          li {
            margin: 0;
            width: 100%;
            line-height: 1.3;
            font-size: 0.8rem;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

.cb-list-profiles--by-clinic {
  .cb-panel-container {
    margin-bottom: 1em;
  }
}

.cb-list-profiles--related-profiles.cb-list-profiles--full {
  .view-content {
    > ul {
      > li {
        padding: $spacing--s;
        &:not(:last-child) {
          margin-bottom: $spacing--m;
        }
      }
      ul {
        padding: 0;
        /* stylelint-disable selector-max-compound-selectors */
        li {
          padding-left: 0;
          padding-right: 0;
        }
        /* stylelint-enable */
      }
    }
    // with pagination
    &:nth-last-child(3) {
      > ul {
        padding-bottom: $spacing--s;
      }
    }
  }
  .cb-panel-container {
    margin-bottom: 0;
    > div {
      &:first-child {
        margin-top: $spacing--s;
      }
      &:last-child {
        margin-bottom: $spacing--s;
      }
    }
  }
  .more-link {
    text-align: center;
    line-height: 1.25;
    border-top: $border--xs;
    padding: $spacing--s;
    a {
      @include more-link($margin-left: 0);
      @include media(bp(m)) {
        @include component-link-title(m);
      }
    }
  }
  .cb-list-profiles {
    &__title {
      a {
        color: a11yrize(color(action, m), $background-solid-light);
      }
    }
    &__content {
      // pagination
      > .item-list {
        padding-bottom: 0;
        border-top: none;
        @include media(bp(l)) {
          padding: 0;
        }
      }
    }
  }
  // pagination
  .item-list:not(:only-child) {
    ul.pager {
      @include media(bp(l)) {
        padding-bottom: $spacing--m;
      }
    }
  }
}
