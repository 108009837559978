// CTA Phone Base Styles

.paragraphs-item-cta {
  &.cw-cta-phone {
    .content {
      .field-name-field-cta-link-prefix,
      .field-name-field-cta-link {
        @include icon($icon: 'phone-outline', $size: 1.6rem);
        &::before {
          margin-right: 0.2em;
          margin-bottom: $spacing--s;
          display: block;
          // prevent phone icon from being cut off
          padding-top: 0.1em;
          @include media(bp(m)) {
            display: inline-block;
            font-size: 2.2rem;
            margin-bottom: 0;
          }
        }
      }
      .field-name-field-cta-link-prefix {
        + .field-name-field-cta-link {
          &::before {
            content: '';
            display: none;
            margin-right: 0;
          }
        }
      }
    }
  }
}

// Override default cta styles
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    &.cb-style {
      .paragraphs-item-cta.cw-cta-phone {
        /* stylelint-disable selector-max-compound-selectors */
        .content {
          @include media(bp(m)) {
            padding: 0;
          }
          > .field-name-field-cta-link {
            overflow: visible;
          }
        }
        &.cw-with-title {
          .field-name-field-cta-description {
            p,
            a {
              @include media(bp(l)) {
                font-size: 1.3rem;
              }
            }
          }
        }
        &:not(.cw-with-title) {
          .field-name-field-cta-description {
            p,
            a {
              @include media(bp(l)) {
                font-size: 1.4rem;
              }
            }
          }
        }
        .cw-cta__overlay-link {
          display: none;
        }
      }
      &:only-child {
        .paragraphs-item-cta.cw-cta-phone {
          padding-top: 0;
          padding-bottom: 0;
          .content {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
      /* stylelint-enable */
    }
    &.cb-style,
    .cb-content-area__main & {
      .cw-cta-phone {
        .content {
          display: block;
        }
        .field-name-field-cta-link-prefix,
        .field-name-field-cta-link,
        .field-name-field-cta-link-suffix,
        a {
          display: inline;
          margin-left: 0;
          margin-right: 0;
        }
      }
      &:last-child:not(:first-child) {
        .cw-cta-phone {
          .content {
            border-top: $border--s;
            margin-top: 2em;
            padding-top: 2rem;
          }
        }
      }
    }
  }
}
