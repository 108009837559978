.paragraphs-item-map {
  .content {
    .field-name-field-map-embed {
      position: relative;
      iframe {
        // Height adjustments to approx 16:9 (tablet, desktop)
        height: 19em;
        width: 100%;
        @include media(bp(s)) {
          height: 24em;
        }
        @include media(bp(m)) {
          height: 30em;
        }
      }
    }
  }
  &:not(:only-child) {
    margin-bottom: 3.3em;
  }
  &:not(:first-child) {
    margin-top: 3em;
  }
}
