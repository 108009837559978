// Academic Programs Search list styles

// Search box
.view-cola-programs-search {
  // Filters
  .views-widget-per-page {
    @include span-columns(12);
    select {
      width: 100%;
    }
  }
  .views-exposed-widget {
    // Submit and clear all buttons
    &.views-submit-button,
    &.views-reset-button {
      width: 48.3%;
      clear: both;
      margin-top: 1em;
      @include media(bp(s)) {
        width: auto;
      }
      .form-submit {
        width: 100%;
        margin-top: 0;
        @include media(bp(s)) {
          width: auto;
        }
      }
    }
    &.views-reset-button {
      .form-submit {
        background: #fff;
        color: $text-link-color;
      }
    }
    &.views-submit-button + .views-reset-button,
    &.views-reset-button + .views-submit-button {
      clear: none;
      margin-left: 3%;
      @include media(bp(m)) {
        margin-left: 0.75em;
      }
    }
  }
}

// Search list
.cb-list-programs--search.cb-list-programs--standard {
  .view-header {
    margin-bottom: 2em;
  }
  .view-content {
    .cb-layout__component-dynamic {
      display: flex;
      flex-direction: column;
      @include media(bp(s)) {
        display: block;
      }
      .column-first {
        order: 2;
      }
      .column-second {
        order: 1;
        margin: 0 0 1em 0;
      }
    }
    > ul {
      > li {
        &:first-child {
          border-top: none;
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 1.75em;
          border-bottom: $border--m;
        }
      }
    }
  }
  ul.pager {
    li {
      border: none;
    }
  }
}
