// Component Link Title Mixin
// e.g. used for news/events link titles, gateways list of links

@mixin component-link-title($size: m) {
  @if $size == xxs {
    font-size: 0.8rem;
    line-height: 1.2;
    line-height: 1.15;
    font-weight: 600;
  }
  @else if $size == xs {
    font-size: 0.9rem;
    line-height: 1.15;
    font-weight: 600;
  }
  @else if $size == s {
    font-size: 1rem;
    line-height: 1.15;
    font-weight: 600;
  }
  @else if $size == m {
    font-size: 1.25rem;
    line-height: 1.25;
    font-weight: 600;
  }
  @else if $size == l {
    font-size: 1.4rem;
    line-height: 1.25;
    font-weight: 600;
  }
  @else if $size == xl {
    font-size: 1.6rem;
    line-height: 1.25;
    font-weight: 600;
  }
  @else {
    @warn 'Invalid size argument: #{$size}';
  }
}

@mixin component-link-title-right-rail() {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 600;
}
