// Appointments with find a doc link: Homepage and Featured layout only

// if find a doc link is not an only-child
.region {
  .cb-appointments.cw-with-doctors-link {
    &.cw-with-title,
    &.cw-with-description,
    &.cw-with-phone-link,
    &.cw-with-book-online-link,
    &.cw-with-telehealth-link,
    &.cw-with-info-link {
      .cb-appointments {
        @include media($bp-only-l) {
          &__link--doctors {
            width: 25%;
          }
          &__title,
          &__content {
            width: 74%;
          }
        }
      }
    }
    .cb-appointments {
      &__link {
        @include quantities-all(5,3) {
          &:not(.cb-appointments__link--doctors) {
            @include media(bp(l)) {
              width: 49.4%;
              float: left;
              &:first-child,
              &:nth-child(3) {
                margin-right: 0.4rem;
              }
              a {
                font-size: 0.9rem;
              }
            }
            @include media(bp(xl)) {
              a {
                font-size: 1rem;
              }
            }
          }
        }
        @include quantities-all(4) {
          &:not(.cb-appointments__link--doctors) {
            @include media(bp(xl)) {
              width: 32.8%;
              float: left;
              &:first-child,
              &:nth-child(2) {
                margin-right: 0.4rem;
              }
              a {
                font-size: 0.89rem;
              }
            }
          }
        }
      }
    }
    // if find a doc is displayed with only one other appointment link
    &.cw-with-phone-link:not(.cw-with-book-online-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
    &.cw-with-book-online-link:not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
    &.cw-with-telehealth-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-info-link),
    &.cw-with-info-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
    &.cw-with-title:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
    &.cw-with-description:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link) {
      .cb-appointments {
        @include media(bp(l)) {
          &__title,
          &__content {
            width: 58%;
          }
          &__link--doctors {
            width: 40%;
          }
        }
      }
      .cb-layout--aside-first &,
      .cb-layout--aside-last &,
      .cb-layout--aside-first-last & {
        .cb-flavor:not(.cb-flavor--subsection-front) & {
          .cb-appointments {
            @include media(bp(xl)) {
              &__title,
              &__content {
                width: 68%;
              }
              &__link--doctors {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
}

// if find a doc is the only link displayed
.region {
  .cb-appointments.cw-with-doctors-link {
    &:not(.cw-with-book-online-link):not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-title):not(.cw-with-description) {
      padding: 0;
      .cb-appointments__link--doctors,
      .cw-appointments__overlay-link {
        height: 6em;
        @include media(bp(bs)) {
          height: 10em;
        }
      }
      .cb-appointments__link--doctors {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        a:not(.cw-appointments__overlay-link) {
          @include btn($style: outline, $size: s, $dark: true);
          @include override-nested() {
            display: flex;
            flex-direction: row;
            width: auto;
            margin: 0 0 0 5.8em;
            padding: 0 0.6em;
            font-size: 1rem;
          }
          @include media(bp(bs)) {
            @include component-heading(s);
            margin-bottom: 0;
          }
          @include media(bp(s)) {
            height: 44px;
          }
          &::before {
            margin: 0 0.6em 0 -1.6em;
            font-size: 3.5em;
          }
          &:hover {
            background: #fff;
            color: color(neutral-brand, xxlo);
          }
        }
        .cw-appointments__overlay-link {
          background: $component-bg-2-color;
        }
      }
    }
  }
}

// layout with no sidebars
.front,
.cb-layout--no-aside {
  .region {
    .cb-appointments.cw-with-doctors-link {
      .cb-appointments {
        @include media(bp(xl)) {
          &__link {
            @include quantities-all(4) {
              &:not(.cb-appointments__link--doctors) {
                @include media(bp(xl)) {
                  /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                  a {
                    font-size: 0.89rem;
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
        }
      }
      &.cw-with-title,
      &.cw-with-description,
      &.cw-with-phone-link,
      &.cw-with-book-online-link,
      &.cw-with-telehealth-link,
      &.cw-with-info-link {
        .cb-appointments {
          @include media(bp(xl)) {
            &__link--doctors {
              width: 18%;
            }
            &__title,
            &__content {
              width: 82%;
            }
          }
        }
      }
      // three appointment links (incl. book online)
      &.cw-with-book-online-link.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
      &.cw-with-book-online-link.cw-with-phone-link.cw-with-info-link:not(.cw-with-telehealth-link),
      &.cw-with-book-online-link.cw-with-phone-link.cw-with-telehealth-link:not(.cw-with-info-link),
      &.cw-with-book-online-link.cw-with-telehealth-link.cw-with-info-link:not(.cw-with-phone-link) {
        @include media(bp(xl)) {
          .cb-appointments__link {
            &:not(.cb-appointments__link--doctors) {
              width: 30%;
            }
            &.cb-appointments__link--book-online {
              width: 38.5%;
            }
          }
        }
      }
      // if find a doc is displayed with only one other appointment link
      &.cw-with-phone-link:not(.cw-with-book-online-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-book-online-link:not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-telehealth-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-info-link),
      &.cw-with-info-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
      &.cw-with-title:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
      &.cw-with-description:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link) {
        .cb-appointments {
          @include media(bp(l)) {
            &__link--doctors {
              a:not(.cw-appointments__overlay-link) {
                @include component-heading(s);
                width: 210px;
                line-height: 0.85;
              }
            }
          }
        }
      }
    }
  }
}
