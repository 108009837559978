// Promoted Blog Only styles depending on list number

.cb-flavor:not(.cb-list-news) {
  .region {
    .cb-list-news--promoted.cb-list-news--blog,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
      @include media(bp(bs)) {
        ul:not(.panels-ipe-linkbar) {
          display: flex;
          flex-wrap: wrap;
          margin: $spacing--m 0;
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          li {
            @include quantities-all(1) {
              width: 100%;
            }
            @include quantities-all(2,4) {
              @include span-columns(6);
              .cb-layout__component-dynamic {
                display: flex;
                flex-direction: column;
              }
              .column-first.column--first,
              .column-second.column--last {
                width: 100%;
              }
              .column-first.column--first {
                order: 2;
              }
              .column-second.column--last {
                order: 1;
              }
              .columns-1 {
                .cb-list-news {
                  // Display summary when news or blog item has no image
                  &__summary {
                    @include visually-hidden--off();
                    display: block;
                    margin-top: 0.5em;
                  }
                }
              }
            }
            @include quantities-all(2) {
              margin-bottom: 0;
              border-bottom: none;
              padding: 0;
              @include omega();
              .cb-list-news {
                &__image-teaser {
                  margin-top: 0;
                }
              }
            }
            @include quantity-positions(2,1) {
              margin-right: 1.6%;
            }
            @include quantity-positions(2,2) {
              margin-left: 1.6%;
            }
            @include quantity-positions(4,1,3) {
              margin-left: 0;
              margin-right: 1.6%;
            }
            @include quantity-positions(4,2,4) {
              margin-right: 0;
              margin-left: 1.6%;
            }
            @include quantity-positions(4,1,2) {
              margin-bottom: 3%;
            }
            @include quantity-positions(4,3,4) {
              margin-bottom: 0;
            }
            @include quantities-all(3) {
              width: 100%;
              .cb-layout__component-dynamic {
                display: flex;
                flex-direction: row;
              }
              .column-first.column--first:not(:only-child) {
                order: 1;
                width: 70%;
                padding-right: 0;
              }
              .column-second.column--last {
                order: 2;
                width: 30%;
                padding: $spacing--m;
              }
            }
          }
          /* stylelint-enable */
        }
      }
      @include media(bp(m)) {
        ul:not(.panels-ipe-linkbar) {
          margin: 0;
          li {
            /* stylelint-disable selector-max-compound-selectors */
            @include quantities-all(1) {
              @include span-columns(12);
              .column-first.column--first:not(:only-child),
              .column-second.column--last {
                @include span-columns(6);
              }
              .column-first.column--first,
              .column-first.column--first:not(:only-child) {
                display: flex;
                padding: 0;
              }
              .cb-layout__component-dynamic {
                padding: $spacing--l;
                display: flex;
                align-items: center;
                flex-direction: row;
              }
              .column-first {
                order: 1;
              }
              .column-second {
                order: 2;
              }
              // no image
              .column-first.column--first:only-child {
                max-width: $component-content-max-width;
                margin: 0 auto;
              }
            }
            @include quantities-all(3) {
              .cb-layout__component-dynamic {
                display: flex;
                flex-direction: column;
              }
              .column-first.column--first:not(:only-child) {
                order: 2;
                padding: $spacing--l;
                width: 100%;
              }
              .column-second.column--last {
                order: 1;
                padding: 0;
                width: 100%;
              }
            }
            @include quantities-all(4) {
              display: flex;
              flex: 1 0 auto;
              flex-direction: column;
            }
            /* stylelint-enable */
          }
        }
      }
      @include media(bp(l)) {
        ul:not(.panels-ipe-linkbar) {
          li {
            /* stylelint-disable selector-max-compound-selectors */
            @include quantities-all(1) {
              .cb-layout__component-dynamic {
                padding: $spacing--xl;
              }
            }
            @include quantities-all(4) {
              @include span-columns(3) {
                padding-bottom: 0;
                margin: 0 1.6%;
              }
            }
            @include quantity-positions(4,1) {
              margin-left: 0;
            }
            @include quantity-positions(4,4) {
              margin-right: 0;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  &.cb-flavor:not(.cb-list-news) {
    .region {
      .cb-list-news--promoted.cb-list-news--blog,
      .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
        @include media(bp(l)) {
          ul:not(.panels-ipe-linkbar) {
            li {
              @include quantities-all(2) {
                /* stylelint-disable selector-max-compound-selectors */
                .column-first.column--first,
                .column-first.column--first:not(:only-child) {
                  padding: $spacing--xl;
                }
                /* stylelint-enable */
              }
              @include quantities-all(4) {
                /* stylelint-disable selector-max-compound-selectors */
                .column-first.column--first,
                .column-first.column--first:not(:only-child) {
                  padding: $spacing--m;
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
  }
}

// Main Content Only
// Display all list items stacked
.cb-flavor:not(.cb-list-news) {
  .cb-content-area__main {
    .cb-list-news--promoted.cb-list-news--blog,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
      @include media(bp(bs)) {
        ul:not(.panels-ipe-linkbar) {
          display: flex;
          flex-flow: column wrap;
          margin: 0;
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          li {
            .cb-layout__component-dynamic {
              display: flex;
              flex-direction: row;
              max-width: $component-content-max-width;
              margin: 0 auto;
            }
            .column-first.column--first:not(:only-child) {
              order: 1;
              width: 70%;
              padding-right: 0;
            }
            .column-second.column--last {
              order: 2;
              width: 30%;
              padding: $spacing--l;
            }
            .columns-1 {
              .cb-list-news {
                // Hide summary
                &__summary {
                  @include visually-hidden();
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
