// Featured Content Sidebar Styles

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.pane-bundle-featured-content.cb-style,
      &.panels-ipe-bundle-featured-content {
        @include component-sidebar-padding();
        @include override-nested() {
          padding-bottom: 1.2em;
          background: $sidebar-component-bg-color;
        }
        &:hover {
          @include component-bg-hover-styles($sidebar-component-bg-color, $change: 'darken');
        }
        h3 {
          color: $sidebar-component-title-color;
          @include component-heading(s);
          @include media(bp(bs)) {
            @include component-heading(m);
          }
          @include media(bp(m)) {
            @include component-heading(l);
          }
        }
        .pane-node-field-subtitle h4 {
          color: $sidebar-component-subtitle-color;
          @include component-subtitle(xs);
          @include media(bp(m)) {
            @include component-subtitle(s);
          }
        }
        .field-name-field-summary {
          color: $sidebar-component-font-color;
          @include component-paragraph(xs);
          @include media(bp(m)) {
            @include component-paragraph(s);
          }
        }
        .pane-node-links {
          a {
            font-size: 1rem;
            font-weight: 700;
            color: $sidebar-component-link-color;
            @include icon($icon: "arrow-right", $pseudo: "after", $size: 0.9rem) {
              margin-left: 0.5em;
            }
            &:hover::after {
              @include animated($duration: 1s, $name: wobble);
            }
          }
        }
        .field-name-title-field {
          a {
            color: $sidebar-component-link-color;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// Component specific sidebar styles
.cb-content-area__aside-last {
  .paragraphs-items-field-featured-content-paragrah,
  .paragraphs-item-featured-content {
    max-width: 47.5em;
    margin: 0 auto;
    padding-top: 0;
    .content {
      max-width: $sidebar-component-content-max-width;
      margin: 0 auto;
    }
    &:not(.cw-with-image) {
      .pane-node-title-field,
      .pane-node-field-subtitle,
      .pane-node-field-summary {
        padding: 0;
      }
    }
    &.cw-with-image {
      .column-first {
        > *:not(.pane-node-links) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .column-second {
        @include media(bp(s)) {
          padding-bottom: 0;
        }
      }
    }
    .panelizer-view-mode {
      padding-top: 0;
    }
    .field-name-field-summary {
      padding-bottom: 0;
    }
    .column-first {
      @include media(bp(s)) {
        padding-bottom: 1em;
      }
    }
  }
  .panels-ipe-bundle-featured-content,
  .pane-bundle-featured-content.cb-style {
    .panelizer-view-mode {
      padding-top: 0;
    }
    .field-name-field-summary {
      padding-bottom: 1rem;
    }
    .pane-node-links {
      border-color: $sidebar-component-border-color;
      padding-bottom: 0;
    }
  }
  .pane-bundle-featured-content.cb-style,
  .panels-ipe-bundle-featured-content {
    .pane-node-links {
      padding-right: 0;
    }
    @include media(bp(bs)) {
      .paragraphs-item-featured-content {
        &.cw-with-image {
          .cb-layout__component-dynamic {
            flex-direction: row;
          }
          .column-first {
            @include span-columns(8) {
              order: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
          .column-second {
            order: 2;
            margin-left: $spacing--m;
            padding-right: 0;
            @include span-columns(4);
          }
        }
      }
    }
    &:only-child {
      @include media(bp(l)) {
        max-width: 100%;
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.panels-ipe-bundle-featured-content,
        &.pane-bundle-featured-content.cb-style {
          padding: $sidebar-component-right-rail-padding;
          .panelizer-view-mode {
            padding-bottom: 0;
            padding-top: 0;
          }
          h3,
          .field-name-field-summary,
          h4 {
            text-align: center;
          }
          .field-name-field-summary {
            padding-bottom: 0;
            text-align: center;
            @include component-paragraph-right-rail();
          }
          h3 {
            @include component-heading-right-rail();
          }
          .pane-node-field-subtitle {
            /* stylelint-disable selector-max-compound-selectors */
            h4 {
              font-size: 1rem;
            }
            /* stylelint-enable */
          }
          .pane-node-links {
            text-align: center;
            padding: 0;
            margin-top: 1em;
            border-top: none;
            position: static;
          }
          .node-readmore {
            /* stylelint-disable selector-max-compound-selectors */
            a {
              font-size: 1rem;
            }
            /* stylelint-enable */
          }
        }
        .paragraphs-items-field-featured-content-paragrah,
        .paragraphs-item-featured-content {
          /* stylelint-disable selector-max-compound-selectors */
          .column-first {
            padding-bottom: 0;
            > *:not(.pane-node-links) {
              padding-right: 0;
            }
          }
          .column-second,
          .column-first {
            width: 100%;
          }
          &.cw-with-image {
            .cb-layout__component-dynamic {
              flex-direction: column;
            }
            .column-second,
            .column-first {
              width: 100%;
            }
            .column-second {
              order: 1;
              padding-left: 0;
              padding-right: 0;
              padding-bottom: 1rem;
              margin-left: 0;
            }
            .column-first {
              order: 2;
            }
            .column-second {
              padding-bottom: 1rem;
            }
          }
          .panelizer-view-mode {
            padding-bottom: 0;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
