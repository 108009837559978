// Promoted Announcements styles depending on number of list items

// Default across all pages
.region,
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    li {
      &:only-child {
        .column-second-empty {
          /* stylelint-disable selector-max-compound-selectors */
          .cb-list-news__title {
            max-width: 100%;
          }
          /* stylelint-enable */
        }
      }
    }
    @include media(bp(bs)) {
      // Display image and text side-by-side
      .cb-layout__component-dynamic {
        flex-direction: row;
        // fix inconsistent image alignment
        width: 100%;
        .column--first {
          order: 1;
          &:not(:only-child) {
            margin-right: $spacing--s;
          }
        }
        .column--last {
          order: 2;
        }
      }
      li {
        .columns-2.column--first {
          &:only-child {
            width: 100%;
          }
          &:not(:only-child) {
            @include span-columns(9);
          }
        }
        .columns-2.column--last {
          @include span-columns(3);
        }
      }
    }
    @include media(bp(m)) {
      li {
        .columns-2.column--first {
          &:not(:only-child) {
            @include span-columns(8);
          }
        }
        .columns-2.column--last {
          @include span-columns(4);
        }
        @include quantities-all(1) {
          @include span-columns(12);
          .column-first.column--first:not(:only-child) {
            @include span-columns(8);
          }
          .column-second.column--last {
            @include span-columns(4);
          }
          .column-first.column--first,
          .column-first.column--first:not(:only-child) {
            display: flex;
            padding: 0;
          }
          .cb-layout__component-dynamic {
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          .column-first {
            order: 1;
          }
          .column-second {
            order: 2;
          }
          // no image
          .column-first.column--first:only-child {
            max-width: $component-content-max-width;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

// Home and featured layout
.region {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    @include media(bp(m)) {
      li {
        @include quantity-positions(2,2) {
          padding-top: 0;
        }
        @include quantities-all(2) {
          border-top: none;
          padding-bottom: 0;
        }
        @include quantity-positions(2,1) {
          &:first-child {
            @include component-divider($right-alignment: -1rem);
          }
        }
        @include quantities-all(2,4) {
          @include span-columns(6) {
            display: flex;
          }
          // Fix IE10 issue
          .feature-empty {
            @include span-columns(12);
          }
        }
        @include quantity-positions(4,1,3) {
          @include component-divider($right-alignment: -1rem);
        }
        @include quantity-positions(4,3) {
          &::after {
            top: -0.1rem;
          }
        }
        @include quantity-positions(4,2) {
          padding-top: 0;
          border-top: none;
          margin-right: 0;
        }
        @include quantity-positions(4,3) {
          clear: both;
        }
        @include quantity-positions(4,3,4) {
          padding-bottom: 0;
        }
      }
    }
    @include media(bp(l)) {
      li {
        @include quantities-all(3) {
          border-top: none;
          padding-top: 0;
          padding-bottom: 0;
          @include span-columns(4);
          .cb-layout__component-dynamic {
            // fix ie10 image alignment issue
            display: block;
          }
        }
        @include quantity-positions(3,3) {
          @include omega();
        }
        @include quantity-positions(3,1,2) {
          @include component-divider($right-alignment: -1.3rem);
        }
        // 5 items: display first-child full width
        @include quantity-positions(5,2,3,4,5) {
          @include span-columns(6) {
            display: flex;
          }
          .cb-layout__component-dynamic {
            display: block;
          }
        }
        @include quantity-positions(5,1) {
          border-bottom: $border--xs;
          margin-bottom: $spacing--m;
          width: 100%;
        }
        @include quantity-positions(5,2,4) {
          @include component-divider($right-alignment: -1rem);
        }
        @include quantity-positions(5,3,5) {
          &::after {
            top: -0.1rem;
          }
        }
        @include quantity-positions(5,2,3) {
          padding-top: 0;
          border-top: none;
        }
        @include quantity-positions(5,3) {
          @include omega();
        }
        @include quantity-positions(5,5) {
          padding-bottom: 0;
        }
      }
    }
  }
}

// Main Content Only
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    li {
      // display list items stacked
      @include media(bp(m)) {
        @include quantities-all(5,4,3,2,1) {
          .columns-2.column--first {
            &:not(:only-child) {
              @include span-columns(9);
            }
          }
          .columns-2.column--last {
            @include span-columns(3);
          }
        }
      }
      @include media(bp(l)) {
        margin-bottom: 0;
        @include quantities-all(5,4,3,2,1) {
          &:not(:first-child) {
            border-top: $border--xs;
          }
        }
      }
      @include quantities-all(5,4,3,2,1) {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}
