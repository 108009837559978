@include component-region-bg-styles(1,2,3) {
  &.cb-padding--auto {
    .cb-epic-scheduling {
      background: #fff;
      &__iframe {
        box-shadow: $box-shadow;
      }
    }
  }
}
