// Micro site header
.cb-flavor--subsite {
  .cb-nameplate__compact {
    .cb-primary-links {
      .cb-search {
        width: 16px;
        &::before {
          font-size: 0.85rem;
          width: 16px;
          padding-top: 0.95rem;
        }
        &:hover::before {
          font-size: 1.25rem;
        }
        :focus::after {
          width: 16px;
        }
      }
    }
  }
  .mobile-menu {
    &__header,
    .cb-mobile-primary {
      background-color: color(secondary, brand);
      > li {
        border : none;
        padding: 0;
      }
    }
    &__header a,
    &__close {
      background-color: color(secondary, lo);
      color: #fff;
    }
    ul.cb-primary-links {
      > li:first-child:not(.active-trail),
      > li {
        border-color: color(secondary, lo);
      }
      > li:first-child {
        border-top-width: 2px;
      }
    }
    .cb-mobile-primary {
      .cb-megamenu__panel-toggle {
        background-color: color(secondary, lo);
        border-color: color(secondary, lo);
      }
      // Site name link in sub-site mobile menus
      .cb-header-logo__site-link {
        // Min height is height of panel reveal button.
        min-height: calc(3.15rem + 4px);
        display: flex;
        align-items: center;
      }
      a {
        font-size: 0.9rem;
      }
    }
    .cb-subsite-header {
      background-color: color(primary, xlo);
      padding: 0.75em 1.9rem;
      a {
        color: #fff;
      }
      h2 {
        font-size: 0.9rem;
      }
      h3 {
        font-size: 1.25rem;
      }
      h2, h3 {
        margin: 0;
      }
    }
  }
}
