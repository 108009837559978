// If CTA Style 1 is selected:
// CTA image is displayed in square format
// Description is hidden in the html markup

.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--1.pane:not(:only-child) &,
    &.cb-style--1.pane:not(:only-child) {
      overflow: hidden;
      position: relative;
      max-width: 100%;
      background: transparent;
      align-self: flex-start;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
      margin-left: 1.7%;
      margin-right: 1.7%;
      // Need block otherwise firefox does not apply pseudo element height.
      display: block;
      .fieldable-panels-pane,
      .paragraphs-items,
      .field-name-field-cta-paragraphs,
      .paragraphs-item-cta,
      .field-name-field-cta-description,
      .field-name-field-description {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        width: 100%;
      }
      .paragraphs-item-cta.cw-with-image:not(.cw-cta-phone):not(.cw-with-icon) {
        justify-content: flex-end;
        position: relative;
        display: flex;
        .content {
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          overflow: visible;
        }
        .field-name-field-title {
          z-index: z(content);
          left: 0;
          right: 0;
          margin: auto;
          bottom: 2.4em;
          /* stylelint-disable selector-max-compound-selectors */
          h2 {
            font-weight: 600;
            color: #fff;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12);
            overflow: hidden;
            position: relative;
          }
          /* stylelint-enable */
        }
        .field-name-field-cta-link {
          /* stylelint-disable selector-max-compound-selectors */
          a {
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12);
          }
          /* stylelint-enable */
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          z-index: z(contentBottom);
        }
        &::before {
          background: rgba(45, 46, 49, 0.15);
          height: 100%;
          width: 100%;
          transition: background-color 0.5s ease;
          bottom: 0;
        }
        &::after {
          height: 80%;
          width: 100%;
          bottom: 0;
          @include media(bp(l)) {
            height: 55%;
          }
        }
      }
      .field-name-field-cta-image {
        box-shadow: none;
        margin-bottom: 0;
        max-width: 14em;
        max-width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
      .field-name-field-cta-link {
        display: block;
        a {
          position: relative;
          display: inline-block;
        }
      }
      .field-name-field-cta-link a {
        z-index: z(contentTop);
      }
      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
        z-index: z(content);
      }
      > .fieldable-panels-pane,
      > .panels-ipe-portlet-content {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .content {
        flex: 0 0 auto;
      }
      .cw-cta__overlay-link {
        // necessary to appear above before and after pseudo element (bg gradients)
        z-index: #{z(content) + 5};
      }
      img {
        display: block;
        max-width: 100%;
        height: auto;
        z-index: z(contentBottom);
        position: relative;
        transition: all 0.2s ease-out;
      }
    }
  }
}

// List Styles
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--1.pane &,
    &.cb-style--1.pane {
      @include media(bp(bs)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .field-name-field-cta-link {
              margin-bottom: $spacing--m;
            }
            .field-name-field-title,
            .field-name-field-cta-link,
            .field-name-field-cta-description {
              max-width: 90%;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
      @include media($bp-only-m) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .field-name-field-cta-link {
              margin-bottom: $spacing--s;
            }
          }
        }
      }
      @include media(bp(m)) {
        @include quantities-all(odd) {
          &:not(:only-child) {
            .field-name-field-title,
            .field-name-field-cta-link,
            .field-name-field-cta-description {
              max-width: 100%;
            }
          }
        }
        @include quantities-all(even) {
          .field-name-field-cta-link {
            margin-bottom: $spacing--m;
          }
          .field-name-field-title,
          .field-name-field-cta-link,
          .field-name-field-cta-description {
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    &.cb-max-columns--4 {
      .pane-bundle-cta,
      .panels-ipe-bundle-cta {
        .cb-style--1.pane &,
        &.cb-style--1.pane {
          @include media(bp(l)) {
            // 4+ items: fixed 1/4 page width for each
            &:not(:only-child) {
              .field-name-field-cta-link {
                margin-bottom: 0;
              }
              .field-name-field-title,
              .field-name-field-cta-link,
              .field-name-field-cta-description {
                max-width: 100%;
              }
            }
            @include quantities-all(2) {
              .field-name-field-cta-link {
                margin-bottom: $spacing--m;
              }
              .field-name-field-title,
              .field-name-field-cta-link,
              .field-name-field-cta-description {
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
              }
            }
          }
        }
      }
    }
  }
}

// IPE fixes
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--1.pane:not(:only-child) &,
    &.cb-style--1.pane:not(:only-child) {
      .panels-ipe-handlebar-wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: z(tooltip);
        .panels-ipe-dragbar {
          background: rgba(255, 255, 255, 0.9);
        }
      }
      /* stylelint-disable selector-max-compound-selectors */
      // fix ipe alignment issue
      .panels-ipe-portlet-content {
        .paragraphs-item-cta {
          .field-name-field-title {
            bottom: 5em;
          }
        }
        .pane-bundle-cta {
          background: transparent;
        }
      }
      /* stylelint-enable */
    }
  }
}
