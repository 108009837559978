// Related Conditions and Treatments styles

.cb-list-conditions--related-conditions,
.cb-list-treatments--related-treatments {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    @include media(bp(s)) {
      column-count: 2;
      break-inside: avoid;
    }
  }
  li {
    page-break-inside: avoid;
    margin: $spacing--m 0 0 0;
    line-height: 1.2;
    h3,
    .field-name-title-field {
      // fix hidden focus outlines
      padding: 2px;
    }
    h3 {
      @include component-link-title(s);
    }
    &:first-child {
      margin-top: 0;
    }
    .cb-layout__component-dynamic {
      // fix hidden focus outlines
      overflow: inherit;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .show-more {
    margin-top: $spacing--s;
    &--more,
    &--less {
      text-align: left;
      border-top: none;
      width: 100%;
    }
    &--hidden::after {
      display: none;
    }
  }
  h2 {
    border-bottom: none;
    padding-bottom: 0;
  }
  .view-content {
    margin-bottom: $spacing--m;
    @include media(bp(s)) {
      margin-bottom: $spacing--l;
    }
  }
}

.node-type-news {
  .cb-list-conditions--related-conditions,
  .cb-list-treatments--related-treatments {
    margin-top: 3em;
    @include media(bp(l)) {
      margin-top: 4em;
    }
    .show-more {
      width: 100%;
    }
  }
}
