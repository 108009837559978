// stylelint-disable selector-max-id, selector-max-specificity, selector-class-pattern
#airbud-root {
  // Hide when mobile menu is open.
  .scroll-lock .mobile-menu-active & {
    z-index: -1;
  }
  a {
    font-family: $font--text--alt;
  }
  // Fix some covid-19 chatbot theme conflicts
  .widgetIcon-0-2-5:hover {
    .text-0-2-24 {
      // Fix contrst issue when button is in hover state.
      color: a11yrize(color(neutral-brand), hsl(0, 0%, 90%));
    }
  }
  // Fix last child margins.
  // Our theme sets all last children to margin 0.
  .ribbonSuggestionContainer-0-2-48:last-child {
    margin-bottom: 17px;
  }
  .textInput-0-2-91:last-child,
  .textInput-0-2-82:last-child {
    margin-bottom: 13px;
  }

  // Fix button style conflicts.
  .ButtonsContainer-0-2-40 {
    button {
      box-shadow: none;
      border-radius: 0;
    }
    .minimize-0-2-42 {
      height: 42px;
    }
  }
}
// stylelint-enable selector-max-id, selector-max-specificity, selector-class-pattern
