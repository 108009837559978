// Styles for callouts implemented with ckeditor.
.ck-callout {
  clear: both;
  padding: 1.8rem;
  margin: 1em 0;
  color: color(neutral-brand, xxlo);
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &.mailto {
      // mail icon
      &::before {
        display: inline-block;
        // to make 'no underline' work in IE11,
        // this needs to be declared first
        // followed by text-decoration: none
        // in separate declaration below
        text-decoration: underline;
      }
      &::before {
        text-decoration: none;
      }
    }
  }
  @include media(bp(m)) {
    &--left,
    &--right {
      width: 45%;
      max-width: 460px;
      padding: $spacing--l;
    }
    &--left {
      float: left;
      margin: 0.5em 1.5em 1em 0;
    }
    &--right {
      float: right;
      margin: 0.5em 0 1em 1.5em;
    }
  }
  &--icon-info,
  &--icon-query {
    .ck-callout__title {
      &::before {
        margin-right: 0.25em;
      }
    }
  }
  &--icon-info {
    .ck-callout__title {
      @include icon($icon:"info", $size:1.5em);
    }
  }
  &--icon-query {
    .ck-callout__title {
      @include icon($icon:"query", $size:1.5em);
    }
  }
  &__title,
  &__content,
  &__content p,
  a {
    font-family: $font--text--alt;
  }
  &__title {
    font-size: 1.125rem;
    color: color(neutral-brand, xxlo);
  }
  /* stylelint-disable selector-max-compound-selector, selector-class-pattern */
  .node-type-news .cb-feature-quaternary .cb-container__inner > .pane-node-body &,
  .cb-content-area__main &,
  .cb-content-area__main .panels-ipe-portlet-content > .pane-node-body &,
  .cke_editable & {
    &__content ol li,
    &__content ol li a,
    &__content ul li,
    &__content ul li a {
      font-size: 0.9rem;
      font-family: $font--text--alt;
    }
  }
  .node-type-news .cb-feature-quaternary .cb-container__inner > .pane-node-body &,
  .cb-content-area__main &,
  .cke_editable & {
    &__content ol li a,
    &__content ul li a,
    &__title a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    &__content,
    &__content p {
      font-size: 0.9rem;
      color: color(neutral-brand, xxlo);
    }
  }
  /* stylelint-enable */
  .cb-button {
    // Ensure button is on its own line.
    display: table;
    font-size: 1em;
    .cb-content-area__main .pane-node-body & {
      margin-top: 1em;
    }
  }
}

// Background styles
.ck-callout--background-neutral {
  background: $background-solid-xlight;
  .node-type-news .cb-feature-quaternary .cb-container__inner > .pane-node-body &,
  .cb-content-area__main & {
    /* stylelint-disable selector-max-compound-selectors */
    .ck-callout__title a:not(.cb-button),
    .ck-callout__content a:not(.cb-button),
    .ck-callout__content li a:not(.cb-button) {
      color: a11yrize(color(primary), $background-solid-xlight);
      font-weight: 600;
    }
    /* stylelint-enable */
  }
}

.ck-callout--background-sky {
  background: lighten(color(secondary, xhi), 16%);
  .node-type-news .cb-feature-quaternary .cb-container__inner > .pane-node-body &,
  .cb-content-area__main & {
    /* stylelint-disable selector-max-compound-selectors */
    .ck-callout__title a:not(.cb-button),
    .ck-callout__content a:not(.cb-button),
    .ck-callout__content li a:not(.cb-button) {
      color: a11yrize(color(primary), lighten(color(secondary, xhi), 16%));
      font-weight: 600;
    }
    /* stylelint-enable */
  }
  .cb-button {
    box-shadow: 0.5px 1px 2px 0.5px rgba(0, 0, 0, 0.3), -0.5px 0 0.5px 1px rgba(0, 0, 0, 0.06);
    border-color: darken(color(neutral-brand, hi), 5%);
    &:hover, &:focus {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
    }
  }
}
