// Promoted News and Blog Styles

.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted:not(.cb-list-news--announcements),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--blog) {
      .view {
        // fix ie10/11 flex grow vertical spacing issue
        display: block;
      }
      .view-content {
        margin-top: $spacing--m;
        @include media(bp(m)) {
          margin: $spacing--m 0 0 0;
          max-width: 100%;
          @include clearfix();
          /* stylelint-disable selector-max-compound-selectors */
          > ul {
            max-width: 100%;
            display: flex;
            justify-content: center;
          }
          /* stylelint-enable */
        }
      }
      li {
        padding-bottom: $spacing--l;
        padding-top: $spacing--l;
        .cb-list-news {
          /* stylelint-disable selector-max-compound-selectors */
          &__best-category {
            order: 3;
          }
          &__title {
            order: 4;
          }
          &__external-source {
            order: 5;
          }
          &__byline {
            order: 6;
          }
          &__date {
            order: 7;
          }
          &__summary {
            order: 8;
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

.cb-flavor:not(.cb-list-news) {
  .region {
    .cb-list-news--promoted:not(.cb-list-news--announcements),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--blog) {
      li:not(:only-child) {
        @include media(bp(m)) {
          margin: 0 1.7%;
          float: left;
          flex: 1;
          padding: 0;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          /* stylelint-disable selector-max-compound-selectors */
          .cb-layout__component-dynamic,
          .col {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
          }
          .column--last {
            order: 1;
            width: 100%;
            flex: 0 0 auto;
            // fix ie11 issue
            display: block;
          }
          .column--first {
            order: 2;
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          .cb-list-news {
            &__title,
            &__date {
              display: flex;
            }
            &__date {
              margin-top: 0;
            }
            &__image-teaser {
              margin-top: 0;
              max-height: 100%;
            }
          }
          /* stylelint-enable */
        }
      }
      li:only-child {
        @include media(bp(m)) {
          // fix ie10/11 flex grow vertical spacing issue
          /* stylelint-disable selector-max-compound-selectors */
          .column--last,
          .col {
            display: block;
          }
          .column--first {
            display: flex;
          }
          /* stylelint-enable */
        }
        @include media(bp(l)) {
          align-items: center;
        }
      }
      .cb-list-news {
        &__title {
          width: 100%;
          /* stylelint-disable selector-max-compound-selectors */
          a {
            max-height: 100%;
            &::before,
            &::after {
              display: none;
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
