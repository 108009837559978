// Appointments with find a doc link: Main Content Only

.cb-content-area__main {
  .cb-appointments.cw-with-doctors-link {
    .cb-appointments {
      &__link {
        &:not(:only-child) {
          &.cb-appointments__link--doctors {
            a {
              &:not(.cw-appointments__overlay-link) {
                margin-left: 1rem;
                margin-right: 1rem;
              }
            }
          }
          .cw-appointments__overlay-link,
          &.cb-appointments__link--doctors {
            height: 65px;
            @include media(bp(s)) {
              height: 100%;
            }
          }
        }
      }
    }
    // if find a doc link is not an only-child
    &.cw-with-title,
    &.cw-with-description,
    &.cw-with-phone-link,
    &.cw-with-book-online-link,
    &.cw-with-telehealth-link,
    &.cw-with-info-link {
      padding-bottom: 75px;
      @include media(bp(s)) {
        padding-bottom: $spacing--m;
      }
    }
    // if find a doc is displayed with only one other appointment link
    &.cw-with-phone-link:not(.cw-with-book-online-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
    &.cw-with-book-online-link:not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
    &.cw-with-telehealth-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-info-link),
    &.cw-with-info-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
    &.cw-with-title:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
    &.cw-with-description:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link) {
      .cb-appointments {
        @include media(bp(s)) {
          &__title {
            margin-bottom: 0;
          }
          &__description {
            margin-top: 0.5rem;
            margin-bottom: 0;
          }
          &__link {
            &:not(.cb-appointments__link--doctors) {
              a {
                margin-bottom: 0;
              }
            }
          }
        }
        @include media(bp(l)) {
          &__link {
            &:not(.cb-appointments__link--doctors) {
              a {
                font-size: 1.15rem;
                &::before {
                  font-size: 1.2rem;
                  line-height: 1.4;
                }
              }
            }
          }
        }
        @include media(bp(xl)) {
          &__title,
          &__content {
            width: 58%;
          }
          &__link {
            &:not(.cb-appointments__link--doctors) {
              a {
                font-size: 1.4rem;
                &::before {
                  font-size: 1.4rem;
                  line-height: 1.2;
                }
              }
            }
            &--doctors {
              width: 40%;
              a:not(.cw-appointments__overlay-link) {
                width: 160px;
              }
            }
          }
        }
      }
    }
    // if find a doc is the only link displayed
    &:not(.cw-with-book-online-link):not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-title):not(.cw-with-description) {
      padding: 0;
      .cb-appointments__link--doctors:only-child {
        position: relative;
        display: block;
        height: 55px;
        width: 255px;
        a:not(.cw-appointments__overlay-link) {
          width: auto;
          height: 55px;
          padding: 0.25em 1em;
          margin: 0;
          background: $btn-bg-color-interior-pg-component;
          &::before {
            font-size: 2.7rem;
          }
        }
        .cw-appointments__overlay-link {
          display: none;
        }
      }
    }
  }
}

// layout with sidebars
.cb-layout--aside-last,
.cb-layout--aside-first,
.cb-layout--aside-first-last {
  .cb-content-area__main {
    .cb-appointments.cw-with-doctors-link {
      // if find a doc link is not an only-child
      &.cw-with-title,
      &.cw-with-description,
      &.cw-with-phone-link,
      &.cw-with-book-online-link,
      &.cw-with-telehealth-link,
      &.cw-with-info-link {
        .cb-appointments {
          @include media(bp(xl)) {
            &__link--doctors {
              width: 22%;
              // Fix for subsection front pages where we hide the sidebar nav.
              .cb-flavor--subsection-front & {
                width: 35%;
              }
            }
            &__title,
            &__content {
              width: 78%;
              // Fix for subsection front pages where we hide the sidebar nav.
              .cb-flavor--subsection-front & {
                width: 63%;
              }
            }
          }
        }
      }
      .cb-appointments {
        &__link {
          @include quantities-all(3,5) {
            &:not(.cb-appointments__link--doctors) {
              @include media(bp(xl)) {
                width: 49%;
                float: left;
                &:first-child,
                &:nth-child(3) {
                  margin-right: 0.4rem;
                }
                a {
                  font-size: 0.9rem;
                }
              }
            }
          }
          @include quantities-all(4) {
            &:not(.cb-appointments__link--doctors) {
              @include media(bp(xl)) {
                width: 100%;
                float: none;
                margin-right: 0;
                a {
                  font-size: 1rem;
                }
              }
            }
            &.cb-appointments__link--doctors {
              @include media(bp(xl)) {
                a {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

// layout with no sidebars
.cb-flavor--subsection-front,
.cb-layout--no-aside {
  .cb-content-area__main {
    .cb-appointments.cw-with-doctors-link {
      .cb-appointments {
        &__link {
          @include quantities-all(3,5) {
            &:not(.cb-appointments__link--doctors) {
              @include media(bp(xl)) {
                width: 100%;
              }
            }
          }
        }
      }
      // if find a doc is displayed with only one other appointment link
      &.cw-with-phone-link:not(.cw-with-book-online-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-book-online-link:not(.cw-with-phone-link):not(.cw-with-telehealth-link):not(.cw-with-info-link),
      &.cw-with-telehealth-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-info-link),
      &.cw-with-info-link:not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
      &.cw-with-title:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link),
      &.cw-with-description:not(.cw-with-info-link):not(.cw-with-phone-link):not(.cw-with-book-online-link):not(.cw-with-telehealth-link) {
        .cb-appointments__link {
          @include media(bp(xl)) {
            &:not(.cb-appointments__link--doctors) {
              a {
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }
  }
}
