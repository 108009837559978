// Temporary fix for iframe focus steal.
.js .cb-mychart {
  &__form {
    display: none;
  }
  &__loading {
    @include center($absolute: true);
    font-size: 0.8rem;
    font-family: $font--text--alt;
    text-transform: uppercase;
    text-align: center;
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('#{$image-path}/ajax-loader-transparent.gif');
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.5em;
    }
    &--fail {
      color: a11yrize(color(alert-fail), $background-solid-light);
      &::before {
        display: none;
      }
    }
  }
  &__actions {
    position: relative;
    &--loaded {
      .cb-mychart__form {
        display: block;
      }
    }
  }
}

.cb-mychart {
  &__title {
    background: $component-bg-2-color;
    @include component-padding();
  }
  &__content {
    background: #fff;
    @include clearfix();
  }
  &__info,
  &__actions {
    padding: 1.5em;
  }
  &__info {
    border-left: $component-border-style;
    border-right: $component-border-style;
    @include media(bp(l)) {
      border-right: none;
      border-bottom: $component-border-style;
    }
    ul,
    ol {
      padding: 0 1em;
      margin: 0 0 1em 0.2em;
      li {
        margin-bottom: 0.7em;
        line-height: 1.3;
      }
    }
  }
  &__actions {
    background: $background-solid-light;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.cb-mychart {
  h2.cb-mychart__title {
    @include component-heading(s);
    margin-bottom: 0;
    text-align: center;
    color: #fff;
    @include media(bp(xs)) {
      @include component-heading(m);
      margin-bottom: 0;
    }
    @include media(bp(s)) {
      @include component-heading(l);
      margin-bottom: 0;
    }
    @include media(bp(m)) {
      @include component-heading(xl);
      margin-bottom: 0;
    }
    @include media(bp(l)) {
      text-align: left;
    }
  }
  &__description {
    p,
    a {
      font-family: $font--text--alt;
    }
    p,
    ul,
    ol {
      @include component-paragraph(s);
      @include media(bp(m)) {
        @include component-paragraph(l);
      }
    }
  }
  // Overspecified to override system.theme.css
  .item-list {
    .cb-mychart__links {
      border-top: $border--xs;
      list-style: none;
      margin: 0;
      margin-top: 1em;
      padding: 0.5em 0 0 0;
      li {
        display: block;
        margin: 0 0 0.5em 0;
        @include media(bp(bs)) {
          display: inline;
          margin: 0 1em 0.2em 0;
          &:last-child {
            margin-right: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        margin-bottom: 0.5em;
        font-size: 0.9rem;
        white-space: nowrap;
      }
    }
  }
}

// My Chart form.
.cb-mychart {
  input {
    width: 100%;
  }
  .form-item {
    margin-bottom: 0;
    &:first-child {
      margin-top: 0;
    }
  }
  &__login {
    a {
      font-size: 0.9rem;
    }
    label {
      font-family: $font--text--alt;
      font-weight: 600;
    }
  }
  &__signup {
    margin: 0.6em 0 0.5em 0;
    text-align: center;
    a {
      font-family: $font--text--alt;
      font-size: 0.9rem;
      text-decoration: none;
    }
  }
  &__actions {
    a {
      color: a11yrize(color(action, m), $background-solid-light);
    }
  }
  .form-submit {
    display: block;
    margin-top: 1em;
  }
  iframe {
    width: 100%;
    height: 540px;
    border: 0;
    overflow: hidden;
    background: #fff;
  }
}

// My Chart Layout
.cb-mychart {
  @include clearfix();
  @include media(bp(l)) {
    // display info and login side-by-side
    .cb-mychart {
      &__info {
        width: 50%;
        float: left;
        display: flex;
        flex-direction: column;
      }
      &__actions {
        width: 50%;
        float: right;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      &__content {
        display: flex;
      }
      &__description {
        flex: 1 0 auto;
      }
      &__description,
      &__info .item-list,
      &__login,
      &__signup {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &__description,
  &__info .item-list,
  &__login,
  &__signup {
    max-width: $component-content-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}
