// Hero with: text background scrim none

.pane-hero,
.panels-ipe-hero {
  .paragraphs-item-hero {
    &.cb-scrim--none {
      &.cb-position-h--right,
      &.cb-position-h--left {
        .group-titles {
          @include media(bp(m)) {
            font-size: 1.75em;
          }
          @include media(bp(l)) {
            font-size: 2.5em;
          }
          h2 {
            @include media(bp(m)) {
              font-size: 1rem;
            }
            @include media(bp(l)) {
              font-size: 1.33rem;
            }
            @include media(bp(xl)) {
              font-size: 1.75rem;
            }
            @include media(100em) {
              font-size: 2.25rem;
            }
          }
          .field-name-field-subtitle p {
            @include media(bp(m)) {
              font-size: 0.85rem;
            }
            @include media(bp(l)) {
              font-size: 1rem;
            }
            @include media(bp(xl)) {
              font-size: 1.125rem;
            }
            @include media(100em) {
              font-size: 1.25rem;
            }
          }
        }
      }
      &.cb-position-v--bottom {
        &.cb-tone--light,
        &.cb-position-v--bottom.cb-tone--default {
          .field-name-field-image-hero {
            // Legacy gradient obverlay.
            &::after {
              content: "";
              @include bg($hero-bg-gradient-top-color, $hero-bg-gradient-bottom-color, $hero-bg-gradient-stop-first, $hero-bg-gradient-stop-second, $ie-fallback: false);
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              z-index: 1;
            }
          }
          .group-titles {
            @include media(bp(m)) {
              .lt-ie10 & {
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str($hero-bg-gradient-top-color)}', endColorstr='#{ie-hex-str($hero-bg-gradient-bottom-color)}', GradientType=0);
              }
            }
          }
        }
        .group-titles {
          .cb-overlapped--bottom & {
            @include media(bp(xl)) {
              margin-bottom: 0.75em;
            }
          }
        }
      }
    }
  }
}

// Slim heroes
// Hero with: text background scrim none
.pane-hero.cb-style--auto,
.panels-ipe-hero.cb-style--auto {
  .paragraphs-item-hero {
    &.cb-scrim--none {
      &.cb-position-h--right,
      &.cb-position-h--left {
        .group-titles {
          @include media(bp(m)) {
            font-size: 1.75em;
          }
          @include media(bp(l)) {
            font-size: 2.5em;
          }
          h2 {
            @include media(bp(m)) {
              font-size: 1rem;
            }
            @include media(bp(l)) {
              font-size: 1.25rem;
            }
            @include media(bp(xl)) {
              font-size: 1.5rem;
            }
            @include media(100em) {
              font-size: 2rem;
            }
          }
          .field-name-field-subtitle p {
            @include media(bp(m)) {
              font-size: 0.8rem;
            }
            @include media(bp(l)) {
              font-size: 0.9rem;
            }
            @include media(bp(xl)) {
              font-size: 1rem;
            }
            @include media(100em) {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
}
