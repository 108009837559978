// Lab Profiles Head styles when it's displayed next to Lab Location component
.node-type-lab {
  .cb-content-area__main {
    .cb-lab-location {
      + .cb-list-profiles--lab-heads {
        margin-top: -2em;
        box-shadow: 0 1.5px 1px 0 rgba(0, 0, 0, 0.2);
        @include media(bp(s)) {
          width: 50%;
          float: left;
          box-shadow: 1.5px 1px 1px 0 rgba(0, 0, 0, 0.1);
          margin-top: 0;
          border-top: 1px solid tint(color(neutral-brand, xhi), 70%);
          border-left: none;
          .lt-ie10 & {
            width: 100%;
            float: none;
            box-shadow: 0 2px 2px 0 color(neutral-brand, xhi);
            margin-top: -2em;
            border-top: 1px solid tint(color(neutral-brand, xhi), 70%);
            border-left: 1px solid tint(color(neutral-brand, xhi), 70%);
          }
        }
        &::before {
          background-color: $background-solid-light;
          content: "";
          width: calc(100% - 3em);
          height: 2px;
          display: block;
          margin-top: -1.7em;
          position: absolute;
          left: 1.5em;
          @include media(bp(s)) {
            width: 2px;
            height: calc(100% - 3em);
            margin: 0;
            top: 1.5em;
            left: 0;
            .lt-ie10 & {
              width: 100%;
              height: 2px;
              margin: 0 auto 1.5em auto;
            }
          }
        }
        h2.pane-title {
          font-size: 1.125rem;
          background: transparent;
        }
        /* stylelint-disable selector-max-compound-selectors */
        .cb-list-profiles__title {
          h3 {
            margin-bottom: 0.25em;
            a {
              font-size: 1rem;
            }
          }
        }
        .cb-list-profiles__positions {
          li {
            font-size: 0.9em;
          }
        }
        /* stylelint-enable */
      }
    }
  }
}

// Override default classy panel styles
.cb-content-area__main {
  .cb-lab-location {
    + .cb-list-profiles--lab-heads {
      &.pane:not(:last-child) {
        margin-bottom: 2em;
      }
    }
  }
}

// Fix conflicts when on front page.
.node-type-lab.front {
  .cb-content-area__main {
    .cb-lab-location {
      + .cb-list-profiles--lab-heads {
        @include media(bp(s)) {
          width: 50%;
        }
      }
    }
  }
}
