.cb-list-conditions-treatments__search {
  .views-exposed-form {
    padding-top: 1.25em;
  }
  .views-exposed-widgets {
    display: flex;
    align-items: flex-end;
  }
  // stylelint-disable-next-line selector-class-pattern
  .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
    margin-right: 0;
    label {
      line-height: 1;
    }
  }
  .views-exposed-widget.views-submit-button {
    flex: 0 1 auto;
    display: flex;
    min-height: $touch-size-large;
    width: auto;
    &:last-child input[type="submit"] {
      border-radius: 0 4px 4px 0;
    }
  }
}
