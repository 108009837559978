// Classy Panels

// Reset ember floats.
.cb-content-area {
  .panels-ipe-region {
    @include media(bp(xl)) {
      float: none;
    }
  }
}

// Region Overlap
.region {
  // Necessary for 'region overlap top' to work
  position: relative;
  &.cb-overlap {
    &--top,
    &--top-and-bottom {
      @include media(bp(xl)) {
        overflow: visible;
        .cb-container:not(.cb-feature-primary) & {
          padding-top: 0;
          .cps-region-inner,
          .panels-ipe-sort-container {
            margin-top: -4em;
          }
          .panels-ipe-portlet-static {
            margin-top: -8em;
            margin-bottom: 4em;
            background-color: rgba(#f5f5f5, 0.7);
            z-index: z(screenOverlay);
          }
        }
        .cps-region-inner {
          padding: 1.5% 0 1.6% 0;
          z-index: z(content);
          background-color: inherit;
          position: relative;
          > .pane {
            z-index: z(contentTop);
          }
          .lt-ie10 & {
            @include clearfix();
          }
        }
        // Fix ipe padding and bg color issue
        .panels-ipe-sort-container {
          z-index: z(contentTop);
          background-color: inherit;
          position: relative;
        }
        // Disable region overlap styles in ie9
        .lt-ie10 & {
          @include clearfix();
        }
      }
    }
    &--top {
      @include media(bp(xl)) {
        .cb-container:not(.cb-feature-primary) & {
          padding-bottom: 0;
        }
      }
    }
    &--bottom {
      // This extra wrapper (cb-container) is needed to override the extra
      // specificity created by feature-rows prominence padding.
      .cb-container & {
        padding-bottom: 0;
        margin-bottom: -1.5%;
        &:last-child {
          margin-bottom: -1.5%;
        }
      }
      .cps-region-inner {
        padding: 0 0 1.6% 0;
        z-index: z(content);
        background-color: inherit;
        position: relative;
        > .pane {
          z-index: z(contentTop);
        }
        .lt-ie10 & {
          @include clearfix();
        }
      }
      // Fix ipe padding and bg color issue
      .panels-ipe-sort-container {
        z-index: z(contentTop);
        background-color: inherit;
        position: relative;
      }
      // Disable region overlap styles in ie9
      .lt-ie10 & {
        @include clearfix();
      }
    }
    &--top-and-bottom {
      @include media(bp(xl)) {
        // This extra wrapper (cb-container) is needed to override the extra
        // specificity created by feature-rows prominence padding.
        .cb-container & {
          padding-bottom: 0;
          margin-bottom: -1.5%;
          &:last-child {
            margin-bottom: -1.5%;
          }
        }
      }
    }
    &--top,
    &--bottom,
    &--top-and-bottom {
      position: inherit;
    }
  }
  // "Landing page" (cola_base_dynamic_features_asides_feature_rows) adjustments.
  .cb-content-area__feature-rows & {
    &.cb-overlap {
      &--top,
      &--bottom,
      &--top-and-bottom {
        @include media(bp(xl)) {
          // fix ipe toolbar display
          .panels-ipe-placeholder {
            z-index: 100;
          }
        }
      }
      &--top,
      &--top-and-bottom {
        @include media(bp(xl)) {
          max-width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        .panels-ipe-sort-container {
          &.ui-sortable {
            margin-top: 0;
          }
        }
      }
    }
  }
}


// Component styles.

// Default component vertical spacing.

// Reset ember ipe overrides.
.panels-ipe-editing div.panels-ipe-portlet-wrapper {
  margin-top: 0;
}

.panels-ipe-portlet-wrapper {
  // Fixes Carousel overflowing content area when logged in.
  max-width: 100%;
  // Fixes content not stretching to full width.
  // e.g. gcse search box on featured layout rows
  flex: 1 0 auto;
}

// Main content components
.pane {
  .cb-content-area__main & {
    &:not(:last-child) {
      margin-bottom: $spacing--xl;
      @include media(bp(m)) {
        margin-bottom: $spacing--xxl;
      }
    }
  }
}

// No left and right margins for full-width components
.front,
.cb-section-front {
  .region.cb-padding--none {
    .pane {
      &:only-child {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: $component-spacing;
        padding-right: $component-spacing;
      }
    }
  }
}

// "Landing page" (cola_base_dynamic_features_asides_feature_rows) adjustments.
// Not applicable to News list pages
.cb-flavor:not(.cb-list-news) {
  .cb-content-area__feature-rows .region.cb-max-columns--3,
  .cb-content-area__feature-rows .region.cb-max-columns--4 {
    .cb-content-area__aside-last & {
      .cb-page-content--feature-rows & {
        &.cb-padding {
          &:nth-last-child(n) {
            float: none;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
