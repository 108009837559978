// In Numbers Typography Styles

// Standardized component default font sizes
.region,
.cb-page-content .cb-content-area__main {
  .panels-ipe-bundle-numbers,
  .pane-bundle-numbers {
    .cb-numbers__title {
      @include component-heading(s);
      padding: 0 0 $spacing--s 0;
      text-align: center;
      @include media(bp(xs)) {
        @include component-heading(m);
      }
      @include media(bp(s)) {
        @include component-heading(l);
      }
      @include media(bp(m)) {
        @include component-heading(xl);
      }
    }
    .cb-number {
      &__description {
        b {
          font-weight: 400;
          font-style: italic;
        }
      }
      &__number {
        font-weight: 700;
        order: 2;
      }
      dd, dt {
        @include component-paragraph(s);
      }
    }
  }
}

// Featured layout overrides
// Decrease font size and/or paddings due to smaller page width than the homepage
.cb-page-content--feature-rows {
  .region {
    .panels-ipe-bundle-numbers,
    .pane-bundle-numbers {
      .cb-numbers__numbers {
        dd, dt {
          @include component-paragraph(s);
        }
      }
    }
  }
}

// Component specific typography styles
.region {
  // Need extra selectors due to ipe overrides
  .pane {
    &.panels-ipe-bundle-numbers,
    &.pane-bundle-numbers {
      .cb-number {
        &__number {
          order: 2;
          float: right;
          margin-left: 1em;
        }
        dl, dt, dd {
          margin: 0;
        }
        dd, dt {
          line-height: 1.5;
        }
        > .content {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}

// Layouts with none or one sidebar
.front,
.cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--aside-first .cb-content-area__main,
.cb-layout--aside-last .cb-content-area__main {
  .pane {
    &.panels-ipe-bundle-numbers,
    &.pane-bundle-numbers {
      .cb-numbers__numbers  {
        dd, dt {
          @include media(bp(l)) {
            @include component-paragraph(m);
          }
        }
      }
    }
  }
}

// Main content only
.cb-layout--aside-first-last {
  .cb-content-area__main {
    .panels-ipe-bundle-numbers,
    .pane-bundle-numbers {
      @include media(bp(l)) {
        h2 {
          @include component-heading(xl);
        }
      }
    }
  }
}
