// Primary sections nav
.cb-flavor--sections .cb-nameplate .cb-primary-menu {
  @include media(bp(xl)) {
    a.active-trail.active {
      border-bottom: 2px solid tint(color(neutral-brand, xhi), 25%);
      padding-bottom: 0.175em;
    }
    // Outlined button style option.
    li.cb-menu-item--featured > a,
    .cb-menu-item--featured > a.active-trail.active {
      @include btn($style: outline, $size: s);
      @include override-nested() {
        border-color: color(action, lo);
        color: color(action, lo);
        padding: 0.3em 0.7em;
        font-size: inherit;
        background: #fff;
        &:hover, &:focus {
          color: #fff;
          background: color(primary, brand);
        }
      }
    }
    .cb-primary-links > li.cb-menu-item--featured > a {
      // stylelint-disable selector-max-compound-selectors
      &:hover {
        text-decoration: none;
      }
      // stylelint-enable selector-max-compound-selectors
    }
  }
}
