.mobile-subnav__toggle {
  width: 100%;
  height: 38px;
  padding: 0 1em;
  box-shadow: none;
  background: $mobile-page-menu-bg-color;
  color: $mobile-page-menu-font-color;
  text-align: center;
  font-size: 1em;
  &:hover,
  &:focus {
    box-shadow: none;
    background: $mobile-page-menu-bg-color;
    color: $mobile-page-menu-font-color;
    outline-color: $text-link-color;
  }
  &[aria-expanded='true'] {
    border-radius: $border-radius--default $border-radius--default 0 0;
  }
  // animated hamburger menu icon
  .mobile-subnav__toggle-icon,
  .mobile-subnav__toggle-icon::after,
  .mobile-subnav__toggle-icon::before {
    content: '';
    width: 16px;
    height: 3px;
    background-color: $mobile-page-menu-font-color;
  }
  .mobile-subnav__toggle-icon {
    display: inline-block;
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    margin-right: 3px;
    &::after,
    &::before {
      display: block;
      margin-left: 0;
      transition: all 0.25s ease-in-out;
    }
    &::before {
      margin-top: -5px;
      transform-origin: left top;
    }
    &::after {
      margin-top: 7px;
      transform-origin: left bottom;
    }
  }
  &[aria-expanded='true'] {
    .mobile-subnav__toggle-icon {
      background-color: $mobile-page-menu-bg-color;
      &::before,
      &::after {
        margin-left: 4px;
      }
      &::before {
        transform: rotateZ(41deg);
      }
      &::after {
        transform: rotateZ(-41deg);
      }
    }
  }
  &[aria-expanded='false'] {
    .mobile-subnav__toggle-icon {
      &::before,
      &::after {
        transform: rotateZ(0deg);
      }
    }
  }
}

.cb-mobile-subnav {
  &--open {
    background: #fff;
    border-top: $border--s;
  }
}

.cb-mobile-subnav,
.no-js .cb-sidebar-menu nav > .menu {
  @include media(max-width (bp(xl) - 0.06em)) {
    a {
      @include word-wrap();
      display: block;
      width: 100%;
      padding: 0.7em 1em;
      line-height: 1.4;
      text-decoration: none;
      &.active {
        color: color(neutral-brand, lo);
        &::after {
          content: "\25CF";
          margin-left: 0.5rem;
          margin-right: -1.25rem;
          vertical-align: baseline;
        }
      }
    }
    ul {
      margin-left: $spacing--m;
    }
    li {
      // fix focus outlines
      padding: 1px;
    }
    // first level links
    > li {
      margin-bottom: 0;
      &:not(:last-child) {
        border-bottom: $border--xs;
      }
      &.active-trail.expanded {
        > a.active {
          color: color(neutral-brand, lo);
        }
      }
    }
    // secondary and lower level links
    ul {
      padding-bottom: $spacing--m;
      li {
        margin-bottom: 0;
      }
      a {
        font-weight: 400;
        font-size: 0.9em;
      }
    }
    ul ul {
      padding-bottom: 0;
      border-left: $border--xs;
      li {
        margin-bottom: 0;
        // stylelint-disable selector-max-compound-selectors
        &.active-trail.expanded {
          .menu {
            padding-bottom: 0;
          }
        }
        // stylelint-enable selector-max-compound-selectors
      }
    }
  }
}

.no-js .cb-sidebar-menu {
  &::before {
    @include media(max-width (bp(xl) - 0.06em)) {
      content: 'Section Menu';
      display: block;
      font-family: $font--text--alt;
      padding: 0.5rem;
      background: $background-solid-xlight;
      font-weight: 600;
      text-align: center;
    }
  }
}

.cb-sidebar-menu {
  margin-top: $spacing--l;
  box-shadow: $box-shadow;
  border-radius: $border-radius--default;
  &--subsection {
    display: flex;
    flex-direction: column;
    margin-top: $spacing--m;
    padding-top: 0;
    .mobile-subnav__toggle {
      order: 2;
      margin-top: 0;
      border-top: none;
      border-radius: 0 0 $border-radius--default $border-radius--default;
      &[aria-expanded='true'] {
        border-radius: 0;
      }
    }
    nav {
      order: 3;
    }
    .pane-title {
      display: inline;
      margin: 0;
      height: auto;
      min-height: $touch-size-min;
      background: $background-solid-xlight;
      text-align: center;
      font-size: 1em;
      a {
        display: block;
        width: 100%;
        min-height: $touch-size-min;
        padding: 0.6em 0.4em;
        color: a11yrize(color(action, m), $background-solid-xlight);
        &.active {
          color: color(neutral-brand, xxlo);
        }
      }
    }
  }
  .cb-flavor:not(.cb-list-news) & {
    // make mobile page menu button
    // same width as body content container
    @include media($bp-only-m) {
      max-width: $component-content-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.cb-page-content {
  .cb-feature-area {
    // if page has breadcrumb
    + .cb-content-area {
      .cb-sidebar-menu {
        margin-top: $spacing--m;
      }
    }
  }
}


// Section flavor only:
// (cb-sidebar-menu--mobile-only class
// doesn't apply to non-section nav sites)
.cb-sidebar-menu--mobile-only.cb-sidebar-menu {
  @include media($bp-only-l) {
    @include span-columns(8, $shift: 2);
  }
  // Featured Layout
  .cb-layout--dynamic-features-asides-feature-rows & {
    margin-left: $component-spacing;
    margin-right: $component-spacing;
    box-shadow: none;
    border-radius: 0;
    @include media(bp(m)) {
      margin-left: auto;
      margin-right: auto;
    }
    @include media($bp-only-m) {
      padding-left: $page-margins;
      padding-right: $page-margins;
    }
    @include media($bp-only-l) {
      padding-left: calc((65.54622% - ((100% - 2.5em) * 0.6554622)) / 2);
      padding-right: calc((65.54622% - ((100% - 2.5em) * 0.6554622)) / 2);
      @include span-columns(8, $shift: 2);
    }
    nav {
      box-shadow: $box-shadow;
      border-radius: $border-radius--default;
    }
  }
}
