////
/// @group mixins
////

/// first-of-quantity
/// Returns a partial quantity query selector for a given quantity of children.
/// @param {mixed} $quantity - the quantity of children (or even, odd)
/// @return {string} - the quantity query partial with a leading ampersand.
@function first-of-quantity($quantity) {
  @return '&:first-child:nth-last-child(#{$quantity})';
}

/// Quantity query list
/// Returns a sass list containing partial selector(s) for a given quantity of
/// children; to be applied to all children of that quantity.
/// @param {mixed} $quantity - the quantity of children (or even, odd)
/// @param {string} $selector ['*'] - the selector of the siblings of the first
/// @return {list} - A sass list containing selectors for the given quantity.
@function quantity-query-list($quantity, $selector: '*') {
  $qq: ();
  @if $quantity == 1 {
    $qq: ('&:only-child');
  }
  @else {
    $qq: append($qq, first-of-quantity($quantity), comma);
    $qq: append($qq, '#{first-of-quantity($quantity)} ~ #{$selector}', comma);
  }
  @return $qq;
}

/// Quantities all
/// Returns the selectors applying styles to all children of a given quantity.
/// @param {list} $quantities - a list of child quantities plus even and odd.
/// @group mixins
@mixin quantities-all($quantities...) {
  $selectors: ();
  @each $quantity in $quantities {
    $selectors: append($selectors, quantity-query-list($quantity), comma);
  }
  @if length($selectors) > 0 {
    #{$selectors} {
      @content;
    }
  }
}

/// Quantity query positions
/// Returns selectors that will apply styles to specific children in a group
/// of a specified quantity.
/// @param {mixed} $quantity - the quantity of children (or even, odd)
/// @param {mixed} $positions - a list of child positions plus even and odd.
/// @group mixins
@mixin quantity-positions($quantity, $positions...) {
  $selectors: ();
  @each $position in $positions {
    $s: '';
    @if $position == 1 {
      $s: first-of-quantity($quantity);
    }
    @else if $position == $quantity and type-of($position) == number {
      $s: '&:nth-child(#{$position}):last-child';
    }
    @else if type-of($position) == number and type-of($quantity) == number  {
      $nth-last: ($quantity + 1 - $position);
      $s: '&:nth-child(#{$position}):nth-last-child(#{($nth-last)})';
    }
    @else {
      $s: '#{first-of-quantity($quantity)} ~ *:nth-child(#{$position})';
    }
    @if $s != '' {
      $selectors: append($selectors, $s, comma);
    }
  }
  @if length($selectors) > 0 {
    #{$selectors} {
      @content;
    }
  }
}
