@include component-region-bg-styles(1) {
  .pane-bundle-cta.pane:not(:only-child),
  .panels-ipe-bundle-cta.pane:not(:only-child) {
    .paragraphs-item-cta.cw-with-icon {
      .field-name-field-icon  {
        color: #fff;
      }
    }
  }
}
