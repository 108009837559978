// Main newsroom list page
.cb-list-news--main {
  // Fix IE9 issues for regions with floated items.
  .cps-region-inner {
    @include clearfix();
  }
  .cb-content-area__main {
    > .pane-page-title {
      margin-bottom: 0;
    }
  }
  // Hide any empty lists in ipe when not editing.
  .cb-ipe-empty {
    display: none;
  }
  &.panels-ipe-editing {
    .cb-ipe-empty {
      display: block;
    }
  }
  // View All Links within Browse by Topics and Latest News
  .cb-feature-row-secondary,
  .cb-feature-row-tertiary {
    .cps-region-inner {
      position: relative;
    }
    .pane-link-to-list {
      text-align: center;
      clear: both;
      width: 100%;
      a {
        @include more-link();
        @include media(bp(s)) {
          @include component-link-title(m);
        }
      }
    }
  }
  // Browse by Topics: Custom Title
  .cb-feature-row-tertiary {
    .panel-pane.pane-custom {
      display: block;
      clear: both;
      margin-left: $component-spacing;
      margin-right: $component-spacing;
      width: width-margins(100%);
      margin-top: 0;
      h2 {
        margin-bottom: 0.5em;
        padding-bottom: $spacing--s;
        border-bottom: $border--m;
        border-color: $heading-border-color;
        @include media(bp(m)) {
          margin-bottom: 1em;
        }
      }
    }
  }
  .panels-ipe-editing {
    .panels-ipe-portlet-content {
      .pane-link-to-list {
        position: static;
        width: 100%;
        margin: 1em 0;
      }
    }
  }
}

// Override default styles
.cb-list-news--main {
  .cb-page-content--feature-rows {
    .cb-content-area__feature-rows {
      .region {
        .cps-region-inner {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .cb-content-area__main {
    .pane-node-body {
      @include media(bp(m)) {
        margin-top: 1rem;
      }
    }
  }
  .cb-content-area__feature-rows {
    margin-top: 0;
  }
  .cb-feature-row-primary {
    .region.cb-padding {
      padding-top: 1em;
    }
  }
  .cb-layout--aside-first-last,
  .cb-layout--aside-first,
  .cb-layout--no-aside {
    .cb-page-content {
      .cb-content-area {
        &__aside-first {
          @include media($bp-only-l) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
        &__main {
          @include media($bp-only-m) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
          @include media($bp-only-l) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
    &.cb-layout--dynamic-features-asides-feature-rows {
      .cb-sidebar-menu--mobile-only {
        @include media($bp-only-l) {
          width: 100%;
          float: none;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  &.cb-list-news--with-topics.cb-list-news--with-search {
    &:not(.panels-ipe-editing) {
      .pane-page-title h1 {
        @include media(bp(m)) {
          margin-bottom: 0;
        }
        @include media(bp(xl)) {
          margin-top: 0;
        }
      }
    }
  }
}

.cb-list-news--with-search.cb-list-news--with-topics {
  .cb-layout--no-aside {
    .cb-page-content.cb-page-content--feature-rows {
      .cb-content-area__main {
        padding-left: 1.5%;
        padding-right: 1.5%;
      }
    }
  }
}
