// Badges Sidebar Styles

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.cb-badges {
        background-color: $sidebar-component-bg-color;
        @include component-sidebar-padding();
        h2 {
          @include component-heading(m);
          @include media(bp(xs)) {
            @include component-heading(l);
          }
          @include media(bp(l)) {
            @include component-heading(xl);
          }
        }
        .cb-badges {
          &__description p {
            color: $sidebar-component-font-color;
            @include component-paragraph(xs);
            @include media(bp(m)) {
              @include component-paragraph(s);
            }
            @include media(bp(l)) {
              @include component-paragraph(m);
            }
          }
          &__content {
            max-width: $sidebar-component-content-max-width;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

// Sidebar specific styles for badges
.cb-content-area__aside-last {
  .pane {
    &.cb-badges {
      &:not(:last-child) {
        margin-bottom: 2em;
      }
      &.cw-with-description {
        .cb-badges {
          &__badges {
            width: 100%;
            &:not(:only-child) {
              margin-right: 0;
            }
          }
        }
      }
      .cb-badges {
        &__badges {
          width: 100%;
          text-align: center;
          display: block;
          .cb-badges__badge--image {
            display: inline-block;
            max-width: 100%;
            float: none;
          }
        }
        &__badge {
          max-width: 100%;
        }
        &__description {
          width: 100%;
          max-width: 100%;
          clear: both;
        }
        &__content {
          &:not(:only-child) {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.cb-badges {
          padding: $sidebar-component-right-rail-padding;
          &:only-child {
            margin-bottom: 0;
          }
          h2 {
            @include component-heading-right-rail();
          }
          .cb-badges {
            &__description {
              p {
                @include component-paragraph-right-rail();
              }
            }
            &__badges {
              display: block;
            }
            &__badge {
              margin-left: auto;
              margin-right: auto;
              &:not(:only-child) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// Drupal panels ipe editing display fixes
.cb-flavor.panels-ipe-editing {
  .cb-content-area__aside-last {
    .pane {
      &.cb-badges {
        padding: 0;
        .panels-ipe-portlet-content {
          @include component-sidebar-padding();
        }
      }
    }
  }
  // right rail
  .cb-layout--aside-last,
  .cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
    .cb-content-area__aside-last {
      @include media(bp(xl)) {
        .pane {
          &.cb-badges {
            padding: 0;
            /* stylelint-disable selector-max-compound-selectors */
            .panels-ipe-portlet-content {
              padding: $sidebar-component-right-rail-padding;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
