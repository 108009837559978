// Unique ID used to bust font cache.
$font-compile-id: unique-id();
$icon-font-family: "#{$icon-font-base-name}-#{$font-compile-id}";

@mixin font-icon-base() {
  font-family: "#{$icon-font-family}";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
}

/// Main Icon mixin.
/// See the [Icons in Pattern Lab](../../pattern-lab/public/?p=atoms-icons).
/// @param {String} $icon - Machine name of icon (filename).
/// @param {String} $pseudo [before] - `before` | `after` The pseudo element to place the icon in.
/// @todo Allow `$pseudo: false` to be declared so we don't have to use a pseudo element if we don't want to.
/// @param {Bool} $text-replace [false]
/// @param {String} $size [inherit]
/// @param {String} $color [inherit]
/// @param {Bool} $block [false] - If `display: block` should be applied.
/// @example scss
/// .class {
///   @include icon('close');
/// }
@mixin icon(
  $icon: "search", // just a default
  $pseudo: before,
  $text-replace: false,
  $size: inherit,
  $color: inherit,
  $block: false
) {
  // Replace text with icon, like classic sprites
  @if $text-replace {
    position: relative;
    right: 9999px;
  }
  // Get around fighting with line-heights
  @if $block {
    display: block;
  }
  &:#{$pseudo} { /* stylelint-disable-line */

    @include font-icon-base();
    content: map-get($font-icons, $icon);
    font-size: $size;
    @if $color != 'remove' {
      color: $color;
    }
    // Replace text with icon, like classic sprites
    @if $text-replace {
      position: absolute;
      height: 100%;
      text-align: center;
      top: 0;
      right: -9999px;
    }

    @content;
  }
}
