// Featured Content Styles Depending on List Number

// Image Alignment Styles

.region {
  .panels-ipe-bundle-featured-content,
  .pane-bundle-featured-content.cb-style {
    .column-first {
      @include component-padding();
    }
    .column-second {
      padding-bottom: 0;
    }
    // Display image and text side by side
    @include media(bp(bs)) {
      @include quantities-all(odd) {
        .paragraphs-item-featured-content {
          &.cw-with-image {
            .cb-layout__component-dynamic {
              flex-direction: row;
            }
            .column-first {
              @include span-columns(8) {
                order: 1;
                padding-right: 0;
              }
            }
            .column-second {
              order: 2;
              margin-left: $spacing--m;
              padding: $spacing--m;
              @include span-columns(4);
            }
          }
        }
      }
      &:only-child {
        .paragraphs-item-featured-content.cw-with-image {
          .column-second {
            padding-bottom: 0;
          }
        }
      }
    }
    @include media(bp(s)) {
      @include quantities-all(odd) {
        .paragraphs-item-featured-content {
          &.cw-with-image {
            .column-first {
              padding-right: 0;
            }
            .column-second {
              padding: $spacing--l;
            }
          }
        }
      }
    }
    @include media(bp(m)) {
      @include quantities-all(odd) {
        &:only-child {
          .paragraphs-item-featured-content {
            &.cw-with-image {
              .column-first {
                padding: $spacing--xl 0 $spacing--xl $spacing--xl;
              }
              .column-second {
                padding: $spacing--xl;
              }
            }
            &:not(.cw-with-image) {
              .column-first {
                padding: $spacing--xl;
              }
            }
          }
        }
        // Display image and text stacked
        &:not(:only-child) {
          .paragraphs-item-featured-content {
            &.cw-with-image {
              .cb-layout__component-dynamic {
                flex-direction: column;
                flex: none;
              }
              .column-second {
                padding: 0;
                margin-left: 0;
                order: 1;
                width: 100%;
                float: none;
                // Fix ie10 and ie11 height stretch issue
                flex: 0 0 auto;
                display: block;
              }
              .column-first {
                padding: $spacing--l;
                order: 2;
                width: 100%;
                float: none;
              }
            }
          }
        }
      }
    }
  }
}

// Home and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .pane-bundle-featured-content.cb-style,
    .panels-ipe-bundle-featured-content {
      &.cb-style {
        @include media(bp(l)) {
          @include quantities-all(1) {
            .paragraphs-item-featured-content {
              &:not(.cw-with-image) {
                .column-first {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }
          }
          // stacked img and text
          @include quantities-all(4) {
            .paragraphs-item-featured-content {
              &.cw-with-image,
              &:not(.cw-with-image) {
                .column-first {
                  padding: $spacing--m;
                }
              }
            }
          }
        }
      }
      // Style 1: Square Img Only
      &.cb-style--1 {
        @include media(bp(l)) {
          // Display image and text on desktop if there's two components
          @include quantities-all(2) {
            .paragraphs-item-featured-content {
              &.cw-with-image {
                .cb-layout__component-dynamic {
                  flex-direction: row;
                }
                .column-first {
                  @include span-columns(8) {
                    order: 1;
                    justify-content: unset;
                    padding-right: 0;
                  }
                }
                .column-second {
                  @include span-columns(4) {
                    order: 2;
                    padding: $spacing--l $spacing--l $spacing--l 0;
                  }
                }
              }
            }
          }
        }
        @include media(bp(xl)) {
          @include quantities-all(2) {
            .paragraphs-item-featured-content {
              &.cw-with-image {
                .column-first {
                  padding: $spacing--xl 0 $spacing--xl $spacing--xl;
                }
                .column-second {
                  padding: $spacing--xl $spacing--xl $spacing--xl 0;
                }
              }
              &:not(.cw-with-image) {
                .column-first {
                  padding: $spacing--xl;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .pane-bundle-featured-content.cb-style,
        .panels-ipe-bundle-featured-content {
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          @include media(bp(l)) {
            @include quantities-all(3,5) {
              .paragraphs-item-featured-content {
                &.cw-with-image,
                &:not(.cw-with-image) {
                  .column-first {
                    padding: $spacing--m;
                  }
                }
              }
            }
            // Six or more components in a region:
            // always "1/3" component width
            &:first-child:nth-last-child(n+6),
            &:nth-child(2):nth-last-child(n+5),
            &:nth-child(3):nth-last-child(n+4),
            &:nth-child(4):nth-last-child(n+3),
            &:nth-child(5):nth-last-child(n+2),
            &:nth-child(n+6) {
              .paragraphs-item-featured-content {
                &.cw-with-image,
                &:not(.cw-with-image) {
                  .column-first {
                    padding: $spacing--m;
                  }
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
  &.cb-layout--aside-first,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .pane-bundle-featured-content.cb-style,
        .panels-ipe-bundle-featured-content {
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          @include media(bp(l)) {
            @include quantities-all(1) {
              .paragraphs-item-featured-content {
                &:not(.cw-with-image) {
                  max-width: 100%;
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
