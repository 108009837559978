// Badges with No Description
// Grid layout with equal container width/height
.cb-badges {
  &:not(.cw-with-description) {
    .cb-badges {
      &__badges {
        width: 100%;
        .lt-ie10 & {
          // IE fix: to center align badges
          text-align: center;
        }
      }
      &__badge {
        &:not(:only-child) {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2em 1em;
          @include media(bp(s)) {
            width: 33.3333%;
          }
        }
        &:only-child {
          margin: 0 auto;
          width: auto;
        }
        @include quantities-all(2) {
          padding-top: 0;
          padding-bottom: 0;
          @include media(bp(bs)) {
            width: 50%;
            &:first-child {
              .field-name-field-image-image {
                text-align: right;
              }
            }
            &:last-child {
              .field-name-field-image-image {
                text-align: left;
              }
            }
            img {
              max-width: 220px;
            }
          }
        }
      }
    }
    // Region max 4 columns: 4 badges per row
    .region.cb-max-columns--4 & {
      .cb-badges__badge:not(:only-child) {
        @include media(bp(s)) {
          width: 50%;
        }
        @include media(bp(l)) {
          width: 25%;
          img {
            // IE fix: prevent img overflow
            max-width: 216px;
            width: auto;
          }
        }
      }
    }
  }
}

// Prevent badge image grid overflow
// Featured Layouts
.cb-content-area__feature-rows {
  .region.cb-max-columns--3 {
    .cb-badges:not(.cw-with-description) {
      .cb-badges__badge:not(:only-child) {
        /* stylelint-disable selector-max-compound-selectors */
        @include media(bp(l)) {
          img {
            max-width: 185px;
            width: auto;
          }
        }
        /* stylelint-enable */
      }
    }
  }
  .region.cb-max-columns--4 {
    .cb-badges:not(.cw-with-description) {
      .cb-badges__badge:not(:only-child) {
        /* stylelint-disable selector-max-compound-selectors */
        @include media(bp(l)) {
          img {
            max-width: 134px;
            width: auto;
          }
        }
        /* stylelint-enable */
      }
    }
  }
}
