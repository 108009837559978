.cb-cups-by-specialties {
  // dropdown filters
  .views-exposed-widget {
    // stylelint-disable selector-class-pattern
    &:not(.views-reset-button):not(.views-widget-filter-search_api_views_fulltext) {
      @include media(bp(s)) {
        &:nth-child(2) {
          margin-top: 0;
        }
      }
      &:first-child {
        margin-top: 0;
      }
    }
    // stylelint-enable selector-class-pattern
    &.views-widget-checkboxes {
      margin-top: $spacing--m;
      @include media(bp(s)) {
        margin-top: $spacing--s;
      }
    }
  }
}
