// Section page nav
.cb-section-menu {
  @include media(bp(xl)) {
    .pane-title {
      &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        top: calc(50% - 10px);
        right: -10px;
      }
    }
  }
}

// Sections menu color styles
.cb-section-page--style-1 {
  .cb-primary-navigation > .cb-container__inner {
    @include section-menu-style(section-style-1);
  }
}

.cb-section-page--style-2 {
  .cb-primary-navigation > .cb-container__inner {
    @include section-menu-style(section-style-2);
  }
}

.cb-section-page--style-3 {
  .cb-primary-navigation > .cb-container__inner {
    @include section-menu-style(section-style-3);
  }
}

.cb-section-page--style-4 {
  .cb-primary-navigation > .cb-container__inner {
    @include section-menu-style(section-style-4);
  }
}

.cb-section-page--style-5 {
  .cb-primary-navigation > .cb-container__inner {
    @include section-menu-style(section-style-5);
  }
}
