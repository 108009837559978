// Sections header
.cb-flavor--sections {
  .cb-site-header {
    .cb-nameplate {
      &:not(:last-child) {
        // Decrease header height when section nav is present
        @include media(bp(xl)) {
          padding-top: #{$header-nameplate-l-padding-top * 0.75};
          padding-bottom: #{$header-nameplate-l-padding-bottom * 0.75};
        }
      }
      // Section header height when section nav is not present
      &:last-child {
        .cb-primary-menu {
          font-weight: 700;
          @include media(bp(xl)) {
            font-size: 1.1rem;
          }
          @include media(bp(xxl)) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
