// Promoted News, Events, Announcements, Blog Background and Border Styles

@include component-region-bg-styles(1) {
  .cb-list-news--promoted,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted,
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    .more-link {
      a,
      a::after {
        color: a11yrize(color(action, m), $region-bg-1-color);
      }
    }
  }
}

@include component-region-bg-styles(2) {
  .cb-list-news--promoted,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted,
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    // icon
    h2.pane-title::before {
      color: $component-bg-2-border-color;
    }
  }
}

@include component-region-bg-styles(3) {
  .cb-list-news--promoted,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted,
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    // icon
    h2.pane-title::before {
      color: $component-bg-3-border-color;
    }
  }
}

@include component-region-bg-styles(2,3) {
  .cb-list-news--promoted,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted,
  .cb-list-events--promoted,
  .panels-ipe-portlet-wrapper .cb-list-events--promoted {
    h2.pane-title {
      color: #fff;
    }
    .more-link {
      a,
      a::after {
        color: #fff;
      }
    }
  }
}
