// stylelint-disable selector-class-pattern
.view-cola-events-search {
  .views-exposed-widgets {
    display: flex;
    flex-wrap: wrap;
  }
  // Filters
  .views-widget-filter-field_event_categories,
  .views-widget-filter-field_event_date_value2_1 {
    @include span-columns(12) {
      position: relative;
      margin-right: 0;
    }
    @include media(bp(bs)) {
      @include span-columns(6);
    }
    @include media(bp(m)) {
      margin-bottom: 0;
      @include span-columns(4);
    }
    select {
      width: 100%;
    }
    label {
      z-index: z(contentBottom);
      position: absolute;
      top: 6px;
      font-size: 1em;
      font-weight: 600;
      color: color(neutral-brand, lo);
    }
  }
  .views-widget-filter-field_event_categories {
    order: 2;
    @include media(bp(xl)) {
      margin-bottom: 0;
      @include span-columns(5);
    }
    label {
      left: 14px;
      background: $dropdown-select-bg-color;
    }
  }
  // Calendar
  .views-widget-filter-field_event_date_value2_1 {
    order: 3;
    @include media(bp(bs)) {
      margin-right: 0;
    }
    @include media(bp(m)) {
      margin-right: 3.36134%;
    }
    @include media($bp-only-l) {
      @include span-columns(3.5);
    }
    @include media(bp(xl)) {
      margin-bottom: 0;
      @include span-columns(3);
    }
    label {
      left: 38px;
    }
    input[type="text"] {
      z-index: z(content);
      position: relative;
      width: 100%;
      padding: 5px 12px 3px 38px;
      background: transparent;
      border: solid 1px color(neutral-brand, m);
      &:hover {
        background: transparent;
      }
    }
    .container-inline-date {
      position: relative;
      @include icon($icon: "calendar", $pseudo: "before", $size: 1.2em, $color: color(primary, brand)) {
        position: absolute;
        top: 9px;
        left: 14px;
        z-index: z(contentTop);
        pointer-events: none;
      }
    }
    .form-item-date {
      margin-right: 0;
    }
    .form-item-date,
    .date-padding,
    .form-item-date-date {
      width: 100%;
    }
    .description {
      @include visually-hidden();
    }
  }
  // Search and Clear All buttons
  .views-submit-button,
  .views-reset-button {
    margin-bottom: 0;
    @include span-columns(6);
    @include media(bp(m)) {
      width: 14.54622%;
    }
    @include media($bp-only-l) {
      width: 16.69958%;
    }
    input[type="submit"] {
      width: 100%;
      margin-top: 0;
    }
  }
  .views-reset-button {
    order: 4;
    input[type="submit"] {
      background: #fff;
      color: $text-link-color;
      @include media(bp(m)) {
        padding-left: 0.7em;
        padding-right: 0.7em;
      }
    }
    &:last-child {
      margin-right: 3.36134%;
      @include media(bp(m)) {
        margin-right: 2%;
      }
    }
  }
  .views-submit-button {
    order: 5;
    margin-right: 0;
  }
}

// Styles for events listing pages without body content.
// Displays a button to show the events form.
.cb-list-events--without-body {
  .cb-content-area__main {
    .pane-page-title {
      // Ensure page title won't overlap with button.
      @include media(bp(xl)) {
        max-width: calc(100% - 12.5rem);
        margin-bottom: 0;
      }
    }
  }
  .cb-list-events__content {
    > .view-filters {
      margin-top: 1em;
      margin-bottom: 1em;
      > .show-hide-controller {
        margin-top: 0;
        text-align: center;
        width: 100%;
        @include btn($style: outline, $size: s);
        @include icon("search");
        @include media(bp(xl)) {
          float: right;
          margin-top: -4.5rem;
          width: auto;
        }
        &::before {
          line-height: 1.7;
          margin-top: -0.125em;
          margin-right: 0.25em;
        }
        &::after {
          display: none;
        }
        .show-hide__action {
          @include visually-hidden();
        }
        .show-hide__description {
          display: none;
        }
      }
      form {
        position: relative;
        border: 2px solid color(primary, brand);
        border-radius: 4px;
        @include media(bp(xl)) {
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            top: -28px;
            right: 4rem;
            border-width: 15px;
            border-bottom: 12.5px solid color(primary, brand);
          }
        }
      }
    }
  }
}
// stylelint-enable selector-class-pattern
