.node-type-event {
  .cb-content {
    .cb-container:not(.cb-system):not(.cb-feature-primary) {
      .cb-container__inner {
        max-width: 47.5rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .cb-feature-primary {
    @include media(bp(m)) {
      padding: 0 $page-margins;
    }
    .cb-container__inner {
      max-width: $max-width;
      margin-bottom: 1.5em;
      @include media(bp(m)) {
        margin-bottom: 2em;
      }
      @include media(bp(l)) {
        margin-bottom: 3em;
      }
    }
    .panel-separator {
      display: none;
    }
    .pane-page-title {
      + .panel-separator {
        display: block;
      }
      h1 {
        text-align: center;
        @include media(bp(m)) {
          margin-top: 1.875rem;
          margin-bottom: 2.875rem;
        }
        @include media(bp(l)) {
          margin-top: 3.125rem;
        }
      }
    }
    .pane-link {
      padding: 1em 0;
      padding-left: $page-margins;
      border-top: $border--s;
      border-bottom: $border--s;
      @include media(bp(m)) {
        padding-left: 0;
      }
      a {
        @include icon("arrow-left");
        &::before {
          margin-right: 0.5em;
          display: inline-block;
          vertical-align: middle;
          text-decoration: none;
          font-size: 0.9em;
          padding-bottom: 0.1em;
        }
        &:hover::before {
          @include animated($duration: 1s, $name: wobble);
        }
      }
    }
  }
  .cb-feature-tertiary {
    padding: 0 $page-margins;
  }
  .pane-node-field-event-date {
    font-family: $font--text--alt;
    font-size: 1em;
    font-weight: 700;
    @include media(bp(m)) {
      font-size: 1.75em;
    }
    ~ .pane-node-field-event-date {
      font-weight: 600;
      font-size: 1em;
      @include media(bp(m)) {
        font-size: 1.25em;
      }
    }
  }
  .pane-node-field-event-venue {
    font-size: 1em;
    @include media(bp(m)) {
      font-size: 1.25em;
    }
  }
  .pane-node-body {
    font-size: 1.125em;
    margin-top: 1.75em;
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: $border--xs;
    @include media(bp(m)) {
      font-size: 1.25em;
    }
  }
  .event-contact {
    h2 {
      font-size: 1.3125em;
      font-weight: 700;
      margin-bottom: 0.7143em;
      @include media(bp(m)) {
        font-size: 1.75em;
      }
    }
  }
  .pane-node-field-event-contact-name,
  .pane-node-field-event-contact-phone,
  .pane-node-field-event-contact-email {
    font-family: $font--text--alt;
    font-size: 1em;
    @include media(bp(m)) {
      font-size: 1.125em;
    }
  }
}
