.cb-list-services--standard {
  .pane-views-row.classy-panel-styles,
  .panels-ipe-views-row .pane-views-row {
    .cb-layout__component-dynamic {
      width: 100%;
      overflow: visible;
      @include clearfix();
    }
    > ul {
      > li {
        margin-bottom: 0;
        padding: 0.75em;
        border: $border--xs;
        @include media(bp(s)) {
          border: none;
          padding: 0;
        }
        &:not(:first-child) {
          margin-top: 1.75em;
          @include media(bp(s)) {
            padding-top: 1.5em;
            border-top: $border--xs;
          }
        }
      }
    }
    .cb-contact-information {
      overflow: visible;
    }
    .cb-list-services {
      &__title {
        h3 {
          font-size: 1.25rem;
          margin: 0 0 0.25em 0;
          line-height: 1.3;
          @include media(bp(m)) {
            font-size: 1.5rem;
          }
        }
      }
      &__type {
        .field-name-field-services-type {
          background-color: lighten(color(accent-green, xhi), 39%);
          color: color(accent-green);
          color: a11yrize(color(accent-green), lighten(color(accent-green, xhi), 39%));
          display: inline-block;
          padding: 0.2em 0.7em;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
      &__affiliation,
      &__directors,
      &__contact {
        .pane-title {
          font-size: 1em;
        }
      }
      &__directors,
      &__contact {
        margin-top: 1.25em;
        @include media(bp(m)) {
          margin-top: 1em;
        }
      }
      &__affiliation {
        margin-top: 1em;
        .field-name-field-services-affiliation {
          font-weight: 700;
          color: color(neutral-brand, m);
        }
      }
      &__summary {
        margin: 1.25em 0 0 0;
        p {
          font-size: 1rem;
          line-height: 1.7;
        }
      }
      &__directors {
        padding-right: 3.5%;
        &:last-child {
          @include media(bp(s)) {
            float: none;
            width: 100%;
            margin-right: 0;
            padding: 0;
          }
        }
        .field-name-field-person-full-name {
          font-weight: 600;
          line-height: 1.5;
          margin-bottom: 0.25em;
          @include h4();
        }
        /* stylelint-disable selector-max-compound-selectors */
        .cw-with-images {
          .cb-person > .content {
            @include media(bp(s)) {
              justify-content: flex-start;
            }
          }
        }
        /* stylelint-enable */
        .field-name-field-postal-address-email {
          a {
            @include word-wrap();
          }
        }
      }
      &__directors,
      &__directors + .cb-list-services__contact {
        @include media(bp(s)) {
          float: left;
          width: 50%;
        }
      }
      &__directors + .cb-list-services__contact {
        @include media(bp(s)) {
          padding-left: 3.5%;
          border-left: $border--xs;
        }
      }
      &__contact {
        .organisation-name {
          font-weight: 600;
          line-height: 1.5;
          margin-bottom: 0.25em;
          @include h4();
        }
      }
    }
  }
}
