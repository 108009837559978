// Featured Content Typography Styles

// Default more link styles
.region,
.cb-content-area__main {
  .pane-bundle-featured-content.cb-style,
  .panels-ipe-bundle-featured-content {
    .pane-node-links {
      a {
        @include component-link-title(s);
        @include icon($icon: "caret-right", $pseudo: "after", $size: 0.8rem) {
          margin-left: 0.25em;
          margin-top: -0.1em;
        }
        &:hover::after {
          @include animated($duration: 1s, $name: wobble);
        }
      }
    }
  }
}

// Standardized component typography sizes

.region,
.cb-content-area__main {
  .pane-bundle-featured-content.cb-style,
  .panels-ipe-bundle-featured-content {
    h3 {
      @include component-heading(s);
    }
    .pane-node-field-subtitle h4 {
      @include component-subtitle(xs);
    }
    .field-name-field-summary,
    .field-name-field-summary p {
      @include component-paragraph(xs);
    }
  }
}

.region {
  .pane-bundle-featured-content.cb-style,
  .panels-ipe-bundle-featured-content {
    @include media(bp(bs)) {
      @include quantities-all(odd) {
        h3 {
          @include component-heading(m);
        }
      }
    }
    @include media(bp(m)) {
      @include quantities-all(even) {
        h3 {
          @include component-heading(l);
        }
        .field-name-field-summary,
        .field-name-field-summary p {
          @include component-paragraph(s);
        }
      }
      @include quantities-all(odd) {
        &:not(:only-child) {
          h3 {
            @include component-heading(s);
          }
          .pane-node-field-subtitle h4 {
            @include component-subtitle(xs);
          }
          .field-name-field-summary,
          .field-name-field-summary p {
            @include component-paragraph(xs);
          }
        }
      }
      @include quantities-all(odd) {
        &:only-child {
          h3 {
            @include component-heading(xl);
          }
          .pane-node-field-subtitle h4 {
            @include component-subtitle(l);
          }
          .field-name-field-summary,
          .field-name-field-summary p {
            @include component-paragraph(m);
          }
        }
      }
    }
  }
  &.cb-max-columns--3 {
    .panels-ipe-bundle-featured-content,
    .pane-bundle-featured-content.cb-style {
      @include media(bp(l)) {
        // 3+ items: fixed 1/3 page width for each
        &:not(:only-child) {
          h3 {
            @include component-heading(m);
          }
        }
        @include quantities-all(2) {
          h3 {
            @include component-heading(l);
          }
          .field-name-field-summary,
          .field-name-field-summary p {
            @include component-paragraph(s);
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .pane-bundle-featured-content.cb-style,
    .panels-ipe-bundle-featured-content {
      @include media(bp(l)) {
        &:only-child {
          .paragraphs-item-featured-content {
            &.cw-with-image {
              // vertically center text
              .column-first {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
        }
      }
    }
    &.cb-max-columns--4 {
      .pane-bundle-featured-content.cb-style,
      .panels-ipe-bundle-featured-content {
        @include media(bp(l)) {
          // 4+ items: fixed 1/4 page width for each
          &:not(:only-child) {
            h3 {
              @include component-heading(s);
            }
            .pane-node-field-subtitle h4 {
              @include component-subtitle(xs);
            }
            .field-name-field-summary,
            .field-name-field-summary p {
              @include component-paragraph(xs);
            }
            .pane-node-links a {
              @include component-link-title(xs);
            }
          }
          @include quantities-all(2) {
            h3 {
              @include component-heading(l);
            }
            .field-name-field-summary,
            .field-name-field-summary p {
              @include component-paragraph(s);
            }
          }
        }
      }
    }
    &.cb-max-columns--3,
    &.cb-max-columns--4 {
      .pane-bundle-featured-content.cb-style,
      .panels-ipe-bundle-featured-content {
        @include media(bp(l)) {
          @include quantities-all(3) {
            h3 {
              @include component-heading(m);
            }
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      &.cb-max-columns--3,
      &.cb-max-columns--4 {
        .pane-bundle-featured-content.cb-style,
        .panels-ipe-bundle-featured-content {
          @include quantities-all(2,4) {
            h3 {
              @include media(bp(l)) {
                @include component-heading(m);
              }
            }
          }
          // Six or more components in a region:
          // always "1/3" component width
          &:first-child:nth-last-child(n+6),
          &:nth-child(2):nth-last-child(n+5),
          &:nth-child(3):nth-last-child(n+4),
          &:nth-child(4):nth-last-child(n+3),
          &:nth-child(5):nth-last-child(n+2),
          &:nth-child(n+6) {
            h3 {
              @include media(bp(l)) {
                @include component-heading(s);
              }
            }
            .field-name-field-summary {
              @include media(bp(xl)) {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}

// Main content only
.cb-content-area__main {
  .pane-bundle-featured-content.cb-style,
  .panels-ipe-bundle-featured-content {
    @include media(bp(bs)) {
      h3 {
        @include component-heading(m);
      }
    }
    @include media(bp(m)) {
      h3 {
        @include component-heading(xl);
      }
      .pane-node-field-subtitle h4 {
        @include component-subtitle(l);
      }
      .field-name-field-summary,
      .field-name-field-summary p {
        @include component-paragraph(m);
      }
    }
  }
}

.cb-layout--aside-first-last {
  .cb-content-area__main {
    .pane-bundle-featured-content.cb-style,
    .panels-ipe-bundle-featured-content {
      @include media(bp(l)) {
        h3 {
          @include component-heading(l);
        }
        .pane-node-field-subtitle h4 {
          @include component-subtitle(m);
        }
        .field-name-field-summary,
        .field-name-field-summary p {
          @include component-paragraph(s);
        }
      }
    }
  }
}
