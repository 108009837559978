// Base styles shared between news header "controls": Browse Topics and Simple Search
.cb-news-simple-search,
.cb-news-browse-topics--compact {
  width: 100%;
  margin-bottom: 0.5em;
  @include media(bp(m)) {
    margin-bottom: 0;
  }
  h2 {
    .no-js & {
      display: block;
      position: static;
      height: 100%;
      width: 100%;
    }
  }
  button {
    @include btn($style: outline, $size: s);
    @include override-nested() {
      color: color(primary, lo);
    }
    &.show-hide-controller--open {
      border-radius: 4px 4px 0 0;
      @include media(bp(m)) {
        border-radius: 4px;
      }
      + .cb-panel-container {
        border-radius: 0 0 2px 2px;
        @include media(bp(xl)) {
          border-radius: 2px;
        }
      }
    }
    span {
      &.show-hide__action {
        @include visually-hidden();
      }
      &.show-hide__description {
        &::before {
          margin-right: 0.5em;
          margin-top: -0.25em;
        }
      }
    }
    &:focus {
      background: transparent;
      color: color(primary, lo);
    }
    &:hover {
      background: color(primary, brand);
      border-color: color(primary, brand);
      color: #fff;
    }
  }
  .cb-panel-container {
    box-shadow: $box-shadow;
    // No javascript fallback
    .no-js & {
      @include media(bp(xl)) {
        position: static;
      }
    }
  }
}

// Fix ipe
.panels-ipe-editing {
  &.cb-list-news--with-topics,
  &.cb-list-news--with-search {
    .cb-news-simple-search,
    .cb-news-browse-topics--compact {
      button {
        position: static;
      }
    }
  }
}

.cb-list-news--with-topics:not(.cb-list-news--with-search) {
  .panels-ipe-portlet-content {
    .cb-news-browse-topics--compact {
      .cb-panel-container {
        @include media(bp(m)) {
          margin-top: 0;
        }
      }
    }
  }
}
