.cb-payment-button {
  &:not(.cb-payment-button--compact):not(.cb-payment-button--add-to-cart) {
    padding: 1em 1em 0 1em;
    background: lighten(color(secondary, xhi), 20%);
    border-top: 2px solid lighten(color(secondary, xhi), 15%);
    label {
      margin-top: 1em;
      @include media(bp(m)) {
        margin-top: 1px;
        float: left;
        width: 30%;
        clear: both;
        padding: 8px;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    select {
      // FF, IE will display the select box shorter without this.
      min-height: calc(1.7em + 16px);
    }
    input[type="text"],
    select {
      @include media(bp(m)) {
        width: 70%;
        background-position: 96%;
        float: right;
        margin-bottom: 1em;
        border-color: darken(color(secondary, m), 5%);
      }
    }
    input[type="submit"] {
      width: calc(100% + 2em);
      margin-left: -1em;
      padding: 1em;
      border-radius: 0;
      box-shadow: none;
      margin-top: 1.5em;
      clear: both;
      display: inline-block;
      background: $btn-bg-color-interior-pg-component;
      color: #fff;
      &:hover,
      &:focus {
        color: #fff;
        background: $btn-hover-bg-color-interior-pg-component;
        outline-color: $btn-focus-outline-color-interior-pg-component;
      }
    }
  }
  &:not(:only-child) {
    margin-top: 3em;
    padding-top: 2em;
    border-top: solid 2px $border-light;
    clear: both;
  }
  &.cb-payment-button--compact {
    input[type="submit"] {
      margin: 0;
      @include btn($size: m);
      &:focus {
        outline-color: color(secondary, lo);
      }
    }
  }
  &.cb-payment-button--add-to-cart:not(.cb-payment-button--compact) {
    padding: 1em 1em 0 1em;
    position: relative;
    border-top: 2px solid color(neutral-brand, xhi);
    background: lighten(color(neutral-brand, xxhi), 12%);
    @include media(bp(s)) {
      padding: 1em 40% 1em 1em;
    }
    @include media(bp(m)) {
      padding: 1em 25% 1em 1em;
    }
    &::after {
      @include media(bp(s)) {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        bottom: auto;
        left: auto;
        right: calc(40% - 12px - 1em);
        transform: translate(0, -50%);
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 12px solid lighten(color(neutral-brand, xxhi), 12%);
        border-right: none;
      }
      @include media(bp(m)) {
        border-width: 20px 0 20px 20px;
        right: calc(25% - 18px - 1em);
      }
    }
    label {
      margin-top: 1em;
    }
    input[type="submit"] {
      width: calc(100% + 2em);
      white-space: normal;
      float: none;
      margin-top: 1.5em;
      margin-left: -1em;
      padding: 1.5em 1em;
      border-radius: 0;
      box-shadow: none;
      @include media(bp(s)) {
        position: absolute;
        width: calc(40% - 1em);
        height: 100%;
        top: 0;
        right: 0;
        padding: 0.6em 1.2em 0.6625em 20px;
        margin: 0;
      }
      @include media(bp(m)) {
        width: calc(25% - 1em);
        padding-left: 28px;
      }
      &:hover,
      &:focus {
        background: $btn-hover-bg-color-interior-pg-component;
      }
      &:focus {
        outline-color: $btn-focus-outline-color-interior-pg-component;
      }
    }
  }
  &__form {
    border-radius: 2px;
    @include clearfix();
  }
  input[type="text"],
  select {
    border: 1px solid darken(color(neutral-brand, m), 3%);
  }
  select {
    width: 100%;
    padding: 8px 34px 8px 14px;
    @include media(bp(s)) {
      background-position: 98%;
    }
  }
  input[type="text"] {
    width: 100%;
    height: 44px;
  }
  input[type="submit"] {
    clear: both;
    display: block;
    margin-top: 1em;
  }
  .field-name-field-title {
    font-size: 1.25rem;
    color: color(neutral-brand, xxlo);
    @include media(bp(m)) {
      font-size: 1.75rem;
    }
    .cb-structured-content & {
      // Override default structured content spacing for first child headlines.
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .field-name-field-body {
    margin-bottom: 0;
    line-height: 1.5;
    @include media(bp(m)) {
      margin-bottom: 2em;
    }
    p {
      font-size: 0.85rem;
      font-family: $font--text--alt;
      font-weight: 400;
      @include media(bp(m)) {
        font-size: 1rem;
      }
      a {
        text-decoration: underline;
        color: color(action, lo);
        font-family: $font--text--alt;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
