// Featured Content Background Styles

// Default
.region,
.cb-content-area__main {
  .pane-bundle-featured-content.cb-style,
  .panels-ipe-bundle-featured-content {
    background: $component-bg-auto-color;
    border: $component-border-style;
    .pane-node-field-subtitle h4 {
      color: color(neutral, m);
    }
    &:hover {
      background: $background-solid-xlight;
      h3 a {
        color: a11yrize(color(action, m), $background-solid-xlight);
      }
      .pane-node-field-subtitle h4 {
        color: a11yrize(color(neutral, m), $background-solid-xlight);
      }
    }
  }
}

@include component-region-bg-styles(1,2,3) {
  .pane-bundle-featured-content.cb-style,
  .panels-ipe-bundle-featured-content {
    border: none;
  }
}
