// CTA Sidebar Styles

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.pane-bundle-cta,
      &.panels-ipe-bundle-cta {
        background: transparent;
        .field-name-field-title h2 {
          color: $sidebar-component-title-color;
          @include component-heading(s);
          @include media(bp(bs)) {
            @include component-heading(m);
          }
          @include media(bp(s)) {
            @include component-heading(l);
          }
        }
        .field-name-field-cta-description {
          padding-bottom: 0;
          p {
            color: $sidebar-component-font-color;
            @include component-paragraph(xs);
            @include media(bp(s)) {
              @include component-paragraph(s);
            }
          }
        }
        .paragraphs-item-cta {
          background: $sidebar-component-bg-color;
          @include component-sidebar-padding();
          a {
            color: $sidebar-component-link-color;
          }
          &:not(.cw-with-image):not(.cw-cta-phone) {
            &.cw-with-description {
              @include media(bp(l)) {
                .field-name-field-cta-description p {
                  @include component-paragraph(m);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar specific styles for CTAs
.cb-content-area__aside-last {
  .pane-bundle-cta.pane,
  .panels-ipe-bundle-cta {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
    .field-name-field-title {
      margin-bottom: 0;
    }
    .content {
      padding-left: 0;
      padding-right: 0;
      position: relative;
    }
    // To override default cta tablet styles
    .cw-with-image {
      @include media(bp(m)) {
        min-height: auto;
        position: relative;
        text-align: left;
      }
      @include media(bp(l)) {
        padding-right: 20em;
        padding-left: 0;
      }
      .field-name-field-title,
      .field-name-field-cta-description,
      .field-name-field-cta-link {
        @include media($bp-only-m) {
          padding-right: calc((100vw - 2 * #{$page-margins}) * 0.3434 + 2em);
        }
      }
      h2 {
        @include media(bp(m)) {
          text-align: left;
        }
      }
      .field-name-field-cta-image {
        margin-top: $spacing--m;
        @include media(bp(m)) {
          right: 0;
          top: 0;
          overflow: hidden;
          position: absolute;
          margin-bottom: 0;
          margin-top: 0;
          width: calc((100vw - 2 * #{$page-margins}) * 0.3434);
          height: calc((100vw - 2 * #{$page-margins}) * 0.1931);
        }
        @include media(bp(l)) {
          max-width: 18em;
        }
      }
      .content {
        @include media(bp(m)) {
          min-height: calc((100vw - 2 * #{$page-margins}) * 0.1931);
        }
        @include media(bp(l)) {
          min-height: 160px;
        }
      }
    }
    // adjust container height when cta has a square image
    &.cb-style--1 {
      .paragraphs-item-cta:not(.cw-cta-phone) {
        @include media(bp(m)) {
          .field-name-field-cta-image {
            overflow: visible;
            width: 290px;
            height: 290px;
          }
          &.cw-with-image {
            img {
              max-width: 300px;
              padding-right: 0;
              padding-top: 0;
            }
            .content {
              min-height: 290px;
            }
            .field-name-field-title,
            .field-name-field-cta-link,
            .field-name-field-cta-description {
              padding-right: 320px;
            }
          }
        }
        img {
          max-width: 460px;
          margin: 0 auto;
        }
      }
    }
    // Override default cta prominence styles to fix image position Safari issue
    &:not(:only-child) {
      &:first-child:nth-last-child(n+3),
      &:nth-child(2):not(:last-child),
      &:nth-child(n+3) {
        @include media(bp(l)) {
          .field-name-field-cta-image {
            position: absolute;
          }
        }
        .paragraphs-item-cta:not(.cw-cta-phone) {
          .field-name-field-cta-link {
            a {
              @include btn($style: outline, $size: s);
              @include override-nested() {
                background: transparent;
                color: $sidebar-component-link-color;
              }
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .paragraphs-item-cta {
      text-align: center;
      font-family: $font--text--alt;
      border-radius: 2px;
      display: block;
      overflow: visible;
      @include media(bp(m)) {
        text-align: left;
      }
      .content {
        max-width: $sidebar-component-content-max-width;
        margin: 0 auto;
      }
      .field-name-field-title {
        h2 {
          margin-bottom: 1rem;
        }
      }
      .field-name-field-cta-image {
        margin-bottom: 1em;
        box-shadow: none;
      }
      .field-name-field-cta-description {
        margin-bottom: 0.25em;
        p {
          text-align: center;
          @include media(bp(m)) {
            text-align: left;
          }
        }
        a {
          text-decoration: none;
          font-family: $font--text--alt;
        }
      }
      .field-name-field-cta-link {
        display: inline-block;
        margin-top: 0;
        a {
          font-size: 1rem;
          font-weight: 700;
          &::after {
            margin-left: 0.5em;
          }
          @include media(bp(m)) {
            font-size: 1.125rem;
          }
        }
      }
      &.cw-with-image {
        max-width: 100%;
        .field-name-field-cta-description {
          p {
            @include media(bp(l)) {
              text-align: left;
            }
          }
        }
      }
      &:not(.cw-cta-phone) {
        .field-name-field-cta-link-prefix,
        .field-name-field-cta-link-suffix {
          font-size: 0.9rem;
          font-weight: 600;
          color: color(neutral-brand, m);
        }
        .field-name-field-cta-link {
          padding-bottom: 0;
          margin-top: 0.5em;
          display: inline-block;
          a {
            @include btn($style: outline, $size: s);
            @include override-nested() {
              background: transparent;
              text-align: center;
              color: $sidebar-component-link-color;
            }
            &:hover {
              color: #fff;
            }
          }
        }
        // Cta with link only
        &:not(.cw-with-title):not(.cw-with-image):not(.cw-with-description) {
          padding: 0;
          box-shadow: none;
          background: none;
          .field-name-field-cta-link {
            margin: 0;
            width: 100%;
            padding-bottom: 0;
            a {
              @include btn($style: outline, $size: s);
              @include override-nested() {
                background: transparent;
                width: 100%;
                color: $sidebar-component-link-color;
              }
              &:hover {
                color: #fff;
              }
            }
          }
          .content {
            min-height: auto;
            max-width: 100%;
          }
        }
        &:not(.cw-with-image):not(.cw-with-description) {
          .field-name-field-cta-link {
            margin-top: 0;
          }
        }
      }
      .cw-cta__overlay-link {
        display: none;
      }
    }
  }
}

// Sidebar displayed beneath main content on small desktop:
// CTA image and text side by side
.cb-layout--aside-last,
.cb-layout--aside-first-last {
  .cb-content-area__aside-last {
    .pane {
      &.pane-bundle-cta,
      &.panels-ipe-bundle-cta {
        @include media(bp(l)) {
          .cw-with-image {
            /* stylelint-disable selector-max-compound-selectors */
            .field-name-field-title,
            .field-name-field-cta-description,
            .field-name-field-cta-link {
              padding-right: 20rem;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
