// Main content area styles on interior pages

.cb-content-area__main {
  .cb-badges {
    &.cw-with-description {
      .cb-badges {
        &__content {
          flex-direction: column;
        }
        &__badges {
          &:not(:only-child) {
            @include media(bp(l)) {
              max-width: 100%;
            }
          }
        }
        &__description {
          @include media(bp(l)) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

// Layout with both sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--aside-first-last {
  .cb-content-area__main {
    .cb-badges {
      @include media(bp(l)) {
        h2 {
          @include component-heading(xl);
        }
        .cb-badges__description p {
          @include component-paragraph(m);
        }
      }
    }
  }
}
