// Promoted Events Base Styles

.cb-list-events--promoted {
  .view-content {
    > ul {
      > li {
        margin-bottom: 1em;
      }
    }
  }
  // Empty events list
  .view-empty {
    min-height: $component-content-min-height;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0;
      max-width: 100%;
      font-family: $font--text--alt;
      text-align: center;
      @include component-paragraph(l);
    }
  }
}

.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-page-content .cb-content-area__main {
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      box-shadow: none;
      background-color: transparent;
      /* stylelint-disable selector-max-compound-selectors */
      .view {
        margin-top: 0;
      }
      .view-content {
        > ul {
          max-width: 100%;
        }
      }
      .view-content,
      .view-empty {
        margin-top: $spacing--m;
      }
      .view-empty,
      li {
        padding: 0;
      }
      li {
        width: 100%;
        &:only-child {
          .cb-layout__component-dynamic {
            text-align: center;
          }
        }
      }
      .column-first {
        display: flex;
        flex-direction: column;
      }
      .cb-layout__component-dynamic {
        height: 100%;
      }
      .cb-list-events {
        &__time,
        &__title,
        &__location {
          margin-left: $spacing--m;
          margin-right: $spacing--m;
          @include media(bp(s)) {
            margin-left: $spacing--l;
            margin-right: $spacing--l;
          }
        }
        &__date {
          order: 1;
          padding: $spacing--m $spacing--m 0 $spacing--m;
          font-weight: 400;
          @include media(bp(s)) {
            padding: $spacing--l $spacing--l 0 $spacing--l;
          }
        }
        &__time {
          order: 2;
          margin-bottom: $spacing--s;
        }
        &__title {
          order: 3;
          flex: 1 0 auto;
          padding-bottom: $spacing--m;
          @include media(bp(s)) {
            padding-bottom: $spacing--l;
          }
        }
        &__location {
          order: 4;
          margin-bottom: $spacing--m;
          @include media(bp(s)) {
            padding-bottom: $spacing--xs;
          }
        }
      }
      /* stylelint-enable */
    }
  }
}
