// CTA Sidebar Right Rail Styles Only

// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    .pane {
      &.pane-bundle-cta,
      &.panels-ipe-bundle-cta {
        @include media(bp(xl)) {
          /* stylelint-disable selector-max-compound-selectors */
          .field-name-field-title {
            h2 {
              @include component-heading-right-rail();
            }
          }
          .cw-with-image {
            .field-name-field-title,
            .field-name-field-cta-description,
            .field-name-field-cta-link {
              padding-right: 0;
            }
            .content {
              min-height: auto;
            }
          }
          /* stylelint-enable */
          .field-name-field-cta-image {
            position: relative;
            left: auto;
            top: auto;
            margin-bottom: 1em;
            width: auto;
            height: auto;
          }
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          .paragraphs-item-cta {
            padding: $sidebar-component-right-rail-padding;
            text-align: center;
            .field-name-field-cta-description {
              p {
                @include component-paragraph-right-rail();
                text-align: center;
              }
            }
            &:not(.cw-cta-phone) {
              .field-name-field-cta-link {
                padding-bottom: 0;
                a {
                  color: $sidebar-component-link-color;
                  @include component-link-title-right-rail();
                  font-weight: 600;
                  &.mailto {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  }
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
            &.cw-cta-phone {
              padding: 0;
              .content {
                line-height: 1.4;
                padding: $sidebar-component-right-rail-padding;
              }
              .field-name-field-cta-link,
              .field-name-field-cta-link-prefix,
              .field-name-field-cta-link-suffix {
                font-size: 1rem;
                // phone icon
                &::before {
                  display: block;
                  font-size: 1.6rem;
                  margin-right: 0;
                  margin-bottom: $spacing--xs;
                }
              }
              span.field-name-field-cta-link a {
                font-size: 1rem;
                padding: 0;
              }
            }
            &:not(.cw-with-image):not(.cw-cta-phone) {
              &.cw-with-description {
                .field-name-field-cta-description p {
                  @include component-paragraph-right-rail();
                }
              }
            }
            // Cta with link only
            &:not(.cw-with-title):not(.cw-with-image):not(.cw-with-description) {
              padding: 0;
            }
          }
          /* stylelint-enable */
          // Override default cta prominence styles to fix image position Safari issue
          &:not(:only-child) {
            &:first-child:nth-last-child(n+3),
            &:nth-child(2):not(:last-child),
            &:nth-child(n+3) {
              /* stylelint-disable selector-max-compound-selectors */
              .field-name-field-cta-image {
                position: static;
              }
              /* stylelint-enable */
            }
          }
          &.cb-style--1 {
            .paragraphs-item-cta:not(.cw-cta-phone) {
              &.cw-with-image {
                /* stylelint-disable selector-max-compound-selectors */
                .content {
                  min-height: auto;
                  padding: 0;
                }
                .field-name-field-title,
                .field-name-field-cta-link,
                .field-name-field-cta-description {
                  padding-right: 0;
                }
                .field-name-field-cta-image {
                  width: auto;
                  height: auto;
                }
                img {
                  padding: 0;
                  max-width: 100%;
                }
                /* stylelint-enable */
              }
            }
          }
        }
      }
    }
  }
}
