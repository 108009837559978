// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing:not(.cb-list-news) {
  .pane {
    &.cb-list-news--related-news {
      padding: 0;
      .panels-ipe-portlet-content {
        @include component-padding();
      }
    }
  }
}
