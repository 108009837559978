// Base contact information component styles
// Component classes currently differs because:
// - in most places it's a paragraph item
// - contact fieldable pane is currently only used in footer
// - cups location can't be altered to reuse the exact same default contact classes

.paragraphs-items:not(.paragraphs-items-field-lab-location) .cb-contact-information,
.cb-pane-contact-information,
.cb-clinical-trial__locations .cb-cups-location {
  font-family: $font--text--alt;
  overflow: hidden;
  line-height: 1.4;
  &:not(:first-child) {
    margin-top: 2em;
  }
  p,
  .cb-content-area__main & {
    font-size: 1rem;
    line-height: 1.4;
    a {
      font-family: $font--text--alt;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .field:not(:last-child) {
    margin-bottom: 0.75em;
  }
  .pane-title {
    font-size: 1.3rem;
    color: color(primary, xlo);
    margin-bottom: 1rem;
  }
  .organisation-block {
    font-weight: 700;
    margin-bottom: 0.2em;
  }
  .cb-content-area__main & {
    &:not(:first-child) {
      margin-top: 2em;
    }
  }
  .cb-contact-information__phone,
  .cb-contact-information__email {
    a {
      &:hover {
        text-decoration: underline;
      }
      // phone and email icon styles
      &::before {
        margin-left: 0.1em;
        display: inline-block;
        // to make 'no underline' work in IE11,
        // this needs to be declared first
        // followed by text-decoration: none
        // in separate declaration below
        text-decoration: underline;
      }
      &::before {
        text-decoration: none;
      }
    }
  }
}
