// Base sidebar styles shared between all components

.cb-content-area__aside-last {
  .pane {
    background: $sidebar-component-bg-color;
    box-shadow: none;
    border-radius: 2px;
    @include clearfix();
    &:not(:last-child) {
      margin-bottom: 2em;
    }
    a {
      color: $sidebar-component-link-color;
      font-weight: 600;
    }
    > h2 {
      @include component-heading(m);
      color: $sidebar-component-title-color;
    }
    p {
      @include component-paragraph(m);
      color: $sidebar-component-font-color;
    }
  }
}

// Right rail sidebar only:
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    .pane {
      @include media(bp(xl)) {
        /* stylelint-disable selector-max-compound-selectors */
        > h2 {
          @include component-heading-right-rail();
        }
        p {
          @include component-paragraph-right-rail();
        }
        /* stylelint-enable */
      }
    }
  }
}

// Fix ie11 flex spacing issue
.cb-page-content--feature-rows {
  .cb-content-area__aside-last {
    .pane {
      display: block;
    }
  }
}
