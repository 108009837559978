// Related Testimonials Only:
// CUPS profile, condition, treatment, specialty
.cb-list-testimonials--related-testimonials.cb-list-testimonials--compact,
.cb-list-testimonials--related-testimonials.cups-profile-section,
.node-type-service .cb-list-testimonials--related-testimonials {
  h2.pane-title {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: $spacing--l;
  }
  .view-content {
    padding: $spacing--m 0;
    @include media(bp(s)) {
      padding: $spacing--m $spacing--l $spacing--l $spacing--l;
    }
    &:not(:only-child) {
      padding-bottom: 0;
    }
    ul {
      width: 100%;
      max-width: $component-content-max-width;
    }
    li {
      margin-top: 0;
      margin-bottom: 0;
      &:hover {
        /* stylelint-disable selector-max-compound-selectors */
        .cb-list-testimonials__title a {
          text-decoration: underline;
        }
        /* stylelint-enable */
      }
    }
  }
  .cb-list-testimonials {
    &__content {
      .view-content {
        /* stylelint-disable selector-max-compound-selectors */
        li {
          @include media(bp(s)) {
            border-left: none;
            border-right: none;
          }
        }
        /* stylelint-enable */
      }
    }
  }
  .cb-layout__component-dynamic {
    max-width: 100%;
    // with image
    &:not(.column-second-empty) {
      .cb-list-testimonials__title {
        span.ext {
          display: inline-block;
        }
      }
    }
  }
  ul.pager {
    margin-top: $spacing--m;
    margin-bottom: $spacing--l;
    padding-bottom: $spacing--l;
    @include media(bp(l)) {
      padding-bottom: 0;
    }
    li.pager-current {
      width: auto;
    }
  }
  .more-link {
    border-top: $border--xs;
    padding: $spacing--s;
    a {
      text-decoration: none;
      color: a11yrize(color(action, m), $background-solid-light);
      @include media(bp(s)) {
        @include component-link-title(s);
      }
      @include media(bp(m)) {
        @include component-link-title(m);
      }
    }
  }
}

// Fix Cups profile page overrides
.node-type-cups-profile {
  .cb-content-area__main {
    .cb-list-testimonials--related-testimonials.cups-profile-section {
      .cb-list-testimonials {
        &__content {
          .view-content {
            ul {
              @include media(bp(l)) {
                max-width: $component-content-max-width;
                margin: 0 auto;
              }
            }
            li {
              padding-left: 0;
              margin-bottom: 0;
              /* stylelint-disable selector-max-compound-selectors */
              &::before {
                display: none;
              }
              /* stylelint-enable */
            }
          }
        }
        &__summary p {
          font-family: $font--display;
        }
      }
      ul.pager {
        margin-top: $spacing--m;
        padding-bottom: 0;
        font-family: $font--display;
        li {
          padding: 0;
          &:not(:last-child) {
            margin: 0 0.2em;
            @include media(bp(m)) {
              margin: 0 0.5em;
            }
          }
          &::before {
            display: none;
          }
          &.pager-current {
            font-weight: 700;
            padding: 0.35em;
            @include media(bp(m)) {
              padding: 0;
            }
          }
        }
      }
      &:not(:first-child) {
        margin-top: 4em;
      }
      h2.pane-title {
        margin-bottom: 0;
        @include media(bp(l)) {
          padding-top: $spacing--l;
        }
      }
    }
    .cb-list-news--related-news {
      + .cb-list-testimonials--related-testimonials.cups-profile-section {
        border-top: none;
        margin-top: 0;
      }
    }
  }
}
