// stylelint-disable selector-class-pattern
.cb-cups-full-search {
  font-family: $font--display;
  padding: 0;
  border-radius: 2px;
  background-color: $cups-search-bg-color;
  .views-exposed-widget {
    @include span-columns(12) {
      padding: 0;
      margin: 0.5em 0;
    }
    @include media(bp(m)) {
      &.views-widget-filter-field_cups_insurance,
      &.views-widget-filter-field_cups_locations_field_cups_profile_location_ref {
        @include span-columns(6);
      }
      &.views-widget-filter-field_cups_insurance_1 {
        @include span-columns(6) {
          @include omega();
        }
      }
      &.views-widget-filter-field_cups_languages {
        @include span-columns(3);
      }
      &.views-widget-filter-field_cups_gender {
        @include span-columns(3) {
          @include omega();
        }
        select {
          padding-right: 0.75em;
        }
      }
    }
    &.views-widget-checkboxes {
      @include span-columns(12);
      label {
        display: inline-block;
        color: $cups-search-label-font-color;
      }
      .form-type-checkbox {
        width: 100%;
        margin: 0.5em 0 0.5em 0;
        @include media(bp(m)) {
          display: inline;
          margin-right: 0.6em;
        }
      }
    }
    &.views-submit-button {
      .form-submit {
        width: 100%;
        margin-top: 0;
        @include media(bp(m)) {
          float: right;
          width: auto;
          margin-bottom: 0;
        }
      }
    }
  }
  label {
    display: none;
  }
  select {
    cursor: pointer;
    background-position: right 12px center;
    @include span-columns(12);
    @include media($bp-only-l) {
      font-size: 0.85em;
    }
  }
  .form-disabled {
    select {
      color: darken(color(neutral-brand, hi), 2%);
      cursor: auto;
      background: $background-solid-xlight url("#{$image-path}/icons/icon-dropdown-arrow--grey.svg") no-repeat;
      background-size: 20px 9px;
      background-position: right 12px center;
    }
  }
}

.cb-cups-full-search--researchers,
.cb-cups-full-search--faculty {
  .views-exposed-widget {
    // display search field and button side-by-side
    @include media(bp(m)) {
      &.views-widget-filter-search_api_views_fulltext {
        display: inline-block;
        width: 81%;
        margin-right: 0;
      }
      &.views-submit-button {
        @include span-columns(2.5, inline-block){
          margin: 0;
        }
        &:last-child input[type="submit"] {
          width: 100%;
          border-radius: 0 $border-radius--default $border-radius--default 0;
        }
      }
    }
  }
}
// stylelint-enable selector-class-pattern
