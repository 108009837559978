// stylelint-disable selector-class-pattern
.cb-signup-form--formassembly {
  border: 2px solid color(secondary, brand);
  * {
    // Override form assembly styles.
    // stylelint-disable declaration-no-important
    font-family: $font--text--alt !important;
    // stylelint-enable declaration-no-important
  }
  iframe {
    max-width: 100%;
  }
  .wFormContainer {
    .reqMark::after {
      color: color('alert-fail', 'm');
    }
    .wForm {
      margin-top: 1rem;
      box-shadow: none;
      .wFormTitle {
        background: color(secondary, brand);
        margin-top: -1rem;
        margin-bottom: 1rem;
      }
      .captchaHelp {
        opacity: 1;
      }
    }
    .inputWrapper select {
      height: auto;
    }
    input[type='submit']:disabled {
      background-color: color('neutral-brand', 'xxhi');
      color: color('neutral-brand', 'lo');
    }
    .actions .primaryAction {
      background-color: color(secondary, brand);
      &:not(:disabled) {
        &:hover {
          background-color: color(primary, brand);
        }
        &:active {
          background-color: color(primary, lo);
        }
        &:focus {
          background-color: color(primary, brand);
        }
      }
    }
  }
  .wFormHeader,
  .wFormFooter {
    display: none;
  }
}
// stylelint-enable selector-class-pattern
