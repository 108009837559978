// Component Paragraph Mixin

@mixin component-paragraph($size: m) {
  @if $size == xs {
    font-size: 0.9rem;
    line-height: 1.5;
  }
  @else if $size == s {
    font-size: 1rem;
    line-height: 1.5;
  }
  @else if $size == m {
    font-size: 1.1rem;
    line-height: 1.5;
  }
  @else if $size == l {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  @else if $size == xl {
    font-size: 1.3rem;
    line-height: 1.7;
  }
  @else {
    @warn 'Invalid size argument: #{$size}';
  }
}

@mixin component-paragraph-right-rail() {
  font-size: 0.8rem;
  line-height: 1.5;
}
