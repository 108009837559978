// If CTA style auto or 1 is selected and icon option is used:
// CTA will display selected icon.

.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style:not(:only-child) &,
    &.pane.cb-style:not(:only-child) {
      .field-name-field-icon {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding: 1rem 1.5rem 0 1.5rem;
      }
      .cw-with-icon {
        &::before {
          display: none;
        }
        .field-name-field-title {
          padding-top: 0.3em;
        }
        .content {
          height: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}

// CTA style auto only
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--auto.pane:not(:only-child) &,
    &.cb-style--auto.pane:not(:only-child) {
      .paragraphs-item-cta.cw-with-icon {
        .content {
          // stylelint-disable selector-max-compound-selectors
          .field-name-field-icon {
            overflow: visible;
          }
          // stylelint-enable selector-max-compound-selectors
        }
        &.cb-position-h--center {
          .content {
            @include component-padding();
          }
          .field-name-field-icon {
            padding-top: 0;
          }
          .field-name-field-cta-link {
            padding-bottom: 0;
          }
          .field-name-field-title,
          .field-name-field-cta-description,
          .field-name-field-cta-link {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
      @include quantities-all(odd) {
        .paragraphs-item-cta.cw-with-icon {
          &.cb-position-h--left {
            .content {
              @include media(bp(m)) {
                padding: $spacing--m;
              }
              @include media(bp(l)) {
                padding: $spacing--l;
              }
            }
            .field-name-field-icon {
              @include media(bp(m)) {
                width: 3vw;
                top: 0.9em;
              }
              @include media(bp(l)) {
                width: 4vw;
                top: 1.3em;
              }
              @include media(bp(xl)) {
                width: 2.5em;
              }
            }
            .field-name-field-title,
            .field-name-field-cta-description,
            .field-name-field-cta-link {
              @include media(bp(m)) {
                padding-left: 4.5vw;
              }
              @include media(bp(l)) {
                padding-left: 5.5vw;
              }
              @include media(bp(xl)) {
                padding-left: 3.5em;
              }
            }
            .field-name-field-cta-link a {
              @include media(bp(m)) {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .cb-style--auto.pane &,
    &.cb-style--auto.pane {
      @include quantities-all(even) {
        .paragraphs-item-cta.cw-with-icon.cb-position-h--left {
          .field-name-field-icon {
            @include media(bp(bs)) {
              width: 6vw;
              top: 0.8em;
            }
            @include media(bp(s)) {
              top: 1.4em;
            }
            @include media(bp(m)) {
              top: 1.3em;
            }
            @include media(bp(l)) {
              width: 7vw;
              top: 1.1em;
            }
            @include media(bp(xl)) {
              width: 3em;
              top: 1.3em;
            }
          }
          .field-name-field-title,
          .field-name-field-cta-description,
          .field-name-field-cta-link {
            @include media(bp(bs)) {
              padding-left: 8vw;
            }
            @include media(bp(l)) {
              padding-left: 9vw;
            }
            @include media(bp(xl)) {
              padding-left: 4em;
            }
          }
          .field-name-field-cta-link a {
            @include media(bp(m)) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-flavor--subsection-front) {
  .region {
    // layout with sidebars
    .cb-layout--aside-first &,
    .cb-layout--aside-last &,
    .cb-layout--aside-first-last & {
      .pane-bundle-cta,
      .panels-ipe-bundle-cta {
        .cb-style--auto.pane:not(:only-child) &,
        &.cb-style--auto.pane:not(:only-child) {
          @include quantities-all(odd) {
            .paragraphs-item-cta.cw-with-icon {
              &.cb-position-h--left {
                @include media(bp(xl)) {
                  // stylelint-disable selector-max-compound-selectors
                  .field-name-field-icon {
                    width: 1.5em;
                  }
                  .field-name-field-title,
                  .field-name-field-cta-description,
                  .field-name-field-cta-link {
                    padding-left: 2.3em;
                  }
                  // stylelint-enable selector-max-compound-selectors
                }
              }
            }
          }
          @include quantities-all(even) {
            .paragraphs-item-cta.cw-with-icon {
              &.cb-position-h--left {
                @include media(bp(xl)) {
                  // stylelint-disable selector-max-compound-selectors
                  .field-name-field-icon {
                    width: 2em;
                  }
                  .field-name-field-title,
                  .field-name-field-cta-description,
                  .field-name-field-cta-link {
                    padding-left: 2.8em;
                  }
                  // stylelint-enable selector-max-compound-selectors
                }
              }
            }
          }
        }
      }
    }
  }
}

// CTA style 1 only
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--1:not(:only-child) &,
    &.pane.cb-style--1:not(:only-child) {
      &:hover {
        .field-name-field-icon .cb-icon {
          height: 55px;
          width: 55px;
        }
      }
      .cw-with-icon {
        .content {
          flex: 1 0 auto;
          .field-name-field-icon {
            position: relative;
            flex: 1 0 auto;
            // stylelint-disable selector-max-compound-selectors
            .cb-icon {
              position: absolute;
              top: 50%;
              left: 1.5rem;
              transform: translateY(-50%);
            }
            // stylelint-enable selector-max-compound-selectors
          }
        }
        &.cb-position-h--center {
          .content {
            .field-name-field-icon {
              // stylelint-disable selector-max-compound-selectors
              .cb-icon {
                margin: 0 auto;
                left: 0;
                right: 0;
              }
              // stylelint-enable selector-max-compound-selectors
            }
          }
        }
      }
    }
  }
}
