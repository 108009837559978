// CTA with Icon Full Width Featured Layout Overrides (Only Child in a Region)

// Icon and link only in feature rows.
.cb-content-area__feature-rows {
  .pane-bundle-cta.cb-style:only-child,
  .panels-ipe-bundle-cta:only-child {
    .paragraphs-item-cta {
      &.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
        &.cb-position-h--left {
          .content {
            justify-content: flex-start;
            @include media(bp(l)) {
              // Top / bottom padding from base plus 16:9 ratio of icon field.
              // Icon width is set to 25.6% in typography.
              min-height: calc(3em + 14.4vw);
            }
            @include media(bp(xl)) {
              // 16:9 ratio of the icon.
              // 75em max-page width * 0.256 icon width * 0.5625 16:9 ratio.
              // Plus 3em top / bottom padding
              min-height: 13.8em;
            }
          }
        }
        .field-name-field-cta-link a {
          @include media(bp(bs)) {
            @include component-heading(s);
          }
          @include media(bp(s)) {
            @include component-heading(m);
          }
          @include media(bp(xl)) {
            @include component-heading(l);
          }
        }
      }
    }
  }
}

// Layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first,
  .cb-layout--aside-last,
  .cb-layout--aside-first-last {
    .cb-content-area__feature-rows {
      .pane-bundle-cta.cb-style:only-child,
      .panels-ipe-bundle-cta:only-child {
        .paragraphs-item-cta {
          &.cw-with-image {
            .content {
              border: $border--xs;
              @include media(bp(m)) {
                border: none;
              }
            }
          }
          &.cw-with-icon:not(.cw-with-title):not(.cw-with-description) {
            &.cb-position-h--center {
              .field-name-field-cta-link {
                a {
                  // arrow icon
                  /* stylelint-disable selector-max-compound-selectors */
                  &::after {
                    font-size: 0.75em;
                    margin-left: 0.25em;
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
        }
      }
    }
  }
}
