// Blog Promoted Featured Layout

// Featured Layout with sidebars:
// Display max 2 items per row if list has 4 items
.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region {
        .cb-list-news--promoted.cb-list-news--blog,
        .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--blog {
          @include media(bp(l)) {
            ul:not(.panels-ipe-linkbar) {
              display: flex;
              flex-wrap: wrap;
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              li {
                @include quantities-all(3) {
                  .column-first.column--first,
                  .column-first.column--first:not(:only-child) {
                    padding: $spacing--m;
                  }
                }
                @include quantities-all(4) {
                  display: flex;
                  flex: 1 0 auto;
                  flex-direction: column;
                  @include span-columns(6);
                }
                @include quantity-positions(4,1,3) {
                  margin-left: 0;
                  margin-right: 1.6%;
                }
                @include quantity-positions(4,2,4) {
                  margin-right: 0;
                  margin-left: 1.6%;
                }
                @include quantity-positions(4,1,2) {
                  margin-bottom: 3%;
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}
