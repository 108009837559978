// Prevent side scrolling on mobile due to hidden menu on the right.
html {
  overflow-x: hidden;
  @include media(bp(xl)) {
    overflow-x: visible;
  }
  &.scroll-lock {
    overflow: hidden;
    body {
      height: 100%;
      overflow: hidden;
    }
  }
}

.mobile-menu {
  position: relative;
  width: 100%;
  text-align: right;
  min-height: 46px;
  background-color: $background-solid-light;
  @include media(bp(m)) {
    background-color: transparent;
    min-height: 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    ul {
      li {
        padding-left: 1em;
      }
    }
    a {
      margin: 1px;
      @include word-wrap();
      color: #fff;
    }
    &.cb-primary-links {
      a {
        display: block;
        font-size: 1.125rem;
        padding: 0.75em 1.9rem;
        line-height: 1.3;
      }
      > li {
        border-bottom: $border--s;
        border-color: color(primary, xlo);
        &:first-child:not(.active-trail) {
          border-top: 1px solid color(neutral-brand, xxhi);
        }
      }
      > li.active-trail {
        > a {
          background: $background-active-state;
          color: a11yrize(color(action, m), $background-active-state);
        }
        > .menu {
          padding-bottom: 1em;
        }
      }
      .main-menu,
      .orphan-menu {
        a {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.3;
          padding: 0.5em 1.9rem;
        }
        .collapsed, .leaf, .active-trail {
          border-bottom: none;
        }
      }
      .main-menu + .orphan-menu {
        margin-top: -1em;
      }
      .orphan-menu {
        &--parent-trail,
        li ul {
          padding: 0.5em 1.9rem;
        }
        &--parent-trail {
          padding-bottom: 0;
        }
        .orphan-active {
          font-weight: 700;
          font-size: 1.125em;
          color: lighten(color(primary, xhi), 10%);
          padding-bottom: 0.5rem;
          &::after {
            content: "\25CF";
            margin-left: 0.5rem;
            margin-right: -1.25rem;
            vertical-align: baseline;
          }
        }
      }
    }
    &.cb-secondary-links {
      margin-top: 0;
      padding-top: $spacing--m;
      padding-bottom: $spacing--m;
      a {
        font-size: 1rem;
        line-height: 1.3;
        display: block;
        padding: 0.25em 1.9rem;
      }
      li.active-trail.first > a {
        padding-top: 0.8rem;
      }
      > li.active-trail {
        > a {
          font-weight: 700;
          &::before {
            content: "\25CF \00a0";
          }
        }
      }
      .sidebar-menu {
        a {
          font-size: 0.9rem;
          font-weight: 400;
          padding: 0.5em 1.9rem;
          &.active {
            font-weight: 700;
          }
        }
        li.active-trail a.active {
          font-size: 1rem;
          padding-top: 0.33rem;
          padding-bottom: 0.33rem;
        }
      }
      .cb-search {
        @include icon("search") {
          margin-right: 0.25em;
        }
      }
    }
  }
  .cb-mobile-menu {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    max-width: 320px;
    top: 0;
    bottom: 0;
    right: -100%;
    position: fixed;
    background-color: $mobile-menu-side-nav-bg-color;
    height: 100vh;
    max-height: 100%;
    overflow-y: auto;
    text-align: left;
    box-shadow: 0 1px 12px #000;
    transition: all 0.4s ease;
    .logged-in & {
      height: calc(100vh - 2.4375rem);
    }
    .cb-secondary-links {
      .cb-search {
        display: none;
      }
    }
  }
  li {
    padding: 1px;
    .subsection-header {
      a.active {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  &__header {
    display: flex;
    position: fixed;
    width: 100%;
    max-width: 320px;
    background-color: color(primary, lo);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    z-index: z(navFixed);
    padding: 1px;
    @include clearfix();
    + ul {
      margin-top: 46px;
    }
    .mobile-menu__home {
      @include icon($icon: home) {
        vertical-align: text-top;
      }
      &:nth-last-child(1) {
        border-right: 1px solid color(primary, lo);
      }
    }
    .cb-search {
      @include icon("search") {
        vertical-align: text-top;
        font-size: 0.9em;
        margin-top: 2px;
      }
    }
    a {
      color: color(primary, brand);
    }
    a,
    .mobile-menu__close {
      margin: 1px;
      height: auto;
      font-size: 1rem;
      padding: 0.75em;
      line-height: 1.3;
      box-shadow: none;
      background-color: #fff;
      &::before {
        margin-right: 0.25em;
      }
      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3) ~ a {
        width: calc(33.3333% - 2px);
        float: left;
        &.mobile-menu__close {
          float: right;
        }
      }
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) + a {
        width: calc(50% - 2px);
        float: left;
        &.mobile-menu__close {
          float: right;
        }
        &.mobile-menu__home {
          text-align: center;
        }
      }
      &:last-child {
        margin-bottom: 1px;
      }
    }
    .mobile-menu__close {
      color: color(primary, brand);
      padding: 0.75em;
      border-radius: 0;
      order: 3;
      @include icon("close") {
        font-size: 0.8em;
        vertical-align: baseline;
      }
      &:focus {
        outline: 1px dotted;
        outline-color: #fff;
      }
      // Hide "Menu" visually.
      span:last-child {
        @include visually-hidden();
      }
    }
  }
  &__toggle {
    color: a11yrize(color(action, lo), $background-solid-light);
    background: $background-solid-light;
    box-shadow: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.125em;
    // 1px margin to make sure focus outline won't be clipped.
    margin: 1px;
    padding: 0 1rem;
    height: 2.75rem;
    line-height: 2.75;
    float: right;
    @include media(bp(m)) {
      background: $background-solid-light;
      display: inline-block;
      color: a11yrize(color(action, lo), $background-solid-light);
      font-size: 1.125rem;
      padding: 0.9em 1.4em 0.956em 1.4em;
      border-radius: 4px;
      border-bottom: solid 1px color(neutral-brand, xhi);
      box-shadow: $box-shadow;
      position: absolute;
      right: $page-margins;
      top: -5.2rem;
      line-height: 1;
      height: auto;
    }
    @include media(bp(xl)) {
      display: none;
    }
    @include icon("menu", before, false, 0.7em) {
      margin: 0 0.5rem;
      display: inline-block;
      vertical-align: baseline;
    }
    span {
      @include visually-hidden();
    }
    &:hover, &:active, &:focus {
      text-decoration: none;
      box-shadow: none;
      background: $background-solid-light;
      color: a11yrize(color(action, lo), $background-solid-light);
    }
    &:focus {
      outline: 1px dotted;
      outline-color: inherit;
    }
  }
  &--visible {
    .cb-mobile-menu {
      visibility: visible;
      opacity: 1;
      right: 0;
    }
    .cb-mobile-menu {
      top: 0;
      z-index: z(navFixed);
      .logged-in & {
        top: 2.4375rem;
      }
    }
  }
}

.mobile-menu-active {
  overflow-x: hidden;
  .cb-site-header {
    // Switched to relative from static,
    // because static breaks z-index with overlay on IE.
    position: relative;
    width: 100vw;
    max-width: 100%;
    .cb-screen-overlay {
      opacity: 0;
      visibility: hidden;
      background-color: transparent;
      position: fixed;
      top: 0;
      left: 0;
      transition: opacity 0.3s ease;
      z-index: z(screenOverlay);
    }
    &--inactive {
      .cumc-global-header-embed {
        z-index: z(contentTop);
      }
      .cb-screen-overlay {
        opacity: 1;
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100vw;
        height: 200vh;
      }
    }
  }
  .cb-primary-navigation {
    position: static;
  }
}
