// Text fieldable pane component styles

.region {
  .cb-text {
    &__title {
      &:only-child {
        margin-bottom: 0;
      }
    }
  }
  // To display text component in region above a list of related components
  // (e.g. CTAs), text component region should use "overlap bottom" setting
  // to make it look like the mixed components are within the same region
  &.cb-overlap--bottom {
    .cb-text {
      &__title:only-child {
        // remove extra spacing caused by "cps-region-inner" default bottom padding
        margin-bottom: -1.6%;
      }
    }
  }
}

// Homepage and featured layout no aside only
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-text {
      &__title {
        &:only-child {
          text-align: center;
        }
      }
      p {
        font-family: $font--text--alt;
        // adjust font size to ensure max characters per line
        // follow ux guidelines (approx 76 char without spaces)
        @include media(bp(l)) {
          font-size: 1.4rem;
          line-height: 1.7;
        }
        @include media(bp(xl)) {
          font-size: 1.45rem;
        }
      }
    }
  }
}

// Featured layout with sidebar
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .cb-text {
        @include media($bp-only-m) {
          max-width: $component-content-max-width;
          margin-left: auto;
          margin-right: auto;
          padding-left: 1.25rem;
          padding-right: 1.25rem;
        }
        @include media($bp-only-l) {
          @include span-columns(8, $shift: 2) {
            padding-left: 1.7%;
            padding-right: 1.7%;
          }
        }
        p {
          // adjust font size to ensure max characters per line
          // follow ux guidelines (approx 76 char without spaces)
          @include media(bp(s)) {
            font-size: 1.3rem;
          }
          @include media(bp(m)) {
            font-size: 1.4rem;
          }
          @include media(bp(l)) {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}
