// Events search box styles
%form-label-minimize {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0 0.5rem;
  background: #fff;
  line-height: 1;
  left: 0.5rem;
  top: -0.6rem;
  z-index: z(contentTop);
  position: absolute;
  margin-bottom: 0;
}

// stylelint-disable selector-class-pattern
.view-cola-events-search,
.cb-list-resources__search {
  .views-exposed-widgets {
    display: flex;
    flex-wrap: wrap;
  }
  // Hide labels when when form elements have focus or have been changed
  .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
    label.minimize {
      .js & {
        @extend %form-label-minimize;
      }
    }
    label {
      .no-js & {
        @extend %form-label-minimize;
      }
    }
  }
  // Search field
  .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
    position: relative;
    order: 1;
    width: 100%;
    margin-right: 0;
    label {
      .js & {
        z-index: z(contentBottom);
        position: absolute;
        top: 0;
        padding: 0.35em 0.7em;
        font-size: 1.2em;
        transition: all 0.1s;
      }
    }
  }
}

.view-cola-events-search {
  .views-widget-filter-field_event_categories,
  .views-widget-filter-field_event_date_value2_1 {
    label.minimize {
      .js & {
        @extend %form-label-minimize;
      }
    }
    label {
      .no-js & {
        @extend %form-label-minimize;
      }
    }
  }
}
