// Styles for solr site search
// Input button is displayed next to search box
.cb-site-search__search {
  // Text input field
  // stylelint-disable selector-class-pattern
  .views-widget-filter-search_api_views_fulltext {
    &:first-child:nth-last-child(2) {
      width: 100%;
      font-size: 1.2em;
      @include media(bp(s)) {
        margin: 0;
        float: left;
        display: inline-block;
        width: 77%;
        input {
          border-right: none;
        }
      }
      label {
        @include visually-hidden();
      }
      .form-item {
        &::after {
          display: none;
        }
      }
    }
  }
  // stylelint-enable selector-class-pattern
  .views-submit-button {
    &:last-child:nth-child(2) {
      @include media(bp(s)) {
        margin: 0;
        float: left;
        display: inline-block;
        @include span-columns(3);
        input {
          height: 50px;
          width: 100%;
          border-radius: 0 4px 4px 0;
        }
      }
      input {
        width: 100%;
        margin: 0;
      }
    }
  }
}

// Compact Lists base styles
.cb-site-search {
  &__header {
    margin-bottom: 0;
  }
  &__results {
    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      padding: 1.9em 0 2em 0;
      border-bottom: $border--s;
      margin-bottom: 0;
      &:last-child {
        border-bottom: $border--m;
      }
    }
  }
  &__title {
    @include h3();
    @include media(bp(l)) {
      font-size: 1.5em;
    }
  }
  &__excerpt {
    margin-top: 1em;
    p {
      line-height: 1.7;
    }
  }
}
