// Base contact information with map component styles

.paragraphs-items:not(.paragraphs-items-field-lab-location) .cb-contact-information.cw-with-map > .content,
.cb-clinical-trial__locations .cb-cups-location .cb-panel-container {
  padding-top: 1em;
  background-color: $background-solid-xlight;
  max-width: 400px;
  margin: 0 auto;
  @include media(max-width (bp(s) - 0.06em)) {
    > *:not(.field-type-geofield) {
      padding: 0 1rem;
    }
  }
  // [todo] revise the scaling by using a floated pseudo element to
  // determine the height for all breakpoints.
  // Will affect Services, Services listing, News Media Contact, Clinics,
  // Labs, Clinical Trials, Paragraph items: People, Contact Info
  @include media(bp(s)) {
    min-height: calc(34.5vw + 2em + 1px);
    padding: 1em;
    padding-left: calc(46vw + 2em);
    position: relative;
    overflow: hidden;
    max-width: 100%;
  }
  @include media(bp(m)) {
    min-height: calc(37.5vw + 1px);
    padding-left: calc(50vw + 1.25em);
  }
  @include media(bp(l)) {
    min-height: calc(27.75vw + 1px);
    padding-left: calc(37vw + 1.25em);
  }
  @include media(bp(xl)) {
    // 3-col layout
    min-height: 207px;
    padding-left: calc(45% + 1em);
    // 2-col layout
    .cb-layout--aside-first &,
    .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last .cb-content-area__aside-last & {
      min-height: 356px;
      padding-left: calc(55% + 1.25em);
    }
    // 1-col layout
    .cb-layout--no-aside & {
      min-height: 315px;
      padding-left: calc(55% + 1.25em);
    }
  }
  a {
    color: a11yrize(color(action, m), $background-solid-xlight);
  }
  // Sidebar.
  .cb-layout:not(.cb-layout--dynamic-features-asides-feature-rows) .cb-aside-last &,
  .cb-layout--dynamic-features-asides-feature-rows:not(.cb-layout--aside-first-last) .cb-aside-last & {
    background-color: transparent;
    padding: 0;
  }
}

.paragraphs-items:not(.paragraphs-items-field-lab-location) .cb-contact-information__map,
.cb-clinical-trial__locations .cb-cups-location .cb-cups-location__map {
  margin-top: 1em;
  a {
    // Preserve focus outlines due to overflow hidden parent(s).
    display: inline-block;
    margin: 2px;
    position: relative;
    @include icon($icon: 'external-link', $color: #fff) {
      position: absolute;
      top: 5px;
      left: 5px;
      display: inline-block;
      padding: 5px 5px 7px 7px;
      background: rgba(color(primary, lo), 0.5);
    }
    &:hover,
    &:focus,
    &:active {
      &::before {
        color: color(primary, lo);
        background: rgba(255, 255, 255, 0.5);
        text-decoration: none;
      }
    }
  }
  @include media(bp(s)) {
    margin-top: 0;
    width: 46vw;
    position: absolute;
    left: 1em;
    top: 1em;
    bottom: 0;
  }
  @include media(bp(m)) {
    width: 50vw;
    left: 0;
    top: 0;
  }
  @include media(bp(l)) {
    width: 37vw;
  }
  // 3-col layout
  @include media(bp(xl)) {
    width: 45%;
    padding: 1em 0.25em 1em 1em;
    background-color: transparent;
  }
  // 1 and 2-col layouts
  .cb-layout--aside-first &,
  .cb-layout--no-aside &,
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last .cb-content-area__aside-last & {
    @include media(bp(xl)) {
      width: 55%;
      padding: 0;
    }
  }
  // Featured layout with two sidebars.
  .cb-layout--dynamic-features-asides-feature-rows.cb-layout--aside-first-last .cb-content-area__aside-last & {
    @include media(bp(xl)) {
      position: absolute;
      margin-top: 0;
    }
  }
  // Sidebar.
  .cb-layout:not(.cb-layout--dynamic-features-asides-feature-rows) .cb-aside-last &,
  .cb-layout--dynamic-features-asides-feature-rows:not(.cb-layout--aside-first-last) .cb-aside-last & {
    width: 100%;
    position: static;
    padding: 0;
    margin-top: 0.7em;
  }
  img {
    // IE needs this or it will size the image using the width attribute.
    width: 100%;
  }
}

// Featured layout overrides
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .paragraphs-items:not(.paragraphs-items-field-lab-location) {
    .cb-contact-information {
      &.cw-with-map {
        > .content {
          @include media(bp(xl)) {
            min-height: 307px;
          }
        }
      }
    }
  }
}
