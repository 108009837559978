// Base Gateway styles

.cb-gateway {
  position: relative;
  .cb-gateway {
    &__content {
      @include media(bp(l)) {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        width: 100%;
      }
      .field-item {
        margin-bottom: 0.7em;
        position: relative;
        padding-left: 0.8em;
        line-height: 1.2;
      }
      a {
        // link right arrow icon
        @include icon($icon: "caret-right", $pseudo: "before", $size: 0.5em, $color: color(action, m));
        &::before {
          padding-right: 0.3rem;
          position: absolute;
          left: 0;
          top: 0.3rem;
          // IE10 and IE11 needs underline to be set first
          // before text-decoration: none can be used
          text-decoration: underline;
        }
      }
    }
  }
}
