// Video sidebar styles

.cb-content-area__aside-last {
  .panels-ipe-video,
  .pane-video {
    @include media(bp(l)) {
      .field-name-field-video-image {
        a {
          &::before {
            // play button
            @include media(bp(l)) {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
    h2.field-name-field-title {
      font-size: 1.25rem;
      color: $sidebar-component-title-color;
      @include media(bp(s)) {
        font-size: 1.4rem;
        color: #fff;
        margin: 0 auto;
      }
      @include media(bp(m)) {
        font-size: 1.9rem;
      }
      @include media(bp(l)) {
        font-size: 1.4rem;
      }
      @include media(bp(xl)) {
        font-size: 2.5rem;
      }
    }
  }
}

// Right Rail only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    .panels-ipe-video.pane,
    .pane-video.pane {
      @include media(bp(xl)) {
        .content {
          display: flex;
          flex-direction: column;
        }
        .field-name-field-video-image {
          a {
            /* stylelint-disable selector-max-compound-selectors */
            &::before {
              top: 0;
              font-size: 1.2rem;
            }
            /* stylelint-enable */
          }
        }
        h2.field-name-field-title {
          position: relative;
          order: 2;
          color: $sidebar-component-title-color;
          text-shadow: none;
          @include component-paragraph-right-rail();
          padding: 0.8em 1em;
        }
        .field-name-field-video-image {
          order: 1;
        }
      }
    }
    .panels-ipe-video {
      .panels-ipe-portlet-content {
        /* stylelint-disable selector-max-compound-selectors */
        .pane-video {
          display: block;
          background: $sidebar-component-bg-color;
        }
        /* stylelint-enable */
      }
    }
  }
}
