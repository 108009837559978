.pane-cola-panels-local-footer {
  .cb-pane-contact-information {
    text-align: center;
    &__title {
      color: #fff;
      font-size: 1.3125rem;
      margin: 0 auto 0.5em auto;
      max-width: 15em;
    }
    .cb-contact-information {
      &__address,
      &__phone,
      &__email {
        color: #fff;
        font-family: $font--text--alt;
        font-size: 1em;
        font-weight: 400;
        line-height: 1.7;
        .field-label {
          @include visually-hidden();
        }
        a {
          color: #fff;
          font-weight: 400;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &__phone,
      &__email {
        a {
          // remove icon underline on hover
          &::before {
            display: inline-block;
            // to make 'no underline' work in IE11,
            // this needs to be declared first
            // followed by text-decoration: none
            // in separate declaration below
            text-decoration: underline;
          }
          &::before {
            text-decoration: none;
          }
        }
        li {
          margin-bottom: 0.7em;
        }
        &:last-child {
          li:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .cb-email,
    .cb-phone {
      &__label {
        margin-top: 0.8em;
        margin-bottom: 0.35em;
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }
}
