// CTA with 16:9 image only background styles (style auto)

@include component-region-bg-styles(auto,1) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--auto:not(:only-child) &,
    &.pane.cb-style--auto:not(:only-child) {
      .paragraphs-item-cta {
        .field-name-field-cta-description a {
          color: #fff;
        }
        &.cw-with-title,
        &.cw-with-description {
          .field-name-field-cta-link a {
            @include btn($style: solid, $size: s, $dark: true);
          }
        }
        // CTA with img and link only
        &:not(.cw-cta-phone) {
          &.cw-with-image:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@include component-region-bg-styles(auto) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--auto:not(:only-child) &,
    &.pane.cb-style--auto:not(:only-child) {
      background: $component-bg-2-color;
      .paragraphs-item-cta {
        &:not(.cw-cta-phone),
        &:not(.cw-cta-phone) .content {
          background: $component-bg-2-color;
        }
      }
    }
  }
}
@include component-region-bg-styles(1) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--auto:not(:only-child) &,
    &.pane.cb-style--auto:not(:only-child) {
      background: $component-bg-3-color;
      .paragraphs-item-cta {
        &:not(.cw-cta-phone),
        &:not(.cw-cta-phone) .content {
          background: $component-bg-3-color;
        }
      }
    }
  }
}
@include component-region-bg-styles(2) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--auto:not(:only-child) &,
    &.pane.cb-style--auto:not(:only-child) {
      background: $component-bg-1-color;
      .field-name-field-cta-description a {
        color: a11yrize(color(action, m), $component-bg-1-color);
      }
      .paragraphs-item-cta {
        &:not(.cw-cta-phone),
        &:not(.cw-cta-phone) .content {
          background: $component-bg-1-color;
        }
        // CTA with img and link only
        &:not(.cw-cta-phone) {
          &.cw-with-image:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link a {
              color: a11yrize(color(action, m), $component-bg-1-color);
            }
          }
        }
      }
    }
  }
}
@include component-region-bg-styles(3) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--auto:not(:only-child) &,
    &.pane.cb-style--auto:not(:only-child) {
      background: $component-bg-auto-color;
      .field-name-field-cta-description a {
        color: color(action, m);
      }
      .paragraphs-item-cta {
        &:not(.cw-cta-phone),
        &:not(.cw-cta-phone) .content {
          background: $component-bg-auto-color;
        }
        // CTA with img and link only
        &:not(.cw-cta-phone) {
          &.cw-with-image:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link a {
              color: color(action, m);
            }
          }
        }
      }
    }
  }
}
@include component-region-bg-styles(2,3) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane.cb-style--auto:not(:only-child) &,
    &.pane.cb-style--auto:not(:only-child) {
      .paragraphs-item-cta:not(.cw-cta-phone) {
        .field-name-field-title h2,
        .field-name-field-cta-description p {
          color: $component-bg-auto-font-color;
        }
        &.cw-with-title,
        &.cw-with-description {
          .field-name-field-cta-link a {
            @include btn($style: solid, $size: s, $dark: false);
          }
        }
        // CTA with link only
        &.cw-with-image:not(.cw-with-title):not(.cw-with-description) {
          .field-name-field-cta-link a {
            &::after {
              color: color(action, m);
            }
            &:focus {
              outline-color: color(action, m);
            }
          }
        }
      }
    }
  }
}
