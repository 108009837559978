// Compact Related Specialties Listing
// Condition, Treatment, Trial Detail pages

.cb-clinical-trial__specialties > .field,
.cb-clinical-trial__conditions > .field,
.cb-flavor:not(.node-type-cups-location) .cb-list-specialties--related-specialties {
  border: $component-border-style;
  @include component-padding();
}

.cb-flavor:not(.node-type-cups-location) .cb-list-specialties--related-specialties {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    @include media(bp(m)) {
      display: inline-block;
    }
    &:first-child {
      a {
        @include media(bp(m)) {
          padding-left: 0;
          border-left: none;
        }
      }
    }
    &:last-child {
      a {
        @include media(bp(m)) {
          padding-right: 0;
        }
      }
    }
    // fix hidden focus outlines
    .cb-layout__component-dynamic {
      overflow: inherit;
    }
  }
  a {
    @include component-link-title(s);
    @include media(bp(m)) {
      padding: 0 0.3em 0 0.6em;
      border-left: $border--xs;
      border-color: color(neutral-brand, hi);
    }
    &:active,
    &:focus {
      outline: 1px dotted;
      outline-color: inherit;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  h2 {
    @include component-heading(xs);
  }
  h3 {
    @include component-link-title(s);
  }
}
