// Testimonials: Right Rail Sidebar Only

// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.cb-list-testimonials--promoted {
          padding: 0;
          h2.pane-title {
            @include component-heading-right-rail();
            padding: 2rem 2rem 0 2rem;
          }
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          .view-content {
            padding: 0 2rem 0 2rem;
            &:last-child {
              padding-bottom: 2rem;
            }
            li {
              // Display image and text stacked due to narrow column width
              padding-left: 0;
              padding-right: 0;
              .cb-list-testimonials {
                &__title {
                  margin: auto;
                  text-align: left;
                  display: block;
                  h3 {
                    font-size: 1rem;
                    a {
                      @include component-link-title-right-rail();
                      color: $sidebar-component-link-color;
                      font-weight: 600;
                    }
                  }
                }
                &__summary {
                  margin-top: 0.3em;
                  p {
                    @include component-paragraph-right-rail();
                  }
                }
              }
              .cb-layout__component-dynamic:not(.column-second-empty) {
                .column-first {
                  margin-top: 0.5em;
                }
                .cb-list-testimonials {
                  &__image-teaser {
                    position: relative;
                    margin: 0 auto;
                    width: 100%;
                    height: auto;
                    img {
                      width: 100%;
                    }
                  }
                  &__title {
                    min-height: auto;
                    margin: auto;
                    &::before {
                      display: none;
                    }
                  }
                }
              }
            }
          }
          .more-link {
            padding-bottom: 0.5em;
            a {
              color: $sidebar-component-link-color;
              @include component-link-title-right-rail();
              &:hover {
                color: $sidebar-component-link-color;
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
