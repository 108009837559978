// CTA Full Width Typography Styles (Only Child in a Region)

.region {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta {
    &:only-child {
      .field-name-field-title h2 {
        @include component-heading(s);
      }
      .field-name-field-cta-description p,
      .field-name-field-cta-description a {
        margin-bottom: 0;
        @include component-paragraph(xs);
      }
      .field-name-field-cta-link a {
        line-height: 1.25;
        margin-bottom: 0;
      }
      @include media(bp(xs)) {
        .field-name-field-title h2 {
          @include component-heading(m);
        }
      }
      @include media(bp(s)) {
        .field-name-field-title h2 {
          @include component-heading(l);
        }
        .field-name-field-cta-description p,
        .field-name-field-cta-description a {
          margin-bottom: 0;
          @include component-paragraph(s);
        }
      }
      @include media(bp(m)) {
        .field-name-field-title h2 {
          @include component-heading(xl);
        }
        .field-name-field-cta-description p,
        .field-name-field-cta-description a {
          margin-bottom: 0;
          @include component-paragraph(m);
        }
      }
      @include media(bp(l)) {
        .paragraphs-item-cta.cw-with-title,
        .paragraphs-item-cta.cw-with-description {
          .field-name-field-cta-link a {
            text-align: center;
          }
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta.cb-style {
        &:only-child {
          @include media(bp(l)) {
            .field-name-field-title h2 {
              @include component-heading(l);
            }
            .field-name-field-cta-description p,
            .field-name-field-cta-description a {
              @include component-paragraph(s);
            }
            .field-name-field-cta-description p {
              margin-bottom: $spacing--s;
            }
          }
        }
      }
    }
  }
}

.region {
  &.cb-max-columns {
    .pane-bundle-cta.cb-style:only-child,
    .panels-ipe-bundle-cta:only-child {
      .content {
        text-align: center;
      }
      .cb-position-h--left {
        * {
          // Needed to override CTA base styles.
          align-items: flex-start;
        }
        .content {
          text-align: left;
        }
        .field-name-field-title,
        .field-name-field-cta-description,
        .field-name-field-cta-link {
          width: 100%;
        }
        .field-name-field-cta-description {
          flex: 1 0 auto;
        }
      }
      .field-name-field-title,
      .field-name-field-cta-description,
      .field-name-field-cta-link {
        margin: 0 auto;
        position: relative;
        height: auto;
        @include media(bp(m)) {
          max-width: 46em;
        }
      }
      .field-name-field-title {
        margin-top: $spacing--xs;
        @include media(bp(m)) {
          margin-top: 0;
        }
      }
      .field-name-field-title,
      .field-name-field-cta-description {
        z-index: 4;
      }
      .field-name-field-cta-description {
        p {
          /* stylelint-disable selector-max-compound-selectors */
          a {
            font-family: $font--text--alt;
          }
          /* stylelint-enable selector-max-compound-selectors */
        }
      }
      .field-name-field-cta-link {
        z-index: z(contentTop);
      }
      .paragraphs-item-cta {
        &.cw-with-image {
          .field-name-field-title {
            @include media(bp(m)) {
              text-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12);
            }
          }
        }
      }
      // stylelint-disable selector-max-compound-selectors, max-nesting-depth
      .paragraphs-item-cta {
        &.cb-position-h--left {
          .field-name-field-title,
          .field-name-field-cta-description,
          .field-name-field-cta-link {
            width: 100%;
            max-width: 100%;
            @include media(bp(l)) {
              display: block;
              width: 71.6%;
              float: left;
            }
          }
          .field-name-field-cta-link {
            a {
              @include media(bp(l)) {
                width: 100%;
              }
            }
          }
          &:not(.cw-with-icon) {
            .field-name-field-cta-link {
              word-break: break-word;
              @include media(bp(l)) {
                width: 25.6%;
                float: right;
                text-align: right;
                margin-top: 0;
              }
            }
            .field-name-field-cta-description {
              @include media(bp(l)) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      // stylelint-enable selector-max-compound-selectors, max-nesting-depth
      .field-name-field-title {
        h2 {
          @include media(bp(l)) {
            font-size: 3rem;
          }
          @include media(bp(m)) {
            font-size: 2.25rem;
            font-weight: 600;
          }
        }
      }
      .field-name-field-cta-link {
        padding-bottom: 0;
        @include media(min-width 0 max-width (bp(bs) - 0.06em)) {
          a {
            @include media(bp(m)) {
              font-size: 0.8rem;
            }
          }
        }
      }
      .paragraphs-item-cta {
        &.cw-with-image {
          @include media($bp-only-m) {
            .field-name-field-title {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
