// CTA with no image background styles

@include component-region-bg-styles(auto) {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta.cb-style {
    .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
      background-color: $component-bg-2-color;
      .field-name-field-title h2,
      .field-name-field-cta-description,
      .field-name-field-cta-description a,
      .field-name-field-cta-link-prefix,
      .field-name-field-cta-link-suffix,
      .field-name-field-icon {
        color: #fff;
      }
      // CTA with link only
      &:not(.cw-with-title):not(.cw-with-description) {
        .field-name-field-cta-link a,
        .field-name-field-cta-link a::after {
          color: #fff;
        }
      }
      &:hover {
        @include component-bg-hover-styles($component-bg-2-color, $change: 'darken');
        .content {
          @include component-bg-hover-styles($component-bg-2-color, $change: 'darken');
        }
      }
    }
  }
}
@include component-region-bg-styles(1) {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta.cb-style {
    .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
      // CTA with link only
      &:not(.cw-with-title):not(.cw-with-description) {
        .field-name-field-cta-link a,
        .field-name-field-cta-link a::after {
          color: #fff;
        }
      }
      &:hover {
        .content {
          @include component-bg-hover-styles($component-bg-3-color, $change: 'darken');
        }
      }
    }
    &:only-child {
      .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
        background: $component-bg-3-color;
        &:hover {
          @include component-bg-hover-styles($component-bg-3-color, $change: 'darken');
        }
        .field-name-field-title h2,
        .field-name-field-cta-description,
        .field-name-field-cta-description a,
        .field-name-field-cta-link-prefix,
        .field-name-field-cta-link-suffix,
        .field-name-field-icon {
          color: #fff;
        }
      }
    }
  }
}
@include component-region-bg-styles(2) {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta.cb-style {
    .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
      background: $component-bg-1-color;
      // CTA with link only
      &:not(.cw-with-title):not(.cw-with-description) {
        .field-name-field-cta-link a,
        .field-name-field-cta-link a::after {
          color: a11yrize(color(action, m), $component-bg-1-color);
        }
      }
      &:hover {
        @include component-bg-hover-styles($component-bg-1-color, $change: 'darken');
        .content {
          @include component-bg-hover-styles($component-bg-1-color, $change: 'darken');
        }
      }
    }
    &:only-child {
      .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
        &.cw-with-title,
        &.cw-with-description {
          .field-name-field-cta-link a {
            @include btn($style: solid, $size: s, $dark: false);
          }
        }
      }
    }
  }
}
@include component-region-bg-styles(3) {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta.cb-style {
    .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
      background: #fff;
      .field-name-field-title h2,
      .field-name-field-cta-description p,
      .field-name-field-cta-description a,
      .field-name-field-cta-link-prefix,
      .field-name-field-cta-link-suffix,
      .field-name-field-icon {
        color: color(neutral-brand, xxlo);
      }
      &.cw-with-title,
      &.cw-with-description {
        .field-name-field-cta-link a {
          @include btn($style: solid, $size: s, $dark: false);
        }
      }
      // CTA with link only
      &:not(.cw-with-title):not(.cw-with-description) {
        .field-name-field-cta-link a,
        .field-name-field-cta-link a::after {
          color: color(action, m);
        }
      }
      &:hover {
        @include component-bg-hover-styles(#fff, $change: 'darken');
        .content {
          @include component-bg-hover-styles(#fff, $change: 'darken');
        }
      }
    }
  }
}

// Region padding-none on homepage and featured layout with no sidebars:
// CTA uses the background color of the region
// to enable full-width edge-to-edge styles

.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  @include component-region-bg-styles(auto) {
    &.cb-padding--none {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta.cb-style {
        &:only-child {
          .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
            background: transparent;
            .field-name-field-title h2,
            .field-name-field-cta-description,
            .field-name-field-cta-description a,
            .field-name-field-cta-link-prefix,
            .field-name-field-cta-link-suffix,
            .field-name-field-icon {
              color: $component-bg-auto-font-color;
            }
            &.cw-with-title,
            &.cw-with-description {
              .field-name-field-cta-link a {
                @include btn($style: solid, $size: s, $dark: false);
              }
            }
            // CTA with link only
            &:not(.cw-with-title):not(.cw-with-description) {
              .field-name-field-cta-link a,
              .field-name-field-cta-link a::after {
                color: color(action, m);
              }
            }
            &:hover {
              @include component-bg-hover-styles(#fff, $change: 'darken');
              .content {
                @include component-bg-hover-styles(#fff, $change: 'darken');
              }
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(1,2,3) {
  &.cb-padding--none {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta.cb-style {
      &:only-child {
        .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
          background: transparent;
        }
      }
    }
  }
}

@include component-region-bg-styles(1) {
  &.cb-padding--none {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta.cb-style {
      &:only-child {
        .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
          .field-name-field-title h2,
          .field-name-field-cta-description,
          .field-name-field-cta-description a,
          .field-name-field-cta-link-prefix,
          .field-name-field-cta-link-suffix,
          .field-name-field-icon {
            color: $component-bg-auto-font-color;
          }
          &.cw-with-title,
          &.cw-with-description {
            .field-name-field-cta-link a {
              @include btn($style: solid, $size: s, $dark: false);
            }
          }
          // CTA with link only
          &:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link a,
            .field-name-field-cta-link a::after {
              color: a11yrize(color(action, m), $region-bg-1-color);
            }
          }
          &:hover {
            @include component-bg-hover-styles($region-bg-1-color, $change: 'darken');
            .content {
              @include component-bg-hover-styles($region-bg-1-color, $change: 'darken');
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(2) {
  &.cb-padding--none {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta.cb-style {
      &:only-child {
        .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
          &:hover {
            @include component-bg-hover-styles($region-bg-2-color, $change: 'darken');
            .content {
              @include component-bg-hover-styles($region-bg-2-color, $change: 'darken');
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(3) {
  &.cb-padding--none {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta.cb-style {
      &:only-child {
        .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
          &:hover {
            @include component-bg-hover-styles($region-bg-3-color, $change: 'darken');
            .content {
              @include component-bg-hover-styles($region-bg-3-color, $change: 'darken');
            }
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(2,3) {
  &.cb-padding--none {
    .pane-bundle-cta.cb-style,
    .panels-ipe-bundle-cta.cb-style {
      &:only-child {
        .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
          .field-name-field-title h2,
          .field-name-field-cta-description p,
          .field-name-field-cta-description a,
          .field-name-field-cta-link-prefix,
          .field-name-field-cta-link-suffix,
          .field-name-field-icon {
            color: #fff;
          }
          &.cw-with-title,
          &.cw-with-description {
            .field-name-field-cta-link a {
              @include btn($style: solid, $size: s, $dark: true);
            }
          }
          // CTA with link only
          &:not(.cw-with-title):not(.cw-with-description) {
            .field-name-field-cta-link a,
            .field-name-field-cta-link a::after {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

// Square CTA without images
@include component-region-bg-styles(2,3) {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .cb-style--1.pane:not(:only-child) &,
    &.cb-style--1.pane:not(:only-child) {
      &:not(:only-child) {
        .paragraphs-item-cta:not(.cw-cta-phone):not(.cw-with-image) {
          &:hover .field-name-field-cta-link a {
            color: $cta-font-color;
          }
          .field-name-field-title h2,
          .field-name-field-cta-link a {
            color: $cta-font-color;
          }
          .field-name-field-cta-link a {
            border-color: $cta-font-color;
            background: transparent;
            box-shadow: none;
            &:focus,
            &:hover {
              box-shadow: none;
              background: $btn-bg-color;
              color: #fff;
              border-color: $btn-bg-color;
            }
          }
        }
      }
    }
  }
}
