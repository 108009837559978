.cb-signup-form--icontact {
  max-width: 600px;
  .form-header {
    background: color(secondary, brand);
    padding: 1em 2rem;
    h3 {
      font-size: 1.5em;
      margin-top: 0;
      color: #fff;
    }
  }
  .sortables {
    background: $background-solid-xlight;
    padding: 2em;
    padding-top: 1.5em;
    border: 2px solid color(secondary, brand);
    border-top: 0;
  }
  // stylelint-disable selector-class-pattern
  .formEl {
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
  // stylelint-enable selector-class-pattern
  .indicator.required {
    margin-left: 0.25em;
    color: darken(color(accent-orange), 2%);
  }
  .submit-container {
    margin-top: 2em;
  }
  input {
    &[type="text"] {
      border: solid 1px color(neutral-brand, m);
      border-radius: 0;
      width: 100%;
    }
    &[type="radio"],
    &[type="checkbox"] {
      width: 25px;
      margin-left: -25px;
      vertical-align: text-top;
    }
  }
  label {
    &.radio,
    &.checkbox {
      padding-left: 25px;
    }
  }
  fieldset {
    border: solid 1px color(neutral-brand, m);
    padding: 0;
    legend {
      font-family: $font--text--alt;
      padding-right: 0.5em;
      background: $background-solid-xlight;
      margin-left: -1px;
    }
    .option-container {
      padding: 1em;
      padding-top: 0.5em;
    }
  }
  select {
    background: #fff url($dropdown-select-icon-img-file) no-repeat 97% center;
    padding: 4px 34px 4px 14px;
    border-radius: 0;
    max-width: 100%;
    white-space: normal;
  }
}
