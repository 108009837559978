// Video basic page styles

.cb-layout--no-aside,
.cb-layout--aside-last,
.cb-layout--aside-first {
  .cb-content-area__main {
    .panels-ipe-video,
    .pane-video {
      @include media(bp(l)) {
        .field-name-field-video-image {
          a {
            &::before {
              font-size: 1.3rem;
            }
          }
        }
        .field-name-field-title {
          @include component-heading(s);
          margin-bottom: 0;
          max-width: 100%;
        }
      }
    }
  }
}

// 3-columns layouts only
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--aside-first-last {
  .cb-content-area__main {
    .panels-ipe-video,
    .pane-video {
      @include media(bp(l)) {
        .field-name-field-video-image {
          a {
            &::before {
              font-size: 1.3rem;
            }
          }
        }
        .field-name-field-title {
          font-size: 1.3rem;
        }
      }
    }
  }
}
