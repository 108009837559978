.cb-news-simple-search {
  .no-js & {
    margin-bottom: 2em;
  }
  button {
    span {
      &.show-hide__description {
        // Search icon
        @include icon($icon: "search", $pseudo: "before", $size: 1em);
      }
    }
  }
  .cb-panel-container {
    background: color(primary, brand);
    .js & {
      @include visually-hidden();
      &.show-hide {
        @include visually-hidden--off();
        overflow: visible;
      }
    }
  }
  .views-exposed-form {
    label {
      @include visually-hidden();
    }
    .views-exposed-widget {
      float: none;
      padding: 0;
    }
    // stylelint-disable-next-line selector-class-pattern
    .views-widget-filter-search_api_views_fulltext {
      @include media(bp(s)) {
        float: left;
        display: inline-block;
        width: 77%;
        margin-right: 0;
      }
      input {
        min-height: $touch-size-large;
        width: 100%;
        padding: 12px;
        border: none;
        @include media(bp(s)) {
          line-height: 20px;
        }
      }
    }
    .views-exposed-widget.views-submit-button {
      @include media(bp(s)) {
        margin: 0;
        @include span-columns(3, inline-block);
      }
      .form-submit {
        @include btn($style: solid, $size: s, $dark: true);
        @include override-nested() {
          padding-bottom: 0.65em;
          width: 100%;
          margin-top: 0.5em;
          background: color(secondary, m);
          color: $btn-font-color-interior-pg-component;
          border-bottom: none;
        }
        @include media(bp(s)) {
          border-radius: 0 4px 4px 0;
          margin-top: 0;
        }
        &:hover,
        &:focus {
          background: color(secondary, m);
          color: $btn-hover-font-color-interior-pg-component;
        }
      }
    }
  }
  .pane-cola-news-search-panel-pane-news-simple-search-box {
    position: relative;
    margin-bottom: 0.1em;
  }
  // Arrow indicator
  .cb-indicator {
    @include media(bp(m)) {
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      top: -59px;
      right: calc(50% - 15px);
      border-width: 15px;
      border-bottom: 12.5px solid color(primary, brand);
    }
  }
}
