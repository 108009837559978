// CUMC Global Header
//
// markup:
// <div class="cb-cumc-global-header">
//   <div class="cb-cumc-global-header__wrapper">
//     <a href="https://www.cuimc.columbia.edu/" target="_blank" class="cb-cumc-global-header__link cb-cumc-global-header__link--cumc-logo">Columbia University Irving Medical Center</a>
//   </div>
// </div>
//
// Styleguide: global.header

.cb-cumc-global-header {
  display: block;
  height: 50px;
  background-color: color(primary, brand);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  z-index: 10;
  &__wrapper {
    background-color: color(primary, brand);
    padding: 1.125em 0.5em 1.25em 0.5em;
    text-align: center;
    font-size: 1em;
    line-height: 1.5;
    color: #fff;
  }
  &__link--cumc-logo {
    color: #fff;
    text-decoration: none;
    margin: 0 auto;
    @include image-replace('#{$image-path}/logos/logo-cuimc-longform--white.svg?v=1.0.0', 384px, 12px, 100, 0.5em);
    @include media(bp(bs)) {
      @include image-replace('#{$image-path}/logos/logo-cuimc-longform--white.svg?v=1.0.0', 384px, 12px, 100, 1.25em);
      background-size: 384px 12px;
    }
  }
}