// Opening Hours Background Styles

@mixin opening-hours-bg-colors($bg-color, $title-color, $font-color, $link-color, $border-color) {
  @if $bg-color != none and $bg-color != transparent {
    $title-color: a11yrize($title-color, $bg-color);
    $font-color: a11yrize($font-color, $bg-color);
    $link-color: a11yrize($link-color, $bg-color);
  }
  background: $bg-color;
  h3 {
    color: $title-color;
    .field-items::after {
      background: color(secondary, xhi);
    }
  }
  ul {
    &.cw-opening-hours__hours {
      border-color: $border-color;
      .cw-opening-hours {
        &__day,
        &__time {
          color: $font-color;
        }
      }
    }
  }
  .field-name-field-summary {
    border-color: $border-color;
    p {
      color: $font-color;
    }
    a {
      text-decoration: underline;
      text-decoration-color: $link-color;
      color: $link-color;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .cw-opening-hours {
    li:nth-child(even) {
      @if $bg-color != none and $bg-color != transparent {
        background: a11yrize(lighten-or-darken($bg-color, 3%, $font-color), $font-color);
      }
      @else {
        border: 1px solid $border-color;
        border-width: 1px 0;
      }
    }
  }
}

@include component-region-bg-styles(auto,3) {
  .panels-ipe-bundle-place,
  .pane-bundle-place {
    @include opening-hours-bg-colors(
      $component-bg-auto-color,
      $component-bg-auto-title-color,
      $component-bg-auto-font-color,
      $component-bg-auto-link-color,
      $component-bg-auto-border-color
    );
    @include override-nested() {
      border: $component-border-style;
    }
    ul.cw-opening-hours__hours,
    .field-name-field-summary {
      border-color: var(--site-accent);
    }
    .cw-opening-hours {
      li:nth-child(even) {
        background: $component-bg-1-color;
      }
    }
  }
}

@include component-region-bg-styles(2) {
  .panels-ipe-bundle-place,
  .pane-bundle-place {
    @include opening-hours-bg-colors(
      $component-bg-3-color,
      $component-bg-3-title-color,
      $component-bg-3-font-color,
      $component-bg-3-link-color,
      $component-bg-3-border-color
    );
    h3 {
      color: #fff;
    }
    .field-name-field-summary {
      p,
      a {
        color: #fff;
      }
    }
    .field-name-field-summary a {
      text-decoration-color: #fff;
    }
    ul {
      &.cw-opening-hours__hours {
        .cw-opening-hours {
          &__day,
          &__time {
            color: #fff;
          }
        }
      }
    }
  }
}

@include component-region-bg-styles(1) {
  .panels-ipe-bundle-place,
  .pane-bundle-place {
    // Darkening bg-color slightly to get around hsl conversion error.
    // see https://github.com/sass/sass/issues/469
    // and https://github.com/sass/sass/issues/363
    @include opening-hours-bg-colors(
      darken($component-bg-2-color, 1%),
      $component-bg-2-title-color,
      $component-bg-2-font-color,
      $component-bg-2-link-color,
      $component-bg-2-border-color
    );
  }
}

// Default basic page styles
.cb-page-content .cb-content-area__main {
  .panels-ipe-bundle-place,
  .pane-bundle-place {
    border: $component-border-style;
    background: $component-bg-auto-color;
    ul.cw-opening-hours__hours,
    .field-name-field-summary {
      border-color: var(--site-accent);
    }
    .cw-opening-hours {
      li:nth-child(even) {
        background: $component-bg-1-color;
      }
    }
  }
}
