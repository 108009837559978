// CTA Phone Sidebar Styles and Overrides

.cb-content-area__aside-last {
  .pane-bundle-cta.pane,
  .panels-ipe-bundle-cta {
    .paragraphs-item-cta {
      &.cw-cta-phone {
        .content {
          padding: 0;
          background-color: transparent;
          min-height: auto;
          a {
            @include component-paragraph(l);
            color: $sidebar-component-link-color;
            font-weight: 700;
          }
        }
        .field-name-field-cta-link-prefix,
        .field-name-field-cta-link-suffix {
          @include component-paragraph(l);
          color: $sidebar-component-title-color;
          font-weight: 600;
          &::before {
            display: inline;
            margin-right: 0.2em;
          }
        }
        span.field-name-field-cta-link {
          width: auto;
          a {
            @include component-paragraph(l);
            color: $sidebar-component-link-color;
            font-weight: 700;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
