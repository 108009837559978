// Labs and Unit by Type List Component Sidebar Styles

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.cb-list-units--component,
      &.cb-list-labs--component {
        border: none;
        background: $sidebar-component-bg-color;
        .view-content {
          max-width: $sidebar-component-content-max-width;
          margin: 0 auto 1rem auto;
          padding: 0;
          background: transparent;
          ul {
            padding-right: $spacing--m;
            padding-left: $spacing--m;
            display: flex;
            flex-wrap: wrap;
          }
          .cb-list-units,
          .cb-list-labs {
            &__summary {
              border-top: $border--xs;
              border-color: $sidebar-component-border-color;
            }
          }
          li {
            background: #fff;
            border-top: $border--l;
            border-color: $sidebar-component-bg-color;
            width: 100%;
            &:hover {
              /* stylelint-disable selector-max-compound-selectors */
              .cb-list-units__title a,
              .cb-list-labs__title a {
                text-decoration: underline;
              }
              /* stylelint-enable */
            }
            &:last-child {
              border-top: $border--l;
              border-color: $sidebar-component-bg-color;
            }
            @include media(bp(bs)) {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              .cb-layout__component-dynamic {
                position: relative;
              }
              .column-first {
                width: 100%;
                margin-right: 0;
                // with image
                &:not(:only-child) {
                  .cb-list-units,
                  .cb-list-labs {
                    &__title {
                      .field-name-title-field {
                        padding-right: 170px;
                        display: flex;
                        align-items: center;
                        height: 90px;
                      }
                    }
                  }
                }
              }
              // adjust image ratio to fit within container
              .column-second {
                height: 90px;
                border-top: none;
                right: 0;
                position: absolute;
                top: 0;
                width: 160px;
                float: left;
                display: block;
                overflow: hidden;
                img {
                  position: absolute;
                  top: 0;
                  right: 0;
                  margin: auto;
                }
              }
              /* stylelint-enable */
            }
            @include media(bp(m)) {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              .column-second {
                height: 140px;
                width: 248.89px;
              }
              // with image
              .column-first:not(:only-child) {
                .cb-list-labs,
                .cb-list-units {
                  &__title {
                    .field-name-title-field {
                      height: 140px;
                      padding-right: 260px;
                    }
                  }
                }
              }
              /* stylelint-enable */
            }
          }
          + .item-list {
            background: transparent;
            /* stylelint-disable selector-max-compound-selectors */
            ul.pager {
              padding-bottom: 0;
              margin-bottom: 0;
            }
            /* stylelint-enable */
          }
        }
        .more-link {
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.cb-list-units--component,
        &.cb-list-labs--component {
          padding-top: $spacing--s;
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          .view-content {
            padding: $sidebar-component-right-rail-padding;
            padding-top: 0;
            margin-bottom: 0;
            ul {
              padding-right: 0;
              padding-left: 0;
              li {
                &:last-child {
                  border-bottom: none;
                }
              }
            }
            li {
              margin-top: 0;
              background: transparent;
              border-top: none;
              border-bottom: $border--s;
              border-color: $sidebar-component-border-color;
              padding: $spacing--m 0;
              &:first-child {
                border-top: $border--m;
                border-color: $sidebar-component-border-color;
              }
              &:last-child {
                padding-bottom: 0;
              }
              a {
                color: a11yrize(color(action, m), $sidebar-component-bg-color);
              }
              // display image and text stacked
              .cb-list-units,
              .cb-list-labs {
                &__title {
                  .field-name-title-field {
                    padding: 0;
                    min-height: auto;
                    height: auto;
                  }
                }
                &__summary {
                  padding: $spacing--xs 0 0 0;
                  border: none;
                }
                &__image-teaser {
                  margin-bottom: $spacing--s;
                }
              }
              .column-second {
                margin-top: 0;
                position: relative;
                width: 100%;
                height: auto;
                img {
                  max-width: 100%;
                  position: relative;
                  top: auto;
                  bottom: auto;
                  left: auto;
                  right: auto;
                  width: 100%;
                  height: auto;
                }
              }
              .column-first {
                &:not(:only-child) {
                  .cb-list-units,
                  .cb-list-labs {
                    &__title {
                      .field-name-title-field {
                        padding-right: $spacing--m;
                        min-height: auto;
                        height: auto;
                      }
                    }
                  }
                }
              }
              &:last-child {
                border-bottom: 3px solid $sidebar-component-border-color;
              }
            }
            // pagination
            + .item-list {
              margin-top: 0;
              ul {
                margin-bottom: 0;
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}
