// Promoted Events: Sidebar

// Sidebar specific events styles only
.cb-content-area__aside-last {
  .pane {
    &.cb-list-events--promoted,
    &.panels-ipe-portlet-wrapper .cb-list-events--promoted {
      .column-first.column--first {
        display: flex;
        flex-direction: column;
      }
      .cb-list-events {
        &__date {
          order: 1;
          font-weight: 400;
          .field-name-field-event-date {
            font-size: 1rem;
            @include media(bp(m)) {
              font-size: 1.3rem;
            }
          }
          ~ .cb-list-events__time {
            font-weight: 400;
            white-space: nowrap;
          }
        }
        &__time {
          order: 2;
          margin-top: 0;
        }
        &__title {
          order: 3;
          margin-top: $spacing--m;
        }
        &__location {
          font-size: 0.8rem;
          line-height: 1.4;
          order: 4;
          @include media(bp(l)) {
            line-height: 1.2;
            margin-top: 0.5em;
          }
          a {
            font-size: 0.8rem;
            font-weight: 400;
            color: $sidebar-component-link-color;
            @include icon($icon: "map-marker", $pseudo: before) {
              font-size: 0.9em;
              margin: -0.1rem 0.3rem 0 0.06rem;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &__date,
        &__time {
          font-size: 0.8rem;
          display: inline;
          line-height: 1.4;
          @include media(bp(l)) {
            display: block;
          }
          * {
            display: inline;
          }
        }
      }
      .view-content {
        li {
          &:first-child {
            padding-top: $spacing--m;
          }
        }
      }
      // Empty events listing
      .view-empty {
        min-height: auto;
        padding: 0.5em 0 0 0;
        margin: 0 auto;
        width: 90%;
        max-width: $sidebar-component-content-max-width;
        @include media(bp(m)) {
          margin: 0 auto 1em auto;
          padding: 0;
        }
        ul {
          max-width: $sidebar-component-content-max-width;
          margin: 0 auto;
        }
        li {
          margin-bottom: 2em;
          border-color: $sidebar-component-border-color;
        }
        p {
          margin-bottom: 0;
          font-family: $font--text--alt;
          text-align: center;
          max-width: 100%;
          color: $sidebar-component-font-color;
          @include component-paragraph(l);
        }
        &:last-child {
          padding: 0;
          margin: 0 auto 1em auto;
        }
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.cb-list-events--promoted {
          .cb-list-events {
            /* stylelint-disable selector-max-compound-selectors */
            &__title {
              h3 {
                margin-bottom: 0;
                a {
                  @include component-link-title-right-rail();
                }
              }
            }
            &__location {
              margin-top: 0.25em;
              a {
                font-size: 0.75rem;
                font-weight: 400;
              }
            }
            &__date {
              .field-name-field-event-date {
                font-size: 0.9rem;
              }
            }
            &__time {
              font-size: 0.7rem;
            }
            /* stylelint-enable */
          }
          .view-empty {
            /* stylelint-disable selector-max-compound-selectors */
            p {
              @include component-paragraph-right-rail();
            }
            /* stylelint-enable */
          }
        }
        &.cb-list-events--promoted.cb-list-events--deadline,
        &.panels-ipe-portlet-wrapper .cb-list-events--promoted.cb-list-events--deadline {
          h2.pane-title {
            /* stylelint-disable selector-max-compound-selectors */
            &::before {
              max-width: 22px;
              max-height: 22px;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
