// Section Nav overrides.
// stylelint-disable selector-max-compound-selectors
.cb-flavor--compact-mega-menu {
  .cb-nameplate {
    .cb-panel-container {
      align-items: flex-end;
    }
    .cb-primary-menu {
      .cb-megamenu {
        line-height: 1;
        > li {
          @include media(bp(xl)) {
            display: inline-block;
            line-height: 1;
          }
        }
      }
      .cb-megamenu__panel-group-links > li {
        margin: 0;
        font-size: 1rem;
        a {
          font-weight: 400;
        }
      }
    }
  }
}

.cb-flavor--compact-mega-menu {
  .cb-site-header {
    .cb-nameplate:last-child {
      .cb-primary-menu {
        .cb-megamenu__link,
        .cb-megamenu .cb-search::before {
          @include media(bp(xl)) {
            font-size: 1.25vw;
          }
          @include media(bp(xxl)) {
            font-size: 1.2rem;
          }
        }
        .cb-megamenu .cb-search {
          @include media(bp(xl)) {
            width: 1.25vw;
          }
          @include media(bp(xxl)) {
            width: 1.2rem;
          }
        }
        .cb-megamenu .cb-search::before {
          @include media(bp(xl)) {
            top: calc(50% - 0.625vw);
            height: 1.25vw;
          }
          @include media(bp(xxl)) {
            top: calc(50% - 0.6rem);
            height: 1.2rem;
          }
        }
      }
    }
  }
}
// stylelint-enable selector-max-compound-selectors

.cb-flavor--compact-mega-menu {
  .cb-site-header .cb-nameplate {
    overflow: visible;
    position: relative;
    .cb-primary-menu .cb-search {
      line-height: 1;
    }
  }
  .cb-megamenu {
    position: static;
    &__panel-heading a {
      color: a11yrize(color('action'), $background-solid-xlight);
      display: inline;
      font-size: 1.25rem;
      @include icon($icon: 'arrow-right', $pseudo: 'after', $size: 1.25rem) {
        margin-left: 0.25em;
        margin-top: -0.2em;
      }
      &:hover::after {
        @include animated($name: wobble);
      }
    }
    &__panel {
      max-width: $page-container-max-width;
      margin: 0 auto;
      text-align: left;
      font-weight: 400;
      top: calc(100% + 6px);
    }
    .cb-megamenu__item--columns-2.cb-megamenu__item--offset-2:not(.cb-megamenu__item--with-large-panel) {
      .cb-megamenu__panel {
        right: -0.5vw;
        @include media(bp(xxl)) {
          right: -0.6rem;
        }
        @include media(bp(xl)) {
          right: -0.625vw;
        }
      }
    }
    &__link,
    > li > a {
      @include media(bp(xl)) {
        display: inline-block;
        line-height: 1;
      }
    }
    &__link {
      position: relative;
      @include media(bp(xl)) {
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          width: 0;
          height: 6px;
          margin: 0 auto;
          background: transparent;
          transition: all 0.35s ease;
        }
        &:hover::after {
          width: 100%;
          background: color(neutral-brand, xxhi);
        }
      }
    }
    &__link--panel {
      padding-right: 0.8em;
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        transition: all 0.2s ease;
        height: 0;
        background: none;
        border-top: 6px solid transparent;
      }
      &.cb-megamenu__link--active {
        &::before {
          left: -0.5vw;
          width: calc(100% + 1vw);
          @include media(bp(xxl)) {
            left: -0.6rem;
            width: calc(100% + 1.2rem);
          }
          @include media(bp(xl)) {
            left: -0.625vw;
            width: calc(100% + 1.25vw);
          }
        }
      }
      &.active-trail::before {
        @include media(bp(xl)) {
          border-color: color(secondary, xhi);
        }
      }
    }
    &__link--active {
      z-index: 10;
      &::before {
        top: -18px;
        // Link height (1em) + link border top (6px)
        // Nameplate padding bottom (2rem) +
        // Nameplate border (6px) +
        // Negative Offset Top (18px) +
        // rounding errors (2px)
        height: calc(1em + 2rem + 32px);
        background: #2058a2;
        z-index: -1;
        border-top-color: #2058a2;
      }
      &::after {
        display: none;
      }
    }
    &--featured-items {
      li:not(.cb-menu-item--featured) .cb-megamenu__link--active::before {
        height: calc(1em + 2rem + 33px);
      }
    }
  }
  // Overspecification needed to override critical styles.
  // stylelint-disable selector-max-compound-selectors
  .cb-nameplate .pane-main-menu.cb-primary-menu .cb-primary-links >li > a.cb-megamenu__link--active {
    color: #fff;
  }
  // stylelint-enable selector-max-compound-selectors
}

// Featured ghosted menu buttons.
.cb-flavor--compact-mega-menu .cb-nameplate {
  .cb-megamenu > li {
    &.cb-menu-item--featured > a,
    &.cb-menu-item--featured > a.active-trail.active {
      @include media(bp(xl)) {
        @include btn($style: outline, $size: s);
        @include override-nested() {
          border-color: color(action, lo);
          color: color(action, lo);
          padding: 0.3em 0.6em;
          background: #fff;
          font-size: 1.05vw;
        }
        &:focus {
          color: color(action, lo);
          background: #fff;
        }
        &::before,
        &::after {
          bottom: -9px;
        }
        &::after {
          background: transparent;
        }
        // stylelint-disable selector-max-compound-selectors
        &.cb-megamenu__link--active {
          color: #fff;
          &:focus {
            outline-color: #fff;
          }
        }
        &.cb-megamenu__link--panel {
          padding-right: 1.2em;
          .cb-megamenu__panel-hint {
            right: 0.6em;
          }
        }
        // stylelint-enable selector-max-compound-selectors
      }
      @include media(bp(xl)) {
        font-size: 1.25vw;
      }
      @include media(bp(xxl)) {
        font-size: 1.2rem;
      }
    }
  }
}
