// Sidebar desktop jump menu: CUPS profile only

.cb-content-area__aside-first {
  .pane-jump-menu {
    display: none;
    @include media(bp(xl)) {
      display: block;
    }
    // No javascript fallback for mobile.
    .no-js & {
      @include media(max-width (bp(xl) - 0.06em)) {
        display: block;
        font-size: 0.9em;
        ul:first-child {
          margin-bottom: -1.5em;
          border: 1px solid #ccc;
          border-width: 1px 0;
          padding: 0.25em 0;
          a {
            font-weight: 700;
          }
        }
        li {
          margin-bottom: 0;
        }
        a {
          &.active-trail.active {
            color: color(neutral-brand, lo);
            pointer-events: none;
            cursor: default;
            &::before {
              content: '';
              border-left: 0.4em solid color(neutral-brand, m);
              height: 1em;
              margin-right: 0.4em;
              display: inline-block;
              vertical-align: text-bottom;
            }
          }
        }
      }
    }
  }
  .pane-jump-menu {
    &.element--sticky {
      width: calc((100% - 2em) * 0.2247899);
      max-width: calc(1160px * 0.2247899);
      @include media(bp(xl)) {
        width: 22.47899%;
      }
    }
  }
}
