// Promoted News, Blog, Announcements Base Styles

.cb-list-news--promoted,
.cb-list-news--related-news {
  .view-content {
    li {
      margin-bottom: $spacing--l;
      padding-top: $spacing--m;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul:not(.panels-ipe-linkbar) {
      .column-second-empty .column--first,
      .columns-2.column--first {
        display: flex;
        flex-direction: column;
      }
      .column--first {
        > * {
          margin-bottom: 0;
        }
        .cb-list-news {
          &__summary {
            margin-top: $spacing--s;
          }
          &__best-category {
            margin-bottom: 0.2rem;
          }
        }
      }
    }
  }
}

.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted,
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      .cb-list-news {
        &__image-teaser {
          max-height: 100%;
        }
      }
    }
  }
}
