// Promoted Events styles depending on list number

.cb-flavor:not(.cb-list-news) {
  .region {
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      @include media(bp(bs)) {
        max-width: 100%;
        /* stylelint-disable selector-max-compound-selectors */
        .view-content {
          > ul {
            flex-wrap: wrap;
            display: flex;
          }
        }
        li {
          // 2 or 4 events: display 2 per row
          @include quantities-all(2,4) {
            @include span-columns(6) {
              display: flex;
              flex: 1 0 auto;
              flex-direction: column;
            }
            .cb-layout__component-dynamic,
            .col {
              display: flex;
              flex: 1 0 auto;
              flex-direction: column;
            }
          }
          /* stylelint-enable */
          @include quantity-positions(2,1) {
            margin-left: 0;
            margin-bottom: 0;
          }
          @include quantity-positions(2,2) {
            margin-right: 0;
          }
          @include quantity-positions(4,1) {
            margin-left: 0;
          }
          @include quantity-positions(4,2,4) {
            margin-right: 0;
            margin-right: 0;
          }
          @include quantity-positions(4,3) {
            margin-left: 0;
            margin-bottom: 0;
          }
        }
      }
      /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
      @include media(bp(m)) {
        li,
        .cb-layout__component-dynamic,
        .col {
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
        }
        .view-content {
          > ul {
            display: flex;
            justify-content: center;
            > li {
              &:only-child {
                max-width: 100%;
                .cb-layout__component-dynamic {
                  width: 100%;
                }
              }
            }
          }
        }
        li {
          @include quantities-all(1) {
            .cb-list-events {
              &__date {
                padding-bottom: 0;
              }
            }
            .cb-layout__component-dynamic {
              // ie11 and ie10 also needs this to prevent
              // flexbox width stretch issue
              max-width: $component-content-max-width;
              width: 100%;
              margin-left: auto;
              margin-right: auto;
            }
          }
          /* stylelint-enable */
          @include quantities-all(3) {
            @include span-columns(4);
          }
          @include quantity-positions(3,1,2) {
            margin-bottom: 0;
          }
          @include quantity-positions(3,1) {
            margin-left: 0;
          }
          @include quantity-positions(3,3) {
            margin-right: 0;
          }
          @include quantity-positions(4,1) {
            margin-bottom: $spacing--m;
          }
        }
      }
      @include media(bp(l)) {
        li {
          @include quantities-all(1) {
            /* stylelint-disable selector-max-compound-selectors */
            .cb-list-events {
              &__title {
                margin-bottom: $spacing--s;
              }
              &__location {
                margin-bottom: $spacing--m;
              }
            }
            /* stylelint-enable */
          }
          @include quantity-positions(2,1) {
            margin-right: 1.6%;
          }
          @include quantity-positions(2,2) {
            margin-left: 1.6%;
          }
          @include quantities-all(4) {
            @include span-columns(3);
          }
          @include quantity-positions(4,1,2) {
            margin-bottom: 0;
          }
          @include quantity-positions(4,1,2,3) {
            margin-right: 1.6%;
          }
          @include quantity-positions(4,2,3,4) {
            margin-left: 1.6%;
          }
        }
      }
      @include media(bp(xl)) {
        li {
          @include quantities-all(2) {
            .cb-list-events {
              /* stylelint-disable selector-max-compound-selectors */
              &__date {
                margin-bottom: 0;
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      @include media(bp(l)) {
        li {
          @include quantities-all(1) {
            /* stylelint-disable selector-max-compound-selectors */
            .cb-layout__component-dynamic {
              text-align: center;
            }
            .cb-list-events {
              &__date {
                padding-left: 0;
                padding-right: 0;
              }
              &__time {
                margin-left: 0;
                margin-right: 0;
              }
              &__title,
              &__location {
                max-width: $component-content-max-width;
                margin: 0 auto;
                text-align: center;
              }
              &__location {
                margin-bottom: $spacing--xl;
              }
            }
            /* stylelint-enable */
          }
          @include quantities-all(2) {
            .cb-list-events {
              /* stylelint-disable selector-max-compound-selectors */
              &__date {
                padding-left: $spacing--xl;
                padding-right: $spacing--xl;
              }
              &__time,
              &__title,
              &__location {
                margin-left: $spacing--xl;
                margin-right: $spacing--xl;
              }
              /* stylelint-enable */
            }
          }
          @include quantities-all(4) {
            .cb-list-events {
              /* stylelint-disable selector-max-compound-selectors */
              &__date {
                padding: $spacing--m $spacing--m 0 $spacing--m;
              }
              &__time,
              &__title,
              &__location {
                margin-left: $spacing--m;
                margin-right: $spacing--m;
              }
              /* stylelint-enable */
            }
          }
        }
      }
      @include media(bp(xl)) {
        li {
          @include quantities-all(4) {
            .cb-list-events {
              /* stylelint-disable selector-max-compound-selectors */
              &__date {
                margin-bottom: 0;
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}
