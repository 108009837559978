// Promoted News, Blog, Announcements, Events Base Styles

.cb-list-news--promoted,
.cb-list-news--related-news,
.cb-list-events--promoted {
  position: relative;
  @include clearfix();
  > * {
    width: 100%;
  }
  .view {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .view-content {
    flex-grow: 1;
    margin: 0;
    > ul {
      margin: 0 auto;
      list-style: none;
      padding: 0;
      @include media(bp(bs)) {
        .columns-2 {
          &.column--first {
            @include span-columns(6 of 8);
          }
          &.column--last {
            flex: 0 0 auto;
            @include span-columns(2 of 8){
              @include omega();
            }
          }
        }
      }
      > li {
        position: relative;
        padding-top: $spacing--m;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .pane-node-body {
        display: none;
      }
      .cb-layout__component-dynamic {
        // fix hidden focus outlines
        overflow: inherit;
      }
    }
  }
  .panel-separator {
    display: none;
  }
  .cb-list-events,
  .cb-list-news {
    &__image-teaser {
      margin-top: $spacing--s;
      text-align: center;
      @include media(bp(s)) {
        overflow: hidden;
        margin-top: 0;
      }
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}

// Icon styles
.region,
.cb-content-area__aside-last,
.cb-content-area__main {
  .cb-list-news--promoted,
  .cb-list-events--promoted {
    h2.pane-title {
      &::before {
        content: "";
        vertical-align: sub;
        @include media(bp(l)) {
          vertical-align: top;
        }
      }
    }
  }
  // Default News
  .cb-list-news--promoted {
    h2.pane-title {
      @include icon($icon: "news", $pseudo: "before", $size: 1em, $color: $component-icon-color) {
        margin-top: -5px;
        margin-right: -2px;
      }
    }
  }
  // Blog
  .cb-list-news--promoted.cb-list-news--blog {
    h2.pane-title {
      @include icon($icon: "comment", $pseudo: "before", $size: 0.9em, $color: $component-icon-color) {
        margin-top: -2px;
        margin-right: -2px;
      }
    }
  }
  // Announcements
  .cb-list-news--promoted.cb-list-news--announcements {
    h2.pane-title {
      @include icon($icon: "announcements", $pseudo: "before", $size: 0.9em, $color: $component-icon-color)  {
        margin-top: -0.25em;
      }
    }
  }
  // News Topic (newsroom): hide icon
  .cb-list-news--main & {
    .cb-list-news--promoted.cb-list-news--topic {
      h2.pane-title {
        &::before {
          display: none;
        }
      }
    }
  }
  // Events
  .cb-list-events--promoted {
    h2.pane-title {
      @include icon($icon: "calendar", $pseudo: "before", $size: 0.85em, $color: $component-icon-color) {
        margin-top: -0.25em;
      }
    }
  }
  .cb-list-events--promoted.cb-list-events--by-category.cb-list-events--deadline {
    h2.pane-title {
      @include icon($icon: "deadline", $pseudo: "before", $size: 0.85em, $color: $component-icon-color) {
        margin-top: -0.25em;
      }
    }
  }
  .cb-list-news--promoted,
  .cb-list-events--promoted {
    h2.pane-title {
      text-align: center;
    }
    .view {
      margin: 0 auto;
    }
    .cb-list-news,
    .cb-list-events {
      &__title {
        a {
          &::after {
            background-color: #fff;
          }
        }
      }
    }
  }
}

// Fix IE10 extra spacing due to flex grow.
.cb-list-news--promoted,
.cb-list-news--related-news,
.cb-list-events--promoted {
  .cb-content-area__aside-last & {
    display: block;
    .view {
      display: block;
    }
  }
}

.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted,
    .cb-list-events--promoted,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted,
    .panels-ipe-portlet-wrapper .cb-list-events--promoted {
      max-width: 100%;
    }
  }
}
