// Related Profiles "Compact Styles"
// (name and image display only)
// Pages: News Article, Unit, Service, Cups location

.cb-list-profiles--related-profiles,
.cb-list-profiles--by-unit,
.node-type-cups-location .pane-cola-search-location-providers {
  .view-content {
    li {
      display: flex;
      align-items: center;
      background: $background-solid-xlight;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      .lt-ie10 & {
        height: 88px;
      }
      .cb-panel-container {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
  }
  .item-list .pager {
    margin-top: 1em;
    @include media(bp(m)) {
      margin-top: 0;
    }
  }
}

// Related providers markup differs on cups location page
// and component is not paginated like all other pages
.node-type-cups-location .pane-cola-search-location-providers {
  overflow: hidden;
  clear: both;
  /* stylelint-disable selector-max-compound-selectors */
  ul:not(.pager) {
    li {
      display: flex;
      align-items: center;
      &:only-child,
      &:first-child:nth-last-child(2),
      &:last-child:nth-child(2) {
        display: flex;
      }
      &:nth-last-child(3):nth-child(odd)
      &:nth-last-child(2):nth-child(even) {
        @include media(bp(bs)) {
          margin-bottom: 0;
        }
      }
    }
  }
  /* stylelint-enable */
}
