// CTA Full Width Typography Styles (no image)

.region {
  .pane-bundle-cta.cb-style,
  .panels-ipe-bundle-cta {
    &:only-child {
      .paragraphs-item-cta:not(.cw-with-title):not(.cw-with-description):not(.cw-with-image):not(.cw-cta-phone) {
        .field-name-field-cta-link {
          a {
            @include media(bp(bs)) {
              @include component-heading(s);
              margin-bottom: 0;
            }
            @include media(bp(s)) {
              @include component-heading(m);
              margin-bottom: 0;
            }
            @include media(bp(m)) {
              @include component-heading(l);
              margin-bottom: 0;
            }
          }
        }
      }
      .paragraphs-item-cta:not(.cw-with-image):not(.cw-cta-phone) {
        .field-name-field-cta-description {
          z-index: auto;
        }
      }
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .pane-bundle-cta.cb-style,
      .panels-ipe-bundle-cta.cb-style {
        &:only-child {
          .paragraphs-item-cta:not(.cw-cta-phone):not(.cw-with-image) {
            &.cw-with-description:not(.cw-with-icon) {
              @include media(bp(l)) {
                .field-name-field-cta-description p,
                .field-name-field-cta-description a {
                  @include component-paragraph(m);
                }
                /* stylelint-disable selector-max-compound-selectors */
                .field-name-field-cta-description a {
                  margin-bottom: 0;
                }
                /* stylelint-enable */
              }
            }
            // CTA link only
            &:not(.cw-with-title):not(.cw-with-description) {
              .field-name-field-cta-link a {
                @include media(bp(l)) {
                  @include component-heading(l);
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
