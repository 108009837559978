// Gateway Main Content Only

.cb-content-area__main {
  .cb-gateway {
    overflow: auto;
    @include component-padding();
    &__content {
      a {
        color: a11yrize(color(action, m), $component-bg-1-color);
        text-decoration: none;
      }
    }
    &__title {
      @include component-heading(m);
      .field-name-field-gateway-references a {
        @include component-link-title(s);
      }
    }
  }
}

// Main content only
.cb-layout--aside-first,
.cb-layout--aside-last {
  .cb-content-area__main {
    .cb-gateway {
      @include media(bp(l)) {
        h2.cb-gateway__title {
          @include component-heading(xl);
        }
        .field-name-field-gateway-references {
          a {
            @include component-link-title(s);
          }
          // Display gateway links in 2 columns
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          .field-item {
            @include media(bp(l)) {
              float: left;
              @include span-columns(6);
              &:nth-child(2n+1) {
                clear: both;
              }
              &:nth-child(2n+2) {
                @include omega();
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}

.cb-layout--aside-first-last {
  .cb-content-area__main {
    .cb-gateway {
      @include media(bp(l)) {
        h2.cb-gateway__title {
          @include component-heading(l);
        }
        .field-name-field-gateway-references a {
          @include component-link-title(s);
        }
      }
    }
  }
}
