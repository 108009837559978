.node-type-condition {
  .pane-node-field-diagnosis-overview,
  .pane-cola-clinical-condition-symptoms,
  .pane-cola-clinical-risk-factors,
  .pane-node-field-treatments,
  .pane-node-field-prevention-overview,
  .pane-node-field-treatments-overview {
    margin-bottom: $spacing--xl;
    @include media(bp(m)) {
      margin-bottom: $spacing--xxl;
    }
  }
  .pane-node-field-diagnosis-overview,
  .pane-cola-clinical-condition-symptoms,
  .pane-cola-clinical-risk-factors,
  .pane-node-field-prevention-overview,
  .pane-node-field-treatments-overview {
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .pane-node-field-symptoms,
  .pane-node-field-risk-factors {
    &:not(:last-child) {
      margin-bottom: $spacing--m;
    }
  }
  .pane-node-field-risk-factors,
  .pane-node-field-symptoms {
    .item-list {
      background: $background-solid-xlight;
      @include component-padding();
    }
    li {
      font-size: 0.9rem;
    }
  }
}
