.page-not-found,
.page-access-denied {
  .cb-page-content {
    .cb-container {
      padding: 0 1.25em;
    }
    .cb-container__inner,
    .pane-page-title h1 {
      text-align: center;
    }
    .cb-container__inner {
      max-width: 28.125em;
      @include media(bp(m)) {
        max-width: 34.375em;
      }
      @include media(bp(l)) {
        max-width: 38.1875em;
      }
    }
    .pane-page-title h1 {
      margin-top: 2em;
      margin-bottom: 0.5rem;
    }
    .field-name-field-text-text {
      p {
        font-size: 1.625em;
        font-family: $font--display;
        font-weight: 700;
        color: color(primary, xlo);
        border-bottom: 1px solid $border-light;
        padding-bottom: 1.25rem;
        margin-bottom: 3rem;
        @include media(bp(m)) {
          font-size: 2em;
        }
        @include media((bp(l))) {
          font-size: 2.25em;
        }
      }
    }
  }
}

.page-access-denied {
  .cb-page-content {
    .cb-container__inner {
      @include media(max-width bp(bs)) {
        max-width: 330px;
      }
    }
  }
}

// Drupal maintenance page uses IDs.
// To override we would need to create our own maintenance tpl.
/* stylelint-disable selector-max-id, selector-max-specificity */
.maintenance-page {
  background-color: $maintenance-page-bg-color;
  overflow-x: visible;
  @include max-page-width();
  text-align: center;
  padding: 0 $page-margins;
  &.cb-flavor--nameplate-primary {
    background-color: color(primary, brand);
  }
  // logo styles
  #header {
    margin: 1.5rem auto 1rem auto;
    @include media(bp(m)) {
      margin: 3rem auto 2rem auto;
    }
  }
  .cb-header-logo {
    @include max-page-width();
    &__site-name {
      text-align: center;
      max-width: 19rem;
      margin: 0 auto;
      @include media(bp(m)) {
        max-width: 42em;
      }
      picture + span {
        display: none;
      }
      img {
        width: auto;
      }
    }
  }
  #container {
    padding: 2em 1em;
    max-width: 47.5em;
    margin: 0 auto;
    box-shadow: $box-shadow;
    border-radius: 2px;
    background: #fff;
    @include media(bp(m)) {
      padding: 2em 3em;
    }
    @include media(bp(l)) {
      max-width: 75%;
      padding: 3em 4em;
    }
  }
  #content {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include media(bp(s)) {
      height: 50vh;
    }
    @include media(bp(l)) {
      height: 50vh;
    }
  }
  .title,
  #content-content {
    width: 100%;
  }
  .title {
    @include icon($icon: 'warning', $size: 1.2em);
    &::before {
      margin-right: 0.35em;
      margin-top: -0.18em;
    }
  }
  #content-content {
    @include h4();
  }
}
/* stylelint-enable */
