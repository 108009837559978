// Opening Hours Sidebar Styles

// Standardized sidebar component default styles
.cb-page-content {
  .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-bundle-place,
      &.pane-bundle-place {
        background: $sidebar-component-bg-color;
        @include component-sidebar-padding();
        h3 {
          color: $sidebar-component-title-color;
          @include component-heading(s);
          @include media(bp(bs)) {
            @include component-heading(m);
          }
          @include media(bp(m)) {
            @include component-heading(l);
          }
        }
        .cw-opening-hours {
          &__day,
          &__time {
            @include component-paragraph(s);
            @include media(bp(m)) {
              @include component-paragraph(m);
            }
          }
          &__day {
            @media screen and (max-width: bp(bs)) {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

// Component specific sidebar styles
.cb-content-area__aside-last {
  .pane {
    &.panels-ipe-bundle-place,
    &.pane-bundle-place {
      &:not(:only-child) {
        .cw-opening-hours {
          @include media(bp(m)) {
            padding: 0;
          }
        }
      }
      &:only-child {
        .cw-opening-hours {
          max-width: 100%;
        }
      }
      .pane-bundle-place {
        margin-bottom: 0;
      }
      .cw-opening-hours {
        max-width: $sidebar-component-content-max-width;
        margin: 0 auto;
        padding: 0;
        h3 {
          @include media(bp(l)) {
            text-align: center;
          }
        }
        .field-name-field-summary {
          border-top: $border--s;
          border-color: $sidebar-component-border-color;
          max-width: 20em;
          /* stylelint-disable selector-max-compound-selectors */
          p {
            color: $sidebar-component-font-color;
            @include component-paragraph(s);
            a {
              color: $sidebar-component-link-color;
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
          /* stylelint-enable */
        }
      }
      ul {
        &.cw-opening-hours__hours {
          margin-top: $spacing--m;
          border-top: $border--m;
          border-color: $sidebar-component-border-color;
          max-width: 20em;
        }
      }
    }
  }
}

// Right rail styles only
// 2-columns layout with no left nav and basic page 3-columns layout
.cb-layout--aside-last,
.cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
  .cb-content-area__aside-last {
    @include media(bp(xl)) {
      .pane {
        &.panels-ipe-bundle-place,
        &.pane-bundle-place {
          padding: $sidebar-component-right-rail-padding;
          h3 {
            @include component-heading-right-rail();
          }
          .cw-opening-hours {
            &__day,
            &__time {
              @include component-paragraph-right-rail();
              width: 100%;
              text-align: center;
              line-height: 1;
            }
            &__time {
              margin-top: 0;
            }
            &__day {
              font-weight: 600;
            }
            .field-name-field-summary {
              p {
                @include component-paragraph-right-rail();
              }
            }
          }
          ul {
            /* stylelint-disable selector-max-compound-selectors */
            &.cw-opening-hours__hours {
              li {
                line-height: 1.3;
                margin-bottom: 0.5em;
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}

// Drupal panels ipe editing display fixes
.cb-flavor.panels-ipe-editing {
  .cb-content-area__aside-last {
    .pane {
      &.panels-ipe-bundle-place {
        padding: 0;
        .panels-ipe-portlet-content {
          @include component-sidebar-padding();
        }
      }
    }
  }
  // right rail
  .cb-layout--aside-last,
  .cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) {
    .cb-content-area__aside-last {
      @include media(bp(xl)) {
        .pane {
          &.panels-ipe-bundle-place {
            padding: 0;
            /* stylelint-disable selector-max-compound-selectors */
            .panels-ipe-portlet-content {
              padding: $sidebar-component-right-rail-padding;
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
