// Shared based styles between lab/clinic/unit/clinical trial "heads and investigator"
.cb-clinical-trial__investigator .field-name-field-trial-investigator,
.cb-list-profiles--unit-heads,
.cb-list-profiles--clinic-heads,
.cb-list-profiles--lab-heads {
  padding: 1.5em;
  border-radius: 2px;
  box-shadow: $box-shadow;
  background-color: #fff;
  text-align: left;
  position: relative;
  width: 100%;
  float: none;
  border: none;
  @include clearfix();
  @include media(bp(s)) {
    padding: 1.6em 2em 2em 2em;
  }
  &:not(:last-child) {
    margin-bottom: $spacing--xl;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul.field-name-field-cola-cups-profiles {
    > li {
      &:not(:last-child) {
        margin-bottom: 0.7em;
      }
    }
  }
  .cb-panel-container {
    padding-left: 75px;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .cb-list-profiles {
      &__title {
        order: 2;
      }
      &__headshot {
        order: 1;
      }
      &__positions {
        order: 3;
        line-height: 1.4;
      }
    }
  }
  .cb-list-profiles {
    &__title {
      width: 100%;
      float: right;
      margin-bottom: 0;
      h3 {
        font-size: 1.125rem;
        line-height: 1.2;
        margin: 0 0 0.25em 0;
      }
      a {
        color: color(action, m);
        font-weight: 600;
        text-decoration: none;
        font-size: 1.125rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__positions {
      font-family: $font--text--alt;
      .field-item {
        + .field-item {
          margin-top: 0.25em;
        }
      }
      ul {
        li {
          margin-left: 0;
          line-height: 1.2;
          color: color(neutral-brand, xxlo);
        }
      }
    }
    &__headshot {
      display: inline-block;
      margin-left: -76px;
      width: 60px;
      overflow: hidden;
      img {
        // Width 100% needed for lazyload auto sizing calculation.
        width: 100%;
        max-width: 60px;
        margin-bottom: 1em;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  // Fix ipe
  .panels-ipe-portlet-wrapper:not(:last-child) & {
    &:last-child {
      margin-bottom: $spacing--xl;
    }
  }
}

.cb-list-profiles--unit-heads,
.cb-list-profiles--clinic-heads,
.cb-list-profiles--lab-heads {
  h2.pane-title {
    text-align: left;
    color: color(neutral-brand, xxlo);
    font-size: 1.3rem;
    margin: 0 0 1em 0;
    font-weight: 600;
  }
}

// Override default base styles
.cb-content-area__main {
  .pane {
    &.cb-list-profiles--unit-heads,
    &.cb-list-profiles--clinic-heads,
    &.cb-list-profiles--lab-heads {
      &:last-child {
        margin-bottom: $spacing--xl;
        @include media(bp(m)) {
          margin-bottom: $spacing--xxl;
        }
      }
      .front & {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }
  }
}
