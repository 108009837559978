// Region Backgrounds

// Homepage and featured layout with no sidebars
.front,
.cb-flavor:not(.cb-list-news):not(.node-type-cups-profile) .cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows,
.cb-flavor--subsection-front:not(.cb-list-news):not(.node-type-cups-profile) .cb-layout--aside-first,
.cb-flavor--subsection-front:not(.cb-list-news):not(.node-type-cups-profile) .cb-layout--aside-first-last {
  .region {
    &.cb-background--auto {
      background-color: #fff;
    }
    &.cb-background--1 {
      @include region-bg(1);
      @include clearfix();
    }
    &.cb-background--2 {
      @include region-bg(2);
      @include clearfix();
    }
    &.cb-background--3 {
      @include region-bg(3);
      @include clearfix();
    }
    // "Landing page" (cola_base_dynamic_features_asides_feature_rows) adjustments.
    // This extra margin is to keep the main page content in line with the region
    // when there is a background color.
    .cb-content-area__feature-rows & {
      &.cb-background:not(.cb-background--auto) {
        @include media(bp(l)) {
          width: width-margins(100%);
          margin: 0 $component-spacing;
        }
      }
    }
  }
}

// featured layout with sidebars: always white bg by default
.cb-flavor:not(.cb-list-news):not(.node-type-cups-profile):not(.cb-flavor--subsection-front) {
  .cb-layout--aside-first-last,
  .cb-layout--aside-first,
  .cb-layout--aside-last {
    &.cb-layout--dynamic-features-asides-feature-rows {
      .region.cb-padding--none.cb-background {
        background-color: #fff;
      }
    }
  }
}
