// Gateway Styles depending on component per row

// Standardized component default padding sizes
.region {
  .cb-gateway {
    @include component-padding();
    &:only-child {
      width: 100%;
      max-width: 100%;
    }
    @include media($bp-only-s) {
      @include quantities-all(odd) {
        .field-name-field-gateway-references {
          .field-item {
            // display gateway links in 2 columns
            float: left;
            @include span-columns(6);
            &:nth-child(2n+1) {
              clear: both;
            }
            &:nth-child(2n+2) {
              @include omega();
            }
          }
        }
      }
    }
    @include media(bp(m)) {
      @include quantities-all(odd) {
        .field-name-field-gateway-references {
          .field-item {
            // display gateway links in 1 column
            @include span-columns(12);
          }
        }
      }
    }
    @include media($bp-only-m) {
      &:nth-child(odd):only-child {
        .field-name-field-gateway-references {
          .field-item {
            // display gateway links in 2 columns
            float: left;
            @include span-columns(6);
            &:nth-child(2n+1) {
              clear: both;
            }
            &:nth-child(2n+2) {
              @include omega();
            }
          }
        }
      }
    }
    @include media(bp(l)) {
      @include quantities-all(1) {
        padding: $spacing--xl;
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-gateway {
      @include media(bp(l)) {
        @include quantities-all(1) {
          .field-name-field-gateway-references {
            .field-item {
              // display gaateway links in 3 columns
              float: left;
              @include span-columns(4);
              &:nth-child(3n+1) {
                clear: both;
              }
              &:nth-child(3n+3) {
                @include omega();
              }
            }
          }
        }
        @include quantities-all(2) {
          padding: $spacing--xl;
        }
        @include quantities-all(4) {
          padding: $spacing--m;
        }
      }
    }
  }
}

// Featured Layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .cb-gateway {
        @include media(bp(l)) {
          &:only-child {
            .field-name-field-gateway-references {
              /* stylelint-disable selector-max-compound-selectors */
              .field-item {
                float: left;
                @include span-columns(6);
                &:nth-child(2n+1) {
                  clear: both;
                }
                &:nth-child(2n+2) {
                  @include omega();
                }
              }
              /* stylelint-enable */
            }
          }
          @include quantities-all(3,5) {
            padding: $spacing--m;
          }
          // Six or more components in a region:
          // always "1/3" component width
          &:first-child:nth-last-child(n+6),
          &:nth-child(2):nth-last-child(n+5),
          &:nth-child(3):nth-last-child(n+4),
          &:nth-child(4):nth-last-child(n+3),
          &:nth-child(5):nth-last-child(n+2),
          &:nth-child(n+6) {
            padding: $spacing--m;
          }
        }
      }
    }
  }
}
