// Video featured layout styles

.cb-content-area__feature-rows {
  .region {
    .panels-ipe-video.pane,
    .pane-video.pane {
      margin-left: $component-spacing;
      margin-right: $component-spacing;
      width: width-margins(100%);
    }
  }
}

// Featured layout with sidebars
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    // default side paddings within all regions
    .region.cb-padding {
      .panels-ipe-video.pane,
      .pane-video.pane {
        margin-left: $component-spacing;
        margin-right: $component-spacing;
      }
    }
  }
}
