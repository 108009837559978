// Carousel
.cb-gallery {
  margin: 3.2em 0 4em 0;
  .slide__content {
    border: $border--s;
  }
  .slide__caption {
    min-height: auto;
    padding: 1em 1.2em 1.1em 1.2em;
    font-family: $font--text--alt;
    font-size: 0.875rem;
    color: color(neutral-brand, m);
    .slide__title {
      @include h4();
    }
  }
  // Minimizing the impact slides have on following content when the images
  // are in the process of loading. This sets a maximum height onthe slide
  // images to prevent excessive placeholder space (based on a 16:9 ratio).
  .slide__media {
    img {
      max-height: 56.25vw;
      @include media(bp(l)) {
        max-height: 675px;
        .cb-content-area__main & {
          // 0.4219 is is the 75% width from the main-content and the 56.25%
          // ratio for the image combined (0.75 * 0.5625).
          max-height: calc((100vw - 2.5em) * 0.4219);
        }
      }
      @include media(bp(xl)) {
        max-height: 945px;
        .cb-content-area__main & {
          max-height: 485px;
        }
      }
      .cb-content-area__main & {
        max-height: calc(56.25vw - 2.5em);
      }
    }
  }
}

.cb-gallery,
.cb-carousel {
  .slide__content {
    display: flex;
    flex-direction: column;
    > * {
      flex: 0 0 auto;
    }
  }
  .slide__caption {
    order: 2;
  }
  .slide__media {
    order: 1;
  }
}

.slick-dots--thumbnail {
  list-style: none;
  text-align: center;
  margin: 1em 0 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  // initial hidden on page load in critical css
  visibility: visible;
  .slick-dot-content {
    @include visually-hidden();
  }
  .slick-dots__thumbnail {
    img {
      display: block;
    }
  }
  li {
    // The min height magic numbers are in place to minimize
    // content jumping while the slick dot thumbnails are loading.
    // This sets the min-height of a thumbnail nav item to the
    // height required by a 16:9 image.
    position: relative;
    display: inline-block;
    margin: 0.15em 0.25em;
    padding: 7px;
    width: calc(25% - 0.5em);
    min-height: calc(((56.25vw - 2.5em) / 4) - 1px);
    transition: all 0.3s ease-in-out;
    @include media(bp(s)) {
      width: calc(20% - 0.5em);
      min-height: calc(((56.25vw - 2.5em) / 5) - 1px);
    }
    @include media(bp(m)) {
      width: calc(16.6666% - 0.5em);
      min-height: calc(((56.25vw - 2.5em) / 6) - 1px);
    }
    @include media(bp(l)) {
      min-height: 65px;
      &.slick-active {
        min-height: 58px;
      }
    }
    @include media(67.5em) {
      min-height: 73px;
      &.slick-active {
        min-height: 67px;
      }
    }
    @include media(bp(xl)) {
      min-height: 81px;
      &.slick-active {
        min-height: 75px;
      }
      .cb-layout--no-aside &,
      .cb-layout--aside-last & {
        min-height: 72px;
        &.slick-active {
          min-height: 66px;
        }
      }
      // 3-columns basic page with both sidebars
      .cb-layout--aside-first-last .cb-page-content:not(.cb-page-content--feature-rows) & {
        min-height: 55px;
        &.slick-active {
          min-height: 47px;
        }
      }
    }
    &:last-child {
      margin: 0.15em 0.25em;
    }
  }
  button {
    background: none;
    box-shadow: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: transparent;
    width: 100%;
  }
  .slick-active {
    padding: 0;
    box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.24);
  }
}

// More graceful loading of the gallery.
// Prevent slick from creating massive spaces based on actual image dimensions.
// Adds a loader animated gif.
.slide--loading {
  .slide__media {
    position: relative;
    &::before {
      content: '';
      background: url('#{$image-path}/ajax-loader-transparent.gif');
      width: 32px;
      height: 32px;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      // 16:9 image ratio.
      padding-bottom: 56.25%;
      .cb-carousel & {
        // We use 21:9 for carousels.
        padding-bottom: 42.86%;
      }
    }
  }
  img {
    position: absolute;
    width: 100%;
  }
  &.slick-current {
    .slide__media {
      background: #eee;
      &::before {
        content: '';
        background: url('#{$image-path}/ajax-loader-transparent.gif');
        width: 32px;
        height: 32px;
        position: absolute;
        top: calc(50% - 16px);
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}

.slick-list {
  .cb-gallery & {
    z-index: z(content);
  }
}

.slick-controls {
  padding: 0;
  margin: 0;
  list-style: none;
  // hidden initially on page load in critical css
  display: block;
  li {
    margin: 0;
  }
  .cb-gallery & {
    position: absolute;
    top: 0;
    width: 100%;
    padding-bottom: 56.25%;
    border: $border--s;
    border-bottom: none;
  }
}

.slick-arrow {
  box-shadow: none;
  padding: 0;
  position: absolute;
  font-size: 30px;
  line-height: 30px;
  width: 38px;
  height: 75px;
  background: rgba(255, 255, 255, 0.75);
  text-indent: 200%;
  overflow: hidden;
  white-space: nowrap;
  color: color(neutral-brand, lo);
  transition: all 0.5s;
  &:hover,
  &:focus {
    background: $btn-bg-color-interior-pg-component;
    color: #fff;
    box-shadow: none;
    outline-color: $btn-focus-outline-color-interior-pg-component;
    @media (hover: hover) and (pointer: fine) {
      background: $btn-bg-color-interior-pg-component;
      color: #fff;
      width: 42px;
      font-size: 34px;
      line-height: 34px;
    }
  }
  .cb-gallery & {
    top: calc(50% - 38px);
    z-index: z(contentTop);
    @include media(bp(m)) {
      top: calc(50% - 50px);
      font-size: 40px;
      line-height: 40px;
      bottom: auto;
      width: 50px;
      height: 100px;
    }
    // stylelint-disable declaration-no-important
    // Slick adds inline display: block styles for the arrow buttons.
    // Therefore we need !important to override the behavior.
    @media (hover: hover) {
      display: block !important;
      @include media(bp(m)) {
        width: 56px;
        font-size: 46px;
        line-height: 46px;
      }
    }
    @media (hover: none) {
      display: none !important;
    }
    // stylelint-enable declaration-no-important
  }
  .cb-carousel & {
    // 21:9 ratio vertical midpoint 21.43%.
    top: calc(21.43vw - 38px);
    @include media(bp(l)) {
      top: calc(50% - 65px);
    }
  }
  // slim carousel (3:1 ratio)
  .cb-style--auto .cb-carousel & {
    // 3:1 ratio vertical midpoint 16.67%.
    top: calc(16.67vw - 38px);
    @include media(bp(l)) {
      top: calc(50% - 65px);
    }
  }
}

.slick-prev {
  left: 0;
  border-radius: 0 4px 4px 0;
  @include icon(caret-left) {
    position: absolute;
    left: 0;
    top: 50%;
    text-indent: 0;
    transform: translate(0, -50%);
    color: $btn-bg-color-interior-pg-component;
  }
  .cb-carousel & {
    @media (hover: hover) and (pointer: fine) {
      left: -50px;
    }
  }
  .cb-gallery & {
    @media (hover: hover) {
      left: -30px;
      @include media(bp(m)) {
        left: -40px;
      }
    }
  }
  &:focus,
  &:hover {
    &::before {
      color: #fff;
    }
  }
}

.slick-next {
  right: 0;
  border-radius: 4px 0 0 4px;
  @include icon(caret-right) {
    position: absolute;
    right: 0;
    top: 50%;
    text-indent: 0;
    margin: auto;
    transform: translate(0, -50%);
    color: $btn-bg-color-interior-pg-component;
  }
  .cb-carousel & {
    @media (hover: hover) {
      right: -50px;
    }
  }
  .cb-gallery & {
    @media (hover: hover) {
      right: -30px;
      @include media(bp(m)) {
        right: -40px;
      }
    }
  }
  &:focus,
  &:hover {
    &::before {
      color: #fff;
    }
  }
}

.cb-gallery {
  .slick-slide:focus {
    outline: none;
  }
  &.has-focus,
  .slick-list:hover + .slick-controls,
  .slick-controls:hover {
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
}

// Carousel specific styles.

.pane-bundle-carousel {
  .cb-carousel {
    margin: 0;
    &:hover,
    &.has-focus {
      .slick-dots {
        bottom: 0;
      }
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }
    }
    &__overlay-link {
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-indent: 200%;
      white-space: nowrap;
      z-index: z(content);
      .cb-content-area__main & {
        text-decoration: none;
      }
    }
    .slide__media,
    .media--image {
      img {
        width: 100%;
      }
    }
  }
  .slick__slide {
    &:hover {
      .slide__title a {
        text-decoration: underline;
      }
    }
    &:not(:first-child) {
      .no-js & {
        display: none;
      }
    }
  }
  .slide__content {
    // Without this lazysizes sets the wrong sizes attribute in chrome sometimes.
    width: 100%;
  }
  .slide__caption {
    // add arrow if slide is a link
    &:nth-last-child(3) {
      .slide__caption-content {
        @include icon($icon: 'arrow-right', $size: 1.1rem, $pseudo: 'after') {
          position: absolute;
          top: calc(50% - 10px);
          right: 0.6em;
          color: a11yrize(color(action, m), rgba(255, 255, 255, 0.9));
          @include media(bp(l)) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  .slide__description {
    color: color(neutral-brand, xxlo);
    font-family: $font--text--alt;
    @include media(bp(l)) {
      font-size: 1rem;
    }
    @include media(bp(xl)) {
      font-size: 1.25rem;
    }
  }
  .slick {
    position: relative;
    overflow: hidden;
    overflow: hidden;
    &:not(.slick--initialized) {
      .js & {
        max-height: calc(100vw * 0.33333);
        @include media(bp(xxl)) {
          max-height: calc(105em * 0.33333);
        }
      }
    }
  }
  .slick-pause,
  .slick-dot button {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    &:focus {
      outline-color: $btn-focus-outline-color-interior-pg-component;
    }
  }
  .slick-pause {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: calc(0.5em + 5px);
    z-index: 10;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.75);
    transition: all ease 400ms;
    &:focus,
    &:hover {
      background: $btn-bg-color-interior-pg-component;
    }
    &.slick--paused {
      overflow: hidden;
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 10px;
        top: 8px;
        border-left: 20px solid $btn-bg-color-interior-pg-component;
        border-right: 20px solid transparent;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
      }
      &:focus,
      &:hover {
        &::before,
        &::after {
          border-left-color: #fff;
        }
      }
    }
    &.slick--playing {
      &::before,
      &::after {
        content: '';
        width: 8px;
        height: 24px;
        background: $btn-bg-color-interior-pg-component;
        transition: all ease 400ms;
        display: block;
        position: absolute;
        top: 8px;
      }
      &::before {
        left: 9px;
      }
      &::after {
        right: 9px;
      }
      &:focus,
      &:hover {
        &::before,
        &::after {
          background: #fff;
        }
      }
    }
  }
  .slick-dots {
    list-style: none;
    padding: 6px 40px;
    text-align: center;
    background: color(neutral-brand, xxhi);
    display: inline-block;
    transition: all 0.5s;
    @include media(bp(l)) {
      left: 50%;
      transform: translateX(-50%);
      padding: 6px 12px;
      width: auto;
      background: rgba(255, 255, 255, 0.75);
      border-radius: 4px 4px 0 0;
      @media (hover: hover) {
        bottom: -50px;
      }
    }
  }
  .slick-dot {
    margin: 5px 5px 0 5px;
    display: inline-block;
    line-height: 1;
    button {
      width: 20px;
      height: 20px;
      background: color(neutral-brand, lo);
      text-indent: 200%;
      white-space: nowrap;
      border-radius: 20px;
      display: inline-block;
      overflow: hidden;
      cursor: pointer;
      border: 3px solid color(neutral-brand, lo);
      &:focus,
      &:hover {
        background: color(neutral-brand, hi);
        border-color: color(neutral-brand, lo);
      }
    }
    &-content {
      // This should be visually hidden, but to avoid a11y errors we need to
      // fix the contrast.
      color: $c-text;
      background: #fff;
    }
    &.slick-active {
      button {
        border-color: color(neutral-brand, lo);
        background: #fff;
      }
    }
  }
}

.front,
.cb-section-front {
  .region.cb-padding--none {
    .pane-bundle-carousel:only-child,
    .panels-ipe-bundle-carousel:only-child {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// Region is overlapped.
.cb-overlapped--bottom {
  .region.cb-padding--none {
    @include media(bp(xl)) {
      padding-bottom: 0;
      .pane-bundle-carousel {
        .cb-carousel {
          &:hover,
          &.has-focus {
            .slick-dots {
              bottom: 4rem;
            }
          }
        }
        .slick-dots {
          bottom: 0;
        }
        .slick-pause {
          bottom: calc(4rem + 13px);
        }
        .slide__caption-content {
          bottom: calc(4rem + 66px);
        }
      }
    }
  }
  .cb-content-area__feature-rows & {
    .region.cb-padding--auto {
      @include media(bp(xl)) {
        padding-bottom: 4em;
      }
    }
  }
}

// Basic page main content
.cb-content-area__main {
  .field-name-body {
    > .cb-gallery {
      margin-top: 0;
    }
  }
}
