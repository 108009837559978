// Promoted News Typography

// Default across all pages
.cb-flavor:not(.cb-list-news) {
  .region,
  .cb-content-area__main {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      ul:not(.panels-ipe-linkbar) {
        li {
          // stylelint-disable selector-max-compound-selectors
          .cb-list-news {
            &__title {
              h3 {
                @include component-link-title(s);
              }
            }
            &__date {
              display: none;
            }
          }
          .cb-list-news {
            &__summary {
              p {
                @include component-paragraph(xs);
              }
            }
          }
          // stylelint-enable selector-max-compound-selectors
        }
      }
    }
  }
}

.cb-flavor:not(.cb-list-news) {
  .region {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      ul:not(.panels-ipe-linkbar) {
        li {
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          @include quantity-positions(5,1) {
            .cb-list-news {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
            }
          }
          @include quantities-all(1) {
            .cb-list-news {
              &__title {
                h3 {
                  @include component-link-title(m);
                }
              }
            }
          }
          @include media(bp(bs)) {
            @include quantities-all(2,3,4,5) {
              .columns-1 {
                .cb-list-news {
                  // Display summary when item has no image
                  &__summary {
                    @include visually-hidden--off();
                    display: block;
                    margin-top: 0.5em;
                  }
                }
              }
            }
          }
          @include media(bp(m)) {
            @include quantities-all(1) {
              .column-first.column--first {
                // with image
                &:not(:only-child) {
                  .cb-list-news {
                    &__title {
                      h3 {
                        @include component-link-title(m);
                      }
                    }
                  }
                }
                // no image
                &:only-child {
                  .cb-list-news {
                    &__title {
                      h3 {
                        @include component-link-title(l);
                      }
                    }
                    &__summary {
                      p {
                        @include component-paragraph(s);
                      }
                    }
                  }
                }
              }
            }
            @include quantity-positions(5,1) {
              .column-first.column--first {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(l);
                    }
                  }
                  &__summary {
                    p {
                      @include component-paragraph(s);
                    }
                  }
                }
              }
            }
            @include quantity-positions(5,2,3,4,5) {
              .column-first.column--first {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(m);
                    }
                  }
                }
              }
            }
            @include quantities-all(2,4) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(m);
                  }
                }
              }
            }
          }
          @include media(bp(l)) {
            @include quantities-all(2) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(l);
                  }
                }
              }
            }
            @include quantities-all(4) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(s);
                  }
                }
              }
            }
            @include quantity-positions(5,2,3,4,5) {
              .column-first.column--first {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(s);
                    }
                  }
                }
              }
            }
          }
          @include media(bp(xl)) {
            @include quantities-all(4) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(m);
                  }
                }
              }
            }
            @include quantities-all(3) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(l);
                  }
                }
              }
            }
            @include quantities-all(2) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(xl);
                  }
                }
                &__summary {
                  p {
                    @include component-paragraph(s);
                  }
                }
              }
            }
            @include quantities-all(1) {
              .column-first.column--first {
                // with image
                &:not(:only-child) {
                  .cb-list-news {
                    &__title {
                      h3 {
                        @include component-link-title(xl);
                      }
                    }
                    &__summary {
                      p {
                        @include component-paragraph(s);
                      }
                    }
                  }
                }
              }
            }
            @include quantity-positions(5,1) {
              .column-first.column--first {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(xl);
                    }
                  }
                }
              }
            }
            @include quantity-positions(5,2,3,4,5) {
              .column-first.column--first {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(m);
                    }
                  }
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

// Featured Layout with sidebars:
// Decrease font size due to smaller page content width
.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region {
        .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
        .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
          @include media(bp(l)) {
            ul:not(.panels-ipe-linkbar) {
              li {
                /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                @include quantities-all(2) {
                  .cb-list-news {
                    &__title {
                      h3 {
                        @include component-link-title(m);
                      }
                    }
                  }
                }
                @include quantities-all(3,4) {
                  .cb-list-news {
                    &__title {
                      h3 {
                        @include component-link-title(s);
                      }
                    }
                  }
                }
                @include quantity-positions(5,2,3,4,5) {
                  .cb-list-news {
                    &__title {
                      h3 {
                        @include component-link-title(s);
                      }
                    }
                  }
                }
                /* stylelint-enable */
              }
            }
          }
          @include media(bp(xl)) {
            @include quantities-all(1) {
              ul:not(.panels-ipe-linkbar) {
                li {
                  /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
                  &:first-child {
                    .column-first.column--first {
                      // with image
                      &:not(:only-child) {
                        .cb-list-news {
                          &__summary {
                            p {
                              @include component-paragraph(xs);
                            }
                          }
                        }
                      }
                    }
                  }
                  /* stylelint-enable */
                }
              }
            }
          }
        }
      }
    }
  }
}

// Main content only:
.cb-flavor:not(.cb-list-news) {
  .cb-content-area__main {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      ul:not(.panels-ipe-linkbar) {
        li {
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          .cb-list-news {
            &__summary {
              @include visually-hidden();
            }
          }
          @include media(bp(s)) {
            .column-first.column--first {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(m);
                  }
                }
              }
            }
          }
          @include media(bp(m)) {
            .column-first.column--first {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(l);
                  }
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}

.cb-layout--aside-first-last.cb-flavor:not(.cb-list-news) {
  .cb-content-area__main {
    .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog),
    .panels-ipe-portlet-wrapper .cb-list-news--promoted:not(.cb-list-news--announcements):not(.cb-list-news--blog) {
      @include media(bp(l)) {
        h2.pane-title {
          @include component-heading(l);
        }
        ul:not(.panels-ipe-linkbar) {
          li {
            /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
            .column-first.column--first {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(m);
                  }
                }
              }
            }
            /* stylelint-enable */
          }
        }
      }
    }
  }
}
