@use "sass:math";

.cb-local-footer {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: color(primary, brand);
  a {
    &:focus {
      outline-color: tint(color(secondary, hi), 50%);
    }
  }
  .cb-container__inner {
    padding-top: 2.5625em;
    padding-bottom: 2.6875em;
  }
  .pane-cola-panels-affiliates {
    margin-right: 0;
    padding-bottom: 0.625em;
    border-bottom: 2px solid rgba(color(secondary, hi), 0.4);
    @include media(bp(m)) {
      margin-bottom: 1.75em;
    }
    .cb-panel-container {
      @include media(bp(m)) {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        text-align: center;
      }
    }
    .pane-link,
    .cb-affiliates--co-branded .pane-link {
      margin-bottom: 2em;
      @include media(bp(m)) {
        overflow: hidden;
        display: flex;
        align-items: stretch;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          width: 100%;
          // Focus outline padding.
          padding: 2px;
        }
      }
      span {
        // Ensure external link text does not break layout width.
        &.ext,
        &.ext .element-invisible {
          width: auto;
        }
        // Hide external link icon
        &::before {
          display: none;
        }
      }
    }
    &:not(.cb-affiliates--co-branded) {
      .pane-link {
        @include media(bp(m)) {
          width: calc(29.6% - 0.125em);
          margin: 0 1.7% 2em 1.7%;
          // Psychiatry site has an additional fourth footer logo
          @include quantities-all(4) {
            width: calc(46.6% - 0.125em);
          }
        }
        @include media(bp(l)) {
          @include quantities-all(4) {
            width: calc(21.6% - 0.125em);
          }
        }
        @include media(bp(xl)) {
          width: calc(21.6% - 0.125em);
          &:first-child:not(:last-child) {
            span {
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  .pane-cola-panels-local-footer,
  .pane-cola-panels-local-footer > .cb-layout__component-dynamic {
    @include media(bp(m)) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .pane-cola-panels-local-footer > .cb-layout__component-dynamic {
    flex: 1 0 auto;
    max-width: 100%;
  }
}

// Affiliate Logos
.cb-local-footer {
  .logo {
    &-cumc {
      @include image-replace("#{$image-path}/logos/logo-columbia--white.svg?v=1", 211, 31, 100);
    }
    &-nyp {
      @include image-replace("#{$image-path}/logos/logo-nyp-white.svg", 246, 30, 100);
    }
    &-nypk {
      @include image-replace("#{$image-path}/logos/logo-nyp-kids-morgan-stanley.png", 246, 43, 100);
    }
    &-nyspi {
      @include image-replace("#{$image-path}/logos/logo-nyspi-white.png", 246, 45, 100);
    }
    &-columbiadoctors {
      @include image-replace("#{$image-path}/logos/logo-columbia-doctors-white.svg", 246, 31, 100);
    }
    &-cumc,
    &-nyp,
    &-nypk,
    &-nyspi,
    &-columbiadoctors,
    &-nyp-co-branded {
      margin: 0 auto;
      color: #fff;
      width: 100%;
      @include media(bp(m)) {
        margin: 0;
        display: inline-block;
      }
    }
    &-nyp {
      position: relative;
      @include media(bp(m)) {
        margin-top: 2%;
      }
    }
    &-nypk {
      // needed to prevent page side-scroll
      position: relative;
      @include media(bp(m)) {
        margin-top: 4.7%;
      }
    }
  }
}

// Co-branded affiliate logos
.cb-local-footer {
  .cb-affiliates--co-branded {
    $cuimc-logo-width: 231;
    $cuimc-logo-height: 34;
    $nyp-logo-width: 149;
    $nyp-logo-height: 31;
    $cuimc-ratio: math.div($cuimc-logo-height, $cuimc-logo-width);
    $crown-offset: $cuimc-logo-width * 0.23933;
    $nyp-overshoot: 0.11327;
    $width-difference: $cuimc-logo-width - $nyp-logo-width;
    $crown-offset-ratio: $cuimc-logo-width - $nyp-logo-width - $crown-offset;
    .pane-link {
      &:first-child {
        // Bottom margin should be the crown height, i.e. CUIMC logo height.
        margin-bottom: clamp(1px, calc((100% - max(0px, (100% - #{$cuimc-logo-width}px))) * #{$cuimc-ratio}), #{$cuimc-logo-width * $cuimc-ratio}px);
      }
      &:last-child {
        // Fallback for legacy browsers without the clamp function.
        padding-left: 26px;
        // The 12.32% value is calculated from the space between the the word
        // "Columbia" and the Crown, and the difference betwteen the width of the
        // nyp and columbia logos. which results in ~6% but we have to double
        // it to account for the fact that the logos are centered.
        padding-left: clamp(1px, calc((100% - max(0px, (100% - #{$cuimc-logo-width}px))) * 0.1232), #{$cuimc-logo-width * 0.1232}px);
      }
      @include media(bp(m)) {
        @include quantities-all(2) {
          &:first-child {
            // In order to center the co-branded lockup we need to make the
            // first child bigger by half the difference between the logo
            // widths minus the right margin.
            width: calc(50% + #{math.div(($width-difference - $cuimc-logo-height), 2)}px);
            margin-left: 0;
            margin-right: #{math.div($cuimc-logo-height, 2)}px;
            margin-bottom: 2em;
            span {
              justify-content: flex-end;
            }
          }
          &:last-child {
            // stylelint-disable declaration-block-no-redundant-longhand-properties
            padding-left: 0;
            // In order to center the co-branded lockup we need to make the
            // second child smaller by half the difference between the logo
            // plus the left margin.
            width: calc(50% - #{math.div(($width-difference + $cuimc-logo-height), 2)}px);
            margin-left: #{math.div($cuimc-logo-height, 2)}px;
            margin-right: 0;
            // We want to vertically align the logos roughly by the type baseline.
            // Since the nyp logo is shorter we need to push it down by the
            // ratio of its overshoot (~11.3269%) plus the difference in
            // logo heights.
            margin-top: #{ceil($nyp-logo-height * $nyp-overshoot)}px;
            margin-bottom: calc(2em - #{ceil($nyp-logo-height * $nyp-overshoot)}px);
            // stylelint-enable declaration-block-no-redundant-longhand-properties
            span {
              justify-content: flex-start;
            }
          }
        }
        @include media(bp(xl)) {
          width: 50%;
        }
      }
    }
    .logo {
      &-cumc {
        @include image-replace("#{$image-path}/logos/logo-columbia--white.svg?v=1", $cuimc-logo-width, $cuimc-logo-height, 100);
      }
      &-nyp-co-branded {
        @include image-replace("#{$image-path}/logos/logo-nyp-co-brand.svg?v=1", $nyp-logo-width, $nyp-logo-height, 100);
      }
      &-cumc,
      &-nyp-co-branded {
        margin: 0 auto;
        color: #fff;
        width: 100%;
        @include media(bp(m)) {
          margin: 0;
          display: inline-block;
        }
      }
    }
  }
}

.pane-cola-panels-local-footer {
  .col-first,
  .col-second,
  .col-third,
  .column-first,
  .column-second,
  .column-third {
    width: 100%;
    margin: 0;
    float: none;
  }
  .col-first,
  .column-first {
    padding: 2em 0;
    border-bottom: 2px solid rgba(color(secondary, hi), 0.4);
    @include media(bp(m)) {
      @include span-columns(6) {
        display: flex;
        width: #{48.31933% + math.div(3.36134%, 2)};
        margin-right: math.div(3.36134%, 2);
        padding-right: math.div(3.36134%, 2);
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
        border-right: 2px solid rgba(color(secondary, hi), 0.4);
        text-align: center;
      }
    }
    @include media(bp(xl)) {
      @include span-columns(4) {
        padding-right: 0;
        display: flex;
      }
    }
  }
  .col-second,
  .column-second {
    border-bottom: 2px solid rgba(color(secondary, hi), 0.4);
    @include media(bp(m)) {
      @include span-columns(6) {
        margin-right: 0;
        border-bottom: none;
      }
    }
    @include media(min-width bp(m) max-width (bp(xl) - 0.06em) ) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include media(bp(xl)) {
      @include span-columns(4);
    }
  }
  .col-second,
  .col-third,
  .column-second,
  .column-third {
    padding: 2em 0;
    @include media(bp(m)) {
      padding: 1em 0;
    }
  }
  .col-third,
  .column-third {
    padding-bottom: 0;
    @include media(bp(m)) {
      width: 100%;
      clear: both;
      padding-top: 2.625em;
      border-top: 2px solid rgba(color(secondary, hi), 0.4);
    }
    @include media(bp(xl)) {
      @include span-columns(4) {
        padding-top: 1em;
        border-top: 0;
        padding-bottom: 1em;
        clear: none;
        border-left: 2px solid rgba(color(secondary, hi), 0.4);
      }
    }
  }
  .col-first,
  .col-second,
  .column-first,
  .column-second {
    @include media(bp(m)) {
      margin-bottom: 1.75em;
    }
    @include media(bp(xl)) {
      margin-bottom: 0;
    }
  }
  .column--last {
    @include omega();
  }
  .pane-menu {
    &-menu-quick-links,
    &-menu-quick-links-secondary {
      text-align: center;
      @include media(bp(m)) {
        margin-left: 1.5em;
        padding: 1em 0;
        float: left;
        text-align: left;
      }
      @include media(min-width bp(m) max-width (bp(xl) - 0.06em) ) {
        text-align: center;
        margin: 0 auto;
        padding-right: 1em;
        padding-left: 1em;
        float: none;
        display: inline-block;
      }
      @include media(bp(xl)) {
        width: 50%;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 0;
        &:only-child {
          width: 100%;
          padding-right: 0;
          padding-left: 0;
          margin-left: 1.5em;
        }
        + .panel-pane {
          border-left: 2px solid rgba(color(secondary, hi), 0.4);
        }
      }
      + .panel-pane {
        li:first-child {
          border-top: 1px dashed rgba(color(secondary, hi), 0.4);
          @include media(bp(m)) {
            border-top: none;
          }
        }
      }
      li {
        line-height: 1.2;
        border-bottom: 1px dashed rgba(color(secondary, hi), 0.4);
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        @include media(bp(m)) {
          border-bottom: none;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        + li {
          @include media(bp(m)) {
            margin-top: $spacing--m;
          }
        }
      }
      a {
        color: #fff;
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.2;
        display: block;
        padding: 0.75em 0;
        @include word-wrap();
        @include media(bp(m)) {
          display: inline;
          padding: 0;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .cb-menu-item--featured a {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1rem;
        padding: 0.5em 0.66em 0.45em 0.66em;
        border: 1px solid #fff;
        border-radius: $border-radius--default;
        display: inline-block;
        margin: 0.75em 0;
        @include media(bp(m)) {
          margin: 0;
        }
        &:hover, &:focus {
          text-decoration: none;
          background-color: #fff;
          color: color(primary, xlo);
        }
      }
    }
  }
}

// Styles for optional header and footer in the component layout.
.pane-cola-panels-local-footer {
  .cb-layout__component-dynamic {
    .feature,
    .footer {
      width: 100%;
      font-family: $font--text--alt;
      color: #fff;
    }
    .feature {
      padding-top: 1.75em;
      padding-bottom: 1.75em;
      border-bottom: 2px solid rgba(color(secondary, hi), 0.4);
      @include media(bp(m)) {
        padding-top: 0;
        margin-bottom: 1.75em;
        padding-bottom: 1.75em;
      }
    }
    .footer {
      margin-top: 1.75em;
      padding-top: 1em;
      border-top: 2px solid rgba(color(secondary, hi), 0.4);
    }
    .cb-text {
      &.cb-local-footer__disclaimer,
      &.center {
        text-align: center;
      }
      &__title,
      &__description p {
        font-family: $font--text--alt;
        color: #fff;
      }
      &__title {
        font-size: 1.125em;
        margin-bottom: 0.25em;
        @include media(bp(l)) {
          font-size: 1.25em;
        }
      }
      &__description p {
        font-size: 1rem;
      }
      &.cb-local-footer__disclaimer {
        .cb-text {
          &__content {
            max-width: 40em;
            margin: 0 auto;
          }
          &__title {
            font-size: 1em;
            margin-bottom: 0.25em;
            @include media(bp(l)) {
              font-size: 1em;
            }
          }
          &__description p {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
