.cb-news-browse-topics--compact {
  .no-js & {
    margin-bottom: 2em;
  }
  button {
    span {
      &.show-hide__description {
        // Search icon
        @include icon($icon: "newspaper", $pseudo: "before", $size: 1em);
      }
    }
  }
  .cb-panel-container {
    width: 100%;
    background: color(primary, brand);
    .js & {
      @include visually-hidden();
      &.show-hide {
        @include visually-hidden--off();
        overflow: visible;
      }
    }
    .pane-menu-menu-cola-news-categories {
      position: relative;
    }
    // Topics listing
    ul.menu {
      @include media(bp(bs)) {
        column-count: 2;
      }
      @include media(bp(xl)) {
        column-count: 3;
      }
      li {
        margin-bottom: 1em;
        line-height: 1.4;
        display: inline-block;
        width: 100%;
        @include media(bp(m)) {
          margin: 0 0.5em 0.7em 0.5em;
        }
        a {
          color: #fff;
          font-weight: 600;
          &:focus {
            outline-color: #fff;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    // View All link
    .pane-link-to-list {
      text-align: center;
      margin: 1em 0 0 0;
      border-top: 1px solid #fff;
      padding-top: 1em;
      position: static;
      @include media(bp(m)) {
        margin: 1em 0.5em 0 0.5em;
      }
      a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        &:focus {
          outline-color: #fff;
        }
        @include icon($icon: "caret-right", $pseudo: "after", $size: 0.8em) {
          margin-top: -0.1em;
          margin-left: 0.5em;
        }
        &:hover::after {
          @include animated($duration: 1s, $name: wobble);
        }
      }
    }
  }
  // Arrow indicator
  .cb-indicator {
    @include media(bp(m)) {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      top: -59px;
      left: calc(50% - 15px);
      border-width: 15px;
      border-bottom: 12.5px solid color(primary, brand);
    }
    .cb-list-news--title-buttons & {
      @include media(bp(xl)) {
        right: 19px;
        left: auto;
      }
    }
    .no-js & {
      display: none;
    }
  }
}

// If both Browse Topics and News Simple Search is displayed
.cb-list-news--with-topics.cb-list-news--with-search {
  &:not(.panels-ipe-editing) {
    .cb-news-browse-topics--compact {
      + .cb-news-simple-search {
        @include media(bp(m)) {
          margin-top: 0;
        }
        @include media(bp(xl)) {
          .no-js & {
            margin: 2em 0;
          }
        }
        .no-js & {
          margin: 2em 0;
        }
      }
    }
    &.cb-list-news--title-buttons {
      .cb-news-browse-topics--compact {
        button {
          @include media(bp(xl)) {
            right: 14.5em;
            position: absolute;
            width: auto;
          }
        }
      }
    }
  }
}

// Fix browse topics button alignment styles when no sidebar exists
.cb-list-news--with-topics.cb-list-news--with-search:not(.cb-list-news--topics) {
  &:not(.panels-ipe-editing) {
    .aside-first-empty.aside-last-empty {
      .cb-news-browse-topics--compact {
        button {
          @include media(bp(xl)) {
            right: 15em;
          }
        }
      }
    }
  }
}
