// The curator grid uses 12px margins on each card. In order to make the feed
// appear to be flush with the other page elements we need to widen the
// container and add some negative spacing.
.crt-widget-waterfall .cb-curator__feed-container {
  width: calc(100% + 24px);
  margin-left: -12px;
}

// Typography
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region.cb-padding {
    &.cb-background--2,
    &.cb-background--3 {
      .cb-curator__feed,
      .crt-load-more {
        border-color: rgba(255, 255, 255, 0.25);
      }
      .cb-curator__more-button {
        @include btn($size: s, $dark: true);
      }
    }
  }
}

.cb-curator__description + .cb-curator__feed {
  margin-top: $spacing--l;
}

.cb-curator__post {
  .crt-social-icon i::before {
    color: color(neutral-brand);
  }
  .crt-post-content {
    // Ensure the overlay link is constrained to the content area.
    position: relative;
  }
  .crt-post-content-text {
    font-family: $font--text--alt;
    font-size: 0.9rem;
    color: color(neutral-brand, lo);
    a {
      @include word-wrap();
      hyphens: none;
      color: color(neutral-brand, xlo);
      z-index: #{z('contentBottom') + 1};
      position: relative;
      text-decoration: none;
      font-weight: 600;
      &:hover,
      &:focus,
      &:active {
        color: color(action);
      }
    }
    .cb-curator__overlay-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: #{z('contentBottom')};
      &:focus,
      &:active {
        border: 2px dotted color(action);
        outline: none;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.02);
      }
    }
  }
  .crt-post-footer {
    font-family: $font--text--alt;
    color: color(neutral-brand);
    .crt-post-share {
      right: 0;
    }
  }
  .crt-post-footer .crt-post-share a,
  .crt-date {
    opacity: 1;
    color: color(neutral-brand);
  }
  .crt-post-footer .crt-post-share a {
    width: 40px;
    float: right;
    text-align: center;
    &:hover,
    &:focus,
    &:active {
      color: color(action);
    }
    i::before {
      margin: 0;
    }
  }
}

.crt-post-footer .crt-post-share a,
.crt-post-footer .cb-curator__feed-link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.cb-curator__feed-link {
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  img {
    position: static;
  }
  // Curator io seems to prevent the link from executing when the profile image
  // is clicked. This pseudo element will cover the profile image to force
  // the issue.
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: #{z('contentBottom')};
  }
}

// Large border top.
.cb-curator__feed {
  padding-top: $spacing--l;
  border-top: $border--l;
}

// Medium border bottom and load more button.
.crt-load-more {
  margin-top: $spacing--l;
  padding-top: $spacing--m;
  border-top: $border--m;
  .cb-curator__more-button {
    @include btn($size: s);
  }
}

.cb-curator__feed,
.crt-load-more {
  border-color: rgba(0, 0, 0, 0.1);
}

// Sidebar styles
.cb-content-area__aside-last {
  .cb-curator__feed {
    padding: 1rem 0 0 0;
  }
}

// Update twitter branding
.crt-icon-twitter {
  &::before {
    content: "\1d54f";
  }
}

.crt-post-v2 .crt-post-footer .crt-post-share a i.crt-icon-twitter {
  font-size: 1.4em;
}
