// Shared styles between News and Announcements listings
.cb-list-news--standard,
.cb-list-news--standard.cb-list-news--announcements {
  .cb-content-area__main {
    .panel-separator {
      display: none;
    }
  }
}

.cb-list-news {
  &__external-source {
    color: color(neutral-brand, xxlo);
    margin-top: 0.25em;
    font-family: $font--display;
    line-height: 1.1;
    .pane-title,
    .field-name-field-news-external-source {
      font-size: 0.9rem;
      font-weight: 400;
      display: inline;
    }
    .pane-title {
      font-weight: 700;
      color: color(neutral-brand, xxlo);
      margin-bottom: 0;
    }
    .field-name-field-news-external-source {
      font-weight: 400;
      color: color(neutral-brand, xxlo);
    }
  }
}

.cb-list-news--standard {
  .cb-list-news {
    &--regular-stories,
    &--announcements,
    &__news {
      .cb-list-news {
        &__best-category {
          display: block;
          position: absolute;
          top: -0.5rem;
          left: 0;
          line-height: 1rem;
          font-size: 0.75rem;
          padding-right: 0.75rem;
          background-color: #fff;
          text-transform: uppercase;
          font-weight: 400;
          color: color(neutral-brand, m);
          @include media(bp(m)) {
            font-size: 0.88rem;
          }
          .pane-title {
            @include visually-hidden();
          }
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &__byline,
        &__date {
          font-size: 0.875rem;
          color: color(neutral-brand, m);
          display: inline-block;
          margin-top: 0.125em;
        }
      }
    }
    &--regular-stories,
    &--announcements,
    &__news {
      ul {
        &:not(.pager) {
          li {
            position: relative;
            padding-top: 1rem;
            @include media(bp(m)) {
              padding-top: 1.8rem;
            }
          }
        }
      }
      h2 {
        margin-bottom: 1.4em;
      }
      ul:not(.pager) {
        li {
          border-top: $border--s;
        }
      }
      .cb-list-news {
        &__date,
        &__byline {
          display: block;
          @include media(bp(m)) {
            display: inline;
          }
        }
        &__date {
          .field-name-field-news-date {
            @include media(bp(m)) {
              display: inline;
            }
          }
          .date-display-single {
            @include media(bp(m)) {
              margin-top: 0;
            }
          }
        }
      }
      .cb-list-news__byline {
        + .cb-list-news__date {
          @include media(bp(m)) {
            &::before {
              content: "/";
            }
          }
        }
      }
    }
    &--regular-stories,
    &--announcements {
      ul:not(.pager) {
        li {
          margin: 0 0 2.4rem 0;
          @include media(bp(m)) {
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}

// News listing styles only
.cb-list-news--standard {
  .cb-list-news {
    &--regular-stories,
    &__news {
      .columns-2 {
        .cb-list-news {
          // Setting pseudo and image element to be same size to wrap text.
          // Pseudo wraps text, image is placed over it using absolute positioning.
          // This also restricts the image content area to a 16:9 ratio.
          &__image-teaser,
          &__title::before {
            width: calc((100vw - 2 * #{$page-margins}) * 0.357);
            height: calc((100vw - 2 * #{$page-margins}) * 0.2);
            @include media(bp(m)) {
              width: calc((100vw - 2 * #{$page-margins}) * 0.3434);
              height: calc((100vw - 2 * #{$page-margins}) * 0.1931);
            }
            @include media(bp(l)) {
              width: 210px;
              height: 118.125px;
            }
            @include media(bp(xl)) {
              width: 260px;
              height: 143px;
            }
          }
          // Add spacing on small mobile to prevent
          // long news topic (two lines) overlap with title and img
          &__title,
          &__title::before,
          &__image-teaser {
            margin-top: 0.7rem;
            @include media(bp(bs)) {
              margin-top: 0;
            }
          }
          &__image-teaser {
            img {
              // Needed for lazyload auto size calculation.
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// Announcements listing styles only
.cb-list-news--standard {
  .cb-list-news--announcements {
    margin-top: $spacing--l;
    .cb-list-news {
      &__content {
        border-top: $border--l;
        padding-top: $spacing--xxl;
        .view-content {
          border-bottom: $border--m;
        }
      }
    }
    ul {
      &.pager {
        padding-top: $spacing--xxl;
      }
      &:not(.pager) {
        .cb-layout__component-dynamic {
          // fix hidden focus outlines
          overflow: inherit;
        }
      }
    }
    .columns-2 {
      .cb-list-news {
        &__image-teaser,
        &__title::before {
          width: calc((100vw - 2 * #{$page-margins}) * 0.357);
          height: calc((100vw - 2 * #{$page-margins}) * 0.2);
          @include media(bp(bs)) {
            width: 140px;
            height: 78.75px;
          }
        }
        &__image-teaser {
          img {
            // Needed for lazyload auto size calculation.
            width: 100%;
          }
        }
      }
    }
  }
}
