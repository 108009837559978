// CUPS simple search typography styles

// Standardized component default font sizes
.region,
.cb-page-content .cb-content-area__main {
  .pane-cola-search-faculty-box-simple,
  .pane-cola-search-providers-box-simple,
  .panels-ipe-cola-search-faculty-box-simple,
  .panels-ipe-cola-search-providers-box-simple {
    h2 {
      @include component-heading(s);
      @include media(bp(bs)) {
        @include component-heading(m);
      }
      @include media(bp(m)) {
        @include component-heading(xl);
      }
    }
  }
}

// Featured Layout with sidebars:
// Decrease font size due to smaller page content width
.cb-flavor:not(.cb-list-news):not(.cb-flavor--subsection-front) {
  .cb-layout--dynamic-features-asides-feature-rows {
    &.cb-layout--aside-first,
    &.cb-layout--aside-last,
    &.cb-layout--aside-first-last {
      .region,
      .cb-page-content .cb-content-area__main {
        .pane-cola-search-faculty-box-simple,
        .pane-cola-search-providers-box-simple,
        .panels-ipe-cola-search-faculty-box-simple,
        .panels-ipe-cola-search-providers-box-simple {
          h2 {
            @include media(bp(l)) {
              @include component-heading(l);
            }
          }
        }
      }
    }
  }
}

// Main Content Only
.cb-layout--aside-first-last {
  .cb-content-area__main {
    .pane-cola-search-faculty-box-simple,
    .pane-cola-search-providers-box-simple,
    .panels-ipe-cola-search-faculty-box-simple,
    .panels-ipe-cola-search-providers-box-simple {
      @include media(bp(l)) {
        h2 {
          @include component-heading(l);
        }
      }
    }
  }
}

// Component specific typography Styles
.pane-cola-search-faculty-box-simple,
.pane-cola-search-providers-box-simple {
  text-align: center;
  h2.pane-title {
    text-align: center;
  }
  .views-exposed-widget {
    .form-text {
      height: 44px;
      width: 100%;
      font-size: 1em;
      line-height: 1em;
      @include media(bp(s)) {
        height: 44px;
        padding: 12px;
        line-height: 20px;
        font-size: 1.2em;
      }
      &::placeholder {
        text-align: center;
      }
    }
    .form-submit {
      @include btn($size: s);
    }
  }
  .more-link {
    text-align: center;
    font-size: 1.125rem;
    margin-top: 1rem;
    font-weight: 700;
  }
}

.pane-cola-search-faculty-box-simple {
  .more-link {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
