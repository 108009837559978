// Shared Related Profiles Base Styles:
// Clinic, Conditions & Treatments, CUPS location,
// News article, Testimonial, Unit

.node-type-cups-location .pane-cola-search-location-providers,
.cb-list-profiles--by-unit,
.cb-list-profiles--by-clinic,
.cb-list-profiles--related-profiles {
  &:not(:first-child) {
    margin-top: 3em;
  }
}

.cb-list-profiles--related-profiles,
.cb-list-profiles--by-unit,
.cb-list-profiles--by-clinic {
  .cb-list-profiles {
    &__title {
      order: 2;
      float: right;
      padding-bottom: 3px;
      display: inline-block;
      @include media(bp(s)) {
        width: calc(100% - 60px - 2rem);
      }
      h3 {
        line-height: 1.2;
        font-size: 1rem;
        a {
          font-weight: 600;
          color: a11yrize(color(action, m), $background-solid-xlight);
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__headshot {
      .field-name-field-cups-headshot {
        order: 1;
        margin-right: 1rem;
        width: 50px;
        height: 72px;
        overflow: hidden;
        border: 1px solid $border-xlight;
        @include media(bp(m)) {
          width: 60px;
          height: 88px;
        }
        img {
          // Needed for lazyload auto size calculation.
          width: 100%;
        }
      }
    }
  }
}

// Profiles list markup differs on cups location page
.node-type-cups-location .pane-cola-search-location-providers {
  .view-content {
    li {
      // title
      a {
        order: 2;
        width: calc(100% - 60px - 2rem);
        float: right;
        padding-bottom: 3px;
        display: inline-block;
        line-height: 1.2;
        font-size: 1rem;
        font-weight: 600;
        color: color(action, lo);
        &:hover {
          text-decoration: underline;
        }
      }
      // headshot
      img {
        order: 1;
        margin-right: 1rem;
        width: 50px;
        height: 72px;
        overflow: hidden;
        border: 1px solid $border-xlight;
        @include media(bp(m)) {
          width: 60px;
          height: 88px;
        }
      }
    }
  }
}
