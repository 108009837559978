// Styles for pullquotes implemented with ckeditor.
// stylelint-disable selector-class-pattern
.ck-pullquote,
.cke_editable .ck-pullquote {
  display: block;
  font-family: $font--text--alt;
  font-size: 1.25rem;
  color: color(primary, xlo);
  border: 0.5em solid var(--site-accent);
  border-width: 0.25em 0 0.5em 0;
  padding: 1em 0;
  margin: 1.5em 0 2em 0;
  text-align: center;
  clear: both;
  @include media(bp(m)) {
    width: 45%;
    max-width: 460px;
    margin: 1rem 1.5rem 1rem 0;
    float: left;
    padding: 0.5em 0.75em 0.5em 1em;
    border-width: 0 0.25em 0 0.5em;
    font-size: 1.33rem;
    text-align: left;
    &:last-child:not(.ck-pullquote--center) {
      margin-bottom: 1em;
    }
    &--right {
      float: right;
      margin: 1em 0 1em 1.5em;
      padding: 0.5em 1em 0.5em 0.75em;
      border-width: 0 0.5em 0 0.25em;
    }
    &--center {
      float: none;
      text-align: center;
      margin: 1.5em auto 2em auto;
      padding: 1em 0;
      border-width: 0.5em 0 0.25em 0;
      width: 54%;
    }
  }
  &:last-child {
    margin-bottom: 2em;
  }
  &::before {
    content: attr(data-pullquote);
  }
}
// stylelint-enable selector-class-pattern
