.cb-list-conditions-treatments__results-filter {
  padding: 0 0 $spacing--s 0;
  clear: both;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include media(bp(l)) {
    padding-bottom: $spacing--m;
  }
  .pane-title {
    font-size: 1rem;
    flex: 0 1 auto;
    margin-right: 1em;
    margin-bottom: 0;
    width: 100%;
    @include media(bp(s)) {
      width: auto;
    }
  }
  .item-list {
    flex: 1 0 auto;
    max-width: 100%;
    ul {
      li {
        // override base styles
        margin: 0;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    width: 100%;
  }
  li {
    text-align: center;
    border: 1px solid color(action, m);
    @include media(bp(bs)) {
      flex: 1 0 auto;
    }
    &:first-child,
    &:first-child a {
      border-radius: 4px 0 0 4px;
    }
    &:last-child,
    &:last-child a {
      border-radius: 0 4px 4px 0;
    }
    &:not(:last-child) {
      border-right: none;
    }
    &.active {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      color: a11yrize(color(action, m), lighten(color(secondary, xhi), 16%));
      background: lighten(color(secondary, xhi), 16%);
      a {
        color: a11yrize(color(action, m), lighten(color(secondary, xhi), 16%));
        font-weight: 600;
      }
      &:not(:first-child) a:hover {
        text-decoration: line-through;
      }
    }
  }
  a,
  .facetapi-zero-results {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
    &:hover,
    &:focus {
      color: a11yrize(color(action, m), lighten(color(secondary, xhi), 23%));
      background: lighten(color(secondary, xhi), 23%);
    }
    &:focus {
      outline: 2px dotted color(neutral-brand, lo);
    }
  }
  a {
    &.facetapi-active {
      width: 100%;
    }
    &.facetapi-inactive {
      width: 100%;
      @include media(bp(s)) {
        display: inline-block;
      }
    }
  }
}
