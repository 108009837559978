// Base CTA styles

.pane-bundle-cta {
  text-align: center;
  position: relative;
  .field-name-field-cta-description,
  .field-name-field-cta-link {
    a {
      z-index: z(contentTop);
      // Needed for the z-index above.
      position: relative;
    }
  }
  .field-name-field-cta-link {
    a {
      // Preserve focus outlines.
      margin: 1px;
    }
  }
  .field-name-field-cta-title,
  .field-name-field-title {
    h2 {
      color: $cta-title-color;
      word-break: break-word;
      // To prevent long titles from being cut off
      max-width: 100%;
    }
  }
  .field-name-field-cta-image {
    max-width: 79vw;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: z(contentBottom);
    img {
      display: block;
    }
  }
  .field-name-field-cta-description {
    padding-bottom: $spacing--s;
    > * {
      width: 100%;
      * {
        display: inline;
      }
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    p,
    a {
      font-family: $font--text--alt;
      font-size: 1rem;
    }
  }
  .cw-cta__overlay-link {
    z-index: z(content);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-indent: -999em;
    // Adding a bg color makes it work in IE9/10
    background-color: rgba(255, 255, 255, 0);
  }
  .content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    .ext, .mailto, a {
      display: inline-block;
    }
  }
  .fieldable-panels-pane,
  .paragraphs-items,
  .field-name-field-cta-paragraphs,
  .paragraphs-item-cta,
  .content,
  .field-name-field-cta-description,
  .field-name-field-description {
    .cb-content-area__aside-last & {
      display: block;
      overflow: visible;
    }
  }
  .field-name-field-title,
  .field-name-field-cta-image,
  .field-name-field-image,
  .field-name-field-cta-link,
  .field-name-field-link,
  img {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    width: 100%;
    .cb-content-area__aside-last & {
      display: block;
      overflow: visible;
    }
  }
}

.paragraphs-item-cta {
  &:not(.cw-cta-phone) {
    .field-name-field-cta-link {
      align-items: center;
      a {
        width: auto;
        display: inline-block;
      }
    }
  }
}

.cb-content-area__main,
.cb-content-area__aside-last {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    max-width: 100%;
  }
}

// CTA Hover Transitions
.region {
  .pane-bundle-cta,
  .panels-ipe-bundle-cta {
    .pane &,
    &.pane {
      @include media(bp(l)) {
        &:hover {
          .content {
            display: flex;
          }
          .paragraphs-item-cta {
            &::before {
              background: rgba(45, 46, 49, 0.15);
            }
          }
          img {
            overflow: hidden;
            transform: scale(1.1);
          }
          .field-name-field-cta-image {
            overflow: hidden;
            box-shadow: none;
          }
        }
      }
    }
  }
}
