// CUPS Simple Search (Provider, Faculty) Background Styles

// Default
.pane-cola-search-faculty-box-simple,
.pane-cola-search-providers-box-simple,
.panels-ipe-cola-search-providers-box-simple,
.panels-ipe-cola-search-faculty-box-simple {
  background: $component-bg-auto-color;
  border: $component-border-style;
}

@include component-region-bg-styles(1,2,3) {
  .pane-cola-search-faculty-box-simple,
  .pane-cola-search-providers-box-simple,
  .panels-ipe-cola-search-providers-box-simple,
  .panels-ipe-cola-search-faculty-box-simple {
    background: $component-bg-auto-color;
    border: none;
  }
}
