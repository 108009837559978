// Yext Search Iframe
// Base styles
.cb-yext-search {
  &__form {
    width: calc(100% + 1rem);
    margin-left: -0.5rem;
  }
  &__footer {
    a[href^="tel:"] {
      white-space: nowrap;
    }
    p, a {
      font-size: 0.75rem;
      font-family: $font--text--alt;
    }
  }
  &__co-branding {
    display: block;
    margin-top: 1.5rem;
    position: relative;
    z-index: -1;
    @include media(bp(l)) {
      margin-top: 0.75rem;
    }
    img {
      max-width: 185px;
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      @include media(bp(s)) {
        max-width: 415px;
      }
    }
  }
}

// On basic pages in the main area.
.cb-content-area__main {
  .cb-yext-search {
    &__form {
      height: 46rem;
      @include media(18.75rem) {
        height: 43rem;
      }
      @include media(30rem) {
        height: 41.5rem;
      }
      @include media(49.5rem) {
        height: 38.5rem;
      }
      @include media(60rem) {
        height: 41.5rem;
      }
      @include media(74.1875rem) {
        height: 38.5rem;
      }
      .cb-layout--aside-first-last & {
        @include media(bp(xl)) {
          height: 41.5rem;
        }
      }
    }
  }
}

// On home pages.
.cb-layout--feature-rows {
  .region,
  .region.cb-overlap--top,
  .region.cb-overlap--top-and-bottom {
    .cps-region-inner > .cb-yext-search {
      z-index: #{z(contentTop) + 1};
    }
  }
  .cb-yext-search {
    &__title {
      margin-bottom: 0.25rem;
      margin-right: 1rem;
      display: inline-block;
      width: 100%;
      text-align: center;
      @include h3();
      @include icon(doctors) {
        font-size: 1.5em;
        margin-right: 0.25em;
        margin-top: -0.1em;
      }
      @include media(bp(s)) {
        text-align: left;
      }
    }
    &__description {
      display: inline-block;
      p,
      a {
        font-size: 0.9rem;
        font-family: $font--text--alt;
      }
    }
    &__footer {
      @include media(63.5625rem) {
        position: relative;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        p {
          flex-basis: 11.25rem;
          max-width: 11.25rem;
        }
      }
    }
    &__form {
      height: 50rem;
      @include media(18.125rem) { // 290px
        height: 34rem;
      }
      @include media(30rem) { // 480px
        height: 33rem;
      }
      @include media(49.5625rem) { // 793px
        height: 30rem;
      }
      @include media(63.5625rem) { // 1017px
        height: 28.5rem;
        margin-bottom: -6.5rem;
      }
      @include media(71.5625rem) { // 1145px
        height: 18rem;
        margin-bottom: -5.5rem;
      }
    }
  }
  .cb-padding--none {
    .cb-yext-search {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    &.cb-overlap--top,
    &.cb-overlap--top-and-bottom {
      .cb-yext-search:first-child {
        @include media(bp(xxl)) {
          padding-top: 0.5rem;
        }
      }
    }
    &.cb-overlap--bottom,
    &.cb-overlap--top-and-bottom {
      .cb-yext-search:last-child {
        @include media(bp(xxl)) {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
  .cb-padding--auto {
    .cb-yext-search {
      @include media(84rem) {
        flex: 1 0 82rem;
        width: 82rem;
        min-width: 82rem;
        margin: 0;
        margin-left: -3.5rem;
        background: transparent;
        padding: 1rem;
      }
      &:first-child {
        margin-top: -0.5rem;
        @include media(bp(l)) {
          margin-top: -1.75rem;
        }
        @include media(bp(xl)) {
          margin-top: 0;
        }
      }
      &:last-child {
        margin-bottom: -0.5rem;
        @include media(bp(l)) {
          margin-bottom: -1.75rem;
        }
        @include media(bp(xl)) {
          margin-bottom: 0;
        }
      }
    }
    &.cb-overlap--none {
      .cb-yext-search {
        @include media(84rem) {
          padding-top: 0;
          padding-bottom: 0;
        }
        @include media(bp(xl)) {
          margin-top: -1rem;
          margin-bottom: -1rem;
          .panels-ipe-editing & {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .cb-overlap--top-and-bottom.cb-padding--auto,
  .cb-overlap--top.cb-padding--auto {
    .cb-yext-search {
      @include media(84rem) {
        &:first-child {
          margin-top: -1.5vw;
        }
      }
    }
  }
  .cb-overlap--top-and-bottom.cb-padding--auto,
  .cb-overlap--bottom.cb-padding--auto {
    .cb-yext-search {
      @include media(84rem) {
        &:last-child {
          margin-bottom: -1.6vw;
        }
      }
    }
  }
  .cb-background--auto {
    .cb-yext-search {
      background-color: #fff;
    }
  }
  .cb-background--1 {
    .cb-yext-search {
      @include region-bg(1);
      &__description,
      &__footer {
        a {
          color: a11yrize(color(action, m), $region-bg-1-color);
        }
      }
    }
  }
  .cb-background--2 {
    .cb-yext-search {
      @include region-bg(2);
    }
  }
  .cb-background--3 {
    .cb-yext-search {
      @include region-bg(3);
    }
  }
  .cb-background--2,
  .cb-background--3 {
    .cb-yext-search {
      color: #fff;
      &__title,
      &__description a,
      &__footer a {
        color: #fff;
      }
    }
  }
}
