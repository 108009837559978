// Mega menu Layout
.cb-megamenu {
  // Base layout.
  .cb-megamenu__panel-group--description {
    @include media(bp(xl)) {
      width: 50%;
    }
  }
  &__panel-group {
    @include media(bp(xl)) {
      width: 25%;
      float: left;
      .cb-megamenu__panel-group-links li {
        float: none;
      }
    }
  }
  // Panel group with more than 10 links.
  &__panel-group--l,
  &__panel-group--xl {
    @include media(bp(xl)) {
      li:nth-child(n+11),
      li:nth-child(10):not(:last-child) {
        display: none;
      }
      .cb-megamenu__more-link-item:nth-child(n+11),
      .cb-megamenu__more-link-item:nth-child(10):not(:last-child) {
        display: inline-block;
      }
    }
    .cb-megamenu__more-link-item {
      display: none;
    }
  }
  // Primary navigation list item.
  &__item {
    // Two link groups, one with 10 or less links, the other with 11 - 20 links.
    &--columns-2.cb-megamenu__item--with-large-panel:not(.cb-megamenu__item--with-multi-large):not(.cb-megamenu__item--with-extra-large) {
      @include media(bp(xl)) {
        &.cb-megamenu__item--offset-2 {
          .cb-megamenu__panel {
            left: auto;
          }
        }
        .cb-megamenu__panel {
          width: 85%;
        }
        .cb-megamenu__panel-group:not(.cb-megamenu__panel-group--l) {
          width: 40%;
        }
        .cb-megamenu__panel-group--l {
          width: 60%;
        }
      }
    }
    // Two link groups with 11+ links.
    &--columns-2.cb-megamenu__item--with-multi-large {
      .cb-megamenu__panel-group--l {
        @include media(bp(xl)) {
          width: 50%;
        }
      }
    }
    // Two link groups with 11+ links,
    // One link group with 11-20 links,
    // Two link groups, one with 11-20 links, the other with 10 or less links.
    &--columns-2.cb-megamenu__item--with-multi-large,
    &--columns-1.cb-megamenu__item--with-large-panel:not(.cb-megamenu__item--with-extra-large),
    &--columns-2.cb-megamenu__item--with-large-panel:not(.cb-megamenu__item--with-multi-large):not(.cb-megamenu__item--with-extra-large) {
      .cb-megamenu__panel-group--l {
        .cb-megamenu__panel-group-links {
          @include media(bp(xl)) {
            column-count: 2;
            li {
              display: inline-block;
              break-inside: avoid;
              margin: 1px 0;
              &:nth-child(n+11) {
                display: inline-block;
              }
              &:nth-child(n+20):not(.cb-megamenu__more-link-item) {
                display: none;
              }
              &:nth-child(20):nth-last-child(2) {
                display: inline-block;
              }
              &.cb-megamenu__more-link-item {
                display: none;
                &:nth-child(n+22) {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
    // Two link groups, one with 21+ links, the other with 10 or less links.
    &--columns-2.cb-megamenu__item--with-extra-large:not(.cb-megamenu__item--with-multi-large) {
      .cb-megamenu__panel-group:not(.cb-megamenu__panel-group--xl) {
        @include media(bp(xl)) {
          width: 25%;
        }
      }
      .cb-megamenu__panel-group--xl {
        @include media(bp(xl)) {
          width: 75%;
          .cb-megamenu__panel-group-links {
            column-count: 3;
            li {
              display: inline-block;
              &:nth-child(n+11) {
                display: inline-block;
              }
              &:nth-child(n+30):not(.cb-megamenu__more-link-item) {
                display: none;
              }
              &:nth-child(30):nth-last-child(2) {
                display: inline-block;
              }
              &.cb-megamenu__more-link-item {
                display: none;
                &:nth-child(n+32) {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
    // One link group with 21+ links.
    &--columns-1.cb-megamenu__item--with-extra-large {
      .cb-megamenu__panel-group--xl {
        @include media(bp(xl)) {
          width: 100%;
          .cb-megamenu__panel-group-links {
            column-count: 4;
            li {
              display: inline-block;
              &:nth-child(n+11) {
                display: inline-block;
              }
              &:nth-child(n+40):not(.cb-megamenu__more-link-item) {
                display: none;
              }
              &:nth-child(40):nth-last-child(2) {
                display: inline-block;
              }
              &.cb-megamenu__more-link-item {
                display: none;
                &:nth-child(n+42) {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
    // Two links groups and a description panel.
    &--columns-3 {
      .cb-megamenu__panel-group {
        @include media(bp(xl)) {
          width: 33.33333%;
        }
      }
    }
    // Positioning for mega menu panels that are less than 100% width.
    &--columns-2:not(.cb-megamenu__item--with-large-panel):not(.cb-megamenu__item--with-extra-large),
    &--columns-1:not(.cb-megamenu__item--with-extra-large) {
      position: relative;
      .cb-megamenu__panel {
        right: auto;
      }
    }
    // Alignment for panels when the primary link is further to the right.
    // Aligns panels to the right edge of the primary link instead of left.
    &--columns-1.cb-megamenu__item--offset-4:not(.cb-megamenu__item--with-extra-large),
    &--columns-1.cb-megamenu__item--offset-2.cb-megamenu__item--with-large-panel,
    &--columns-2.cb-megamenu__item--offset-2:not(.cb-megamenu__item--with-large-panel):not(.cb-megamenu__item--with-extra-large)  {
      .cb-megamenu__panel {
        right: 0;
        left: auto;
        .cb-flavor--compact-mega-menu & {
          right: -0.5vw;
          top: calc(100% + 2rem + 5px);
          @include media(bp(xxl)) {
            right: -0.6rem;
          }
          @include media(bp(xl)) {
            right: -0.625vw;
          }
        }
      }
    }
    &--columns-1.cb-menu-item--featured:not(.cb-megamenu__item--with-extra-large),
    &--columns-1.cb-menu-item--featured.cb-megamenu__item--with-large-panel,
    &--columns-2.cb-menu-item--featured:not(.cb-megamenu__item--with-large-panel):not(.cb-megamenu__item--with-extra-large) {
      .cb-flavor--mega-menu & {
        .cb-megamenu__panel {
          right: 0;
          left: auto;
        }
      }
    }
    // Width for a panel with a single link group of 10 or less items.
    &--columns-1:not(.cb-megamenu__item--with-large-panel):not(.cb-megamenu__item--with-extra-large) {
      .cb-megamenu__panel {
        @include media(bp(xl)) {
          width: 25vw;
        }
        @include media(bp(xxl)) {
          width: #{$page-container-max-width * 0.25};
        }
        &-group {
          width: 100%
        }
      }
    }
    // Width for a panel with two link groups of 10 or less items,
    // width for a panel with a single link group 20 or less items.
    &--columns-2:not(.cb-megamenu__item--with-large-panel):not(.cb-megamenu__item--with-extra-large),
    &--columns-1.cb-megamenu__item--with-large-panel:not(.cb-megamenu__item--with-extra-large) {
      .cb-megamenu__panel {
        @include media(bp(xl)) {
          width: 50vw;
        }
        @include media(bp(xxl)) {
          width: #{$page-container-max-width * 0.5};
        }
        &-group {
          @include media(bp(xl)) {
            width: 50%;
          }
          &--l {
            width: 100%;
          }
        }
      }
    }
  }
}

// Hide any primary link items that would exceed the total maximum character
// limit for mega menus.
.cb-flavor--mega-menu,
.cb-flavor--compact-mega-menu .cb-nameplate .cb-primary-menu {
  .cb-megamenu {
    @include media(bp(xl)) {
      > .cb-megamenu__item--overflow {
        display: none;
      }
    }
  }
}

.cb-flavor--mega-menu {
  .cb-primary-navigation,
  .cb-subsection-navigation {
    // Override site specific menu styles.
    @include media(bp(xl)) {
      background-color: $background-solid-light;
      li a {
        color: color(action, lo);
      }
    }
    // Blue underline for the navigation when a panel is opened.
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 6px;
      background: transparent;
      bottom: 0;
      position: absolute;
      transition: all 300ms ease-in-out;
    }
    &--active-drawer {
      &::after {
        width: 100%;
        background: color(primary, brand);
        z-index: 1;
      }
    }
  }
}

// Primary Links
.cb-megamenu {
  .cb-flavor--mega-menu & {
    @include media(bp(xl)) {
      display: flex;
      &--featured-items.cb-megamenu--l {
        > li:not(.cb-menu-item--featured) {
          margin-left: 0;
          margin-right: 3.5%;
        }
        .cb-megamenu__item--content-overflow {
          order: 100;
          margin-right: 0;
        }
      }
      > li {
        display: inline-block;
        float: none;
        &.cb-megamenu__item--overflow {
          display: none;
        }
      }
      &.cb-megamenu--l > .cb-menu-item--featured {
        margin-right: 0;
        margin-left: auto;
      }
      > .cb-menu-item--featured {
        @include media(bp(xl)) {
          order: 99;
          > a {
            color: #fff;
            background-color: color(secondary, brand);
            padding-left: 1em;
            padding-right: 1em;
            &.cb-megamenu__link--panel {
              padding-right: 1.9em;
              .cb-megamenu__panel-hint {
                right: 1.5em;
              }
            }
          }
          ~ .cb-menu-item--featured {
            margin-left: 0;
          }
          ~ .cb-menu-item--featured > a {
            background-color: color(neutral-brand, lo);
          }
          // stylelint-disable selector-max-compound-selectors
          ~ .cb-menu-item--featured ~ .cb-menu-item--featured > a {
            background-color: color(neutral-brand);
          }
          // stylelint-enable selector-max-compound-selectors
        }
      }
    }
  }
  // Reduce primary nav margins for mega menus near the character limit.
  .js .cb-flavor--mega-menu .cb-primary-navigation & {
    &.cb-megamenu--l {
      > li {
        @include media(bp(xl)) {
          margin-right: 2.9%;
          &.cb-menu-item--featured {
            margin-right: 0;
          }
        }
        // Left/Right padding is reset to zero at xxl + 1.875em in critical nav.
        @include media(#{(bp(xxl) + 1.875em)}) {
          margin-right: 3.6%;
          &.cb-menu-item--featured {
            margin-right: 0;
          }
        }
      }
    }
  }
  // Primary nav list item with a mega menu panel.
  &__item--panel {
    padding-right: 50px;
    position: relative;
    @include media(bp(xl)) {
      padding-right: 0;
      position: static;
    }
  }
  > li .cb-megamenu__link {
    @include media(bp(xl)) {
      border-bottom: none;
      padding-top: 0.4em;
      padding-bottom: 0.25em;
      .cb-click & {
        &:focus {
          outline: none;
        }
      }
      &--panel {
        padding-right: 0.5em;
      }
      &.active-trail {
        .cb-flavor--mega-menu & {
          border-bottom-color: color(primary, brand);
        }
        &:not(.cb-megamenu__link--active):not(:hover) {
          .cb-flavor--mega-menu .cb-primary-navigation--active-drawer & {
            &::after {
              transform: scale(0, 1);
            }
          }
        }
        &.cb-megamenu__link--active,
        &:hover {
          .cb-flavor--mega-menu .cb-primary-navigation--active-drawer & {
            &::after {
              z-index: 2;
            }
          }
        }
        &:hover:not(.cb-megamenu__link--active) {
          .cb-flavor--mega-menu .cb-primary-navigation--active-drawer & {
            &::after {
              background-color: a11yrize(color(neutral-brand, xxhi), $background-solid-light, 1.5);
            }
          }
        }
        &.cb-megamenu__link--active {
          .cb-flavor--mega-menu .cb-primary-navigation--active-drawer & {
            &::after {
              background-color: a11yrize(color(neutral-brand, xxhi), $background-solid-light, 1.5);
            }
          }
        }
      }
      &::after {
        .cb-flavor--mega-menu & {
          right: 0;
          background-color: a11yrize(color(neutral-brand), $background-solid-light, 3);
          z-index: 2;
        }
      }
      &--active {
        .cb-flavor--mega-menu & {
          &::after {
            background-color: a11yrize(color(neutral-brand, xxhi), $background-solid-light, 1.5);
            transform: scale(1, 1);
          }
        }
      }
      &--active,
      &:hover {
        .cb-flavor--mega-menu .cb-primary-navigation--active-drawer & {
          &::after {
            background-color: a11yrize(color(neutral-brand, xxhi), $background-solid-light, 1.5);
          }
        }
      }
    }
  }
  > li.cb-menu-item--featured .cb-megamenu__link {
    &::after {
      .cb-flavor--mega-menu & {
        @include media(bp(xl)) {
          background-color: a11yrize(color(neutral-brand, xxhi), $background-solid-light, 1.5);
        }
      }
    }
  }
  &__link {
    .cb-flavor--mega-menu .cb-megamenu:not(.cb-megamenu--l) & {
      @include media(bp(xl)) {
        font-size: 1.75vw;
        ~ .cb-megamenu__panel {
          li, p, h2 {
            font-size: 0.9rem;
          }
          // stylelint-disable selector-max-compound-selectors
          .cb-megamenu__panel-group-caption p {
            font-size: 1em;
          }
          .cb-megamenu__panel-group--description .cb-megamenu__group-heading {
            font-size: 1.44rem;
          }
          // stylelint-enable selector-max-compound-selectors
        }
      }
      @include media(bp(xl)) {
        font-size: 1.5vw;
        ~ .cb-megamenu__panel {
          li, p, h2 {
            font-size: 0.95rem;
          }
          // stylelint-disable selector-max-compound-selectors
          .cb-megamenu__panel-group--description .cb-megamenu__group-heading {
            font-size: 1.52rem;
          }
          // stylelint-enable selector-max-compound-selectors
        }
      }
      @include media(93em) {
        font-size: 1.3vw;
        ~ .cb-megamenu__panel {
          li, p, h2 {
            font-size: 1rem;
          }
          // stylelint-disable selector-max-compound-selectors
          .cb-megamenu__panel-group--description .cb-megamenu__group-heading {
            font-size: 1.6rem;
          }
          // stylelint-enable selector-max-compound-selectors
        }
      }
      @include media(bp(xxl)) {
        font-size: 1.3125em;
      }
    }
    .cb-flavor--mega-menu .cb-megamenu--l & {
      @include media(bp(xl)) {
        font-size: 1.3vw;
      }
      @include media(bp(xxl)) {
        font-size: 1.3125em;
      }
    }
    .cb-flavor--mega-menu .cb-megamenu--l &,
    .cb-flavor--compact-mega-menu .cb-megamenu & {
      @include media(bp(xl)) {
        ~ .cb-megamenu__panel {
          li, p, h2 {
            font-size: 0.9rem;
          }
          // stylelint-disable selector-max-compound-selectors
          .cb-megamenu__panel-group--description .cb-megamenu__group-heading {
            font-size: 1.44rem;
          }
          // stylelint-enable selector-max-compound-selectors
        }
      }
      @include media(bp(xxl)) {
        ~ .cb-megamenu__panel {
          li, p, h2 {
            font-size: 1rem;
          }
          // stylelint-disable selector-max-compound-selectors
          .cb-megamenu__panel-group--description .cb-megamenu__group-heading {
            font-size: 1.6rem;
          }
          // stylelint-enable selector-max-compound-selectors
        }
      }
    }
  }
  &__link--panel {
    // Extra specificity needed to override base styles.
    .cb-megamenu--active > li & {
      padding-right: 0.9em;
    }
  }
  // Caret indicating the presence of a mega menu panel.
  &__panel-hint {
    @include media(bp(xl)) {
      position: absolute;
      top: calc(50% - 0.25em);
      right: 0;
      line-height: 0;
      font-size: 0.6em;
      transition: transform 0.3s ease;
      @include icon($icon: 'caret-down');
      .cb-megamenu__link--active & {
        transform: rotate(0.5turn);
      }
    }
  }
}

// Mega Menu panel styles.
.cb-megamenu {
  position: relative;
  &__panel-link-duplicate {
    display: none;
    @include media(bp(xl)) {
      display: block;
    }
  }
  &__panel {
    display: none;
    @include media(bp(xl)) {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      background: $background-solid-xlight;
      z-index: -1;
      box-shadow: rgba(0, 0, 0, 0.12) 0 4px 4px 0, rgba(0, 0, 0, 0.12) -4px 4px 4px 0, rgba(0, 0, 0, 0.12) 4px 4px 4px 0;
      &--active {
        @include animated($duration: 0.5s);
        display: flex;
        // Wrap required to make the optional panel heading span accross.
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
  &__group-heading {
    @include media(bp(xl)) {
      margin-bottom: 0.5em;
      border-bottom: 1px solid darken($border-light, 4%);
      padding-bottom: 0.5rem;
      // Overspecification to override base styles.
      // stylelint-disable selector-max-compound-selectors
      a, span {
        .cb-flavor .cb-primary-navigation .cb-megamenu .cb-megamenu__panel &,
        .cb-flavor .cb-subsection-navigation .cb-megamenu .cb-megamenu__panel &,
        .cb-flavor .cb-primary-menu .cb-megamenu .cb-megamenu__panel &  {
          font-size: 1.6em;
        }
      }
      // stylelint-enable selector-max-compound-selectors
      a {
        // Preserve focus outlines;
        margin-top: 1px;
        padding: 0;
        border: none;
        color: a11yrize(color(action, m), darken($background-solid-xlight, 6%));
        font-weight: 600;
        @include icon($icon: 'arrow-right', $pseudo: 'after', $size: 0.75em) {
          margin-left: 0.25em;
        }
        &:focus {
          border-bottom: none;
        }
        &:hover {
          text-decoration: underline;
          &::after {
            text-decoration: none;
            @include animated($name: wobble);
          }
        }
        .cb-primary-menu &,
        .cb-subsection-menu & {
          // Overriding critial base styles.
          padding: 0;
          color: a11yrize(color(action, m), darken($background-solid-xlight, 6%));
        }
      }
    }
  }
  &__panel-group {
    @include media(bp(xl)) {
      overflow: hidden;
      padding: 2em;
      padding-top: 1.5em;
      background: $background-solid-xlight;
      // IE 11 flex height fix
      min-height: 1px;
      @include quantity-positions(2, 2) {
        background-color: color(secondary);
        .cb-megamenu__panel-heading ~ & {
          background-color: darken($background-solid-xlight, 3%);
        }
        .cb-megamenu__group-heading a,
        .cb-megamenu__panel-group-links a {
          .cb-flavor &,
          .cb-flavor .cb-nameplate & {
            color: #fff;
            &.active {
              color: #fff;
            }
          }
        }
      }
      @include quantity-positions(3, 2) {
        background-color: darken($background-solid-xlight, 3%);
      }
      @include quantity-positions(3, 3) {
        background-color: color(secondary);
        .cb-megamenu__panel-heading ~ & {
          background-color: darken($background-solid-xlight, 3%);
        }
      }
    }
    &:first-child .cb-megamenu__group-heading,
    &:nth-child(n+2) {
      display: none;
      @include media(bp(xl)) {
        display: block;
      }
      // Expanded children.
      .cb-megamenu__panel-heading ~ & {
        display: block;
        .cb-megamenu__group-heading {
          @include media(max-width (bp(l) - 0.06em)) {
            font-size: 1em;
            padding: 0 1rem;
            margin: 0;
            // Overspecified to override base styles.
            // stylelint-disable selector-max-compound-selectors
            a {
              .mobile-menu ul.cb-primary-links & {
                font-size: 1em;
                padding: 0.5rem 1.9rem;
              }
            }
            // stylelint-enable selector-max-compound-selectors
            + nav {
              padding-left: 1rem;
            }
          }
        }
      }
    }
    &.cb-megamenu__panel-group--description {
      @include media(bp(xl)) {
        display: flex;
        flex-flow: column wrap;
        // IE 11 flex height fix
        min-height: 1px;
      }
    }
    li {
      display: block;
      width: 100%;
      margin-right: 0;
    }
    &-links a {
      display: block;
      font-size: 1em;
      line-height: 1.5;
      padding: 0.4em 0;
      padding-left: 0.9em;
      .cb-flavor .cb-nameplate .pane-main-menu.cb-primary-menu &,
      .cb-flavor--mega-menu .pane-main-menu.cb-primary-menu &,
      .cb-flavor--subsection .pane-main-menu.cb-subsection-menu & {
        color: #fff;
        font-weight: 400;
        @include media(bp(xl)) {
          color: a11yrize(color(action, m), darken($background-solid-xlight, 6%));
        }
        &.active {
          font-weight: 700;
          @include media(bp(xl)) {
            color: a11yrize(color(neutral-brand, m), darken($background-solid-xlight, 6%));
          }
          &::before {
            content: "\25CF \00a0";
          }
        }
      }
      &::before {
        content: "\00BB \00a0";
        display: inline-block;
        margin-left: -0.85em;
      }
      &:hover {
        text-decoration: underline;
        &::before {
          text-decoration: none;
        }
      }
      // Overrides for primary link cascades.
      .cb-primary-navigation &,
      .cb-subsection-navigation & {
        font-size: 1em;
        font-weight: 400;
        border: none;
        padding: 0.4em 0;
        padding-left: 0.9em;
        &::after {
          display: none;
        }
        &:focus {
          border-bottom: none;
        }
        &:hover {
          text-decoration: underline;
          &::before {
            text-decoration: none;
          }
        }
      }
      .cb-primary-navigation .cb-mobile-menu & {
        font-size: 1rem;
        font-weight: 400;
        padding: 0.5em 1.9rem;
        &.active {
          font-weight: 700;
          &::before {
            content: "\25CF \00a0";
          }
        }
      }
      &.cb-megamenu__more-link {
        font-style: italic;
        padding-left: 0;
        @include icon($icon: 'arrow-right', $pseudo: 'after') {
          position: static;
          background: none;
          margin-left: 0.33em;
          text-decoration: none;
          bottom: initial;
          left: initial;
          transform: none;
          width: initial;
          height: initial;
        }
        &::before {
          display: none;
        }
        &:hover {
          text-decoration: none;
          &::after {
            @include animated($name: wobble);
          }
        }
      }
    }
    &--description {
      background: color(secondary);
      color: #fff;
      .cb-megamenu__group-heading {
        color: #fff;
        // IE 11 alignment fix.
        width: 100%;
      }
      .cb-megamenu__panel-group-description {
        // IE 11 flex height fix.
        min-height: 1px;
        // IE 11 alignment fix.
        width: 100%;
      }
      .cb-megamenu__panel-group-caption p {
        a {
          font-family: $font--text--alt;
          padding: 0;
          text-decoration: underline;
          font-size: 1em;
          color: #fff;
          display: inline;
          &:hover {
            text-decoration: none;
          }
        }
        // fix tel links to not wrap over more than one line
        a[href^="tel"] {
          white-space: nowrap;
        }
      }
      img {
        padding-top: 0.5rem;
      }
      @include media(bp(xl)) {
        .cb-megamenu__panel-group-caption {
          margin-top: 0.5em;
          font-size: 0.9rem;
          line-height: 1.5;
          p {
            hyphens: auto;
            a {
              hyphens: none;;
            }
          }
          .cb-button {
            background: transparent;
            border-color: #fff;
            color: #fff;
            font-size: 1em;
            padding: 0.6em 1.2em 0.6625em 1.2em;
            hyphens: none;
            // Fix for missing paragraph element in markup of description.
            display: table;
            &:hover,
            &:focus {
              background-color: #fff;
              color: color(secondary, brand);
            }
          }
          &:not(:only-child) {
            .cb-button {
              margin-top: 1em;
            }
            p + .cb-button {
              margin-top: 0;
            }
          }
          p + .cb-button {
            display: inline-block;
          }
        }
      }
      @include media(bp(xxl)) {
        .cb-megamenu__panel-group-caption p {
          font-size: 1rem;
        }
      }
    }
  }
}

// Panels with non curated links.
.cb-megamenu__panel-heading {
  display: none;
  width: 100%;
  font-size: 1rem;
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 2rem;
  border-bottom: 1px solid darken($border-light, 4%);
  @include media(bp(xl)) {
    display: block;
  }
  a {
    .cb-primary-navigation &,
    .cb-subsection-navigation & {
      color: a11yrize(color('action'), $background-solid-xlight);
      display: inline;
      @include icon($icon: 'arrow-right', $pseudo: 'after', $size: 0.7em) {
        position: static;
        background-color: transparent;
        transform: none;
        width: auto;
        height: auto;
        margin-left: 0.25em;
        margin-top: -0.2em;
      }
      &:hover::after {
        @include animated($name: wobble);
      }
    }
  }
  // Expanded Children.
  ~ .cb-megamenu__panel-group {
    @include media(bp(xl)) {
      width: 25%;
      padding-bottom: 1rem;
      padding-top: 1rem;
      background-color: darken($background-solid-xlight, 3%);
      // Overspecified to override base styles
      // stylelint-disable selector-max-compound-selectors
      .cb-megamenu__group-heading {
        a, span {
          .cb-flavor .cb-primary-navigation .cb-megamenu .cb-megamenu__panel &,
          .cb-flavor .cb-subsection-navigation .cb-megamenu .cb-megamenu__panel &,
          .cb-flavor .cb-primary-menu .cb-megamenu .cb-megamenu__panel &  {
            font-size: 1.5em;
          }
        }
      }
      // stylelint-enable selector-max-compound-selectors
      &:nth-child(n+6) {
        h2 {
          margin-bottom: 0;
        }
        .cb-megamenu__panel-group-links {
          display: none;
        }
      }
      // Adjust last item width to apply the background color full width.
      &:nth-child(4n+6):last-child {
        width: 100%;
        .cb-megamenu__group-heading {
          width: calc(25% - 3rem);
        }
      }
      &:nth-child(4n+7):last-child {
        width: 75%;
        .cb-megamenu__group-heading {
          width: calc(33.33333% - 2.66666rem);
        }
      }
      &:nth-child(4n+8):last-child {
        width: 50%;
        .cb-megamenu__group-heading {
          width: calc(50% - 2rem);
        }
      }
    }
  }
}

// Mobile.
.cb-megamenu {
  // Mobile toggle button for panels.
  &__panel-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    // Height of the primary menu link with 1px border plus list item padding.
    height: calc(3.15rem + 4px);
    text-align: center;
    background: color(primary, xlo);
    color: #fff;
    border: 2px solid color(primary, brand);
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    @include icon($icon:'caret-down', $pseudo:'after') {
      transition: all 0.3s ease;
      margin: 0 auto;
    }
    &:hover,
    &:focus {
      background: color(primary, xlo);
      color: #fff;
      box-shadow: none;
    }
    &--active::after {
      transform: rotate(0.5turn);
    }
  }
  &__link--panel {
    .mobile-menu ul.cb-primary-links & {
      padding-right: calc(50px + 1em);
    }
  }
}

// Featured ghosted menu buttons in the mobile menu.
.cb-mobile-menu {
  // stylelint-disable selector-max-compound-selectors
  .cb-megamenu > li {
    &.cb-menu-item--featured > a,
    &.cb-menu-item--featured > a.active-trail
    &.cb-menu-item--featured > a.active-trail.active {
      position: relative;
      &::after {
        content: '';
        border: 2px solid color(primary, xlo);
        position: absolute;
        left: 1.25rem;
        right: 3.5rem;
        top: 0.5rem;
        bottom: 0.5rem;
        border-radius: 4px;
      }
    }
    &.cb-menu-item--featured > a.active-trail,
    &.cb-menu-item--featured > a.active-trail.active {
      &::after {
        border-color: a11yrize(color(action, m), $background-active-state);
      }
    }
  }
  // stylelint-enable selector-max-compound-selectors
}

// Non section primary link spacing.
.cb-flavor--mega-menu {
  .cb-primary-navigation,
  .cb-subsection-navigation {
    .cb-megamenu:not(.cb-megamenu--l) > li:not(.cb-menu-item--featured) {
      @include media(bp(xl)) {
        margin-right: 3.5%;
      }
    }
    .cb-megamenu:not(.cb-megamenu--l) > li.cb-menu-item--featured {
      margin-left: auto;
      margin-right: 0;
      ~ .cb-menu-item--featured {
        margin-left: 0;
      }
    }
  }
}
