// CTA Drupal IPE fixes across all cta styles

.panels-ipe-bundle-cta {
  // Prevent vertical stretchimg in IPE.
  align-self: flex-start;
  // Force height in IPE.
  .pane-bundle-cta,
  .pane-bundle-cta > .fieldable-panels-pane {
    height: 100%;
  }
  // Remove the added padding from region bg 3.
  .pane-bundle-cta {
    .cb-background--3.region & {
      padding: 0;
    }
  }
}

.cb-flavor.panels-ipe-editing:not(.cb-list-news) {
  .pane.cb-style {
    &.panels-ipe-bundle-cta {
      .panels-ipe-handlebar-wrapper {
        position: relative;
        background: #fff;
      }
      .panels-ipe-dragbar {
        border: 1px solid #CCC;
      }
    }
  }
  // Sidebar
  .cb-content-area__aside-last {
    .pane.cb-style {
      &.panels-ipe-bundle-cta {
        .paragraphs-item-cta {
          padding: 0;
        }
        .panels-ipe-portlet-content {
          background: $sidebar-component-bg-color;
          @include component-padding();
        }
      }
    }
  }
}
