// Promoted Announcements typography

// Default across all pages
.region,
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    ul:not(.panels-ipe-linkbar) {
      li {
        .cb-list-news {
          &__title {
            h3 {
              @include component-link-title(s);
            }
          }
        }
        @include quantities-all(1) {
          .column-first.column--first {
            /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
            // no image
            &:only-child {
              .cb-list-news {
                &__title {
                  h3 {
                    text-align: center;
                  }
                }
              }
            }
            /* stylelint-enable */
          }
        }
        @include media(bp(s)) {
          .cb-list-news {
            &__title {
              h3 {
                @include component-link-title(m);
              }
            }
          }
        }
        @include media(bp(m)) {
          @include quantities-all(1) {
            .column-first.column--first {
              /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
              // no image
              &:only-child {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(l);
                    }
                  }
                  &__summary {
                    p {
                      @include component-paragraph(s);
                    }
                  }
                }
              }
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}

.region {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    ul:not(.panels-ipe-linkbar) {
      li {
        @include media(bp(m)) {
          @include quantity-positions(5,1) {
            .cb-list-news {
              &__title {
                h3 {
                  @include component-link-title(l);
                }
              }
            }
          }
        }
        @include media(bp(xl)) {
          @include quantities-all(2,4) {
            .cb-list-news {
              &__title {
                h3 {
                  @include component-link-title(l);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Homepage and featured layout with no sidebars
.front,
.cb-flavor--subsection-front .cb-layout--dynamic-features-asides-feature-rows,
.cb-layout--no-aside.cb-layout--dynamic-features-asides-feature-rows {
  .region {
    .cb-list-news--promoted.cb-list-news--announcements,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
      ul:not(.panels-ipe-linkbar) {
        li {
          /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
          @include media(bp(l)) {
            @include quantity-positions(5,1) {
              .column-first.column--first {
                // no image
                &:only-child {
                  .cb-list-news {
                    &__title {
                      h3 {
                        text-align: center;
                      }
                    }
                  }
                }
              }
            }
          }
          @include media(bp(xl)) {
            @include quantity-positions(5,1) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(xl);
                  }
                }
              }
            }
            @include quantities-all(1) {
              .cb-list-news {
                &__title {
                  h3 {
                    @include component-link-title(xl);
                  }
                }
              }
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }
}


// Featured Layout with sidebars:
// Decrease font size due to smaller page content width
.cb-flavor:not(.cb-flavor--subsection-front) .cb-layout--dynamic-features-asides-feature-rows {
  &.cb-layout--aside-first,
  &.cb-layout--aside-last,
  &.cb-layout--aside-first-last {
    .region {
      .cb-list-news--promoted.cb-list-news--announcements,
      .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        @include media(bp(l)) {
          ul:not(.panels-ipe-linkbar) {
            li {
              @include quantities-all(2,4) {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(s);
                    }
                  }
                }
              }
              @include quantity-positions(5,1) {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(m);
                    }
                  }
                }
              }
              @include quantity-positions(5,2,3,4,5) {
                .cb-list-news {
                  &__title {
                    h3 {
                      @include component-link-title(s);
                    }
                  }
                }
              }
            }
          }
        }
        /* stylelint-enable */
      }
    }
  }
}


// Main content only
.cb-content-area__main {
  .cb-list-news--promoted.cb-list-news--announcements,
  .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
    .more-link a {
      text-decoration: none;
    }
    li {
      // no image
      .column-first.column--first:only-child {
        // fix IE11 text wrap
        display: block;
      }
    }
  }
}

.cb-layout--aside-first,
.cb-layout--aside-last {
  .cb-content-area__main {
    .cb-list-news--promoted.cb-list-news--announcements,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
      @include media(bp(l)) {
        .cb-list-news {
          &__title {
            h3 {
              @include component-link-title(l);
            }
          }
        }
      }
    }
  }
}

.cb-layout--aside-first-last {
  .cb-content-area__main {
    .cb-list-news--promoted.cb-list-news--announcements,
    .panels-ipe-portlet-wrapper .cb-list-news--promoted.cb-list-news--announcements {
      @include media(bp(l)) {
        h2.pane-title {
          @include component-heading(xl);
        }
      }
      @include media(bp(xl)) {
        li {
          @include quantities-all(5,4,3,2,1) {
            .cb-list-news {
              &__title {
                h3 {
                  @include component-link-title(s);
                }
              }
            }
          }
        }
      }
    }
  }
}
