span.ext {
  display: inline-block;
  margin-left: 0.25em;
  width: auto;
  height: auto;
  padding: 0;
  background: none;
  @include icon($icon: "external-link", $size: 0.7em, $color: 'remove') {
    vertical-align: baseline;
  }
}

a[href^="mailto:"] {
  line-height: 1.2;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  // display mail icon before link
  @include icon($icon: "mailto", $size: 0.7em, $color: 'remove') {
    vertical-align: baseline;
    margin-right: 0.5em;
    display: inline;
  }
  // hide default drupal mail icon
  // that appears after the link
  span.mailto {
    background: transparent;
    padding-right: 0;
    width: 0;
    height: 0;
  }
}

a.phone-link {
  @include icon($icon: "phone", $size: 0.7em, $color: 'remove') {
    vertical-align: baseline;
    margin-right: 0.5em;
    display: inline;
  }
}

a[href^="mailto:"],
a.phone-link {
  // remove icon default link underline
  // in main content area
  .node-type-news .pane-node-body &,
  .cb-content-area__main & {
    &::before {
      display: inline-block;
      // to make 'no underline' work in IE11,
      // this needs to be declared first
      // followed by text-decoration: none
      // in separate declaration below
      text-decoration: underline;
    }
    &::before {
      text-decoration: none;
    }
  }
}
