// stylelint-disable scss/dollar-variable-pattern, scss/dollar-variable-empty-line-before

/* ==================== DEFAULT THEME VARIABLES ======================= */
/// Is used to set page padding, or margins on content; i.e. grid outside margins;
///
/// @type Number (rem)
$page-margins: 1.25rem !default;

$page-margins-combined: 2 * $page-margins !default;

$component-spacing: 1.7% !default;

// This can't be a percentage, beacuse firefox interprets that as 0 flor flex items.
$component-vertical-spacing: 2.5em;

$image-path: "../../images" !default;

$box-shadow: 0.5px 1px 2px 0.5px rgba(0, 0, 0, 0.1), -0.5px 0 0.5px 1px rgba(0, 0, 0, 0.06) !default;

$text-shadow: 0 4px 4px rgba(0,0,0,0.24) !default;

// Disable Bourbon deprecation warnings
$output-bourbon-deprecation-warnings: false !default;

// Remove any styles meant only for the styleguide.
$styleguide-only: false !default;

// Remove any styles meant only for a global critical css file.
$criticalcss: false !default;

/* ========== PAGE CONTAINER ============= */

$page-container-max-width: 105rem; // 1680px


/* ========== SITE HEADER ============= */

$header-nameplate-padding-top: 1em !default;
$header-nameplate-padding-bottom: 1em !default;
$header-nameplate-m-padding-top: 1.5em !default;
$header-nameplate-m-padding-bottom: 1.5em !default;
$header-nameplate-l-padding-top: 2em !default;
$header-nameplate-l-padding-bottom: 2em !default;
$header-nameplate-background-color: #fff !default;


/* ========== SITE FOOTER ============= */

$footer-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24) !default;


/* ========== ALERT ============= */
$alert-bg-color--emergency: color(accent-red, lo) !default;
$alert-font-color--emergency: #fff !default;
$alert-bg-color--advisory: color(neutral-brand, lo) !default;
$alert-font-color--advisory: #fff !default;
$alert-bg-color--notice: color(neutral-brand, xxhi) !default;
$alert-font-color--notice: color(neutral-brand, xlo) !default;

/* ========== INTERACTION GUIDELINES ============= */
$touch-size-min: 1cm;
$touch-size-large: 44px;


/* ========== STANDARD BACKGROUND COLORS ============= */

$background-solid-xlight: lighten(color(neutral-brand, xxhi), 14%) !default;
$background-solid-light: lighten(color(neutral-brand, xxhi), 12%) !default;
$background-blue-solid-light: lighten(color(secondary, xhi), 16%) !default;
$background-gradient-1-top-color: color(neutral-brand, m) !default;
$background-gradient-1-bottom-color: #000 !default;
$background-gradient-2-top-color: lighten(color(primary, xhi), 22%) !default;
$background-gradient-2-bottom-color: lighten(color(primary, xhi), 18%) !default;

$background-active-state: lighten(color(secondary, xhi), 16%);

/* ========== STANDARD BORDERS ============= */

$border-xlight: lighten(color(neutral-brand, xxhi), 12%) !default;
$border-light: lighten(color(neutral-brand, xxhi), 8%) !default;

$border--xs: 1px solid $border-light !default;
$border--s: 2px solid $border-xlight !default;
$border--m: 5px solid $border-xlight !default;
$border--l: 10px solid $border-xlight !default;

$heading-border-color: color(primary, brand) !default;

$border-radius--default: 4px !default;

/* ========== REGION BACKGROUND STYLES ============= */

$region-bg-1-color: $background-solid-light !default;
$region-bg-2-color: color(primary, lo) !default;
$region-bg-3-color: color(secondary, brand) !default;

// Displays on large desktop within region with full width components
$region-default-background-color: $background-solid-xlight;


/* ========== HERO ============= */

// Style Auto (All heros):
// desktop
$hero-tone-default-title-font-color: #fff !default;
$hero-tone-default-group-titles-bg-color: rgba(color(primary, lo), 0.78) !default;
$hero-tone-light-title-font-color: #fff !default;
$hero-tone-light-group-titles-bg-color: rgba(color(primary, lo), 0.78) !default;
$hero-tone-dark-title-font-color: color(neutral-brand, xxlo) !default;
$hero-tone-dark-group-titles-bg-color: rgba(#fff, 0.78) !default;

// mobile
$hero-mobile-tone-default-title-font-color: #fff !default;
$hero-mobile-tone-default-group-titles-bg-color: color(primary, lo) !default;
$hero-mobile-tone-light-title-font-color: #fff !default;
$hero-mobile-tone-light-group-titles-bg-color: color(primary, lo) !default;
$hero-mobile-tone-dark-title-font-color: color(primary, xlo) !default;
$hero-mobile-tone-dark-group-titles-bg-color: #fff !default;

// Style 1 (Interior and Featured Layout):
// desktop: left aligned title block next to hero image
$hero-tone-default-title-font-color--style-1: #fff !default;
$hero-tone-default-group-titles-bg-color--style-1: color(primary, m) !default;
$hero-tone-light-title-font-color--style-1: #fff !default;
$hero-tone-light-group-titles-bg-color--style-1: color(primary, m) !default;
$hero-tone-dark-title-font-color--style-1: lighten(color(neutral-brand, xxlo), 2%) !default;
$hero-tone-dark-group-titles-bg-color--style-1: lighten(color(neutral-brand, xxhi), 5%) !default;

// mobile
$hero-mobile-tone-default-title-font-color--style-1: #fff !default;
$hero-mobile-tone-default-group-titles-bg-color--style-1: color(primary, m) !default;
$hero-mobile-tone-light-title-font-color--style-1: #fff !default;
$hero-mobile-tone-light-group-titles-bg-color--style-1: color(primary, m) !default;
$hero-mobile-tone-dark-title-font-color--style-1: lighten(color(neutral-brand, xxlo), 2%) !default;
$hero-mobile-tone-dark-group-titles-bg-color--style-1: lighten(color(neutral-brand, xxhi), 5%) !default;

// Hero jump link:
$hero-home-jump-link-icon-color: var(--site-accent-font-color) !default;
$hero-home-jump-link-bg-color: var(--site-accent) !default; // Page jump btn

// Gradient
$hero-bg-gradient-stop-first: 35% !default;
$hero-bg-gradient-stop-second: 100% !default;
$hero-bg-gradient-top-color: rgba(40, 20, 5, 0.0) !default;
$hero-bg-gradient-bottom-color: rgba(40, 20, 5, 0.5) !default;


/* ========== NAVIGATION ============= */

// Desktop styles
$nav-primary-bg-color: var(--nav-primary-bg-color) !default;
$nav-primary-link-color: color(action, lo) !default;
$nav-primary-hover-border-color: var(--site-accent) !default;
$nav-secondary-link-color: color(action, lo) !default;
$nav-secondary-btn-hover-bg-color: color(primary, brand) !default;
$nav-secondary-btn-hover-font-color: #fff !default;

// Mobile styles
$mobile-menu-side-nav-bg-color: color(primary, brand) !default;

// Page menu
$mobile-page-menu-bg-color: color(primary, brand) !default;
$mobile-page-menu-font-color: #fff !default;


/* ========== SECTIONS ============= */

// Section navigation color styles

// Navigation bottom border color when there is no sub nav
$section-nav-border-color: color(primary, lo);

// Style 1: Orange
$section-style-1-menu-title-color: #fff !default;
$section-style-1-menu-title-bg-color: color(accent-orange, lo) !default;
$section-style-1-menu-links-color: #fff !default;
$section-style-1-menu-links-hover-border-color: #fff !default;
$section-style-1-menu-links-bg-color: color(accent-orange, m) !default;

// Style 2: Green
$section-style-2-menu-title-color: #fff !default;
$section-style-2-menu-title-bg-color: color(accent-green, lo) !default;
$section-style-2-menu-links-color: #fff !default;
$section-style-2-menu-links-hover-border-color: #fff !default;
$section-style-2-menu-links-bg-color: color(accent-green, m) !default;

// Style 3: Blue
$section-style-3-menu-title-color: #fff !default;
$section-style-3-menu-title-bg-color: color(primary, xlo) !default;
$section-style-3-menu-links-color: #fff !default;
$section-style-3-menu-links-hover-border-color: #fff !default;
$section-style-3-menu-links-bg-color: color(primary, lo) !default;

// Style 4: Pink
$section-style-4-menu-title-color: #fff !default;
$section-style-4-menu-title-bg-color: color(accent-pink, xlo) !default;
$section-style-4-menu-links-color: #fff !default;
$section-style-4-menu-links-hover-border-color: #fff !default;
$section-style-4-menu-links-bg-color: color(accent-pink, brand) !default;

// Style 5: Yellow
$section-style-5-menu-title-color: color(neutral-brand, xxlo) !default;
$section-style-5-menu-title-bg-color: lighten(color(accent-yellow, xhi), 8%) !default;
$section-style-5-menu-links-color: color(neutral-brand, xxlo) !default;
$section-style-5-menu-links-hover-border-color: color(neutral-brand, xxlo) !default;
$section-style-5-menu-links-bg-color: color(accent-yellow, brand)  !default;


/* ========== TYPOGRAPHY ============= */

// Text
$c-text: color(neutral-brand, xxlo) !default;
$text-body-bg: lighten(color(neutral-warm, xhi), 23%);
$text-blockquote-color: color(neutral-brand, m) !default;
$text-link-color: color(action, m) !default;
$text-link-color-hover: darken(color(action, m), 8%) !default;
$text-link-color-main-content-and-p: color(action, m) !default;
$text-horizontal-rule-border: 2px solid $border-xlight !default;

// Headings
$c-headings: $c-text !default;
$headings-page-title-color: lighten(color(neutral-brand, xxlo), 4%) !default;
$headings-page-subtitle-color: color(neutral-brand, m) !default;
$headings-h1-color: $c-headings !default;
$headings-h2-color: $c-headings !default;
$headings-h3-color: $c-headings !default;
$headings-h4-color: $c-headings !default;
$headings-h5-color: $c-headings !default;
$headings-h6-color: $c-headings !default;


/* ========== BUTTONS ============= */

// Style Solid (default): Buttons with a solid background color
// Default styles (changes depending on main theme accent color)
$btn-font-color: var(--site-accent-font-color) !default;
$btn-bg-color: var(--site-accent-bg-color) !default;
$btn-hover-font-color: var(--site-accent-font-color) !default;
$btn-hover-bg-color: var(--site-accent-bg-color) !default;
$btn-border-color: color(neutral-brand, xhi) !default;
$btn-hover-box-shadow-color: color(neutral-brand, xhi) !default;

// Dark styles
$btn-dark-bg-color: #fff !default;
$btn-dark-font-color: color(neutral-brand, xxlo) !default;
$btn-dark-hover-bg-color: #fff !default;
$btn-dark-hover-font-color: color(neutral-brand, xxlo) !default;
$btn-dark-border-color: color(neutral-brand, m) !default;
$btn-dark-box-shadow-color: color(neutral-brand, xlo) !default;
$btn-dark-hover-box-shadow-color: lighten(color(neutral-brand, xxlo), 4%) !default;

// Style Outline: Buttons with a colored border
// Default styles
$btn-style-outline-color: color(primary, brand) !default;
$btn-style-outline-font-color: color(primary, brand) !default;
$btn-style-outline-hover-font-color: #fff !default;

// Dark styles
$btn-style-outline-dark-color: #fff !default;
$btn-style-outline-dark-hover-font-color: color(neutral-brand, xxlo) !default;

// Default interior page components btn styles (similar across all sites/themes)
$btn-bg-color-interior-pg-component: color(primary, brand) !default;
$btn-font-color-interior-pg-component: #fff !default;
$btn-hover-font-color-interior-pg-component: #fff !default;
$btn-hover-bg-color-interior-pg-component: lighten(color(primary, brand), 8%) !default;
$btn-focus-outline-color-interior-pg-component: color(neutral-brand, xxlo) !default;
$btn-outline-color-interior-pg-component: color(primary, brand) !default;
$btn-hover-outline-color-interior-pg-component: color(primary, brand) !default;


/* ========== DROPDOWNS ============= */

$dropdown-select-icon-img-file: "#{$image-path}/icons/icon-dropdown-arrow.svg" !default;
$dropdown-select-bg-color: $background-solid-xlight !default;


/* ========== ACCORDION ============= */

// Header
$accordion-bg-color: #fff !default;
$accordion-title-color: color(action, m) !default;
$accordion-open-close-icon-color: color(action, m) !default;

// Content
$accordion-content-bg-color: #fff !default;
$accordion-content-font-color: color(neutral-brand, xxlo) !default;
$accordion-content-link-color: color(action, m) !default;

// Striped variant
$accordion-bg-color--striped-1: color(primary, brand) !default;
$accordion-bg-color--striped-2: color(secondary, brand) !default;
$accordion-header-color--striped: #fff !default;
$accordion-open-close-icon-color--striped: #fff !default;


/* ========== DEFAULT COMPONENT STYLES ============= */

// Custom icons:
// changes color based on the site's primary branding color
$component-icon-color: var(--site-accent) !default;

// Widths
$component-content-max-width: 47.5rem !default;

// Height
$component-content-min-height: 7rem !default;

// Border
$component-border-style: $border--xs !default;

// Background colors (default)
$component-bg-auto-color: #fff !default;
$component-bg-auto-link-underline-color: lighten(color(neutral-brand, xxhi), 9%) !default;
$component-bg-1-color: $background-solid-xlight !default;
$component-bg-1-link-underline-color: lighten(color(neutral-brand, xxhi), 5%) !default;
$component-bg-2-color: color(primary, lo) !default;
$component-bg-2-border-color: lighten($component-bg-2-color, 35%) !default;
$component-bg-2-link-underline-color: color(primary, m) !default;
$component-bg-3-color: color(secondary, m) !default;
$component-bg-3-border-color: lighten($component-bg-3-color, 35%) !default;
$component-bg-3-link-underline-color: color(secondary, hi) !default;

// Font/link/border colors (default)
$component-bg-auto-title-color: lighten(color(neutral-brand, xxlo), 2%) !default;
$component-bg-auto-subtitle-color: color(neutral-brand, m) !default;
$component-bg-auto-font-color: color(neutral-brand, xxlo) !default;
$component-bg-auto-link-color: color(action, m) !default;
$component-bg-auto-border-color: lighten(color(neutral-brand, xxhi), 9%) !default;

$component-bg-1-title-color: color(neutral-brand, xxlo) !default;
$component-bg-1-subtitle-color: color(neutral-brand, xxlo) !default;
$component-bg-1-font-color: color(neutral-brand, xxlo) !default;
$component-bg-1-link-color: color(action, lo) !default;
$component-bg-1-border-color: color(secondary, xlo) !default;

$component-bg-2-title-color: #fff !default;
$component-bg-2-subtitle-color: #fff !default;
$component-bg-2-font-color: #fff !default;
$component-bg-2-link-color: #fff !default;

$component-bg-3-title-color: #fff !default;
$component-bg-3-subtitle-color: #fff !default;
$component-bg-3-font-color: #fff !default;
$component-bg-3-link-color: #fff !default;
$component-bg-3-border-color: color(secondary, hi) !default;


// Background colors (muted lighter palette)
// Currently used for news, events
$component-bg-light-auto-color: #fff !default;
$component-bg-light-1-color: lighten(color(neutral-brand, xxhi), 15%) !default;
$component-bg-light-2-color: lighten(color(primary, xhi), 18%) !default;
$component-bg-light-3-color: lighten(color(neutral-brand, xxhi), 10%) !default;
$component-bg-light-4-color: lighten(color(secondary, xhi), 19%) !default;


/* ========== CTAs ============= */

$cta-title-color: color(neutral-brand, xxlo) !default;
$cta-font-color: color(neutral-brand, xxlo) !default;
$cta-btn-alt: true !default;
$cta-btn-dark: false !default;
$cta-btn-size: s !default;

// Style 3
$cta-style-3-fallback-bg: color(primary, xlo) !default;
$cta-style-3-btn-alt: false !default;
$cta-style-3-btn-size: s !default;
$cta-style-3-btn-dark: true !default;

// Background 1
$cta-background-1-color: $background-solid-xlight !default;

// Background 2
$cta-background-2-color: $background-solid-light !default;

// Background 3
$cta-background-3-gradient-top-color: $background-gradient-1-top-color !default;
$cta-background-3-gradient-bottom-color: $background-gradient-1-bottom-color !default;
$cta-background-3-title-color: #fff !default;
$cta-background-3-bg-color: color(primary, xlo) !default;
$cta-background-3-font-color: #fff !default;
$cta-background-3-btn-alt: false !default;
$cta-background-3-btn-dark: true !default;
$cta-background-3-btn-size: s !default;

// Background 4
$cta-background-4-gradient-top-color: $background-gradient-2-top-color !default;
$cta-background-4-gradient-bottom-color: $background-gradient-2-bottom-color !default;

// CTA aside
$cta-aside-bg-color: $background-solid-xlight !default;
$cta-aside-title-color: color(neutral-brand, xxlo) !default;
$cta-aside-font-color: color(neutral-brand, xxlo) !default;
$cta-aside-link-color: color(action, m) !default;

// Region Style 1
// CTA Style auto
$cta-region-1-default-background: color(primary);


/* ========== FEATURED CONTENT ============= */

// Featured content
$featured-content-bg-color: #fff !default;
$featured-content-title-font-color: color(neutral-brand, xxlo) !default;
$featured-content-title-link-color: color(action, m) !default;
$featured-content-sub-title-color: color(neutral-brand, m) !default;
$featured-content-summary-color: color(neutral-brand, xxlo) !default;
$featured-content-separate-link-color: color(action, m) !default;


/* ========== CUPS ============= */

// Search
$cups-search-bg-color: #fff !default;
$cups-search-label-font-color: color(neutral-brand, m) !default;

// Simple search
$cups-simple-search-bg-color: #fff !default;
$cups-simple-search-title-color: color(neutral-brand, xxlo) !default;

// Results listing: header displaying number of search results
$cups-results-header-bg-color: color(secondary, lo) !default;
$cups-results-header-font-color: #fff !default;

// Results listing: individual listing
$cups-results-listing-title-name-color: color(action, m) !default;
$cups-results-listing-view-more-font-color: #fff !default;
$cups-results-listing-view-more-bg-color: color(secondary, lo) !default;
$cups-results-listing-view-more-hover-bg-color: color(secondary, m) !default;
$cups-results-listing-view-more-hover-font-color: #fff !default;

// Cups profile
$cups-profile-featured-area-title-color: #fff !default;
$cups-profile-featured-area-title-phone-color: #fff !default; // mobile
$cups-profile-blockquote-border-color: color(primary, brand) !default;

// Our locations: list
$cups-our-locations-bg-color: $background-solid-xlight !default;
$cups-our-locations-result-number-color: color(neutral-brand, xxlo) !default;
$cups-our-locations-inactive-result-number-color: color(neutral-brand, hi) !default;
$cups-our-locations-address-color: color(neutral-brand, xxlo) !default;
$cups-our-locations-map-bg-color: $background-solid-light !default;

// Active selected location
$cups-our-locations-active-bg-color: $background-solid-light !default;
$cups-our-ocations-active-result-number-color: color(neutral-brand, xlo) !default;
$cups-our-locations-active-font-color: color(neutral-brand, xxlo) !default;

// Location: icons with text label
$disabled-accessible-icon-color: color(neutral-brand, xxlo) !default; // need to add styles
$disabled-accessible-icon-font-color: color(neutral-brand, xxlo) !default;
$public-transit-accessible-icon-color: color(neutral-brand, xxlo) !default;
$public-transit-accessible-icon-font-color: color(neutral-brand, xxlo) !default;

// Location: primary label
$cups-locations-primary-label-bg-color: color(neutral-brand, m) !default;
$cups-locations-primary-label-font-color: #fff !default;


/* ========== NEWS & EVENTS ============= */

// News listing page
$news-featured-bg-color: $background-solid-xlight !default;
$news-featured-category-color: color(neutral-brand, m) !default;
$news-featured-title-link-color: color(action, lo) !default;
$news-featured-summary-color: color(neutral-brand, m) !default;

// Default:
// Promoted news and events
$news-events-promoted-bg-color: #fff !default;
$news-events-promoted-title-color: color(neutral-brand, xxlo) !default;
$news-events-promoted-border-color: lighten(color(neutral-brand, xxhi), 9%) !default;

// Promoted news
$news-promoted-category-color: color(neutral-brand, m) !default;
$news-promoted-category-bg-color: #fff !default;
$news-promoted-date-color: color(neutral-brand, m) !default;
$news-promoted-title-link-color: color(action, m) !default;

// Promoted events
$events-promoted-date-color: color(neutral-brand, xxlo) !default;
$events-promoted-title-link-color: color(action, m) !default;
$events-promoted-venue-font-color: color(neutral-brand, xxlo) !default;

// Style 1:
// Promoted news and events
$news-events-promoted-style-1-item-bg-color: $background-solid-xlight !default;
$news-events-promoted-style-1-date-color: lighten(color(neutral-brand, hi), 3%) !default;
$news-events-promoted-style-1-title-link-color: color(action, lo) !default;

// Promoted news
$news-promoted-style-1-news-tags-color: lighten(color(neutral-brand, hi), 3%) !default;
$news-promoted-style-1-date-font-color: color(neutral-brand, xxlo) !default;

// Promoted events
$events-promoted-style-1-venue-font-color: color(action, lo) !default;
$events-promoted-style-1-date-font-color: color(neutral-brand, m) !default;

// Style 2:
// Promoted news primary prominence
$news-promoted-primary-bg-color: inherit !default;
$news-promoted-primary-category-color: darken(color(neutral-brand, hi), 2%) !default;
$news-promoted-primary-title-link-color: color(action, m) !default;
$news-promoted-primary-summary-color: color(neutral-brand, xxlo) !default;


/* ========== VIDEO ============= */

$video-home-play-btn-icon-color: $btn-bg-color-interior-pg-component !default;
$video-home-play-btn-bg-color: #fff !default;


/* ========== SIDEBAR STYLES ============= */

$sidebar-component-bg-color: $background-solid-xlight !default;
$sidebar-component-title-color: color(neutral-brand, xxlo) !default;
$sidebar-component-subtitle-color: color(neutral-brand, lo) !default;
$sidebar-component-tags-color: color(neutral-brand, lo) !default;
$sidebar-component-date-color: color(neutral-brand, lo) !default;
$sidebar-component-font-color: color(neutral-brand, xxlo) !default;
$sidebar-component-link-color: a11yrize(color(action,m), $sidebar-component-bg-color) !default;
$sidebar-component-border-color: $border-light !default;
$sidebar-component-content-max-width: 47.5em !default;

$sidebar-component-right-rail-title-size: 1.3rem !default;
$sidebar-component-right-rail-padding: 2em 2em 2.2em 2em !default;


/* ========== SEARCH ============= */
$search-header-results-color: color(secondary, lo) !default;


/* ========== MAINTENANCE PAGE ============= */

$maintenance-site-name-color: color(primary, xlo);
$maintenance-page-bg-color: $text-body-bg;

// stylelint-enable scss/dollar-variable-pattern, scss/dollar-variable-empty-line-before
