.file-cola-wysiwyg-teaser,
.file-cola-wysiwyg-full {
  margin: 2.1em auto;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  figure {
    margin: 0;
  }
  figcaption {
    font-family: $font--text--alt;
    color: color(neutral-brand, m);
    padding: 0.5em 0;
    text-align: left;
    display: block;
    margin: 0 auto;
    margin-bottom: -0.5rem;
    // stylelint-disable selector-max-compound-selectors
    &,p {
      &,
      .cb-content-area__main>.pane-node-body &,
      .node-type-news .cb-feature-quaternary .cb-container__inner > .pane-node-body & {
        font-size: 0.9em;
      }
    }
    // stylelint-enable selector-max-compound-selectors
  }
}

.file-cola-wysiwyg-full {
  &:first-child,
  &:last-child {
    li & {
      margin-top: 2.1em;
      margin-bottom: 2.1em;
    }
  }
  &.align-center {
    @include media(bp(m)) {
      max-width: 460px;
      width: 54%;
    }
  }
  img.lazyload {
    width: 100%;
  }
}

.file-cola-wysiwyg-teaser {
  float: none;
  text-align: center;
  @include media(max-width bp(bs)) {
    // On smaller screens the caption width is too narrow when the image
    // is also narrow, so we need to override the behavior.
    &.cb-image-squarish,
    &.cb-image-portrait {
      figure {
        position: relative;
        padding-top: 0.5em;
      }
      figcaption {
        // stylelint-disable declaration-no-important
        // Need important to override the width set by javascript.
        width: auto !important;
        // stylelint-enable declaration-no-important
        color: a11yrize(color(neutral-brand, m), $background-solid-light);
        padding: 0.5em;
        text-align: center;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $background-solid-light;
          z-index: -1;
        }
      }
    }
  }
  @include media(bp(m)) {
    width: 31%;
    max-width: 260px;
    &.cb-image-squarish {
      width: 38%;
      max-width: 320px;
    }
    &.cb-image-landscape,
    &.file-video {
      width: 45%;
      max-width: 460px;
    }
    &.align-right {
      margin: 0 0 1.25em 1.5em;
      float: right;
      clear: right;
    }
    &.align-left {
      margin: 0 1.5em 1.25em 0;
      float: left;
      clear: left;
    }
    &.align-right,
    &.align-left {
      li & {
        margin-top: 0.5em;
      }
    }
    &.cb-image-portrait:not(.cb-image-squarish) {
      + .cb-image-portrait:not(.cb-image-squarish) {
        &.align-right,
        &.align-left {
          clear: none;
        }
      }
    }
  }
  img {
    max-height: 56.25vw;
    width: auto;
    @include media(bp(m)) {
      max-height: 100%;
      // Need max-width or the images might overflow and cover text in
      // the wysiwyg.
      max-width: 100%;
    }
    &.lazyload {
      width: 100%;
    }
  }
}

.cb-embed {
  iframe {
    width: 100%;
  }
  // Backend adds an inline style with bottom padding on the .cb-embed div for
  // iframes that have an intrinsic ratio, and adds the cb-embed--ratio class.
  &--ratio {
    position: relative;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border: none;
    }
  }
  &--arcgis {
    iframe {
      border: $border--xs;
    }
  }
  &--instagram {
    // stylelint-disable declaration-no-important
    iframe,
    blockquote {
      // Need to override instagram inline styles.
      min-width: 0 !important;
      max-width: 100% !important;
      position: static !important;
    }
    iframe {
      .file-cola-wysiwyg-full & {
        // Need to override instagram inline styles.
        margin-left: auto !important;
        margin-right: auto !important;

      }
      .file-cola-wysiwyg-teaser & {
        @include media(max-width bp(m)) {
          // Need to override instagram inline styles.
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
    }
    // stylelint-enable declaration-no-important
  }
}
