.cb-phone,
.cb-email {
  &__label {
    font-size: 0.85rem;
    margin: 0;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin-bottom: 0.5em;
    line-height: 1;
    @include media(bp(l)) {
      margin-bottom: 0.7em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    overflow: hidden;
    line-height: 1.2;
    display: inline-block;
  }
}

.cb-phone + .cb-email,
.cb-email + .cb-phone {
  margin-top: 0.5em;
  @include media(bp(l)) {
    margin-top: 0.7em;
  }
}

// Make sure long emails don't break the layout by forcing them to wrap.
.cb-email a {
  word-wrap: break-word;
  word-break: break-all;
}
