// Style fixes within Drupal panels ipe editing display

.cb-flavor.panels-ipe-editing {
  .region,
  .cb-layout--aside-last .cb-content-area__aside-last,
  .cb-layout--aside-first-last .cb-content-area__aside-last {
    .pane {
      &.cb-list-units--component,
      &.cb-list-labs--component {
        border: 1px solid #CCC;
        .panels-ipe-dragbar {
          border: 1px solid #CCC;
        }
      }
    }
  }
}
